export const CREATING_NEW_ENTITY = 'ENTITY/CREATING_NEW_ENTITY';
export const POST_ENTITY = 'ENTITY/POST_ENTITY';
export const CANCEL_ENTITY_CREATION = 'ENTITY/CANCEL_ENTITY_CREATION';
export const POST_ENTITY_SUCCESS = 'ENTITY/POST_ENTITY_SUCCESS';
export const EDIT_ENTITY = 'ENTITY/EDIT_ENTITY';
export const EDIT_LOAD_ENTITY_SUCCESS = 'ENTITY/EDIT_LOAD_ENTITY_SUCCESS';
export const DELETE_ENTITY = 'ENTITY/DELETE_ENTITY';

export function creatingNewEntity(selectedGroup) {
  return {
    type: CREATING_NEW_ENTITY,
    showForm: true,
    isEdit: false,
    loading: false,
    payload: {
      id: -1,
    },
    selection: {
      selectedGroup: selectedGroup,
    },
  };
}

export function editEntity(entityId, selectedGroup) {
  return {
    type: EDIT_ENTITY,
    showForm: true,
    isEdit: true,
    loading: true,
    payload: {
      id: entityId,
    },
    selection: {
      selectedGroup: selectedGroup,
    },
  };
}

export function postEntity(payload, isEdit) {
  return {
    type: POST_ENTITY,
    showForm: true,
    isEdit: isEdit,
    loading: true,
    payload: payload,
  };
}

export function cancelEntityCreation() {
  return {
    type: CANCEL_ENTITY_CREATION,
  };
}

export function postEntitySuccess() {
  return {
    type: POST_ENTITY_SUCCESS,
    showForm: false,
    isEdit: false,
    loading: false,
    payload: {},
  };
}

export function editLoadEntitySuccess(json) {
  return {
    type: EDIT_LOAD_ENTITY_SUCCESS,
    showForm: true,
    isEdit: true,
    loading: false,
    payload: json,
  };
}

export function deleteEntity(entityId, groupId, entityName) {
  return {
    type: DELETE_ENTITY,
    payload: {
      entityId,
      groupId,
      name: entityName,
    },
    showForm: false,
  };
}
