import {
  UPDATE_TAG_EXPORT_PIVOT_TABLE,
  GET_FILTER_TAG_VALUES,
  GET_FILTER_TAG_VALUES_SUCCESS,
} from 'Store/Areas/Export/ExportPivotTableActions';

const initialState = {
  tags: [],
  tag: {},
  column: '',
  isAdding: false,
  payload: {},
  filterLoading: false,
  filterValues: [],
};

export default function exportPivotTableReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TAG_EXPORT_PIVOT_TABLE:
      return Object.assign({}, state, {
        tags: action.tags,
        tag: action.tag,
        column: action.column,
        isAdding: action.isAdding,
      });
    case GET_FILTER_TAG_VALUES:
    case GET_FILTER_TAG_VALUES_SUCCESS:
      return Object.assign({}, state, {
        filterLoading: action.filterLoading,
        filterValues: action.filterValues,
      });
    default:
      return state;
  }
}
