import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withTheme, withStyles, IconButton } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import DataGrid from 'Components/Shared/DataGrid/DataGrid';
import SimpleCell from 'Components/Shared/DataGrid/Cells/SimpleCell/SimpleCell';
import SimpleHeader from 'Components/Shared/DataGrid/Cells/SimpleHeader/SimpleHeader';
import {
  getTemplatesForCurrentUser,
  deleteTemplate,
  resetTemplatesData,
  downloadTemplate,
} from 'Store/Areas/Projects/TemplatesActions';
import { strings, templateTableHeaders } from 'Constants/Templates/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import Close from 'Assets/Images/exit-ic.svg';
import DeleteTemplateModal from './DeleteTemplateModal';
import styles from './TemplateTable.styles';

class TemplateTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDeleteModalOpen: false,
      templateId: null,
      templateName: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(getTemplatesForCurrentUser());
  }

  componentWillUnmount() {
    this.props.dispatch(resetTemplatesData());
  }

  onRemoveTemplate = (templateId, templateName) => {
    this.setState({
      isDeleteModalOpen: true,
      templateId,
      templateName,
    });
  }

  onDownloadTemplate = (templateId) => {
    this.props.dispatch(downloadTemplate(templateId));
  }

  onConfirmDelete = () => {
    const { templateId } = this.state;
    this.props.dispatch(deleteTemplate(templateId));
    this.onCloseDelete();
  }

  onCloseDelete = () => {
    this.setState({
      isDeleteModalOpen: false,
      templateId: null,
      templateName: '',
    });
  }

  getCellValue = ({ rowIndex, columnIndex }) => {
    const { templates } = this.props;
    const row = templates[rowIndex];
    switch (columnIndex) {
      case 0:
        return row.name;
      case 1:
        return row.taxCodeName;
      case 2:
        return row.groupName;
      case 3:
        return row.licenseType;
      case 4:
        return row.dateUploaded
          ? moment(row.dateUploaded).format(commonStrings.momentDateDisplayFormat)
          : '';
      case 5:
        return { id: row.id, cloudReference: row.cloudReference, name: row.name };
      case 6:
        return { id: row.id, isReadOnly: row.isReadOnly };
      default:
        return '';
    }
  };

  getWidth = ({ index }) => {
    let { width } = this.props;
    width = width < 1000 ? 1000 : width - 20;

    switch (index) {
      case 0:
        return width * 0.2;
      case 1:
        return width * 0.2;
      case 2:
        return width * 0.2;
      case 3:
        return width * 0.11;
      case 4:
        return width * 0.06;
      case 5:
        return width * 0.2;
      default:
        return width * 0.03;
    }
  };

  render() {
    const {
      isDeleteModalOpen,
      templateName,
    } = this.state;

    const {
      templates,
      classes,
      loading,
      width,
      height,
    } = this.props;

    return (
      <div className={classes.tableContainer}>
        <div className={classes.tableTitle}>
          {strings.tableTitle}
        </div>
        <DataGrid
          recomputeGridSizeKey={`${width} ${height}`}
          columns={templateTableHeaders}
          rows={templates}
          fixedWidth={width}
          fixedHeight={height - 100}
          getCellValue={this.getCellValue}
          getWidth={this.getWidth}
          renderHeaderCell={props => <SimpleHeader {...props} className={classes.headerCell} />}
          renderCell={(props) => {
            switch (props.index) {
              case 5:
                return (
                  <SimpleCell
                    {...props}
                    className={classes.tableCell}
                  >
                    {
                      props.children.cloudReference &&
                      <button
                        className={classes.downloadTemplate}
                        onClick={() => this.onDownloadTemplate(props.children.id)}
                      >
                        {props.children.name}
                      </button>
                    }
                  </SimpleCell>
                );
                case 6:
                return (
                  <SimpleCell
                    {...props}
                    className={classes.tableCell}
                  >
                    {
                      !props.children.isReadOnly &&
                      <IconButton onClick={
                        () => this.onRemoveTemplate(
                          props.children.id,
                          templates.find(x => x.id === props.children.id).name,
                        )}
                      >
                        <img src={Close} alt="remove" />
                      </IconButton>
                    }
                  </SimpleCell>
                );
              default:
                return (<SimpleCell
                  {...props}
                  className={classes.tableCell}
                />);
            }
          }}
        />
        <DeleteTemplateModal
          onConfirm={this.onConfirmDelete}
          onCancel={this.onCloseDelete}
          isModalOpen={isDeleteModalOpen}
          loading={loading}
          templateName={templateName}
        />
      </div>
    );
  }
}

TemplateTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};


function mapStateToProps(state) {
  return {
    templates: state.projects.templates.data,
    loading: state.projects.templates.loading,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(TemplateTable);
