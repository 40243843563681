import { CHECK_CATEGORY_CAN_BE_DELETED, CHECK_CATEGORY_CAN_BE_DELETED_SUCCESS, RESET_DELETING_CATEGORY, POST_DELETE_CATEGORY } from 'Store/Areas/Categorisation/DeleteCategoryActions';
import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';

const initialState = {
  categoryHasRules: false,
  categoryHasLineItems: false,
  lineItemCount: 0,
  categoryCanBeDeleted: false,
  deleting: false,
  loading: false,
  submitting: false,
};

export default function deleteCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_CATEGORY_CAN_BE_DELETED:
      return Object.assign({}, state, {
        loading: action.loading,
        deleting: action.deleting,
      });
    case CHECK_CATEGORY_CAN_BE_DELETED_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        categoryHasRules: action.categoryHasRules,
        categoryHasLineItems: action.categoryHasLineItems,
        lineItemCount: action.lineItemCount,
        categoryCanBeDeleted: action.categoryCanBeDeleted,
      });
    case POST_DELETE_CATEGORY:
      return Object.assign({}, state, {
        submitting: action.submitting,
      });
    case RESET_DELETING_CATEGORY:
      return Object.assign({}, state, {
        deleting: action.deleting,
      });
    case RESET_PERIOD:
      return initialState;
    default:
      return state;
  }
}
