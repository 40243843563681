import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getImportSummary } from 'Store/Areas/Import/ImportSummaryActions';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import SuccessfulSummaryModal from './Modals/SuccessfulSummaryModal';
import FailedSummaryModal from './Modals/FailedSummaryModal';

class ImportSummaryManager extends React.PureComponent {
  componentDidMount() {
    const { dispatch, periodId } = this.props;
    dispatch(getTreePickerData());
    dispatch(getImportSummary(periodId));
  }

  render() {
    const {
      importSummary: {
        loading,
        userCanProceed,
        tooManyErrors,
        tooManyClauses,
      },
      projects,
      currencySymbol,
    } = this.props;

    const sidebarLoading = projects.treePicker.loading;

    return (
      <Fragment>
        <If condition={!loading && !sidebarLoading}>
          <Choose>
            <When condition={!userCanProceed || tooManyClauses}>
              <FailedSummaryModal tooManyErrors={tooManyErrors || tooManyClauses} />
            </When>
            <Otherwise>
              <SuccessfulSummaryModal currencySymbol={currencySymbol} />
            </Otherwise>
          </Choose>
        </If>
        <If condition={loading || sidebarLoading}>
          <CircularLoader />
        </If>
      </Fragment>
    );
  }
}

ImportSummaryManager.propTypes = {
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  importSummary: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    tooManyErrors: PropTypes.bool.isRequired,
    userCanProceed: PropTypes.bool.isRequired,
    tooManyClauses: PropTypes.bool.isRequired,
  }).isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    periodId: state.periods.period.periodId,
    importSummary: state.import.importSummary,
    currencySymbol: currencySymbolSelector(state),
  };
}

export default compose(connect(mapStateToProps))(ImportSummaryManager);
