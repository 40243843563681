export const styles = theme => ({
  root: {
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.small,
    position: 'relative',
    marginTop: 4,
    marginBottom: 4,
  },
  isOver: {
    marginTop: 40,
  },
});
