const styles = theme => ({
  root: {
    padding: 0,
  },
  content: {
    padding: 20,
    width: 475,
    fontSize: theme.constants.fontSizes.regular,
  },
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 15,
  },
});

export default styles;
