import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, Card, Hidden, Grid, IconButton } from '@material-ui/core';
import Close from 'Assets/Images/exit-ic-light-blue.svg';
import withCorruptedFileProvider from 'Components/Shared/CorruptedFileProvider/WithCorruptedFileProvider';
import Breadcrumb from 'Components/Shared/Breadcrumbs/ChevronBreadcrumb/Breadcrumb';
import FileUploader from 'Components/Shared/FileUploader/FileUploader';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import Message from 'Components/Shared/Message/Message';
import UploadDropzoneContent from 'Components/Upload/Shared/UploadDropzoneContent';
import ViewReferenceListModal from 'Components/Categorization/Modals/ViewReferenceListModal/ViewReferenceListModal';
import ReferenceListDeletionModal from 'Components/Categorization/Modals/ReferenceListDeletionModal/ReferenceListDeletionModal';
import ReferenceListsList from 'Components/Categorization/Shared/ReferenceLists/ReferenceListsList';
import { commonStrings } from 'Constants/CommonStrings';
import { strings } from 'Constants/Categorization/Strings';
import { strings as uploadStrings } from 'Constants/Upload/Strings';
import { title } from 'Constants/App/Titles/Strings';
import { xlsxOnly } from 'Constants/FileTypes';
import { referenceListsSelector } from 'Store/Areas/Categorisation/ReferenceListSelectors';
import { getReferenceListItems, resetReferenceListsNavigation } from 'Store/Areas/Categorisation/ReferenceListActions';
import { requestUploadReferenceListFile, toggleCorruptedFileDetectedModal } from 'Store/Areas/FileUpload/FileUploadActions';
import TagSelectionModal from 'Components/Categorization/Modals/TagSelectionModal/TagSelectionModal';
import DragnDrop from 'Constants/DragnDrop';
import { isReadOnlySelector, periodIdSelector, isAnyLineItemFlaggedSelector } from 'Store/Areas/Period/PeriodSelectors';
import GenericDragLayer from 'Components/Shared/DragNDrop/GenericDragLayer';
import { DragRule } from 'Components/Categorization/Pages/CustomRules/RulesBuilder/Components';
import ReferenceListUploadFailedModal from './Modals/ReferenceListUploadFailedModal/ReferenceListUploadFailedModal';
import { styles } from './ReferenceLists.styles';
import DuplicateReferenceListModal from './Modals/DuplicateReferenceListModal/DuplicateReferenceListModal';

class ReferenceLists extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showImportArea: false,
    };
  }

  componentDidMount() {
    const { dispatch, periodId } = this.props;
    document.title = title.referenceLists;
    dispatch(getReferenceListItems(periodId));
  }

  componentDidUpdate() {
    const { dispatch, referenceLists } = this.props;
    if (!referenceLists.chevronNavigation && !referenceLists.loading && !referenceLists.fileLoading
      && (!referenceLists.referenceLists || !referenceLists.referenceLists.length)) {
      dispatch(push(routes.categorisation.singleKeywords));
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetReferenceListsNavigation());
  }

  onFileSelected = (file) => {
    const { dispatch, periodId } = this.props;
    this.toggleImportArea(false);
    dispatch(requestUploadReferenceListFile(file, periodId));
  }

  onFileRejected = () => {
    this.props.dispatch(toggleCorruptedFileDetectedModal({ showModal: true, fileTypes: xlsxOnly }));
  }

  toggleImportArea = (visible) => {
    this.setState({
      showImportArea: visible,
    });
  }

  renderDragLayer(item) {
    if (item.type === DragnDrop.referenceListItem) {
      return (
        <DragRule
          text={item.keyword}
        />
      );
    }

    return null;
  }

  render() {
    const {
      dispatch,
      classes,
      referenceLists,
      isReadOnly,
      periodId,
      isAnyLineItemFlagged,
    } = this.props;

    const { showImportArea } = this.state;
    return (
      <Fragment>
        <TagSelectionModal />
        <If condition={referenceLists.open}>
          <ViewReferenceListModal />
        </If>
        <If condition={referenceLists.referenceListRules}>
          <ReferenceListDeletionModal />
        </If>
        <If condition={!isReadOnly}>
          <GenericDragLayer disableIE={false}>
            {this.renderDragLayer}
          </GenericDragLayer>
        </If>
        <Breadcrumb
          items={[
            strings.referenceLists,
            strings.keyword,
            strings.keywordCombination,
            strings.customRules,
            strings.lineItems,
            strings.conflictedLineItems,
            strings.flaggedLineItems,
          ]}
          activeIndex={0}
          className={classes.breadcrumb}
          selectableIndexes={[1, 3, 4, 5, ...isAnyLineItemFlagged ? [6] : []]}
          onClicks={
            [
              null,
              () => dispatch(push(routes.categorisation.singleKeywords)),
              null,
              () => dispatch(push(routes.categorisation.customKeywords)),
              () => dispatch(push(routes.categorisation.lineItems)),
              () => dispatch(push(routes.categorisation.conflictingLineItems)),
              () => dispatch(push(routes.categorisation.flaggedLineItems)),
            ]
          }
          isSelectable
        />
        <Card className={classes.card}>
          <Hidden smDown>
            <Message
              title={commonStrings.didYouKnow}
              className={classes.message}
              contentClassName={classes.messageContent}
              message={strings.referenceListsDidYouKnow}
            />
          </Hidden>
          <Choose>
            <When condition={referenceLists.referenceLists &&
               !referenceLists.loading && !referenceLists.fileLoading}
            >
              <Grid container direction="row" spacing={8} alignItems="flex-start">
                <Grid item xs={12} className={classes.buttonContent}>
                  <div className={classes.flexContainer}>
                    <button
                      className={(showImportArea || isReadOnly)
                        ? classes.buttonDisabled : classes.button}
                      onClick={() => this.toggleImportArea(true)}
                      disabled={!!showImportArea || isReadOnly}
                    >
                      {strings.addNewReferenceList}
                    </button>
                    {showImportArea &&
                      <div className={classes.deleteButton}>
                        <IconButton
                          className={classes.icon}
                          onClick={() => this.toggleImportArea(false)}
                        >
                          <img src={Close} alt="remove" />
                        </IconButton>
                      </div>
                    }
                  </div>
                </Grid>
                <Grid item xs={12} className={classes.uploadContent}>
                  {showImportArea &&
                    <FileUploader
                      className={classes.flex}
                      onFileSelected={files => this.onFileSelected(files[0])}
                      onDropRejected={this.onFileRejected}
                      fileTypes={xlsxOnly}
                    >
                      <UploadDropzoneContent
                        description={uploadStrings.uploadReferenceDescription}
                        buttonDescription={uploadStrings.templateUploadButtonDescription}
                      />
                    </FileUploader>
                  }
                </Grid>
                <ReferenceListsList
                  isReadOnly={isReadOnly}
                  referenceLists={referenceLists}
                />
              </Grid>
            </When>
            <Otherwise>
              <CircularLoader />
            </Otherwise>
          </Choose>
        </Card>
        <ReferenceListUploadFailedModal />
        <DuplicateReferenceListModal periodId={periodId} />
      </Fragment>
    );
  }
}

ReferenceLists.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
  fileUpload: PropTypes.shape({
    uploadResponse: PropTypes.object,
    showCorruptedFileModal: PropTypes.bool,
    checkingForViruses: PropTypes.bool,
    fileContainsVirus: PropTypes.bool,
    isXlsOnly: PropTypes.bool,
  }).isRequired,
  referenceLists: PropTypes.shape({
    referenceLists: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })),
    loading: PropTypes.bool.isRequired,
    fileLoading: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    chevronNavigation: PropTypes.bool.isRequired,
  }).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isAnyLineItemFlagged: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
    isReadOnly: isReadOnlySelector(state),
    referenceLists: referenceListsSelector(state),
    isAnyLineItemFlagged: isAnyLineItemFlaggedSelector(state),
  };
}

export default compose(
  withCorruptedFileProvider(),
  withStyles(styles),
  connect(mapStateToProps),
)(ReferenceLists);
