export const matchedSupportingAndMainDocHeaders = [
  {
    name: 'matchingValue',
    displayName: 'Matching Value',
  },
  {
    name: 'supportingDataValue',
    displayName: 'Supporting data value',
  },
  {
    name: 'mainDataValue',
    displayName: 'Main data value',
  },
  {
    name: 'difference',
    displayName: 'Difference',
  },
  {
    name: 'isProRate',
    displayName: 'Pro Rate',
  },
  {
    name: 'isBalancingFigure',
    displayName: 'Balancing Figure',
  },
];
