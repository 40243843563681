import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import DragnDrop from 'Constants/DragnDrop';
import { strings } from 'Constants/Upload/TagMapping';
import { DataTypes } from 'Constants/Upload/DataTypes';
import { checkDataType } from 'Constants/Upload/DataTypeHelpers'; // eslint-disable-line react/no-unused-prop-types
import { styles } from './TagDropzone.styles';

class TagDropzone extends React.Component {
  render() {
    const {
      connectDropTarget, classes, hovered, canDrop, dragTag,
    } = this.props;
    const renderedComponent = (
      <div
        className={`${classes.root}
        ${dragTag && !canDrop ? classes.cantDrop : ''}
        ${hovered && canDrop ? classes.hovered : ''}
        `}
      >
        <span>{dragTag && !canDrop ? strings.dragTagWrongType : strings.dragTagHere}</span>
      </div>
    );

    return connectDropTarget(renderedComponent);
  }
}

TagDropzone.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  hovered: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
  // Dragndrop only props
  onDrop: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  dragTag: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    tagType: PropTypes.string,
  }),
  expectedDataType: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DataTypes))).isRequired, // eslint-disable-line
};

TagDropzone.defaultProps = {
  dragTag: null,
};

const spec = {
  drop(props, monitor) {
    switch (monitor.getItemType()) {
      case DragnDrop.mappingTag:
        return {
          onDrop: (tagId, tagName) => props.onDrop(tagId, tagName),
        };
      default:
        return undefined;
    }
  },
  canDrop(props, monitor) {
    const { tagType } = monitor.getItem();
    return checkDataType(tagType, props.expectedDataType);
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    canDrop: monitor.canDrop(),
    dragTag: monitor.getItem(),
  };
}

export default compose(
  DropTarget(DragnDrop.mappingTag, spec, collect),
  withStyles(styles),
  withTheme(),
)(TagDropzone);
