export const CREATING_NEW_GROUP = 'GROUP/CREATING_NEW_GROUP';
export const EDIT_GROUP = 'GROUP/EDIT_GROUP';
export const EDIT_LOAD_GROUP_SUCCESS = 'GROUP/EDIT_LOAD_GROUP_SUCCESS';
export const POST_GROUP = 'GROUP/POST_GROUP';
export const CANCEL_GROUP_CREATION = 'GROUP/CANCEL_GROUP_CREATION';
export const POST_NEW_GROUP_SUCCESS = 'GROUP/POST_NEW_GROUP_SUCCESS';
export const DELETE_GROUP = 'GROUP/DELETE_GROUP';

export function creatingNewGroup() {
  return {
    type: CREATING_NEW_GROUP,
    showForm: true,
    isEdit: false,
    loading: false,
    payload: {},
  };
}

export function editGroup(groupId) {
  return {
    type: EDIT_GROUP,
    showForm: true,
    isEdit: true,
    payload: {
      id: groupId,
    },
    loading: true,
  };
}

export function postGroup(payload, isEdit) {
  return {
    type: POST_GROUP,
    showForm: true,
    isEdit: isEdit,
    loading: true,
    payload: payload,
  };
}

export function cancelGroupCreation() {
  return {
    type: CANCEL_GROUP_CREATION,
    showForm: false,
    isEdit: false,
    loading: false,
    payload: {},
  };
}

export function postNewGroupSuccess() {
  return {
    type: POST_NEW_GROUP_SUCCESS,
    showForm: false,
    isEdit: false,
    loading: false,
    payload: {},
  };
}

export function editLoadGroupSuccess(json) {
  return {
    type: EDIT_LOAD_GROUP_SUCCESS,
    showForm: true,
    isEdit: true,
    loading: false,
    payload: json,
  };
}
export function deleteGroup(groupId, groupName) {
  return {
    type: DELETE_GROUP,
    payload: {
      groupId,
      name: groupName,
    },
    showForm: false,
  };
}
