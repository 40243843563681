export const styles = theme => ({
  breadcrumb: {
    marginBottom: 10,
  },
  card: {
    padding: '20px 20px 0 20px',
    height: 'Calc(100vh - 10.5em)',
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    backgroundColor: theme.palette.primary.messageBackground,
    color: theme.palette.primary.darkText,
    lineHeight: 1.43,
    fontSize: theme.constants.fontSizes.regular,
  },
  button: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
  },
  buttonDisabled: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    border: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingBottom: 10,
    marginTop: 20,
  },
  buttonContent: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  icon: {
    padding: 5,
  },
});
