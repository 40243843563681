import { currencies } from 'Constants/Entities/Currencies';
import { taxContexts } from 'Constants/Projects/TaxContexts';

export const currencySymbolSelector = (state) => {
  const {
    projects: {
      treePicker,
    },
    periods: {
      period,
    },
  } = state;

  if (treePicker.loading || !treePicker.data || period.loading || !period.data) {
    return '';
  }

  const {
    data: {
      projectId,
    },
  } = period;

  if (!projectId) {
    return '';
  }

  const entity = treePicker.data
    .filter(g => !!g.entities && g.entities.length)
    .map(g => g.entities
      && g.entities.find(e =>
        e.projects && e.projects.some(p => p.id === projectId)))
    .find(e => !!e);

  if (!entity) {
    return '';
  }

  return entity.currencyName;
};

export const updateTreePickerState = (action, state, isAdmin) => {
  const newState = {
    loading: action.loading,
    taxContextFilter: action.taxContextFilter,
    emailFilter: action.emailFilter,
    data: action.data,
  };

  if (newState.data && newState.data.length) {
    newState.data.forEach((g) => {
      if (g && g.entities && g.entities.length) {
        g.entities.forEach((e) => {
          if (!e) {
            return;
          }

          e.currencyName = currencies.find(c => c.id === e.currencyId).name;

          if (e.projects && e.projects.length) {
            e.projects.forEach((p) => {
              if (!p) {
                return;
              }

              p.taxContext = // eslint-disable-line no-param-reassign
                taxContexts.find(tc => tc.id === p.taxContextId);

              if (!isAdmin && action.payload && !action.payload.includePeriods) {
                const oldG = state.data.find(g2 => g2.id === g.id);
                const oldE = oldG && oldG.entities.find(e2 => e2.id === e.id);
                const oldP = oldE && oldE.projects.find(p2 => p2.id === p.id);

                if (oldP) {
                  p.periods = // eslint-disable-line no-param-reassign
                    oldP.periods;
                }
              }
            });
          }
        });
      }
    });
  }

  return newState;
};
