import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import { strings } from 'Constants/Projects/CreatePeriod/Strings';

class ArchivePeriodOverlay extends React.PureComponent {
  render() {
    const {
      period,
      onConfirm,
      onCancel,
      isModalOpen,
    } = this.props;

    return (
      <ConfirmationModal
        open={isModalOpen}
        title={strings.archivePeriodTitle(period)}
        message={strings.archivePeriodNote}
        acceptText={strings.archivePeriod}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
      />
    );
  }
}

ArchivePeriodOverlay.propTypes = {
  period: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

export default (ArchivePeriodOverlay);
