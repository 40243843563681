import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { periodLoadingSelector } from 'Store/Areas/Period/PeriodSelectors';

/*
  Calls the provided action when the period loading flag is
  set from true => false in the redux store
*/
export const onPeriodReloaded = (action = () => {}) => (Component) => {
  class OnPeriodReloaded extends React.PureComponent {
    componentDidUpdate(prevProps) {
      if (!this.props.loading && prevProps.loading) {
        action(this.props);
      }
    }

    render() {
      const { loading, ...rest } = this.props;
      return (
        <Component {...rest} />
      );
    }
  }

  OnPeriodReloaded.propTypes = {
    loading: PropTypes.bool.isRequired,
  };

  function mapStateToProps(state) {
    return {
      loading: periodLoadingSelector(state),
    };
  }

  return connect(mapStateToProps)(OnPeriodReloaded);
};
