import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { ADMIN_POST_REMOVE_ACCESS_URL } from 'Store/Api/ApiEndpoints';
import { Post } from 'Store/Api/CallApi';
import {
  POST_REMOVE_ACCESS,
  postRemoveAccessSuccess,
  postRemoveAccessFailure,
  resetRemoveAccess,
} from 'Store/Areas/Setup/Admin/RemoveAccessActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { getAdminTreePickerData } from 'Store/Areas/Setup/Admin/AdminTreePickerActions';
import { getUserPermissions } from 'Store/Areas/User/PermissionsActions';
import { getUsers } from 'Store/Areas/Setup/Admin/GetUserActions';

/* Worker Sagas */
function* removeAccess(action) {
  const response = yield Post(ADMIN_POST_REMOVE_ACCESS_URL, action.payload);
  if (response.ok) {
    const data = yield response.json();
    if (data.success) {
      yield put(postRemoveAccessSuccess());
      yield put(resetRemoveAccess());
      const message = `${action.payload.userIds.length} user(s) have been successfully removed`;
      yield put(displayToastNotification(message));
      if (action.removingSelf) {
        yield put(getUserPermissions());
        yield put(getAdminTreePickerData());
      }
      yield put(getUsers(action.payload.entityLevel, action.payload.entityLevelId));
    } else {
      yield put(postRemoveAccessFailure(data.error));
    }
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* removeAccessSagas() {
  yield takeLatest(POST_REMOVE_ACCESS, removeAccess);
}
