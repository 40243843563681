const styles = theme => ({
  asterisk: {
    color: theme.palette.primary.main,
    fontWeight: 800,
    fontSize: 16,
    marginLeft: 4,
  },
});

export default styles;
