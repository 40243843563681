import React from 'react';
import {
  DonutWidth,
  DonutHeight,
  LabelR,
  LabelRAlt,
  LineR,
  DonutArc,
  DonutPie,
  DonutTitle,
  EmptyDonut,
} from 'Constants/Categorization/CategoryReview/DonutChartConstants';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { formatCurrencyString } from 'Helpers/NumberHelpers';
import styles from './DonutChart.styles';


class DonutChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      popoverCategory: null,
      popoverText: null,
    };
  }

  handlePopoverOpen(keyword, value) {
    this.setState({ popoverCategory: keyword, popoverText: value });
  }

  handlePopoverClose() {
    this.setState({ popoverCategory: null, popoverText: null });
  }

  render() {
    const {
      classes,
      category,
      popularKeywords,
      currencySymbol,
    } = this.props;

    let empty = false;
    const data = DonutPie(popularKeywords.result.slice(0, 10), (d) => {
      d.value = +d.value; //eslint-disable-line
      return d;
    });


    if (popularKeywords.lineCount === 0) {
      empty = true;
      data.push({
        data: { keyword: '', value: 0 }, endAngle: 0, index: 0, padAngle: 0, startAngle: 6.29, value: 0,
      });
    }

    const { popoverCategory, popoverText } = this.state;
    return (
      <div>
        <svg className={classes.svg}>
          <text className={`${classes.text} ${classes.titleText}`}>
            <tspan className={classes.rowCountText} x="38" y="24" dy="1em">{DonutTitle}</tspan>
          </text>
          <g transform={`translate(${DonutWidth / 2}, ${DonutHeight / 2})`}>
            {data && data.map((d) => {
              const c = DonutArc.centroid(d);
              const x = c[0];
              const y = c[1];
              const h = Math.sqrt((x * x) + (y * y));
              const start = d.startAngle;
              const end = d.endAngle;
              const anchor = ((end + start) / 2 > Math.PI);
              const xPosLabel = ((x / h) * LabelR);
              const yPosLabel = ((y / h) * LabelR);
              const xPosLabelAlt = ((x / h) * LabelRAlt);
              const yPosLabelAlt = ((y / h) * LabelRAlt);
              const xPosLine = ((x / h) * LineR);
              const yPosLine = ((y / h) * LineR);
              const arcHideThreshold = 0.20;
              const formattedNumber = formatCurrencyString(d.data.value, currencySymbol);
              return (
                <g className={classes.arc} key={`a${d.data.keyword}`}>
                  <Choose>
                    <When condition={(end - start) >= arcHideThreshold}>
                      <path
                        className={classes.arcPath}
                        d={DonutArc(d)}
                        fill={category.color}
                      />
                      <line x1={x} y1={y} x2={xPosLine} y2={yPosLine} stroke="#666" dy="1em" />
                      <text className={classes.text} transform={`${anchor ? `translate(${xPosLabelAlt} , ${yPosLabelAlt})` : `translate(${xPosLabel} , ${yPosLabel})`}`} dy="0.1em">
                        <tspan x="0" textAnchor={`${anchor ? 'end' : 'start'}`}>{d.data.keyword}</tspan>
                        <tspan x="0" textAnchor={`${anchor ? 'end' : 'start'}`} dy="1.2em">{formattedNumber}</tspan>
                      </text>
                    </When>
                    <Otherwise>
                      <path
                        className={`${classes.arcPath} ${classes.hidePath} ${empty ? classes.emptyDonut : classes.filledDonut}`}
                        d={DonutArc(d)}
                        fill={category.color}
                        onMouseOver={o => this.handlePopoverOpen(o, d)}
                        onFocus={o => this.handlePopoverOpen(o, d)}
                        onMouseOut={() => this.handlePopoverClose()}
                        onBlur={() => this.handlePopoverClose()}
                      />
                      <If condition={
                        popoverCategory
                        && popoverText.data.keyword === d.data.keyword
                        && !empty
                      }
                      >
                        <line x1={x} y1={y} x2={xPosLine} y2={yPosLine} stroke="#666" dy="1em" />
                        <text className={classes.text} transform={`${anchor ? `translate(${xPosLabelAlt} , ${yPosLabelAlt})` : `translate(${xPosLabel} , ${yPosLabel})`}`} dy="0.1em">
                          <tspan x="0" textAnchor={`${anchor ? 'end' : 'start'}`}>{d.data.keyword}</tspan>
                          <tspan x="0" textAnchor={`${anchor ? 'end' : 'start'}`} dy="1.2em">{formattedNumber}</tspan>
                        </text>
                      </If>
                    </Otherwise>
                  </Choose>
                  <If condition={empty}>
                    <text className={classes.text} dy="0.1em">
                      <tspan x="0">{EmptyDonut}</tspan>
                    </text>
                  </If>
                </g>
              );
            })}
          </g>
        </svg>
      </div >
    );
  }
}

DonutChart.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  category: PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.string,
    name: PropTypes.string,
    amountValue: PropTypes.number,
  }).isRequired,
  popularKeywords: PropTypes.shape({
    lineCount: PropTypes.number.isRequired,
    result: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string,
      value: PropTypes.number,
    })).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    category: state.categorisation.reviewCategory.category,
    popularKeywords: state.categorisation.reviewCategory.popularKeywords,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(DonutChart);
