import React from 'react';
import { connect } from 'react-redux';
import { IconButton, Drawer, withTheme, withStyles, Fade } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { shouldRenderBannerSelector } from 'Store/Areas/Projects/UserTreePickerSelectors';
import styles from 'Components/Shared/Sidebar/SidebarStructure.styles';
import Loader from 'Components/Shared/Loaders/CircularLoader';
import MenuIcon from 'Assets/Images/menu.svg';
import CloseIcon from 'Assets/Images/exit-ic.svg';
import Blur from 'Components/Shared/Blur/Blur';

class SidebarStructure extends React.PureComponent {
  render() {
    const {
      classes,
      mainContent,
      sidebarContent,
      mainContentLoading,
      mainContentLoadingStatus,
      sidebarLoading,
      isCollapsible,
      collapsed,
      shouldRenderBanner,
      toggleCollapse,
    } = this.props;
    return (
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          anchor="left"
          className={`${collapsed ? classes.sidebarCollapsed : ''}`}
          classes={{
            paper:
            `${classes.drawerPaper} ${(collapsed ? classes.sidebarCollapsed : classes.sidebarExpanded)} ${shouldRenderBanner ? classes.bannerShift : ''}`,
          }}
        >
          {isCollapsible &&
            <IconButton className={`${collapsed ? classes.toggle : classes.toggleExtended}`} onClick={toggleCollapse}>
              <img alt="menu" src={collapsed ? MenuIcon : CloseIcon} />
            </IconButton>
          }
          {sidebarLoading &&
            <div className={classes.sidebarWrapper}>
              <Loader
                color="white"
              />
            </div>
          }
          {!collapsed &&
            <Fade in={!collapsed} timeout={600}>
              <div className={classes.sidebarWrapper}>
                {!sidebarLoading &&
                  sidebarContent
                }
              </div>
            </Fade>
          }
        </Drawer>
        <div className={`${classes.content} ${!isCollapsible ? classes.staticSidebarContent : ''}`}>
          {mainContentLoading &&
            <div className={classes.loaderWrapper}>
              <div className={classes.loader}>
                <Loader fullWidth={false} size={60} />
              </div>
              <div className={classes.loader}>
                {mainContentLoadingStatus}
              </div>
            </div>
          }
          {!mainContentLoading &&
            <Blur blur={isCollapsible && !collapsed}>
              <React.Fragment>
                {mainContent}
              </React.Fragment>
            </Blur>
          }
        </div>
      </div>
    );
  }
}

SidebarStructure.defaultProps = {
  mainContentLoading: false,
  mainContentLoadingStatus: null,
  sidebarLoading: false,
  isCollapsible: false,
  collapsed: false,
  shouldRenderBanner: false,
  toggleCollapse: () => { },
};

SidebarStructure.propTypes = {
  collapsed: PropTypes.bool,
  shouldRenderBanner: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  mainContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  mainContentLoading: PropTypes.bool,
  mainContentLoadingStatus: PropTypes.string,
  sidebarContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  sidebarLoading: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  toggleCollapse: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    shouldRenderBanner: shouldRenderBannerSelector(state),
  };
}

export default compose(
  withTheme(),
  withStyles(styles),
  connect(mapStateToProps),
)(SidebarStructure);

