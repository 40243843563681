import React from 'react';
import { DropTarget } from 'react-dnd';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles, withTheme, IconButton } from '@material-ui/core';
import Close from 'Assets/Images/exit-ic.svg';
import DragnDrop from 'Constants/DragnDrop';
import { styles } from './BuilderCell.styles';

class BuilderCell extends React.PureComponent {
  render() {
    const {
      children,
      width,
      className,
      classes,
      hovered,
      connectDropTarget,
      id,
      onRemove,
      dragMessage,
    } = this.props;
    const component = (
      <div
        style={{ width: width, maxWidth: width }}
        className={`${className} ${hovered ? classes.hovered : ''} ${classes.root}`}
      >
        <If condition={children}>
          <div className={`${classes.text} ${classes.ellipsis}`}>{children}</div>
          <IconButton onClick={() => onRemove(id)} className={classes.icon}>
            <img src={Close} alt="remove" />
          </IconButton>
        </If>
        <If condition={!children && !hovered}>
          <div className={`${classes.dragMessage} ${classes.ellipsis}`}>
            {dragMessage}
          </div>
        </If>
      </div>
    );

    return connectDropTarget(component);
  }
}

BuilderCell.defaultProps = {
  children: '',
};

BuilderCell.propTypes = {
  type: PropTypes.string.isRequired, // eslint-disable-line
  id: PropTypes.number.isRequired,
  children: PropTypes.string,
  width: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  hovered: PropTypes.bool.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired, // eslint-disable-line
  onRemove: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dragMessage: PropTypes.string.isRequired,
};

const spec = {
  drop(props) {
    switch (props.type) {
      case DragnDrop.operator.tag:
        return {
          onDrop: (value, metadata) => props.onDrop(metadata, props.id),
        };
      default:
        return {
          onDrop: value => props.onDrop(value, props.id),
        };
    }
  },
  canDrop(props, monitor) {
    return monitor.getItemType() === props.type;
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  DropTarget(x => x.type, spec, collect),
)(BuilderCell);

