const styles = theme => ({
  card: {
    marginLeft: 20,
    marginRight: 20,
    padding: 20,
  },
  uploadContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  flex: {
    flexGrow: 1,
  },
  gridRow: {
    paddingBottom: 15,
  },
  description: {
    fontSize: theme.constants.fontSizes.large,
    color: theme.palette.primary.darkText,
  },
  message: {
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.regular,
    marginTop: 10,
    marginRight: 30,
  },
  disabled: {
    pointerEvents: 'none',
  },
});

export default styles;
