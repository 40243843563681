import { updateTreePickerState } from 'Helpers/TreePickerHelpers';
import {
  GET_TREEPICKER_DATA,
  GET_TREEPICKER_DATA_SUCCESS,
} from './TreePickerActions';

const initialState = {
  data: [],
  loading: true,
};

export default function treePickerReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TREEPICKER_DATA:
    case GET_TREEPICKER_DATA_SUCCESS:
      return updateTreePickerState(action, state, false);
    default:
      return state;
  }
}
