import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme, Card } from '@material-ui/core';
import { connect } from 'react-redux';
import { strings } from 'Constants/Templates/Strings';
import greenTick from 'Assets/Images/green-tick.svg';
import redCross from 'Assets/Images/exit-ic-red.svg';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import { templateImportStages } from 'Constants/Templates/TemplateProcessingStages';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import { resetUploadTemplateState } from 'Store/Areas/Template/UploadTemplateActions';
import styles from './UploadTemplateResult.styles';

class UploadTemplateResult extends React.PureComponent {
  onProceedClick = () => {
    const { dispatch } = this.props;
    dispatch(resetUploadTemplateState());
    dispatch(push(routes.templates));
  }
  render() {
    const {
      classes,
      templateUpload,
    } = this.props;
    return (
      <Card className={classes.card}>
        <div className={classes.content}>
          <Choose>
            <When condition={templateUpload.stage === templateImportStages.templateCreated}>
              <div className={classes.title}>
                <img alt="" className={classes.tick} src={greenTick} />
                {strings.templateSuccessful}
              </div>
              <div className={classes.textArea}>
                <div className={classes.note}>
                  {strings.templateSuccessfulNote}
                </div>
                {templateUpload.hasImportMessages &&
                <div>
                  <h4>Warnings</h4>
                  <h5>The following rules have been excluded from the import:</h5>
                  {templateUpload.importMessages.map((x) => {
                    return <p>{x}</p>;
                  })}
                </div>
                }
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  disableRipple
                  backgroundColor={constants.backgroundColor.main}
                  className={classes.button}
                  onClick={this.onProceedClick}
                >
                  {strings.outcomeTemplateButton}
                </Button>
              </div>
            </When>
            <Otherwise>
              <div className={classes.title}>
                <img alt="" className={classes.tick} src={redCross} />
                {strings.templateFailed}
              </div>
              <div className={classes.textArea}>
                <div className={classes.note}>
                  {strings.templateFailedNote}
                </div>
                {templateUpload.hasImportMessages &&
                <div>
                  <h4>Errors</h4>
                  {templateUpload.importMessages.map((x) => {
                    return <p>{x}</p>;
                  })}
                </div>
                }
                <div />
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  disableRipple
                  backgroundColor={constants.backgroundColor.main}
                  className={classes.button}
                  onClick={this.onProceedClick}
                >
                  {strings.outcomeTemplateButton}
                </Button>
              </div>
            </Otherwise>
          </Choose>
        </div >
      </Card >
    );
  }
}

UploadTemplateResult.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  templateUpload: PropTypes.shape({
    stage: PropTypes.number.isRequired,
    hasImportMessages: PropTypes.bool.isRequired,
    importMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    templateUpload: state.templateUpload,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(UploadTemplateResult);
