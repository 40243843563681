const styles = theme => ({
  card: {
    marginLeft: 20,
    marginRight: 20,
    padding: 20,
  },
  uploadContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  flex: {
    flexGrow: 1,
  },
  didYouKnowContainer: {
    paddingRight: 30,
    marginTop: -15,
    marginBottom: -15,
  },
  didYouKnow: {
    marginTop: 5,
    marginBottom: 5,
    lineHeight: '20px',
    fontSize: theme.constants.fontSizes.regular,
  },
  didYouKnowTitle: {
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  disabled: {
    pointerEvents: 'none',
  },
});

export default styles;
