export const styles = theme => ({
  root: {
    lineHeight: '40px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  hovered: {
    backgroundColor: 'rgba(0, 48, 135, 0.1)',
  },
  text: {
    paddingLeft: 10,
    fontSize: theme.constants.fontSizes.small,
    flexGrow: 1,
    textAlign: 'center',
  },
  icon: {
    cursor: 'pointer',
    borderRadius: 4,
    margin: 4,
  },
  dragMessage: {
    width: '100%',
    height: 'Calc(100% - 10px)',
    margin: 4,
    borderStyle: 'dashed',
    borderColor: '#c8c8c8',
    borderWidth: 1,
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 12,
    color: '#666666',
    lineHeight: '35px',
    textAlign: 'center',
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '70px',
  },
});
