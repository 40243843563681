import {
  CHANGE_MENU_MODE,
  SET_USER_WIP,
  DISMISS_WIP_WARNING,
  TRIGGER_WIP_WARNING,
  RESET_RETURN_ROUTE,
} from './MenuModeActions';

const initialState = {
  returnRoute: null,
  workInProgress: false,
  userHasDismissedWarning: false,
  menuModeToggled: false,
};

export default function menuModeReducer(state = initialState, action) {
  switch (action.type) {
    case TRIGGER_WIP_WARNING:
    case DISMISS_WIP_WARNING:
      return Object.assign({}, state, {
        userHasDismissedWarning: action.userHasDismissedWarning,
        menuModeToggled: action.menuModeToggled,
      });
    case CHANGE_MENU_MODE:
      return Object.assign({}, state, {
        workInProgress: action.workInProgress,
        returnRoute: action.returnRoute,
        menuModeToggled: action.menuModeToggled,
        userHasDismissedWarning: action.userHasDismissedWarning,
      });
    case SET_USER_WIP:
      return Object.assign({}, state, {
        workInProgress: action.workInProgress,
        userHasDismissedWarning: action.userHasDismissedWarning,
      });
    case RESET_RETURN_ROUTE:
      return Object.assign({}, state, {
        returnRoute: action.returnRoute,
      });
    default:
      return state;
  }
}
