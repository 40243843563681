export const GET_NUMBER_OF_UNCATEGORISED_LINE_ITEMS = 'EXTERNAL_REVIEW/GET_NUMBER_OF_UNCATEGORISED_LINE_ITEMS';
export const GET_NUMBER_OF_UNCATEGORISED_LINE_ITEMS_SUCCESS = 'EXTERNAL_REVIEW/GET_NUMBER_OF_UNCATEGORISED_LINE_ITEMS_SUCCESS';
export const IS_EXTERNAL_REVIEW_EXPORTED_FILE_EXPIRED = 'EXTERNAL_REVIEW/IS_EXTERNAL_REVIEW_EXPORTED_FILE_EXPIRED';
export const IS_EXTERNAL_REVIEW_EXPORTED_FILE_EXPIRED_SUCCESS = 'EXTERNAL_REVIEW/IS_EXTERNAL_REVIEW_EXPORTED_FILE_EXPIRED_SUCCESS';
export const GET_EXTERNAL_REVIEW_EXPORTED_FILES = 'EXTERNAL_REVIEW/GET_EXTERNAL_REVIEW_EXPORTED_FILES';
export const GET_EXTERNAL_REVIEW_EXPORTED_FILES_SUCCESS = 'EXTERNAL_REVIEW/GET_EXTERNAL_REVIEW_EXPORTED_FILES_SUCCESS';
export const REQUEST_DOWNLOAD_EXTERNAL_REVIEW_EXPORTED_FILE = 'EXTERNAL_REVIEW/REQUEST_DOWNLOAD_EXTERNAL_REVIEW_EXPORTED_FILE';
export const SET_EXTERNAL_REVIEW_FILE_LOADING = 'EXTERNAL_REVIEW/SET_EXTERNAL_REVIEW_FILE_LOADING';
export const DELETE_EXTERNAL_REVIEW_EXPORTED_FILE = 'EXTERNAL_REVIEW/DELETE_EXTERNAL_REVIEW_EXPORTED_FILE';
export const DELETE_EXTERNAL_REVIEW_EXPORTED_FILE_SUCCESS = 'EXTERNAL_REVIEW/DELETE_EXTERNAL_REVIEW_EXPORTED_FILE_SUCCESS';
export const SET_EXTERNAL_REVIEW_FILE_NAME = 'EXTERNAL_REVIEW/SET_EXTERNAL_REVIEW_FILE_NAME';
export const RESET_EXTERNAL_REVIEW_FILES = 'EXTERNAL_REVIEW/RESET_EXTERNAL_REVIEW_FILES';
export const GET_EXTERNAL_REVIEW_IMPORTED_FILE = 'EXTERNAL_REVIEW/GET_EXTERNAL_REVIEW_IMPORTED_FILE';
export const GET_EXTERNAL_REVIEW_IMPORTED_FILE_SUCCESS = 'EXTERNAL_REVIEW/GET_EXTERNAL_REVIEW_IMPORTED_FILE_SUCCESS';
export const REQUEST_EXTERNAL_REVIEW_PROCESSING = 'EXTERNAL_REVIEW/REQUEST_EXTERNAL_REVIEW_PROCESSING';
export const REQUEST_EXTERNAL_REVIEW_PROCESSING_SUCCESS = 'EXTERNAL_REVIEW/REQUEST_EXTERNAL_REVIEW_PROCESSING_SUCCESS';
export const CANCEL_EXTERNAL_REVIEW = 'EXTERNAL_REVIEW/CANCEL_EXTERNAL_REVIEW';

export function getExternalReviewExportedFiles(periodId) {
  return {
    type: GET_EXTERNAL_REVIEW_EXPORTED_FILES,
    loading: true,
    payload: {
      periodId: periodId,
      isExternalReview: true,
    },
  };
}

export function getExternalReviewImportedFile(periodId) {
  return {
    type: GET_EXTERNAL_REVIEW_IMPORTED_FILE,
    loading: true,
    payload: {
      periodId: periodId,
    },
  };
}

export function getExternalReviewExportedFilesSuccess(files) {
  return {
    type: GET_EXTERNAL_REVIEW_EXPORTED_FILES_SUCCESS,
    loading: false,
    files: files,
  };
}

export function getExternalReviewImportedFileSuccess(file) {
  return {
    type: GET_EXTERNAL_REVIEW_IMPORTED_FILE_SUCCESS,
    loading: false,
    importedFile: file,
  };
}

export function downloadExternalReviewExportFile(periodId, exportFileId) {
  return {
    type: REQUEST_DOWNLOAD_EXTERNAL_REVIEW_EXPORTED_FILE,
    payload: {
      periodId: periodId,
      exportFileId: exportFileId,
    },
  };
}

export function deleteExternalReviewExportFile(periodId, exportFileId, periodName) {
  return {
    type: DELETE_EXTERNAL_REVIEW_EXPORTED_FILE,
    payload: {
      periodId: periodId,
      exportFileId: exportFileId,
    },
    periodName,
    loading: true,
  };
}

export function deleteExternalReviewExportFileSuccess() {
  return {
    type: DELETE_EXTERNAL_REVIEW_EXPORTED_FILE_SUCCESS,
    loading: false,
  };
}

export function setExternalReviewFileLoading(fileLoading) {
  return {
    type: SET_EXTERNAL_REVIEW_FILE_LOADING,
    fileLoading: fileLoading,
  };
}

export function setExternalReviewFileName(externalReviewCloudRef, externalReviewFilename) {
  return {
    type: SET_EXTERNAL_REVIEW_FILE_NAME,
    externalReviewCloudRef: externalReviewCloudRef,
    externalReviewFilename: externalReviewFilename,
    fileLoading: false,
  };
}
export function resetExternalReviewFiles() {
  return {
    type: RESET_EXTERNAL_REVIEW_FILES,
  };
}

export function getNumberOfUncategorisedLineItems(periodId) {
  return {
    type: GET_NUMBER_OF_UNCATEGORISED_LINE_ITEMS,
    payload: {
      periodId: periodId,
    },
    loading: true,
    uncategorisedLineItemCount: null,
    canExport: false,
  };
}

export function getNumberOfUncategorisedLineItemsSuccess(data) {
  return {
    type: GET_NUMBER_OF_UNCATEGORISED_LINE_ITEMS_SUCCESS,
    uncategorisedLineItemCount: data.uncategorisedLineItemCount,
    canExport: data.canExport,
    loading: false,
  };
}

export function isExternalReviewExportedFileExpired(periodId) {
  return {
    type: IS_EXTERNAL_REVIEW_EXPORTED_FILE_EXPIRED,
    payload: {
      periodId: periodId,
    },
    isExternalReviewExportedFileExpired: false,
    loading: true,
  };
}

export function isExternalReviewExportedFileExpiredSuccess(data) {
  return {
    type: IS_EXTERNAL_REVIEW_EXPORTED_FILE_EXPIRED_SUCCESS,
    isExternalReviewExportedFileExpired: data.isExternalReviewExportedFileExpired,
    loading: false,
  };
}

export function requestExternalReviewProcessing(periodId, cloudRef) {
  return {
    type: REQUEST_EXTERNAL_REVIEW_PROCESSING,
    payload: {
      periodId: periodId,
      cloudReference: cloudRef,
      loading: true,
    },
  };
}

export function requestExternalReviewProcessingSuccess() {
  return {
    type: REQUEST_EXTERNAL_REVIEW_PROCESSING_SUCCESS,
    loading: false,
  };
}

export function cancelExternalReview() {
  return {
    type: CANCEL_EXTERNAL_REVIEW,
  };
}