import React, { Fragment } from 'react';
import { withStyles, withTheme, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { strings } from 'Constants/Categorization/Strings';
import { styles } from './ReferenceListsList.styles';
import ReferenceListItem from './ReferenceListItems/ReferenceListItem';

class ReferenceListsList extends React.PureComponent {
  render() {
    const {
      classes,
      referenceLists,
      isReadOnly,
    } = this.props;
    return (
      <Fragment>
        <If condition={!referenceLists.loading}>
          <Grid container direction="row" spacing={8} className={classes.grid}>
            <If condition={!referenceLists.referenceLists.length}>
              <div className={classes.searchMatchesNoRefListsMessage}>
                <p className={classes.simpleMessage}>{strings.noRefListsFound}</p>
              </div>
            </If>
            <For each="refList" of={referenceLists.referenceLists}>
              <Grid item xl={4} lg={4} md={6} xs={12} key={`{grid_${refList.name}}`}>
                <ReferenceListItem
                  allowDrag={!isReadOnly}
                  referenceLists={refList}
                  key={refList.name}
                />
              </Grid>
            </For>
          </Grid>
        </If>
      </Fragment>
    );
  }
}


ReferenceListsList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  referenceLists: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    referenceLists: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })),
  }).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(ReferenceListsList);
