import { CREATING_NEW_PROJECT, POST_PROJECT, POST_PROJECT_SUCCESS, CANCEL_PROJECT_CREATION, EDIT_PROJECT, EDIT_LOAD_PROJECT_SUCCESS, DELETE_PROJECT } from 'Store/Areas/Setup/Admin/ProjectActions';

const initialState = {
  showForm: false,
  loading: false,
  isEdit: false,
  payload: {
    id: -1,
  },
  selection: {
    selectedGroup: { id: 0 },
    selectedEntity: { id: 0, currencyId: 0 },
  },
};

export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case CREATING_NEW_PROJECT:
    case EDIT_PROJECT:
      return Object.assign({}, state, {
        showForm: action.showForm,
        isEdit: action.isEdit,
        loading: action.loading,
        payload: action.payload,
        selection: action.selection,
      });
    case CANCEL_PROJECT_CREATION:
      return initialState;
    case POST_PROJECT:
    case POST_PROJECT_SUCCESS:
    case EDIT_LOAD_PROJECT_SUCCESS:
      return Object.assign({}, state, {
        showForm: action.showForm,
        loading: action.loading,
        isEdit: action.isEdit,
        payload: action.payload,
      });
    case DELETE_PROJECT:
      return Object.assign({}, state, {
        showForm: action.showForm,
        payload: action.payload,
      });
    default:
      return state;
  }
}
