import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Close from 'Assets/Images/exit-ic.svg';
import MandatoryAsterisk from 'Components/Shared/MandatoryAsterisk/MandatoryAsterisk';
import SimpleDragCard, { constants } from 'Components/Shared/DragNDrop/SimpleDragCard';
import { withStyles, withTheme, IconButton } from '@material-ui/core';
import styles from './Tag.styles';

class Tag extends React.Component {
  render() {
    const {
      classes,
      tag,
      onClick,
      showDelete,
    } = this.props;

    return (
      <SimpleDragCard borderColor={constants.borderColor.grey}>
        {tag.name}
        <If condition={tag.isMandatory}>
          <MandatoryAsterisk />
        </If>
        <If condition={!tag.isMandatory && showDelete}>
          <IconButton className={classes.tagCloseIcon} onClick={onClick}>
            <img src={Close} alt="remove" />
          </IconButton>
        </If>
      </SimpleDragCard>
    );
  }
}

Tag.defaultProps = {
  onClick: () => {},
  showDelete: true,
};

Tag.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  tag: PropTypes.shape({
    name: PropTypes.string,
    isMandatory: PropTypes.bool,
  }).isRequired,
  showDelete: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(Tag);

