export const isCtrlKeyActive = e => e.metaKey || e.ctrlKey;
export const isShiftKeyActive = e => e.shiftKey;

// taken from example on https://codesandbox.io/s/9j897k0mwy
export function onMultiSelectionChange(
  items,
  selection,
  ctrlKeyActive,
  shiftKeyActive,
  previousSelections,
  previousActiveSelection,
  selectionSelector = i => i,
) {
  let selections = [];
  let activeSelection;

  // CTRL-click handler
  if (ctrlKeyActive) {
    if (previousSelections.indexOf(selection) > -1) {
      selections = previousSelections.filter(x => x !== selection);
    } else {
      selections = [...previousSelections, selection];
    }
    activeSelection = selection;
    // SHIFT-click handler
  } else if (shiftKeyActive && selection !== previousActiveSelection) {
    let activeIndex = 0;

    if (previousActiveSelection === null) {
      activeSelection = selectionSelector(items[0]);
    } else {
      activeIndex = items.findIndex(i => selectionSelector(i) === previousActiveSelection);
      activeSelection = previousActiveSelection;
    }

    const selectionIndex = items.findIndex(i => selectionSelector(i) === selection);
    const lowerIndex = Math.min(activeIndex, selectionIndex);
    const upperIndex = Math.max(activeIndex, selectionIndex);
    selections = items.slice(lowerIndex, upperIndex + 1).map(i => selectionSelector(i));
  } else {
    selections = [selection];
    activeSelection = selection;
  }

  const newState = {
    selectedItems: items.filter(i => selections.includes(selectionSelector(i))),
    selections,
  };

  if (activeSelection) {
    newState.activeSelection = activeSelection;
  }

  return newState;
}
