export const styles = theme => ({
  root: {
    height: 64,
    width: 250,
    boxShadow: '0 2px 4px 0 rgba(194, 194, 194, 0.5)',
    backgroundColor: theme.palette.colors.white,
    padding: '0 10px',
    margin: '0 5px',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.small,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: 5,
    whiteSpace: 'nowrap',
  },
  dropzoneContainer: {
    height: 30,
    marginTop: 10,
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 3,
    border: 'dashed 1px #d9d9d9',
    textAlign: 'center',
  },
  amount: {
    fontSize: theme.constants.fontSizes.small,
  },
  dropzoneContent: {
    textAlign: 'center',
    flexGrow: 1,
    lineHeight: '30px',
    fontSize: theme.constants.fontSizes.small,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  removeButton: {
    outline: 'none',
    background: 'transparent',
    border: 'hidden',
    cursor: 'pointer',
  },
  blockHovered: {
    backgroundColor: `${theme.palette.primary.messageBackground} !important`,
    opacity: '0.8 !important',
  },
});

