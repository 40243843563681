export const GET_SETTINGS_DATA = 'SETTINGS/GET_SETTINGS_DATA';
export const GET_SETTINGS_DATA_SUCCESS = 'SETTINGS/GET_SETTINGS_DATA_SUCCESS';
export const UPDATE_SETTINGS_DATA = 'SETTINGS/UPDATE_SETTINGS_DATA';
export const UPDATE_SETTINGS_DATA_SUCCESS = 'SETTINGS/UPDATE_SETTINGS_DATA_SUCCESS';
export const GET_SITE_MESSAGE = 'SETTINGS/GET_SITE_MESSAGE';
export const GET_SITE_MESSAGE_SUCCESS = 'SETTINGS/GET_SITE_MESSAGE_SUCCESS';

export function getSettingsData() {
  return {
    type: GET_SETTINGS_DATA,
    data: [],
    loading: true,
  };
}

export function getSettingsDataSuccess(data) {
  return {
    type: GET_SETTINGS_DATA_SUCCESS,
    data: data,
    loading: false,
  };
}

export function getSiteMessage() {
  return {
    type: GET_SITE_MESSAGE,
    siteMessage: '',
    loading: true,
  };
}

export function getSiteMessageSuccess(data) {
  return {
    type: GET_SITE_MESSAGE_SUCCESS,
    siteMessage: data,
    loading: false,
  };
}

export function updateSettingsData(payload) {
  return {
    type: UPDATE_SETTINGS_DATA,
    payload: payload,
    loading: true,
  };
}

export function updateSettingsDataSuccess(data) {
  return {
    type: UPDATE_SETTINGS_DATA_SUCCESS,
    data: data,
    loading: false,
  };
}