import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import styles from './Blur.styles';
import CircularLoader from '../Loaders/CircularLoader';

class Blur extends React.PureComponent {
  render() {
    const {
      children,
      rootClassName,
      classes,
      blur,
      lightBackground,
      displayLoader,
    } = this.props;

    return (
      <div className={`${blur ? classes.noOverflow : ''} ${rootClassName}`}>
        <div className={`${blur ? classes.blurred : ''} ${lightBackground ? classes.lightBackground : classes.darkBackground}`}>
          <If condition={blur && displayLoader}>
            <CircularLoader className={classes.loader} size={60} />
          </If>
        </div>
        {children}
      </div>
    );
  }
}

Blur.defaultProps = {
  rootClassName: '',
  blur: false,
  lightBackground: false,
  displayLoader: false,
};

Blur.propTypes = {
  rootClassName: PropTypes.string,
  children: PropTypes.element.isRequired,
  blur: PropTypes.bool,
  lightBackground: PropTypes.bool,
  displayLoader: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};


export default compose(withStyles(styles))(Blur);

