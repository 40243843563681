import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { commonStrings } from 'Constants/CommonStrings';
import { strings } from 'Constants/Categorization/Strings';
import { referenceListsSelector } from 'Store/Areas/Categorisation/ReferenceListSelectors';
import { setFileUploadResponse, uploadAndReplaceReferenceList } from 'Store/Areas/Categorisation/ReferenceListActions';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import { styles } from './DuplicateReferenceListModal.styles';

class DuplicateReferenceListModal extends PureComponent {
  onDuplicateUploadCancel = () => {
    const { dispatch } = this.props;
    dispatch(setFileUploadResponse());
  }

  onDuplicateUploadConfirm = () => {
    const {
      dispatch,
      periodId,
      referenceLists: { fileUploadResponse: { cloudReference } },
    } = this.props;
    dispatch(uploadAndReplaceReferenceList(periodId, cloudReference));
  }

  render() {
    const {
      referenceLists,
      classes,
    } = this.props;

    if (!referenceLists.fileUploadResponse.duplicates ||
      !referenceLists.fileUploadResponse.cloudReference) { return null; }

    return (
      <ConfirmationModal
        open={!!referenceLists.fileUploadResponse.duplicates}
        title={strings.referenceLists}
        acceptText={commonStrings.replaceAll}
        onCancel={this.onDuplicateUploadCancel}
        onConfirm={this.onDuplicateUploadConfirm}
      >
        <div>
          {strings.referenceListExists}
          <ul>
            {// eslint-disable-next-line react/no-array-index-key
              referenceLists.fileUploadResponse.duplicates.map((x, i) => <li key={`duplicate_ref_list_${i}`} className={classes.duplicates}>{x}</li>)
            }
          </ul>
          {strings.referenceListsReplaceQuestion}
        </div>
      </ConfirmationModal>
    );
  }
}

DuplicateReferenceListModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  referenceLists: PropTypes.shape({
    fileUploadResponse: PropTypes.shape({
      duplicates: PropTypes.arrayOf(PropTypes.string),
      cloudReference: PropTypes.string,
    }).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    referenceLists: referenceListsSelector(state),
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(DuplicateReferenceListModal);
