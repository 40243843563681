import { withStyles, withTheme, Paper, TextField } from '@material-ui/core';
import { strings } from 'Constants/Categorization/CategoryReview/EditRuleOverlayConstants';
import { strings as categorizationStrings } from 'Constants/Categorization/Strings';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { currentRouteSelector } from 'Store/Areas/Router/RouteSelectors';
import { resetFailure } from 'Store/Areas/Categorisation/CategoryActions';
import { processingSelector } from 'Store/Areas/Rule/CategorisationSelectors';
import { closeEditingRule } from 'Store/Areas/Rule/EditRuleActions';
import { isReadOnlySelector } from 'Store/Areas/Period/PeriodSelectors';
import { categoryListTypes } from 'Constants/Categorization/CategoryListTypes';
import { rulePriorities } from 'Constants/Categorization/RulePriorities';
import { selectMappedTagsFromPeriod } from 'Constants/Categorization/Tags';
import ErrorModal from 'Components/Shared/Modal/ErrorModal';
import Blur from 'Components/Shared/Blur/Blur';
import { OperatorsPanel } from 'Components/Categorization/Pages/CustomRules/RulesBuilder/Components';
import RulesBuilder from 'Components/Categorization/Pages/CustomRules/RulesBuilder/RulesBuilder';
import FullPageModal from 'Components/Shared/Modal/FullPageModal/FullPageModal';
import CategoryList from 'Components/Categorization/Shared/CategoryList/CategoryList';
import CategoryCreationModal from 'Components/Categorization/Modals/CategoryCreationModal/CategoryCreationModal';
import CategoryDeletionModal from 'Components/Categorization/Modals/CategoryDeletionModal/CategoryDeletionModal';
import { formatPercentage } from 'Helpers/NumberHelpers';
import AddElementsRow from 'Components/Categorization/Pages/CustomRules/RulesBuilder/Components/AddElementsRow/AddElementsRow';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import styles from './EditRuleOverlay.styles';

class EditRuleOverlay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      note: props.ruleNote,
    };
  }

  onChange = (event) => {
    this.setState({
      note: event.target.value,
    });
  }
  handleClose = () => {
    this.props.dispatch(closeEditingRule());
  }
  handleErrorClose = () => {
    this.props.dispatch(resetFailure());
  }
  componentWillUnmount() {
    this.props.dispatch(closeEditingRule());
  }
  renderCategoriesApportionmentForRule = ({ categoryName, apportionmentPercentage }) => (
    <div key={categoryName}>
      {categoryName}: {formatPercentage(apportionmentPercentage)}
    </div>
  );

  render() {
    if (!this.props.showEditRuleOverlay) {
      return null;
    }

    const {
      classes,
      rule,
      period: { data },
      category,
      tags,
      processing,
      isApportionable,
    } = this.props;

    return (
      <FullPageModal
        open
        onClose={this.handleClose}
        title={strings.title}
      >
        {strings.editRuleText}
        <Paper className={classes.content}>
          <Blur
            rootClassName={classes.innerContent}
            blur={processing}
            displayLoader={processing}
            lightBackground
          >
            <div className={classes.grid}>
              <If condition={data && data.categories}>
                <div className={classes.categories}>
                  <div className={classes.categoriesListWrapper}>
                    <CategoryList
                      categoryType={categoryListTypes.categoryType.customRule}
                      currencySymbol={this.props.currencySymbol}
                      editable={false}
                    />
                  </div>
                </div>
                <Choose>
                  <When condition={!category.success && category.errorMessage}>
                    <ErrorModal
                      open
                      onClose={this.handleErrorClose}
                      error={categorizationStrings.categoryErrorMessage(category.errorMessage)}
                      title={categorizationStrings.categoryErrorTitle}
                    />
                  </When>
                  <Otherwise>
                    <CategoryCreationModal />
                    <CategoryDeletionModal />
                  </Otherwise>
                </Choose>
              </If>
              <div className={classes.builder}>
                <div className={classes.rulesBuilderWrapper}>
                  <RulesBuilder
                    note={this.state.note}
                    initialRuleChunks={rule.ruleChunks}
                    initialRule={rule.keywordRule}
                    initialRuleId={rule.id}
                    tags={tags}
                    isApportionable={isApportionable}
                  />
                </div>
                <div className={classes.buttons}><AddElementsRow /></div>
                <div className={classes.ruleInfoWrapper}>
                  <span className={classes.boldText}>{strings.currentApportionmentText}</span>
                  <div className={`${classes.ruleInfo} ${classes.ruleInfoList}`}>
                    {rule.categoriesApportionmentForRule
                      .map(this.renderCategoriesApportionmentForRule)}
                  </div>
                  <div className={classes.boldText}>{strings.currentPriorityText}</div>
                  <div>
                    <div className={classes.ruleInfo}>
                      {rulePriorities.find(x => x.value === rule.priority).label}
                    </div>
                  </div>
                  <span className={classes.boldText}>{strings.notes}</span>
                  <div>
                    <TextField
                      className={classes.textField}
                      defaultValue={rule.notes}
                      placeholder={categorizationStrings.customRuleNotePlaceholder}
                      margin="dense"
                      InputProps={{
                        classes: {
                          input: classes.textSize,
                        },
                        inputProps: {
                          maxLength: '255',
                        },
                      }}
                      variant="outlined"
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.operators}>
                <OperatorsPanel
                  tags={tags}
                  isReadOnly={this.props.isReadOnly || !rule.ruleChunks.length}
                />
              </div>
            </div>
          </Blur>
        </Paper>
      </FullPageModal>
    );
  }
}

EditRuleOverlay.defaultProps = {
  rule: {},
  ruleNote: '',
};

EditRuleOverlay.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  period: PropTypes.shape({
    data: PropTypes.shape({
      categories: PropTypes.array,
      status: PropTypes.string,
    }),
  }).isRequired,
  rule: PropTypes.shape({
    keywordRule: PropTypes.string,
    notes: PropTypes.string,
    addedBy: PropTypes.string,
    dateCreated: PropTypes.string,
    value: PropTypes.number,
    priority: PropTypes.number,
  }),
  category: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    adding: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  processing: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  showEditRuleOverlay: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  ruleNote: PropTypes.string,
  currencySymbol: PropTypes.string.isRequired,
  isApportionable: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    period: state.periods.period,
    rule: state.rules.inspectRule.inspectedRule,
    category: state.categorisation.category,
    processing: processingSelector(state),
    isReadOnly: isReadOnlySelector(state),
    currentRoute: currentRouteSelector(state),
    tags: selectMappedTagsFromPeriod(state),
    currencySymbol: currencySymbolSelector(state),
    isApportionable: state.categorisation.reviewCategory.isApportionable,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(EditRuleOverlay);
