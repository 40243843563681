import { POST_REMOVE_ACCESS, POST_REMOVE_ACCESS_SUCCESS, POST_REMOVE_ACCESS_FAILURE, RESET_REMOVE_ACCESS, TOGGLE_REMOVE_ACCESS_MODAL } from 'Store/Areas/Setup/Admin/RemoveAccessActions';

const initialState = {
  loading: false,
  success: false,
  errorMessage: '',
  showModal: false,
};

export default function removeAccessReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_REMOVE_ACCESS:
    case POST_REMOVE_ACCESS:
    case POST_REMOVE_ACCESS_SUCCESS:
    case POST_REMOVE_ACCESS_FAILURE:
    case TOGGLE_REMOVE_ACCESS_MODAL:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        errorMessage: action.errorMessage,
        showModal: action.showModal,
      });
    default:
      return state;
  }
}
