import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { toggleSidebar } from 'Store/Areas/Projects/UserTreePickerActions';
import { userInAdminMode } from 'Helpers/RouteHelpers';
import { getPeriodStatusDescription } from 'Constants/PeriodStatuses';
import { styles } from './NavigationBreadcrumb.styles.';

class NavigationBreadcrumb extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      groupName: '',
      entityName: '',
      projectName: '',
      periodName: '',
      periodStatus: '',
      periodIsLocked: '',
      periodIsInUse: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Update breadcrumbs
    const { userTreePicker, treePicker } = props.projects;

    const group = treePicker.data.find(y =>
      y.id === userTreePicker.selectedGroupId);
    const entity = group
      ? group.entities.find(y =>
        y.id === userTreePicker.selectedEntityId)
      : undefined;
    const project = entity
      ? entity.projects.find(y =>
        y.id === userTreePicker.selectedProjectId)
      : undefined;
    const period = project
      ? project.periods.find(y =>
        y.id === userTreePicker.selectedPeriodId)
      : undefined;

    const newState = {
      groupName: group ? group.name : undefined,
      entityName: entity ? entity.name : undefined,
      projectName: project ? project.name : undefined,
      periodName: period ? period.name : undefined,
      periodStatus: period ? period.status : undefined,
      periodIsLocked: period ? period.isLocked : undefined,
      periodIsInUse: period ? period.inUse : undefined,
    };

    if (newState === state) {
      return null;
    }

    return newState;
  }

  toggleDrawer = () => {
    const { collapsed, isCollapsible, dispatch } = this.props;
    if (isCollapsible) {
      dispatch(toggleSidebar(!collapsed));
    }
  }

  render() {
    const {
      groupName,
      entityName,
      projectName,
      periodName,
      periodStatus,
      periodIsLocked,
      periodIsInUse,
    } = this.state;

    const {
      classes,
      className,
      route,
    } = this.props;

    const isAdminMode = userInAdminMode(route.location.pathname);

    const periodStatusText = getPeriodStatusDescription({
      status: periodStatus,
      isLocked: periodIsLocked,
      inUse: periodIsInUse,
    });

    if (isAdminMode || !periodName) {
      return (<div />);
    }

    return (
      <div className={`${classes.root} ${className}`}>
        <span className={classes.breadcrumb}>
          {groupName} \ {entityName} \&nbsp;
          {projectName} \ {periodName}&nbsp;
          {periodStatusText && `(${periodStatusText})`}
          <button className={classes.button} onClick={this.toggleDrawer}>
            <span className={classes.change}>Change</span>
          </button>
        </span>
      </div>
    );
  }
}

NavigationBreadcrumb.defaultProps = {
  className: '',
};

NavigationBreadcrumb.propTypes = {
  dispatch: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  isCollapsible: PropTypes.bool.isRequired,
  className: PropTypes.string,
  projects: PropTypes.shape({ // eslint-disable-line
    treePicker: PropTypes.shape({
      data: PropTypes.array,
    }).isRequired,
    userTreePicker: PropTypes.shape({
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
      selectedProjectId: PropTypes.number,
      selectedPeriodId: PropTypes.number,
    }).isRequired,
  }).isRequired,
  route: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    route: state.router,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(NavigationBreadcrumb);
