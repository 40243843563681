import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import DragnDrop from 'Constants/DragnDrop';
import { withStyles, withTheme } from '@material-ui/core';
import { DragSource } from 'react-dnd';
import { strings } from 'Constants/Rules/Strings';
import DragIcon from 'Assets/Images/drag-icon.svg';
import withDndPreviewContext from 'Components/Shared/DragNDrop/DndPreviewContextProvider';
import { styles } from './DragRuleHandle.styles';

class DragRuleHandle extends React.Component {
  componentDidMount() {
    this.props.connectDragPreview(this.props.dndPreview);
  }

  render() {
    const {
      connectDragSource, classes, hasError, isVisible,
    } = this.props;

    return (
      <If condition={isVisible}>
        <div className={`${classes.root} ${hasError ? classes.error : classes.valid}`}>
          {!hasError && connectDragSource(<div className={classes.dragLayer} />)}
          <div>
            <div className={classes.dragText}>{strings.drag}</div>
            <img src={DragIcon} alt="" className={classes.icon} />
          </div>
        </div>
      </If>
    );
  }
}

DragRuleHandle.defaultProps = {
  isVisible: true,
};

DragRuleHandle.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  hasError: PropTypes.bool.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  // DragnDrop-only props
  ruleText: PropTypes.string.isRequired, // eslint-disable-line
  note: PropTypes.string.isRequired, // eslint-disable-line
  dndPreview: PropTypes.object.isRequired, // eslint-disable-line
  isVisible: PropTypes.bool,
};

// Implements the drag source contract.
const cardSource = {
  beginDrag(props) {
    return {
      text: props.ruleText,
      note: props.note,
      ruleId: props.ruleId,
      isApportionable: props.isApportionable,
    };
  },
  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const { onDrop } = monitor.getDropResult();
      onDrop();
    }
  },
};

// Specifies the props to inject into your component
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

export default compose(
  withTheme(),
  withStyles(styles),
  DragSource(DragnDrop.customRule, cardSource, collect),
  withDndPreviewContext(),
)(DragRuleHandle);
