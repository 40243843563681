import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  withStyles,
  withTheme,
  Paper,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import UploadBreadcrumb, { constants } from 'Components/Shared/UploadBreadcrumb/UploadBreadcrumb';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { periodIdSelector, hasSupportingFilesSelector } from 'Store/Areas/Period/PeriodSelectors';
import { getImportFailedRules } from 'Store/Areas/Import/ImportFailedRulesActions';
import { updatePeriodStatus } from 'Store/Areas/Period/PeriodActions';
import { periodStatuses } from 'Constants/PeriodStatuses';
import Button, { constants as buttonConstants } from 'Components/Shared/Buttons/Button';
import Sidebar from 'Components/UserSidebar/Sidebar';
import redCross from 'Assets/Images/exit-ic-red.svg';
import { strings } from 'Constants/FailedRules/Strings';
import { title } from 'Constants/App/Titles/Strings';
import { styles } from './FailedRulesReview.styles';

class FailedRulesReview extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tableHeaders: strings.failedRulesTableHeaders,
    };
  }

  componentDidMount() {
    document.title = title.failedRulesReview;
    this.props.dispatch(getTreePickerData());
    this.loadFailedRules();
  }

  getCell(row, headerName) {
    if (headerName === strings.failedRulesTableHeaders[0]) {
      return row.keywordRule;
    } else if (headerName === strings.failedRulesTableHeaders[1]) {
      return row.categoryName;
    } else if (headerName === strings.failedRulesTableHeaders[2]) {
      return row.rulePriority;
    }
    return '';
  }

  onProceedClick = () => {
    const { periodId, dispatch, hasSupportingFiles } = this.props;
    if (hasSupportingFiles) {
      dispatch(updatePeriodStatus(periodId, periodStatuses.supportingDocumentsSetup));
    } else {
      dispatch(updatePeriodStatus(periodId, periodStatuses.contrasSetup));
    }
  }

  loadFailedRules = () => {
    const { periodId, dispatch } = this.props;
    dispatch(getImportFailedRules(periodId));
  }

  render() {
    const {
      classes,
      projects,
      importFailedRules,
    } = this.props;

    const {
      tableHeaders,
    } = this.state;

    const treePickerLoading = projects.treePicker.loading;
    const failedRulesPresent = importFailedRules.failedRules.length !== 0;

    return (
      <UserSidebarStructure
        mainContent={
          <div className={classes.failedRulesListWrapper}>
            <UploadBreadcrumb index={constants.indexes.failedRules} />
            <Paper className={classes.paperContainer}>
              <Grid container direction="row" alignItems="stretch">
                <Grid container justify="flex-end">
                  <If condition={failedRulesPresent}>
                    <Grid item xs={12} >
                      <div className={classes.title}>
                        <div className={classes.boldText}>
                          <img alt="X" className={classes.cross} src={redCross} />
                          {strings.failedRulesFound}
                        </div>
                        <br />
                        {strings.failedRulesAmount(importFailedRules.failedRules.length)}
                        <br />
                        {strings.failedRulesPresentMessage}
                      </div>
                    </Grid>
                    <Grid item xs={12} >
                      <Table>
                        <TableHead>
                          <TableRow>
                            {tableHeaders.map((col, index) => {
                              const headerKey = `${col}_${index}`;
                              return (
                                <TableCell
                                  className={`${classes.tableCell} ${classes.headerText}`}
                                  key={headerKey}
                                >
                                  {col}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <For each="row" of={importFailedRules.failedRules}>
                            <TableRow key={row.id}>
                              <For each="header" of={tableHeaders}>
                                <TableCell className={`${classes.cell}`} key={`${row.id}_${header}`}>
                                  {this.getCell(row, header)}
                                </TableCell>
                              </For>
                            </TableRow>
                          </For>
                        </TableBody>
                      </Table>
                    </Grid>
                  </If>
                </Grid>
              </Grid>
              <div className={classes.flexContainer}>
                <Button
                  onClick={this.onProceedClick}
                  className={classes.proceedButton}
                  height={buttonConstants.height.big}
                >
                  <span className={classes.label}>
                    {strings.continue}
                  </span>
                </Button>
              </div>
            </Paper>
          </div>
        }
        sidebarContent={<Sidebar />}
        sidebarContentLoading={false}
        mainContentLoading={treePickerLoading}
        isCollapsible
        collapsed
      />
    );
  }
}

FailedRulesReview.propTypes = {
  periodId: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  importFailedRules: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    failedRules: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  hasSupportingFiles: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    periodId: periodIdSelector(state),
    importFailedRules: state.import.importFailedRules,
    hasSupportingFiles: hasSupportingFilesSelector(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTheme(),
)(FailedRulesReview);