import {
  REQUEST_BULK_GROUP_ENTITY_PROJECT_UPLOAD,
  REQUEST_BULK_GROUP_ENTITY_PROJECT_UPLOAD_SUCCESS,
  SET_BULK_GROUP_ENTITY_PROJECT_UPLOAD_STAGE,
  RESET_BULK_GROUP_ENTITY_PROJECT_UPLOAD_STATE,
} from 'Store/Areas/BulkUpload/BulkGroupEntityProjectUploadActions';
import { bulkGroupEntityProjectUploadImportStages } from 'Constants/BulkUpload/BulkGroupEntityProjectUploadStages';

const initialState = {
  loading: false,
  stage: bulkGroupEntityProjectUploadImportStages.uploading,
  importMessages: [],
  hasImportMessages: false,
  userId: -1,
};

export default function bulkUploadReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_BULK_GROUP_ENTITY_PROJECT_UPLOAD:
      return Object.assign({}, state, {
        loading: true,
      });
    case REQUEST_BULK_GROUP_ENTITY_PROJECT_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        userId: action.userId,
      });
    case SET_BULK_GROUP_ENTITY_PROJECT_UPLOAD_STAGE:
      return Object.assign({}, state, {
        stage: action.stage,
        importMessages: action.importMessages,
        hasImportMessages: action.importMessages && action.importMessages.length > 0,
        loading: action.stage === bulkGroupEntityProjectUploadImportStages.uploading
          || action.stage === bulkGroupEntityProjectUploadImportStages.passedScan,
      });
    case RESET_BULK_GROUP_ENTITY_PROJECT_UPLOAD_STATE:
      return initialState;
    default:
      return state;
  }
}
