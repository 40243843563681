import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';

class ExternalReviewModal extends React.PureComponent {
  render() {
    const {
      onConfirm, onCancel, isModalOpen, title, message, acceptText,
    } = this.props;

    return (
      <ConfirmationModal
        open={isModalOpen}
        title={title}
        message={message}
        acceptText={acceptText}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
      />
    );
  }
}

ExternalReviewModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  acceptText: PropTypes.string.isRequired,
};

export default (ExternalReviewModal);
