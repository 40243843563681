const DragnDrop = {
  keyword: 'keyword',
  referenceListItem: 'referenceListItem',
  allSearchResultsLineItem: 'allSearchResultsLineItem',
  customRule: 'customRule',
  operator: {
    brackets: 'brackets',
    tag: 'tag',
    operator: 'operator',
    join: 'join',
    keyword: 'keyword',
  },
  customRuleBrackets: {
    start: 'startBrackets',
    end: 'endBrackets',
  },
  customRuleSegment: 'customRuleSegment',
  mappingTag: 'mappingTag',
  lineItem: {
    uncategorised: 'uncategorised',
    supportingDocuments: 'supportingDocuments',
  },
};

export default DragnDrop;
