import { put } from 'redux-saga/effects';
import { getApportionableItems } from 'Store/Areas/Apportionables/ApportionablesActions';
import { userActions } from 'Constants/UserActions';
import { apportionmentStrings } from 'Components/Apportionables/ApportionablesStrings';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import SignalrStrategyHandler from './SignalrStrategyHandler';

function* handleApportionablesEvent(payload, action) {
  if (payload.error) {
    yield this.handleError(action);
  } else {
    yield put(getPeriod(action.selectedPeriodId, true));
    yield put(getApportionableItems(action.selectedPeriodId, 0, 1, -1, 0, null));
    yield put(setProcessing(false));
    yield put(displayToastNotification(apportionmentStrings.calculationCompleted));
  }
}

export const genericApportionablesHandler = new SignalrStrategyHandler(
  [userActions.ApportionmentCalculationProcess],
  function* handler(payload, action) {
    yield handleApportionablesEvent(payload, action);
  },
);