const styles = theme => ({
  root: {},
  label: {
    opacity: 0.6,
    color: theme.palette.colors.white,
    fontSize: theme.constants.fontSizes.small,
  },
  img: {
    height: 15,
    width: 15,
  },
  calendarIcon: {
    paddingTop: 5,
    paddingRight: 10,
    height: 15,
    width: 15,
    color: theme.palette.colors.white,
    cursor: 'pointer',
  },
  disabled: {
    cursor: 'default',
    opacity: 0.6,
  },
  hiddenInput: {
    display: 'none',
  },
});

export default styles;
