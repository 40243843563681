export const strings = {
  identifyContras: 'Identify contras',
  identifyContrasDescription:
    'From the list of tags below, please select the mapped columns that you would like to use to identify ' +
    'potential contras. You may choose up to five tags, including Amount, which must always be selected.' +
    'When you are ready, click the ‘identify contras’ to review a list of contras matched from the columns selected below. ',
  skipThisStep: 'Skip this step',
  mappedTags: 'Mapped tags',
  dataType: 'Data type',
  reviewContrasDescription: 'Below is a list of contras that have been found using the selected columns you identified. ' +
    'Please review the list of contras below that have been removed from the main dataset. You can delete contras that ' +
    'you do not agree with using the ‘x’. Any line items deleted will return to the main dataset. You can click proceed ' +
    'at any time and can also review these contras during the categorisation stage.',
  reviewCategoryContrasDescription: 'Below is a list of contras that have been found using the selected columns you identified.',
  removeContraMessage: 'Removing a contra will add it back to the main data set. If any current rules include these line items then they will be allocated to the corresponding categories.',
  removeContraSuccessfulMessage: 'Contra removed successfully',
  removeContra: 'Remove contra group',
  noContras: 'There are no contras identified that match the search terms selected. To edit the search criteria return to ' +
  'the previous screen by using the back button or if you no longer wish to identify contras at this stage, please skip this step.',
  noContrasReview: 'There are no contras identified that match the search terms selected.',
  highestValue: 'Highest value first',
  lowestValue: 'Lowest value first',
  sortBy: 'Sort by',
  searchContras: 'Search',
  searchPlaceholder: 'Start typing to search',
  totalContrasInfo: (totalContras, totalLinesWithinContras) => `${totalContras} contras containing ${totalLinesWithinContras} lines identified`,
  maxLineItemsInContraSelector: 'Maximum number of line items in a contra',
};

export const contraTagColumnHeaders = ['', strings.mappedTags, strings.dataType];
export const rowHeight = 50;
