import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { templateProcessingStages, templateImportStages } from 'Constants/Templates/TemplateProcessingStages';
import { templateMessageArray } from 'Constants/Templates/Strings';
import ProgressBar from 'Components/Processing/ProgressBar/ProgressBar';
import ProcessingStatus from 'Components/Shared/ProcessingStatus/ProcessingStatus';
import UploadTemplateResult from './UploadTemplateResult';
import FailedScanModal from './FailedScanModal';

class TemplateProcessingContainer extends React.PureComponent {
  render() {
    const {
      currentStage,
    } = this.props;

    return (
      <Fragment>
        <Choose>
          <When condition={
            currentStage === templateImportStages.uploading ||
            currentStage === templateImportStages.passedScan}
          >
            <ProcessingStatus
              processingMessageArray={templateMessageArray}
              renderProgressBar={() => (<ProgressBar
                stage={currentStage}
                processingStages={templateProcessingStages}
              />)}
            />
          </When>
          <When condition={currentStage === templateImportStages.failedScan}>
            <FailedScanModal />
          </When>
          <Otherwise>
            <UploadTemplateResult />
          </Otherwise>
        </Choose>
      </Fragment>
    );
  }
}

TemplateProcessingContainer.propTypes = {
  currentStage: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    currentStage: state.templateUpload.stage,
  };
}

export default connect(mapStateToProps)(TemplateProcessingContainer);
