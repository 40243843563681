export const styles = () => ({
  progressBar: {
    margin: '30px auto 0 auto',
    width: '65%',
    border: '4px solid white',
    marginBottom: 40,
  },
  cancelImportButton: {
    cursor: 'pointer',
    fontWeight: 'bold',
    border: 'none',
    width: '15%',
    marginBottom: '40px',
  },
  cancelImportDiv: {
    textAlign: 'center',
  },
});
