import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles, withTheme, Grid } from '@material-ui/core';
import { routes } from 'Constants/Routes';
import { push } from 'connected-react-router';
import { summaryString } from 'Constants/Categorization/CategoryReview/CategoryReviewConstants';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import DonutChart from 'Components/CategoryReview/DonutChart';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Sidebar from 'Components/UserSidebar/Sidebar';
import ArrowButton from 'Components/Shared/Buttons/ArrowButton/ArrowButton';
import DashboardTab from 'Components/Shared/DashboardTab/DashboardTab';
import withLineItemMetadata from 'Components/Shared/LineItemMetadataProvider/LineItemMetadataProvider';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { formatCurrencyString } from 'Helpers/NumberHelpers';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import { strings } from 'Constants/Categorization/CategoryReview/Strings';
import { title } from 'Constants/App/Titles/Strings';
import { AutoSizer } from 'react-virtualized';
import styles from './CategoryReview.styles';
import CategoryReviewRuleList from './RuleList/CategoryReviewRuleList';
import PopularKeywords from './PopularKeywords/PopularKeywords';
import InspectRuleOverlay from './InspectRuleOverlay';


class CategoryReview extends React.PureComponent {
  componentDidMount() {
    const {
      category,
      setMetadataOptions,
    } = this.props;

    document.title = title.categoryReview(category.name);
    this.props.dispatch(getTreePickerData());

    setMetadataOptions({
      categoryId: category.id,
    });
  }

  onOpenRuleReview = () => {
    this.props.dispatch(push(routes.lineItemsCategorisationReview.rules));
  }

  onOpenMlReview = () => {
    this.props.dispatch(push(routes.lineItemsCategorisationReview.ml));
  }

  onOpenManualReview = () => {
    this.props.dispatch(push(routes.lineItemsCategorisationReview.manual));
  }

  render() {
    const {
      category,
      classes,
      projects,
      popularKeywords,
      amountValue,
      mlMetadata,
      manualMetadata,
      ruleMetadata,
      currencySymbol,
    } = this.props;

    const sidebarLoading = projects.treePicker.loading;

    return (
      <UserSidebarStructure
        isCollapsible
        collapsed
        sidebarLoading={sidebarLoading}
        mainContentLoading={sidebarLoading}
        sidebarContent={
          <Sidebar />
        }
        mainContent={
          <div className={classes.root}>
            <InspectRuleOverlay currencySymbol={currencySymbol} />
            <Choose>
              <When condition={category.name.toLowerCase() === strings.apportionable.toLowerCase()
                && category.color === strings.apportionableColor}
              >
                <ArrowButton to={routes.apportionableManager} />
              </When>
              <Otherwise>
                <ArrowButton to={routes.categorisation.referenceLists} />
              </Otherwise>
            </Choose>
            <Grid container direction="row" className={classes.body} spacing={8}>
              <Grid item xs={12}>
                <If condition={popularKeywords}>
                  <span className={classes.title}>
                    {summaryString(
                      category.name,
                      amountValue,
                      currencySymbol,
                      popularKeywords.lineCount,
                    )}
                  </span>
                </If>
              </Grid>
              <Grid item xs={12} lg={6}>
                <PopularKeywords currencySymbol={currencySymbol} />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Choose>
                  <When condition={!popularKeywords}>
                    <CircularLoader />
                  </When>
                  <Otherwise>
                    <If condition={popularKeywords}>
                      <DonutChart
                        currencySymbol={currencySymbol}
                        category={category}
                        popularkeywords={popularKeywords}
                      />
                    </If>
                  </Otherwise>
                </Choose>
              </Grid>
              <Grid container direction="row" className={classes.tabGrid} spacing={16} alignContent="stretch">
                <Grid item xs={12} className={classes.lineItemsTitle}>
                  {strings.lineItems}
                </Grid>
                <Grid item xs={6}>
                  <DashboardTab
                    title={strings.categorisedByRules}
                    content={[
                      `${strings.value}${formatCurrencyString(ruleMetadata.amount, currencySymbol)}`,
                      `${strings.categorisedLineItems}${ruleMetadata.count}`,
                    ]}
                    onClick={this.onOpenRuleReview}
                    hideChevron={ruleMetadata.count <= 0}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DashboardTab
                    title={strings.machineLearning}
                    content={[
                      `${strings.value}${formatCurrencyString(mlMetadata.amount, currencySymbol)}`,
                      `${strings.categorisedLineItems}${mlMetadata.count}`,
                    ]}
                    onClick={this.onOpenMlReview}
                    hideChevron={mlMetadata.count <= 0}
                  />
                </Grid>
                <Grid item xs={3}>
                  <DashboardTab
                    title={strings.manuallyCategorised}
                    content={[
                      `${strings.value}${formatCurrencyString(manualMetadata.amount, currencySymbol)}`,
                      `${strings.categorisedLineItems}${manualMetadata.count}`,
                    ]}
                    onClick={this.onOpenManualReview}
                    hideChevron={manualMetadata.count <= 0}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <AutoSizer>
                  {({ width }) => (
                    <div style={{ width: width }}>
                      <CategoryReviewRuleList width={width} currencySymbol={currencySymbol} />
                    </div>
                  )}
                </AutoSizer>
              </Grid>
            </Grid>
          </div >
        }
      />
    );
  }
}

CategoryReview.defaultProps = {
  popularKeywords: null,
};

CategoryReview.propTypes = {
  dispatch: PropTypes.func.isRequired,
  category: PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  amountValue: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  popularKeywords: PropTypes.shape({
    lineCount: PropTypes.number.isRequired,
    result: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string,
      value: PropTypes.number,
    })).isRequired,
  }),
  mlMetadata: PropTypes.shape({
    count: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  manualMetadata: PropTypes.shape({
    count: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  ruleMetadata: PropTypes.shape({
    count: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  setMetadataOptions: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { category } = state.categorisation.reviewCategory;
  return {
    projects: state.projects,
    category: category,
    amountValue: state.periods.period.data.categories.find(x => x.id === category.id).amountValue,
    popularKeywords: state.categorisation.reviewCategory.popularKeywords,
    periodId: state.periods.period.periodId,
    currencySymbol: currencySymbolSelector(state),
  };
}

export default compose(
  withLineItemMetadata({
    includeMl: true,
    includeRule: true,
    includeManual: true,
  }),
  withTheme(),
  withStyles(styles),
  connect(mapStateToProps),
)(CategoryReview);
