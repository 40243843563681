import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { checkCategoryCanBeDeleted } from 'Store/Areas/Categorisation/DeleteCategoryActions';
import Modal from 'Components/Shared/Modal/StyledModal';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import WrappedInput from 'Components/Shared/Inputs/Input';
import { getCreateCategorySchema } from 'Constants/Categorization/CategorySchema';
import { commonStrings } from 'Constants/CommonStrings';
import { strings } from 'Constants/Categorization/Strings';
import { postNewCategory, cancelAdding, postEditCategory } from 'Store/Areas/Categorisation/CategoryActions';
import styles from './CategoryCreationModal.styles';
import ColorPicker from './ColorPicker';

class CategoryCreationModal extends React.PureComponent {
  onCancel() {
    this.props.dispatch(cancelAdding());
  }

  createCategory(values) {
    const { period, dispatch } = this.props;
    const request = Object.assign({}, values, { periodId: period.data.id });
    dispatch(postNewCategory(request));
  }

  editCategory(values) {
    const { period, dispatch, category } = this.props;
    if (values.name === category.payload.name &&
      values.colorCode === category.payload.color) {
      this.onCancel();
      return;
    }
    const request = Object.assign(
      {},
      values,
      {
        periodId: period.data.id,
        categoryId: category.payload.id,
      },
    );
    dispatch(postEditCategory(request));
  }

  deleteCategory() {
    const { category, period, dispatch } = this.props;
    dispatch(checkCategoryCanBeDeleted(category.payload.id, period.data.id));
  }

  render() {
    const {
      category,
      deleteCategory,
      period,
      classes,
    } = this.props;
    const { isEdit } = category;
    let categoryNames = period.data.categories.map(x => x.name);
    if (isEdit) {
      categoryNames = categoryNames.filter(x => x !== category.payload.name);
    }
    return (
      <Modal open={category.adding && !deleteCategory.deleting} isSubmitting={category.loading}>
        <Formik
          initialValues={{
            name: isEdit ? category.payload.name : '',
            colorCode: isEdit ? category.payload.color : '',
          }}
          validationSchema={getCreateCategorySchema(categoryNames)}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            if (isEdit) {
              this.editCategory(values);
            } else {
              this.createCategory(values);
            }
          }}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.content}>
                <div className={classes.title}>
                  {isEdit ? strings.editCategory : strings.addNewCategory}
                  <If condition={isEdit}>
                    <button className={classes.delete} type="button" onClick={() => this.deleteCategory()}>
                      {strings.deleteCategory}
                    </button>
                  </If>
                </div>
                <WrappedInput
                  id="name"
                  value={values.name}
                  onChange={(field, value) => setFieldValue(field, value)}
                  touched={touched.name}
                  error={touched.name && errors.name}
                  className={classes.input}
                  placeholder={strings.enterCategoryNameHere}
                />
                <span className={`${classes.text} ${(errors.colorCode && touched.colorCode) && classes.red}`}>
                  {isEdit ? strings.editCategoryColour : strings.pleaseAssignAColour}
                </span>
                <ColorPicker
                  onChange={value => setFieldValue('colorCode', value)}
                  value={values.colorCode}
                />
              </div>
              <Buttons
                isSubmitting={category.loading}
                onAccept={handleSubmit}
                onCancel={() => this.onCancel()}
                acceptText={isEdit ? commonStrings.saveChanges : strings.createCategory}
                cancelText={commonStrings.cancel}
              />
            </form>
            )}
        />
      </Modal>
    );
  }
}

CategoryCreationModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  period: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    data: PropTypes.object,
  }).isRequired,
  deleteCategory: PropTypes.shape({
    deleting: PropTypes.bool.isRequired,
  }).isRequired,
  category: PropTypes.shape({
    isEdit: PropTypes.bool,
    payload: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string,
    }),
    loading: PropTypes.bool,
    success: PropTypes.bool,
    adding: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    period: state.periods.period,
    category: state.categorisation.category,
    deleteCategory: state.categorisation.deleteCategory,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(CategoryCreationModal);
