import Yup from 'yup';
import { strings } from 'Constants/Upload/TagMapping';
import { allowedCharacters, beginsOrEndsInSpaces, caseInsensitiveDuplicateTestOptions } from '../ValidationHelpers';

export function getAddTagSchema(tagNames, tagTypes) {
  return (Yup.object().shape({
    newTagName: Yup.string()
      .required(strings.tagNameRequired)
      .lowercase()
      .test(caseInsensitiveDuplicateTestOptions(
        tagNames,
        strings.tagNameExists,
      ))
      .matches(allowedCharacters, strings.forbiddenCharacters)
      .matches(beginsOrEndsInSpaces, strings.beginOrEndSpaces)
      .max(50, strings.tagNameLength),
    dataType: Yup.string()
      .oneOf(tagTypes, strings.dataTypeRequired)
      .required(strings.dataTypeRequired),
  }));
}
