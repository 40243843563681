import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import DragnDrop from 'Constants/DragnDrop';
import { DragSource } from 'react-dnd';
import { withStyles } from '@material-ui/core';
import SimpleDragCard, { constants } from 'Components/Shared/DragNDrop/SimpleDragCard';
import withDndPreviewContext from 'Components/Shared/DragNDrop/DndPreviewContextProvider';
import { styles } from './Operator.styles';

class Operator extends React.PureComponent {
  componentDidMount() {
    this.props.connectDragPreview(this.props.dndPreview);
  }

  getCursorClassName = () => {
    const { classes, canDrag, isCopyTextAvailable } = this.props;
    if (canDrag) {
      return '';
    }
    if (isCopyTextAvailable) {
      return classes.textCursor;
    }
    return classes.readOnly;
  }

  render() {
    const {
      children,
      borderColor,
      connectDragSource,
      classes,
      canDrag,
    } = this.props;

    return (
      <div className={classes.root}>
        {canDrag && connectDragSource(<div className={classes.dragLayer} />)}
        <SimpleDragCard
          borderColor={borderColor}
          className={this.getCursorClassName()}
        >
          {children}
        </SimpleDragCard>
      </div>
    );
  }
}

Operator.defaultProps = {
  borderColor: constants.borderColor.grey,
  metadata: {},
  canDrag: true,
  isCopyTextAvailable: false,
};

Operator.propTypes = {
  children: PropTypes.string.isRequired,
  borderColor: PropTypes.oneOf(Object.values(constants.borderColor)),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isCopyTextAvailable: PropTypes.bool,
  canDrag: PropTypes.bool,
  /* Drag and drop */
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  metadata: PropTypes.shape({ // eslint-disable-line react/no-unused-prop-types
    name: PropTypes.string,
    id: PropTypes.number,
    type: PropTypes.string,
  }),
  type: PropTypes.oneOf(Object.values(DragnDrop.operator)).isRequired, //eslint-disable-line
  dndPreview: PropTypes.object.isRequired, // eslint-disable-line
};

// Implements the drag source contract.
const cardSource = {
  beginDrag(props) {
    return {
      children: props.children,
      borderColor: props.borderColor,
      type: props.type,
      metadata: props.metadata,
      canDelete: false,
    };
  },
  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const { onDrop } = monitor.getDropResult();
      onDrop(props.children, props.metadata);
    }
  },
};

// Specifies the props to inject into your component
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

export default compose(
  DragSource(x => x.type, cardSource, collect),
  withStyles(styles),
  withDndPreviewContext(),
)(Operator);
