import { put, select } from 'redux-saga/effects';
import { userActions } from 'Constants/UserActions';
import { strings } from 'Constants/Banner/Strings';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import SignalrStrategyHandler from './SignalrStrategyHandler';

function* handlePeriodResetEvent(payload, action) {
  if (payload.error) {
    yield this.handleError(action);
  } else {
    const selectedPeriodId = yield select(state => state.projects.userTreePicker.selectedPeriodId);
    if (selectedPeriodId === payload.periodId
      && payload.periodLockReason === 1) {
      yield put(getPeriod(action.selectedPeriodId, true));
      yield put(setProcessing(false));
      yield put(displayToastNotification(strings.periodReleaseText));
    }
  }
}

export const periodResetHandler = new SignalrStrategyHandler(
  [userActions.ResetPeriod],
  function* handler(payload, action) {
    yield handlePeriodResetEvent(payload, action);
  },
);