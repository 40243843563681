import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withTheme, withStyles } from '@material-ui/core';
import { strings } from 'Constants/Banner/Strings';
import PeriodControlOverlay from 'Components/Projects/PeriodControlOverlay';
import { currentRouteSelector } from 'Store/Areas/Router/RouteSelectors';
import {
  periodIdSelector,
  changeSupportingDocsAfterPeriodCreationSelector,
  periodControlNotificationSelector,
  periodLockedByUserSelector } from 'Store/Areas/Period/PeriodSelectors';
import { setPeriodInUse } from 'Store/Areas/Period/PeriodActions';
import { periodControlStatuses, periodControlStatusText } from 'Constants/PeriodControlStatuses';
import styles from './LockedPeriodBanner.styles';

class LockedPeriodBanner extends React.Component {
  state = {
    isTakePeriodControlModalOpen: false,
    isErrorModalOpen: false,
  };

  componentDidUpdate(prevProps) {
    const {
      notification,
    } = this.props;

    if (notification && prevProps.notification !== notification
      &&
      periodControlStatusText(notification.periodControlStatus) === periodControlStatuses.denied) {
      this.showErrorModal();
    }
  }

  showErrorModal = () => {
    this.setState({
      isErrorModalOpen: true,
    });
  }
  onResetImportClick = (event) => {
    this.props.deleteImportFile();
    event.stopPropagation();
  }

  onTakeControlClick = () => {
    this.setState({
      isTakePeriodControlModalOpen: true,
    });
  }

  onKeyPress = () => {
    this.setState({
      isTakePeriodControlModalOpen: true,
    });
  }

  onTakeControlConfirm = () => {
    const { periodId, dispatch } = this.props;
    this.setState({
      isTakePeriodControlModalOpen: false,
    });
    dispatch(setPeriodInUse(periodId, periodControlStatuses.requested));
  }

  onTakeControlCancel = () => {
    this.setState({
      isTakePeriodControlModalOpen: false,
    });
  }

  onErrorModalClose = () => {
    this.setState({
      isErrorModalOpen: false,
    });
  }

  getLockedBannerText = () => {
    const {
      changeSupportingDocsAfterPeriodCreation,
    } = this.props;

    if (changeSupportingDocsAfterPeriodCreation) {
      return strings.lockedBannerTextWhenChangingSupportingDocs;
    }
    return strings.lockedBannerText;
  }

  render() {
    const { isTakePeriodControlModalOpen, isErrorModalOpen } = this.state;
    const {
      classes,
      shouldHide,
      inUse,
      isLocked,
      externalReviewLocked,
      currentRoute,
      permissions,
      projects,
      notification,
      userName,
      periodLockedByUser,
    } = this.props;
    return (
      <Fragment>
        <div className={inUse || isLocked ? classes.lockedBanner : classes.externalReviewBanner}>
          <Choose>
            <When condition={inUse}>
              <span className={classes.lockedBannerText}>{strings.inUseText(userName)}
                <If condition={!permissions.projectsCanAccessAsReadOnly
                    .includes(projects.userTreePicker.selectedProjectId)}
                >
                  <span className={classes.linkText} onClick={this.onTakeControlClick} onKeyPress={this.onKeyPress} role="button" tabIndex="0">
                    {strings.requestControl}
                  </span>
                </If>
              </span>
            </When>
            <When condition={isLocked}>
              <If condition={!externalReviewLocked}>
                <span className={classes.lockedBannerText}>
                  {this.getLockedBannerText()}
                </span>
              </If>
              <If condition={externalReviewLocked}>
                <span className={classes.lockedBannerText}>
                  {strings.externalReviewBannerText(periodLockedByUser ? periodLockedByUser.emailAddress : '')}
                  <If condition={currentRoute === '/externalReview'}>
                    <span className={shouldHide ? classes.hidden : classes.linkText} onClick={this.onResetImportClick} role="presentation">
                      {strings.reuploadCompletedData}
                    </span>
                  </If>
                </span>
              </If>
            </When>
          </Choose>
        </div>
        <PeriodControlOverlay
          onConfirm={this.onTakeControlConfirm}
          onCancel={this.onTakeControlCancel}
          isModalOpen={isTakePeriodControlModalOpen}
          isErrorModalOpen={isErrorModalOpen}
          onClose={this.onErrorModalClose}
          userName={notification.userName}
        />
      </Fragment>
    );
  }
}

LockedPeriodBanner.defaultProps = {
  shouldHide: false,
  inUse: false,
  isLocked: false,
  deleteImportFile: () => {},
  periodId: 0,
  changeSupportingDocsAfterPeriodCreation: false,
  externalReviewLocked: false,
  userName: '',
  periodLockedByUser: {},
};

LockedPeriodBanner.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  shouldHide: PropTypes.bool,
  deleteImportFile: PropTypes.func,
  periodId: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  inUse: PropTypes.bool,
  isLocked: PropTypes.bool,
  changeSupportingDocsAfterPeriodCreation: PropTypes.bool,
  externalReviewLocked: PropTypes.bool,
  currentRoute: PropTypes.string.isRequired,
  permissions: PropTypes.shape({
    projectsCanAccessAsReadOnly: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  projects: PropTypes.shape({
    userTreePicker: PropTypes.shape({
      selectedProjectId: PropTypes.number,
    }),
  }).isRequired,
  notification: PropTypes.shape({
    periodName: PropTypes.string,
    userName: PropTypes.string,
  }).isRequired,
  userName: PropTypes.string,
  periodLockedByUser: PropTypes.shape({
    emailAddress: PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
    changeSupportingDocsAfterPeriodCreation: changeSupportingDocsAfterPeriodCreationSelector(state),
    currentRoute: currentRouteSelector(state),
    permissions: state.user.permissions.data,
    projects: state.projects,
    notification: periodControlNotificationSelector(state),
    periodLockedByUser: periodLockedByUserSelector(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withTheme(),
  withStyles(styles),
)(LockedPeriodBanner);