import React from 'react';
import {
  withStyles,
  Dialog,
  LinearProgress,
} from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styles from './StyledModal.styles';

class StyledModal extends React.PureComponent {
  render() {
    const {
      children,
      open,
      className,
      isSubmitting,
      classes,
      width,
      fullWidth,
    } = this.props;
    return (
      <Dialog
        open={open}
        className={className}
        transitionDuration={0}
        maxWidth={width}
        fullWidth={fullWidth}
      >
        <div className={classes.modalProgress}>
          <LinearProgress hidden={!isSubmitting} />
        </div>
        {children}
      </Dialog>
    );
  }
}

StyledModal.defaultProps = {
  children: '',
  className: '',
  isSubmitting: false,
  width: 'sm',
  fullWidth: false,
};

StyledModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  fullWidth: PropTypes.bool,
};

export default compose(withStyles(styles))(StyledModal);
