const styles = theme => ({
  root: {
    color: theme.palette.primary.darkText,
    backgroundColor: theme.palette.colors.white,
    boxShadow: '0 3px 4px 0 rgba(138, 138, 138, 0.5)',
  },
  linkContainer: {
    display: 'block',
  },
  linkButton: {
    fontWeight: 'normal',
  },
  closeButtonForLink: {
    position: 'absolute',
    top: 6,
    right: 6,
    padding: 5,
  },
  closeButton: {
    position: 'absolute',
    top: 13,
    right: 6,
    padding: 5,
  },
});

export default styles;
