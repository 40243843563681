// MUST be in sync with KPMG.K_Analyser.Services.Constants/CategoryColors.cs
const colors = {
  red: {
    color: '#BC204B',
  },
  yellow: {
    color: '#EAAA00',
  },
  mediumBlue: {
    color: '#005EB8',
  },
  darkGreen: {
    color: '#457525',
  },
  purple: {
    color: '#470A68',
  },
  green: {
    color: '#00A3A1',
  },
  orange: {
    color: '#DE8626',
  },
  violet: {
    color: '#483698',
  },
  pink: {
    color: '#C6007E',
  },
  lightGreen: {
    color: '#43B02A',
  },
  lightYellow: {
    color: '#FFDA5A',
  },
  olive: {
    color: '#9D9375',
  },
  rose: {
    color: '#E2A0C5',
  },
};

export default colors;
