import { takeLatest, put } from 'redux-saga/effects';
import { Post, Get } from 'Store/Api/CallApi';
import {
  POST_GROUP,
  EDIT_GROUP,
  DELETE_GROUP,
  postNewGroupSuccess,
  cancelGroupCreation,
  editLoadGroupSuccess,
} from 'Store/Areas/Setup/Admin/GroupActions';
import { ADMIN_POST_GROUP_URL, ADMIN_GET_GROUP_URL, ADMIN_DELETE_GROUP } from 'Store/Api/ApiEndpoints';
import {
  getAdminTreePickerData,
  editNode,
} from 'Store/Areas/Setup/Admin/AdminTreePickerActions';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { levels } from 'Constants/EntityLevels';

function* postGroup(action) {
  const response = yield Post(ADMIN_POST_GROUP_URL, action.payload);
  if (response.ok) {
    const data = yield response.json();
    if (data.success) {
      yield put(postNewGroupSuccess());
      yield put(getAdminTreePickerData());
      yield put(editNode(levels.group, data.addedItem));
    } else {
      cancelGroupCreation();
      window.location.replace('/error');
    }
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getGroup(action) {
  const response = yield Get(ADMIN_GET_GROUP_URL(action.payload.id));
  if (response.ok) {
    const data = yield response.json();
    yield put(editLoadGroupSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* deleteGroup(action) {
  const response = yield Post(ADMIN_DELETE_GROUP, action.payload);
  if (response.ok) {
    yield put(getAdminTreePickerData(true));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* groupSagas() {
  yield takeLatest(POST_GROUP, postGroup);
  yield takeLatest(EDIT_GROUP, getGroup);
  yield takeLatest(DELETE_GROUP, deleteGroup);
}
