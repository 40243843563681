export const GET_USERS = 'GETUSER/GET_USERS';
export const GET_USERS_SUCCESS = 'GETUSER/GET_USERS_SUCCESS';
export const ADD_SELECTED_USER = 'GETUSER/ADD_SELECTED_USER';
export const REMOVE_SELECTED_USER = 'GETUSER/REMOVE_SELECTED_USER';
export const RESET_USERS = 'GETUSER/RESET_USERS';

export function getUsers(itemLevel, itemId, emailFilter) {
  return {
    type: GET_USERS,
    loading: true,
    data: [],
    payload: {
      entityLevel: itemLevel,
      entityLevelId: itemId,
      emailFilter: emailFilter,
    },
    selectedUserIds: [],
  };
}

export function addSelectedUser(selectedUserId) {
  return {
    type: ADD_SELECTED_USER,
    selectedUserId: selectedUserId,
  };
}

export function removeSelectedUser(selectedUserId) {
  return {
    type: REMOVE_SELECTED_USER,
    selectedUserId: selectedUserId,
  };
}

export function getUsersSuccess(data) {
  return {
    type: GET_USERS_SUCCESS,
    data: data,
    loading: false,
    selectedUserIds: [],
  };
}

export function resetUsers() {
  return {
    type: RESET_USERS,
  };
}
