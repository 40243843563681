import {
  MAP_TAG,
  MAP_TAG_SUCCESS,
  TOGGLE_MANDATORY_TAGS_MODAL,
  DRAG_TAG,
  AUTO_MAP_SUPPORTING_TAGS,
  AUTO_MAP_SUPPORTING_TAGS_SUCCESS,
} from 'Store/Areas/Import/TagMappingActions';
import { GET_PERIOD_SUCCESS } from 'Store/Areas/Period/PeriodActions';

const initialState = {
  loading: false,
  success: false,
  errorMessage: '',
  showMandatoryTagModal: false,
  dragTagType: null,
  mappingTagId: -1,
  autoMapLoading: false,
};

export default function addTagReducer(state = initialState, action) {
  switch (action.type) {
    case MAP_TAG:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        errorMessage: action.errorMessage,
        showMandatoryTagModal: action.showMandatoryTagModal,
        mappingTagId: action.mappingTagId,
      });
    case TOGGLE_MANDATORY_TAGS_MODAL:
    case MAP_TAG_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        errorMessage: action.errorMessage,
        showMandatoryTagModal: action.showMandatoryTagModal,
      });
    case DRAG_TAG:
      return Object.assign({}, state, {
        dragTagType: action.dragTagType,
      });
    case GET_PERIOD_SUCCESS:
      return Object.assign({}, state, {
        mappingTagId: initialState.mappingTagId,
      });
    case AUTO_MAP_SUPPORTING_TAGS:
    case AUTO_MAP_SUPPORTING_TAGS_SUCCESS:
      return Object.assign({}, state, {
        autoMapLoading: action.loading,
      });
    default:
      return state;
  }
}
