export const styles = theme => ({
  root: {
    position: 'relative',
    paddingTop: 20,
  },
  title: {
    fontSize: theme.constants.fontSizes.small,
    fontWeight: 'bold',
    marginLeft: 20,
  },
});
