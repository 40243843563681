import { put } from 'redux-saga/effects';
import { userActions } from 'Constants/UserActions';
import { reportProcessingProgress } from 'Store/Areas/Import/ProcessActions';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import SignalrStrategyHandler from './SignalrStrategyHandler';

export const importProgressHandler = new SignalrStrategyHandler(
  userActions.Import,
  function* handler(payload, action) {
    if (payload.error) {
      yield this.handleError(action);
    } else {
      yield put(reportProcessingProgress(
        payload.message,
        payload.importStage,
        payload.forceClientPoll,
      ));
      if (payload.forceClientPoll) {
        yield put(getPeriod(action.selectedPeriodId));
      }
    }
  },
);
