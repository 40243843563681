import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme, IconButton } from '@material-ui/core';
import DragnDrop from 'Constants/DragnDrop';
import { strings } from 'Constants/Categorization/Strings';
import { isMultiKeywordOperator } from 'Helpers/OperatorHelpers';
import Close from 'Assets/Images/exit-ic.svg';
import { styles } from './BuilderRow.styles';
import { BuilderCell, BuilderInput, withRulesBuilder, BuilderDragHandle, BuilderRowDropzone } from '../';

class BuilderRow extends React.PureComponent {
  onKeywordDrop = (keyword) => {
    const {
      onConditionChanged,
      ruleSegment: {
        id,
        data: {
          condition,
          operator,
          tag,
        },
      },
    } = this.props;

    let newCondition = keyword;

    if (isMultiKeywordOperator(operator, tag.type) && condition) {
      newCondition = `${condition}","${newCondition}`;
    }

    if (condition !== newCondition) {
      onConditionChanged(newCondition, id);
    }
  }

  render() {
    const {
      width,
      ruleSegment,
      onTagChanged,
      onOperatorChanged,
      onJoinChanged,
      onConditionChanged,
      onRemoveSegment,
      onRemoveJoin,
      onRemoveTag,
      onRemoveOperator,
      onMoveSegment,
      classes,
      index,
      setIsOver,
      isOver,
      hoveredIndex,
      draggingIndex,
      keywordPanelAvailable,
    } = this.props;

    const {
      operator,
      join,
      condition,
      tag,
    } = ruleSegment.data;

    const isDragging = index === draggingIndex;
    const rootWidth = width - 20;
    const handleWidth = 15;
    const removeWidth = 20;
    const boxWidth = (rootWidth - handleWidth - removeWidth) / 4;

    return (
      <div className={`${isDragging ? classes.hidden : classes.root}`} style={{ width: rootWidth, marginTop: isOver ? 40 : 0 }}>
        <BuilderRowDropzone
          setIsOver={setIsOver}
          index={index}
          isOver={isOver}
        />
        <BuilderDragHandle
          width={rootWidth}
          style={{ width: handleWidth }}
          index={index}
          condition={condition}
          tag={tag.name}
          operator={operator}
          join={join}
          id={ruleSegment.id}
          setIsOver={setIsOver}
          hoveredIndex={hoveredIndex}
          onDrop={onMoveSegment}
          className={`${classes.borderLeftRadius} ${tag.name ? classes.complete : `${classes.incomplete} ${classes.borderLeft}`}`}
          isComplete={!!tag.name}
        />
        <BuilderCell
          id={ruleSegment.id}
          type={DragnDrop.operator.tag}
          width={boxWidth}
          className={`${classes.box} ${tag.name ? classes.complete : classes.incomplete}`}
          onDrop={onTagChanged}
          onRemove={onRemoveTag}
          dragMessage={strings.dragTag}
        >
          {tag.name}
        </BuilderCell>
        <BuilderCell
          id={ruleSegment.id}
          type={DragnDrop.operator.operator}
          width={boxWidth}
          className={`${classes.box} ${operator ? classes.complete : classes.incomplete}`}
          onDrop={onOperatorChanged}
          onRemove={onRemoveOperator}
          dragMessage={strings.dragOperator}
        >
          {operator}
        </BuilderCell>
        <BuilderInput
          id={ruleSegment.id}
          width={boxWidth}
          className={`${classes.box} ${condition ? classes.complete : classes.incomplete}`}
          onChange={onConditionChanged}
          placeholder={strings.typeKeyword}
          tag={tag}
          operator={operator}
          onDrop={this.onKeywordDrop}
          keywordPanelAvailable={keywordPanelAvailable}
        >
          {condition}
        </BuilderInput>
        <BuilderCell
          id={ruleSegment.id}
          type={DragnDrop.operator.join}
          width={boxWidth}
          className={`${classes.box} ${join ? classes.complete : `${classes.incomplete} ${classes.borderRight}`} ${classes.borderRightRadius}`}
          onDrop={onJoinChanged}
          dragMessage={strings.dragJoin}
          onRemove={onRemoveJoin}
        >
          {join}
        </BuilderCell>
        <IconButton onClick={() => onRemoveSegment(ruleSegment.id, index)} className={classes.icon}>
          <img src={Close} alt="remove" />
        </IconButton>
      </div>
    );
  }
}

BuilderRow.defaultProps = {
  keywordPanelAvailable: false,
};


BuilderRow.propTypes = {
  width: PropTypes.number.isRequired,
  ruleSegment: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.shape({
      tag: PropTypes.shape({
        name: PropTypes.string,
      }),
      operator: PropTypes.string,
      condition: PropTypes.string,
      join: PropTypes.string,
    }),
  }).isRequired,
  hoveredIndex: PropTypes.number.isRequired,
  onTagChanged: PropTypes.func.isRequired,
  onOperatorChanged: PropTypes.func.isRequired,
  onJoinChanged: PropTypes.func.isRequired,
  onConditionChanged: PropTypes.func.isRequired,
  onRemoveSegment: PropTypes.func.isRequired,
  onRemoveJoin: PropTypes.func.isRequired,
  onRemoveOperator: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  onMoveSegment: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  draggingIndex: PropTypes.number.isRequired,
  setIsOver: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  keywordPanelAvailable: PropTypes.bool,
};

export default compose(withStyles(styles), withTheme(), withRulesBuilder())(BuilderRow);
