import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Paper } from '@material-ui/core';
import { getCurrentRulePriority } from 'Store/Areas/Rule/RulePriorityActions';
import { strings } from 'Constants/Conflicts/Strings';
import { formatPercentageString } from 'Helpers/NumberHelpers';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import CheckboxLineItem from 'Components/Shared/CheckboxLineItem/CheckboxLineItem';
import EditPriorityModal from 'Components/Categorization/Modals/EditPriorityModal/EditPriorityModal';
import Check from 'Assets/Images/small-check-ic.svg';
import Circle from 'Assets/Images/oval.svg';
import { styles } from './ConflictResolver.styles';
import ConflictTable from './ConflictTable/ConflictTable';

class ConflictResolver extends React.PureComponent {
  state = {
    selectedRules: [],
  };

  updateSelectedRules(toggledRuleId) {
    const { selectedRules } = this.state;

    const newSelectedRules = [...selectedRules];
    const index = newSelectedRules.indexOf(toggledRuleId);

    if (index === -1) {
      newSelectedRules.push(toggledRuleId);
    } else {
      newSelectedRules.splice(index, 1);
    }

    this.setState({
      selectedRules: newSelectedRules,
    });
  }

  toggleEditPriorityModal(selectedRuleId) {
    const {
      dispatch,
      periodId,
      periodConflicts: { conflicts },
      getRuleText,
      rulePriority: { open },
    } = this.props;
    if (!open) {
      const rule = conflicts.find(x => x.id === selectedRuleId);
      dispatch(getCurrentRulePriority(periodId, selectedRuleId, getRuleText(rule)));
    }
  }

  calculatePercentageChange(prev, curr) {
    if (prev === 0) {
      return null;
    }

    return Math.abs((curr - prev) / prev);
  }

  renderRemainingConflictsNote() {
    const {
      classes,
      periodConflicts: {
        prevTotalNumberOfConflicts,
        totalNumberOfConflicts,
      },
    } = this.props;

    return (
      <p className={classes.noteBold}>
        {strings.remainingConflictsNotePart1(totalNumberOfConflicts)}
        <If condition={prevTotalNumberOfConflicts !== 0}>
          <With isIncrease={totalNumberOfConflicts >= prevTotalNumberOfConflicts}>
            <span className={isIncrease ? classes.increase : classes.decrease}>
              {strings.remainingConflictsNotePart2(
                prevTotalNumberOfConflicts,
                isIncrease,
                formatPercentageString(this.calculatePercentageChange(
                  prevTotalNumberOfConflicts,
                  totalNumberOfConflicts,
                )),
              )}
            </span>
          </With>
        </If>
        {strings.remainingConflictsNotePart3}
      </p>
    );
  }

  render() {
    const {
      classes,
      onResolve,
      onDelete,
      onAllow,
      onAllAllow,
      currencySymbol,
      periodConflicts: { conflicts },
      rulePriority: { open },
      getRuleText,
    } = this.props;

    const {
      selectedRules,
    } = this.state;

    const hasNoSelection = !selectedRules.length;
    const hasOnlyOneSelection = selectedRules.length === 1;
    const hasAllSelections = selectedRules.length === conflicts.length;

    return (
      <div>
        {open && <EditPriorityModal isConflictAction />}
        <div className={classes.title}>{strings.conflictingRules}</div>
        <Paper className={classes.root}>
          <div className={classes.description}>
            <p>{strings.description}</p>
            {this.renderRemainingConflictsNote()}
          </div>
          <div className={classes.rulesContainer}>
            <For each="rule" of={conflicts}>
              <CheckboxLineItem
                key={rule.id}
                active={selectedRules.includes(rule.id)}
                onClick={() => this.updateSelectedRules(rule.id)}
                text={getRuleText(rule)}
                renderSelectionIcon={({ active }) => (
                  <React.Fragment>
                    <If condition={active}><img alt="checked" src={Check} /></If>
                    <If condition={!active}><img alt="disabled" src={Circle} /></If>
                  </React.Fragment>
                )}
              />
            </For>
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              className={`${classes.button} ${!hasOnlyOneSelection && classes.disabledButton}`}
              backgroundColor={constants.backgroundColor.dark}
              onClick={() =>
                this.toggleEditPriorityModal(selectedRules[0])
              }
              disabled={!hasOnlyOneSelection}
              title={strings.editButtonTooltip}
            >
              {strings.editRulePriority}
            </Button>
            <Button
              className={`${classes.button} ${hasNoSelection && classes.disabledButton}`}
              backgroundColor={constants.backgroundColor.red}
              onClick={() => onDelete(selectedRules)}
              disabled={hasNoSelection}
              title={strings.deleteButtonTooltip}
            >
              {strings.deleteButton}
            </Button>
            <Button
              className={`${classes.button} ${!hasAllSelections && classes.disabledButton}`}
              backgroundColor={constants.backgroundColor.green}
              onClick={() => onAllow(selectedRules)}
              disabled={!hasAllSelections}
              title={strings.allowButtonTooltip}
            >
              {strings.allowButton}
            </Button>
            <Button
              className={`${classes.button}`}
              backgroundColor={constants.backgroundColor.green}
              onClick={() => onAllAllow()}
              title={strings.allowAllButtonTooltip}
            >
              {strings.allowAllButton}
            </Button>
            <Button
              className={`${classes.button} ${!hasOnlyOneSelection && classes.disabledButton}`}
              backgroundColor={constants.backgroundColor.main}
              onClick={() => onResolve(selectedRules[0])}
              disabled={!hasOnlyOneSelection}
              title={strings.resolveButtonTooltip}
            >
              {strings.resolveConflictButton}
            </Button>
          </div>
          <div className={classes.conflictTable}>
            <ConflictTable conflicts={conflicts} currencySymbol={currencySymbol} />
          </div>
        </Paper>
      </div>
    );
  }
}

ConflictResolver.propTypes = {
  periodId: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  getRuleText: PropTypes.func.isRequired,
  onAllow: PropTypes.func.isRequired,
  onAllAllow: PropTypes.func.isRequired,
  onResolve: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  periodConflicts: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    conflicted: PropTypes.bool.isRequired,
    conflicts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      rule: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.shape({
        categoryId: PropTypes.number,
        categoryName: PropTypes.string,
      })),
    })).isRequired,
    prevTotalNumberOfConflicts: PropTypes.number.isRequired,
    totalNumberOfConflicts: PropTypes.number.isRequired,
  }).isRequired,
  rulePriority: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    ruleText: PropTypes.string,
    ruleId: PropTypes.number,
    priorityLevel: PropTypes.number,
    open: PropTypes.bool.isRequired,
  }).isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
  connect(),
)(ConflictResolver);
