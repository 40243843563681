export const styles = theme => ({
  headerGrid: {
    overflow: 'hidden !important',
    outline: 'none',
  },
  hideOutline: {
    outline: 'none',
  },
  loadingMask: {
    position: 'absolute',
    bottom: 0,
    opacity: 0.7,
    backgroundColor: theme.palette.colors.white,
  },
  loadingBox: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 10px 5px 10px',
    width: 200,
    height: 40,
    boxShadow: '0 0 5px 3px rgba(237, 237, 237, 0.5)',
    border: `solid 1px ${theme.palette.primary.greyBorder}`,
    backgroundColor: theme.palette.colors.white,
  },
  loader: {
    width: 30,
  },
  message: {
    fontSize: theme.constants.fontSizes.small,
    padding: 25,
  },
  hideVerticalScroll: {
    overflowY: 'hidden !important',
  },
  hideHorizontalScroll: {
    overflowX: 'hidden !important',
  },
  blankingContainer: {
    position: 'absolute',
    top: 0,
    height: '40px',
  },
});
