export const RESET_REMOVE_ACCESS = 'REMOVEACCESS/RESET_REMOVE_ACCESS';
export const POST_REMOVE_ACCESS = 'REMOVEACCESS/POST_REMOVE_ACCESS';
export const POST_REMOVE_ACCESS_SUCCESS = 'REMOVEACCESS/POST_REMOVE_ACCESS_SUCCESS';
export const POST_REMOVE_ACCESS_FAILURE = 'REMOVEACCESS/POST_REMOVE_ACCESS_FAILURE';
export const TOGGLE_REMOVE_ACCESS_MODAL = 'REMOVEACCESS/TOGGLE_REMOVE_ACCESS_MODAL';

export function toggleRemoveAccessModal(showModal) {
  return {
    type: TOGGLE_REMOVE_ACCESS_MODAL,
    loading: false,
    success: false,
    errorMessage: '',
    showModal: showModal,
  };
}

export function resetRemoveAccess() {
  return {
    type: RESET_REMOVE_ACCESS,
    loading: false,
    success: false,
    errorMessage: '',
    showModal: false,
  };
}

export function postRemoveAccess(
  selectedIds,
  entityLevelId,
  entityLevel,
  removingSelf,
  groupId,
  emails,
) {
  return {
    type: POST_REMOVE_ACCESS,
    loading: true,
    success: false,
    errorMessage: '',
    payload: {
      userIds: selectedIds,
      entityLevelId: entityLevelId,
      entityLevel: entityLevel,
      groupId: groupId,
      emails: emails,
    },
    removingSelf,
    showModal: true,
  };
}

export function postRemoveAccessSuccess() {
  return {
    type: POST_REMOVE_ACCESS_SUCCESS,
    loading: false,
    success: true,
    errorMessage: '',
    showModal: false,
  };
}

export function postRemoveAccessFailure(errorMessage) {
  return {
    type: POST_REMOVE_ACCESS_FAILURE,
    loading: false,
    success: false,
    errorMessage: errorMessage,
    showModal: true,
  };
}
