import { createMuiTheme } from '@material-ui/core/styles';
import brandingColors from './BrandingChartColors';

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#0091da',
      dark: '#00338d',
      darkAlt: '#003087',
      darkAltButton: '#1b3377',
      contrastText: '#ffffff',
      darkText: '#666666',
      error: '#bb1f4a',
      valid: '#43b02a',
      lightLabel: '#656565',
      messageBackground: '#f4f4f4',
      purple: '#460868',
      greyBorder: '#d4d4d4',
      background: '#efefef',
      orangeBanner: '#e09002',
      greenButton: '#009933',
      redButton: '#c92d2d',
    },
    colors: {
      white: '#ffffff',
      black: '#333333',
      grey: '#f6f6f6',
      darkGrey: '#dcdcdc',
      paleGrey: '#999999',
      mainLightened: '#0eaeff',
      darkLightened: '#0045c0',
      orange: '#e09002',
      green: '#009933',
      greenLightened: '#1fc857',
      blue: '#0091da',
      red: '#da0e0e',
      redLightened: '#ff0000',
    },
    brandingColors: brandingColors,
    graphs: {
      darkBlue: '#003087',
      blue: '#0091da',
      orange: '#e09002',
      green: '#009933',
      grey: '#dcdcdc',
    },
    highlightingColors: {
      lightBlue: '#dbe1ec',
    },
  },
  constants: {
    sidebarWidths: {
      primary: 120,
      secondary: 400,
      secondaryCollapsed: 40,
    },
    fontSizes: {
      small: 12,
      regular: 14,
      large: 20,
    },
  },
  overrides: {
    /* date picker overrides */
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#0091da',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#2c3135',
        '&$selected': {
          backgroundColor: '#1f3a8b',
        },
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#0091da',
      },
    },
    MuiButton: {
      root: {
        '&$disabled': {
          color: '#fff',
        },
      },
    },
    MuiSlider: {
      track: {
        borderRadius: 2,
        transition: 'none',
        '&$activated': {
          transition: 'none',
        },
      },
      trackAfter: {
        backgroundColor: '#CCC',
        transition: 'none',
        '&$activated, &$jumped': {
          backgroundColor: '#CCC',
          transition: 'none',
        },
      },
      thumb: {
        backgroundColor: '#f4f4f4',
        boxShadow: '0 2px 4px 0 #9e9e9e',
        height: 20,
        transition: 'none',
        width: 20,
        '&$activated': {
          height: 20,
          transition: 'none',
          width: 20,
        },
        '&$jumped': {
          height: 20,
          transition: 'none',
          width: 20,
        },
      },
    },
  },
  /* Shared classes */
  simpleMessage: {
    textAlign: 'center',
    color: '#666666',
    fontSize: 13,
  },
});
