import React from 'react';
import { DatePicker as Picker } from 'material-ui-pickers';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import moment from 'moment';
import { commonStrings } from 'Constants/CommonStrings';
import ChevronLeft from 'Assets/Images/chevron-left-ic.svg';
import ChevronRight from 'Assets/Images/chevron-right-ic.svg';
import Calendar from 'Assets/Images/calendar.svg';
import DatePickerInput from 'Components/Shared/Inputs/DatePickerInput';
import styles from './DatePicker.styles';

class DatePicker extends React.PureComponent {
  onDatePickerChange(date) {
    const newDate = date.format(this.props.inputFormat);
    this.props.onChange(newDate);
  }

  onKeyboardChange(event) {
    const newValue = moment(event.target.value, this.props.displayFormat, true);
    const onChangeValue = newValue.isValid()
      ? newValue.format(this.props.inputFormat)
      : event.target.value;

    this.props.onChange(onChangeValue);
  }

  onIconClicked() {
    if (!this.props.disabled) {
      this.picker.open();
    }
  }

  render() {
    const {
      id,
      value,
      label,
      classes,
      className,
      inputFormat,
      displayFormat,
      disabled,
    } = this.props;

    const momentValue = moment.isMoment(value) ? value : moment(value, inputFormat, true);
    const formattedValue = momentValue.isValid() ? momentValue.format(displayFormat) : value;

    return (
      <div className={className}>
        {label && (
          <div className={classes.label}>{label}</div>
        )}
        <DatePickerInput
          disabled={disabled}
          id={id}
          value={formattedValue}
          onChange={event => this.onKeyboardChange(event)}
          onIconClicked={() => this.onIconClicked()}
          placeholder="dd/mm/yyyy"
          iconComponent={
            <img
              src={Calendar}
              alt="Calendar"
              className={`${classes.calendarIcon} ${disabled && classes.disabled}`}
            />
          }
        />
        {!disabled && <Picker
          className={classes.hiddenInput}
          ref={(node) => { this.picker = node; }}
          leftArrowIcon={<img src={ChevronLeft} alt="Left" className={classes.img} />}
          rightArrowIcon={<img src={ChevronRight} alt="Right" className={classes.img} />}
          onChange={event => this.onDatePickerChange(event)}
          animateYearScrolling={false}
        />}
      </div>
    );
  }
}

DatePicker.defaultProps = {
  id: '',
  label: '',
  className: '',
  inputFormat: 'YYYY-MM-DDTHH:mm:ss',
  displayFormat: commonStrings.momentDateDisplayFormat,
  value: '',
  disabled: false,
};

DatePicker.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  inputFormat: PropTypes.string,
  displayFormat: PropTypes.string,
  disabled: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(DatePicker);
