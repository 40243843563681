import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import styles from './UploadDropzoneContent.styles';

class UploadDropzoneContent extends React.PureComponent {
  render() {
    const {
      classes, description, buttonDescription,
    } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.description}>
            {description}
          </div>
          <Button
            backgroundColor={constants.backgroundColor.dark}
            height={constants.height.regular}
            className={classes.button}
          >
            {buttonDescription}
          </Button>
        </div>
      </div >
    );
  }
}

UploadDropzoneContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  description: PropTypes.string.isRequired,
  buttonDescription: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withTheme())(UploadDropzoneContent);
