const styles = theme => ({
  successMessage: {
    fontSize: 30,
    textAlign: 'center',
  },
  successSecondMessage: {
    paddingTop: 10,
    fontSize: theme.constants.fontSizes.regular,
    textAlign: 'center',
  },
});

export default styles;
