import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Paper, Grid } from '@material-ui/core';
import { compose } from 'recompose';
import { connect as reduxConnect } from 'react-redux';
import CheckboxCheckedIcon from 'Assets/Images/check-box-ic-checked.svg';
import Button from 'Components/Shared/Buttons/Button';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import { strings } from 'Constants/Categorization/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { formatCurrency } from 'Helpers/NumberHelpers';
import { removeLineItemFromContraShelf, markLineItemsAsContra, markLineItemsAsFlaggedOrUnflagged } from 'Store/Areas/Categorisation/LineItemActions';
import { styles } from './ContraShelfLineItems.styles';

class ContraShelfLineItems extends React.PureComponent {
   getAmountColor = (amount) => {
     if (amount === 0) { return 'grey'; }
     if (amount > 0) { return 'green'; }
     return 'red';
   }
   getRowValue = (lineItem, columnHeader) => {
     if (columnHeader in lineItem.columns) {
       return lineItem.columns[columnHeader].value;
     }
     return '';
   }
   generateKey(id, columnHeader) {
     return `${columnHeader}_${id}`;
   }
   removeLineItemFromContraShelf = (lineItemId) => {
     const { dispatch } = this.props;
     dispatch(removeLineItemFromContraShelf(Number(lineItemId)));
   }

   markLineItemsAsContra = (lineItems) => {
     const { dispatch, periodId } = this.props;
     dispatch(markLineItemsAsContra(
       periodId,
       lineItems.map(x => x.id),
       Math.max(...lineItems.map(x => Number(x.columns.Amount.value))),
     ));
   }

   markLineItemsAsFlaggedOrUnflagged = (lineItems, markAsFlagged) => {
     const { dispatch, periodId, showOnlyFlaggedLineItems } = this.props;
     dispatch(markLineItemsAsFlaggedOrUnflagged(
       periodId,
       lineItems.map(x => x.id),
       markAsFlagged,
       showOnlyFlaggedLineItems,
     ));
   }

   render() {
     const {
       classes,
       lineItemsColumnHeaders,
       lineItems,
       currencySymbol,
       showOnlyFlaggedLineItems,
     } = this.props;

     const totalLineItemsAmount = lineItems.reduce((total, lineItem) => {
       return total + Number(lineItem.columns.Amount.value);
     }, 0);

     const contraLineItems = lineItems.map((lineItem) => {
       return (
         <div className={classes.root} key={lineItem.id}>
           {lineItemsColumnHeaders.filter(x => x !== '').map((columnHeader) => {
              return (
                <div
                  className={classes.individualColumnWidth}
                  key={this.generateKey(lineItem.id, columnHeader)}
                >
                  <span className={classes.flex}>
                    <If condition={columnHeader === commonStrings.amount}>
                      {
                        formatCurrency(
                          this.getRowValue(lineItem, columnHeader),
                          currencySymbol,
                        )
                      }
                    </If>
                    <If condition={columnHeader !== commonStrings.amount}>
                      {this.getRowValue(lineItem, columnHeader)}
                    </If>
                  </span>
                </div>);
            })}
           <Button
             onClick={() => this.removeLineItemFromContraShelf(lineItem.id)}
             className={classes.imgButton}
             disableRipple
           >
             <img alt={strings.removeFromContraShelfText} src={CheckboxCheckedIcon} />
           </Button>
         </div>
       );
     });

     return (
       <Fragment>
         <Paper className={classes.contentHeader}>
           <div className={classes.contraLineItemsInfo}>
             <span style={{ color: this.getAmountColor(totalLineItemsAmount), fontWeight: 'bold' }}>
               {strings.contrasAmountText}{formatCurrency(totalLineItemsAmount, currencySymbol)}
             </span>
             <div className={classes.buttonContainer}>
               <If condition={!showOnlyFlaggedLineItems}>
                 <Button
                   onClick={() => this.markLineItemsAsFlaggedOrUnflagged(lineItems, true)}
                   className={`${classes.buttonCommon}`}
                   disabled={lineItems.length === 0}
                 >
                   {strings.markLineItemsAsFlagged()}
                 </Button>
               </If>
               <Button
                 onClick={() => this.markLineItemsAsFlaggedOrUnflagged(lineItems, false)}
                 className={`${classes.buttonCommon}`}
                 disabled={lineItems.length === 0}
               >
                 {strings.markLineItemsAsUnflagged()}
               </Button>
               <If condition={!showOnlyFlaggedLineItems}>
                 <Button
                   onClick={() => this.markLineItemsAsContra(lineItems)}
                   className={`${classes.buttonCommon} ${(lineItems.length < 2 || totalLineItemsAmount !== 0) && classes.disabledButton}`}
                   disabled={lineItems.length < 2 || totalLineItemsAmount !== 0}
                 >
                   {strings.markLineItemsAsContra}
                 </Button>
               </If>
             </div>
           </div>
           <Grid className={classes.contraShelfLineItemsGrid} key="grid_ContraShelfLineItems">
             {contraLineItems}
           </Grid>
         </Paper>
       </Fragment>
     );
   }
}

ContraShelfLineItems.propTypes = {
  periodId: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  lineItemsColumnHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.shape).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  showOnlyFlaggedLineItems: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  reduxConnect(mapStateToProps),
)(ContraShelfLineItems);