import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import InfiniteScroll from 'react-infinite-scroller';
import { withStyles, Grid } from '@material-ui/core';
import { formatCurrency } from 'Helpers/NumberHelpers';
import { getPopularKeywordsByTag } from 'Store/Areas/Categorisation/ReviewCategoryActions';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import styles from './KeywordValuesList.styles';

class KeywordValuesList extends React.PureComponent {
  componentDidMount() {
    this.loadMoreKeywords();
  }

  componentDidUpdate(prevProps) {
    if (this.props.tagId !== prevProps.tagId) {
      this.loadMoreKeywords();
    }
  }

  loadMoreKeywords() {
    const {
      categoryId,
      periodId,
      tagId,
      dispatch,
      pageNumber,
    } = this.props;
    dispatch(getPopularKeywordsByTag(periodId, categoryId, tagId, pageNumber));
  }

  render() {
    const {
      keywords,
      classes,
      currencySymbol,
      hasMore,
      loading,
    } = this.props;
    return (
      <Grid container direction="row">
        <Grid item xs={6}>
          Keyword
        </Grid>
        <Grid item xs={6}>
          Value
        </Grid>
        <div className={keywords.length ? `${classes.listWithContent} ${classes.list}` : classes.list}>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={() => this.loadMoreKeywords()}
            hasMore={hasMore}
            useWindow={false}
            threshold={50}
          >
            <Grid container direction="row">
              <For each="keyword" of={keywords}>
                <Grid item xs={6} className={classes.keyword}>
                  {keyword.keyword}
                </Grid>
                <Grid item xs={6} className={classes.value}>
                  {formatCurrency(keyword.value, currencySymbol, 2)}
                </Grid>
              </For>
            </Grid>
          </InfiniteScroll>
          <If condition={loading}>
            <CircularLoader className={classes.loader} />
          </If>
        </div>
      </Grid >
    );
  }
}

KeywordValuesList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.shape({
    keyword: PropTypes.string,
    value: PropTypes.number,
  })).isRequired,
  tagId: PropTypes.number.isRequired,
  periodId: PropTypes.number.isRequired,
  categoryId: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const review = state.categorisation.reviewCategory;
  return {
    keywords: review.popularKeywordsByTag,
    tagId: review.tagId,
    periodId: review.periodId,
    categoryId: review.category.id,
    tags: state.periods.period.data.tags.filter(x => x.type === 'Text'),
    pageNumber: review.pageNumber,
    hasMore: review.hasMore,
    loading: review.popularLoading,
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(KeywordValuesList);
