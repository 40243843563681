import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, FormControlLabel, Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { updatePeriodStatus } from 'Store/Areas/Period/PeriodActions';
import { resetProcessingState } from 'Store/Areas/Import/ProcessActions';
import { removeFileImportRows } from 'Store/Areas/Import/ImportSummaryActions';
import Modal from 'Components/Shared/Modal/StyledModal';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import greenTick from 'Assets/Images/green-tick.svg';
import { periodStatuses } from 'Constants/PeriodStatuses';
import withLineItemMetadata from 'Components/Shared/LineItemMetadataProvider/LineItemMetadataProvider';
import { strings } from 'Constants/ImportSummary/Strings';
import { periodStatusSelector, hasSupportingFilesSelector } from 'Store/Areas/Period/PeriodSelectors';
import styles from './SuccessfulSummaryModal.styles';
import SummaryItemTable from '../Table/SummaryItemTable';

class SuccessfulSummaryModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: false,
    };
  }

  onStartAgainClick = () => {
    const { dispatch, periodId } = this.props;
    dispatch(removeFileImportRows(periodId));
    dispatch(updatePeriodStatus(periodId, periodStatuses.notStarted));
    dispatch(resetProcessingState());
  }

  onProceedAnywayClick = () => {
    const { dispatch, periodId, hasSupportingFiles } = this.props;
    dispatch(updatePeriodStatus(periodId, hasSupportingFiles ?
      periodStatuses.supportingDocumentsSetup : periodStatuses.contrasSetup));
  }

  handleChange = () => {
    this.setState({ isChecked: !this.state.isChecked });
  }

  render() {
    const {
      classes, importErrors, loading, status, currencySymbol,
    } = this.props;
    return (
      <Fragment>
        <Modal open={status === periodStatuses.importSummary} width="md">
          <div className={classes.content}>
            <div className={classes.title}>
              <img alt="" className={classes.tick} src={greenTick} />
              {strings.importSuccessful}
            </div>
            <div className={classes.textArea}>
              <span className={classes.note}>
                {strings.numOfLineItemsFail}{importErrors.length}
              </span>
            </div>
            <div className={classes.textArea}>
              <span className={classes.note}>
                {strings.note}
              </span>
            </div>
            <SummaryItemTable
              currencySymbol={currencySymbol}
              importErrors={importErrors}
              loading={loading}
              successful
            />
            <div className={classes.confirmation}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="confirm"
                    onChange={this.handleChange}
                  />
                }
                label={strings.confirmation}
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button
                disableRipple
                backgroundColor={constants.backgroundColor.dark}
                className={classes.button}
                onClick={this.onStartAgainClick}
              >
                {strings.startAgain}
              </Button>
              <Button
                disabled={!this.state.isChecked}
                disableRipple
                backgroundColor={constants.backgroundColor.main}
                className={classes.button}
                onClick={this.onProceedAnywayClick}
              >
                {strings.proceedAnyway}
              </Button>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

SuccessfulSummaryModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  status: PropTypes.string.isRequired,
  importErrors: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  hasSupportingFiles: PropTypes.bool.isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { period } = state.periods;
  return {
    status: periodStatusSelector(state),
    periodId: period.periodId,
    importErrors: state.import.importSummary.importErrors,
    loading: state.import.importSummary.loading,
    hasSupportingFiles: hasSupportingFilesSelector(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTheme(),
  withLineItemMetadata(),
)(SuccessfulSummaryModal);
