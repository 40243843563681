import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { PERIOD_GET_KEYWORDS } from 'Store/Api/ApiEndpoints';
import { Post } from 'Store/Api/CallApi';
import {
  GET_COMBO_KEYWORDS,
  UPDATE_COMBO_KEYWORD_FILTERS,
  getComboKeywordsSuccess,
  getComboKeywords,
} from 'Store/Areas/Categorisation/ComboKeywordActions';

/* Worker Sagas */
function* getComboKeywordsSaga(action) {
  const response = yield Post(PERIOD_GET_KEYWORDS, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getComboKeywordsSuccess(data, action.hasSearched, action.isLoadMoreKeywords));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* queryTermsUpdated(action) {
  yield put(getComboKeywords(
    action.payload.periodId,
    action.payload.rootKeyword,
    action.payload.rootTagFilter,
    action.payload.currentPageNumber,
    action.sortOrder,
    action.tagFilter,
    action.payload.searchTerm,
    !!action.payload.searchTerm,
  ));
}

/* Watcher Sagas */
export function* comboKeywordSagas() {
  yield takeLatest(GET_COMBO_KEYWORDS, getComboKeywordsSaga);
  yield takeLatest(UPDATE_COMBO_KEYWORD_FILTERS, queryTermsUpdated);
}
