import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import BreadcrumbItem from './BreadcrumbItem';
import { styles } from './Breadcrumb.styles';

class Breadcrumb extends React.PureComponent {
  render() {
    const {
      items,
      activeIndex,
      classes,
      className,
      onClicks,
      selectableIndexes,
      isSelectable,
      withTails,
    } = this.props;

    return (
      <div className={`${classes.root} ${className}`}>
        <For each="item" index="index" of={items}>
          <Choose>
            <When condition={isSelectable}>
              <With selectable={selectableIndexes.indexOf(index) > -1}>
                <BreadcrumbItem
                  key={item}
                  active={index === activeIndex}
                  firstItem={index === 0}
                  selectable={selectable}
                  text={item}
                  onClick={selectable ? onClicks[index] : null}
                  withTails={withTails}
                />
              </With>
            </When>
            <Otherwise>
              <BreadcrumbItem
                key={item}
                active={index === activeIndex}
                firstItem={index === 0}
                text={item}
                withTails={withTails}
              />
            </Otherwise>
          </Choose>
        </For>
      </div>
    );
  }
}

Breadcrumb.defaultProps = {
  className: '',
  onClicks: [],
  selectableIndexes: [],
  isSelectable: false,
  withTails: true,
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
  isSelectable: PropTypes.bool,
  withTails: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeIndex: PropTypes.number.isRequired,
  // array of funcs to be passed down to the breadcrumb items if the item is selectable
  onClicks: PropTypes.arrayOf(PropTypes.func),
  // array of the selectabled indexes
  selectableIndexes: PropTypes.arrayOf(PropTypes.number),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(Breadcrumb);
