import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import styles from './ProcessingStatus.styles';

class ProcessingStatus extends React.PureComponent {
  render() {
    const {
      classes, message, renderProgressBar, processingMessageArray,
    } = this.props;
    return (
      <div className={classes.container}>
        <span className={classes.yourDocuments}>
          {processingMessageArray[0]}
        </span>
        {message}
        {renderProgressBar()}
        <div className={classes.workSmoothly}>
          <div>
            {processingMessageArray[1]}
          </div>
          <div className={classes.marginTop}>
            {processingMessageArray[2]}
          </div>
          <div className={classes.marginTop}>
            {processingMessageArray[3]}
          </div>
        </div>
      </div>
    );
  }
}

ProcessingStatus.defaultProps = {
  message: ' ',
};

ProcessingStatus.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  message: PropTypes.string,
  renderProgressBar: PropTypes.func.isRequired,
  processingMessageArray: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(ProcessingStatus);
