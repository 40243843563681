const styles = theme => ({
  root: {
    textDecoration: 'none',
  },
  container: {
    height: 90,
    textAlign: 'center',
    borderBottomStyle: 'solid',
    borderBottomColor: '#cccccc',
    borderBottomWidth: 1,
  },
  active: {
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.primary.main,
    borderLeftWidth: '5px',
  },
  text: {
    fontSize: theme.constants.fontSizes.regular,
    color: '#888888',
    textDecoration: 'none',
  },
  image: {
    marginTop: 25,
    marginLeft: 29,
    marginRight: 29,
    marginBottom: 7,
  },
  disabled: {
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  enabled: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default styles;
