import React from 'react';
import { connect } from 'react-redux';
import {
  Snackbar,
  IconButton,
  withStyles,
  withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import SimpleButton from 'Components/Shared/Buttons/SimpleButton/SimpleButton';
import { resetToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import CloseIcon from 'Assets/Images/exit-ic.svg';
import styles from './ToastNotification.styles';

class ToastNotification extends React.PureComponent {
  onClickEvent = () => {
    const { toastNotification, dispatch } = this.props;
    dispatch(push(toastNotification.linkUrl));
    dispatch(resetToastNotification());
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.dispatch(resetToastNotification());
  }

  render() {
    const { toastNotification, classes } = this.props;
    return (
      <Snackbar
        key="notification-id"
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        open={toastNotification.showNotification}
        autoHideDuration={4000}
        onClose={this.handleClose}
        ContentProps={{
            'aria-describedby': 'message-id',
            classes: { root: classes.root },
          }}
        message={
          <div>
            <span id="message-id">{toastNotification.message}</span>
            <If condition={toastNotification.hasLink}>
              <div className={classes.linkContainer}>
                <SimpleButton className={classes.linkButton} onClickEvent={this.onClickEvent}>
                  {toastNotification.linkText}
                </SimpleButton>
              </div>
            </If>
          </div>
      }
        action={[
          <IconButton
            className={toastNotification.hasLink ? classes.closeButtonForLink : classes.closeButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.handleClose}
          >
            <img alt="close" src={CloseIcon} />
          </IconButton>,
          ]}
      />
    );
  }
}

ToastNotification.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  toastNotification: PropTypes.shape({
    message: PropTypes.string,
    showNotification: PropTypes.bool,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string,
    hasLink: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    toastNotification: state.app.toastNotification,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(ToastNotification);
