import React from 'react';
import { Paper, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Sidebar from 'Components/UserSidebar/Sidebar';
import { strings, contraTagColumnHeaders } from 'Constants/Contras/Strings';
import Button, { constants as buttonConstants } from 'Components/Shared/Buttons/Button';
import ArrowButton, {
  constants as arrowButtonConstants,
} from 'Components/Shared/Buttons/ArrowButton/ArrowButton';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import DataGrid from 'Components/Shared/DataGrid/DataGrid';
import SimpleCell from 'Components/Shared/DataGrid/Cells/SimpleCell/SimpleCell';
import Select, { constants as constantsSelect } from 'Components/Shared/Selects/Select';
import Checkbox from 'Components/Shared/Checkbox/Checkbox';
import { commonStrings } from 'Constants/CommonStrings';
import { title } from 'Constants/App/Titles/Strings';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import {
  hasSupportingFilesSelector,
  periodInUseSelector,
  periodLockedByUserIdSelector,
} from 'Store/Areas/Period/PeriodSelectors';
import { resumeProcessing } from 'Store/Areas/Import/ProcessActions';
import { postIdentifyContras } from 'Store/Areas/Import/ContraActions';
import { tagTypeLabel, selectMappedTagsFromPeriod } from 'Constants/Categorization/Tags';
import UploadBreadcrumb, { constants } from 'Components/Shared/UploadBreadcrumb/UploadBreadcrumb';
import { styles } from './ContrasSetup.styles';

class Contras extends React.PureComponent {
  constructor(props) {
    super(props);
    const { tags, headers } = this.props;
    const uniqueHeaders = [];
    headers
      .sort(h => h.columnIndex)
      .map((h) => {
        const tag = tags.find(t => t.id === h.tagId);
        return {
          tagId: tag.id,
          tagName: tag.name,
          checked: tag.name === commonStrings.amount || h.inScopeForContras,
          disabled: tag.name === commonStrings.amount,
          type: tag.type,
        };
      })
      .map((h) => {
        if (!uniqueHeaders.some(x => x.tagName === h.tagName)) {
          uniqueHeaders.push(h);
        }
        return null;
      });
    this.state = {
      data: uniqueHeaders,
      canProceed: uniqueHeaders.filter(x => x.checked).length > 1,
      maxLineItemsInContra: 2,
    };
  }

  componentDidMount() {
    document.title = title.contras;
    this.props.dispatch(getTreePickerData());
  }

  onProceedClick = () => {
    const { dispatch, periodId } = this.props;
    const headers = this.state.data.filter(x => x.checked).map(x => x.tagId);
    dispatch(postIdentifyContras(periodId, headers, this.state.maxLineItemsInContra));
  }

  onBackToMappingClick = () => {
    this.props.dispatch(push(routes.import.mappingMain));
  }

  onSkipClick = () => {
    const { dispatch, periodId } = this.props;
    dispatch(resumeProcessing(periodId));
  }

  getValue = ({ columnIndex, rowIndex }) => {
    const row = this.state.data[rowIndex];
    switch (columnIndex) {
      case 0:
        return '';
      case 1:
        return row.tagName;
      case 2:
        return tagTypeLabel(row.type);
      default:
        return '';
    }
  }

  getWidth = ({ index }) => {
    switch (index) {
      case 0:
        return 50;
      case 1:
        return 250;
      case 2:
        return 250;
      default:
        return 0;
    }
  }

  setIsChecked = (rowIndex) => {
    const newData = this.state.data.map((tag, index) => {
      return index === rowIndex ?
        { ...tag, checked: !tag.checked } : tag;
    });

    const checkedTagsData = newData.filter(x => x.checked);
    const canProceed = checkedTagsData.length >= 2 && checkedTagsData.length <= 5;
    this.setState({
      data: newData,
      canProceed: canProceed,
    });
  }

  getMaxLineItemsInContraArray = () => {
    const data = [];
    for (let i = 2; i <= 10; i += 1) {
      data.push({ id: i, name: i.toString() });
    }
    return data;
  }

  setMaxLineItemsInContra = (value) => {
    if (this.state.maxLineItemsInContra !== value) {
      this.setState({
        maxLineItemsInContra: value,
      });
    }
  }

  render() {
    const {
      classes,
      projects,
      currencySymbol,
      periodInUse,
      periodLockedByUserId,
      permissions,
    } = this.props;
    const { data, canProceed, maxLineItemsInContra } = this.state;

    const treePickerLoading = projects.treePicker.loading;

    return (
      <UserSidebarStructure
        mainContent={
          <React.Fragment>
            <UploadBreadcrumb index={constants.indexes.contras} />
            <Paper className={`${classes.root} ${(periodInUse && periodLockedByUserId !== permissions.id) ? classes.disabledAsInUse : ''}`}>
              <div className={classes.flexContainer}>
                <div className={classes.arrowButton}>
                  <ArrowButton
                    direction={arrowButtonConstants.direction.back}
                    onClickEvent={this.onBackToMappingClick}
                    className={classes.backButton}
                  />
                  <ArrowButton
                    direction={arrowButtonConstants.direction.forwards}
                    text={strings.skipThisStep}
                    onClickEvent={this.onSkipClick}
                    className={classes.skipButton}
                  />
                </div>
              </div>
              <div className={classes.flexContainer}>
                <div className={`${classes.flex} ${classes.description}`}>
                  {strings.identifyContrasDescription}
                </div>
                <Button
                  disabled={!canProceed}
                  onClick={this.onProceedClick}
                  className={`${classes.proceedButton} ${!canProceed && classes.disabled}`}
                  height={buttonConstants.height.big}
                >
                  <span className={classes.label}>
                    {strings.identifyContras}
                  </span>
                </Button>
              </div>
              <div className={classes.maxLineItemsInContraContainer}>
                <div className={classes.maxLineItemsInContra}>
                  <Select
                    id={strings.maxLineItemsInContraSelector}
                    label={strings.maxLineItemsInContraSelector}
                    preventPlaceholder
                    data={this.getMaxLineItemsInContraArray()}
                    onChange={this.setMaxLineItemsInContra}
                    value={maxLineItemsInContra}
                    colorScheme={constantsSelect.colorScheme.lightBlue}
                  />
                </div>
              </div>
              <div className={classes.tableContainer}>
                <DataGrid
                  columns={contraTagColumnHeaders}
                  rows={data}
                  getCellValue={this.getValue}
                  getWidth={this.getWidth}
                  renderHeaderCell={props => (
                    <SimpleCell
                      {...props}
                      currencySymbol={currencySymbol}
                      className={classes.tableCell}
                    />
                  )}
                  renderCell={(props) => {
                    switch (props.index) {
                      case 0:
                        return (
                          <SimpleCell
                            {...props}
                            currencySymbol={currencySymbol}
                            className={classes.tableCell}
                          >
                            <Checkbox
                              onClick={() => this.setIsChecked(props.rowIndex)}
                              checked={data[props.rowIndex].checked}
                              disabled={data[props.rowIndex].disabled}
                            />
                          </SimpleCell>
                        );
                      default:
                        return (<SimpleCell
                          {...props}
                          currencySymbol={currencySymbol}
                          className={classes.tableCell}
                        />);
                    }
                  }}
                />
              </div>
            </Paper>
          </React.Fragment>
        }
        sidebarContent={<Sidebar />}
        sidebarContentLoading={false}
        mainContentLoading={treePickerLoading}
        isCollapsible
        collapsed
      />
    );
  }
}

Contras.defaultProps = {
  periodLockedByUserId: -1,
};

Contras.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    tagId: PropTypes.number,
    columnIndex: PropTypes.number,
  })).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  hasSupportingFiles: PropTypes.bool.isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  periodInUse: PropTypes.bool.isRequired,
  periodLockedByUserId: PropTypes.number,
  permissions: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    periodId: state.periods.period.periodId,
    hasSupportingFiles: hasSupportingFilesSelector(state),
    headers: state.periods.period.data.fileSchema.headers.filter(x => x.isTagged),
    tags: selectMappedTagsFromPeriod(state),
    currencySymbol: currencySymbolSelector(state),
    periodInUse: periodInUseSelector(state),
    periodLockedByUserId: periodLockedByUserIdSelector(state),
    permissions: state.user.permissions.data,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(Contras);
