import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { REQUEST_DELETE_RULE, requestDeleteRulesSuccess } from 'Store/Areas/Rule/DeleteRuleActions';
import { Post } from 'Store/Api/CallApi';
import { RULE_REQUEST_DELETE_RULE } from 'Store/Api/ApiEndpoints';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { routes } from 'Constants/Routes';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';

function* requestDeleteRuleSaga(action) {
  yield put(setProcessing(true));
  yield put(push(routes.categorisation.singleKeywords));
  const response = yield Post(RULE_REQUEST_DELETE_RULE, action.payload);
  if (response.ok) {
    yield put(requestDeleteRulesSuccess());
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* deleteRuleWatcher() {
  yield takeLatest(REQUEST_DELETE_RULE, requestDeleteRuleSaga);
}
