const styles = theme => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 6,
    marginTop: 4,
  },
  input: {
    opacity: 1.0,
    height: 50,
    color: theme.palette.colors.white,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 0,
    paddingBottom: 0,
    width: 'inherit',
  },
  disabledInput: {
    opacity: '0.6 !important',
  },
  icon: {
    '&:focus, &:focus-visible': {
      outline: 0,
    },
  },
});

export default styles;
