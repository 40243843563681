export const styles = theme => ({
  root: {
    height: 30,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: '2px',
    backgroundColor: theme.palette.colors.white,
    border: `solid 1px ${theme.palette.primary.greyBorder}`,
    fontSize: theme.constants.fontSizes.small,
    lineHeight: '30px',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 10,
  },
  contentHeader: {
    padding: 10,
    marginBottom: 5,
  },
  flex: {
    flexGrow: 0.3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  imgButton: {
    zIndex: '200',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
    marginTop: '-5px',
    fontSize: theme.constants.fontSizes.small,
    background: 'transparent',
    border: 'none',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  individualColumnWidth: {
    width: '200px',
  },
  contraShelfLineItemsGrid: {
    maxHeight: '100px',
    overflowY: 'scroll',
  },
  contraLineItemsInfo: {
    height: '40px',
  },
  disabledButton: {
    opacity: 0.6,
  },
  buttonContainer: {
    float: 'right',
  },
  buttonCommon: {
    display: 'inline-block',
    marginRight: '10px',
  },
});
