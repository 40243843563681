export const SELECT_CREATE_PERIOD = 'CREATE_PERIOD/SELECT_CREATE_PERIOD';
export const RESET_CREATE_PERIOD = 'CREATE_PERIOD/RESET_CREATE_PERIOD';
export const GET_PERIOD_INFO = 'CREATE_PERIOD/GET_PERIOD_INFO';
export const GET_PERIOD_INFO_SUCCESS = 'CREATE_PERIOD/GET_PERIOD_INFO_SUCCESS';
export const RESET_CREATE_PERIOD_REDIRECT = 'CREATE_PERIOD/RESET_CREATE_PERIOD_REDIRECT';
export const POST_PERIOD = 'CREATE_PERIOD/POST_PERIOD';
export const POST_PERIOD_SUCCESS = 'CREATE_PERIOD/POST_PERIOD_SUCCESS';
export const SELECT_PERIOD_TEMPLATE = 'CREATE_PERIOD/SELECT_PERIOD_TEMPLATE';
export const SELECT_PERIOD_TEMPLATE_RULE = 'CREATE_PERIOD/SELECT_PERIOD_TEMPLATE_RULE';
export const SELECT_PERIOD_TEMPLATE_RULE_SUCCESS = 'CREATE_PERIOD/SELECT_PERIOD_TEMPLATE_RULE_SUCCESS';
export const SELECT_PERIOD = 'CREATE_PERIOD/SELECT_PERIOD';
export const EDIT_PERIOD = 'CREATE_PERIOD/EDIT_PERIOD';
export const EDIT_LOAD_PERIOD_SUCCESS = 'CREATE_PERIOD/EDIT_LOAD_PERIOD_SUCCESS';
export const DELETE_PERIOD = 'CREATE_PERIOD/DELETE_PERIOD';
export const CLEAR_PERIOD_DATA = 'CREATE_PERIOD/CLEAR_PERIOD_DATA';
export const ARCHIVE_PERIOD = 'CREATE_PERIOD/ARCHIVE_PERIOD';
export const TOGGLE_PERIOD_LOCK = 'CREATE_PERIOD/TOGGLE_PERIOD_LOCK';

export function selectCreatePeriod(selectedGroup, selectedEntity, selectedProject, payload) {
  return {
    type: SELECT_CREATE_PERIOD,
    loading: false,
    success: false,
    selection: {
      selectedGroup,
      selectedEntity,
      selectedProject,
      isEdit: !!payload,
    },
    form: {
      selectedTemplateId: 0,
      selectedPeriodId: 0,
    },
    payload,
  };
}

export function resetCreatePeriod(redirectToHome = false) {
  return {
    type: RESET_CREATE_PERIOD,
    redirectToHome,
  };
}

export function getPeriodInfo(periodId) {
  return {
    type: GET_PERIOD_INFO,
    loading: true,
    success: false,
    payload: { periodId },
  };
}

export function getPeriodInfoSuccess(periodId, data) {
  return {
    type: GET_PERIOD_INFO_SUCCESS,
    loading: false,
    success: true,
    periodId,
    data,
  };
}

export function postPeriod(payload, selection, isEdit = false) {
  return {
    type: POST_PERIOD,
    loading: true,
    success: false,
    isEdit: isEdit,
    payload: payload,
    selection: selection,
  };
}

export function postPeriodSuccess() {
  return {
    type: POST_PERIOD_SUCCESS,
    loading: false,
    success: true,
  };
}

export function editPeriod(
  selectedGroupId,
  selectedEntityId,
  selectedProjectId,
  selectedPeriodId,
  selectedTaxContextId,
  selectedTemplateId,
) {
  return {
    type: EDIT_PERIOD,
    showForm: true,
    isEdit: true,
    loading: true,
    payload: {
      selectedPeriodId: selectedPeriodId,
    },
    selection: {
      selectedGroup: { id: selectedGroupId },
      selectedEntity: { id: selectedEntityId },
      selectedProject: { id: selectedProjectId, taxContextId: selectedTaxContextId },
    },
    form: {
      selectedTemplateId: selectedTemplateId,
    },
  };
}

export function editLoadPeriodSuccess(json) {
  return {
    type: EDIT_LOAD_PERIOD_SUCCESS,
    showForm: true,
    isEdit: true,
    loading: false,
    payload: json,
  };
}

export function selectPeriodTemplate(templateId) {
  return {
    type: SELECT_PERIOD_TEMPLATE,
    form: {
      selectedTemplateId: templateId,
    },
  };
}

export function selectPeriodTemplateRule(templateId) {
  return {
    type: SELECT_PERIOD_TEMPLATE_RULE,
    form: {
      selectedTemplateId: templateId,
    },
  };
}

export function selectPeriodTemplateRuleSuccess(data) {
  return {
    type: SELECT_PERIOD_TEMPLATE_RULE_SUCCESS,
    selectedTemplateRuleList: data,
  };
}

export function selectPeriod(periodId) {
  return {
    type: SELECT_PERIOD,
    form: {
      selectedPeriodId: periodId,
    },
  };
}

export function deletePeriod(periodId, groupId, periodName) {
  return {
    type: DELETE_PERIOD,
    payload: {
      periodId,
      groupId,
      name: periodName,
    },
  };
}

export function clearPeriodData(periodId, groupId, periodName) {
  return {
    type: CLEAR_PERIOD_DATA,
    payload: {
      periodId,
      groupId,
      name: periodName,
    },
  };
}

export function archivePeriod(periodId, groupId) {
  return {
    type: ARCHIVE_PERIOD,
    payload: {
      periodId,
      groupId,
    },
    selectedPeriodId: periodId,
  };
}

export function togglePeriodLock(
  periodId,
  editingPeriodPayload,
  isLocking,
  isExport = false,
) {
  return {
    type: TOGGLE_PERIOD_LOCK,
    editingPeriodPayload: editingPeriodPayload,
    payload: {
      periodId: periodId,
      isLocking: isLocking,
      isExport: isExport,
    },
  };
}
