import { take, takeLatest, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import { USER_DOWNLOAD_USER_GUIDE, USER_GET_PERMISSIONS_URL } from 'Store/Api/ApiEndpoints';
import { Get, SimpleGet } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { newUserSession, NEW_USER_SESSION_SUCCESS } from 'Store/Areas/App/ConfigActions';
import { commonStrings } from 'Constants/CommonStrings';
import {
  GET_USER_PERMISSIONS,
  getUserPermissionsSuccess,
  GET_USER_PERMISSIONS_SUCCESS,
  connectToSignalRUserHub,
  DOWNLOAD_USER_GUIDE,
} from './PermissionsActions';

/* Worker Sagas */
function* getPermissions(action) {
  const init = yield select(state => state.user.permissions.init);

  if (!init) {
    yield put(newUserSession());
    yield take(NEW_USER_SESSION_SUCCESS);
  }

  const response = yield Get(USER_GET_PERMISSIONS_URL, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getUserPermissionsSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getPermissionsSuccess(action) {
  const { data } = action.payload;
  if ((data.isSysAdmin || data.isGroupAdmin)
  && (data.projectsCanEdit.length === 0 && data.projectsCanAddPeriod.length === 0)) {
    yield put(push(routes.setup));
  }
  yield put(connectToSignalRUserHub(data.id));
}

function* downloadUserGuide() {
  const response = yield Get(USER_DOWNLOAD_USER_GUIDE);
  if (response.ok) {
    let { result } = yield response.json();

    result = result && result.trim();

    if (!result) {
      yield put(displayToastNotification(commonStrings.linkNotAvailable));
      return;
    }

    // GET request on temporaryDownloadUrl, and if OK, initiate download
    // (this doesn't download the file)
    const presignedUrlResponse = yield SimpleGet(result);

    if (presignedUrlResponse.ok) {
      window.location.href = result;
    } else {
      yield handleStatusCode(presignedUrlResponse.status);
    }
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* getUserPermissions() {
  yield takeLatest(GET_USER_PERMISSIONS, getPermissions);
  yield takeLatest(GET_USER_PERMISSIONS_SUCCESS, getPermissionsSuccess);
  yield takeLatest(DOWNLOAD_USER_GUIDE, downloadUserGuide);
}
