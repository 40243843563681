const styles = theme => ({
  root: {
    paddingTop: 14,
  },
  radio: {
    display: 'none',
  },
  label: {
    marginLeft: 0,
    color: `${theme.palette.primary.darkText} !important`,
    paddingLeft: 5,
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.primary.messageBackground,
    paddingTop: 3,
    paddingBottom: 3,
  },
  checkedLabel: {
    backgroundColor: 'rgba(0,51,141, 0.12)',
    borderRadius: 4,
    borderBottom: 'none',
  },
});

export default styles;
