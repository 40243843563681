import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import chevron from 'Assets/Images/chevron-left-blue-ic.svg';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import styles from './ArrowButton.styles';

export const constants = {
  direction: {
    back: 'back',
    forwards: 'forwards',
  },
};

class ArrowButton extends React.PureComponent {
  onClick = () => {
    const { onClickEvent, dispatch, to } = this.props;
    if (to !== '') {
      dispatch(push(to));
    }
    onClickEvent();
  };
  render() {
    const {
      classes,
      direction,
      text,
      className,
    } = this.props;
    return (
      <button className={`${className} ${classes.back}`} onClick={this.onClick}>
        <If condition={direction === constants.direction.back}>
          <img className={classes.chevronLeft} alt="" src={chevron} />
        </If>
        {text}
        <If condition={direction === constants.direction.forwards}>
          <img className={classes.chevronRight} alt="" src={chevron} />
        </If>
      </button>
    );
  }
}

ArrowButton.defaultProps = {
  onClickEvent: () => {},
  to: '',
  direction: constants.direction.back,
  text: ' Back',
  className: '',
};

ArrowButton.propTypes = {
  to: PropTypes.string,
  onClickEvent: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  direction: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

export default compose(
  withTheme(),
  withStyles(styles),
  connect(),
)(ArrowButton);
