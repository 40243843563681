import { call, select } from 'redux-saga/effects';
import { apiUrl } from '../../index';

export function* getAdalToken() {
  const authContext = yield select(state => state.app.config.authContext);
  return authContext.user.access_token;
}

function* adalApiFetch(fetch, url, options = {}) {
  return yield call(fetch, url, options);
}

export function* SimpleGet(url) {
  const response = yield fetch(url, {
    method: 'GET',
    headers: {
      Range: 'bytes=0-0',
    },
  });
  return response;
}

export function* Get(url) {
  const authContext = yield select(state => state.app.config.authContext);
  const token = authContext.user.access_token;
  const response = yield adalApiFetch(
    fetch,
    `${apiUrl}/${url}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${(token)}`,
      },
    },
  );
  return response;
}

export function* AnonymousGet(url) {
  const response = yield adalApiFetch(
    fetch,
    `${apiUrl}/${url}`,
    {
      method: 'GET',
    },
  );
  return response;
}

function* executePost(url, body, headers) {
  const response = yield adalApiFetch(
    fetch,
    `${apiUrl}/${url}`,
    {
      method: 'POST',
      body: body,
      headers: headers,
    },
  );

  return response;
}

/* Regular HttpPost */
export function* Post(url, payload) {
  const authContext = yield select(state => state.app.config.authContext);
  const token = authContext.user.access_token;
  const body = JSON.stringify(payload);
  const headers = {
    From: 'reactApp',
    Accept:
      'application/json, application/xml, text/plain, text/html, multipart/form-data, *.*,',
    'Content-type': 'application/json',
    Authorization: `Bearer ${(token)}`,
  };

  return yield executePost(url, body, headers);
}

/* Unathenticated HttpPost used for registering new user */
export function* AnonymousPost(url, payload) {
  const body = JSON.stringify(payload);
  const headers = {
    From: 'reactApp',
    Accept:
      'application/json, application/xml, text/plain, text/html, multipart/form-data, *.*,',
    'Content-type': 'application/json',
  };

  return yield executePost(url, body, headers);
}

/* File post - file must be provide as a formData object */
export function* PostFile(url, payload) {
  const authContext = yield select(state => state.app.config.authContext);
  const token = authContext.user.access_token;
  const headers = {
    From: 'reactApp',
    Accept: 'multipart/form-data',
    Authorization: `Bearer ${(token)}`,
  };

  return yield executePost(url, payload, headers);
}