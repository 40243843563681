export const styles = theme => ({
  root: {
    padding: 25,
    paddingTop: 40,
  },
  title: {
    fontSize: theme.constants.fontSizes.large,
    color: theme.palette.primary.darkText,
    marginBottom: 20,
  },
  description: {
    fontSize: theme.constants.fontSizes.regular,
    color: theme.palette.primary.darkText,
  },
  card: {
    padding: 40,
  },
  button: {
    minWidth: 140,
    marginTop: 30,
    marginRight: 10,
    marginBottom: 10,
  },
  message: {
    backgroundColor: '#f4f4f4',
    color: '#666666',
  },
  messageContent: {
    padding: 20,
  },
  table: {
    marginTop: 20,
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  textBox: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  input: {
    height: 40,
    minWidth: 500,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  textInput: {
    padding: '10px 0px 10px 10px',
    fontSize: theme.constants.fontSizes.small,
  },
  checkbox: {
    padding: 0,
    width: 48,
  },
  header: {
    border: 'none',
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
    color: '#666666',
  },
});
