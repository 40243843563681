import { takeLatest } from 'redux-saga/effects';
import { EXPORT_REQUEST_DOWNLOAD } from 'Store/Api/ApiEndpoints';
import { Post, SimpleGet } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { REQUEST_DOWNLOAD_EXPORT_FILE } from './ExportActions';
import { REQUEST_DOWNLOAD_EXTERNAL_REVIEW_EXPORTED_FILE } from './ExternalReviewActions';

function* requestExportDownloadSaga(action) {
  const response = yield Post(EXPORT_REQUEST_DOWNLOAD, action.payload);
  if (response.ok) {
    const temporaryDownloadUrl = yield response.json();

    // GET request on temporaryDownloadUrl, and if OK, initiate download
    // (this doesn't download the file)
    const presignedUrlResponse = yield SimpleGet(temporaryDownloadUrl);

    if (presignedUrlResponse.ok) {
      window.location.href = temporaryDownloadUrl;
    } else {
      yield handleStatusCode(presignedUrlResponse.status);
    }
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* exportDownloadSagas() {
  yield takeLatest(REQUEST_DOWNLOAD_EXPORT_FILE, requestExportDownloadSaga);
  yield takeLatest(REQUEST_DOWNLOAD_EXTERNAL_REVIEW_EXPORTED_FILE, requestExportDownloadSaga);
}
