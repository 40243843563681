import React from 'react';
import { Input as MaterialInput, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styles from './Input.styles';
import ErrorLabel, { constants } from '../Label/ErrorLabel';

class Input extends React.Component {
  render() {
    const {
      placeholder,
      value,
      error,
      id,
      onChange,
      classes,
      preserveSpacing,
    } = this.props;
    return (
      <React.Fragment>
        <MaterialInput
          className={`${classes.input} ${classes.text} ${error ? classes.error : classes.regular}`}
          classes={{ input: classes.muiInput }}
          autoComplete="off"
          type="text"
          placeholder={placeholder}
          id={id}
          onChange={event => onChange(id, event.target.value)}
          value={value}
          fullWidth
          disableUnderline
        />
        <ErrorLabel
          error={error}
          verticalPadding
          textColor={constants.textColor.darkRed}
          preserveSpacing={preserveSpacing}
        />
      </React.Fragment>
    );
  }
}

Input.defaultProps = {
  placeholder: '',
  error: '',
  preserveSpacing: false,
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  preserveSpacing: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(Input);
