import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'Components/Shared/Modal/ErrorModal';
import { strings } from 'Constants/BulkUpload/Strings';
import { routes } from 'Constants/Routes';
import { push } from 'connected-react-router';
import { resetBulkUserUploadState } from 'Store/Areas/BulkUpload/BulkUserUploadActions';

class FailedBulkUserUploadScanModal extends React.PureComponent {
  onClose = () => {
    const { dispatch } = this.props;
    dispatch(push(routes.bulkUpload));
    dispatch(resetBulkUserUploadState());
  }

  render() {
    return (
      <Modal
        title={strings.failedScanTitle}
        error={strings.failedScanDescription}
        onClose={this.onClose}
        open
      />
    );
  }
}

FailedBulkUserUploadScanModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(FailedBulkUserUploadScanModal);
