import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme, Grid, Paper, LinearProgress, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Button, { constants as buttonConstants } from 'Components/Shared/Buttons/Button';
import { strings, exportList, exportProcessingArray } from 'Constants/Export/Strings';
import { strings as uploadStrings } from 'Constants/Upload/Strings';
import { title } from 'Constants/App/Titles/Strings';
import { Formik } from 'formik';
import { routes } from 'Constants/Routes';
import Breadcrumb from 'Components/Shared/Breadcrumbs/ChevronBreadcrumb/Breadcrumb';
import Checkbox from 'Components/Shared/Checkbox/Checkbox';
import Close from 'Assets/Images/exit-ic.svg';
import { getExportSelectionSchema } from 'Constants/Export/ExportSelectionSchema';
import withCorruptedFileProvider from 'Components/Shared/CorruptedFileProvider/WithCorruptedFileProvider';
import ProcessingStatus from 'Components/Shared/ProcessingStatus/ProcessingStatus';
import CheckboxLineItem from 'Components/Shared/CheckboxLineItem/CheckboxLineItem';
import FileUploader from 'Components/Shared/FileUploader/FileUploader';
import UploadDropzoneContent from 'Components/Upload/Shared/UploadDropzoneContent';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import ErrorModal from 'Components/Shared/Modal/ErrorModal';
import { requestExport, setExportLocked, resetCustomReportFiles } from 'Store/Areas/Export/ExportActions';
import { updatePeriodStatusInRedux, getPeriodLockedByUserId } from 'Store/Areas/Period/PeriodActions';
import { xlsFileTypes } from 'Constants/FileTypes';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { exportTypes } from 'Constants/Export/ExportTypes';
import {
  periodStatusSelector,
  periodIdSelector,
  periodLoadingSelector,
  periodIsLockedSelector,
  periodIsExternalReviewLockedSelector,
} from 'Store/Areas/Period/PeriodSelectors';
import { exportLockedSelector, exportCustomReportCloudNameSelector, exportCustomReportCloudRefSelector, exportFileLoadingSelector } from 'Store/Areas/Export/ExportSelectors';
import { requestUploadCustomReportFile, toggleCorruptedFileDetectedModal } from 'Store/Areas/FileUpload/FileUploadActions';
import FileList from './FileList/FileList';
import { styles } from './ExportPage.styles';

class ExportPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showNoAddedFilesModal: false,
    };
  }

  componentDidMount() {
    const { periodId, dispatch } = this.props;
    document.title = title.export;
    dispatch(getPeriodLockedByUserId(periodId));
  }

  onFileSelected = (file) => {
    const { dispatch, periodId } = this.props;
    dispatch(requestUploadCustomReportFile(file, periodId));
  }

  onFileRejected = () => {
    this.props.dispatch(toggleCorruptedFileDetectedModal({
      showModal: true,
      fileTypes: xlsFileTypes,
    }));
  }

  onDelete = () => {
    const { dispatch } = this.props;
    dispatch(resetCustomReportFiles());
  }

  getBreadcrumbs(periodStatus, permissions, projects) {
    const {
      periodIsLocked,
      periodIsExternalReviewLocked,
    } = this.props;
    const breadcrumbs = [{ item: strings.exportReports, link: null }];

    if (periodStatus !== periodStatuses.periodArchived) {
      breadcrumbs.push({
        item: strings.exportPivotTable,
        link: () => this.props.dispatch(push(routes.pivotTable)),
      });
      if (!periodIsLocked || periodIsExternalReviewLocked) {
        if (!permissions.projectsCanAccessAsReadOnly
          .includes(projects.userTreePicker.selectedProjectId)) {
          breadcrumbs.push({
            item: strings.exportUncategorisedData,
            link: () => this.props.dispatch(push(routes.externalReview)),
          });
        }
      }
    }

    return breadcrumbs;
  }

  startExportProgress(exportTypeIds) {
    const { dispatch, periodId, customReportCloudRef } = this.props;
    dispatch(setExportLocked(true));
    dispatch(updatePeriodStatusInRedux(periodStatuses.exportRunning));
    dispatch(requestExport(periodId, exportTypeIds, customReportCloudRef));
  }

  toggleNoAddedFilesModal = (visible) => {
    this.setState({
      showNoAddedFilesModal: visible,
    });
  }

  renderFileList = (classes, currencySymbol) => {
    return (
      <Paper className={classes.fileListContainer}>
        <FileList currencySymbol={currencySymbol} />
      </Paper>
    );
  }

  render() {
    const {
      classes,
      customReportOriginalFileName,
      customReportCloudRef,
      periodLoading,
      fileLoading,
      exportLocked,
      periodStatus,
      currencySymbol,
      permissions,
      projects,
      checkApportionmentResponse,
    } = this.props;
    const { showNoAddedFilesModal } = this.state;
    const breadcrumbs = this.getBreadcrumbs(periodStatus, permissions, projects);

    return (
      <div className={classes.root}>
        <Breadcrumb
          items={breadcrumbs.map(x => x.item)}
          activeIndex={0}
          className={classes.breadcrumb}
          selectableIndexes={breadcrumbs.map((x, i) => (x.link ? i : null)).filter(x => x !== null)}
          onClicks={breadcrumbs.map(x => x.link)}
          isSelectable
          withTails={false}
        />
        <Choose>
          <When condition={periodStatus === periodStatuses.periodArchived}>
            {this.renderFileList(classes, currencySymbol)}
          </When>
          <When condition={!exportLocked}>
            <div className={classes.title}>
              {strings.export}
            </div>
            <If condition={checkApportionmentResponse.isDefinitionNotCompleted}>
              <div className={classes.apportionableWarning}>
                {strings.apportionmentDefinitionNotCompleted}
              </div>
            </If>
            <Paper className={classes.proceedContainer}>
              <Grid container direction="row" spacing={8} alignItems="flex-start">
                <Formik
                  initialValues={{
                    selectedIds: [],
                  }}
                  validationSchema={
                    getExportSelectionSchema()
                  }
                  onSubmit={(values) => {
                    if (values.selectedIds.includes(exportTypes.customReport)
                      && !customReportOriginalFileName) {
                      this.setState({
                        showNoAddedFilesModal: true,
                      });
                    } else {
                      this.startExportProgress(values.selectedIds);
                    }
                  }}
                  render={({
                    setFieldValue,
                    values,
                    handleSubmit,
                    isValid,
                    touched,
                    errors,
                  }) => (
                    <Fragment>
                      <Grid item xs={9}>
                        <div className={classes.description}>
                          {strings.exportListNote}
                          <div className={classes.descriptionNote}>
                            {strings.exportListFormatNote}
                          </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                          <div className={classes.exportList}>
                            <div className={classes.note}>
                              {strings.availableFiles}
                            </div>
                            <For each="item" of={exportList}>
                              <If condition={(!permissions.projectsCanAccessAsReadOnly
                                .includes(projects.userTreePicker.selectedProjectId) ||
                                item.exportType !== exportTypes.customReport) &&
                                (!permissions.projectsCanAccessAsReadOnly
                                  .includes(projects.userTreePicker.selectedProjectId) ||
                                  item.exportType !== exportTypes.tagDetailsSummary)
                              }
                              >
                                <With isSelected={values.selectedIds.includes(item.exportType)}>
                                  <CheckboxLineItem
                                    key={item.exportType}
                                    shadeBackground={false}
                                    className={`${classes.lineItem} ${item.disabled ? classes.disabledFileType : ''}`}
                                    active={isSelected}
                                    onClick={item.disabled ? () => { } : () => {
                                      if (isSelected) {
                                        setFieldValue('selectedIds', values.selectedIds.filter(x => x !== item.exportType));
                                      } else {
                                        setFieldValue('selectedIds', values.selectedIds.concat([item.exportType]));
                                      }
                                    }}
                                    text={`${item.name}`}
                                    renderSelectionIcon={({ active }) => (
                                      <Checkbox
                                        disabled={item.disabled}
                                        className={classes.checkbox}
                                        checked={active}
                                      />
                                    )}
                                  />
                                </With>
                              </If>
                            </For>
                          </div>
                        </form>
                        {values.selectedIds.includes(exportTypes.customReport) &&
                          <div className={classes.customReportFile}>
                            <Choose>
                              <When condition={!periodLoading && !fileLoading}>
                                {customReportOriginalFileName !== '' &&
                                  <div className={classes.flexContainer}>
                                    <div className={classes.text}>
                                      {customReportOriginalFileName}
                                    </div>
                                    <div className={classes.deleteButton}>
                                      <IconButton
                                        onClick={() => this.onDelete()}
                                        className={classes.icon}
                                      >
                                        <img src={Close} alt="remove" />
                                      </IconButton>
                                    </div>
                                  </div>
                                }
                              </When>
                              <Otherwise>
                                <CircularLoader />
                              </Otherwise>
                            </Choose>
                          </div>
                        }
                        <div className={classes.validationMessage}>
                          <If condition={!isValid && !touched.error}>
                            {errors.selectedIds}
                          </If>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          disabled={!isValid && !touched.error}
                          onClick={handleSubmit}
                          className={classes.exportButton}
                          height={buttonConstants.height.big}
                        >
                          {strings.exportButton}
                        </Button>
                      </Grid>
                      <Grid item xs={12} className={classes.uploadContent}>
                        {!fileLoading && customReportCloudRef === '' && values.selectedIds.includes(exportTypes.customReport) &&
                          <FileUploader
                            className={classes.flex}
                            onFileSelected={files => this.onFileSelected(files[0])}
                            onDropRejected={this.onFileRejected}
                            fileTypes={xlsFileTypes}
                          >
                            <UploadDropzoneContent
                              description={uploadStrings.uploadReportDescription}
                              buttonDescription={uploadStrings.templateUploadButtonDescription}
                            />
                          </FileUploader>
                        }
                      </Grid>
                    </Fragment>
                  )}
                />
              </Grid>
            </Paper>
            {this.renderFileList(classes, currencySymbol)}
          </When>
          <Otherwise>
            <ProcessingStatus
              processingMessageArray={exportProcessingArray}
              message={strings.exporting}
              renderProgressBar={() => (<LinearProgress className={classes.progressBar} />)}
            />
          </Otherwise>
        </Choose>
        <ErrorModal
          open={showNoAddedFilesModal}
          onClose={() => this.toggleNoAddedFilesModal(false)}
          title={strings.customReportErrorTitle}
          error={strings.customReportErrorDescription}
        />
      </div>
    );
  }
}

ExportPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  periodLoading: PropTypes.bool.isRequired,
  periodStatus: PropTypes.string.isRequired,
  fileLoading: PropTypes.bool.isRequired,
  exportLocked: PropTypes.bool.isRequired,
  customReportOriginalFileName: PropTypes.string.isRequired,
  customReportCloudRef: PropTypes.string.isRequired,
  fileUpload: PropTypes.shape({
    uploadResponse: PropTypes.object,
    showCorruptedFileModal: PropTypes.bool,
    checkingForViruses: PropTypes.bool,
    fileContainsVirus: PropTypes.bool,
    isXlsOnly: PropTypes.bool,
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  periodIsLocked: PropTypes.bool.isRequired,
  periodIsExternalReviewLocked: PropTypes.bool.isRequired,
  permissions: PropTypes.shape({
    projectsCanAccessAsReadOnly: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  projects: PropTypes.shape({
    userTreePicker: PropTypes.shape({
      selectedProjectId: PropTypes.number,
    }),
  }).isRequired,
  checkApportionmentResponse: PropTypes.shape({
    isCalculationCompleted: PropTypes.bool.isRequired,
    isDefinitionNotCompleted: PropTypes.bool.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
    periodLoading: periodLoadingSelector(state),
    periodStatus: periodStatusSelector(state),
    customReportOriginalFileName: exportCustomReportCloudNameSelector(state),
    exportLocked: exportLockedSelector(state),
    customReportCloudRef: exportCustomReportCloudRefSelector(state),
    fileLoading: exportFileLoadingSelector(state),
    fileUpload: state.app.fileUpload,
    periodIsLocked: periodIsLockedSelector(state),
    periodIsExternalReviewLocked: periodIsExternalReviewLockedSelector(state),
    permissions: state.user.permissions.data,
    projects: state.projects,
    checkApportionmentResponse: state.periods.period.checkApportionmentResponse,
  };
}

export default compose(
  withCorruptedFileProvider(),
  withTheme(),
  withStyles(styles),
  connect(mapStateToProps),
)(ExportPage);
