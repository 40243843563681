export const exportTypes = {
  categorisedData: 0,
  ruleData: 1,
  auditLog: 2,
  categoriesReport: 3,
  customReport: 4,
  uncategorisedData: 5,
  categorisedDataWithSupportingDocuments: 6,
  pivotTable: 7,
  dashboardSummary: 8,
  tagDetailsSummary: 9,
};
