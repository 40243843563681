import {
  UPDATE_PERIOD_STATUS,
  UNLOCK_PERIOD,
} from 'Store/Areas/Projects/ChangePeriodStatusActions';

const initialState = {
  open: false,
};

export default function changePeriodStatusReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PERIOD_STATUS:
      return Object.assign({
        payload: action.payload,
      });
    case UNLOCK_PERIOD:
      return Object.assign({
        payload: action.payload,
      });
    default:
      return state;
  }
}