import {
  ADD_SEGMENT,
  DELETE_SEGMENT,
  MOVE_SEGMENT,
  UPDATE_TAG,
  UPDATE_OPERATOR,
  UPDATE_CONDITION,
  UPDATE_JOIN,
  BUILD_RULE_SUCCESS,
  DELETE_JOIN,
  DELETE_OPERATOR,
  DELETE_TAG,
  VALIDATE_RULE,
  RESET_RULE,
  ADD_BRACKETS,
  LOAD_INITIAL_RULE,
  SET_FREE_TEXT_RULE,
} from 'Store/Areas/Rule/RuleBuilderActions';
import { moveItemInArray } from 'Helpers/ArrayHelpers';
import { isCaseInsensitiveEqual } from 'Helpers/StringHelpers';
import { anyTag } from 'Constants/Categorization/Tags';

export const chunkTypes = {
  ruleRow: 'ruleRow',
  startBracket: 'startBracket',
  endBracket: 'endBracket',
};

function getSegment(id) {
  return {
    type: chunkTypes.ruleRow,
    id: id,
    data: {
      tag: {
        id: 0,
        type: '',
        name: '',
      },
      operator: '',
      condition: '',
      join: '',
    },
  };
}

function getChunks(initialRuleChunks, tags) {
  return initialRuleChunks.map((c, i) => ({
    type: chunkTypes.ruleRow,
    id: i,
    data: {
      tag: tags.find(t => isCaseInsensitiveEqual(t.name, c.rawTag, true)) || anyTag,
      operator: c.operator,
      condition: c.rawCondition.replace(/^"(.*)"$/, '$1'),
      join: c.join,
    },
  }));
}

function getBracket(bracketType, id) {
  return {
    type: bracketType,
    id: id,
  };
}

const initialState = {
  error: '',
  hasError: true,
  rule: '',
  chunks: [getSegment(0)],
  lastChunkId: 0,
  initialRuleId: 0,
};

export default function ruleBuilderReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SEGMENT:
      return Object.assign({}, state, {
        chunks: state.chunks.concat([getSegment(state.lastChunkId + 1)]),
        lastChunkId: state.lastChunkId + 1,
      });
    case DELETE_SEGMENT: {
      return Object.assign({}, state, {
        chunks: state.chunks.filter(x => !action.ids.includes(x.id)),
      });
    }
    case MOVE_SEGMENT:
      return Object.assign({}, state, {
        chunks: moveItemInArray(state.chunks, action.fromIndex, action.toIndex),
      });
    case ADD_BRACKETS:
      return Object.assign({}, state, {
        chunks: state.chunks.concat([getBracket(action.bracketType, state.lastChunkId + 1)]),
        lastChunkId: state.lastChunkId + 1,
      });
    case UPDATE_TAG:
    case UPDATE_OPERATOR:
    case UPDATE_CONDITION:
    case UPDATE_JOIN:
    case DELETE_JOIN:
    case DELETE_OPERATOR:
    case DELETE_TAG:
      return Object.assign({}, state, {
        chunks: state.chunks.map(x => (
          x.id === action.id ? Object.assign({}, x, {
            data: Object.assign({}, x.data, action.update),
          }) : x
        )),
      });
    case SET_FREE_TEXT_RULE:
    case BUILD_RULE_SUCCESS:
      return Object.assign({}, state, {
        rule: action.rule,
      });
    case VALIDATE_RULE:
      return Object.assign({}, state, {
        error: action.error,
        hasError: action.hasError,
      });
    case RESET_RULE:
      return initialState;
    case LOAD_INITIAL_RULE: {
      return Object.assign({}, state, {
        initialRuleId: action.initialRuleId,
        chunks: getChunks(action.initialRuleChunks, action.tags),
        lastChunkId: action.initialRuleChunks.length ? action.initialRuleChunks.length + 1 : -1,
        rule: action.rule,
        error: action.ruleError,
        hasError: action.ruleHasError,
      });
    }
    default:
      return state;
  }
}
