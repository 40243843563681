export function validateLuceneRegex(message) {
  // Lucene doesn't support the '$' anchor
  const dollarSignIndex = message.indexOf('$');
  if (dollarSignIndex !== -1) {
    if (dollarSignIndex === 0) {
      return false;
    }
    let i = message.length;
    // eslint-disable-next-line no-plusplus
    while (i--) {
      if ((message[i] === '$') && (message.charAt(i - 1) !== '\\')) {
        return false;
      }
    }
  }

  // Lucene supports '^' for class negation, but not as an anchor
  if (message.indexOf('^') === 0) {
    return false;
  }
  const matches = message.match(/(?!\[\^)([^\\]\^([^\s]*[^0-9.]+)|[^\\]\^$)/);
  if (matches != null) {
    return false;
  }

  // Lucene validation passes, check for normal RegExp
  try {
    // eslint-disable-next-line no-new
    new RegExp(message);
  } catch (e) {
    return false;
  }

  return true;
}