export const GET_IMPORT_FAILED_RULES = 'IMPORT_FAILED_RULES/GET_IMPORT_FAILED_RULES';
export const GET_IMPORT_FAILED_RULES_SUCCESS = 'IMPORT_FAILED_RULES/GET_IMPORT_FAILED_RULES_SUCCESS';

export function getImportFailedRules(periodId) {
  return {
    type: GET_IMPORT_FAILED_RULES,
    payload: {
      periodId: periodId,
      loading: true,
    },
  };
}

export function getImportFailedRulesSuccess(data) {
  return {
    type: GET_IMPORT_FAILED_RULES_SUCCESS,
    payload: {
      loading: false,
      failedRules: data,
    },
  };
}

