import Yup from 'yup';
import { strings } from './Strings';

export function getExportSelectionSchema() {
  return (
    Yup.object().shape({
      selectedIds: Yup.array().of(Yup.number()).min(1, strings.exportSelectionValidation),
    })
  );
}
