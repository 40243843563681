import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { PROJECTS_GET_TREEPICKER_URL } from 'Store/Api/ApiEndpoints';
import { Post } from 'Store/Api/CallApi';
import {
  GET_TREEPICKER_DATA,
  getTreePickerDataSuccess,
} from 'Store/Areas/Projects/TreePickerActions';

/* Worker Sagas */
function* getTreePickerData(action) {
  const response = yield Post(PROJECTS_GET_TREEPICKER_URL, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getTreePickerDataSuccess(action.payload, data));
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* treePickerSagas() {
  yield takeLatest(GET_TREEPICKER_DATA, getTreePickerData);
}
