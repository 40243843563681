import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label, ResponsiveContainer, Cell } from 'recharts';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, withTheme, Fade } from '@material-ui/core';
import { compose } from 'recompose';
import { strings } from 'Constants/Export/Strings';
import { routes } from 'Constants/Routes';
import { Link } from 'react-router-dom';
import { formatNumberToAbbreviatedString, formatCurrencyString } from 'Helpers/NumberHelpers';
import { CustomizedAxisTick } from './CustomisedAxisTick';
import { styles } from './CategoriesBarChart.styles';

class CategoriesBarChart extends React.PureComponent {
  static graphHasCategorization = (data) => {
    if (!data || data.length === 0) {
      return 0;
    }

    const categorizationValues =
      data.map(x => x.currentPeriod || 0)
        .concat(data.map(x => x.previousPeriod || 0));

    return categorizationValues.some(x => x !== 0);
  }

  prepareData = () => {
    const { period1Data, period2Data } = this.props;
    if (period2Data) {
      const data = new Map();
      period1Data.categories.forEach((e) => {
        data.set(e.name, {
          currentPeriod: e.amountValue,
          previousPeriod: 0,
          color: e.color,
        });
      });

      period2Data.categories.forEach((e) => {
        const existing = data.get(e.name);
        if (existing) {
          existing.previousPeriod = e.amountValue;
        } else {
          data.set(e.name, {
            currentPeriod: 0,
            previousPeriod: e.amountValue,
            color: e.color,
          });
        }
      });
      return Array
        .from(data)
        .map((e) => { return { name: e[0], ...e[1] }; })
        .sort((a, b) => a.name.localeCompare(b.name));
    }

    return period1Data.categories
      .map(c => ({ name: c.name, currentPeriod: c.amountValue, color: c.color }));
  }

  render() {
    const { classes, period2Data, currencySymbol } = this.props;
    const isComparingPeriods = period2Data !== null;
    const data = this.prepareData();
    const barSize = 25;
    const axisHeight = 60;
    const barCategoryGap = 50;
    const barGap = 5;
    const minGraphWidth = data.length * ((barSize * 2) + barGap + barCategoryGap);
    const graphHasCategorization = CategoriesBarChart.graphHasCategorization(data);

    return (
      <div className={classes.graphContainer}>
        <div className={classes.legendRow}>
          <div className={classes.title}>{strings.chartTitle}</div>
          <Fade in={isComparingPeriods} timeout={600}>
            <div className={classes.keyContainer}>
              <div className={classes.previousPeriod} />
              <div className={classes.legend}>{strings.previousPeriod}</div>
            </div>
          </Fade>
        </div>
        <div>
          <If condition={!graphHasCategorization}>
            <div className={classes.noData}>
              {strings.noDataMessagePt1}
              <Link
                to={routes.categorisation.referenceLists}
                className={classes.categorisationLink}
              >
                {strings.noDataMessagePt2}
              </Link>
            </div>
          </If>
          <div className={classes.chart} style={{ minWidth: minGraphWidth }}>
            <ResponsiveContainer>
              <BarChart
                data={data}
              >
                <XAxis dataKey="name" tick={<CustomizedAxisTick />} interval={0} height={axisHeight} />
                <YAxis tickFormatter={formatNumberToAbbreviatedString}>
                  <Label angle={270} position="insideLeft" style={{ textAnchor: 'middle' }} value={strings.yAxisLabel(currencySymbol)} />
                </YAxis>
                <If condition={graphHasCategorization}>
                  <Tooltip
                    isAnimationActive={false}
                    formatter={value => formatCurrencyString(value, currencySymbol)}
                  />
                  <CartesianGrid vertical={false} />
                </If>
                <Bar dataKey="previousPeriod" fill="#dcdcdc" name={strings.previousPeriod} />
                <Bar dataKey="currentPeriod" fill="#003087" name={strings.currentPeriod}>
                  {
                    data.map(category => (
                      <Cell key={category.name} fill={category.color} />
                    ))
                  }
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}

CategoriesBarChart.defaultProps = {
  period2Data: null,
};

CategoriesBarChart.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  period1Data: PropTypes.shape({
    amountValue: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
      amountValue: PropTypes.number,
    })),
  }).isRequired,
  period2Data: PropTypes.shape({
    amountValue: PropTypes.number,
    categories: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.string,
      name: PropTypes.string,
      amountValue: PropTypes.number,
    })),
  }),
};

function mapStateToProps(state) {
  return {
    period1Data: state.periods.period.data,
    period2Data: state.review.comparePeriod.comparePeriod,
  };
}


export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(CategoriesBarChart);

