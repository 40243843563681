export const RESET_KEYWORDS = 'KEYWORD/RESET_KEYWORDS';
export const GET_KEYWORDS = 'KEYWORD/GET_KEYWORDS';
export const GET_KEYWORDS_SUCCESS = 'KEYWORD/GET_KEYWORDS_SUCCESS';
export const UPDATE_KEYWORD_FILTERS = 'KEYWORD_FILTERS/UPDATE_KEYWORD_FILTERS';
export const UPDATE_SEARCH_TERM = 'KEYWORD_FILTERS/UPDATE_SEARCH_TERM';
export const RESET_KEYWORD_FILTERS = 'KEYWORD_FILTERS/RESET_KEYWORD_FILTERS';

export function getKeywords(
  periodId,
  currentPageNumber,
  sortOrder,
  tagFilter,
  searchTerm,
  hasSearched,
  isLoadMoreKeywords = false,
) {
  return {
    type: GET_KEYWORDS,
    morePagesAvailable: false,
    loading: true,
    hasSearched,
    isLoadMoreKeywords,
    requestTime: Date.now(),
    payload: {
      periodId,
      currentPageNumber: isLoadMoreKeywords ? currentPageNumber : 1,
      sortOrder,
      tagFilter,
      searchTerm,
    },
  };
}

export function resetKeywords() {
  return {
    type: RESET_KEYWORDS,
  };
}

export function getKeywordsSuccess(data, hasSearched, isLoadMoreKeywords) {
  return {
    type: GET_KEYWORDS_SUCCESS,
    loading: false,
    keywords: data.keywords,
    nextPageNumber: data.nextPageNumber,
    morePagesAvailable: data.hasMore,
    hasSearched,
    isLoadMoreKeywords,
  };
}

export function updateKeywordFilters(
  sortOrder,
  tagFilter,
  searchTerm,
  periodId,
  currentPageNumber,
) {
  return {
    type: UPDATE_KEYWORD_FILTERS,
    sortOrder: sortOrder,
    tagFilter: tagFilter,
    payload: { // we pass along this extra data in the action for when we refetch keywords
      searchTerm: searchTerm,
      periodId: periodId,
      currentPageNumber: currentPageNumber,
    },
  };
}

export function updateSearchTerm(searchTerm) {
  return {
    type: UPDATE_SEARCH_TERM,
    searchTerm: searchTerm,
  };
}

export function resetKeywordFilters() {
  return {
    type: RESET_KEYWORD_FILTERS,
  };
}
