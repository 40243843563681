import { put } from 'redux-saga/effects';
import { userActions } from 'Constants/UserActions';
import { getCategorisationLogicSuccess } from 'Store/Areas/Import/LineItemValueActions';
import SignalrStrategyHandler from './SignalrStrategyHandler';

export const mlCategorisationReasonHandler = new SignalrStrategyHandler(
  userActions.MlCategorisationReason,
  function* handler(payload) {
    if (!payload.error) {
      yield put(getCategorisationLogicSuccess(payload));
    }
  },
);
