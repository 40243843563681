import React from 'react';
import ErrorPage from 'Components/ErrorPages/ErrorPage';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { retryFailedImport } from 'Store/Areas/Import/ProcessActions';
import PropTypes from 'prop-types';
import StyledModalButtons from 'Components/Shared/Modal/StyledModal.Buttons';
import { updatePeriodStatus } from 'Store/Areas/Period/PeriodActions';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { strings } from 'Constants/Processing/Strings';
import { styles } from './ImportErrorPage.styles';

class ImportErrorPage extends React.PureComponent {
  requeueJob = () => {
    const { dispatch, periodId } = this.props;
    dispatch(retryFailedImport(periodId));
  }

  startAgain = () => {
    const { dispatch, periodId } = this.props;
    dispatch(updatePeriodStatus(periodId, periodStatuses.mapping));
  }

  render() {
    const { classes } = this.props;
    return (
      <ErrorPage
        userActions={
          <StyledModalButtons
            buttonClassName={classes.buttonClass}
            onCancel={this.requeueJob}
            onAccept={this.startAgain}
            acceptText={strings.startAgain}
            cancelText={strings.retryImport}
            isSubmitting={false}
          />
        }
      />
    );
  }
}

ImportErrorPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: state.periods.period.periodId,
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(ImportErrorPage);
