import { SET_REFERENCE_LIST_FILE_LOADING, GET_REFERENCE_LIST_ITEMS, GET_REFERENCE_LIST_ITEMS_SUCCESS, RESET_REFERENCE_LIST_ENTRIES, OPEN_VIEW_REFERENCE_LIST, CLOSE_VIEW_REFERENCE_LIST, GET_REFERENCE_LIST_ENTRIES, GET_REFERENCE_LIST_ENTRIES_SUCCESS, SET_REFERENCE_LIST_FILE_UPLOAD_RESPONSE, UPLOAD_AND_REPLACE_REFERENCE_LIST, CHECK_REFERENCE_LIST_RULES, CHECK_REFERENCE_LIST_RULES_SUCCESS, RESET_REFERENCE_LISTS_RULES, RESET_REFERENCE_LISTS, NAVIGATE_TO_REFERENCE_LISTS, RESET_REFERENCE_LISTS_NAVIGATION, DELETE_REFERENCE_LIST_ENTRIES, DELETE_REFERENCE_LIST_ENTRIES_SUCCESS } from 'Store/Areas/Categorisation/ReferenceListActions';
import { REQUEST_UPLOAD_REFERENCE_LIST_FILE } from 'Store/Areas/FileUpload/FileUploadActions';
import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';

const initialState = {
  referenceLists: null,
  loading: false,
  fileLoading: false,
  entriesLoading: false,
  open: false,
  referenceListName: '',
  referenceListId: '',
  referenceListEntries: [],
  fileUploadResponse: {},
  referenceListRules: null,
  chevronNavigation: false,
};

export default function exportReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REFERENCE_LIST_ITEMS:
      return Object.assign({}, state, {
        loading: action.loading,
        chevronNavigation: action.maintainRoute || state.chevronNavigation,
      });
    case GET_REFERENCE_LIST_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        fileLoading: action.fileLoading,
        referenceLists: action.referenceLists,
      });
    case CHECK_REFERENCE_LIST_RULES:
      return Object.assign({}, state, {
        loading: action.loading,
        referenceListName: action.referenceListName,
        referenceListId: action.payload.referenceListId,
      });
    case CHECK_REFERENCE_LIST_RULES_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        referenceListRules: action.referenceListRules,
      });
    case RESET_REFERENCE_LISTS_RULES:
      return Object.assign({}, state, {
        loading: initialState.loading,
        referenceListRules: initialState.referenceListRules,
      });
    case SET_REFERENCE_LIST_FILE_LOADING:
    case REQUEST_UPLOAD_REFERENCE_LIST_FILE:
      return Object.assign({}, state, {
        fileLoading: action.fileLoading,
      });
    case OPEN_VIEW_REFERENCE_LIST:
      return Object.assign({}, state, {
        open: action.open,
        referenceListName: action.payload.refList,
        referenceListId: action.payload.refListId,
      });
    case CLOSE_VIEW_REFERENCE_LIST:
      return Object.assign({}, state, {
        open: action.open,
      });
    case GET_REFERENCE_LIST_ENTRIES:
      return Object.assign({}, state, {
        entriesLoading: action.entriesLoading,
      });
    case GET_REFERENCE_LIST_ENTRIES_SUCCESS:
      return Object.assign({}, state, {
        referenceListEntries: action.referenceListEntries,
        entriesLoading: action.entriesLoading,
      });
    case RESET_REFERENCE_LIST_ENTRIES:
      return Object.assign({}, state, {
        referenceListEntries: [],
      });
    case SET_REFERENCE_LIST_FILE_UPLOAD_RESPONSE:
    case UPLOAD_AND_REPLACE_REFERENCE_LIST:
      return Object.assign({}, state, {
        fileLoading: action.fileLoading,
        fileUploadResponse: action.fileUploadResponse,
      });
    case NAVIGATE_TO_REFERENCE_LISTS:
    case RESET_REFERENCE_LISTS_NAVIGATION:
      return Object.assign({}, state, {
        chevronNavigation: action.chevronNavigation,
      });
    case DELETE_REFERENCE_LIST_ENTRIES:
      return Object.assign({}, state, {
        loading: action.loading,
        periodId: action.payload.periodId,
        referenceListId: action.payload.referenceListId,
        referenceListEntryIds: action.payload.referenceListEntryIds,
      });
    case DELETE_REFERENCE_LIST_ENTRIES_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        fileLoading: action.fileLoading,
      });
    case RESET_PERIOD:
    case RESET_REFERENCE_LISTS:
      return initialState;
    default:
      return state;
  }
}
