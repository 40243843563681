import React from 'react';
import { FormattedNumber } from 'react-intl';

export const defaultLocale = 'en-GB';

export function getIntlNumberFormatOptions(decimalPoints = 2, style = 'decimal') {
  return {
    style,
    minimumFractionDigits: decimalPoints,
    maximumFractionDigits: decimalPoints,
  };
}

export function formatCurrency(
  value,
  currencySymbol,
  decimalPoints = 2,
  useBracketNotationForNegative = true,
) {
  const isNegative = value < 0;
  return (
    <React.Fragment>
      <If condition={!useBracketNotationForNegative && isNegative}>
        -
      </If>
      {`${currencySymbol} `}
      <If condition={useBracketNotationForNegative && isNegative}>
        (
      </If>
      <FormattedNumber
        {...getIntlNumberFormatOptions(decimalPoints)}
        value={isNegative ? value * -1 : value}
      />
      <If condition={useBracketNotationForNegative && isNegative}>
        )
      </If>
    </React.Fragment>
  );
}

function getDecimalPlacesForPercentage(value) {
  return (value * 100) % 1 === 0 ? 0 : 2;
}

export function formatPercentage(value) {
  return (
    <React.Fragment>
      <FormattedNumber
        {...getIntlNumberFormatOptions(
          getDecimalPlacesForPercentage(value),
          'percent',
        )}
        value={value}
      />
    </React.Fragment>
  );
}

export function formatNumberCommaString(value, decimalPoints = 2) {
  return new Intl.NumberFormat(
    defaultLocale,
    getIntlNumberFormatOptions(decimalPoints),
  ).format(value);
}

export function formatCurrencyString(
  value,
  currencySymbol,
  decimalPoints = 2,
  useBracketNotationForNegative = true,
) {
  let numericValue = Number(value);

  const shouldAddBrackets = useBracketNotationForNegative && numericValue < 0;

  if (shouldAddBrackets) {
    numericValue *= -1;
  }

  const formattedNumber = formatNumberCommaString(numericValue, decimalPoints);

  return `${currencySymbol} ${shouldAddBrackets ? '(' : ''}${formattedNumber}${shouldAddBrackets ? ')' : ''}`;
}

export function formatNumberToAbbreviatedString(number) {
  if (number > 1000000000 || number < -1000000000) {
    return `${(number / 1000000000).toString()}B`;
  } else if (number > 1000000 || number < -1000000) {
    return `${(number / 1000000).toString()}M`;
  } else if (number > 1000 || number < -1000) {
    return `${(number / 1000).toString()}K`;
  }
  return number.toString();
}

export function formatPercentageString(value) {
  return new Intl.NumberFormat(
    defaultLocale,
    getIntlNumberFormatOptions(
      getDecimalPlacesForPercentage(value),
      'percent',
    ),
  ).format(Number(value));
}
