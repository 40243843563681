import { put } from 'redux-saga/effects';
import { categoryListTypes } from 'Constants/Categorization/CategoryListTypes';
import { ruleTypes } from 'Constants/Rules/RuleConstants';
import * as RuleActions from '../CategorisationActions';
import { CategorisationStrategyHandler } from './CategorisationStrategyHandler';


export const referenceListsStrategyHandler = new CategorisationStrategyHandler(
  categoryListTypes.categoryType.referenceList,
  function* handleRuleStrategy(action) {
    if (action.ruleProperties.selectedTag == null) {
      yield put(RuleActions.requestTagSelection());
      return;
    }
    if (!this.checkCategoriesTotalTo1(action.categories)) {
      yield put(RuleActions.requestApportionment());
      return;
    }
    const payload = {
      parentId: action.periodId,
      keywordRule: this.buildReferenceListRule(
        action.ruleProperties.referenceListName,
        action.ruleProperties.selectedTag,
      ),
      categories: action.categories,
      isHighPriority: action.ruleProperties.isHighPriority,
      isSubstring: action.isSubstring,
      isCategoryApportionable: this.checkApportionment(action.categories),
      ruleType: ruleTypes.referenceListRule,
      referenceListId: action.ruleProperties.referenceListId,
    };

    yield this.handleRuleCreation(payload, action.periodId);
  },
);
