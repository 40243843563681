const styles = theme => ({
  mandatoryTags: {
    fontSize: theme.constants.fontSizes.small,
  },
  addTagButton: {
    marginRight: 10,
    textTransform: 'none',
    fontSize: theme.constants.fontSizes.small,
    paddingLeft: 9,
    paddingRight: 9,
    borderColor: '#008fdd',
    color: theme.palette.primary.main,
    '&:hover': {
      boxShadow: '0 0 5px 0 rgba(0, 143, 221, 0.5)',
      backgroundColor: 'transparent',
    },
  },
});

export default styles;
