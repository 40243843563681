import React from 'react';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import { DragSource } from 'react-dnd';
import PropTypes from 'prop-types';
import DragnDrop from 'Constants/DragnDrop';
import withDndPreviewContext from 'Components/Shared/DragNDrop/DndPreviewContextProvider';
import { styles } from './BuilderDragHandle.styles';

class BuilderDragHandle extends React.PureComponent {
  componentDidMount() {
    this.props.connectDragPreview(this.props.dndPreview);
  }

  render() {
    const {
      connectDragSource,
      classes,
      style,
      className,
      isComplete,
    } = this.props;

    return (
      <div className={`${classes.root} ${className}`} style={style}>
        {connectDragSource(<div className={classes.dragLayer} />)}
        <div className={`${classes.handle} ${isComplete ? classes.complete : classes.incomplete}`} />
      </div>);
  }
}

BuilderDragHandle.propTypes = {
  id: PropTypes.number.isRequired, // eslint-disable-line
  index: PropTypes.number.isRequired, // eslint-disable-line
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  hoveredIndex: PropTypes.number.isRequired, // eslint-disable-line
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
  condition: PropTypes.string.isRequired, // eslint-disable-line
  tag: PropTypes.string.isRequired, // eslint-disable-line
  operator: PropTypes.string.isRequired, // eslint-disable-line
  join: PropTypes.string.isRequired,  // eslint-disable-line
  width: PropTypes.number.isRequired, // eslint-disable-line
  dndPreview: PropTypes.object.isRequired, // eslint-disable-line
};

const cardSource = {
  beginDrag(props) {
    return {
      index: props.index,
      width: props.width,
      condition: props.condition,
      tag: props.tag,
      operator: props.operator,
      join: props.join,
    };
  },
  endDrag(props) {
    props.setIsOver(-1);
    if (props.hoveredIndex > -1) {
      props.onDrop(props.index, props.hoveredIndex);
    }
  },
};

// Specifies the props to inject into your component
function collect(connect) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  };
}


export default compose(
  DragSource(DragnDrop.customRuleSegment, cardSource, collect),
  withStyles(styles),
  withTheme(),
  withDndPreviewContext(),
)(BuilderDragHandle);
