import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import robot from 'Assets/Images/robot-success.svg';
import styles from './Robot.styles';

class Robot extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.robotContainer}>
          <img alt="" className={classes.robot} src={robot} />
        </div>
      </Fragment>
    );
  }
}

Robot.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(Robot);
