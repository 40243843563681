const styles = {
  root: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
  },
  radio: {
    width: 20,
    paddingRight: 6,
    paddingLeft: 6,
  },
};

export default styles;
