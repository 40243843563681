import { takeLatest, cancelled, put, select } from 'redux-saga/effects';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import { showPeriodInUsePopup, resetPeriodInUseSuccess } from 'Store/Areas/Period/PeriodActions';
import { CONNECT_TO_SIGNALR_USER_HUB } from 'Store/Areas/User/PermissionsActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { watchSignalREvents } from 'Store/Api/CallSignalR';
import { strings } from 'Constants/Projects/CreatePeriod/Strings';
import * as errorStrings from 'Constants/Error/Strings';
import { periodControlStatuses, periodControlStatusText } from 'Constants/PeriodControlStatuses';

function* handlePayload(payload) {
  if (periodControlStatusText(payload.periodControlStatus) === periodControlStatuses.approved) {
    yield put(displayToastNotification(strings.periodControlTakenNotificationText(
      payload.userEmail,
      payload.periodName,
    )));
    yield put(resetPeriodInUseSuccess());
  }
  const selectedPeriodId = yield select(periodIdSelector);
  if (selectedPeriodId === payload.periodId) {
    yield put(showPeriodInUsePopup(
      payload.periodName,
      payload.userEmail, payload.requestUserId, payload.periodControlStatus,
    ));
  }
}

function* handleSignalRMessage(payload) {
  if (payload.error) {
    yield put(displayToastNotification(errorStrings.strings.errorMessageRetry));
  } else {
    yield handlePayload(payload);
  }
}

export function* userSignalRSaga(action) {
  yield watchSignalREvents({
    action,
    processSignalRPayload: {
      hubUrl: `user?groupid=user-${action.userId}`,
      name: 'PeriodControl',
      endConnection: (connection, name) => connection.stop(name),
    },
    handleSignalRMessage,
    cancelled,
  });
}

export function* userSignalRSagas() {
  yield takeLatest(CONNECT_TO_SIGNALR_USER_HUB, userSignalRSaga);
}
