export const REQUEST_SEARCH = 'KEYWORD_SEARCH/REQUEST_SEARCH';
export const RESET_SEARCH = 'KEYWORD_SEARCH/RESET_SEARCH';
export const REQUEST_SEARCH_SUCCESS = 'KEYWORD_SEARCH/REQUEST_SEARCH_SUCCESS';

export function requestSearch(query, tagId, periodId, searchId, operator) {
  return {
    type: REQUEST_SEARCH,
    payload: {
      periodId: periodId,
      searchTerm: query,
      tagFilter: tagId,
    },
    searchId: searchId,
    operator: operator,
  };
}

export function requestSearchSuccess(results) {
  return {
    type: REQUEST_SEARCH_SUCCESS,
    results: results,
  };
}

export function resetSearch() {
  return {
    type: RESET_SEARCH,
  };
}
