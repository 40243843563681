export const templateImportStages = {
  uploading: 0,
  passedScan: 1,
  templateCreated: 2,
  failedScan: 3,
  error: 4,
};

export const templateProcessingStages = [
  {
    stageId: templateImportStages.uploading,
    stageName: 'Scanning File',
    startMessage: 'Scanning File',
  },
  {
    stageId: templateImportStages.passedScan,
    stageName: 'Creating Template',
    startMessage: 'Creating Template',
  },
  {
    stageId: templateImportStages.templateCreated,
    stageName: 'Finishing Up',
    startMessage: 'Finishing Up',
  },
];
