import { put, select } from 'redux-saga/effects';
import { userActions } from 'Constants/UserActions';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';
import { closeDeleteRule } from 'Store/Areas/Rule/DeleteRuleActions';
import { currentRouteSelector } from 'Store/Areas/Router/RouteSelectors';
import { routes } from 'Constants/Routes';
import { resetCategoryRules, getCategoryRules } from 'Store/Areas/Rule/CategoryRulesActions';
import { selectCategoryForReview, getPopularKeywordsByTag } from 'Store/Areas/Categorisation/ReviewCategoryActions';
import { closeInspectRule } from 'Store/Areas/Rule/InspectRuleActions';
import * as metadataActions from 'Store/Areas/Review/LineItemMetadataActions';
import SignalrStrategyHandler from './SignalrStrategyHandler';

export const deleteRuleHandler = new SignalrStrategyHandler(
  userActions.DeleteRule,
  function* handler(payload, action) {
    if (payload.error) {
      yield this.handleError(action);
    } else {
      const currentRoute = yield select(currentRouteSelector);
      if (currentRoute === routes.categoryReview) {
        const category = yield select(x => x.categorisation.reviewCategory.category);
        const ruleFilters = yield select(x => x.rules.categoryRulesFilter);
        yield put(closeDeleteRule());
        yield put(resetCategoryRules());
        yield put(getPeriod(action.selectedPeriodId, true));
        yield put(getCategoryRules(
          action.selectedPeriodId,
          category.id,
          1,
          ruleFilters.sortColumn,
          ruleFilters.sortOrder,
          ruleFilters.tagFilter,
          ruleFilters.searchTerm,
        ));
        const newCategory =
            yield select(x => x.periods.period.data.categories.find(y => y.id === category.id));
        yield put(selectCategoryForReview(newCategory, action.selectedPeriodId, false));

        const reviewCategoryState = yield select(x => x.categorisation.reviewCategory);
        yield put(getPopularKeywordsByTag(
          reviewCategoryState.periodId,
          reviewCategoryState.category.id,
          reviewCategoryState.tagId,
          1,
        ));
        yield put(setProcessing(false));
        yield put(metadataActions.setRequiresSearch());
        yield put(closeInspectRule());
      } else {
        yield put(closeDeleteRule());
        const isConflictsPage = (yield select(currentRouteSelector)) === routes.conflicts;
        yield put(getPeriod(action.selectedPeriodId, !isConflictsPage));
        if (!isConflictsPage) {
          yield put(setProcessing(false));
        }
      }
    }
  },
);
