import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import ProjectsTreePicker from 'Components/Projects/ProjectsTreePicker';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import { strings } from 'Constants/Projects/Strings';
import styles from './ProjectsSidebar.styles';

class ProjectsSidebar extends React.PureComponent {
  render() {
    const { data } = this.props.treePickerData;
    const {
      classes,
      onEditPeriodClick,
      onAddPeriodClick,
      onPeriodSelection,
      userTreePicker,
      onTreeItemClick,
      isReadOnly,
    } = this.props;
    if (data.length === 0) {
      return (
        <div className={`${classes.treePicker} ${classes.title} ${classes.text}`}>
          {strings.noGroupsFound}
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <div className={classes.treePicker}>
          <div className={`${classes.title} ${classes.text}`}>{strings.treePickerTitle}</div>
          <ProjectsTreePicker
            isReadOnly={isReadOnly}
            data={data}
            onEditPeriodClick={onEditPeriodClick}
            onAddPeriodClick={onAddPeriodClick}
            onPeriodSelection={onPeriodSelection}
            userTreePicker={userTreePicker}
            onTreeItemClick={onTreeItemClick}
          />
        </div>

        {!isReadOnly &&
          <Button
            className={classes.button}
            backgroundColor={constants.backgroundColor.main}
            height={constants.height.big}
            onClick={() => onAddPeriodClick(null)}
            rounded={false}
          >
            {strings.addPeriodButton}
          </Button>
        }
      </div>
    );
  }
}

ProjectsSidebar.defaultProps = {
  isReadOnly: false,
};

ProjectsSidebar.propTypes = {
  treePickerData: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
  userTreePicker: PropTypes.shape({
    selectedGroupId: PropTypes.number,
    selectedEntityId: PropTypes.number,
    selectedProjectId: PropTypes.number,
    selectedGroupName: PropTypes.string,
    selectedEntityName: PropTypes.string,
    selectedProjectName: PropTypes.string,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onAddPeriodClick: PropTypes.func.isRequired,
  onEditPeriodClick: PropTypes.func.isRequired,
  onPeriodSelection: PropTypes.func.isRequired,
  onTreeItemClick: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(ProjectsSidebar);
