import { takeLatest } from 'redux-saga/effects';
import * as RuleActions from './CategorisationActions';
import { customRuleStrategyHandler } from './CategorisationHandlers/CustomRuleCategorisationHandler';
import { referenceListsStrategyHandler } from './CategorisationHandlers/ReferenceListCategorisationHandler';
import { keywordRuleStrategyHandler } from './CategorisationHandlers/KeywordRuleCategorisationHandler';
import { lineItemsStrategyHandler } from './CategorisationHandlers/LineItemCategorisationHandler';

const strategies = [
  customRuleStrategyHandler,
  referenceListsStrategyHandler,
  keywordRuleStrategyHandler,
  lineItemsStrategyHandler,
];

/* Watcher Sagas */
export function* ruleSagas() {
  yield takeLatest(RuleActions.CATEGORISE_ITEMS, function* handleAction(action) {
    const strategy = strategies.find(x => x.categoryType === action.categoryType);
    yield strategy.handle(action);
  });
}
