import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, withTheme, IconButton } from '@material-ui/core';
import { compose } from 'recompose';
import { updateTagExportPivotTable } from 'Store/Areas/Export/ExportPivotTableActions';
import Close from 'Assets/Images/exit-ic-light-blue.svg';
import { DataTypes } from 'Constants/Upload/DataTypes';
import { selectMappedTagsFromPeriod } from 'Constants/Categorization/Tags';
import TagDropzone from 'Components/Upload/MappingPage/PreviewTable/TagDropzone';
import { styles } from './PivotTableParametersCell.styles';

class PivotTableParametersCell extends React.PureComponent {
  mapTag(tag, colName, isAdding) {
    const { dispatch } = this.props;

    dispatch(updateTagExportPivotTable(tag, colName, isAdding));
  }

  addTag = (tagId, tagName) => {
    const { tags, colName } = this.props;
    const tag = tags.find(item => item.id === tagId && item.name === tagName);
    this.mapTag(tag, colName, true);
  }

  removeTag = () => {
    const { tag, colName } = this.props;
    this.mapTag(tag, colName, false);
  }

  render() {
    const {
      classes,
      tag,
      expectedDataType,
    } = this.props;
    return (
      <div>
        <If condition={tag !== null}>
          <div className={`${classes.headerTagDrop} ${classes.headerTagDropWithTag}`}>
            <span>{tag.name}</span>
            <IconButton
              disableRipple
              className={classes.closeButton}
              color="inherit"
              onClick={this.removeTag}
              aria-label="Remove_map"
            >
              <img alt="Close" src={Close} className={classes.closeIcon} />
            </IconButton>
          </div>
        </If>
        <If condition={tag === null}>
          <TagDropzone
            className={classes.tagDropzone}
            onDrop={this.addTag}
            expectedDataType={expectedDataType}
          />
        </If>
      </div>
    );
  }
}

PivotTableParametersCell.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  tag: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
  }),
  expectedDataType: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DataTypes))),
  colName: PropTypes.string.isRequired,
};

PivotTableParametersCell.defaultProps = {
  tag: null,
  expectedDataType: [DataTypes.Text],
};

function mapStateToProps(state) {
  return {
    tags: selectMappedTagsFromPeriod(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(PivotTableParametersCell);
