import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { chunkTypes } from 'Store/Areas/Rule/RuleBuilderReducer';
import { withStyles, withTheme } from '@material-ui/core';
import { isReadOnlySelector } from 'Store/Areas/Period/PeriodSelectors';
import { strings } from 'Constants/Rules/Strings';
import { styles } from './AddElementsRow.styles';
import { withRulesBuilder } from '../';

class AddElementsRow extends React.PureComponent {
  render() {
    const {
      onAddSegment,
      onAddBrackets,
      classes,
      ruleChunks,
      isReadOnly,
    } = this.props;
    const openBracketCount = ruleChunks.filter(x => x.type === chunkTypes.startBracket).length;
    const endBracketCount = ruleChunks.filter(x => x.type === chunkTypes.endBracket).length;
    const nextBracketType = openBracketCount === endBracketCount ?
      chunkTypes.startBracket : chunkTypes.endBracket;
    return (
      <div className={classes.root}>
        <button
          className={`${classes.buttonBase} ${isReadOnly
            ? classes.buttonDisabled : classes.button}`}
          onClick={() => onAddBrackets(nextBracketType)}
          disabled={isReadOnly}
        >
          {nextBracketType === chunkTypes.startBracket ?
            strings.openBracketsButton : strings.closeBracketsButton}
        </button>
        <button
          className={`${classes.buttonBase} ${isReadOnly
            ? classes.buttonDisabled : classes.button}`}
          onClick={onAddSegment}
          disabled={isReadOnly}
        >
          {strings.addLineButton}
        </button>
        <div className={classes.flex} />
      </div>
    );
  }
}

AddElementsRow.propTypes = {
  onAddSegment: PropTypes.func.isRequired,
  onAddBrackets: PropTypes.func.isRequired,
  ruleChunks: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isReadOnly: isReadOnlySelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  withRulesBuilder(),
  connect(mapStateToProps),
)(AddElementsRow);
