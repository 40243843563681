import React from 'react';
import { withStyles, withTheme, Table, TableCell, Paper, Input, TableRow, TableHead, Card, TableBody } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import StyledModal from 'Components/Shared/Modal/StyledModal';
import Checkbox from 'Components/Shared/Checkbox/Checkbox';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import MandatoryAsterisk from 'Components/Shared/MandatoryAsterisk/MandatoryAsterisk';
import { connect } from 'react-redux';
import StickyTable from 'Components/Shared/Table/StickyTable';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import { apportionablesResourceSelector } from 'Store/Areas/Apportionables/ApportionablesSelectors';
import { requestCreateApportionable, closeApportionmentModal } from 'Store/Areas/Apportionables/ApportionablesModalActions';
import styles from './ApportionablesModal.styles';
import { apportionmentStrings } from '../ApportionablesStrings';


class ApportionablesModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      chipConditions: [],
      apportionableCondition: '',
      requestEntryOfApportionableCondition: false,
      requestEntryOfPriority: false,
      priorityValue: 0,
      checked: {},
      tagInherited: {},
      amountValue: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { tagValues, tags } = this.props;
    if (tagValues && tags) {
      if (prevProps.tagValues !== this.props.tagValues && this.props.tagValues.length > 0) {
        this.getDefaultValues();
      }
    }
  }

  getDefaultValues() {
    const { tagValues } = this.props;
    const checked = {};
    const tagInherited = {};
    const totalCount = tagValues.length;
    for (let i = 0; i < totalCount; i += 1) {
      checked[`Inherit_${i}`] = false;
      tagInherited[tagValues[i].tagName] = false;
    }
    this.setState({
      checked,
      tagInherited,
    });
  }

  setPriority = ({ target: { value } }) => {
    const priorityValue = value === '' ? 0 : parseInt(value, 0);
    if (priorityValue >= 0 && priorityValue <= 500) {
      this.setState({
        requestEntryOfPriority: priorityValue === 0,
        priorityValue: priorityValue,
      });
    }
  }

  handleAmountChange = ({ target: { value } }) => {
    const amountValue = value === '' ? 0 : value;
    if (this.isNumber(amountValue) || amountValue === '-') {
      this.setState({
        amountValue: amountValue === 0 ? '' : amountValue,
      });
      this.addChip([amountValue], { name: 'Amount', type: 'Numeric' });
      if (amountValue === 0) {
        this.removeChip([amountValue], { name: 'Amount', type: 'Numeric' });
      }
    }
  }

  isNumber = value => !Number.isNaN(Number(value));

  onClickLater = () => {
    const {
      dispatch, periodId, keywordRules, lineItemIds, amount, isLineItemsDragged,
      ruleType, isHighPriority, categories, rules,
    } = this.props;
    dispatch(requestCreateApportionable(
      periodId,
      keywordRules,
      lineItemIds,
      amount,
      isLineItemsDragged,
      ruleType,
      isHighPriority,
      categories,
      rules,
    ));
    const chipConditions = [];
    this.setState({
      apportionableCondition: '',
      chipConditions: chipConditions,
      requestEntryOfApportionableCondition: false,
      requestEntryOfPriority: false,
      priorityValue: 0,
      amountValue: '',
      checked: {},
    });
  }

  onClickSave = () => {
    const {
      chipConditions,
      apportionableCondition,
      priorityValue,
      tagInherited,
    } = this.state;
    if (apportionableCondition === '') {
      this.setState({
        requestEntryOfApportionableCondition: true,
      });
    } else if (priorityValue === 0) {
      this.setState({
        requestEntryOfPriority: true,
      });
    } else {
      const {
        dispatch, periodId, keywordRules, lineItemIds, amount,
        isLineItemsDragged, tags, ruleType, isHighPriority, categories, rules,
      } = this.props;
      const tagDictionary = JSON.stringify(Object.assign({}, ...chipConditions.map(x => (
        {
          [x.tag.tagName]: x.chips,
        }))));
      const tag = tags.map(x => (
        {
          tagName: x.name,
          type: x.type,
          tagid: x.id,
        }));
      dispatch(requestCreateApportionable(
        periodId,
        keywordRules,
        lineItemIds,
        amount,
        isLineItemsDragged,
        ruleType,
        isHighPriority,
        categories,
        rules,
        apportionableCondition,
        tagDictionary,
        priorityValue,
        tagInherited,
        tag,
      ));
      const chipCondition = [];
      this.setState({
        apportionableCondition: '',
        chipConditions: chipCondition,
        requestEntryOfApportionableCondition: false,
        requestEntryOfPriority: false,
        priorityValue: 0,
        amountValue: '',
        checked: {},
      });
    }
  }

  getCondition() {
    const { chipConditions } = this.state;
    if (chipConditions.every(x => x.chips.length === 0)) {
      return '';
    }
    if (chipConditions != null && chipConditions.length > 0) {
      return chipConditions.filter(c => c.chips.length > 0).map(x => `[${x.tag.tagName}] EQUALS (${!x.tag.tagType.includes('Numeric') ? '"' : ''}${x.tag.tagType.includes('Text') ? x.chips.map(c => `${c}`).join('","') : x.chips.map(c => `${c}`).join(',')}${x.tag.tagType.includes('Text') ? '"' : ''})`).join(' AND ');
    }
    return '';
  }

  addChip = (chip, tag) => {
    const { chipConditions } = this.state;
    if (chipConditions.some(x => x.tag.tagName === tag.name)) {
      const requiredTag = chipConditions.find(x => x.tag.tagName === tag.name);
      requiredTag.chips = chip;
      if (requiredTag.chips.length === 0) {
        const index = chipConditions.indexOf(requiredTag);
        chipConditions.splice(index, 1);
      }
    } else {
      const newChipCondition = {
        tag: {
          tagName: tag.name,
          tagType: tag.type,
        },
        chips: chip,
      };
      chipConditions.push(newChipCondition);
    }

    const newCondition = this.getCondition();
    this.setState({
      apportionableCondition: newCondition,
      requestEntryOfApportionableCondition: false,
    });
  }

  removeChip = (chip, tag) => {
    const { chipConditions } = this.state;
    const chipToRemove = chipConditions.find(x => x.tag.tagName === tag.name);

    if (chipToRemove) {
      const chipIndex = chipToRemove.chips.indexOf(chip);
      chipToRemove.chips.splice(chipIndex, 1);
      if (chipToRemove.chips.length === 0) {
        const index = chipConditions.indexOf(chipToRemove);
        chipConditions.splice(index, 1);
      }
      const newCondition = this.getCondition();
      this.setState({
        apportionableCondition: newCondition,
      });
    }
  }

  goBack = () => {
    this.props.dispatch(closeApportionmentModal());
  }

  onTagInheritChange = (e, id, tag) => {
    this.setCheckboxValue(id, e, tag);
  }

  setCheckboxValue(id, value, tag) {
    const { checked, tagInherited } = this.state;
    checked[id] = value;
    tagInherited[tag.name] = value;
    this.setState({
      checked: { ...checked },
      tagInherited: { ...tagInherited },
    });
    if (value === true) {
      this.addChip([apportionmentStrings.inheritedFromTagValueText], tag);
    } else {
      this.removeChip(apportionmentStrings.inheritedFromTagValueText, tag);
      if (tag.name === 'Amount') {
        this.setState({
          amountValue: '',
        });
      }
    }
  }

  render() {
    const {
      classes,
      open,
      tags,
      keywordRules,
      loading,
      tagValues,
      lineItemIds,
      isLineItemsDragged,
    } = this.props;

    if (!open) {
      return null;
    }

    const {
      apportionableCondition,
      requestEntryOfApportionableCondition,
      requestEntryOfPriority,
      checked,
      chipConditions,
      amountValue,
    } = this.state;

    const invalidData = lineItemIds === null || tagValues === null || lineItemIds.length === 0 ||
      tagValues.length === 0;
    const disable = loading || invalidData;

    return (
      <StyledModal open={open}>
        <Paper className={classes.paperRoot}>
          <Choose>
            <When condition={loading}>
              <div className={classes.loader}>
                <CircularLoader />
              </div>
            </When>
            <Otherwise>
              <If condition={!isLineItemsDragged}>
                <Card className={classes.card}>
                  <div className={classes.header}>
                    <b>{apportionmentStrings.apportionable}</b>
                  </div>
                  <span className={classes.keywordRule}>{keywordRules}</span>
                </Card>
              </If>
              <If condition={!invalidData}>
                <Card className={classes.card}>
                  <div className={classes.header}>
                    <b>{apportionmentStrings.apportionmentCondition}</b>
                  </div>
                  <Table>
                    <TableHead>
                      <TableRow className={classes.tableHeaderRow}>
                        <TableCell
                          className={`${classes.headerText} ${classes.headerTableCell} 
                      ${classes.descriptionCellWidth}`}
                          key="TagsLabel"
                          align="center"
                        >
                        Tag
                        </TableCell>
                        <TableCell
                          className={`${classes.headerText} ${classes.headerTableCell} 
                      ${classes.descriptionCellWidth}`}
                          key="InheritLabel"
                          align="center"
                        >
                        Inherit
                        </TableCell>
                        <TableCell
                          className={`${classes.headerText} ${classes.headerTableCell} 
                      ${classes.descriptionCellWidth}`}
                          key="ValueLabel"
                          align="center"
                        >
                        Value
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tags.map((tag, rowIndex) => {
                        const rowKey = `${tag.name}_${rowIndex}`;
                        const userInputChips = chipConditions && chipConditions.length > 0 &&
                          chipConditions.some(y => tag.name === y.tag.tagName) ?
                          chipConditions.find(y => tag.name === y.tag.tagName).chips : [];
                        const inheritId = `Inherit_${rowIndex}`;
                        const tagValue = checked[inheritId] ?
                          [apportionmentStrings.inheritedFromTagValueText] : userInputChips;
                        return (
                          <TableRow key={rowKey}>
                            <TableCell className={classes.tableCell} key={`${rowKey}_${'tagname'}`}>
                              {tag.name}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              <Checkbox
                                className={classes.Checkbox}
                                id={inheritId}
                                onChange={e => this.onTagInheritChange(e, inheritId, tag)}
                                checked={checked[inheritId]}
                                disabled={disable}
                              />
                            </TableCell>
                            <TableCell className={classes.tableCell} key={`${rowKey}_${'value'}`}>
                              <Choose>
                                <When condition={checked[inheritId]}>
                                  <input
                                    value={tagValue}
                                    className={classes.input}
                                    clearInputValueOnChange
                                    disableUnderline
                                    classes={{ chip: classes.customChip }}
                                    disabled={checked[inheritId]}
                                  />
                                </When>
                                <When condition={tag.name === 'Amount' && tag.type === 'Numeric'}>
                                  <Input
                                    className={classes.amountInput}
                                    classes={{ input: classes.textInput }}
                                    disableUnderline
                                    onChange={this.handleAmountChange}
                                    value={amountValue}
                                  />
                                </When>
                                <Otherwise>
                                  <ChipInput
                                    value={tagValue}
                                    onChange={value => this.addChip(value, tag)}
                                    onDelete={value => this.removeChip(value, tag)}
                                    className={classes.input}
                                    clearInputValueOnChange
                                    disableUnderline
                                    classes={{ chip: classes.customChip }}
                                  />
                                </Otherwise>
                              </Choose>
                            </TableCell>
                          </TableRow>);
                      })}
                    </TableBody>
                  </Table>
                </Card>
              </If>
              <Choose>
                <When
                  condition={!requestEntryOfApportionableCondition && lineItemIds !== null
                    && apportionableCondition}
                >
                  <Card className={classes.card}>
                    <span className={classes.definition}>{apportionableCondition}</span>
                  </Card>
                </When>
              </Choose>
              <If condition={!invalidData}>
                <Card className={classes.card}>
                  <StickyTable bodyHeight={50}>
                    <TableHead />
                    <TableBody>
                      <TableRow key="priority">
                        <TableCell className={classes.sequenceText} key="priorityLabel">
                          {apportionmentStrings.apportionmentModalPriority}
                          <MandatoryAsterisk />
                          <Input
                            value={this.state.priorityValue === 0 ? '' : this.state.priorityValue}
                            className={classes.priorityInput}
                            classes={{ input: classes.textInput }}
                            type="number"
                            disableUnderline
                            onChange={this.setPriority}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </StickyTable>
                </Card>
              </If>
              <Choose>
                <When condition={requestEntryOfApportionableCondition}>
                  <Card className={classes.card}>
                    <span className={classes.note}>
                      {apportionmentStrings.enterApportionableTagValues}
                    </span>
                  </Card>
                </When>
                <When condition={invalidData}>
                  <Card className={classes.card}>
                    <span className={classes.note}>
                      {keywordRules ? apportionmentStrings.noLineitemFound
                        : apportionmentStrings.lineItemAlreadyApportioned}
                    </span>
                  </Card>
                </When>
                <When condition={requestEntryOfPriority}>
                  <Card className={classes.card}>
                    <span className={classes.note}>
                      {apportionmentStrings.enterPriority}
                    </span>
                  </Card>
                </When>
              </Choose>
              <Buttons
                onAccept={invalidData ? this.goBack : this.onClickSave}
                onCancel={invalidData ? this.goBack : this.onClickLater}
                acceptText={invalidData ? apportionmentStrings.instructionToGoBack
                  : apportionmentStrings.save}
                cancelText={invalidData ? apportionmentStrings.goBack : apportionmentStrings.later}
                submitDisabled={disable}
              />
            </Otherwise>
          </Choose>
        </Paper>
      </StyledModal>
    );
  }
}

const mapStateToProps = (state) => {
  const tagValueList = state.apportionables.apportionablesModal.tagValuesForDisplay;
  return {
    open: apportionablesResourceSelector(state),
    periodId: periodIdSelector(state),
    tags: state.periods.period.data.tags.filter(x => x.type !== 'NotMapped'),
    keywordRules: state.apportionables.apportionablesModal.keywordRules,
    tagValues: tagValueList == null ? [] : tagValueList.find(x => !x.tagName.startsWith('SYSTEM_UNMAPPED_TAG_Main_')),
    lineItemIds: state.apportionables.apportionablesModal.lineItemIds,
    amount: state.apportionables.apportionablesModal.apportionmentTotalAmount,
    loading: state.apportionables.apportionablesModal.loading,
    isLineItemsDragged: state.apportionables.apportionablesModal.isLineItems,
    ruleType: state.apportionables.apportionablesModal.ruleType,
    isHighPriority: state.apportionables.apportionablesModal.isHighPriority,
    categories: state.apportionables.apportionablesModal.categories,
    rules: state.apportionables.apportionablesModal.rules,
  };
};

ApportionablesModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  periodId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  keywordRules: PropTypes.string.isRequired,
  tagValues: PropTypes.arrayOf(PropTypes.shape({
    tagName: PropTypes.string.isRequired,
    tagValue: PropTypes.node.isRequired,
  })),
  lineItemIds: PropTypes.arrayOf(PropTypes.node).isRequired,
  amount: PropTypes.number,
  loading: PropTypes.bool.isRequired,
  isLineItemsDragged: PropTypes.bool,
  ruleType: PropTypes.number,
  isHighPriority: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.object),
  rules: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ApportionablesModal.defaultProps = {
  amount: 0,
  isLineItemsDragged: false,
  tagValues: [],
  isHighPriority: false,
  ruleType: 0,
  categories: [],
};

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(ApportionablesModal);