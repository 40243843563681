const styles = theme => ({
  root: {
    paddingTop: 30,
    paddingLeft: 30,
  },
  text: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.constants.fontSizes.regular,
    marginBottom: 27,
  },
  form: {
    height: '100%',
  },
  buttons: {
    bottom: '0px',
    width: '100%',
  },
  input: {
    paddingRight: 30,
    paddingTop: 20,
  },
  deleteButton: {
    fontSize: theme.constants.fontSizes.small,
    fontWeight: 'bold',
    height: 40,
    marginBottom: 50,
  },
  fieldMargin: {
    paddingTop: 20,
    paddingRight: 20,
  },
});

export default styles;
