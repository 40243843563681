import { GET_USERS, GET_USERS_SUCCESS, ADD_SELECTED_USER, REMOVE_SELECTED_USER, RESET_USERS } from 'Store/Areas/Setup/Admin/GetUserActions';
import { POST_NEW_USER_SUCCESS } from 'Store/Areas/Setup/Admin/AddUserActions';
import { POST_TOGGLE_ACCESS_SUCCESS } from 'Store/Areas/Setup/Admin/ToggleAccessActions';

const initialState = {
  users: [],
  selectedUserIds: [],
  loading: false,
};

export default function getUsersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
    case GET_USERS_SUCCESS:
      return Object.assign({}, state, {
        users: action.data,
        loading: action.loading,
        selectedUserIds: action.selectedUserIds,
      });
    case POST_NEW_USER_SUCCESS:
      return Object.assign({}, state, {
        users: [...state.users, action.user],
        loading: state.loading,
      });
    case POST_TOGGLE_ACCESS_SUCCESS:
      return Object.assign({}, state, {
        users: state.users.map((user) => {
          if (user.id === action.payload.user.id) {
            return action.payload.user;
          }
          return user;
        }),
        loading: state.loading,
      });
    case ADD_SELECTED_USER:
      return Object.assign({}, state, {
        selectedUserIds: state.selectedUserIds.concat([action.selectedUserId]),
      });
    case REMOVE_SELECTED_USER:
      return Object.assign({}, state, {
        selectedUserIds: state.selectedUserIds.filter(x => x !== action.selectedUserId),
      });
    case RESET_USERS:
      return initialState;
    default:
      return state;
  }
}
