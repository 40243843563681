import { takeLatest, select, put } from 'redux-saga/effects';
import { buildRuleSuccess, validateRule, deleteMultipleSegments } from 'Store/Areas/Rule/RuleBuilderActions';
import { chunkTypes } from 'Store/Areas/Rule/RuleBuilderReducer';
import { textOperators, operatorTypes, validationRules } from 'Constants/Rules/RuleConstants';
import { strings } from 'Constants/Rules/Strings';
import { TagTypes } from 'Constants/Categorization/Tags';
import { validateLuceneRegex } from 'Helpers/LuceneRegexHelper';
import { isValidDate } from 'Helpers/DateHelpers';
import {
  getOperatorType,
  isMultiKeywordOperator,
  isOperatorValidWithTagType,
  operatorAllowsEmptyValues,
} from 'Helpers/OperatorHelpers';

function getCondition(ruleChunk) {
  let condition;
  if (!ruleChunk.data.operator) {
    condition = '';
  } else if (isMultiKeywordOperator(ruleChunk.data.operator, ruleChunk.data.tag.type)) {
    condition = `(${ruleChunk.data.condition.split('\n').map(x => `"${x}"`).join(',')})`;
  } else {
    const operatorType = getOperatorType(ruleChunk.data.operator, ruleChunk.data.tag.type);
    condition = operatorType === operatorTypes.keyword ? `("${ruleChunk.data.condition}")` : `(${ruleChunk.data.condition})`;
  }
  return condition;
}

function* buildRuleSaga() {
  const { chunks } = yield select(x => x.rules.ruleBuilder);
  let rule = '';

  for (let i = 0; i < chunks.length; i += 1) {
    const chunk = chunks[i];
    if (chunk.type === chunkTypes.startBracket) {
      rule += '(';
    }
    if (chunk.type === chunkTypes.ruleRow) {
      const condition = getCondition(chunk);
      const tag = chunk.data.tag.name ? `[${chunk.data.tag.name}]` : '';
      const operator = chunk.data.operator ? chunk.data.operator : '';
      const join = chunk.data.join ? chunk.data.join : '';
      const nextChunk = chunks[i + 1];
      const isEndBracket = nextChunk && nextChunk.type === chunkTypes.endBracket ? ')' : '';
      rule += `${tag} ${operator} ${condition}${isEndBracket}${join ? ` ${join} ` : ''}`;
    }
  }
  yield put(buildRuleSuccess(rule));
}


function* validateRuleSaga() {
  const allChunks = yield select(x => x.rules.ruleBuilder.chunks);
  const chunks = allChunks.filter(x => x.type === chunkTypes.ruleRow);

  let hasError = false;

  for (let i = 0; i < chunks.length; i += 1) {
    const item = chunks[i].data;
    if (!item.tag.name) {
      yield put(validateRule(strings.builderValidation.missingTag(i), true));
      hasError = true;
      break;
    }
    if (!item.operator) {
      yield put(validateRule(strings.builderValidation.missingOperator(i), true));
      hasError = true;
      break;
    }
    if (!item.condition
        || (!item.condition.trim() && !operatorAllowsEmptyValues(item.operator, item.tag.type))) {
      yield put(validateRule(strings.builderValidation.missingCondition(i), true));
      hasError = true;
      break;
    }
    if (!item.join && i < chunks.length - 1) {
      yield put(validateRule(strings.builderValidation.missingJoin(i), true));
      hasError = true;
      break;
    }
    if (item.join && i === chunks.length - 1) {
      yield put(validateRule(strings.builderValidation.unexpectedJoin(i), true));
      hasError = true;
      break;
    }
    if (item.operator && item.tag.type
      && !isOperatorValidWithTagType(item.tag.type, item.operator)) {
      switch (item.tag.type) {
        case TagTypes.Text:
        case TagTypes.TextWholeValue:
          yield put(validateRule(strings.builderValidation.incorrectTextOperator(i), true));
          break;
        case TagTypes.Numeric:
          yield put(validateRule(strings.builderValidation.incorrectNumericOperator(i), true));
          break;
        case TagTypes.Date:
          yield put(validateRule(strings.builderValidation.incorrectDateOperator(i), true));
          break;
        default:
      }
      hasError = true;
      break;
    }
    if (item.operator && item.condition && item.operator === textOperators.matchRegex) {
      if (!validateLuceneRegex(item.condition)) {
        yield put(validateRule(strings.builderValidation.invalidLuceneRegex(i), true));
        hasError = true;
        break;
      } else if (item.condition.length > validationRules.luceneRegexMaxLength) {
        yield put(validateRule(
          strings.builderValidation.invalidLuceneRegexLength(
            i,
            validationRules.luceneRegexMaxLength,
          ),
          true,
        ));
        hasError = true;
      }
    }
    if (item.operator && item.condition && item.tag.type === TagTypes.Date) {
      if (!isValidDate(item.condition)) {
        yield put(validateRule(strings.builderValidation.invalidDateFormat(i), true));
        hasError = true;
        break;
      }
    }
  }

  const startBrackets = allChunks.filter(x => x.type === chunkTypes.startBracket);
  const endBrackets = allChunks.filter(x => x.type === chunkTypes.endBracket);
  if (startBrackets.length > endBrackets.length) {
    yield put(validateRule(strings.missingBracket, true));
    hasError = true;
  }
  if (chunks.length === 0) {
    hasError = true;
    yield put(validateRule('', true));
  }
  if (!hasError) {
    yield put(validateRule('', false));
  }
}

function* removeRedundantBrackets() {
  const { chunks } = yield select(x => x.rules.ruleBuilder);
  let bracketsToRemove = [];
  for (let i = 0; i < chunks.length - 1; i += 1) {
    if (chunks[i].type === chunkTypes.startBracket &&
      chunks[i + 1].type === chunkTypes.endBracket) {
      bracketsToRemove = bracketsToRemove.concat([chunks[i].id, chunks[i + 1].id]);
    }
  }
  if (bracketsToRemove.length > 0) {
    yield put(deleteMultipleSegments(bracketsToRemove));
  }
}

/* Watcher Sagas */
export function* ruleBuilderSagas() {
  yield takeLatest(action => /^RULEBUILDER\/CHANGE\/.*/.test(action.type), buildRuleSaga);
  yield takeLatest(action => /^RULEBUILDER\/CHANGE\/.*/.test(action.type), validateRuleSaga);
  yield takeLatest(action => /^RULEBUILDER\/CHANGE\/.*/.test(action.type), removeRedundantBrackets);
}
