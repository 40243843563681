import {
  REQUEST_DELETE_FILE,
  CANCEL_DELETING,
  BEGIN_DELETE_FILE,
  DELETE_FILE_SUCCESS,
  MAP_FILE_TO_SCHEMA,
  MAP_FILE_TO_SCHEMA_COMPLETED,
  REQUEST_DELETE_SUPPORTING_DOC,
  BEGIN_DELETE_SUPPORTING_DOC,
  DELETE_SUPPORTING_DOC_SUCCESS,
  CANCEL_DELETE_SUPPORTING_DOC,
} from 'Store/Areas/Import/UploadActions';
import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';

const initialState = {
  loading: false,
  deletion: {
    isDelete: false,
    fileName: null,
    fileId: null,
    loading: false,
  },
  supportingDocInfo: {
    isDelete: false,
    fileName: null,
    cloudReference: null,
    loading: false,
  },
  schemaMapping: {
    failed: false,
    fileType: null,
  },
};

export default function uploadReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_PERIOD:
      return initialState;
    case REQUEST_DELETE_FILE:
    case BEGIN_DELETE_FILE:
      return Object.assign({}, state, {
        deletion: {
          isDelete: action.isDelete,
          fileName: action.fileName,
          cloudReference: action.cloudReference,
          loading: action.loading,
        },
      });
    case CANCEL_DELETING:
    case DELETE_FILE_SUCCESS:
      return Object.assign({}, state, {
        deletion: {
          isDelete: action.isDelete,
          fileName: null,
          fileId: null,
        },
      });
    case REQUEST_DELETE_SUPPORTING_DOC:
    case BEGIN_DELETE_SUPPORTING_DOC:
    case CANCEL_DELETE_SUPPORTING_DOC:
    case DELETE_SUPPORTING_DOC_SUCCESS:
      return Object.assign({}, state, {
        supportingDocInfo: {
          isDelete: action.isDelete,
          fileName: action.fileName,
          cloudReference: action.cloudReference,
          loading: action.loading,
        },
      });
    case MAP_FILE_TO_SCHEMA:
      return Object.assign({}, state, {
        loading: true,
        schemaMapping: {
          failed: false,
          fileType: action.payload.fileType,
        },
      });
    case MAP_FILE_TO_SCHEMA_COMPLETED:
      return Object.assign({}, state, {
        loading: false,
        schemaMapping: {
          ...state.schemaMapping,
          failed: !action.success,
        },
      });
    default:
      return state;
  }
}
