export const OPEN_EDIT_RULE = 'EDITRULE/OPEN_EDIT_RULE';
export const CLOSE_EDIT_RULE = 'EDITRULE/CLOSE_EDIT_RULE';

export function openEditingRule() {
  return {
    type: OPEN_EDIT_RULE,
    open: true,
  };
}

export function closeEditingRule() {
  return {
    type: CLOSE_EDIT_RULE,
    open: false,
  };
}
