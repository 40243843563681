import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { getJsonResponse } from 'Store/Api/HandleStatusCode';
import { Post } from 'Store/Api/CallApi';
import { routes } from 'Constants/Routes';
import { RULES_CREATE_RULE, RULES_CREATE_RULES, RULES_EDIT_RULE } from 'Store/Api/ApiEndpoints';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';
import { openApportionmentResourceFromCustomRule, openApportionmentResourceFromKeywordDnd, openApportionmentResourceFromRefList } from 'Store/Areas/Apportionables/ApportionablesModalActions';
import { startPolling } from 'Store/Areas/App/PeriodStatusPollingActions';
import { anyTag } from 'Constants/Categorization/Tags';
import { textOperators, ruleTypes } from 'Constants/Rules/RuleConstants';
import { strings } from 'Constants/Categorization/Strings';

/* eslint-disable class-methods-use-this */
export class CategorisationStrategyHandler {
  constructor(categoryType, handler) {
    this.categoryType = categoryType;
    this.handle = handler.bind(this);
  }

  * handleRuleCreation(payload, periodId) {
    if (payload.isCategoryApportionable) {
      if (payload.isCustomRule) {
        yield put(openApportionmentResourceFromCustomRule(
          payload.parentId,
          payload.keywordRule,
          payload.ruleId,
          payload.ruleType,
          payload.isHighPriority,
          payload.categories,
        ));
      } else if (payload.isKeywordDrag) {
        yield put(openApportionmentResourceFromKeywordDnd(
          payload.parentId,
          payload.keywords,
          payload.rules,
          payload.rules[0].ruleType,
          payload.rules[0].isHighPriority,
          payload.rules[0].categories,
        ));
      } else if (payload.isReferenceListDrag) {
        yield put(openApportionmentResourceFromRefList(
          payload.parentId,
          payload.keywordRule,
          payload.isSubstring,
          payload.referenceListId,
          payload.ruleType,
          payload.isHighPriority,
          payload.categories,
        ));
      }
    } else {
      yield put(setProcessing(true));
      const upsertRuleApiUrl = this.getUpsertRuleApiUrl(payload);
      const response = yield Post(upsertRuleApiUrl, payload);
      const data = yield getJsonResponse(response);
      if (!data) {
        return;
      }
      if (!data.success) {
        yield put(displayToastNotification(data.error));
        yield put(setProcessing(false));
      } else {
        if (upsertRuleApiUrl === RULES_EDIT_RULE) {
          yield put(displayToastNotification(strings.ruleEditedSuccessfullyMessage));
          yield put(push(routes.categorisation.referenceLists));
        }
        yield put(startPolling(periodId));
      }
    }
  }

  getUpsertRuleApiUrl(payload) {
    if (payload.ruleId !== undefined && payload.ruleId !== 0) {
      return RULES_EDIT_RULE;
    } else if (payload.rules && payload.rules.length) {
      return RULES_CREATE_RULES;
    }
    return RULES_CREATE_RULE;
  }

  getCustomRulePayload(action) {
    let rulePayload = {};
    if (action.ruleProperties.ruleId !== undefined && action.ruleProperties.ruleId !== 0) {
      rulePayload = {
        parentId: action.periodId,
        categories: action.categories,
        keywordRule: action.ruleProperties.ruleText,
        note: action.ruleProperties.note,
        isHighPriority: action.ruleProperties.isHighPriority,
        periodId: action.periodId,
        ruleId: action.ruleProperties.ruleId,
        isApportionable: action.ruleProperties.isApportionable,
        ruleType: ruleTypes.customRule,
      };
    } else {
      rulePayload = {
        parentId: action.periodId,
        categories: action.categories,
        keywordRule: action.ruleProperties.ruleText,
        note: action.ruleProperties.note,
        isHighPriority: action.ruleProperties.isHighPriority,
        isCustomRule: true,
        isCategoryApportionable: this.checkApportionment(action.categories),
        ruleType: ruleTypes.customRule,
      };
    }
    return rulePayload;
  }

  checkCategoriesTotalTo1(categories) {
    return categories.map(x => x.percentage).reduce((sum, currentValue) => {
      return sum + currentValue;
    }, 0) === 1;
  }

  checkApportionment(categories) {
    return categories[0].categoryName && categories[0].categoryName.toLowerCase() ===
      strings.apportionable.toLowerCase() && categories[0].color === strings.apportionableColor;
  }

  buildKeywordRule(keywords) {
    return keywords.map(k => `[${k.tag.id === anyTag.id ? anyTag.name : k.tag.name}] ${textOperators.contains} ("${k.keyword}")`).join(' AND ');
  }

  buildReferenceListRule(listName, tagName) {
    return `[${tagName}] ${textOperators.in} ("${listName}")`;
  }
}
