export const strings = {
  activateVersion: 'Activate version',
  addModel: 'Add new model',
  activeVersionText: 'This model is active and currently being used in the Machine Learning stage of categorisation.',
  activeVersion: 'Active',
  newVersionText1: 'This is the first time you are using this Machine Learning version.',
  newVersionText2: 'Click ‘Add new model’ to proceed.',
  versionActivated: version => `'${version}' is now the active version`,
  addModelText: version => `Add new model to '${version}'`,
  editModelText: (model, version) => `Edit '${model}' in '${version}'`,
  createModel: 'Create model',
  enterDescription: 'Enter a description here',
  descriptionLabel: 'Model description (max 450 characters)',
  enterCategories: 'Enter categories here',
  categoriesLabel: 'Categories',
  selectContext: 'Select a tax context',
  selectLicenseType: 'Select a license type',
  selectGroup: 'Select one or more group',
  enterModelName: 'Enter model display name',
  enterMlKey: 'Enter a machine learning key',
  nameLabel: 'Model display name',
  keyLabel: 'ML key',
  contextLabel: 'Tax context',
  licenseTypeLabel: 'License Type',
  groupLabel: 'Group',
  nameRequiredValidation: 'Name is required',
  nameInvalidValidation: 'Name contains forbidden characters',
  nameStartsOrEndsWithSpacesValidation: 'Name starts or ends with spaces',
  modelKeyInvalidValidation: 'Model key contains forbidden characters',
  modelKeyRequiredValidation: 'Model key is required',
  modelKeyStartsOrEndsWithSpacesValidation: 'Model key starts or ends with spaces',
  descriptionInvalidValidation: 'Description contains forbidden characters',
  descriptionRequiredValidation: 'Description is required',
  descriptionMaxCharsValidation: 'Description must be at most 450 characters',
  categoriesInvalidValidation: 'Categories contains forbidden characters',
  categoriesRequiredValidation: 'Categories are required',
  taxContextRequiredValidation: 'Tax context is required',
  modelManagement: 'Model management',
  deleteModel: 'Are you sure you want to delete this model?',
  copyModels: 'Copy models from active version',
  modelsCopied: 'Models are copied from the active version',
};
