const styles = theme => ({
  root: {
    borderRadius: 4,
  },
  distArea: {
    stroke: 'url(#confidence-shading)',
    fill: 'url(#confidence-shading)',
    opacity: 0.8,
  },
  labelX: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto',
    width: 140,
    fontSize: theme.constants.fontSizes.regular,
  },
  labelY: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    transform: 'translateY(60%)',
    width: 110,
    fontSize: theme.constants.fontSizes.regular,
  },
  xAxisWrapper: {
    marginTop: 5,
  },
  yAxisMax: {
    position: 'absolute',
    marginTop: 0,
    width: 110,
    textAlign: 'right',
    left: 0,
    fontSize: theme.constants.fontSizes.regular,
  },
  xAxisMin: {
    float: 'left',
    width: 10,
    fontSize: theme.constants.fontSizes.regular,
    textAlign: 'left',
  },
  xAxisMax: {
    float: 'right',
    fontSize: theme.constants.fontSizes.regular,
    textAlign: 'right',
    marginRight: -10,
  },
  graph: {
    overflow: 'visible',
    borderLeft: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    verticalAlign: 'top',
    width: '100%',
    height: '100%',
  },
  gradientStopNonConfident: {
    stopColor: '#e8e8e8',
  },
  gradientStopConfident: {
    stopColor: '#c2e5f6',
  },
  separatorCircle: {
    fill: '#0091da',
    opacity: 0.9,
  },
  separatorLine: {
    stroke: '#0091da',
    strokeWidth: 3,
    strokeDasharray: '5, 5',
  },
});

export default styles;
