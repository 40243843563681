const styles = theme => ({
  headerText: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  mainText: {
    fontWeight: 'normal',
    fontSize: theme.constants.fontSizes.regular,
  },
  submitButton: {
    width: 180,
    height: 40,
    margin: '10px 10px 10px 0',
  },
  descriptionWarning: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 15,
    color: 'red',
  },
  validationError: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'normal',
    marginBottom: 15,
    color: 'red',
  },
  menuItem: {
    fontSize: theme.constants.fontSizes.regular,
    height: '25px',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.colors.white,
    color: theme.palette.colors.black,
  },
  optionSelected: {
    backgroundColor: theme.palette.primary.white,
    color: theme.palette.colors.blue,
  },
});

export default styles;