import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export const constants = {
  permissionLevels: {
    requireSysAdmin: 1,
    requireGroupAdmin: 2,
    requireAdmin: 3,
    requirePeriodAdmin: 4,
    requireEntityAdmin: 5,
  },
};

class AuthorisedComponent extends React.PureComponent {
  render() {
    const {
      permissions,
      requiredPermissions,
      children,
      periodGroupId,
      periodEntityId,
    } = this.props;

    let authorised = false;

    switch (requiredPermissions) {
      case constants.permissionLevels.requireSysAdmin:
        authorised = permissions.isSysAdmin;
        break;
      case constants.permissionLevels.requireGroupAdmin:
        authorised = permissions.isGroupAdmin;
        break;
      case constants.permissionLevels.requirePeriodAdmin:
        authorised = (periodGroupId && permissions.isGroupAdmin && permissions.groupAdmins
          && permissions.groupAdmins.includes(periodGroupId)) ||
          (periodEntityId && permissions.isEntityAdmin && permissions.entityAdmins
            && permissions.entityAdmins.includes(periodEntityId));
        break;
      case constants.permissionLevels.requireAdmin:
        authorised = permissions.isSysAdmin || permissions.isGroupAdmin;
        break;
      case constants.permissionLevels.requireEntityAdmin:
        authorised = permissions.isSysAdmin || permissions.isGroupAdmin
        || permissions.isEntityAdmin;
        break;
      default:
        break;
    }

    if (authorised) {
      return (
        <Fragment>
          {children}
        </Fragment>
      );
    }

    return (<div />);
  }
}

AuthorisedComponent.defaultProps = {
  periodGroupId: null,
  periodEntityId: null,
};

AuthorisedComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  requiredPermissions: PropTypes.oneOf(Object.values(constants.permissionLevels)).isRequired,
  periodGroupId: PropTypes.number,
  periodEntityId: PropTypes.number,
  permissions: PropTypes.shape({
    name: PropTypes.string,
    isGroupAdmin: PropTypes.bool,
    isSysAdmin: PropTypes.bool,
    emailAddress: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    permissions: state.user.permissions.data,
  };
}

export default connect(mapStateToProps)(AuthorisedComponent);
