const styles = theme => ({
  tableContainer: {
    margin: '30px 0 0 0',
    width: '100%',
  },
  columnHeader: {
    fontSize: theme.constants.fontSizes.small,
    color: theme.palette.primary.darkText,
  },
  cell: {
    padding: '0 30px 0 0',
    minWidth: 169,
    maxWidth: 169,
    maxHeight: 60,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  cellCantDrop: {
    opacity: '0.2 !important',
  },
});

export default styles;
