import React from 'react';
import { connect } from 'react-redux';
import { withStyles, withTheme, Hidden } from '@material-ui/core';
import { routes } from 'Constants/Routes';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { processingSelector } from 'Store/Areas/Rule/CategorisationSelectors';
import { strings } from 'Constants/Categorization/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import Message from 'Components/Shared/Message/Message';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Blur from 'Components/Shared/Blur/Blur';
import Sidebar from 'Components/UserSidebar/Sidebar';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import ArrowButton, {
  constants as arrowButtonConstants,
} from 'Components/Shared/Buttons/ArrowButton/ArrowButton';
import { selectCategoryForReview, resetApportionable } from 'Store/Areas/Categorisation/ReviewCategoryActions';
import { isReadOnlySelector } from 'Store/Areas/Period/PeriodSelectors';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import { calculateApportionment } from 'Store/Areas/Apportionables/ApportionablesActions';
import { styles } from './ApportionableManager.styles';
import ApportionableCondition from './ApportionableCondition';
import ApportionableLineItems from './ApportionableLineItems';
import { apportionmentStrings } from './ApportionablesStrings';


class ApportionableManager extends React.PureComponent {
  onProceedClick = () => {
    const {
      category,
      dispatch,
      period,
    } = this.props;
    dispatch(selectCategoryForReview(category, period.periodId, true));
    dispatch(push(routes.categoryReview));
  }

  onBackClick = () => {
    const { dispatch } = this.props;
    dispatch(resetApportionable());
    dispatch(push(routes.categorisation.referenceLists));
  }

  onCalculateClick = () => {
    const { dispatch, period } = this.props;
    dispatch(calculateApportionment(period.periodId));
  }

  render() {
    const {
      classes,
      period,
      processing,
      currencySymbol,
      isReadOnly,
    } = this.props;

    return (
      <UserSidebarStructure
        isCollapsible
        collapsed
        sidebarContent={
          <Sidebar />
        }
        mainContent={
          <Blur blur={processing} lightBackground displayLoader>
            <div>
              <div className={classes.arrowAlignment}>
                <ArrowButton
                  direction={arrowButtonConstants.direction.back}
                  text={strings.back}
                  onClickEvent={this.onBackClick}
                />|<ArrowButton
                  direction={arrowButtonConstants.direction.forwards}
                  text={strings.summary}
                  onClickEvent={this.onProceedClick}
                />
              </div>
              <div className={classes.root}>
                <div align="up">
                  <Hidden smDown>
                    <Message
                      title={commonStrings.didYouKnow}
                      className={classes.message}
                      contentClassName={classes.messageContent}
                      message={apportionmentStrings.didYouKnowMessage}
                    />
                  </Hidden>
                </div>
                <If condition={period.data && period.data.categories}>
                  <div className={classes.grid}>
                    <div className={classes.conditions}>
                      <ApportionableCondition />
                    </div>
                    <div className={classes.keywords}>
                      <ApportionableLineItems
                        currencySymbol={currencySymbol}
                      />
                    </div>
                  </div>
                  <If condition={!isReadOnly}>
                    <div align="right">
                      <Button
                        disableRipple
                        backgroundColor={constants.backgroundColor.main}
                        height={constants.height.regular}
                        className={classes.Button}
                        onClick={this.onCalculateClick}
                      >
                        {apportionmentStrings.calculateApportionment}
                      </Button>
                    </div>
                  </If>
                </If>
              </div>
            </div>
          </Blur>
        }
      />
    );
  }
}

ApportionableManager.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  category: PropTypes.shape({
    id: PropTypes.number,
    color: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  period: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    data: PropTypes.object,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { category } = state.categorisation.reviewCategory;

  return {
    projects: state.projects,
    category: category,
    period: state.periods.period,
    processing: processingSelector(state),
    currencySymbol: currencySymbolSelector(state),
    isReadOnly: isReadOnlySelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(ApportionableManager);
