const styles = theme => ({
  muiInput: {
    padding: '5px 10px',
  },
  label: {
    color: '#bb1f4a',
    paddingTop: 10,
    fontSize: theme.constants.fontSizes.regular,
  },
  hidden: {
    display: 'none',
  },
  input: {
    height: 40,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  text: {
    fontSize: theme.constants.fontSizes.regular,
  },
  regular: {
    borderColor: '#979797',
  },
  error: {
    borderColor: theme.palette.primary.error,
  },
});

export default styles;
