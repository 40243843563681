import React from 'react';
import CheckboxIcon from 'Assets/Images/check-box-ic.svg';
import CheckboxCheckedIcon from 'Assets/Images/check-box-ic-checked.svg';
import DisabledCheckboxIcon from 'Assets/Images/check-box-ic-disabled.svg';
import PropTypes from 'prop-types';
import { Checkbox as MuiCheckbox, withStyles } from '@material-ui/core';
import { styles } from './Checkbox.styles';

class Checkbox extends React.PureComponent {
  onCheckChange = (ev, checked) => {
    this.props.onChange(checked, this.props.id);
  }

  render() {
    const {
      id,
      checked,
      disabled,
      onClick,
      classes,
      onChange,
      ...extraProps
    } = this.props;
    return (
      <MuiCheckbox
        classes={{
          checked: classes.checked,
        }}
        id={id}
        onClick={onClick}
        onChange={this.onCheckChange}
        checked={checked}
        disabled={disabled}
        icon={<img src={CheckboxIcon} alt="" />}
        checkedIcon={<img src={disabled ? DisabledCheckboxIcon : CheckboxCheckedIcon} alt="" />}
        {...extraProps}
      />
    );
  }
}

Checkbox.defaultProps = {
  id: '',
  disabled: false,
  checked: false,
  onClick: () => {},
  onChange: () => {},
};

Checkbox.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(Checkbox);
