export const GET_NEXT_CONFLICT = 'CONFLICTS/GET_NEXT_CONFLICT';
export const GET_NEXT_CONFLICT_SUCCESS = 'CONFLICTS/GET_NEXT_CONFLICT_SUCCESS';
export const PUT_RESOLVE_CONFLICT = 'CONFLICTS/PUT_RESOLVE_CONFLICT';
export const PUT_ALLOW_CONFLICT = 'CONFLICTS/PUT_ALLOW_CONFLICT';
export const PUT_ALLOW_ALL_CONFLICT = 'CONFLICTS/PUT_ALLOW_ALL_CONFLICT';
export const PUT_DELETE_CONFLICT = 'CONFLICTS/PUT_DELETE_CONFLICT';
export const SET_CONFLICT_ACTION = 'CONFLICTS/SET_CONFLICT_ACTION';
export const RESET_CONFLICT = 'CONFLICTS/RESET_CONFLICT';

export function getNextConflict(periodId) {
  return {
    type: GET_NEXT_CONFLICT,
    payload: {
      periodId,
    },
  };
}

export function getNextConflictSuccess(periodId, { conflictingRules, totalNumberOfConflicts }) {
  return {
    type: GET_NEXT_CONFLICT_SUCCESS,
    periodId,
    loading: false,
    success: true,
    conflicted: !!(conflictingRules && conflictingRules.length),
    conflicts: conflictingRules || [],
    totalNumberOfConflicts: totalNumberOfConflicts || 0,
  };
}

export function putResolveConflict(
  periodId,
  winningRuleId,
  winningRuleText,
  losingRuleIds,
  losingRuleTexts,
) {
  return {
    type: PUT_RESOLVE_CONFLICT,
    payload: {
      periodId,
      winningRuleId,
      winningRuleText,
      losingRuleIds,
      losingRuleTexts,
    },
    loading: true,
    success: false,
    isConflictAction: true,
  };
}

export function putAllowConflict(
  periodId,
  ruleIds,
  ruleTexts,
) {
  return {
    type: PUT_ALLOW_CONFLICT,
    payload: {
      periodId,
      ruleIds,
      ruleTexts,
    },
    loading: true,
    success: false,
    isConflictAction: true,
  };
}

export function putAllowAllConflict(periodId) {
  return {
    type: PUT_ALLOW_ALL_CONFLICT,
    payload: {
      periodId,
    },
    loading: true,
    success: false,
    isConflictAction: true,
  };
}

export function putDeleteConflict(periodId, ruleIds, ruleTexts) {
  return {
    type: PUT_DELETE_CONFLICT,
    payload: {
      periodId,
      ruleIds,
      ruleTexts,
    },
    loading: true,
    success: false,
    isConflictAction: true,
  };
}

export function setConflictAction(isConflictAction = true) {
  return {
    type: SET_CONFLICT_ACTION,
    isConflictAction,
  };
}

export function resetConflict() {
  return {
    type: RESET_CONFLICT,
  };
}
