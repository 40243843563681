import {
  REVIEW_CATEGORY,
  RESET_REVIEW_CATEGORY,
  GET_POPULAR_KEYWORDS_SUCCESS,
  GET_POPULAR_KEYWORDS_BY_TAG,
  GET_POPULAR_KEYWORDS_BY_TAG_SUCCESS,
  SELECT_TAG,
  RESET_APPORITONABLE,
} from 'Store/Areas/Categorisation/ReviewCategoryActions';

const initialState = {
  popularKeywordsByTag: [],
  popularKeywords: null,
  category: {},
  periodId: 0,
  loading: true,
  pageNumber: 1,
  tagId: -1,
  hasMore: true,
  popularLoading: true,
  isApportionable: false,
};

export default function reviewCategoryReducer(state = initialState, action) {
  switch (action.type) {
    case REVIEW_CATEGORY:
      return Object.assign({}, state, {
        popularKeywords: null,
        category: action.payload.category,
        periodId: action.payload.periodId,
        isApportionable: action.payload.isApportionable,
      });
    case GET_POPULAR_KEYWORDS_SUCCESS:
      return Object.assign({}, state, {
        popularKeywords: action.payload.popularKeywords,
      });
    case GET_POPULAR_KEYWORDS_BY_TAG:
      return Object.assign({}, state, {
        popularLoading: true,
        hasMore: false,
      });
    case GET_POPULAR_KEYWORDS_BY_TAG_SUCCESS:
      return Object.assign({}, state, {
        popularKeywordsByTag: action.popularKeywordsByTag,
        pageNumber: action.pageNumber,
        hasMore: action.hasMore,
        popularLoading: false,
      });
    case SELECT_TAG:
      return Object.assign({}, state, {
        tagId: action.tagId,
        popularKeywordsByTag: [],
        hasMore: true,
        pageNumber: 1,
      });
    case RESET_APPORITONABLE:
      return Object.assign({}, state, {
        isApportionable: false,
      });
    case RESET_REVIEW_CATEGORY:
      return initialState;
    default:
      return state;
  }
}
