import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TreePickerNode from 'Components/Shared/TreePicker/TreePickerNode';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { levels } from 'Constants/EntityLevels';
import { getPeriodStatusDescription } from 'Constants/PeriodStatuses';
import { strings } from 'Constants/TreePicker/Strings';
import styles from './ProjectsTreePicker.styles';

/* eslint-disable class-methods-use-this */
class ProjectsTreePicker extends React.Component {
  generateKey(nodeLevel, id) {
    return `${nodeLevel}_${id}`;
  }

  isEntityHidden = (permissions, entity) => {
    if (permissions.entityAdmins.includes(entity.id) ||
        permissions.groupAdmins.includes(entity.groupId)) {
      return false;
    }

    const projectsAccessForCurrentUser = [
      ...permissions.projectsCanAddPeriod,
      ...permissions.projectsCanEdit,
      ...permissions.projectsCanAccessAsReadOnly,
    ];
    const projectsAccessForCurrentEntity = entity.projects.map(a => a.projectId);

    const matchedProjects = [projectsAccessForCurrentUser, projectsAccessForCurrentEntity]
      .reduce((a, b) => a.filter(i => b.includes(i)));

    if (matchedProjects.length !== 0) {
      return false;
    }
    return true;
  };

  isProjectHidden = (permissions, project) => {
    const projectsAccessForCurrentUser = [
      ...permissions.projectsCanAddPeriod,
      ...permissions.projectsCanEdit,
      ...permissions.projectsCanAccessAsReadOnly,
    ];

    if (projectsAccessForCurrentUser.includes(project.id)) {
      return false;
    }
    return true;
  };

  render() {
    const {
      data,
      classes,
      onEditPeriodClick,
      onAddPeriodClick,
      onPeriodSelection,
      userTreePicker,
      onTreeItemClick,
      permissions,
    } = this.props;
    const {
      selectedIndex,
      selectedGroupId,
      selectedEntityId,
      selectedProjectId,
      selectedPeriodId,
    } = userTreePicker;
    let index = -1;
    const filteredData = [...new Map(data.map(item => [item.id, item])).values()];
    return (
      <Fragment>
        {filteredData.map((group) => {
          index += 1;
          return (
            <TreePickerNode
              index={index}
              key={this.generateKey(0, group.id)}
              title={group.name}
              nodeLevel={0}
              onNodeClick={() => onTreeItemClick(group.id, -1, -1, levels.group)}
              expanded={selectedGroupId === group.id}
              expandable
              hideBorder={selectedIndex > -1 &&
                (index === selectedIndex || index === selectedIndex - 1)}
            >
              {group.entities.map((entity) => {
                if (this.isEntityHidden(permissions, entity)) {
                  return () => {};
                }
                index += 1;
                return (
                  <TreePickerNode
                    index={index}
                    key={this.generateKey(1, entity.id)}
                    title={entity.name}
                    nodeLevel={1}
                    onNodeClick={() =>
                      onTreeItemClick(group.id, entity.id, -1, levels.entity)}
                    expanded={selectedEntityId === entity.id}
                    expandable
                    hideBorder={selectedIndex > -1 &&
                      (index === selectedIndex || index === selectedIndex - 1)}
                  >
                    {entity.projects.map((project) => {
                      if (this.isProjectHidden(permissions, project)) {
                        return () => {};
                      }
                      index += 1;
                      return (
                        <TreePickerNode
                          index={index}
                          key={this.generateKey(2, project.id)}
                          title={project.name}
                          nodeLevel={2}
                          onNodeClick={() =>
                            onTreeItemClick(group.id, entity.id, project.id, levels.project)}
                          expanded={selectedProjectId === project.id}
                          expandable
                          hideBorder={selectedIndex > -1 &&
                            (index === selectedIndex || index === selectedIndex - 1)}
                        >
                          {project.periods.map((period) => {
                            index += 1;
                            const statusDescription = getPeriodStatusDescription(period) || '';
                            const sanitisedStatusDescription = statusDescription.split(' ').join('');

                            return (
                              <TreePickerNode
                                index={index}
                                key={this.generateKey(3, period.id)}
                                title={
                                  <Fragment>
                                    <span>{period.name}</span>
                                    <If condition={statusDescription}>
                                      <span
                                        className={
                                          classes[sanitisedStatusDescription.toLowerCase()]
                                        }
                                      >
                                        &nbsp;&nbsp;({statusDescription})
                                      </span>
                                    </If>
                                  </Fragment>
                                }
                                nodeLevel={3}
                                enableChangeStatusText={permissions.isSysAdmin &&
                                  strings.changePeriodStatusLinkHoverText}
                                hoverText={permissions.projectsCanAddPeriod.includes(project.id) &&
                                  strings.editLinkHoverText}
                                onNodeClick={nodeIndex =>
                                  onPeriodSelection(period.id, nodeIndex)}
                                onHoverClick={() =>
                                  onEditPeriodClick(
                                    group.id,
                                    entity.id,
                                    project.id,
                                    period.id,
                                    project.taxContextId,
                                    period.templateId,
                                  )}
                                period={period}
                                selected={selectedPeriodId === period.id}
                                hideBorder={selectedIndex > -1 &&
                                  (index === selectedIndex || index === selectedIndex - 1)}
                              />
                            );
                          })}
                          <If condition={permissions.projectsCanAddPeriod.includes(project.id)}>
                            <TreePickerNode
                              title={strings.addNewPeriodNodeText}
                              nodeLevel={3}
                              add
                              onNodeClick={() => onAddPeriodClick(group, entity, project)}
                            />
                          </If>
                        </TreePickerNode>
                      );
                    })}
                  </TreePickerNode>
                );
              })}
            </TreePickerNode>
          );
        })}
      </Fragment>
    );
  }
}

ProjectsTreePicker.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  userTreePicker: PropTypes.shape({
    selectedGroupId: PropTypes.number,
    selectedEntityId: PropTypes.number,
    selectedProjectId: PropTypes.number,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onAddPeriodClick: PropTypes.func.isRequired,
  onEditPeriodClick: PropTypes.func.isRequired,
  onPeriodSelection: PropTypes.func.isRequired,
  onTreeItemClick: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    projectsCanAddPeriod: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    permissions: state.user.permissions.data,
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(ProjectsTreePicker);
