import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTheme, withStyles, Button } from '@material-ui/core';
import { navLinks } from 'Constants/NavLinks';
import KPMGLogo from 'Assets/Images/kpmg-logo.png';
import { routes } from 'Constants/Routes';
import { getAdminToggleRoute, getAbsoluteUrlForRoute, userInAdminMode } from 'Helpers/RouteHelpers';
import { triggerWorkInProgressWarning } from 'Store/Areas/App/MenuModeActions';
import { periodStatusSelector, periodIsLockedSelector, periodSelector, periodLockedByUserIdSelector, changeSupportingDocsAfterPeriodCreationSelector } from 'Store/Areas/Period/PeriodSelectors';
import AuthorisedComponent, { constants } from 'Components/Shared/AuthorisedComponent/AuthorisedComponent';
import WorkInProgress from 'Components/Layout/WorkInProgress/WorkInProgress';
import { currentRouteSelector } from 'Store/Areas/Router/RouteSelectors';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { resetPeriodLock } from 'Store/Areas/App/ConfigActions';
import MenuItem from './MenuItem';
import MenuToggle from './MenuToggle';
import styles from './Menu.styles';

class Menu extends React.PureComponent {
  getLink(item) {
    if (!this.isLinkEnabled(item)) {
      return '';
    }

    if (typeof item.primaryLink === 'string') {
      // Return the actual link.
      return item.primaryLink;
    } else if (typeof item.primaryLink === 'number') {
      // Return the link at the specified index.
      return item.links[item.primaryLink];
    } else if (typeof item.primaryLink === 'function') {
      // Call the function with the current period status and return the resultant link.
      return item.primaryLink(this.props);
    }

    // If primary link is not set treat the first link as the default.
    return item.links[0];
  }

  getLinksFilter(item, isAdminMode, permissions) {
    if (isAdminMode) {
      if (permissions.isSysAdmin) {
        return item.admin;
      }
      if (permissions.isGroupAdmin) {
        return item.admin && !item.systemAdmin;
      }
      if (permissions.isEntityAdmin) {
        if (item.name === 'Setup' || item.name === 'Bulk Upload') {
          return true;
        }
        return false;
      }
    }
    return item.user;
  }

  clickMenuToggle = (togglingToAdmin) => {
    const { dispatch, menuMode } = this.props;
    if (menuMode.workInProgress) {
      dispatch(triggerWorkInProgressWarning());
    } else {
      dispatch(resetPeriodLock(getAbsoluteUrlForRoute(getAdminToggleRoute(togglingToAdmin))));
    }
  }

  clickBrandLogo = () => {
    this.props.dispatch(resetPeriodLock(getAbsoluteUrlForRoute(routes.home)));
  }

  isLinkEnabled(link) {
    const {
      periodStatus,
      projects,
      periodConflicted,
      periodLocked,
      changeSupportingDocsAfterPeriodCreation,
      period: { data: { lineItems } },
    } = this.props;

    if (link.admin) {
      return true;
    }

    if (periodConflicted) {
      return false;
    }

    const periodSelected = projects.userTreePicker.selectedPeriodId !== -1;

    if (periodSelected && periodStatus === periodStatuses.externalReviewImportFailed) {
      return false;
    }

    switch (link.name) {
      case 'Import':
        return periodSelected &&
          (periodStatus === periodStatuses.notStarted ||
            periodStatus === periodStatuses.readyForUserCategorisation ||
            (lineItems && periodLocked && !changeSupportingDocsAfterPeriodCreation));
      case 'Action log':
        return periodSelected;
      case 'Categorisation':
        if (!periodSelected) {
          return false;
        } else if (periodStatus === periodStatuses.notStarted ||
          periodStatus === periodStatuses.supportingDocumentsSetup ||
          periodStatus === periodStatuses.periodArchived) {
          return false;
        } else if (periodLocked) {
          return true;
        }

        switch (periodStatus) {
          case periodStatuses.readyForUserCategorisation:
          case periodStatuses.userCategorizationRunning:
          case periodStatuses.userCategorizationFailed:
            return true;
          default:
            return false;
        }
      case 'Review':
        if (!periodSelected) {
          return false;
        } else if (periodStatus === periodStatuses.notStarted ||
          periodStatus === periodStatuses.supportingDocumentsSetup ||
          periodStatus === periodStatuses.periodArchived) {
          return false;
        } else if (periodLocked) {
          return true;
        }

        switch (periodStatus) {
          case periodStatuses.readyForUserCategorisation:
          case periodStatuses.exportRunning:
            return true;
          default:
            return false;
        }
      case 'Upload':
        return true;
      case 'Export':
        if (!periodSelected) {
          return false;
        } else if (periodStatus === periodStatuses.notStarted ||
          periodStatus === periodStatuses.supportingDocumentsSetup) {
          return false;
        } else if (periodLocked) {
          return true;
        }

        switch (periodStatus) {
          case periodStatuses.readyForUserCategorisation:
          case periodStatuses.exportQueued:
          case periodStatuses.archiveQueued:
          case periodStatuses.exportRunning:
          case periodStatuses.archiveRunning:
          case periodStatuses.periodArchived:
            return true;
          default:
            return false;
        }
      default:
        return false;
    }
  }

  render() {
    const {
      classes,
      currentRoute,
      permissions,
    } = this.props;

    const isAdminMode = userInAdminMode(currentRoute);
    const links = navLinks.filter(item => this.getLinksFilter(item, isAdminMode, permissions));

    return (
      <div>
        <Button
          classes={{ root: classes.brandButton }}
          onClick={this.clickBrandLogo}
        >
          <div className={classes.brand}>
            <img alt="brand" src={KPMGLogo} className={classes.brandImage} />
          </div>
        </Button>

        {links.map((item) => {
          return (
            <div key={item.name}>
              <With linkUrl={this.getLink(item)}>
                <MenuItem
                  link={linkUrl}
                  image={item.img}
                  text={item.name}
                  active={item.links.indexOf(currentRoute) > -1}
                  enabled={!!linkUrl}
                />
              </With>
            </div>
          );
        })}

        <AuthorisedComponent
          requiredPermissions={constants.permissionLevels.requireEntityAdmin}
        >
          <div className={classes.bottom}>
            <MenuToggle
              isAdminMode={isAdminMode}
              clickMenuToggle={this.clickMenuToggle}
            />
          </div>
          <WorkInProgress isAdminMode={isAdminMode} />
        </AuthorisedComponent>
      </div>
    );
  }
}

Menu.defaultProps = {
  periodConflicted: false,
};

Menu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentRoute: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
    }),
  }).isRequired,
  menuMode: PropTypes.shape({
    workInProgress: PropTypes.bool.isRequired,
    returnRoute: PropTypes.string,
  }).isRequired,
  periodStatus: PropTypes.string.isRequired,
  periodLocked: PropTypes.bool.isRequired,
  changeSupportingDocsAfterPeriodCreation: PropTypes.bool.isRequired,
  periodConflicted: PropTypes.bool,
  period: PropTypes.shape({
    data: PropTypes.shape({
      lineItems: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    isSysAdmin: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    currentRoute: currentRouteSelector(state),
    projects: state.projects,
    menuMode: state.app.menuMode,
    periodStatus: periodStatusSelector(state),
    periodLocked: periodIsLockedSelector(state),
    periodLockedByUserId: periodLockedByUserIdSelector(state),
    changeSupportingDocsAfterPeriodCreation: changeSupportingDocsAfterPeriodCreationSelector(state),
    periodConflicted: state.periods.periodConflicts.conflicted,
    period: periodSelector(state),
    permissions: state.user.permissions.data,
  };
}

export default compose(
  withTheme(),
  withStyles(styles),
  connect(mapStateToProps),
)(Menu);
