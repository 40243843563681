import Yup from 'yup';
import { allowedCharacters, beginsOrEndsInSpaces, caseInsensitiveDuplicateTestOptions } from '../../ValidationHelpers';

export function getCreateProjectSchema(projectNames) {
  return (
    Yup.object().shape({
      group: Yup.number()
        .min(1, 'Group is required.'),
      entity: Yup.number()
        .min(1, 'Entity is required.'),
      project: Yup.string()
        .required('Project is required.')
        .test(caseInsensitiveDuplicateTestOptions(
          projectNames,
          'Project names must be unique (irrespective of capitalisation).',
        ))
        .notOneOf(projectNames, 'Project names must be unique.')
        .max(50, 'Project names must be less than 50 characters long.')
        .matches(allowedCharacters, 'Project name contains forbidden characters.')
        .matches(beginsOrEndsInSpaces, 'Project name must not begin or end with spaces.'),
      context: Yup.number()
        .min(1, 'Context is required.'),
    })
  );
}
