import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Sidebar from 'Components/UserSidebar/Sidebar';
import PivotTableBuilder from './PivotTableBuilder';

class ExportPivotTableManager extends React.PureComponent {
  render() {
    const {
      projects,
    } = this.props;

    const mainContentLoading = projects.treePicker.loading;

    return (
      <UserSidebarStructure
        mainContent={
          <Fragment>
            <PivotTableBuilder />
          </Fragment>
        }
        sidebarContent={<Sidebar />}
        sidebarContentLoading={false}
        mainContentLoading={mainContentLoading}
        isCollapsible
        collapsed
      />
    );
  }
}

ExportPivotTableManager.propTypes = {
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
};
function mapStateToProps(state) {
  return {
    projects: state.projects,
  };
}

export default compose(connect(mapStateToProps))(ExportPivotTableManager);