import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme, Card } from '@material-ui/core';
import { connect } from 'react-redux';
import { strings } from 'Constants/BulkUpload/Strings';
import greenTick from 'Assets/Images/green-tick.svg';
import redCross from 'Assets/Images/exit-ic-red.svg';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import { resetBulkUserUploadState } from 'Store/Areas/BulkUpload/BulkUserUploadActions';
import { bulkUserUploadImportStages } from 'Constants/BulkUpload/BulkUserUploadStages';
import styles from './BulkUserUploadResult.styles';

class BulkUserUploadResult extends React.PureComponent {
  onProceedClick = () => {
    const { dispatch } = this.props;
    dispatch(resetBulkUserUploadState());
    dispatch(push(routes.bulkUpload));
  }
  render() {
    const {
      classes,
      bulkUserUpload,
    } = this.props;
    return (
      <Card className={classes.card}>
        <div className={classes.content}>
          <Choose>
            <When condition={bulkUserUpload.stage === bulkUserUploadImportStages.bulkUsersUploaded}>
              <div className={classes.title}>
                <img alt="" className={classes.tick} src={greenTick} />
                {strings.bulkUserUploadSuccessful}
              </div>
              <div className={classes.textArea}>
                <div className={classes.note}>
                  {strings.bulkUserUploadSuccessfulNote}
                </div>
                {bulkUserUpload.hasImportMessages &&
                <div>
                  <h4>{strings.warnings}</h4>
                  <h5>{strings.itemWarnings}</h5>
                  {bulkUserUpload.importMessages.map((x) => {
                    return <p>{x}</p>;
                  })}
                </div>
                }
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  disableRipple
                  backgroundColor={constants.backgroundColor.main}
                  className={classes.button}
                  onClick={this.onProceedClick}
                >
                  {strings.outcomeBulkUserUploadButton}
                </Button>
              </div>
            </When>
            <Otherwise>
              <div className={classes.title}>
                <img alt="" className={classes.tick} src={redCross} />
                {strings.bulkUserUploadFailed}
              </div>
              <div className={classes.textArea}>
                <div className={classes.note}>
                  {strings.bulkUserUploadFailedNote}
                </div>
                {bulkUserUpload.hasImportMessages &&
                <div>
                  <h4>Errors</h4>
                  {bulkUserUpload.importMessages.map((x) => {
                    return <p>{x}</p>;
                  })}
                </div>
                }
                <div />
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  disableRipple
                  backgroundColor={constants.backgroundColor.main}
                  className={classes.button}
                  onClick={this.onProceedClick}
                >
                  {strings.outcomeBulkUserUploadButton}
                </Button>
              </div>
            </Otherwise>
          </Choose>
        </div >
      </Card >
    );
  }
}

BulkUserUploadResult.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  bulkUserUpload: PropTypes.shape({
    stage: PropTypes.number.isRequired,
    hasImportMessages: PropTypes.bool.isRequired,
    importMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    bulkUserUpload: state.bulkUserUpload,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(BulkUserUploadResult);
