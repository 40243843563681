import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { Post } from 'Store/Api/CallApi';
import { CREATE_APPORTIONABLE_URL, GET_LINE_ITEMS_APPORTIONABLES } from 'Store/Api/ApiEndpoints';
import { apportionmentStrings } from 'Components/Apportionables/ApportionablesStrings';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { requestDeleteRules } from 'Store/Areas/Rule/DeleteRuleActions';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';
import { apportionLineItemsSuccess, APPORTIONMENT_RESOURCE_FROM_CUSTOM_RULE, APPORTIONMENT_RESOURCE_FROM_REFLIST, REQUEST_CREATE_APPORTIONABLE, APPORTIONMENT_RESOURCE_FROM_KEYWORD, APPORTIONMENT_RESOURCE_FROM_LINE_ITEM, closeApportionmentModal } from './ApportionablesModalActions';


/* Worker Sagas */
function* requestCreateApportionable(action) {
  yield put(setProcessing(true));
  const response = yield Post(CREATE_APPORTIONABLE_URL, action.payload);
  if (response.ok) {
    yield put(displayToastNotification(apportionmentStrings.apportionableStarted));
  } else {
    yield put(displayToastNotification(apportionmentStrings.apportionableCreationFailed));
  }
}

function* apportionLineItems(action) {
  const response = yield Post(GET_LINE_ITEMS_APPORTIONABLES, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(apportionLineItemsSuccess(data));
    if (data.success && action.payload.ruleId) {
      yield put(requestDeleteRules(
        action.payload.periodId,
        Array.isArray(action.payload.ruleId) ? action.payload.ruleId : [action.payload.ruleId],
        Array.isArray(action.payload.keywordRule) ? action.payload.keywordRule :
          [action.payload.keywordRule],
      ));
    }
  } else {
    yield put(closeApportionmentModal());
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* apportionablesModalSagas() {
  yield takeLatest(REQUEST_CREATE_APPORTIONABLE, requestCreateApportionable);
  yield takeLatest(APPORTIONMENT_RESOURCE_FROM_KEYWORD, apportionLineItems);
  yield takeLatest(APPORTIONMENT_RESOURCE_FROM_LINE_ITEM, apportionLineItems);
  yield takeLatest(APPORTIONMENT_RESOURCE_FROM_REFLIST, apportionLineItems);
  yield takeLatest(APPORTIONMENT_RESOURCE_FROM_CUSTOM_RULE, apportionLineItems);
}
