import { POST_NEW_USER, POST_NEW_USER_SUCCESS, POST_NEW_USER_FAILURE, RESET_NEW_USER, TOGGLE_ADD_USER_MODAL, POST_NEW_USER_CONFIRM } from 'Store/Areas/Setup/Admin/AddUserActions';

const initialState = {
  loading: false,
  success: false,
  errorMessage: '',
  showModal: false,
  showAddUserConfirmModal: false,
};

export default function addUsersReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_NEW_USER:
    case POST_NEW_USER:
    case POST_NEW_USER_SUCCESS:
    case POST_NEW_USER_FAILURE:
    case POST_NEW_USER_CONFIRM:
    case TOGGLE_ADD_USER_MODAL:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        errorMessage: action.errorMessage,
        showModal: action.showModal,
        showAddUserConfirmModal: action.showAddUserConfirmModal,
      });
    default:
      return state;
  }
}
