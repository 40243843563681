import { Grid, withStyles, withTheme } from '@material-ui/core';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import { strings } from 'Constants/Categorization/CategoryReview/InspectRuleOverlayConstants';
import { formatCurrency, formatPercentage } from 'Helpers/NumberHelpers';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { openDeletingRule } from 'Store/Areas/Rule/DeleteRuleActions';
import { openEditRuleNote } from 'Store/Areas/Rule/EditRuleNoteActions';
import { closeInspectRule } from 'Store/Areas/Rule/InspectRuleActions';
import { openEditingRule } from 'Store/Areas/Rule/EditRuleActions';
import { isReadOnlySelector } from 'Store/Areas/Period/PeriodSelectors';
import { getCurrentRulePriority } from 'Store/Areas/Rule/RulePriorityActions';
import { rulePriorities } from 'Constants/Categorization/RulePriorities';
import EditPriorityModal from 'Components/Categorization/Modals/EditPriorityModal/EditPriorityModal';
import FullPageModal from 'Components/Shared/Modal/FullPageModal/FullPageModal';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import DeleteRuleModal from './DeleteRuleModal/DeleteRuleModal';
import EditRuleOverlay from './EditRuleOverlay/EditRuleOverlay';
import EditRuleNoteModal from './EditRuleNoteModal/EditRuleNoteModal';
import LineItemTable from './LineItemTable';
import styles from './InspectRuleOverlay.styles';

class InspectRuleOverlay extends React.PureComponent {
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(closeInspectRule());
  }

  handleClose = () => {
    const { dispatch } = this.props;
    dispatch(closeInspectRule());
  }

  deleteRule = () => {
    this.props.dispatch(openDeletingRule());
  }

  editRule = () => {
    this.props.dispatch(openEditingRule());
  }

  editRuleNote = () => {
    this.props.dispatch(openEditRuleNote());
  }

  toggleEditPriorityModal(rule) {
    this.props.dispatch(getCurrentRulePriority(rule.periodId, rule.id, rule.keywordRule));
  }

  render() {
    const {
      showOverlay,
      showEditRuleOverlay,
      rule,
      classes,
      currencySymbol,
      isReadOnly,
      rulePriority,
    } = this.props;

    return (
      <div>
        <If condition={!isReadOnly}>
          <If condition={!!rulePriority.periodId && rulePriority.loading}>
            <div className={classes.loader}>
              <CircularLoader />
            </div>
          </If>
          <If condition={rulePriority.open}>
            <EditPriorityModal />
          </If>
        </If>
        <FullPageModal
          open={showOverlay}
          onClose={this.handleClose}
          title={strings.title}
        >
          <If condition={!rule}>
            <div />
          </If>
          <If condition={rule}>
            <div>
              <DeleteRuleModal ruleText={rule.keywordRule} />
              <EditRuleNoteModal ruleNote={rule.notes} />
              <EditRuleOverlay
                showEditRuleOverlay={showEditRuleOverlay}
                rule={rule}
                ruleNote={rule.notes}
              />
            </div>
            <Grid>
              <Grid container direction="column" justify="space-between" className={classes.headerContainer}>
                <Grid item>
                  <Grid item xs>
                    <span className={classes.headerText}>{strings.headerRule}: </span>
                    <span className={classes.mainText}>{rule.keywordRule} </span>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row">
                    <Grid item xs>
                      <span className={classes.headerText}>{strings.headerApportionment}: </span>
                      <span className={classes.mainText}>
                        {formatPercentage(rule.apportionment)}
                      </span>
                    </Grid>
                    <Grid item xs>
                      <span className={classes.headerText}>{strings.headerCreatedDate}: </span>
                      <span className={classes.mainText}>{rule.formattedDateCreatedShort}</span>
                    </Grid>
                    <Grid item xs>
                      <span className={classes.headerText}>{strings.headerCreatedBy}: </span>
                      <span className={classes.mainText}>{rule.addedBy}</span>
                    </Grid>
                    <Grid item xs>
                      <span className={classes.headerText}>{strings.headerPriority}: </span>
                      <span className={classes.mainText}>
                        {rulePriorities.find(x => x.value === rule.priority).label}
                      </span>
                    </Grid>
                    <Grid item xs>
                      <span className={classes.headerText}>{strings.headerValue}: </span>
                      <span className={classes.mainText}>
                        {formatCurrency(rule.value, currencySymbol, 2)}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <If condition={rule.notes}>
                  <Grid item>
                    <div className={classes.noteContainer}>
                      <span className={classes.headerText}>{strings.headerNote}: </span>
                      <span className={classes.mainText}>{rule.notes}</span>
                    </div>
                  </Grid>
                </If>
                <If condition={!isReadOnly}>
                  <Grid item>
                    <Grid container direction="row">
                      <Grid item xs>
                        <Button
                          disableRipple
                          backgroundColor={constants.backgroundColor.dark}
                          className={classes.headerButton}
                          onClick={this.editRule}
                        >
                          {strings.headerEditButton}
                        </Button>
                      </Grid>
                      <Grid item xs>
                        <Button
                          disableRipple
                          backgroundColor={constants.backgroundColor.dark}
                          className={classes.headerButton}
                          onClick={this.deleteRule}
                        >
                          {strings.headerDeleteButton}
                        </Button>
                      </Grid>
                      <Grid item xs>
                        <Button
                          disableRipple
                          backgroundColor={constants.backgroundColor.dark}
                          className={classes.headerButton}
                          onClick={this.editRuleNote}
                        >
                          {strings.headerEditNoteButton}
                        </Button>
                      </Grid>
                      <Grid item xs>
                        <Button
                          disableRipple
                          backgroundColor={constants.backgroundColor.dark}
                          className={classes.headerButton}
                          onClick={() =>
                            this.toggleEditPriorityModal(this.props.rule)
                          }
                        >
                          {strings.headerEditPriorityButton}
                        </Button>
                      </Grid>
                      <Grid item xs={6} />
                    </Grid>
                  </Grid>
                </If>
              </Grid>
              <Grid item>
                <LineItemTable currencySymbol={currencySymbol} />
              </Grid>
            </Grid>
          </If>
        </FullPageModal>
      </div>
    );
  }
}

InspectRuleOverlay.defaultProps = {
  rule: {},
};

InspectRuleOverlay.propTypes = {
  dispatch: PropTypes.func.isRequired,
  showOverlay: PropTypes.bool.isRequired,
  rule: PropTypes.shape({
    keywordRule: PropTypes.string,
    notes: PropTypes.string,
    addedBy: PropTypes.string,
    dateCreated: PropTypes.string,
    value: PropTypes.number,
    priority: PropTypes.number,
  }),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  rulePriority: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    ruleText: PropTypes.string,
    ruleId: PropTypes.number,
    priorityLevel: PropTypes.number,
    open: PropTypes.bool.isRequired,
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  showEditRuleOverlay: PropTypes.bool.isRequired,
};

function mapStateToProps(state, props) {
  return {
    rule: state.rules.inspectRule.inspectedRule,
    showOverlay: state.rules.inspectRule.showInspectRuleOverlay,
    isReadOnly: props.isReadOnly || isReadOnlySelector(state),
    rulePriority: state.rules.rulePriority,
    showEditRuleOverlay: state.rules.editRule.open,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(InspectRuleOverlay);
