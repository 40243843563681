import React from 'react';
import {
  withStyles,
  withTheme,
} from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { styles } from './TabItem.styles';

class TabItem extends React.PureComponent {
  render() {
    const {
      classes,
      text,
      active,
      selectable,
      onClick,
    } = this.props;
    return (
      <div
        className={`${classes.root} ${selectable ? classes.cursor : ''}`}
        onClick={onClick}
        role="button"
        tabIndex="0"
        onKeyPress={onClick}
      >
        <div className={`
          ${classes.middle}
          ${active && classes.active}
          ${selectable ? classes.selectable : classes.unselectable}`}
        >
          {text}
        </div>
      </div>
    );
  }
}

TabItem.defaultProps = {
  onClick: () => { },
  active: false,
  selectable: false,
  text: '',
};

TabItem.propTypes = {
  active: PropTypes.bool,
  selectable: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(TabItem);
