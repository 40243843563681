import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import DragnDrop from 'Constants/DragnDrop';
import { DropTarget } from 'react-dnd';
import Close from 'Assets/Images/exit-ic.svg';
import { formatCurrency } from 'Helpers/NumberHelpers';
import { strings } from 'Constants/SupportingDocuments/Strings';
import { styles } from './SupportingDocumentsDropzone.styles';

class SupportingDocumentsDropzone extends React.PureComponent {
  render() {
    const {
      hovered,
      classes,
      connectDropTarget,
      onRemove,
      canDrop,
      file,
      currencySymbol,
    } = this.props;

    const dropzoneText =
      file.lineItemIds.length === 0
        ? strings.addLineItems
        : `${file.lineItemIds.length} ${strings.lineItemsAdded}`;

    const dropzone = (
      <div className={`${hovered && canDrop ? classes.blockHovered : ''} ${classes.dropzone}`}>
        <div className={classes.dropzoneContent}>{dropzoneText}</div>
        {(file.lineItemIds.length > 0 || file.balancingValue > 0) &&
          <button onClick={onRemove} className={classes.removeButton}>
            <img src={Close} alt="remove" />
          </button>
        }
      </div>
    );

    return (
      <div className={classes.root}>
        <div className={classes.flexContainer}>
          <div className={classes.title}>{file.name}</div>
          <div className={classes.amount}>{formatCurrency(file.currentAmount, currencySymbol)}</div>
        </div>
        <div className={classes.dropzoneContainer}>
          {connectDropTarget(dropzone)}
        </div>
      </div>
    );
  }
}

SupportingDocumentsDropzone.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    currentAmount: PropTypes.number,
    lineItemIds: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  onDrop: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  hasBalancingFigure: PropTypes.bool.isRequired,
  // DnD collect-props
  connectDropTarget: PropTypes.func.isRequired,
  hovered: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool.isRequired,
};

const spec = {
  drop(props) {
    return {
      onDrop: lineItemProps => props.onDrop(props.file.id, lineItemProps),
    };
  },
  canDrop(props, monitor) {
    return (
      monitor.getItemType() === DragnDrop.lineItem.supportingDocuments && !props.hasBalancingFigure
    );
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  DropTarget([DragnDrop.lineItem.supportingDocuments], spec, collect),
)(SupportingDocumentsDropzone);
