import { acceptedSpreadsheetTypes } from 'Constants/FileTypes';

export const REQUEST_UPLOAD_PERIOD_FILE = 'FILE_UPLOAD/REQUEST_UPLOAD_PERIOD_FILE';
export const REQUEST_UPLOAD_CUSTOM_REPORT_FILE = 'FILE_UPLOAD/REQUEST_UPLOAD_CUSTOM_REPORT_FILE';
export const REQUEST_UPLOAD_REFERENCE_LIST_FILE = 'FILE_UPLOAD/REQUEST_UPLOAD_REFERENCE_LIST_FILE';
export const REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE = 'FILE_UPLOAD/REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE';
export const REQUEST_UPLOAD_FILE_SUCCESS = 'FILE_UPLOAD/REQUEST_UPLOAD_FILE_SUCCESS';
export const TOGGLE_CORRUPTED_FILE_DETECTED_MODAL = 'FILE_UPLOAD/TOGGLE_CORRUPTED_FILE_DETECTED_MODAL';
export const TOGGLE_ANTIVIRUS_LOADER = 'FILE_UPLOAD/TOGGLE_ANTIVIRUS_LOADER';
export const RESET_UPLOAD_FILE = 'FILE_UPLOAD/RESET_UPLOAD_FILE';

export function resetUploadFile() {
  return {
    type: RESET_UPLOAD_FILE,
  };
}

export function toggleCorruptedFileDetectedModal({
  showModal = false,
  fileContainsVirus = false,
  fileTypes = acceptedSpreadsheetTypes,
}) {
  return {
    type: TOGGLE_CORRUPTED_FILE_DETECTED_MODAL,
    uploadResponse: null,
    showCorruptedFileModal: showModal,
    fileContainsVirus,
    fileTypes,
    fileLoading: false,
  };
}

export function requestUploadPeriodFile(
  file,
  periodId,
  changeSupportingDocsAfterPeriodCreation = false,
) {
  return {
    type: REQUEST_UPLOAD_PERIOD_FILE,
    payload: {
      file: file,
      periodId: periodId,
      changeSupportingDocsAfterPeriodCreation: changeSupportingDocsAfterPeriodCreation,
    },
    checkingForViruses: true,
  };
}

export function requestUploadCustomReportFile(file, periodId) {
  return {
    type: REQUEST_UPLOAD_CUSTOM_REPORT_FILE,
    payload: { file: file, periodId: periodId },
    checkingForViruses: true,
    fileLoading: true,
  };
}
export function requestUploadReferenceListFile(file, periodId) {
  return {
    type: REQUEST_UPLOAD_REFERENCE_LIST_FILE,
    payload: { file: file, periodId: periodId },
    checkingForViruses: true,
    fileLoading: true,
  };
}

export function requestUploadExternalReviewFile(file, periodId) {
  return {
    type: REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE,
    payload: { file: file, periodId: periodId },
    checkingForViruses: true,
    fileLoading: true,
  };
}

export function toggleAntiVirusLoader(loading) {
  return {
    type: TOGGLE_ANTIVIRUS_LOADER,
    checkingForViruses: loading,
  };
}

export function requestUploadFileSuccess(response) {
  return {
    type: REQUEST_UPLOAD_FILE_SUCCESS,
    uploadResponse: response,
  };
}
