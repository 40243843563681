export const REQUEST_TOAST_NOTIFICATION = 'TOAST_NOTIFICATION/REQUEST_TOAST_NOTIFICATION';
export const RESET_TOAST_NOTIFICATION = 'TOAST_NOTIFICATION/RESET_TOAST_NOTIFICATION';

export function displayToastNotification(message, linkText = null, linkUrl = null) {
  return {
    type: REQUEST_TOAST_NOTIFICATION,
    message: message,
    showNotification: true,
    linkText: linkText,
    linkUrl: linkUrl,
  };
}

export function resetToastNotification() {
  return {
    type: RESET_TOAST_NOTIFICATION,
  };
}
