const styles = theme => ({
  root: {
    width: '100%',
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 5,
    paddingTop: 20,
  },
  text: {
    fontSize: theme.constants.fontSizes.regular,
    lineHeight: 1.71,
  },
  textArea: {
    paddingTop: 15,
    fontSize: theme.constants.fontSizes.small,
  },
  button: {
    minWidth: 140,
    marginTop: 30,
    marginRight: 10,
    marginBottom: 10,
  },
  tick: {
    paddingRight: 5,
  },
  note: {
    paddingRight: 20,
  },
  buttonContainer: {
    paddingBottom: 10,
  },
});

export default styles;
