export const styles = theme => ({
  root: {
    padding: 20,
  },
  graphContainer: {
    overflowX: 'auto',
    overflowY: 'hidden',
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    width: '100%',
    position: 'relative',
  },
  chart: {
    width: '100%',
    height: 400,
  },
  noData: {
    position: 'absolute',
    top: '150px',
    textAlign: 'center',
    zIndex: 10,
    width: '100%',
    fontSize: theme.constants.fontSizes.regular,
  },
  categorisationLink: {
    outline: 'none',
    display: 'inline-block',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  legendRow: {
    paddingLeft: 20,
    paddingTop: 20,
    paddingBottom: 35,
    alignItems: 'center',
    color: theme.palette.colors.black,
    display: 'flex',
    height: 14,
    fontSize: theme.constants.fontSizes.regular,
  },
  keyContainer: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.small,
  },
  previousPeriod: {
    borderRadius: '50%',
    height: 10,
    margin: 'auto 7px auto 0',
    width: 10,
    backgroundColor: theme.palette.graphs.grey,
  },
  legend: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 20,
  },
});

export default styles;
