import * as PeriodActions from 'Store/Areas/Period/PeriodActions';
import { SELECT_PERIOD } from 'Store/Areas/Projects/UserTreePickerActions';
import {
  REQUEST_PROCESS,
  REQUEST_PROCESS_SUCCESS,
  REQUEST_APPLY_MACHINE_LEARNING,
  REPORT_PROCESSING_PROGRESS,
  RESET_PROCESSING_STATE,
  REQUEST_PROCESS_NEW_SUPPORTING_DOCS,
  REQUEST_PROCESS_NEW_SUPPORTING_DOCS_SUCCESS,
} from 'Store/Areas/Import/ProcessActions';

const initialState = {
  loading: false,
  progress: null,
  stage: 0,
};

export default function processReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_PROCESS:
    case REQUEST_PROCESS_SUCCESS:
    case REQUEST_PROCESS_NEW_SUPPORTING_DOCS:
    case REQUEST_PROCESS_NEW_SUPPORTING_DOCS_SUCCESS:
    case REQUEST_APPLY_MACHINE_LEARNING:
      return Object.assign({}, state, {
        loading: action.loading,
        progress: action.progress,
      });
    case REPORT_PROCESSING_PROGRESS:
      return Object.assign({}, state, {
        progress: action.message,
        loading: action.complete,
        stage: action.stage,
      });
    case PeriodActions.RESET_PERIOD:
    case SELECT_PERIOD:
    case RESET_PROCESSING_STATE:
      return initialState;
    default:
      return state;
  }
}
