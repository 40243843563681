import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect as reduxConnect } from 'react-redux';
import { DragSource } from 'react-dnd';
import { withStyles, withTheme, Paper } from '@material-ui/core';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import DragnDrop from 'Constants/DragnDrop';
import { strings } from 'Constants/Categorization/Strings';
import DragIcon from 'Assets/Images/drag-icon.svg';
import { styles } from './AllSearchResultsDragLayer.styles';

class AllSearchResultsDragLayer extends React.PureComponent {
  render() {
    const {
      classes,
      allowDrag,
      connectDragSource,
    } = this.props;

    return (
      <Paper className={classes.paperRoot}>
        <div className={classes.contentRoot} title={strings.allSearchResultsDragAllTooltip}>
          <div className={classes.dragTextWrapper}>
            {allowDrag &&
              connectDragSource(<div className={classes.dragSource} />)
            }
            <span className={classes.dragAllSearchResultsText}>
              {strings.allSearchResultsDragAllText}
            </span>
            &nbsp;
            <img
              src={DragIcon}
              alt={strings.allSearchResultsDragAllText}
              className={classes.icon}
            />
          </div>
        </div>
      </Paper>
    );
  }
}

AllSearchResultsDragLayer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  connectDragSource: PropTypes.func.isRequired,
  allowDrag: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  searchTerm: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  contraShelfLineItemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  categorizationType: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
  };
}

const spec = {
  beginDrag(props) {
    return {
      type: DragnDrop.allSearchResultsLineItem,
      searchTerm: props.searchTerm,
      contraShelfLineItemIds: props.contraShelfLineItemIds,
      categorizationType: props.categorizationType,
    };
  },
  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const { onDrop } = monitor.getDropResult();
      onDrop();
    }
  },
};

function collect(connect) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  };
}

export default compose(
  DragSource(DragnDrop.allSearchResultsLineItem, spec, collect),
  withStyles(styles),
  withTheme(),
  reduxConnect(mapStateToProps),
)(AllSearchResultsDragLayer);