import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import RadioButton from './RadioButton';
import { styles } from './RadioButtonContainer.styles';

class RadioButtonContainer extends React.PureComponent {
  render() {
    const {
      classes,
      label,
      className,
      children,
      childrenWrapperClassName,
    } = this.props;

    return (
      <div className={className}>
        {label && (
          <div className={classes.label}>{label}</div>
        )}
        <div className={childrenWrapperClassName}>
          {children}
        </div>
      </div>
    );
  }
}

RadioButtonContainer.propTypes = {
  className: PropTypes.string,
  childrenWrapperClassName: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf([RadioButton]),
    })),
    PropTypes.shape({
      type: PropTypes.oneOf([RadioButton]),
    }),
  ]).isRequired,
};

RadioButtonContainer.defaultProps = {
  className: '',
  childrenWrapperClassName: '',
};

export default compose(withStyles(styles))(RadioButtonContainer);
