export const UPDATE_PERIOD_STATUS = 'CHANGE_PERIOD_STATUS/UPDATE_PERIOD_STATUS';
export const UPDATE_PERIOD_STATUS_SUCCESS = 'CHANGE_PERIOD_STATUS/UPDATE_PERIOD_STATUS_SUCCESS';
export const UNLOCK_PERIOD = 'CHANGE_PERIOD_STATUS/UNLOCK_PERIOD';
export const UNLOCK_PERIOD_SUCCESS = 'CHANGE_PERIOD_STATUS/UNLOCK_PERIOD_SUCCESS';

export function updatePeriodStatus(periodId, status, name) {
  return {
    type: UPDATE_PERIOD_STATUS,
    periodPayload: { periodName: name },
    payload: {
      periodId: periodId,
      status: status,
    },
  };
}

export function unlockPeriod(periodId, name) {
  return {
    type: UNLOCK_PERIOD,
    periodPayload: { periodName: name },
    payload: {
      periodId: periodId,
      isLocking: false,
      lockReason: false,
      userId: -1,
    },
  };
}
