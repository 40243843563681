import Yup from 'yup';
import { allowedMLModelCharacters, beginsOrEndsInSpaces } from 'Constants/ValidationHelpers';
import { strings } from './Strings';

export const createModelSchema = Yup.object().shape({
  name: Yup.string()
    .matches(allowedMLModelCharacters, strings.nameInvalidValidation)
    .matches(beginsOrEndsInSpaces, strings.nameStartsOrEndsWithSpacesValidation)
    .required(strings.nameRequiredValidation),
  key: Yup.string()
    .matches(allowedMLModelCharacters, strings.modelKeyInvalidValidation)
    .matches(beginsOrEndsInSpaces, strings.modelKeyStartsOrEndsWithSpacesValidation)
    .required(strings.modelKeyRequiredValidation),
  description: Yup.string()
    .matches(allowedMLModelCharacters, strings.descriptionInvalidValidation)
    .max(450, strings.descriptionMaxCharsValidation)
    .required(strings.descriptionRequiredValidation),
  taxContext: Yup.number()
    .min(1, strings.taxContextRequiredValidation)
    .required(strings.taxContextRequiredValidation),
  categories: Yup.string()
    .matches(allowedMLModelCharacters, strings.categoriesInvalidValidation)
    .required(strings.categoriesRequiredValidation),
});

