import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { onMultiSelectionChange } from 'Helpers/SelectionHelpers';
import { getApportionableItems, resetApportionableItems, updateSelectedRow } from 'Store/Areas/Apportionables/ApportionablesActions';
import { commonStrings } from 'Constants/CommonStrings';
import { sortOrder as sortOrders } from 'Constants/Categorization/QueryTermFilters';
import { apportionmentStrings } from './ApportionablesStrings';

export const sortFields = {
  condition: 0,
  priority: 1,
  method: 2,
  amount: 3,
  tag: 4,
  definitionText: 5,
};

const defaultOptions = {
  sortOrder: sortOrders.valueAscending,
  sortField: sortFields.priority,
  sortTagId: -1,
  ruleId: -1,
  matchedRuleIds: [],
  categoryId: -1,
  categorizationType: -1,
  includeCategoryColumn: false,
  includeRuleColumn: false,
  categoryType: 0,
  maxLineItemValue: null,
  minLineItemValue: null,
  debounceDelay: 0,
  scrollId: null,
  searchTerm: '',
  mapSortField: null,
  fileType: null,
  tagFilters: [],
};

const withApportionableItems = (options = defaultOptions) => (Component) => {
  options = { ...defaultOptions, ...options }; // eslint-disable-line
  class WithApportionableItems extends React.PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        sortOrder: options.sortOrder,
        sortField: options.sortField,
        sortTagId: options.sortTagId,
        ruleId: options.ruleId,
        categoryId: options.categoryId,
        categorizationType: options.categorizationType,
        includeCategoryColumn: options.includeCategoryColumn,
        maxLineItemValue: options.maxLineItemValue,
        minLineItemValue: options.minLineItemValue,
        debounceDelay: options.debounceDelay,
        categoryType: options.categoryType,
        tagFilters: options.tagFilters,
        descriptionColumnIndex: -1,
        amountColumnIndex: -1,
        conditionColumnIndex: -1,
        priorityColumnIndex: -1,
        methodColumnIndex: -1,
        definitionTextColumnIndex: -1,
        sortedColIndex: -1,
        hasColumnData: false,
        fileType: options.fileType,
        lineItemsToFilter: [],
        searchTerm: options.searchTerm,
      };
    }

    componentDidUpdate() {
      const { apportionables } = this.props;
      const { hasColumnData } = this.state;
      if (!hasColumnData) {
        const amountColumnIndex = apportionables.columns.findIndex(x => x === commonStrings.amount);
        const priorityColumnIndex = apportionables.columns.findIndex(x =>
          x === apportionmentStrings.apportionmentPriority);
        const methodColumnIndex = apportionables.columns.findIndex(x =>
          x === apportionmentStrings.method);
        const conditionColumnIndex = apportionables.columns.findIndex(x => x
          === apportionmentStrings.condition);
        const definitionTextColumnIndex = apportionables.columns.findIndex(x => x
            === apportionmentStrings.definitionText);
        const descriptionColumnIndex =
          apportionables.columns.findIndex(x => x === commonStrings.description);
        if (amountColumnIndex > 0 || descriptionColumnIndex > 0) {
          this.setState({ // eslint-disable-line
            hasColumnData: true,
            amountColumnIndex: amountColumnIndex,
            conditionColumnIndex: conditionColumnIndex,
            descriptionColumnIndex: descriptionColumnIndex,
            priorityColumnIndex: priorityColumnIndex,
            methodColumnIndex: methodColumnIndex,
            definitionTextColumnIndex: definitionTextColumnIndex,
            sortedColIndex:
              this.getSortColumn(
                amountColumnIndex,
                conditionColumnIndex,
                priorityColumnIndex,
                methodColumnIndex,
                definitionTextColumnIndex,
              ),
          });
        }
      }
    }

    componentWillUnmount() {
      this.props.dispatch(resetApportionableItems());
    }

    onSort = (colIndex) => {
      const {
        columnTagIds,
      } = this.props.apportionables;
      const {
        conditionColumnIndex,
      } = this.state;
      if (colIndex === conditionColumnIndex) {
        // disable sorting for the `Category` column
        // on the Manually Categorised line items review screen
        return;
      }
      const sortField = this.determineSortField(colIndex);
      this.setOptions({
        sortOrder: this.toggleSortOrder(sortField),
        sortField: sortField,
        sortTagId: (sortField === sortFields.tag) ? columnTagIds[colIndex - 2] : -1,
        sortedColIndex: colIndex,
      });
    }

    onBeginDrag = ({ lineItemId }) => {
      if (this.props.apportionables.selectedRowIds.indexOf(lineItemId) === -1) {
        this.onRowSelectionChange(lineItemId, false, false);
      }
    }

    onEndDrag = () => {
      this.props.apportionables.activeRowId = null;
      this.props.apportionables.selectedRows = [];
      this.props.apportionables.selectedRowIds = [];
      this.props.apportionables.selectedRowsTotalAmount = 0;
    }

    onRowSelectionChange = (rowId, ctrlKeyActive, shiftKeyActive) => {
      const resultantState = onMultiSelectionChange(
        this.props.apportionables.lineItems.filter(x =>
          this.state.lineItemsToFilter.indexOf(x.id) === -1),
        rowId,
        ctrlKeyActive,
        shiftKeyActive,
        this.props.apportionables.selectedRowIds,
        this.props.apportionables.activeRowId,
        r => r.lineItemId,
      );

      this.props.dispatch(updateSelectedRow(
        resultantState.activeSelection,
        resultantState.selections,
        resultantState.selectedItems,
        resultantState.selectedItems.reduce((a, b) =>
          a + Number(b.tagAndValues.find(f => f.tagName === 'Amount').tagValue), 0),
      ));
    }

    getSortColumn = (
      amountColumnIndex, conditionColumnIndex,
      priorityColumnIndex, methodColumnIndex, definitionTextColumnIndex,
    ) => {
      const { sortField } = this.state;
      switch (sortField) {
        case sortFields.amount:
          return amountColumnIndex;
        case sortFields.condition:
          return conditionColumnIndex;
        case sortFields.priority:
          return priorityColumnIndex;
        case sortFields.method:
          return methodColumnIndex;
        case sortFields.definitionText:
          return definitionTextColumnIndex;
        default:
          return -1;
      }
    }

    setOptions = (newOptions) => {
      this.setState(
        {
          ...newOptions,
        },
        () => {
          this.props.dispatch(resetApportionableItems());
          this.loadItems();
        },
      );
    }

    setTagFilters = (tagFilters) => {
      this.setState({
        tagFilters,
      });
    }

    setFilteredItems = (itemsToFilter) => {
      this.setState({
        lineItemsToFilter: itemsToFilter,
      });
    }

    toggleSortOrder = (colIndex) => {
      if (this.state.sortField === colIndex) {
        return this.state.sortOrder === sortOrders.valueAscending ?
          sortOrders.valueDescending : sortOrders.valueAscending;
      }

      return sortOrders.valueDescending;
    }

    onSearch = () => {
      this.setOptions();
    }

    resetSearch = () => {
      this.setOptions({
        searchTerm: '',
        tagFilters: [],
      });
    }

    setSearchTerm = (searchTerm) => {
      this.setState({
        searchTerm: searchTerm,
      });
    }

    determineSortField = (columnIndex) => {
      const {
        amountColumnIndex,
        priorityColumnIndex,
        methodColumnIndex,
        definitionTextColumnIndex,
      } = this.state;

      if (columnIndex === -1) {
        return sortFields.rowId;
      }

      switch (columnIndex) {
        case amountColumnIndex:
          return sortFields.amount;
        case methodColumnIndex:
          return sortFields.method;
        case priorityColumnIndex:
          return sortFields.priority;
        case definitionTextColumnIndex:
          return sortFields.definitionText;
        default:
          return sortFields.tag;
      }
    }

    loadMoreItems = () => {
      this.loadItems(this.props.apportionables.scrollId);
    }

    loadItems = (scrollId) => {
      const { dispatch, periodId } = this.props;
      const {
        sortOrder,
        sortField,
        ruleId,
        categoryId,
        categorizationType,
        includeCategoryColumn,
        includeRuleColumn,
        includeContraFlagColumn,
        categoryType,
        maxLineItemValue,
        minLineItemValue,
        debounceDelay,
        searchTerm,
        sortTagId,
        fileType,
        tagFilters,
      } = this.state;

      dispatch(getApportionableItems(
        periodId,
        sortOrder,
        sortField,
        sortTagId,
        debounceDelay,
        scrollId,
        searchTerm,
        ruleId,
        categoryId,
        categorizationType,
        includeCategoryColumn,
        includeRuleColumn,
        includeContraFlagColumn,
        categoryType,
        maxLineItemValue,
        minLineItemValue,
        fileType,
        tagFilters,
      ));
    }

    render() {
      const {
        descriptionColumnIndex,
        amountColumnIndex,
        conditionColumnIndex,
        sortedColIndex,
        sortOrder,
        sortField,
        sortTagId,
        searchTerm,
      } = this.state;
      const { periodId, apportionables, ...rest } = this.props;

      const { lineItemsToFilter } = this.state;
      const filteredItems = apportionables.lineItems.filter(x =>
        lineItemsToFilter.indexOf(x.id) === -1);
      return (
        <Component
          lineItemsLoadMore={this.loadMoreItems}
          lineItems={filteredItems}
          sortedColIndex={sortedColIndex}
          sortOrder={sortOrder}
          sortField={sortField}
          sortTagId={sortTagId}
          lineItemsHasMore={apportionables.hasMore}
          lineItemsLoading={apportionables.loading}
          lineItemsColumns={apportionables.columns}
          lineItemsColumnHeaders={apportionables.columnHeaders}
          totalLineItemCount={apportionables.totalLineItemCount}
          setLineItemOptions={this.setOptions}
          descriptionColumnIndex={descriptionColumnIndex}
          amountColumnIndex={amountColumnIndex}
          conditionColumnIndex={conditionColumnIndex}
          setTagFilters={this.setTagFilters}
          onSort={colIndex => this.onSort(colIndex)}
          setOptions={this.setOptions}
          setFilteredItems={this.setFilteredItems}
          selectedRows={apportionables.selectedRows}
          selectedRowIds={apportionables.selectedRowIds}
          selectedRowsTotalAmount={apportionables.selectedRowsTotalAmount}
          onBeginDrag={this.onBeginDrag}
          onEndDrag={this.onEndDrag}
          onRowSelectionChange={this.onRowSelectionChange}
          resetSearch={this.resetSearch}
          setSearchTerm={this.setSearchTerm}
          onSearch={this.onSearch}
          searchTerm={searchTerm}
          {...rest}
        />
      );
    }
  }

  WithApportionableItems.propTypes = {
    dispatch: PropTypes.func.isRequired,
    periodId: PropTypes.number.isRequired,
    apportionables: PropTypes.shape({
      lineItems: PropTypes.array.isRequired,
      columns: PropTypes.array.isRequired,
      columnHeaders: PropTypes.array.isRequired,
      columnTagIds: PropTypes.array.isRequired,
      loading: PropTypes.bool.isRequired,
      hasMore: PropTypes.bool.isRequired,
      scrollId: PropTypes.string,
      totalLineItemCount: PropTypes.number.isRequired,
      activeRowId: PropTypes.number,
      selectedRows: PropTypes.array,
      selectedRowIds: PropTypes.array,
      selectedRowsTotalAmount: PropTypes.number,
    }).isRequired,
    columnTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  };

  function mapStateToProps(state) {
    return {
      periodId: state.periods.period.periodId,
      apportionables: state.categorisation.apportionables,
      columnTagIds: state.categorisation.apportionables.columnTagIds,
    };
  }

  return connect(mapStateToProps)(WithApportionableItems);
};

export default withApportionableItems;
