export const RESET_DELETING_CATEGORY = 'DELETE_CATEGORY/RESET_DELETING_CATEGORY';
export const CHECK_CATEGORY_CAN_BE_DELETED = 'DELETE_CATEGORY/CHECK_CATEGORY_CAN_BE_DELETED';
export const CHECK_CATEGORY_CAN_BE_DELETED_SUCCESS = 'DELETE_CATEGORY/CHECK_CATEGORY_CAN_BE_DELETED_SUCCESS';
export const POST_DELETE_CATEGORY = 'DELETE_CATEGORY/POST_DELETE_CATEGORY';

export function resetDeletingCategory() {
  return {
    type: RESET_DELETING_CATEGORY,
    deleting: false,
  };
}

export function checkCategoryCanBeDeleted(categoryId, periodId) {
  return {
    type: CHECK_CATEGORY_CAN_BE_DELETED,
    loading: true,
    deleting: true,
    payload: {
      categoryId: categoryId,
      periodId: periodId,
    },
  };
}

export function checkCategoryCanBeDeletedSuccess(response) {
  return {
    type: CHECK_CATEGORY_CAN_BE_DELETED_SUCCESS,
    loading: false,
    categoryHasRules: response.categoryHasRules,
    categoryHasLineItems: response.categoryHasLineItems,
    lineItemCount: response.lineItemCount,
    categoryCanBeDeleted: response.categoryCanBeDeleted,
  };
}

export function postDeleteCategory(categoryId, periodId, categoryName) {
  return {
    type: POST_DELETE_CATEGORY,
    submitting: true,
    payload: {
      categoryId: categoryId,
      periodId: periodId,
      categoryName: categoryName,
    },
  };
}
