import React from 'react';
import withLineItems from 'Components/Shared/LineItemsProvider/LineItemsProvider';
import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';
import DataGrid from 'Components/Shared/DataGrid/DataGrid';
import SimpleHeader from 'Components/Shared/DataGrid/Cells/SimpleHeader/SimpleHeader';
import SimpleCell from 'Components/Shared/DataGrid/Cells/SimpleCell/SimpleCell';
import Search from 'Components/Shared/Inputs/Search';
import { commonStrings } from 'Constants/CommonStrings';
import { strings } from 'Constants/SupportingDocuments/Strings';
import { main } from 'Constants/FileTypes';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { formatCellValue } from 'Helpers/LineItemHelpers';
import PropTypes from 'prop-types';
import BorderedRow from 'Components/Shared/DataGrid/Rows/BorderedRow';
import DragnDrop from 'Constants/DragnDrop';
import GenericDragLayer from 'Components/Shared/DragNDrop/GenericDragLayer';
import PreviewRule from 'Components/Shared/DragNDrop/DragPreviewComponents/PreviewRule';
import { formatCurrency } from 'Helpers/NumberHelpers';
import { getTooltip } from 'Helpers/DataGridHelpers';
import { connect } from 'react-redux';
import { getSupportingDocumentMappedLineItemIds } from 'Store/Areas/Import/ImportSelectors';
import { styles } from './SupportingDocumentsLineItems.styles';

class SupportingDocumentsLineItems extends React.PureComponent {
  componentDidMount() {
    this.props.setOptions({
      categorizationType: categorizationTypes.uncategorized,
      fileType: main,
    });
  }

  componentDidUpdate(prevProps) {
    const { mappedItems, mappedItemsLength, setFilteredItems } = this.props;
    if (prevProps.mappedItemsLength !== mappedItemsLength) {
      setFilteredItems(mappedItems);
    }
  }

  getWidth = ({ index }) => {
    const {
      descriptionColumnIndex,
    } = this.props;
    return index === descriptionColumnIndex ? 300 : 150;
  }

  getCellValue = ({ rowIndex, columnIndex }) => {
    const {
      lineItems,
      lineItemsColumns,
      amountColumnIndex,
    } = this.props;

    const cell = lineItems[rowIndex].columns[lineItemsColumns[columnIndex]];

    if (columnIndex === amountColumnIndex) {
      return cell.value;
    }

    return formatCellValue(cell);
  }

  renderDragLayer = (item, itemType) => {
    const {
      selectedRows,
      lineItems,
    } = this.props;

    if (itemType === DragnDrop.lineItem.supportingDocuments &&
      this.props.selectedRowIds.length > 0) {
      return (
        <PreviewRule
          leftAlignedText={selectedRows.length > 1 ? `${selectedRows.length} items` : // eslint-disable-line
          lineItems.find(x => x.id === selectedRows[0].id).columns.Description ?
          lineItems.find(x => x.id === selectedRows[0].id).columns.Description.value : ''}
          rightAlignedText={
            formatCurrency(this.props.selectedRowsTotalAmount, this.props.currencySymbol)
          }
        />
      );
    }

    return null;
  }

  render() {
    const {
      currencySymbol,
      lineItems,
      lineItemsColumnHeaders,
      lineItemsHasMore,
      lineItemsLoadMore,
      lineItemsLoading,
      amountColumnIndex,
      descriptionColumnIndex,
      setSearchTerm,
      onSearch,
      resetSearch,
      searchTerm,
      classes,
      selectedRows,
      selectedRowsTotalAmount,
      selectedRowIds,
      onBeginDrag,
      onEndDrag,
      onRowSelectionChange,
    } = this.props;

    return (
      <div className={classes.lineItems}>
        <GenericDragLayer disableIE={false}>
          {this.renderDragLayer}
        </GenericDragLayer>
        <Search
          className={classes.searchBox}
          id={strings.search}
          placeholder={strings.searchPlaceholder}
          onChange={value => setSearchTerm(value)}
          onReset={() => resetSearch()}
          onSearch={() => onSearch()}
          value={searchTerm}
        />
        <div className={classes.gridContainer}>
          <DataGrid
            recomputeGridSizeKey={`${descriptionColumnIndex}${amountColumnIndex}`}
            columns={lineItemsColumnHeaders}
            rows={lineItems}
            getCellValue={this.getCellValue}
            hasNextPage={lineItemsHasMore}
            isNextPageLoading={lineItemsLoading}
            loadNextPage={lineItemsLoadMore}
            getWidth={this.getWidth}
            rowMargin={10}
            childRowHeight={30}
            parentRowHeight={30}
            noDataMessage={commonStrings.noLineItems}
            renderHeaderCell={props => (
              <SimpleHeader {...props} className={classes.header} />
            )}
            renderRow={props => (
              <BorderedRow
                {...props}
                tooltip={getTooltip(lineItems[props.index].columns, currencySymbol)}
                dragProps={{
                    lineItemId: lineItems[props.index].id,
                    selectedRows: selectedRows,
                    selectedRowsTotalAmount: selectedRowsTotalAmount,
                  }}
                onBeginDrag={onBeginDrag}
                onEndDrag={onEndDrag}
                dragType={DragnDrop.lineItem.supportingDocuments}
                onSelectionChange={onRowSelectionChange}
                isSelected={selectedRowIds.includes(lineItems[props.index].id)}
              />
            )}
            renderCell={props => (
              <SimpleCell
                {...props}
                currencySymbol={currencySymbol}
                padLeft={props.index === 0}
                padRight={props.index === lineItemsColumnHeaders.length - 1}
                padding={10}
                formatAsCurrency={props.index === amountColumnIndex}
              />
            )}
          />
        </div>
      </div>
    );
  }
}

SupportingDocumentsLineItems.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  // redux props
  mappedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  mappedItemsLength: PropTypes.number.isRequired,
  // WithLineItems props
  setOptions: PropTypes.func.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  lineItemsColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineItemsColumnHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineItemsHasMore: PropTypes.bool.isRequired,
  lineItemsLoadMore: PropTypes.func.isRequired,
  lineItemsLoading: PropTypes.bool.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  amountColumnIndex: PropTypes.number.isRequired,
  descriptionColumnIndex: PropTypes.number.isRequired,
  setFilteredItems: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRowsTotalAmount: PropTypes.number.isRequired,
  selectedRowIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onBeginDrag: PropTypes.func.isRequired,
  onEndDrag: PropTypes.func.isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const mappedItems = getSupportingDocumentMappedLineItemIds(state);
  return {
    mappedItems: mappedItems,
    mappedItemsLength: Object.keys(mappedItems).length,
  };
};

export default compose(
  withStyles(styles),
  withLineItems(),
  connect(mapStateToProps),
)(SupportingDocumentsLineItems);
