import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Input } from '@material-ui/core';
import { compose } from 'recompose';
import { Formik } from 'formik';
import Modal from 'Components/Shared/Modal/StyledModal';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import { strings, noteValue } from 'Constants/MachineLearning/Strings';
import styles from './MachineLearningModal.styles';

class MachineLearningModal extends React.PureComponent {
  static lineLimitFocus(event) {
    event.target.select();
  }

  static handleKeyDown(e) {
    const charCode = (typeof e.which === 'undefined') ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);
    if (['e', 'E'].includes(charStr)) {
      e.preventDefault();
    }
  }

  render() {
    const {
      classes, setValueLimit, open, maxValue, skipMl, currentValue, currencySymbol,
    } = this.props;
    return (
      <Modal open={open}>
        <Formik
          initialValues={{
            lineLimit: currentValue,
          }}
          onSubmit={(values) => {
            setValueLimit(values.lineLimit);
          }}
          render={({ handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.content}>
                <div className={classes.title}>{strings.machineLearningTitle}</div>
                <span className={classes.text}>{strings.machineLearningApportionmentMessage}</span>
                <div className={classes.textArea}>
                  <span className={classes.note}>{noteValue(maxValue, currencySymbol)}</span>
                </div>
                <div className={classes.textBox}>
                  <Input
                    className={classes.input}
                    classes={{ input: classes.textInput }}
                    type="number"
                    id="lineLimit"
                    value={values.lineLimit}
                    placeholder={strings.machineLearningModalPlaceholder}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setFieldValue('lineLimit', '');
                      } else if (e.target.value === '0') {
                        setFieldValue('lineLimit', '');
                      } else if (Number(e.target.value) > Number(maxValue)) {
                        setFieldValue('lineLimit', Number(maxValue));
                      } else {
                        setFieldValue('lineLimit', Number(e.target.value));
                      }
                    }}
                    onFocus={MachineLearningModal.lineLimitFocus}
                    onKeyPress={MachineLearningModal.handleKeyDown}
                    inputProps={{ min: 0 }}
                    disableUnderline
                  />
                </div>
              </div>
              <Buttons
                onAccept={handleSubmit}
                onCancel={skipMl}
                acceptText={strings.machineLearningApply}
                cancelText={strings.machineLearningCancel}
                submitDisabled={
                  maxValue < values.lineLimit ||
                  !values.lineLimit ||
                  Number(values.lineLimit) < 0.01
                }
              />
            </form>
          )}
        />
      </Modal>
    );
  }
}

MachineLearningModal.defaultProps = {
  currentValue: '',
};

MachineLearningModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setValueLimit: PropTypes.func.isRequired,
  skipMl: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  maxValue: PropTypes.number.isRequired,
  currentValue: PropTypes.string, // needs to support empty textbox
  currencySymbol: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(MachineLearningModal);
