export const CREATING_NEW_PROJECT = 'PROJECT/CREATING_NEW_PROJECT';
export const EDIT_PROJECT = 'PROJECT/EDIT_PROJECT';
export const POST_PROJECT = 'PROJECT/POST_PROJECT';
export const CANCEL_PROJECT_CREATION = 'PROJECT/CANCEL_PROJECT_CREATION';
export const POST_PROJECT_SUCCESS = 'PROJECT/POST_PROJECT_SUCCESS';
export const EDIT_LOAD_PROJECT_SUCCESS = 'PROJECT/EDIT_LOAD_PROJECT_SUCCESS';
export const DELETE_PROJECT = 'PROJECT/DELETE_PROJECT';

export function creatingNewProject(selectedGroup, selectedEntity) {
  return {
    type: CREATING_NEW_PROJECT,
    showForm: true,
    isEdit: false,
    loading: false,
    payload: {
      id: -1,
    },
    selection: {
      selectedGroup: selectedGroup,
      selectedEntity: selectedEntity,
    },
  };
}

export function editProject(projectId, selectedGroup, selectedEntity) {
  return {
    type: EDIT_PROJECT,
    showForm: true,
    isEdit: true,
    loading: true,
    payload: {
      id: projectId,
    },
    selection: {
      selectedGroup: selectedGroup,
      selectedEntity: selectedEntity,
    },
  };
}

export function postProject(payload, groupId, entityId, isEdit) {
  return {
    type: POST_PROJECT,
    showForm: true,
    isEdit: isEdit,
    loading: true,
    payload: payload,
    groupId: groupId,
    entityId: entityId,
  };
}

export function cancelProjectCreation() {
  return {
    type: CANCEL_PROJECT_CREATION,
  };
}

export function postProjectSuccess() {
  return {
    type: POST_PROJECT_SUCCESS,
    showForm: false,
    loading: false,
  };
}

export function editLoadProjectSuccess(json) {
  return {
    type: EDIT_LOAD_PROJECT_SUCCESS,
    showForm: true,
    isEdit: true,
    loading: false,
    payload: json,
  };
}

export function deleteProject(projectId, groupId, projectName) {
  return {
    type: DELETE_PROJECT,
    payload: {
      projectId,
      groupId,
      name: projectName,
    },
    showForm: false,
  };
}
