import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import ProgressBarSegment from './ProgressBarSegment';
import styles from './ProgressBar.styles';

class ProgressBar extends React.PureComponent {
  render() {
    const { classes, processingStages, stage } = this.props;
    return (
      <div className={classes.root}>
        <For each="displayStage" index="currentIndex" of={processingStages}>
          <ProgressBarSegment
            key={displayStage.stageId}
            name={displayStage.stageName}
            isFirst={currentIndex === 0}
            isFinal={currentIndex === (processingStages.length - 1)}
            isStarted={displayStage.stageId <= stage}
            isFinished={displayStage.stageId < stage}
          />
        </For>
      </div>
    );
  }
}

ProgressBar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  processingStages: PropTypes.arrayOf(PropTypes.shape({
    stageId: PropTypes.number,
    stageName: PropTypes.string,
    startMessage: PropTypes.string,
  })).isRequired,
  stage: PropTypes.number.isRequired,
};

export default withStyles(styles)(ProgressBar);
