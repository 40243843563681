import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import { commonStrings } from 'Constants/CommonStrings';

class AdminDeleteModal extends React.PureComponent {
  render() {
    const {
      onConfirm, onCancel, isModalOpen, message, canDelete, title,
    } = this.props;

    return (
      <ConfirmationModal
        open={isModalOpen}
        title={title}
        message={message}
        acceptText={commonStrings.delete}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        submitDisabled={!canDelete}
      />
    );
  }
}

AdminDeleteModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  canDelete: PropTypes.bool.isRequired,
};

export default (AdminDeleteModal);
