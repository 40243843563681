const styles = theme => ({
  actions: {
    display: 'flex',
    margin: 0,
  },
  button: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    padding: 0,
    margin: 0,
    borderRadius: 0,
    height: 60,
    '&[disabled]': {
      color: theme.palette.primary.contrastText,
      opacity: 0.6,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  cancelButton: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.darkAltButton,
    '&:hover': {
      backgroundColor: theme.palette.colors.darkLightened,
    },
  },
  acceptButton: {
    flexGrow: 3,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.colors.mainLightened,
    },
  },
});

export default styles;
