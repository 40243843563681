export const strings = {
  addRow: 'Add row',
  startBlock: id => `Start Block ${id + 1} (`,
  endBlock: id => `) End Block ${id + 1}`,
  drag: 'Drag rule',
  builderValidation: {
    missingTag: i => `Missing tag on line ${i + 1}`,
    missingOperator: i => `Missing operator on line ${i + 1}`,
    missingCondition: i => `Missing condition on line ${i + 1}`,
    missingJoin: i => `Missing join on line ${i + 1}`,
    unexpectedJoin: i => `Unexpected join on line ${i + 1}`,
    incorrectTextOperator: i => `Cannot use a numeric operator with a text tag on line ${i + 1}`,
    incorrectNumericOperator: i => `Cannot use a text operator with a numeric tag on line ${i + 1}`,
    incorrectDateOperator: i => `Cannot use a text operator with a Date tag on line ${i + 1}`,
    invalidLuceneRegex: i => `Invalid Lucene Regex on line ${i + 1}`,
    invalidDateFormat: i => `Invalid Date format on line ${i + 1}. It should be dd/mm/yyyy.`,
    invalidLuceneRegexLength: (i, maxLength) => `Invalid Lucene Regex Length (max ${maxLength} characters) on line ${i + 1}`,
  },
  conditionBlock: 'Condition Block',
  operators: 'Operators',
  joinWords: 'Join Words',
  keywords: 'Keywords',
  tags: 'Tags',
  missingBracket: 'Missing end bracket',
  tag: 'Tag',
  condition: 'Condition',
  operator: 'Operator',
  join: 'Join',
  openBrackets: 'Open bracket (',
  closeBrackets: 'Close bracket )',
  openBracketsButton: 'Open brackets',
  closeBracketsButton: 'Close brackets',
  addLineButton: 'Add new line',
};
