import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { AnonymousGet, AnonymousPost } from 'Store/Api/CallApi';
import {
  CHECK_EXISTING_EXTERNAL_USER_URL,
  ADD_EXTERNAL_USER_URL,
} from 'Store/Api/ApiEndpoints';
import {
  CHECK_EXTERNAL_USER_EXISTS,
  checkExternalUserExistsSuccess,
  ADD_EXTERNAL_USER,
  AddExternalUserResult,
} from 'Store/Areas/Register/RegisterActions';


/* Worker Sagas */
function* getExternalUserExists(action) {
  const response =
    yield AnonymousGet(CHECK_EXISTING_EXTERNAL_USER_URL(action.payload.emailAddress));
  if (response.ok) {
    const data = yield response.json();
    yield put(checkExternalUserExistsSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* addExternalUser(action) {
  const response = yield AnonymousPost(ADD_EXTERNAL_USER_URL, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(AddExternalUserResult(data.addedItem));
  } else {
    yield put(AddExternalUserResult(null));
  }
}


/* Watcher Sagas */
export function* registerSagas() {
  yield takeLatest(CHECK_EXTERNAL_USER_EXISTS, getExternalUserExists);
  yield takeLatest(ADD_EXTERNAL_USER, addExternalUser);
}
