export const styles = theme => ({
  grid: {
    marginTop: 20,
    maxWidth: '100%',
  },
  keywordsGrid: {
    maxWidth: '100%',
  },
  card: {
    padding: 20,
  },
  scrollDiv: {
    height: 'Calc(100vh - 230px)',
    overflowY: 'auto',
  },
  rootKeyword: {
    paddingBottom: 20,
  },
  andWord: {
    paddingTop: 20,
    fontSize: theme.constants.fontSizes.regular,
  },
  loaderIcon: {
    marginTop: 10,
  },
  lastKeywordsPageMessage: {
    paddingTop: 20,
  },
  searchMatchesNoKeywordsMessage: {
    paddingTop: 20,
    paddingLeft: 4,
  },
  simpleMessage: theme.simpleMessage,
});
