export const styles = theme => ({
  root: {
    padding: 25,
    paddingTop: 40,
  },
  title: {
    fontSize: theme.constants.fontSizes.large,
    color: theme.palette.primary.darkText,
    marginBottom: 20,
  },
});
