import {
  GET_TEMPLATES_DATA,
  GET_TEMPLATES_DATA_SUCCESS,
  RESET_TEMPLATES_DATA,
  GET_TEMPLATES_FOR_CURRENT_USER,
  GET_TEMPLATES_FOR_CURRENT_USER_SUCCESS,
  DELETE_TEMPLATE,
  DELETE_TEMPLATE_SUCCESS,
} from 'Store/Areas/Projects/TemplatesActions';

const initialState = {
  data: [],
  loading: true,
};

export default function templateReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEMPLATES_DATA:
    case GET_TEMPLATES_DATA_SUCCESS:
    case GET_TEMPLATES_FOR_CURRENT_USER:
    case GET_TEMPLATES_FOR_CURRENT_USER_SUCCESS:
      return Object.assign({}, state, {
        data: action.data,
        loading: action.loading,
      });

    case DELETE_TEMPLATE:
    case DELETE_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
      });

    case RESET_TEMPLATES_DATA:
      return {
        ...initialState,
        loading: action.loading,
      };

    default:
      return state;
  }
}
