const styles = theme => ({
  root: {
    height: 50,
    paddingLeft: 10,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
  },
  light: {
    color: theme.palette.colors.white,
    borderBottomColor: theme.palette.colors.white,
  },
  dark: {
    color: theme.palette.colors.darkText,
    borderBottomColor: theme.palette.primary.main,
  },
  label: {
    color: theme.palette.colors.white,
    fontSize: theme.constants.fontSizes.small,
    opacity: '0.6',
  },
  error: {
    color: 'red',
    paddingTop: 4,
    marginTop: 0,
    fontSize: theme.constants.fontSizes.regular,
  },
  disabled: {
    color: theme.palette.colors.white,
  },
});

export default styles;
