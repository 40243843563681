import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Modal from 'Components/Shared/Modal/StyledModal';
import StyledModalButtons from 'Components/Shared/Modal/StyledModal.Buttons';
import { commonStrings } from 'Constants/CommonStrings';
import { strings } from 'Constants/Categorization/Strings';
import { requestDeleteRules, closeDeleteRule } from 'Store/Areas/Rule/DeleteRuleActions';
import { styles } from './DeleteRuleModal.styles';

class DeleteRuleModal extends React.PureComponent {
  onCancel() {
    this.props.dispatch(closeDeleteRule());
  }

  onSubmit() {
    const {
      dispatch, periodId, ruleId, ruleText,
    } = this.props;
    dispatch(requestDeleteRules(
      periodId,
      Array.isArray(ruleId) ? ruleId : [ruleId],
      Array.isArray(ruleText) ? ruleText : [ruleText],
    ));
  }

  render() {
    const {
      classes,
      deleteRule,
      ruleId,
    } = this.props;

    if (!deleteRule.open || !ruleId) {
      return null;
    }

    return (
      <Modal open isSubmitting={deleteRule.loading}>
        <div className={classes.content}>
          <div className={classes.title}>
            {strings.deleteRule}
          </div>
          <div className={classes.text}>
            {strings.deleteRuleConfirmation}
          </div>
        </div>
        <StyledModalButtons
          onCancel={() => this.onCancel()}
          onAccept={() => this.onSubmit()}
          acceptText={strings.deleteRule}
          cancelText={commonStrings.cancel}
          isSubmitting={deleteRule.loading}
        />
      </Modal>
    );
  }
}

DeleteRuleModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  ruleId: PropTypes.number.isRequired,
  ruleText: PropTypes.string.isRequired,
  deleteRule: PropTypes.shape({
    open: PropTypes.bool,
    loading: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state) {
  const { inspectedRule } = state.rules.inspectRule;
  return {
    ruleId: inspectedRule ? inspectedRule.id : -1,
    periodId: inspectedRule ? inspectedRule.periodId : -1,
    deleteRule: state.rules.deleteRule,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(DeleteRuleModal);
