import { CREATING_NEW_ENTITY, POST_ENTITY, POST_ENTITY_SUCCESS, CANCEL_ENTITY_CREATION, EDIT_ENTITY, EDIT_LOAD_ENTITY_SUCCESS, DELETE_ENTITY } from 'Store/Areas/Setup/Admin/EntityActions';

const initialState = {
  showForm: false,
  loading: false,
  isEdit: false,
  payload: {
    id: -1,
  },
  selection: {
    selectedGroup: { id: 0 },
  },
};

export default function createEntityReducer(state = initialState, action) {
  switch (action.type) {
    case CREATING_NEW_ENTITY:
    case EDIT_ENTITY:
      return Object.assign({}, state, {
        showForm: action.showForm,
        isEdit: action.isEdit,
        loading: action.loading,
        payload: action.payload,
        selection: action.selection,
      });
    case CANCEL_ENTITY_CREATION:
      return initialState;
    case POST_ENTITY:
    case POST_ENTITY_SUCCESS:
    case EDIT_LOAD_ENTITY_SUCCESS:
      return Object.assign({}, state, {
        showForm: action.showForm,
        isEdit: action.isEdit,
        loading: action.loading,
        payload: action.payload,
      });
    case DELETE_ENTITY:
      return Object.assign({}, state, {
        showForm: action.showForm,
        payload: action.payload,
      });
    default:
      return state;
  }
}
