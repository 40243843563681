import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Paper, withStyles, withTheme } from '@material-ui/core';
import { strings } from 'Constants/Projects/CreatePeriod/Strings';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { styles } from './PeriodViewer.styles';

class PeriodViewer extends React.PureComponent {
  render() {
    if (this.props.periodInfo.loading) {
      return <CircularLoader />;
    }

    const { classes } = this.props;
    const { mappedTags, rulesCount, categories } = this.props.periodInfo;

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          {strings.viewPeriodTitle}
        </div>
        <Paper className={classes.paper}>
          <div className={classes.headerContainer}>
            <div className={`
              ${classes.largeDescription}
              ${classes.bold}
              ${classes.paddingBottom}
              ${classes.paddingTop}`}
            >
              {`${rulesCount !== undefined ? rulesCount : 0} ${strings.templateViewerRules(rulesCount)}, 
                ${categories.length} ${strings.templateViewerCategories(categories.length)},
                ${mappedTags.length} ${strings.periodViewerTags(mappedTags.length)}`}
            </div>
            <div className={`
                ${classes.regularDescription}
                ${classes.paddingTop}`}
            >
              {`${strings.templateViewerContext} ${this.props.project.taxContext.name}`}
            </div>
          </div>
          <div className={classes.contentContainer}>
            <div className={`
                ${classes.regularDescription}
                ${classes.bold}`}
            >
              {strings.necessaryTags}
            </div>
            <div className={`
                ${classes.regularDescription}
                ${classes.paddingTop}
                ${classes.paddingBottom}`}
            >
              {mappedTags.filter(t => t.isMandatory).map(x => x.name).join(', ')}
            </div>
            <If condition={mappedTags.some(t => !t.isMandatory)}>
              <div className={`
                  ${classes.regularDescription}
                  ${classes.bold}
                  ${classes.paddingTop}`}
              >
                {strings.unnecessaryTags}
              </div>
              <div className={`
                  ${classes.regularDescription}
                  ${classes.paddingTop}
                  ${classes.paddingBottom}`}
              >
                {mappedTags.filter(t => !t.isMandatory).map(x => x.name).join(', ')}
              </div>
            </If>
            <div className={`
                ${classes.regularDescription}
                ${classes.bold}
                ${classes.paddingTop}`}
            >
              {strings.categories}
            </div>
            <div className={`
                ${classes.regularDescription}
                ${classes.paddingTop}
                ${classes.paddingBottom}`}
            >
              {categories.join(', ')}
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

PeriodViewer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  project: PropTypes.shape({
    taxContext: PropTypes.object,
  }).isRequired,
  periodInfo: PropTypes.shape({
    id: PropTypes.number.isRequired,
    mappedTags: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      isMandatory: PropTypes.bool.isRequired,
    })).isRequired,
    rulesCount: PropTypes.number.isRequired,
    categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    loading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
  }).isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(PeriodViewer);
