const styles = theme => ({
  hoverText: {
    fontWeight: 'bold',
    marginRight: 10,
    fontSize: theme.constants.fontSizes.small,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  node: {
    color: theme.palette.colors.white,
    borderBottomWidth: 'thin',
    borderBottomStyle: 'solid',
    paddingLeft: 10,
    cursor: 'pointer',
    borderColor: 'rgba(255, 255, 255, 0.3)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
  paddingLarge: {
    paddingTop: 19.5,
    paddingBottom: 11.5,
  },
  paddingSmall: {
    paddingTop: 18,
    paddingBottom: 10,
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 0,
  },
  iconContainer: {
    width: 30,
    opacity: 0.7,
  },
  flex: {
    flexGrow: 1,
  },
  level0: {
    marginLeft: 0,
  },
  level1: {
    marginLeft: 30,
  },
  level2: {
    marginLeft: 60,
  },
  level3: {
    flexGrow: 3,
  },
  rotateAntiClockwise: {
    transform: 'rotate(-90deg)',
  },
  bold: {
    fontWeight: 'bold',
  },
  selected: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
  text: {
    fontSize: theme.constants.fontSizes.regular,
  },
  smallText: {
    fontSize: theme.constants.fontSizes.small,
  },
  add: {
    fontWeight: 'bold',
  },
  hideBorder: {
    borderBottomStyle: 'none !important',
  },
  level3Padding: {
    width: 20,
    marginLeft: 60,
    height: 'inherit',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export default styles;
