import React from 'react';
import Modal from 'Components/Shared/Modal/StyledModal';
import PropTypes from 'prop-types';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { Formik } from 'formik';
import { strings } from 'Constants/Upload/TagMapping';
import styles from './DeleteTagModal.styles';

class DeleteTagModal extends React.PureComponent {
  render() {
    const {
      classes,
      open,
      onCancel,
      loading,
      tagName,
      tagId,
      onAccept,
    } = this.props;
    return (
      <Modal open={open} className={classes.root} isSubmitting={loading}>
        <Formik
          initialValues={{
            tagName: tagName,
            tagId: tagId,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onAccept(values.tagName, values.tagId);
          }}
          render={({
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.content}>
                <div className={classes.title}>{strings.deleteTagTitle}</div>
                <div className={classes.description}>{strings.deleteTagText(tagName)}</div>
              </div>
              <Buttons
                isSubmitting={loading}
                onAccept={handleSubmit}
                onCancel={onCancel}
                acceptText={strings.removeTag}
                cancelText={strings.addTagModalCancel}
              />
            </form>
            )}
        />
      </Modal>
    );
  }
}

DeleteTagModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  tagName: PropTypes.string.isRequired,
  tagId: PropTypes.number.isRequired,
};

export default compose(withStyles(styles), withTheme())(DeleteTagModal);
