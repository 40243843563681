import React from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { formatCurrency } from 'Helpers/NumberHelpers';
import DragnDrop from 'Constants/DragnDrop';
import withDndPreviewContext from 'Components/Shared/DragNDrop/DndPreviewContextProvider';
import LinearProgress from 'Components/Shared/LinearProgress/LinearProgress';
import { styles } from './Keyword.styles';

class Keyword extends React.PureComponent {
  componentDidMount() {
    this.props.connectDragPreview(this.props.dndPreview);
  }

  onClick = (e) => {
    this.props.onClick(this.props.keyword, e);
  }

  onMouseOver = () => {
    this.props.onMouseOver(this.props.keyword);
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onClick(this.props.keyword, e);
    }
  }

  static getDragProps(props) {
    return {
      keywords: (props.selectedKeywords
        && props.selectedKeywords.some(k => k.name === props.keyword.name))
        ? props.selectedKeywords
        : [props.keyword],
      currentTag: props.currentTag,
      type: DragnDrop.keyword,
      rootKeyword: props.rootKeyword,
      rootTag: props.rootTag,
    };
  }

  render() {
    const {
      keyword,
      classes,
      currencySymbol,
      connectDragSource,
      isDragging,
      isSelected,
      showProgress,
      rootKeyword,
      allowDrag,
      transparentSource,
      onMouseOut,
    } = this.props;

    let displayName = keyword.name;

    if (isDragging && rootKeyword) {
      displayName = `${rootKeyword.name} + ${displayName}`;
    }
    const renderedComponent = (
      <div
        className={`${classes.root} ${isSelected ? classes.selectedItem : ''} ${isDragging && classes.dragging}`}
        onClick={this.onClick}
        onMouseOver={this.onMouseOver}
        onFocus={this.onMouseOver}
        onMouseOut={onMouseOut}
        onBlur={onMouseOut}
        onKeyPress={this.onKeyPress}
        role="button"
        tabIndex="0"
      >
        <span className={classes.flex}>{displayName}</span>
        <If condition={keyword.totalSum !== undefined}>
          <span className={classes.padded}>
            {formatCurrency(keyword.totalSum, currencySymbol, 0)}
          </span>
        </If>
        <div className={classes.progressBar}>
          <If condition={showProgress}>
            <LinearProgress progress={keyword.percentageCategorized} />
          </If>
        </div>
        {transparentSource && allowDrag && connectDragSource(<div style={{
          position: 'absolute', height: '100%', width: '100%', opacity: 0, zIndex: 100, right: 0,
        }}
        />)}
      </div>
    );

    return (allowDrag && !transparentSource) ?
      connectDragSource(renderedComponent) : renderedComponent;
  }
}

Keyword.defaultProps = {
  onClick: () => {},
  onMouseOver: () => {},
  onMouseOut: () => {},
  onBeginDrag: () => {},
  isSelected: false,
  isDragging: false,
  showProgress: true,
  allowDrag: true,
  rootKeyword: null,
  rootTag: null,
  selectedKeywords: null,
  transparentSource: false,
};

Keyword.propTypes = {
  keyword: PropTypes.shape({
    name: PropTypes.string,
    totalSum: PropTypes.number,
    percentageCategorized: PropTypes.number,
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  selectedKeywords: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    totalSum: PropTypes.number,
    percentageCategorized: PropTypes.number,
  })),
  currentTag: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  isSelected: PropTypes.bool,
  isDragging: PropTypes.bool,
  showProgress: PropTypes.bool,
  allowDrag: PropTypes.bool,
  rootKeyword: PropTypes.shape({
    name: PropTypes.string,
  }),
  rootTag: PropTypes.shape({
    name: PropTypes.string,
  }),
  onBeginDrag: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
  transparentSource: PropTypes.bool,
  dndPreview: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

const spec = {
  beginDrag(props) {
    const dragProps = Keyword.getDragProps(props);

    if (props.onBeginDrag) {
      props.onBeginDrag(dragProps);
    }

    return dragProps;
  },
  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const { onDrop } = monitor.getDropResult();
      onDrop();
    }
  },
};

function collect(connect) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  };
}

export default compose(
  DragSource(DragnDrop.keyword, spec, collect),
  withStyles(styles),
  withTheme(),
  withDndPreviewContext(),
)(Keyword);
