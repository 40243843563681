export const ADD_SEGMENT = 'RULEBUILDER/CHANGE/ADD_SEGMENT';
export const DELETE_SEGMENT = 'RULEBUILDER/CHANGE/DELETE_SEGMENT';
export const MOVE_SEGMENT = 'RULEBUILDER/CHANGE/MOVE_SEGMENT';

export const ADD_BRACKETS = 'RULEBUILDER/CHANGE/ADD_BRACKETS';

export const UPDATE_TAG = 'RULEBUILDER/CHANGE/UPDATE_TAG';
export const UPDATE_OPERATOR = 'RULEBUILDER/CHANGE/UPDATE_OPERATOR';
export const UPDATE_CONDITION = 'RULEBUILDER/CHANGE/UPDATE_CONDITION';
export const UPDATE_JOIN = 'RULEBUILDER/CHANGE/UPDATE_JOIN';
export const DELETE_TAG = 'RULEBUILDER/CHANGE/DELETE_TAG';
export const DELETE_OPERATOR = 'RULEBUILDER/CHANGE/DELETE_OPERATOR';
export const DELETE_JOIN = 'RULEBUILDER/CHANGE/DELETE_JOIN';

export const SET_FREE_TEXT_RULE = 'RULEBUILDER/SET_FREE_TEXT_RULE';
export const BUILD_RULE_SUCCESS = 'RULEBUILDER/BUILD_RULE_SUCCESS';
export const VALIDATE_RULE = 'RULEBUILDER/VALIDATE_RULE';
export const RESET_RULE = 'RULEBUILDER/RESET_RULE';

export const LOAD_INITIAL_RULE = 'RULEBUILDER/LOAD_INITIAL_RULE';

export function addSegment() {
  return {
    type: ADD_SEGMENT,
  };
}

export function loadInitialRule(initialRuleId, initialRuleChunks, rule, tags) {
  return {
    type: LOAD_INITIAL_RULE,
    initialRuleId,
    initialRuleChunks,
    rule,
    ruleError: '',
    ruleHasError: false,
    tags,
  };
}

export function deleteSegment(id) {
  return {
    type: DELETE_SEGMENT,
    ids: [id],
  };
}

export function deleteMultipleSegments(ids) {
  return {
    type: DELETE_SEGMENT,
    ids: ids,
  };
}

export function moveSegment(fromIndex, toIndex) {
  return {
    type: MOVE_SEGMENT,
    fromIndex: fromIndex,
    toIndex: toIndex,
  };
}

export function addBrackets(type) {
  return {
    type: ADD_BRACKETS,
    bracketType: type,
  };
}

export function updateTag(id, tag) {
  return {
    type: UPDATE_TAG,
    id: id,
    update: { tag: tag },
  };
}

export function deleteTag(id) {
  return {
    type: DELETE_TAG,
    id: id,
    update: { tag: { id: 0, type: '', name: '' } },
  };
}

export function updateOperator(id, operator) {
  return {
    type: UPDATE_OPERATOR,
    id: id,
    update: { operator: operator },
  };
}

export function deleteOperator(id) {
  return {
    type: DELETE_OPERATOR,
    id: id,
    update: { operator: '' },
  };
}

export function updateCondition(id, condition) {
  return {
    type: UPDATE_CONDITION,
    id: id,
    update: { condition: condition },
  };
}

export function updateJoin(id, join) {
  return {
    type: UPDATE_JOIN,
    id: id,
    update: { join: join },
  };
}

export function deleteJoin(id) {
  return {
    type: DELETE_JOIN,
    id: id,
    update: { join: '' },
  };
}

export function setFreeTextRule(rule) {
  return {
    type: SET_FREE_TEXT_RULE,
    rule: rule,
  };
}

export function buildRuleSuccess(rule) {
  return {
    type: BUILD_RULE_SUCCESS,
    rule: rule,
  };
}

export function validateRule(error, hasError) {
  return {
    type: VALIDATE_RULE,
    hasError: hasError,
    error: error,
  };
}

export function resetRule() {
  return {
    type: RESET_RULE,
  };
}
