import { takeLatest, put } from 'redux-saga/effects';
import { levels } from 'Constants/EntityLevels';
import { resetUsers } from 'Store/Areas/Setup/Admin/GetUserActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { ADMIN_GET_GROUPS_URL } from 'Store/Api/ApiEndpoints';
import { Post } from 'Store/Api/CallApi';
import {
  GET_ADMIN_TREEPICKER_DATA,
  getAdminTreePickerDataSuccess,
  selectNode,
} from 'Store/Areas/Setup/Admin/AdminTreePickerActions';

/* Worker Sagas */
function* getAdminTreePickerData(action) {
  const response = yield Post(ADMIN_GET_GROUPS_URL, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getAdminTreePickerDataSuccess(
      data.addedItem,
      action.payload.taxContextFilter,
      action.payload.emailFilter,
    ));

    if (action.resetAdminSelectionNode) {
      yield put(selectNode(data.length ? data[0].id : -1, -1, -1, levels.group));

      if (!data.length) {
        yield put(resetUsers());
      }
    }
    if (!data.success) {
      yield put(displayToastNotification(data.error));
    }
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* adminTreePickerSagas() {
  yield takeLatest(GET_ADMIN_TREEPICKER_DATA, getAdminTreePickerData);
}
