import { Dialog, IconButton, Toolbar, withStyles, withTheme } from '@material-ui/core';
import CloseIcon from 'Assets/Images/exit-ic.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { styles } from './FullPageModal.styles';

class FullPageModal extends React.PureComponent {
  render() {
    const {
      classes,
      open,
      children,
      onClose,
      title,
    } = this.props;
    return (
      <Dialog
        disableBackdropClick
        open={open}
        onClose={onClose}
        classes={{ paper: classes.root }}
        transitionDuration={0}
      >
        <Toolbar className={classes.headerToolbar}>
          <span className={classes.title}>
            {title}
          </span>
          <IconButton disableRipple className={classes.closeButton} color="inherit" onClick={onClose} aria-label="Close" >
            <img alt="Close" src={CloseIcon} className={classes.closeIcon} />
          </IconButton>
        </Toolbar>
        {children}
      </Dialog>
    );
  }
}

FullPageModal.defaultProps = {
  title: '',
};

FullPageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  title: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(FullPageModal);
