import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { routes } from 'Constants/Routes';
import { strings } from 'Constants/Projects/CreatePeriod/Strings';
import { getCreatePeriodSchema } from 'Constants/Projects/CreatePeriod/CreatePeriodSchema';
import { commonStrings } from 'Constants/CommonStrings';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { getErrorsFromValidationError } from 'Constants/ValidationHelpers';
import Input from 'Components/Shared/Inputs/SidebarInput';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import Select from 'Components/Shared/Selects/Select';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import SimpleButton from 'Components/Shared/Buttons/SimpleButton/SimpleButton';
import DisabledSelect from 'Components/Shared/Selects/DisabledSelect';
import DatePicker from 'Components/Shared/DatePicker/DatePicker';
import ErrorModal from 'Components/Shared/Modal/ErrorModal';
import ErrorLabel, { constants as labelConstants } from 'Components/Shared/Label/ErrorLabel';
import { getExternalReviewExportedFiles } from 'Store/Areas/Export/ExternalReviewActions';
import { archivePeriod, deletePeriod, togglePeriodLock, clearPeriodData } from 'Store/Areas/Projects/CreatePeriodActions';
import { externalReviewExportedFilesSelector, externalReviewLoadingSelector } from 'Store/Areas/Export/ExportSelectors';
import AuthorisedComponent, { constants as adminConstants } from 'Components/Shared/AuthorisedComponent/AuthorisedComponent';
import RadioButtonContainer from 'Components/Shared/RadioButtons/RadioButtonContainer';
import RadioButton from 'Components/Shared/RadioButtons/RadioButton';
import DeletePeriodOverlay from './DeletePeriodOverlay';
import ArchivePeriodOverlay from './ArchivePeriodOverlay';
import styles from './CreatePeriodForm.styles';
import ClearPeriodDataOverlay from './ClearPeriodDataOverlay';

class CreatePeriodForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isDeleteModalOpen: false,
      isArchiveModalOpen: false,
      creatingPeriodFromTemplate: false,
      templatePeriodToggled: false,
      isExternalReviewInProgressModalOpen: false,
      groups: this.getFilteredGroups(props.treePicker.data),
      isClearPeriodDataModalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { externalReviewExportedFiles, externalReviewLoading } = this.props;

    if (externalReviewExportedFiles
      && prevProps.externalReviewExportedFiles !== externalReviewExportedFiles
      && externalReviewExportedFiles.length > 0) {
      this.showExternalReviewInProgressModal();
    } else if (externalReviewExportedFiles
      && prevProps.externalReviewLoading !== externalReviewLoading
      && !externalReviewLoading
      && externalReviewExportedFiles.length === 0) {
      this.executeTogglePeriodLock(false);
    }

    if (prevProps.treePicker.loading && !this.props.treePicker.loading) {
      this.updateGroupsState(this.props.treePicker.data);
    }
  }

  onRemovePeriod = () => {
    this.setState({
      isDeleteModalOpen: true,
    });
  }

  onClearPeriodData = () => {
    this.setState({
      isClearPeriodDataModalOpen: true,
    });
  }

  onCloseClearPeriodData = () => {
    this.setState({
      isClearPeriodDataModalOpen: false,
    });
  }

  onConfirmClearPeriodData = () => {
    const { createPeriod, dispatch } = this.props;
    dispatch(clearPeriodData(
      createPeriod.payload.id,
      createPeriod.selection.selectedGroup.id,
      createPeriod.payload.name,
    ));
    this.onCloseClearPeriodData();
    this.props.onCancelClick();
  }

  onConfirmDelete = () => {
    const { createPeriod, dispatch } = this.props;
    dispatch(deletePeriod(
      createPeriod.payload.id,
      createPeriod.selection.selectedGroup.id,
      createPeriod.payload.name,
    ));
    this.onCloseDelete();
    this.props.onCancelClick();
  }

  onCloseDelete = () => {
    this.setState({
      isDeleteModalOpen: false,
    });
  }

  onArchivePeriod = () => {
    this.setState({
      isArchiveModalOpen: true,
    });
  }

  onTogglePeriodLock = (isLocking) => {
    const { createPeriod, dispatch } = this.props;

    if (!isLocking) {
      dispatch(getExternalReviewExportedFiles(createPeriod.payload.id));
    } else {
      this.executeTogglePeriodLock(true);
    }
  }

  onConfirmArchive = () => {
    const { createPeriod, dispatch } = this.props;
    dispatch(archivePeriod(createPeriod.payload.id, createPeriod.selection.selectedGroup.id));
    this.onCloseArchive();
  }

  onCloseArchive = () => {
    this.setState({
      isArchiveModalOpen: false,
    });
  }

  onCloseExternalReviewInProgress = () => {
    this.setState({
      isExternalReviewInProgressModalOpen: false,
    });
  }

  setCreatingPeriodFromTemplate = (value) => {
    this.setState({
      creatingPeriodFromTemplate: value,
      templatePeriodToggled: true,
    });
  }

  resetCreatingPeriodFromTemplate = () => {
    this.setState({
      creatingPeriodFromTemplate: false,
      templatePeriodToggled: false,
    });
  }

  getPeriodsForProject(groupId, entityId, projectId, periodId, dropDown, includeArchived = false) {
    if (projectId === 0) {
      return [''];
    }
    let projectPeriods = this.props.treePicker.data
      .find(group => group.id === groupId)
      .entities.find(entity => entity.id === entityId)
      .projects.find(project => project.id === projectId)
      .periods;

    if (!includeArchived) {
      projectPeriods = projectPeriods.filter(p => p.status !== periodStatuses.periodArchived);
    }
    if (!this.state.templatePeriodToggled) {
      if (projectPeriods.filter(p => p.status !== periodStatuses.periodArchived).length === 0) {
        this.setCreatingPeriodFromTemplate(true);
      } else {
        this.setCreatingPeriodFromTemplate(false);
      }
    }
    if (periodId) {
      projectPeriods = projectPeriods.filter(x => x.id !== periodId);
    }

    return dropDown ? projectPeriods.map(item => item) : projectPeriods.map(item => item.name);
  }

  getFilteredGroups(treePickerData) {
    const { user: { permissions: { data: { projectsCanAddPeriod } } } } = this.props;

    return [...treePickerData].filter((g) => {
      g.entities = g.entities.filter((e) => { // eslint-disable-line no-param-reassign
        e.projects = e.projects.filter(p => projectsCanAddPeriod.includes(p.id));

        return e.projects.length;
      });

      return g.entities.length;
    });
  }

  fetchTemplates(groupId, entityId, projectId) {
    if (projectId === 0) {
      this.props.resetTemplates();
    } else {
      const { taxContextId } = this.props.treePicker.data
        .find(group => group.id === groupId)
        .entities.find(entity => entity.id === entityId)
        .projects.find(project => project.id === projectId);

      this.props.fetchTemplates(taxContextId, groupId);
    }
  }

  executeTogglePeriodLock(isLocking) {
    const { createPeriod, dispatch } = this.props;

    dispatch(togglePeriodLock(
      createPeriod.payload.id,
      {
        selectedGroupId: createPeriod.selection.selectedGroup.id,
        selectedEntityId: createPeriod.selection.selectedEntity.id,
        selectedProjectId: createPeriod.selection.selectedProject.id,
        selectedTaxContextId: createPeriod.selection.selectedProject.taxContextId,
        selectedTemplateId: createPeriod.payload.templateId,
        periodName: createPeriod.payload.name,
      },
      isLocking,
    ));

    dispatch(push(routes.home));
  }

  updateGroupsState(treePickerData) {
    this.setState({
      groups: this.getFilteredGroups(treePickerData),
    });
  }

  showExternalReviewInProgressModal() {
    this.setState({
      isExternalReviewInProgressModalOpen: true,
    });
  }

  handleProjectChange(groupId, entityId, projectId) {
    this.resetCreatingPeriodFromTemplate();
    const periods = this.getPeriodsForProject(groupId, entityId, projectId, undefined, true);

    if (periods.length === 0) {
      this.setCreatingPeriodFromTemplate(true);
    }
  }

  render() {
    const {
      isDeleteModalOpen,
      isArchiveModalOpen,
      creatingPeriodFromTemplate,
      groups,
      isClearPeriodDataModalOpen,
    } = this.state;
    const {
      classes,
      createPeriod,
      treePicker,
      templates,
      submitForm,
      onCancelClick,
      selectTemplate,
      selectPeriod,
      setGroupEntityAndProject,
      isCreatePeriodDisabled,
      templateRules,
    } = this.props;
    const createPeriodButtonText = templateRules
      .some(r => r.isDisabled) ? strings.proceedAnyway : strings.createPeriod;
    const periodIsArchived = createPeriod.payload
      && createPeriod.payload.status === periodStatuses.periodArchived;

    const periodIsReadOnly = createPeriod.payload
      && createPeriod.payload.isReadOnly;

    const periodIsLocked = createPeriod.payload
      && createPeriod.payload.isLocked;

    const changeSupportingDocsAfterPeriodCreation = createPeriod.payload
      && createPeriod.payload.changeSupportingDocsAfterPeriodCreation;

    const periodLockIsEnabled = createPeriod.payload
      && createPeriod.payload.status === periodStatuses.readyForUserCategorisation;

    const periodClearIsEnabled = periodLockIsEnabled || (createPeriod.payload
      && createPeriod.payload.status === periodStatuses.periodCategorisationComplete);

    if (treePicker.loading || createPeriod.loading) {
      return (
        <div className={classes.root}>
          <CircularLoader size={60} />
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <Formik
          validateOnChange
          validateOnBlur
          initialValues={{
            group: createPeriod.selection.selectedGroup.id,
            entity: createPeriod.selection.selectedEntity.id,
            project: createPeriod.selection.selectedProject.id,
            period: createPeriod.isEdit ? createPeriod.payload.name : '',
            dateFrom: createPeriod.isEdit ? createPeriod.payload.startDate : '',
            dateTo: createPeriod.isEdit ? createPeriod.payload.endDate : '',
            template: createPeriod.isEdit ? createPeriod.payload.templateId : 0,
            priorPeriod: createPeriod.isEdit ? createPeriod.payload.priorPeriod : 0,
          }}
          validate={(values) => {
            const periods =
              this.getPeriodsForProject(
                values.group,
                values.entity,
                values.project,
                createPeriod.isEdit ? createPeriod.payload.id : 0,
                false,
                true,
              );
            const validationSchema = getCreatePeriodSchema(
              values,
              periods,
              createPeriod.isEdit,
              !creatingPeriodFromTemplate,
            );
            try {
              validationSchema.validateSync(values, { abortEarly: false });
              return {};
            } catch (error) {
              return getErrorsFromValidationError(error);
            }
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            submitForm(createPeriod.isEdit ? {
              name: values.period,
              startDate: values.dateFrom,
              endDate: values.dateTo,
              id: createPeriod.payload.id,
            } : {
              name: values.period,
              groupId: values.group,
              projectId: values.project,
              startDate: values.dateFrom,
              endDate: values.dateTo,
              templateId: values.template,
              previousPeriodId: values.priorPeriod,
              id: 0,
            }, {
              groupId: values.group,
              entityId: values.entity,
              projectId: values.project,
            });
          }}
          render={({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            touched,
          }) => {
            const group = values.group && groups.find(item => item.id === values.group);

            const entities = group ? group.entities : [];
            const entity = values.entity && entities.find(x => x.id === values.entity);

            const projects = entity ? entity.projects : [];
            const project = values.project && projects.find(x => x.id === values.project);

            const taxContext = project ? project.taxContext.name : '';

            const templatesData = templates.data;

            const periodsData = values.project ? this.getPeriodsForProject(
              values.group,
              values.entity,
              values.project,
              createPeriod.isEdit ? createPeriod.payload.id : 0,
              true,
            ) : [];

            let period;
            let template;
            let createdFromName;
            let createdFromLabel;

            if (createPeriod.isEdit) {
              period = project
                ? project.periods.find(x => x.id === createPeriod.payload.id)
                : null;

              template = templatesData && period
                ? templatesData.find(x => x.id === period.templateId)
                : null;

              if (template && (createPeriod.payload.previousPeriodId === null
                || createPeriod.payload.previousPeriodId === 0)) {
                  createdFromName = template.name;
                  createdFromLabel = strings.templateLabel;
                } else {
                  const previousPeriod =
                    project.periods.find(x => x.id === createPeriod.payload.previousPeriodId);
                  createdFromName = previousPeriod ? previousPeriod.name : '[Period deleted]';
                  createdFromLabel = strings.periodLabel;
                }
            }

            return (
              <form className={`${classes.form}`}>
                <div className={`${classes.flex} ${classes.padded} ${classes.scrollable}`}>
                  <div className={`${classes.title}`}>
                    <div>
                      {strings.sidebarTitle}
                    </div>
                  </div>
                  <Choose>
                    <When condition={!createPeriod.isEdit}>
                      <Select
                        id="group"
                        label="Group"
                        value={values.group}
                        error={touched.group ? errors.group : ''}
                        onChange={(value) => {
                          setFieldValue('group', value);
                          setFieldValue('project', 0);
                          setFieldValue('entity', 0);
                          setFieldValue('template', 0);
                          setFieldValue('priorPeriod', 0);
                        }}
                        data={groups}
                        className={classes.fieldMargin}
                        placeholder={strings.groupOptionPlaceholder}
                      />
                    </When>
                    <Otherwise>
                      <DisabledSelect
                        text={group ? group.name : ''}
                        label="Group"
                        className={classes.fieldMargin}
                      />
                    </Otherwise>
                  </Choose>
                  <Choose>
                    <When condition={!createPeriod.isEdit}>
                      <Select
                        id="entity"
                        label="Entity"
                        value={values.entity}
                        error={touched.entity ? errors.entity : ''}
                        onChange={(value) => {
                          setFieldValue('entity', value);
                          setFieldValue('project', 0);
                          setFieldValue('template', 0);
                          setFieldValue('priorPeriod', 0);
                        }}
                        data={entities}
                        className={classes.fieldMargin}
                        placeholder={strings.entityOptionPlaceholder}
                      />
                    </When>
                    <Otherwise>
                      <DisabledSelect
                        text={entity ? entity.name : ''}
                        label="Entity"
                        className={classes.fieldMargin}
                      />
                    </Otherwise>
                  </Choose>
                  <Choose>
                    <When condition={!createPeriod.isEdit}>
                      <Select
                        id="project"
                        label="Project"
                        value={values.project}
                        error={touched.project ? errors.project : ''}
                        onChange={(value) => {
                          setFieldValue('project', value);
                          setFieldValue('template', 0);
                          setFieldValue('priorPeriod', 0);
                          this.fetchTemplates(values.group, values.entity, value);
                          this.handleProjectChange(values.group, values.entity, value);
                        }}
                        data={projects}
                        className={classes.fieldMargin}
                        placeholder={strings.projectOptionPlaceholder}
                      />
                    </When>
                    <Otherwise>
                      <DisabledSelect
                        text={project ? project.name : ''}
                        label="Project"
                        className={classes.fieldMargin}
                      />
                    </Otherwise>
                  </Choose>
                  <Input
                    disabled={periodIsReadOnly}
                    id="period"
                    label="Period"
                    value={values.period || ''}
                    error={touched.period ? errors.period : ''}
                    onChange={(value) => {
                      setFieldTouched('period', true);
                      setFieldValue('period', value);
                    }}
                    className={classes.fieldMargin}
                    classes={{
                      root: periodIsReadOnly && classes.disabledInput,
                    }}
                    placeholder={strings.periodNamePlaceholder}
                  />
                  <Choose>
                    <When condition={!createPeriod.isEdit}>
                      <Input
                        id="context"
                        label="Context"
                        disabled
                        value={taxContext}
                        onChange={() => { }}
                        className={classes.fieldMargin}
                      />
                    </When>
                    <Otherwise>
                      <DisabledSelect
                        text={taxContext}
                        label="Context"
                        className={classes.fieldMargin}
                      />
                    </Otherwise>
                  </Choose>
                  <div className={classes.twoColumns}>
                    <DatePicker
                      disabled={periodIsReadOnly}
                      id="Date from"
                      className={`
                        ${classes.twoColumnField} 
                        ${classes.paddedRight}
                      `}
                      label="From"
                      value={createPeriod.isEdit || touched.dateFrom ? values.dateFrom || '' : ''}
                      onChange={(value) => {
                        setFieldValue('dateFrom', value);
                        setFieldTouched('dateFrom', true);
                      }}
                    />
                    <DatePicker
                      disabled={periodIsReadOnly}
                      id="Date To"
                      className={classes.twoColumnField}
                      label="To"
                      value={createPeriod.isEdit || touched.dateTo ? values.dateTo || '' : ''}
                      onChange={(value) => {
                        setFieldValue('dateTo', value);
                        setFieldTouched('dateTo', true);
                      }}
                    />
                  </div>
                  <div className={classes.fieldMargin}>
                    <ErrorLabel
                      error={errors.dateFrom}
                      verticalPadding
                      textColor={labelConstants.textColor.pink}
                    />
                    <ErrorLabel
                      error={errors.dateTo}
                      verticalPadding
                      textColor={labelConstants.textColor.pink}
                    />
                  </div>
                  <div>
                    <If condition={!createPeriod.isEdit && periodsData.length > 0}>
                      <RadioButtonContainer
                        label={strings.createFrom}
                        className={classes.fieldMargin}
                        childrenWrapperClassName={classes.twoColumns}
                      >
                        <RadioButton
                          active={creatingPeriodFromTemplate}
                          onClick={() => {
                            this.setCreatingPeriodFromTemplate(true);
                            setFieldValue('priorPeriod', 0);
                          }}
                          label={strings.templateLabel}
                          className={`
                            ${classes.twoColumnField} 
                            ${classes.marginRight}
                          `}
                        />
                        <RadioButton
                          active={!creatingPeriodFromTemplate}
                          onClick={() => {
                            this.setCreatingPeriodFromTemplate(false);
                            setFieldValue('template', 0);
                          }}
                          label={strings.periodLabel}
                          className={classes.twoColumnField}
                        />
                      </RadioButtonContainer>
                    </If>
                  </div>
                  <Choose>
                    <When condition={
                      !createPeriod.isEdit
                      && creatingPeriodFromTemplate
                      && templatesData.length > 0}
                    >
                      <Select
                        id="template"
                        label={strings.templateLabel}
                        value={values.template}
                        error={touched.template ? errors.template : ''}
                        data={templatesData}
                        onChange={(value) => {
                          setFieldValue('template', value);
                          selectTemplate(value);
                        }}
                        className={classes.fieldMargin}
                        placeholder={strings.templateOptionPlaceholder}
                      />
                    </When>
                    <When condition={
                      !createPeriod.isEdit
                      && !creatingPeriodFromTemplate
                      && periodsData.length > 0}
                    >
                      <Select
                        id="priorPeriod"
                        label={strings.periodLabel}
                        value={values.priorPeriod}
                        error={touched.priorPeriod ? errors.priorPeriod : ''}
                        data={periodsData}
                        onChange={(value) => {
                          setFieldValue('priorPeriod', value);
                          setGroupEntityAndProject(values.group, values.entity, values.project);
                          selectPeriod(value);
                        }}
                        className={classes.fieldMargin}
                        placeholder={strings.periodOptionPlaceholder}
                      />
                    </When>
                    <Otherwise>
                      <If condition={periodsData.length > 0}>
                        <DisabledSelect
                          text={createdFromName}
                          label={createdFromLabel}
                          className={classes.fieldMargin}
                        />
                      </If>
                    </Otherwise>
                  </Choose>
                  <If condition={createPeriod.isEdit && !periodIsArchived}>
                    <AuthorisedComponent
                      requiredPermissions={adminConstants.permissionLevels.requirePeriodAdmin}
                      periodGroupId={values.group}
                      periodEntityId={values.entity}
                    >
                      <If condition={createPeriod.payload.previousPeriodId > 0}>
                        <DisabledSelect
                          text={createPeriod.payload.originalTemplate}
                          label={strings.originalTemplate}
                          className={classes.fieldMargin}
                        />
                      </If>
                      <div className={classes.actionButtonsContainer}>
                        <Choose>
                          <When condition={periodIsLocked}>
                            <ErrorModal
                              open={this.state.isExternalReviewInProgressModalOpen}
                              onClose={this.onCloseExternalReviewInProgress}
                              title={strings.externalReviewInProgress}
                              error={strings.externalReviewInProgressMessage}
                            />
                            <SimpleButton
                              disabled={changeSupportingDocsAfterPeriodCreation}
                              description={strings.unlockPeriodButtonText}
                              onClickEvent={() => this.onTogglePeriodLock(false)}
                            >
                              {strings.unlockPeriod}
                            </SimpleButton>
                          </When>
                          <Otherwise>
                            <SimpleButton
                              disabled={!periodLockIsEnabled}
                              description={strings.lockPeriodButtonText}
                              onClickEvent={() => this.onTogglePeriodLock(true)}
                            >
                              {strings.lockPeriod}
                            </SimpleButton>
                          </Otherwise>
                        </Choose>
                        <SimpleButton
                          disabled={!periodIsLocked || periodIsArchived}
                          description={strings.archivePeriodButtonText}
                          onClickEvent={this.onArchivePeriod}
                        >
                          {strings.archivePeriod}
                        </SimpleButton>
                        <SimpleButton
                          disabled={periodIsLocked || periodIsArchived || !periodClearIsEnabled}
                          description={strings.clearPeriodButtonText}
                          onClickEvent={this.onClearPeriodData}
                        >
                          {strings.clearPeriodData}
                        </SimpleButton>
                        <SimpleButton
                          disabled={periodIsLocked}
                          description={strings.deletePeriodButtonText}
                          onClickEvent={this.onRemovePeriod}
                        >
                          {strings.deletePeriod}
                        </SimpleButton>
                      </div>
                    </AuthorisedComponent>
                  </If>
                </div>
                <div>
                  <Button
                    backgroundColor={constants.backgroundColor.dark}
                    height={constants.height.big}
                    className={classes.cancel}
                    onClick={onCancelClick}
                    rounded={false}
                  >
                    {commonStrings.cancel}
                  </Button>
                  <Button
                    className={classes.accept}
                    backgroundColor={constants.backgroundColor.main}
                    height={constants.height.big}
                    onClick={handleSubmit}
                    rounded={false}
                    type="submit"
                    disabled={periodIsLocked || isCreatePeriodDisabled}
                  >
                    {createPeriod.isEdit ? strings.editPeriod : createPeriodButtonText}
                  </Button>
                </div>
              </form>
            );
          }}
        />

        <DeletePeriodOverlay
          onConfirm={this.onConfirmDelete}
          onCancel={this.onCloseDelete}
          isModalOpen={isDeleteModalOpen}
        />
        {createPeriod.payload &&
        <ArchivePeriodOverlay
          period={createPeriod.payload.name}
          onConfirm={this.onConfirmArchive}
          onCancel={this.onCloseArchive}
          isModalOpen={isArchiveModalOpen}
        />}
        <ClearPeriodDataOverlay
          onConfirm={this.onConfirmClearPeriodData}
          onCancel={this.onCloseClearPeriodData}
          isModalOpen={isClearPeriodDataModalOpen}
        />
      </div>
    );
  }
}

CreatePeriodForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  treePicker: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.array,
  }).isRequired,
  selectTemplate: PropTypes.func.isRequired,
  fetchTemplates: PropTypes.func.isRequired,
  resetTemplates: PropTypes.func.isRequired,
  selectPeriod: PropTypes.func.isRequired,
  setGroupEntityAndProject: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  createPeriod: PropTypes.shape({
    isEdit: PropTypes.bool,
    payload: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
      previousPeriodId: PropTypes.number,
      originalTemplate: PropTypes.string,
      templateId: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.string,
    }),
    selection: PropTypes.shape({
      selectedGroup: PropTypes.object,
      selectedEntity: PropTypes.object,
      selectedProject: PropTypes.object,
    }),
    loading: PropTypes.bool,
  }).isRequired,
  templates: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.array,
  }).isRequired,
  user: PropTypes.shape({
    permissions: PropTypes.shape({
      data: PropTypes.shape({
        isSysAdmin: PropTypes.bool.isRequired,
        isGroupAdmin: PropTypes.bool.isRequired,
        projectsCanAddPeriod: PropTypes.arrayOf(PropTypes.number).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  externalReviewExportedFiles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  externalReviewLoading: PropTypes.bool.isRequired,
  isCreatePeriodDisabled: PropTypes.bool.isRequired,
  templateRules: PropTypes.arrayOf(PropTypes.shape({
    keywordRule: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool.isRequired,
  })).isRequired,
};

function mapStateToProps(state) {
  return {
    loading: state.projects.templates.loading,
    user: state.user,
    externalReviewExportedFiles: externalReviewExportedFilesSelector(state),
    externalReviewLoading: externalReviewLoadingSelector(state),
    templateRules: state.projects.createPeriod.selectedTemplateRuleList,
    isCreatePeriodDisabled: state.projects.createPeriod.isCreatePeriodDisabled,
  };
}

export default compose(withStyles(styles), withTheme(), connect(mapStateToProps))(CreatePeriodForm);
