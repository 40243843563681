import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, IconButton, Card, Grid, Collapse } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import CollapsedArrow from 'Assets/Images/chevron-right-blue-ic.svg';
import ExpandedArrow from 'Assets/Images/chevron-down-blue-ic.svg';
import Button, { constants as buttonConstants } from 'Components/Shared/Buttons/Button';
import { strings } from 'Constants/ModelManagement/Strings';
import { activateVersion, addModel, editModel, copyModels } from 'Store/Areas/MachineLearning/MachineLearningActions';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import { styles } from './Version.styles';

class Version extends React.PureComponent {
  onExpand = () => {
    const { onExpand, version } = this.props;
    onExpand(version.id);
  }

  onActivateVersion = () => {
    const { version, dispatch } = this.props;
    dispatch(activateVersion(version.id, version.name));
  }

  onAddModel = () => {
    const { version, dispatch } = this.props;
    dispatch(addModel(version));
    dispatch(push(routes.createModel));
  }

  onCopyModels = () => {
    const { version, dispatch } = this.props;
    dispatch(copyModels(version.id));
  }

  onEditModel = (model) => {
    const { version, dispatch } = this.props;
    dispatch(editModel(model, version));
    dispatch(push(routes.createModel));
  }

  renderModel(model, classes, version) {
    return (
      <Card className={classes.modelContainer} key={model.id}>
        <Grid container>
          <Grid item xs={version.isActive ? 12 : 11}>
            <div className={classes.modelTitle}>{model.displayName}</div>
            <p className={classes.taxContext}>{model.taxContext.name}</p>
            <p className={classes.description}>{model.description}</p>
          </Grid>
          {
            !version.isActive &&
            <Grid item xs={1} className={classes.verticalFlex}>
              <IconButton
                className={classes.editModelButton}
                onClick={() => this.onEditModel(model)}
              >
                <img src={CollapsedArrow} alt="" />
              </IconButton>
            </Grid>
          }
        </Grid>
      </Card>
    );
  }

  renderInactiveVersionButtons(classes) {
    return (
      <Fragment>
        <Button
          onClick={this.onActivateVersion}
          className={classes.modelButton}
          height={buttonConstants.height.regular}
          backgroundColor={buttonConstants.backgroundColor.dark}
        >
          {strings.activateVersion}
        </Button>
        <Button
          onClick={this.onAddModel}
          className={classes.modelButton}
          height={buttonConstants.height.regular}
        >
          {strings.addModel}
        </Button>
        <Button
          onClick={this.onCopyModels}
          className={classes.modelButton}
          height={buttonConstants.height.regular}
        >
          {strings.copyModels}
        </Button>
      </Fragment>
    );
  }

  render() {
    const {
      version,
      expanded,
      classes,
    } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.flexContainer}>
          <div className={classes.title}>{version.name}</div>
          <IconButton className={classes.expandVersionButton} onClick={this.onExpand}>
            <img src={expanded ? ExpandedArrow : CollapsedArrow} alt="" />
          </IconButton>
          {version.isActive && <div className={classes.description}>{strings.activeVersion}</div>}
        </div>
        <Collapse in={expanded}>
          {version.isActive &&
            <div className={classes.description}>{strings.activeVersionText}</div>
          }
          {version.models.map(m => this.renderModel(m, classes, version))}
          {!version.isActive && version.models && !version.models.length &&
            <Card className={classes.newVersionContainer}>
              <div className={classes.newModelDescription}>
                <p>{strings.newVersionText1}</p>
                <p style={{ margin: 0 }}>{strings.newVersionText2}</p>
              </div>
              {this.renderInactiveVersionButtons(classes)}
            </Card>
          }
          {!version.isActive && version.models.length > 0 &&
            <div className={classes.flexContainer}>
              {this.renderInactiveVersionButtons(classes)}
            </div>
          }
        </Collapse>
      </div>
    );
  }
}

Version.propTypes = {
  version: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    models: PropTypes.arrayOf(PropTypes.shape({
      displayName: PropTypes.string,
      categories: PropTypes.string,
      description: PropTypes.string,
      taxContext: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })),
  }).isRequired,
  onExpand: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,

};

export default compose(withStyles(styles), connect())(Version);
