const styles = theme => ({
  back: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.small,
    background: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: 0,
    paddingRight: 0,
  },
  chevronLeft: {
    height: 8,
    marginRight: 3,
  },
  chevronRight: {
    height: 8,
    marginLeft: 3,
    transform: 'rotate(180deg)',
  },
});

export default styles;
