import { takeLatest, put } from 'redux-saga/effects';
import { REQUEST_EDIT_RULE_NOTE, requestEditRuleNoteSuccess } from 'Store/Areas/Rule/EditRuleNoteActions';
import { Post } from 'Store/Api/CallApi';
import { RULE_REQUEST_EDIT_RULE_NOTE } from 'Store/Api/ApiEndpoints';
import handleStatusCode from 'Store/Api/HandleStatusCode';

function* requestEditRuleNoteSaga(action) {
  const response = yield Post(RULE_REQUEST_EDIT_RULE_NOTE, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(requestEditRuleNoteSuccess(data, action.index));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* editRuleNoteWatcher() {
  yield takeLatest(REQUEST_EDIT_RULE_NOTE, requestEditRuleNoteSaga);
}
