export const styles = theme => ({
  root: {
    height: '75vh',
    padding: '20px 30px 30px 30px',
    margin: '15px 30px 0 20px',
    display: 'flex',
    flexDirection: 'column',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  dropzoneContainer: {
    overflowX: 'auto',
    overflowY: 'hidden',
    flexDirection: 'row',
    display: 'flex',
    paddingBottom: 6,
    paddingTop: 10,
  },
  flex: {
    flexGrow: 1,
  },
  header: {
    marginTop: 10,
    lineHeight: '24px',
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  description: {
    fontSize: theme.constants.fontSizes.regular,
    marginRight: 50,
    lineHeight: '22px',
  },
  proceedButton: {
    width: 350,
  },
  titleRow: {
    alignItems: 'center',
    color: theme.palette.colors.black,
    display: 'flex',
    height: 14,
    marginBottom: 12,
  },
  title: {
    fontWeight: 'bold',
    height: 14,
    width: 63,
    flexGrow: '1',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 10,
  },
  tagContainer: {
    flexDirection: 'row',
    display: 'flex',
    paddingBottom: 6,
    paddingTop: 10,
  },
  supportingDocTag: {
    marginRight: 80,
  },
  manuallyMapLineItemsContainer: {
    marginBottom: 30,
  },
  arrowButton: {
    flex: 1,
    width: '100%',
    display: 'flex',
  },
  disabled: {
    opacity: 0.6,
  },
});

