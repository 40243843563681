import React from 'react';
import { withStyles, withTheme, Paper, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Close from 'Assets/Images/exit-ic.svg';
import CheckboxIcon from 'Assets/Images/small-check-ic.svg';
import redCross from 'Assets/Images/exit-ic-red.svg';
import Button from 'Components/Shared/Buttons/Button';
import { commonStrings } from 'Constants/CommonStrings';
import withApportionableItems from 'Components/Apportionables/ApportionableItemsProvider';
import PropTypes from 'prop-types';
import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';
import { title } from 'Constants/App/Titles/Strings';
import DataGrid from 'Components/Shared/DataGrid/DataGrid';
import SimpleHeader from 'Components/Shared/DataGrid/Cells/SimpleHeader/SimpleHeader';
import BorderedRow from 'Components/Shared/DataGrid/Rows/BorderedRow';
import SortableHeader from 'Components/Shared/DataGrid/Cells/SortableHeader/SortableHeader';
import SimpleCell from 'Components/Shared/DataGrid/Cells/SimpleCell/SimpleCell';
import DragnDrop from 'Constants/DragnDrop';
import { isReadOnlySelector, periodSelector } from 'Store/Areas/Period/PeriodSelectors';
import { onPeriodReloaded } from 'Components/Shared/PeriodProviders/onPeriodReloaded';
import { styles } from 'Components/Apportionables/ApportionableLineItems.styles';
import Search from 'Components/Shared/Inputs/Search';
import { strings } from 'Constants/Categorization/Strings';
import { getApportionableCategoryRules } from 'Store/Areas/Rule/CategoryRulesActions';
import { inspectRule } from 'Store/Areas/Rule/InspectRuleActions';
import InspectRuleOverlay from 'Components/CategoryReview/InspectRuleOverlay';
import { apportionmentTypes } from './ApportionablesStrings';

class ApportionableLineItems extends React.PureComponent {
  componentDidMount() {
    this.props.setOptions({
      categorizationType: this.props.categorizationType,
    });
    document.title = title.lineItems(this.props.categorizationType);
    this.loadRules();
  }

  loadRules() {
    const {
      dispatch,
      periodId,
      category,
    } = this.props;
    dispatch(getApportionableCategoryRules(
      periodId,
      category.id,
      1,
    ));
  }

  getCellValue = ({ rowIndex, columnIndex }) => {
    const {
      classes,
      lineItems,
      lineItemsColumnHeaders,
      sortedColIndex,
      categoryRules,
    } = this.props;

    const cell = lineItems[rowIndex].tagAndValues.find(f => f.tagName ===
      lineItemsColumnHeaders[columnIndex]);

    if (columnIndex === 0 && lineItems[rowIndex].apportionmentDefinitionId > 0) {
      return (
        <Button
          className={classes.imgButton}
          disabled
          disableRipple
        >
          <img alt="" src={lineItems[rowIndex].apportionableErrorMessage ? redCross : CheckboxIcon} />
        </Button>
      );
    }
    if (columnIndex === 1) {
      const conditionText = lineItems[rowIndex].apportionmentDefinitionText !== undefined ?
        lineItems[rowIndex].apportionmentDefinitionText : '';
      if (sortedColIndex === 1) {
        return (
          <div className={this.showGreyBackground(lineItems[rowIndex])}>
            {cell !== undefined ? cell.tagValue : conditionText}
          </div>);
      }
      return cell !== undefined ? cell.tagValue : conditionText;
    }
    if (columnIndex === 2 && lineItems[rowIndex].priority >= 0) {
      const priorityText = lineItems[rowIndex].priority === 0 ? '' : lineItems[rowIndex].priority;
      return cell !== undefined ? cell.tagValue : priorityText;
    }

    if (columnIndex === 3) {
      const methodText = (() => {
        switch (lineItems[rowIndex].apportionableType) {
          case 1: return apportionmentTypes[1];
          case 3: return apportionmentTypes[3];
          default: return lineItems[rowIndex].apportionableRuleText;
        }
      })();
      const rule = categoryRules.rules.find(x => x.keywordRule === methodText);
      if (sortedColIndex === 3) {
        if (rule !== undefined) {
          return (
            <Button
              onClick={() => this.showInspectRuleOverlay(rule)}
              className={this.showGreyBackgroundMethodColumn(lineItems[rowIndex])}
              disableRipple
            >
              {cell !== undefined ? cell.tagValue : methodText}
            </Button>
          );
        }
        return cell !== undefined ? cell.tagValue : methodText;
      }
      if (rule !== undefined) {
        return (
          <Button
            onClick={() => this.showInspectRuleOverlay(rule)}
            className={classes.imgButton}
            disableRipple
          >
            {cell !== undefined ? cell.tagValue : methodText}
          </Button>
        );
      }
      return cell !== undefined ? cell.tagValue : methodText;
    }
    return cell !== undefined ? cell.tagValue : '';
  }

  showGreyBackground = (lineItem) => {
    return lineItem.sortingHelper % 2 !== 0 ? this.props.classes.greyBgRule : {};
  }

  showGreyBackgroundMethodColumn = (lineItem) => {
    return lineItem.sortingHelper % 2 !== 0 ?
      this.props.classes.greyBgRuleMethodColumn :
      this.props.classes.imgButton;
  }

  getWidth = ({ index }) => {
    const {
      amountColumnIndex,
    } = this.props;
    if (index === 0 || index === 2) {
      return 100;
    }
    return index === amountColumnIndex ? 200 : 300;
  }

  getDragProps = (props) => {
    const {
      lineItems,
      selectedRowIds,
      selectedRows,
      selectedRowsTotalAmount,
    } = this.props;

    return {
      index: props.index,
      lineItemId: lineItems[props.index].lineItemId,
      lineItemIds: selectedRowIds,
      selectedRows,
      selectedRowsTotalAmount,
    };
  }

  showInspectRuleOverlay=(rule) => {
    const { dispatch } = this.props;
    dispatch(inspectRule(rule));
  }

  renderStickyCell(categorizationType, lineItems, classes, theme) {
    if (categorizationType === categorizationTypes.allowedConflicts) {
      return {
        renderStickyCell: props => (
          <div {...props}>
            <IconButton
              className={classes.removeIcon}
            >
              <img src={Close} alt="remove" style={{ cursor: 'pointer' }} />
            </IconButton>
          </div>
        ),
        stickyColumnWidth: 40,
        stickyBackgroundColor: theme.palette.primary.background,
      };
    }

    return null;
  }

  getTooltip = (lineItem) => {
    if (lineItem.apportionableErrorMessage) {
      return lineItem.apportionableErrorMessage;
    }
    return '';
  }

  render() {
    const {
      classes,
      currencySymbol,
      lineItems,
      lineItemsColumnHeaders,
      lineItemsHasMore,
      lineItemsLoadMore,
      lineItemsLoading,
      amountColumnIndex,
      descriptionColumnIndex,
      isReadOnly,
      categorizationType,
      theme,
      onSort,
      sortOrder,
      sortedColIndex,
      selectedRowIds,
      onBeginDrag,
      onEndDrag,
      onRowSelectionChange,
      setSearchTerm,
      onSearch,
      resetSearch,
      searchTerm,

    } = this.props;
    return (
      <div className={classes.root}>
        <InspectRuleOverlay currencySymbol={currencySymbol} />
        <Paper className={classes.contentHeader}>
          <Search
            className={classes.searchBox}
            id={strings.searchKeywords}
            label={strings.searchKeywords}
            placeholder={strings.searchPlaceholder}
            onChange={value => setSearchTerm(value)}
            onReset={() => resetSearch()}
            onSearch={() => onSearch()}
            value={searchTerm}
          />
        </Paper>
        <Paper className={classes.content}>
          <DataGrid
            recomputeGridSizeKey={`${amountColumnIndex}_${descriptionColumnIndex}`}
            className={classes.grid}
            columns={lineItemsColumnHeaders}
            rows={lineItems}
            getCellValue={this.getCellValue}
            hasNextPage={lineItemsHasMore}
            isNextPageLoading={lineItemsLoading}
            loadNextPage={lineItemsLoadMore}
            getWidth={this.getWidth}
            rowMargin={10}
            childRowHeight={30}
            parentRowHeight={30}
            sortedByKey={`${sortOrder}_${sortedColIndex}`}
            showDefaultNoDataMessage
            noDataMessage={commonStrings.noApportionableLineItems}
            renderHeaderCell={props => (
              <Choose>
                <When condition={props.index === 0}>
                  <SimpleHeader {...props} />
                </When>
                <Otherwise>
                  <SortableHeader
                    sortOrder={sortOrder}
                    isSorting={sortedColIndex === props.index}
                    onClick={onSort}
                    {...props}
                  />
                </Otherwise>
              </Choose>
            )}
            renderRow={props => (
              <BorderedRow
                {...props}
                canDrag={!isReadOnly}
                tooltip={this.getTooltip(lineItems[props.index])}
                dragType={DragnDrop.lineItem.uncategorised}
                onGetDragProps={() => this.getDragProps(props)}
                onBeginDrag={onBeginDrag}
                onEndDrag={onEndDrag}
                onSelectionChange={onRowSelectionChange}
                isSelected={selectedRowIds.includes(lineItems[props.index].lineItemId)}
              />
            )}
            renderCell={props => (
              <SimpleCell
                {...props}
                currencySymbol={currencySymbol}
                padLeft={props.index === 0}
                padRight={props.index === lineItemsColumnHeaders.length - 1}
                padding={10}
                formatAsCurrency={props.index === amountColumnIndex}
              />
            )}
            {...this.renderStickyCell(categorizationType, lineItems, classes, theme)}
          />
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    periodId: state.periods.period.periodId,
    isReadOnly: isReadOnlySelector(state),
    period: periodSelector(state),
    categoryRules: state.rules.categoryRules,
    category: state.categorisation.reviewCategory.category,
  };
}

ApportionableLineItems.defaultProps = {
  categorizationType: categorizationTypes.uncategorized,
};

ApportionableLineItems.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  lineItemsColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineItemsColumnHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineItemsHasMore: PropTypes.bool.isRequired,
  lineItemsLoadMore: PropTypes.func.isRequired,
  lineItemsLoading: PropTypes.bool.isRequired,
  amountColumnIndex: PropTypes.number.isRequired,
  descriptionColumnIndex: PropTypes.number.isRequired,
  categorizationType: PropTypes.number,
  isReadOnly: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  sortedColIndex: PropTypes.number.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRowsTotalAmount: PropTypes.number.isRequired,
  selectedRowIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onBeginDrag: PropTypes.func.isRequired,
  onEndDrag: PropTypes.func.isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        background: PropTypes.string,
      }),
    }),
  }).isRequired,
  period: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    data: PropTypes.object,
  }).isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  categoryRules: PropTypes.shape({
    name: PropTypes.string,
    rules: PropTypes.arrayOf(PropTypes.shape({
      keywordRule: PropTypes.string,
      addedBy: PropTypes.string,
      dateCreated: PropTypes.string,
      value: PropTypes.number,
      priority: PropTypes.number,
      notes: PropTypes.string,
    })),
    morePagesAvailable: PropTypes.bool,
    loading: PropTypes.bool,
    totalSum: PropTypes.number,
    currentPageNumber: PropTypes.number,
  }).isRequired,
  category: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default compose(
  withApportionableItems(),
  withStyles(styles),
  connect(mapStateToProps),
  withTheme(),
  onPeriodReloaded(props => props.setOptions({})), // triggers line items refresh
)(ApportionableLineItems);
