import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withLineItems, {
  sortFields,
} from 'Components/Shared/LineItemsProvider/LineItemsProvider';
import { withStyles, Grid } from '@material-ui/core';
import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';
import { formatCurrency } from 'Helpers/NumberHelpers';
import { formatColumnByType } from 'Constants/ValidationHelpers';
import { getTooltip } from 'Helpers/DataGridHelpers';
import { commonStrings } from 'Constants/CommonStrings';
import { strings } from 'Constants/MachineLearning/Strings';
import { strings as searchStrings } from 'Constants/Categorization/Strings';
import PropTypes from 'prop-types';
import BorderedRow from 'Components/Shared/DataGrid/Rows/BorderedRow';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import DataGrid from 'Components/Shared/DataGrid/DataGrid';
import Search from 'Components/Shared/Inputs/Search';
import SimpleHeader from 'Components/Shared/DataGrid/Cells/SimpleHeader/SimpleHeader';
import KeywordHighlighter from 'Components/MachineLearning/KeywordHighlighter';
import { sortOrder } from 'Constants/Categorization/QueryTermFilters';
import DragnDrop from 'Constants/DragnDrop';
import { getCategorisationLogic } from 'Store/Areas/Import/LineItemValueActions';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import { formatCellValue } from 'Helpers/LineItemHelpers';
import { isCaseInsensitiveEqual } from 'Helpers/StringHelpers';
import styles from './SampleTable.styles';

const maxLineItems = 60;

class SampleTable extends React.PureComponent {
  componentDidMount() {
    const { confidenceThreshold, lineItemLimit } = this.props;
    this.updateLineItemOptions(lineItemLimit, confidenceThreshold);
  }

  componentDidUpdate(prevProps) {
    const { confidenceThreshold, lineItemLimit } = this.props;
    if (
      prevProps.confidenceThreshold !== confidenceThreshold ||
      prevProps.lineItemLimit !== lineItemLimit
    ) {
      this.updateLineItemOptions(lineItemLimit, confidenceThreshold);
    }
  }

  getCellValue = ({ rowIndex, columnIndex }) => {
    const {
      lineItems,
      lineItemsColumns,
      amountColumnIndex,
      descriptionColumnIndex,
      categoriesColumnIndex,
      currencySymbol,
    } = this.props;
    const cell = lineItems[rowIndex].columns[lineItemsColumns[columnIndex]];
    if (!cell) {
      return '';
    }
    if (columnIndex === 0) {
      return Math.floor(cell.value * 100);
    }
    if (columnIndex === amountColumnIndex) {
      return formatCurrency(cell.value, currencySymbol);
    }
    if (columnIndex === descriptionColumnIndex) {
      return formatColumnByType(cell, 'description');
    }
    if (columnIndex === categoriesColumnIndex) {
      return formatColumnByType(cell, 'category');
    }
    return formatCellValue(cell);
  }

  getWidth = ({ index }) => {
    const { descriptionColumnIndex } = this.props;
    return index === descriptionColumnIndex ? 300 : 150;
  }

  getCategoryColour(categoryName) {
    const { categories } = this.props;

    const category = categories
      .filter(cat => isCaseInsensitiveEqual(cat.name, categoryName))[0];

    return category ? category.color : '#ffffff';
  }

  updateLineItemOptions(lineItemLimit, confidenceThreshold) {
    const { setLineItemOptions } = this.props;

    setLineItemOptions({
      maxLineItemValue: lineItemLimit,
      minLineItemValue: -lineItemLimit,
      confidenceThreshold: confidenceThreshold / 100,
    });
  }

  runCategorisationRequest(selectedRowIds) {
    const { periodId, dispatch } = this.props;
    dispatch(getCategorisationLogic(periodId, selectedRowIds));
  }

  render() {
    const {
      lineItemsColumnHeaders,
      lineItems,
      lineItemsHasMore,
      lineItemsLoading,
      lineItemsLoadMore,
      classes,
      amountColumnIndex,
      descriptionColumnIndex,
      selectedRows,
      selectedRowsTotalAmount,
      selectedRowIds,
      onRowSelectionChange,
      setSearchTerm,
      onSearch,
      resetSearch,
      searchTerm,
      currencySymbol,
    } = this.props;
    return (
      <div className={classes.root}>
        <Grid container direction="row" alignItems="stretch">
          <Grid className={classes.gridRow} item xs={12}>
            <span className={classes.title}>
              {strings.lineItemResults}
            </span>
          </Grid>
          <Grid className={classes.gridRow} item xs={10}>
            <span className={classes.text}>
              {strings.lineItemsButtonNote}
            </span>
          </Grid>
          <Grid className={classes.gridRow} item xs={2}>
            <Button
              className={
                selectedRows.length === 0 || selectedRows.length >= maxLineItems
                  ? classes.buttonDisabled : classes.button}
              backgroundColor={constants.backgroundColor.dark}
              onClick={() => this.runCategorisationRequest(selectedRowIds)}
              disabled={(selectedRows.length === 0 || selectedRows.length >= maxLineItems)}
            >
              {strings.showCategorisationLogic}
            </Button>
          </Grid>
        </Grid>
        {
          lineItems && !!lineItems.length &&
          <Grid className={classes.gridRowSearch} item xs={12}>
            <div className={classes.flexContainer}>
              <Search
                className={classes.searchBox}
                id={searchStrings.searchKeywords}
                label={searchStrings.searchKeywords}
                placeholder={searchStrings.searchPlaceholder}
                onChange={value => setSearchTerm(value)}
                onReset={() => resetSearch()}
                onSearch={() => onSearch()}
                value={searchTerm}
              />
              {selectedRows.length >= maxLineItems &&
                <div className={classes.toolTipBoxContainer}>
                  <div className={classes.toolTipBox}>
                    <div>
                      {commonStrings.maxNumberOfLineItems}
                    </div>
                  </div>
                </div>
              }
            </div>
          </Grid>
        }
        <DataGrid
          recomputeGridSizeKey={`${amountColumnIndex}_${descriptionColumnIndex}`}
          columns={lineItemsColumnHeaders}
          rows={lineItems}
          getCellValue={this.getCellValue}
          hasNextPage={lineItemsHasMore}
          isNextPageLoading={lineItemsLoading}
          loadNextPage={lineItemsLoadMore}
          getWidth={this.getWidth}
          rowMargin={10}
          childRowHeight={30}
          parentRowHeight={30}
          fixedHeight={400}
          renderHeaderCell={(props) => {
            return <SimpleHeader className={classes.header} {...props} />;
          }}
          renderRow={props => (
            <BorderedRow
              {...props}
              tooltip={getTooltip(lineItems[props.index].columns, currencySymbol)}
              dragProps={{
                lineItemId: lineItems[props.index].id,
                selectedRows: selectedRows,
                selectedRowsTotalAmount: selectedRowsTotalAmount,
              }}
              dragType={DragnDrop.lineItem.supportingDocuments}
              onSelectionChange={onRowSelectionChange}
              isSelected={selectedRowIds.includes(lineItems[props.index].id)}
            />
          )}
          renderCell={props => (
            <div
              style={{
                ...props.style,
                width: props.width
                  - (props.padLeft ? props.padding : 0) - (props.padRight ? props.padding : 0),
                paddingLeft: props.padLeft ? props.padding : 0,
                paddingRight: props.padRight ? props.padding : 0,
              }}
              key={props.index}
            >
              <If condition={props.index === 1}>
                <span
                  className={classes.categoryCircle}
                  style={{ backgroundColor: this.getCategoryColour(props.children) }}
                />
              </If>
              <Choose>
                <When condition={props.index === descriptionColumnIndex &&
                   lineItems[props.rowIndex].keyword}
                >
                  <KeywordHighlighter
                    {...props}
                    searchWords={[lineItems[props.rowIndex].keyword]}
                    textToHighlight={props.children}
                  />
                </When>
                <Otherwise>
                  {props.children}
                </Otherwise>
              </Choose>
            </div>
          )}
          noDataMessage={commonStrings.noLineItems}
        />
      </div>
    );
  }
}

SampleTable.propTypes = {
  confidenceThreshold: PropTypes.number.isRequired,
  lineItemLimit: PropTypes.number.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  lineItemsColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineItemsColumnHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineItemsHasMore: PropTypes.bool.isRequired,
  lineItemsLoadMore: PropTypes.func.isRequired,
  lineItemsLoading: PropTypes.bool.isRequired,
  setLineItemOptions: PropTypes.func.isRequired,
  amountColumnIndex: PropTypes.number.isRequired,
  descriptionColumnIndex: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  })).isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRowsTotalAmount: PropTypes.number.isRequired,
  selectedRowIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  periodId: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

function mapSortField(columnIdx) {
  return columnIdx;
}

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
  };
}

export default compose(
  withLineItems({
    categorizationType: categorizationTypes.uncategorized,
    includeMlPredictions: true,
    includeCategoryColumn: true,
    includeRuleColumn: false,
    confidenceThreshold: 0.9,
    sortOrder: sortOrder.valueAscending,
    sortField: sortFields.confidence,
    debounceDelay: 500,
    mapSortField: mapSortField,
  }),
  withStyles(styles),
  connect(mapStateToProps),
)(SampleTable);
