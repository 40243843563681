import * as d3 from 'd3';

export const DonutWidth = 650;
export const DonutHeight = 550;
export const radius = 200;
export const LabelR = radius + 25;
export const LabelRAlt = radius + 30;
export const LineR = radius + 10;
export const DonutArc = d3.arc().outerRadius(radius).innerRadius(radius - 54).padAngle(0.003);
export const DonutPie = d3.pie().sort(null).value(d => d.value);

export const DonutTitle = 'Most Popular Keywords';
export const EmptyDonut = 'No Keywords allocated to this category';

export const EmptyColor = '#D2D2D2';
