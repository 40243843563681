import React from 'react';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { resetFailure } from 'Store/Areas/Categorisation/CategoryActions';
import { referenceListsSelector } from 'Store/Areas/Categorisation/ReferenceListSelectors';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { processingSelector } from 'Store/Areas/Rule/CategorisationSelectors';
import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';
import { categoryListTypes } from 'Constants/Categorization/CategoryListTypes';
import { categorisationLocations } from 'Constants/Routes';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { strings } from 'Constants/Categorization/Strings';
import ErrorModal from 'Components/Shared/Modal/ErrorModal';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Blur from 'Components/Shared/Blur/Blur';
import Sidebar from 'Components/UserSidebar/Sidebar';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import { resetPeriodLock } from 'Store/Areas/App/ConfigActions';
import { styles } from './CategorizationManager.styles';
import CategorizationValuesBar from './Shared/CategorizationValuesBar/CategorizationValuesBar';
import CategoryCreationModal from './Modals/CategoryCreationModal/CategoryCreationModal';
import CategoryDeletionModal from './Modals/CategoryDeletionModal/CategoryDeletionModal';
import SingleKeywords from './Pages/SingleKeywords/SingleKeywords';
import CustomRules from './Pages/CustomRules/CustomRules';
import LineItems from './Pages/LineItems/LineItems';
import CombinationKeywords from './Pages/CombinationKeywords/CombinationKeywords';
import CategoryList from './Shared/CategoryList/CategoryList';
import ReferenceLists from './Pages/ReferenceLists/ReferenceLists';


class CategorizationManager extends React.PureComponent {
  state = {
    categoryType: '',
    selectedCategoryType: false,
  };

  componentDidMount() {
    this.props.dispatch(getTreePickerData());
  }

  getCategoryType(location) {
    switch (location) {
      case categorisationLocations.singleKeywords:
      case categorisationLocations.combinationKeywords:
        return categoryListTypes.categoryType.keywordRule;
      case categorisationLocations.customRules:
        return categoryListTypes.categoryType.customRule;
      case categorisationLocations.lineItems:
      case categorisationLocations.conflictingLineItems:
      case categorisationLocations.flaggedLineItems:
        return categoryListTypes.categoryType.lineItems;
      case categorisationLocations.referenceLists:
        return categoryListTypes.categoryType.referenceList;
      default:
        return categoryListTypes.categoryType.keywordRule;
    }
  }

  setCategoryType = () => {
    this.setState({
      categoryType: categoryListTypes.categoryType.customRule,
      selectedCategoryType: true,
    });
  }

  clearCategoryType = () => {
    this.setState({
      categoryType: '',
      selectedCategoryType: false,
    });
  }

  render() {
    const {
      classes,
      category,
      period,
      dispatch,
      projects,
      processing,
      referenceLists: { fileLoading: referenceListsLoading },
      currencySymbol,
    } = this.props;

    const sidebarLoading = projects.treePicker.loading;

    const location = this.props.match.params.stage;

    if (!this.state.selectedCategoryType) {
      this.setState({
        categoryType: this.getCategoryType(location),
      });
    }

    const mainContentLoading = sidebarLoading && (!period.data ||
      period.data.status === periodStatuses.periodReferenceListImportQueued ||
      period.data.status === periodStatuses.periodReferenceListImportRunning ||
      referenceListsLoading);

    const mainContentLoadingStatus = mainContentLoading && period.data ?
      strings.referenceListProcessing : null;

    window.addEventListener('beforeunload', () => {
      this.props.dispatch(resetPeriodLock());
    });

    return (
      <UserSidebarStructure
        isCollapsible
        collapsed
        sidebarLoading={sidebarLoading}
        mainContentLoading={mainContentLoading}
        mainContentLoadingStatus={mainContentLoadingStatus}
        sidebarContent={
          <Sidebar />
        }
        mainContent={
          <Blur blur={processing} lightBackground displayLoader>
            <div className={classes.root}>
              <CategorizationValuesBar
                currencySymbol={currencySymbol}
                className={classes.valueBar}
              />
              <If condition={period.data && period.data.categories}>
                <div className={classes.grid}>
                  <div className={classes.categories}>
                    <div className={classes.categoriesListWrapper}>
                      <CategoryList
                        currencySymbol={currencySymbol}
                        categoryType={this.state.categoryType}
                      />
                    </div>
                  </div>
                  <div className={classes.keywords}>
                    <If condition={location === categorisationLocations.referenceLists}>
                      <ReferenceLists />
                    </If>
                    <If condition={location === categorisationLocations.singleKeywords}>
                      <SingleKeywords currencySymbol={currencySymbol} />
                    </If>
                    <If condition={location === categorisationLocations.combinationKeywords}>
                      <CombinationKeywords currencySymbol={currencySymbol} />
                    </If>
                    <If condition={location === categorisationLocations.customRules}>
                      <CustomRules />
                    </If>
                    <If condition={location === categorisationLocations.lineItems}>
                      <LineItems
                        currencySymbol={currencySymbol}
                        setCategoryType={this.setCategoryType}
                        clearCategoryType={this.clearCategoryType}
                        includeContraFlagColumn
                        includeFlaggedColumn
                        showOnlyFlaggedLineItems={false}
                      />
                    </If>
                    <If condition={location === categorisationLocations.conflictingLineItems}>
                      <LineItems
                        currencySymbol={currencySymbol}
                        categorizationType={categorizationTypes.allowedConflicts}
                        includeContraFlagColumn={false}
                        includeFlaggedColumn={false}
                      />
                    </If>
                    <If condition={location === categorisationLocations.flaggedLineItems}>
                      <LineItems
                        currencySymbol={currencySymbol}
                        includeContraFlagColumn
                        includeFlaggedColumn
                        showOnlyFlaggedLineItems
                      />
                    </If>
                  </div>
                </div>
                <Choose>
                  <When condition={!category.success && category.errorMessage}>
                    <ErrorModal
                      open
                      onClose={() => { dispatch(resetFailure()); }}
                      error={strings.categoryErrorMessage(category.errorMessage)}
                      title={strings.categoryErrorTitle}
                    />
                  </When>
                  <Otherwise>
                    <CategoryCreationModal />
                    <CategoryDeletionModal />
                  </Otherwise>
                </Choose>
              </If>
            </div>
          </Blur>
        }
      />
    );
  }
}

CategorizationManager.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  category: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    adding: PropTypes.bool,
    errorMessage: PropTypes.string,
  }).isRequired,
  period: PropTypes.shape({
    data: PropTypes.shape({
      categories: PropTypes.array,
      status: PropTypes.string,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  processing: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      stage: PropTypes.string,
    }),
  }).isRequired,
  referenceLists: PropTypes.shape({
    fileLoading: PropTypes.bool.isRequired,
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    category: state.categorisation.category,
    period: state.periods.period,
    periodConflicts: state.periods.periodConflicts,
    processing: processingSelector(state),
    referenceLists: referenceListsSelector(state),
    currencySymbol: currencySymbolSelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(CategorizationManager);
