import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bulkGroupEntityProjectUploadProcessingStages, bulkGroupEntityProjectUploadImportStages } from 'Constants/BulkUpload/BulkGroupEntityProjectUploadStages';
import { bulkGroupEntityProjectUploadMessageArray } from 'Constants/BulkUpload/Strings';
import ProgressBar from 'Components/Processing/ProgressBar/ProgressBar';
import ProcessingStatus from 'Components/Shared/ProcessingStatus/ProcessingStatus';
import BulkGroupEntityProjectUploadResult from './BulkGroupEntityProjectUploadResult';
import FailedBulkGroupEntityProjectUploadScanModal from './FailedBulkGroupEntityProjectUploadScanModal';

class BulkGroupEntityProjectUploadProcessingContainer extends React.PureComponent {
  render() {
    const {
      currentStage,
    } = this.props;

    return (
      <Fragment>
        <Choose>
          <When condition={
            currentStage === bulkGroupEntityProjectUploadImportStages.uploading ||
            currentStage === bulkGroupEntityProjectUploadImportStages.passedScan}
          >
            <ProcessingStatus
              processingMessageArray={bulkGroupEntityProjectUploadMessageArray}
              renderProgressBar={() => (<ProgressBar
                stage={currentStage}
                processingStages={bulkGroupEntityProjectUploadProcessingStages}
              />)}
            />
          </When>
          <When condition={currentStage === bulkGroupEntityProjectUploadImportStages.failedScan}>
            <FailedBulkGroupEntityProjectUploadScanModal />
          </When>
          <Otherwise>
            <BulkGroupEntityProjectUploadResult />
          </Otherwise>
        </Choose>
      </Fragment>
    );
  }
}

BulkGroupEntityProjectUploadProcessingContainer.propTypes = {
  currentStage: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    currentStage: state.bulkGroupEntityProjectUpload.stage,
  };
}

export default connect(mapStateToProps)(BulkGroupEntityProjectUploadProcessingContainer);
