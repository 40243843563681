import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, LinearProgress } from '@material-ui/core';
import { compose } from 'recompose';
import styles from './ProgressBarSegment.styles';

class ProgressBarSegment extends React.PureComponent {
  getNoduleClasses() {
    const { isFinished, classes } = this.props;
    let otherClass;
    if (isFinished) {
      otherClass = classes.blue;
    } else {
      otherClass = classes.transparent;
    }
    return `${classes.nodule} ${otherClass}`;
  }

  getProgressBarClasses() {
    const {
      isStarted,
      isFirst,
      isFinal,
      classes,
    } = this.props;
    const progressBarClasses = [classes.progressBar];
    if (isFinal) {
      progressBarClasses.push(classes.final);
    }
    if (isFirst) {
      progressBarClasses.push(classes.first);
    }
    if (!isStarted) {
      progressBarClasses.push(classes.transparent);
    }
    return progressBarClasses.join(' ');
  }

  render() {
    const {
      isFinal,
      isStarted,
      isFinished,
      classes,
      name,
    } = this.props;
    const noduleClasses = this.getNoduleClasses();

    const inProgress = isStarted && !isFinished;

    const progressBarClasses = this.getProgressBarClasses();

    return (
      <React.Fragment>
        <Choose>
          <When condition={inProgress}>
            <LinearProgress className={progressBarClasses} />
          </When>
          <Otherwise>
            <LinearProgress className={progressBarClasses} variant="determinate" value={isFinished ? 100 : 0} />
          </Otherwise>
        </Choose>
        <If condition={!isFinal}>
          <div className={noduleClasses}>
            <div className={classes.name}>
              <span>{name}</span>
            </div>
          </div>
        </If>
      </React.Fragment>
    );
  }
}

ProgressBarSegment.defaultProps = {
  isFinal: false,
  isStarted: false,
  isFinished: false,
};

ProgressBarSegment.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  isFinal: PropTypes.bool,
  isFirst: PropTypes.bool.isRequired,
  isFinished: PropTypes.bool,
  isStarted: PropTypes.bool,
};

export default compose(withStyles(styles), withTheme())(ProgressBarSegment);
