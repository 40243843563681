import Yup from 'yup';
import { allowedCharacters, beginsOrEndsInSpaces, caseInsensitiveDuplicateTestOptions } from '../../ValidationHelpers';

export function getCreateGroupSchema(groupNames) {
  return (
    Yup.object().shape({
      name: Yup.string()
        .required('Group is required.')
        .test(caseInsensitiveDuplicateTestOptions(
          groupNames,
          'Group names must be unique (irrespective of capitalisation).',
        ))
        .notOneOf(groupNames, 'Group names must be unique.')
        .max(50, 'Group names must be less than 50 characters long.')
        .matches(allowedCharacters, 'Group name contains forbidden characters.')
        .matches(beginsOrEndsInSpaces, 'Group name must not begin or end with spaces.'),
      licenseType: Yup.number().min(1, 'License type is required.'),
      engagingEntityName: Yup.string().required('Engaging Entity Name is required.').typeError('Engaging Entity Name is required.'),
      opportunityNumber: Yup.string().required('Opportunity Number is required.').typeError('Opportunity Number is required.'),
      engagementLead: Yup.string().required('Engagement Lead is required.').typeError('Engagement Lead is required.'),
      engagementManager: Yup.string().required('Engagement Manager is required.').typeError('Engagement Manager is required.'),
      activeClient: Yup.number().min(1, 'Active Client is required.'),
    })
  );
}
