import { takeLatest, put } from 'redux-saga/effects';
import {
  UPDATE_PERIOD_STATUS,
  UNLOCK_PERIOD,
} from 'Store/Areas/Projects/ChangePeriodStatusActions';
import { TOGGLE_PERIOD_LOCK_URL, PERIOD_UPDATE_STATUS } from 'Store/Api/ApiEndpoints';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { Post } from 'Store/Api/CallApi';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { strings } from 'Constants/Projects/CreatePeriod/Strings';

function* updatePeriodStatus(action) {
  const response = yield Post(PERIOD_UPDATE_STATUS, action.payload);
  if (response.ok) {
    const { periodName } = action.periodPayload;
    yield put(getTreePickerData());
    yield put(displayToastNotification(strings.periodStatusChangeNotificationText(periodName)));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* unlockPeriod(action) {
  const response = yield Post(
    TOGGLE_PERIOD_LOCK_URL(true, action.payload.isLocking),
    action.payload,
  );
  if (response.ok) {
    const { periodName } = action.periodPayload;
    yield put(getTreePickerData());
    yield put(displayToastNotification(strings.toggleLockPeriodNotificationText(
      periodName,
      action.payload.isLocking,
    )));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* changePeriodStatusSagas() {
  yield takeLatest(UPDATE_PERIOD_STATUS, updatePeriodStatus);
  yield takeLatest(UNLOCK_PERIOD, unlockPeriod);
}