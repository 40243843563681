import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { styles } from './DragRow.styles';

class DragRow extends React.PureComponent {
  render() {
    const {
      condition,
      tag,
      operator,
      join,
      classes,
      width,
    } = this.props;
    return (
      <div className={classes.root} style={{ width: width }}>
        <div className={`${classes.cell} ${classes.borderLeftRadius}`} style={{ width: width / 4 }}>{tag}</div>
        <div className={classes.cell} style={{ width: width / 4 }}>{operator}</div>
        <div className={classes.cell} style={{ width: width / 4 }}>{condition}</div>
        <div className={`${classes.cell} ${classes.borderRightRadius}`} style={{ width: width / 4 }}>{join}</div>
      </div>
    );
  }
}

DragRow.propTypes = {
  condition: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  join: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  width: PropTypes.number.isRequired,
};

export default withStyles(styles)(DragRow);
