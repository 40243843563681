export const GET_TEMPLATES_DATA = 'TEMPLATES/GET_TEMPLATES_DATA';
export const GET_TEMPLATES_DATA_SUCCESS = 'TEMPLATES/GET_TEMPLATES_DATA_SUCCESS';
export const GET_TEMPLATES_FOR_CURRENT_USER = 'TEMPLATES/GET_TEMPLATES_FOR_CURRENT_USER';
export const GET_TEMPLATES_FOR_CURRENT_USER_SUCCESS = 'TEMPLATES/GET_TEMPLATES_FOR_CURRENT_USER_SUCCESS';
export const DELETE_TEMPLATE = 'TEMPLATES/DELETE_TEMPLATE';
export const DELETE_TEMPLATE_SUCCESS = 'TEMPLATES/DELETE_TEMPLATE_SUCCESS';
export const RESET_TEMPLATES_DATA = 'TEMPLATES/RESET_TEMPLATES_DATA';
export const DOWNLOAD_TEMPLATE = 'TEMPLATES/DOWNLOAD_TEMPLATE';

export function getTemplatesData(contextId, groupId) {
  return {
    type: GET_TEMPLATES_DATA,
    data: [],
    loading: true,
    payload: {
      taxContextId: contextId,
      groupId: groupId,
    },
  };
}

export function getTemplatesDataSuccess(data) {
  return {
    type: GET_TEMPLATES_DATA_SUCCESS,
    data: data,
    loading: false,
  };
}

export function resetTemplatesData(loading = true) {
  return {
    type: RESET_TEMPLATES_DATA,
    loading,
  };
}

export function getTemplatesForCurrentUser() {
  return {
    type: GET_TEMPLATES_FOR_CURRENT_USER,
    data: [],
    loading: true,
  };
}

export function getTemplatesForCurrentUserSuccess(data) {
  return {
    type: GET_TEMPLATES_FOR_CURRENT_USER_SUCCESS,
    data: data,
    loading: false,
  };
}
export function deleteTemplate(templateId) {
  return {
    type: DELETE_TEMPLATE,
    templateId,
    loading: true,
  };
}
export function deleteTemplateSuccess() {
  return {
    type: DELETE_TEMPLATE_SUCCESS,
    loading: false,
  };
}
export function downloadTemplate(templateId) {
  return {
    type: DOWNLOAD_TEMPLATE,
    payload: {
      templateId,
    },
  };
}
