import Yup from 'yup';
import { allowedCharacters, beginsOrEndsInSpaces, caseInsensitiveDuplicateTestOptions } from '../ValidationHelpers';

export function getCreateCategorySchema(categoryNames) {
  return (
    Yup.object().shape({
      name: Yup.string()
        .required('Category name is required.')
        .test(caseInsensitiveDuplicateTestOptions(
          categoryNames,
          'Category names must be unique (irrespective of capitalisation).',
        ))
        .notOneOf(categoryNames, 'Category names must be unique.')
        .max(100, 'Category names must be less than 100 characters long.')
        .matches(allowedCharacters, 'Category name contains forbidden characters.')
        .matches(beginsOrEndsInSpaces, 'Category name must not begin or end with spaces.'),
      colorCode: Yup.string().required('Please assign a colour.'),
    })
  );
}
