export const categorisationSelector = state => state.rules.categorisation;

export const apportionmentOpenSelector = state =>
  categorisationSelector(state).displayApportionmentSelection;

export const tagSelectionOpenSelector = state =>
  categorisationSelector(state).displayTagSelection;

export const categorisationRulePropertiesSelector = state =>
  categorisationSelector(state).ruleProperties;

export const categorisationTypeSelector = state =>
  categorisationSelector(state).categoryType;

export const inspectedRuleSelector = state =>
  state.rules.inspectRule.inspectedRule;

export const contraShelfLineItemIdsSelector = state =>
  state.categorisation.lineItems.contraShelfLineItems.map(x => x.id);

export const categorizationTypeSelector = state =>
  categorisationSelector(state).categorizationType;

export const processingSelector = state =>
  state.categorisation.processing.processing;