import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { cancelDeleting, beginDeleteFile } from 'Store/Areas/Import/UploadActions';
import { commonStrings } from 'Constants/CommonStrings';
import { strings } from 'Constants/Upload/Strings';

class DeleteFileModal extends React.PureComponent {
  onCancel() {
    this.props.dispatch(cancelDeleting());
  }

  onConfirm() {
    const { dispatch, deletion, period } = this.props;
    dispatch(beginDeleteFile(
      deletion.cloudReference,
      deletion.fileName,
      period.periodId,
      period.data.changeSupportingDocsAfterPeriodCreation,
    ));
  }

  render() {
    const { deletion } = this.props;
    return (
      <ConfirmationModal
        open={deletion.isDelete}
        message={`${strings.deleteFileConfirmation} ${deletion.fileName}?`}
        title={strings.deleteFileConfirmationTitle}
        isSubmitting={deletion.loading}
        acceptText={commonStrings.delete}
        onConfirm={() => this.onConfirm()}
        onCancel={() => this.onCancel()}
      />
    );
  }
}

DeleteFileModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  deletion: PropTypes.shape({
    isDelete: PropTypes.bool,
  }),
  period: PropTypes.shape({
    periodId: PropTypes.number,
  }).isRequired,
};

DeleteFileModal.defaultProps = {
  deletion: {
    isDelete: false,
  },
};

function mapStateToProps(state) {
  return {
    deletion: state.import.upload.deletion,
    period: state.periods.period,
  };
}

export default connect(mapStateToProps)(DeleteFileModal);
