import { put } from 'redux-saga/effects';
import { userActions } from 'Constants/UserActions';
import { reportProcessingProgress } from 'Store/Areas/Import/ProcessActions';
import { routes } from 'Constants/Routes';
import { push } from 'connected-react-router';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import SignalrStrategyHandler from './SignalrStrategyHandler';

export const deleteSupportingDocHandler = new SignalrStrategyHandler(
  userActions.DeleteSupportingDoc,
  function* handler(payload, action) {
    if (payload.error) {
      yield this.handleError(action);
    } else {
      yield put(reportProcessingProgress(
        payload.message,
        payload.importStage,
        payload.forceClientPoll,
      ));
      if (payload.forceClientPoll) {
        yield put(getPeriod(action.selectedPeriodId, true));
        yield put(push(routes.periodImportDetails));
      }
    }
  },
);
