export const styles = theme => ({
  root: {
    width: 330,
  },
  categoriesPanel: {
    marginBottom: 18,
  },
  category: {
    marginBottom: 5,
  },
  links: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: theme.constants.fontSizes.small,
    fontWeight: 'bold',
    marginBottom: 20,
    backgroundColor: 'transparent',
    border: 'none',
  },
});
