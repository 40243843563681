import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import { formatCurrency, formatPercentage } from 'Helpers/NumberHelpers';
import { styles } from './ContraGridCell.styles';

class ContraGridCell extends React.PureComponent {
  render() {
    const {
      classes,
      currencySymbol,
      children,
      padding,
      style,
      width,
      padLeft,
      padRight,
      formatAsCurrency,
      formatAsPercentage,
      spacing,
      className,
      childHeight,
    } = this.props;

    const actualPadLeft = padLeft ? padding : 0;
    const actualPadRight = padRight ? padding : spacing;

    return (
      <div
        style={{
          ...style,
          width: width - actualPadLeft - actualPadRight,
          paddingLeft: actualPadLeft,
          paddingRight: actualPadRight,
        }}
        className={className}
      >
        {children.sort((c1, c2) => c2 - c1).map((x, i) => {
          const index = i;
          let displayValue = x;
          if (formatAsCurrency) {
            displayValue = formatCurrency(displayValue, currencySymbol);
          } else if (formatAsPercentage) {
            displayValue = formatPercentage(displayValue);
          }

          return (
            <p
              style={{ height: childHeight }}
              key={`${index}`}
              className={i > 0 ?
              classes.contraLineItemWithBorder : classes.contraLineItem}
            >
              <Choose>
                <When condition={formatAsCurrency}>
                  <span className={x > -1 ? classes.positive : classes.negative} >
                    {displayValue}
                  </span>
                </When>
                <Otherwise>
                  {displayValue}
                </Otherwise>
              </Choose>
            </p>
          );
        })}
      </div>
    );
  }
}

ContraGridCell.defaultProps = {
  padding: 10,
  spacing: 0,
  formatAsPercentage: false,
  formatAsCurrency: false,
  padRight: false,
  padLeft: false,
  className: '',
  children: [],
};

ContraGridCell.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  padding: PropTypes.number,
  spacing: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  childHeight: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  padLeft: PropTypes.bool,
  padRight: PropTypes.bool,
  formatAsCurrency: PropTypes.bool,
  formatAsPercentage: PropTypes.bool,
  className: PropTypes.string,
  currencySymbol: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(ContraGridCell);
