import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import { DragLayer } from 'react-dnd';
import { isIE } from 'Helpers/EnvironmentHelpers';
import { styles } from './GenericDragLayer.styles';

/*
Generic drag layer injects the props from the dragsource into the
draggable component via render-props pattern

e.g. if we have the following in the drag source
  const cardSource = {
    beginDrag(props) {
      return {
        text: props.rule,
      };
    },
  };

we then access the text via render props:
  <GenericDragLayer>
    {props => <DragRule rule={props.text} />}
  </GenericDragLayer>
*/

class GenericDragLayer extends React.Component {
  constructor(props) {
    super(props);

    this.lastRenderedDndProps = null;
    this.debounceStart = 0;
    this.idleIntervalId = -1;
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.isDragging) {
      if (!this.props.isDragging
        || this.debounceElapsed()) {
        const nextDndProps = this.getDndProps(nextProps);

        if (!this.areDndPropsUnchanged(nextDndProps)) {
          this.debounceStart = Date.now();
          this.lastRenderedDndProps = nextDndProps;

          if (this.idleIntervalId === -1) {
            this.idleIntervalId = window.setInterval(this.onIdleInterval, 200);
          }

          return true;
        }
      }
    } else if (this.debounceStart !== 0) {
      this.debounceStart = 0;
      this.lastRenderedDndProps = null;

      if (this.idleIntervalId !== -1) {
        window.clearInterval(this.idleIntervalId);
        this.idleIntervalId = -1;
      }

      return true;
    }

    return false;
  }

  componentWillUnmount() {
    if (this.idleIntervalId !== -1) {
      window.clearInterval(this.idleIntervalId);
    }
  }

  onIdleInterval = () => {
    if (this.debounceElapsed()) {
      if (!this.areDndPropsUnchanged(this.props)) {
        this.forceUpdate();
      }
    }
  }

  getDndProps({ item, itemType, currentOffset }) {
    return {
      item,
      itemType,
      currentOffset,
    };
  }

  getItemStyles({ x, y }) {
    return {
      left: x,
      top: y,
    };
  }

  debounceElapsed() {
    return !this.debounceStart || (Date.now() - this.debounceStart) > 800;
  }

  areDndPropsUnchanged(propsOther) {
    if (!this.lastRenderedDndProps
      || !this.lastRenderedDndProps.currentOffset
      || !propsOther
      || !propsOther.currentOffset) {
      return false;
    }

    return this.lastRenderedDndProps.item === propsOther.item
      && this.lastRenderedDndProps.itemType === propsOther.itemType
      && this.lastRenderedDndProps.currentOffset.x === propsOther.currentOffset.x
      && this.lastRenderedDndProps.currentOffset.y === propsOther.currentOffset.y;
  }

  render() {
    const {
      classes,
      isDragging,
      children,
      item,
      currentOffset,
      itemType,
      disableIE,
    } = this.props;

    if ((isIE && disableIE) || !isDragging || !currentOffset) {
      return null;
    }

    return (
      <div
        className={classes.draggableContainer}
        style={this.getItemStyles(currentOffset)}
      >
        {children(item, itemType)}
      </div>
    );
  }
}

GenericDragLayer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.func.isRequired,
  currentOffset: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }),
  isDragging: PropTypes.bool.isRequired,
  item: PropTypes.object, //eslint-disable-line
  itemType: PropTypes.string,
  disableIE: PropTypes.bool,
};

GenericDragLayer.defaultProps = {
  currentOffset: { x: 0, y: 0 },
  itemType: null,
  disableIE: true,
};

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  };
}

export default compose(
  DragLayer(collect),
  withStyles(styles),
  withTheme(),
)(GenericDragLayer);
