const styles = theme => ({
  paperRoot: {
    display: 'flow-root',
    flexDirection: 'column',
    flex: '1 1 auto',
    minHeight: 0,
    overflowX: 'hidden',
  },
  paper: {
    backgroundColor: theme.palette.primary.messageBackground,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    minHeight: 0,
    paddingTop: 20,
    width: 400,
    margin: '0 20px 0 20px',
    overflowX: 'hidden',
  },
  header: {
    margin: '10px 10px 10px 10px',
    fontSize: theme.constants.fontSizes.regular,
  },
  note: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    color: theme.palette.primary.error,
  },
  keywordRule: {
    fontSize: theme.constants.fontSizes.small,
  },
  definition: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: theme.constants.fontSizes.small,
  },
  checkbox: {
    padding: 0,
  },
  buttons: {
    height: 60,
    paddingTop: 30,
  },
  card: {
    paddingTop: 0,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  input: {
    background: 'transparent',
    fontSize: theme.constants.fontSizes.small,
    width: 'Calc(100% - 10px)',
    minHeight: 40,
    maxHeight: 70,
    padding: '1px 2px',
    borderStyle: 'solid',
    borderColor: '#c8c8c8',
    borderWidth: 1,
    '& > div': {
      overflowY: 'auto',
      display: 'block',
      minHeight: 0,
      marginBottom: 0,
      '& input': {
        margin: 0,
        padding: 0,
      },
    },
  },
  customChip: {
    backgroundColor: '#E6EBF3',
    width: 'auto',
  },
  scroll: {
    width: '100%',
    marginTop: 20,
  },
  priorityTable: {
    width: '100%',
  },
  priorityNote: {
    fontSize: theme.constants.fontSizes.regular,
    paddingLeft: 350,
  },
  amountInput: {
    minHeight: 40,
    maxHeight: 70,
    width: 'Calc(100% - 10px)',
    borderRadius: 4,
    background: 'transparent',
    fontSize: theme.constants.fontSizes.small,
    padding: '1px 2px',
    borderStyle: 'solid',
    borderColor: '#c8c8c8',
    borderWidth: 1,
    '& > div': {
      overflowY: 'auto',
      display: 'block',
      minHeight: 0,
      marginBottom: 0,
      '& input': {
        margin: 0,
        padding: 0,
      },
    },
  },
  priorityInput: {
    height: 25,
    minWidth: 30,
    maxWidth: 50,
    borderRadius: 4,
    background: 'transparent',
    fontSize: theme.constants.fontSizes.small,
    padding: '1px 2px',
    borderStyle: 'solid',
    borderColor: '#c8c8c8',
    borderWidth: 1,
    '& > div': {
      overflowY: 'auto',
      display: 'block',
      minHeight: 0,
      marginBottom: 0,
      '& input': {
        margin: 0,
        padding: 0,
      },
    },
  },
  textInput: {
    padding: '10px 0px 10px 10px',
    fontSize: theme.constants.fontSizes.small,
  },
  loader: {
    marginBottom: 15,
    overflow: 'hidden',
  },
  headerText: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.small,
  },
  sequenceText: {
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.small,
  },
  headerTableCell: {
    padding: '0 10px 0 0',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:first-child': {
      width: '40%',
    },
    '&:last-child': {
      width: '100%',
    },
    overflow: 'hidden',
  },
  descriptionCellWidth: {
    minWidth: 50,
    maxWidth: 50,
  },
  tableCell: {
    fontSize: theme.constants.fontSizes.small,
  },
  tableHeaderRow: {
    height: 25,
  },
});

export default styles;
