import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import { strings } from 'Constants/Projects/CreatePeriod/Strings';
import { commonStrings } from 'Constants/CommonStrings';

class DeletePeriodOverlay extends React.PureComponent {
  render() {
    const {
      onConfirm, onCancel, isModalOpen,
    } = this.props;

    return (
      <ConfirmationModal
        open={isModalOpen}
        title={strings.deletePeriod}
        message={strings.deletePeriodNote}
        acceptText={commonStrings.delete}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
      />
    );
  }
}

DeletePeriodOverlay.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

export default (DeletePeriodOverlay);
