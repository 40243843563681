const styles = theme => ({
  root: {
    fontColor: theme.palette.primary.darkText,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    height: '70vh',
    textAlign: 'center',
    flexDirection: 'column',
  },
  smallText: {
    fontColor: theme.palette.colors.paleGrey,
    fontSize: 10,
    position: 'fixed',
    marginTop: 0,
    marginLeft: 20,
    marginRight: 20,
    bottom: 7,
    textAlign: 'center',
    lineHeight: '16px',
  },
  version: {
    marginBottom: '-2px',
    marginLeft: '30%',
  },
  largeText: {
    fontSize: 32,
    marginBottom: 15,
  },
  userName: {
    color: theme.palette.primary.main,
  },
  siteMessageWrapper: {
    backgroundColor: theme.palette.primary.orangeBanner,
    color: theme.palette.colors.white,
    textAlign: 'center',
  },
  siteMessage: {
    margin: 0,
    padding: 7,
  },
});

export default styles;
