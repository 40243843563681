import { takeLatest, put } from 'redux-saga/effects';
import { EXPORT_GET_PIVOT_TABLE_FILTER_VALUES } from 'Store/Api/ApiEndpoints';
import { Post } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { GET_FILTER_TAG_VALUES, getFilterTagValuesSuccess } from './ExportPivotTableActions';

function* getPivotTableFilterValues(action) {
  const response = yield Post(EXPORT_GET_PIVOT_TABLE_FILTER_VALUES, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getFilterTagValuesSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* exportPivotTableSagas() {
  yield takeLatest(GET_FILTER_TAG_VALUES, getPivotTableFilterValues);
}