export const GET_APPORTIONABLE_ITEMS = 'APPORTIONMENTITEMS/GET_APPORTIONABLE_ITEMS';
export const GET_APPORTIONABLE_ITEMS_SUCCESS = 'APPORTIONMENTITEMS/GET_APPORTIONABLE_ITEMS_SUCCESS';
export const RESET_APPORTION_ITEMS = 'APPORTIONMENTITEMS/RESET_APPORTION_ITEMS';
export const APPORTIONABLE_ROWS_CHANGE = 'APPORTIONMENTITEMS/APPORTIONABLE_ROWS_CHANGE';
export const UPDATE_APPORTIONMENT_DEFINITION = 'APPORTIONMENTITEMS/UPDATE_APPORTIONMENT_DEFINITION';
export const CALCULATE_APPORTIONMENT = 'APPORTIONMENTITEMS/CALCULATE_APPORTIONMENT';
export const CHECK_APPORTIONMENT_CALCULATION = 'APPORTIONMENTITEMS/CHECK_APPORTIONMENT_CALCULATION';
export const CHECK_APPORTIONMENT_CALCULATION_SUCCESS = 'APPORTIONMENTITEMS/CHECK_APPORTIONMENT_SUCCESS';
export const GET_APPORTIONABLE_ITEMS_SUCCESS_AFTER_UPDATE = 'APPORTIONMENTITEMS/GET_APPORTIONABLE_ITEMS_SUCCESS_AFTER_UPDATE';

export function getApportionableItems(
  periodId,
  sortOrder,
  sortField,
  sortTagId,
  debounceDelay,
  scrollId,
  searchTerm,
) {
  return {
    type: GET_APPORTIONABLE_ITEMS,
    loading: true,
    hasMore: true,
    debounceDelay: debounceDelay,
    payload: {
      periodId,
      scrollId,
      sortOrder,
      sortField,
      sortTagId,
      searchTerm,
    },
  };
}

export function getApportionableItemsSuccess(data) {
  const firstElement = data.apportionables.length >
    0 ? data.apportionables[0] : null;

  return {
    type: GET_APPORTIONABLE_ITEMS_SUCCESS,
    loading: false,
    lineItems: data.apportionables,
    columnTagIds: [-1, ...data.columnTagIds],
    columns: ['Condition', 'Condition String', 'Sequence', 'Method', ...data.columns],
    columnHeaders: ['Condition', 'Condition String', 'Sequence', 'Method', ...data.columnHeaders],
    hasMore: data.hasMore,
    scrollId: data.scrollId,
    totalLineItemCount: data.totalRowCount,
    activeRowId: firstElement ? firstElement.lineItemId : 0,
    selectedRows: firstElement ? [firstElement] : [],
    selectedRowIds: firstElement ? [firstElement.lineItemId] : [],
    selectedRowsTotalAmount: firstElement ? firstElement.tagAndValues.find(f => f.tagName === 'Amount').tagValue : 0,
  };
}

export function getApportionableItemsSuccessAfterUpdate(data, selectedLineItemIds = []) {
  const selectedRows = data.apportionables.length > 0 ? data.apportionables.filter(x =>
    selectedLineItemIds.includes(x.lineItemId)) : null;
  const firstElement = selectedRows !== null && selectedRows.length > 0 ? selectedRows[0] : null;

  return {
    type: GET_APPORTIONABLE_ITEMS_SUCCESS_AFTER_UPDATE,
    loading: false,
    lineItems: data.apportionables,
    columnTagIds: [-1, ...data.columnTagIds],
    columns: ['Condition', 'Condition String', 'Sequence', 'Method', ...data.columns],
    columnHeaders: ['Condition', 'Condition String', 'Sequence', 'Method', ...data.columnHeaders],
    hasMore: data.hasMore,
    scrollId: data.scrollId,
    totalLineItemCount: data.totalRowCount,
    activeRowId: firstElement ? firstElement.lineItemId : 0,
    selectedRows: selectedRows,
    selectedRowIds: selectedLineItemIds,
    selectedRowsTotalAmount: firstElement ? firstElement.tagAndValues.find(f => f.tagName === 'Amount').tagValue : 0,
  };
}

export function updateSelectedRow(
  activeRowId,
  selectedRowIds,
  selectedRows,
  selectedRowsTotalAmount,
) {
  return {
    type: APPORTIONABLE_ROWS_CHANGE,
    activeRowId: activeRowId,
    selectedRows: selectedRows,
    selectedRowIds: selectedRowIds,
    selectedRowsTotalAmount: selectedRowsTotalAmount,
  };
}

export function resetApportionableItems() {
  return {
    type: RESET_APPORTION_ITEMS,
  };
}

export function updateApportionmentDefinition(
  periodId,
  keywordRules = '',
  lineItemIds = [],
  amount = 0,
  definitionText = '',
  tagDictionary,
  priority = 1,
  tagInherited = {},
  tags,
  addedBy = '',
) {
  return {
    type: UPDATE_APPORTIONMENT_DEFINITION,
    payload: {
      periodId: periodId,
      keywordRule: keywordRules,
      lineItemIds: lineItemIds,
      amount: amount,
      definitionText: definitionText,
      priority: priority,
      addedBy: addedBy,
      tagDictionary: tagDictionary,
      tagInherited: tagInherited,
      tags: tags,
    },
  };
}

export function calculateApportionment(periodId) {
  return {
    type: CALCULATE_APPORTIONMENT,
    payload: {
      periodId,
    },
  };
}

export function checkApportionmentCalculation(periodId) {
  return {
    type: CHECK_APPORTIONMENT_CALCULATION,
    payload: {
      periodId,
    },
  };
}

export function checkApportionmentCalculationSuccess(checkApportionmentResponse) {
  return {
    type: CHECK_APPORTIONMENT_CALCULATION_SUCCESS,
    checkApportionmentResponse: {
      isCalculationCompleted: checkApportionmentResponse.isCalculationCompleted,
      isDefinitionNotCompleted: checkApportionmentResponse.isDefinitionNotCompleted
        || checkApportionmentResponse.isErrorMessage,
    },
  };
}
