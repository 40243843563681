import { commonStrings } from 'Constants/CommonStrings';
import { formatCurrencyString } from './NumberHelpers';

export function getTooltip(columns, currencySymbol) {
  return columns ? Object.keys(columns)
    .filter(k => k !== 'id' && !k.endsWith('Id') && !k.startsWith('SYSTEM_UNMAPPED'))
    .map((k) => {
      const value = columns[k] && columns[k].value;

      if (currencySymbol && k === commonStrings.amount) {
        return typeof value === 'number' ? formatCurrencyString(value, currencySymbol) : null;
      }

      return typeof value === 'string' && value || null;
    })
    .filter(v => v !== null)
    .join(' | ') : '';
}