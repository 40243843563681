const styles = theme => ({
  headerText: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  tableCell: {
    padding: '0 50px 0 0',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:first-child': {
      width: '100%',
    },
    '&:last-child': {
      width: '100%',
    },
    overflow: 'hidden',
  },
  descriptionCellWidth: {
    minWidth: 300,
    maxWidth: 300,
  },
  otherCellWidth: {
    minWidth: 150,
    maxWidth: 150,
  },
  scrollContainer: {
    marginTop: 30,
    width: '100%',
  },
  loaderIcon: {
    marginTop: 10,
  },
});

export default styles;
