import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import { strings } from 'Constants/MachineLearning/Strings';
import { formatCurrencyString, formatNumberCommaString } from 'Helpers/NumberHelpers';
import styles from './MLSliderToolTip.styles';

class MLSliderToolTip extends React.PureComponent {
  render() {
    const {
      classes,
      confidenceLevel,
      mlGraphData,
      currencySymbol,
    }
      = this.props;

    let value = 0;
    let valueAbs = 0;
    let lineItems = 0;
    let i = 0;

    if (mlGraphData.length === 101) {
      for (i = confidenceLevel; i <= 100; i += 1) {
        value += mlGraphData[i].amount;
        valueAbs += mlGraphData[i].absAmount;
        lineItems += mlGraphData[i].documentCount;
      }
    }

    return (
      <div className={classes.toolTipBoxContainer}>
        <div className={classes.toolTipBox} style={{ left: `${confidenceLevel}%` }}>
          <div>
            {strings.tooltipConfidenceRange}: {confidenceLevel}% - 100%{' '}
          </div>
          <div>
            {strings.tooltipAbsValue}: {formatCurrencyString(valueAbs, currencySymbol)}
          </div>
          <div>
            {strings.tooltipTotalValue}: {formatCurrencyString(value, currencySymbol)}
          </div>
          <div>
            {strings.tooltipLineItems}: {formatNumberCommaString(lineItems)}
          </div>
        </div>
      </div>
    );
  }
}

MLSliderToolTip.propTypes = {
  confidenceLevel: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  mlGraphData: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.number,
    absAmount: PropTypes.number,
    documentCount: PropTypes.number,
  })).isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(MLSliderToolTip);
