export const GET_COMBO_KEYWORDS = 'COMBO_KEYWORD/GET_COMBO_KEYWORDS';
export const SET_COMBO_KEYWORD_RULE = 'COMBO_KEYWORD/SET_COMBO_KEYWORD_RULE';
export const RESET_COMBO_KEYWORDS = 'COMBO_KEYWORD/RESET_COMBO_KEYWORDS';
export const GET_COMBO_KEYWORDS_SUCCESS = 'COMBO_KEYWORD/GET_COMBO_KEYWORDS_SUCCESS';
export const UPDATE_COMBO_KEYWORD_FILTERS = 'COMBO_KEYWORD/UPDATE_KEYWORD_FILTERS';
export const UPDATE_COMBO_SEARCH_TERM = 'COMBO_KEYWORD/UPDATE_SEARCH_TERM';
export const RESET_COMBO_KEYWORD_FILTERS = 'COMBO_KEYWORD/RESET_KEYWORD_FILTERS';

export function getComboKeywords(
  periodId,
  rootKeyword,
  rootTagFilter,
  currentPageNumber,
  sortOrder,
  tagFilter,
  searchTerm,
  hasSearched,
  isLoadMoreKeywords = false,
) {
  return {
    type: GET_COMBO_KEYWORDS,
    morePagesAvailable: false,
    loading: true,
    hasSearched,
    isLoadMoreKeywords,
    requestTime: Date.now(),
    payload: {
      periodId,
      rootKeyword,
      rootTagFilter,
      currentPageNumber: isLoadMoreKeywords ? currentPageNumber : 1,
      sortOrder,
      tagFilter,
      searchTerm,
    },
  };
}

export function setComboKeywordRule(keyword, tag) {
  return {
    type: SET_COMBO_KEYWORD_RULE,
    payload: {
      rootKeyword: keyword,
      rootTag: tag,
    },
  };
}

export function resetComboKeywords() {
  return {
    type: RESET_COMBO_KEYWORDS,
  };
}

export function getComboKeywordsSuccess(data, hasSearched, isLoadMoreKeywords) {
  return {
    type: GET_COMBO_KEYWORDS_SUCCESS,
    loading: false,
    keywords: data.keywords,
    nextPageNumber: data.nextPageNumber,
    morePagesAvailable: data.hasMore,
    isLoadMoreKeywords,
    hasSearched,
  };
}

export function updateKeywordFilters(
  periodId,
  rootKeyword,
  rootTagFilter,
  currentPageNumber,
  sortOrder,
  tagFilter,
  searchTerm,
) {
  return {
    type: UPDATE_COMBO_KEYWORD_FILTERS,
    sortOrder: sortOrder,
    tagFilter: tagFilter,
    payload: { // we pass along this extra data in the action for when we refetch keywords
      searchTerm: searchTerm,
      periodId: periodId,
      currentPageNumber: currentPageNumber,
      rootKeyword,
      rootTagFilter,
    },
  };
}

export function updateSearchTerm(searchTerm) {
  return {
    type: UPDATE_COMBO_SEARCH_TERM,
    searchTerm: searchTerm,
  };
}

export function resetKeywordFilters() {
  return {
    type: RESET_COMBO_KEYWORD_FILTERS,
  };
}
