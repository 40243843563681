import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { title } from 'Constants/App/Titles/Strings';
import { LinearProgress, withStyles } from '@material-ui/core';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Sidebar from 'Components/UserSidebar/Sidebar';
import {
  importStages,
  importStageMessages,
  analysisStageMessages,
  externalReviewImportStageMessages,
} from 'Constants/Processing/ImportStages';
import { importProcessingArray, contraProcessingArray, deleteSupportingDocArray } from 'Constants/Processing/Strings';
import ProcessingStatus from 'Components/Shared/ProcessingStatus/ProcessingStatus';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import {
  periodIdSelector,
} from 'Store/Areas/Period/PeriodSelectors';
import { cancelImport } from 'Store/Areas/Import/ProcessActions';
import { styles } from './ProcessingContainer.styles';
import ProgressBar from './ProgressBar/ProgressBar';

class ProcessingContainer extends React.PureComponent {
  componentDidMount() {
    document.title = title.processing;
  }

  getProcessingMessageArray =(process) => {
    if (process.stage === importStages.deleteSupportingDoc) {
      return deleteSupportingDocArray;
    } else if (process.stage === importStages.identifyingContras) {
      return contraProcessingArray;
    }
    return importProcessingArray;
  }

  onCancelImportClick = () => {
    const { periodId, dispatch, projects } = this.props;
    dispatch(cancelImport(periodId, projects.userTreePicker.selectedGroupId));
  }

  render() {
    const { process, classes } = this.props;
    return (
      <Fragment>
        <UserSidebarStructure
          mainContent={
            <ProcessingStatus
              processingMessageArray={this.getProcessingMessageArray(process)}
              message={process.progress}
              renderProgressBar={() => (
                <Choose>
                  <When condition={importStageMessages.filter(x =>
                    x.stageId === process.stage).length > 0}
                  >
                    <ProgressBar
                      stage={process.stage}
                      processingStages={importStageMessages}
                    />
                    <div className={classes.cancelImportDiv}>
                      <Button
                        className={classes.cancelImportButton}
                        onClick={this.onCancelImportClick}
                        backgroundColor={constants.backgroundColor.main}
                        height={constants.height.big}
                      >
                        Cancel Import
                      </Button>
                    </div>
                  </When>
                  <When condition={analysisStageMessages.filter(x =>
                    x.stageId === process.stage).length > 0}
                  >
                    <ProgressBar
                      stage={process.stage}
                      processingStages={analysisStageMessages}
                    />
                    <div className={classes.cancelImportDiv}>
                      <Button
                        className={classes.cancelImportButton}
                        onClick={this.onCancelImportClick}
                        backgroundColor={constants.backgroundColor.main}
                        height={constants.height.big}
                      >
                        Cancel Import
                      </Button>
                    </div>
                  </When>
                  <When condition={externalReviewImportStageMessages.filter(x =>
                    x.stageId === process.stage).length > 0}
                  >
                    <ProgressBar
                      stage={process.stage}
                      processingStages={externalReviewImportStageMessages}
                    />
                  </When>
                  <Otherwise>
                    <LinearProgress className={classes.progressBar} />
                    <div className={classes.cancelImportDiv}>
                      <Button
                        className={classes.cancelImportButton}
                        onClick={this.onCancelImportClick}
                        backgroundColor={constants.backgroundColor.main}
                        height={constants.height.big}
                      >
                        Cancel Import
                      </Button>
                    </div>
                  </Otherwise>
                </Choose>
              )}
            />
          }
          sidebarContent={<Sidebar />}
          sidebarContentLoading={false}
          mainContentLoading={false}
          isCollapsible
          collapsed
        />
      </Fragment>
    );
  }
}

ProcessingContainer.propTypes = {
  process: PropTypes.shape({
    loading: PropTypes.bool,
    progress: PropTypes.string,
    stage: PropTypes.number,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedGroupId: PropTypes.number,
    }),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    process: state.import.process,
    projects: state.projects,
    periodId: periodIdSelector(state),
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(ProcessingContainer);
