export const styles = theme => ({
  fileListContainer: {
    margin: '20px 0 10px 0',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '23vh',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.messageBackground,
    paddingBottom: 10,
  },
  icon: {
    padding: 5,
  },
  externalReviewFile: {
    flexGrow: 1,
    margin: '20px 0 10px 5px',
    color: theme.palette.primary.main,
    fontSize: theme.constants.fontSizes.regular,
  },
  loader: {
    width: 30,
  },
});
