export const styles = () => ({
  root: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  sortIcon: {
    marginLeft: 5,
    marginBottom: 5,
  },
});
