const styles = theme => ({
  root: {
    height: 'Calc(100vh - 400px)',
    minHeight: 400,
  },
  header: {
    fontSize: theme.constants.fontSizes.regular,
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
  },
  categoryCircle: {
    borderRadius: '50%',
    height: 10,
    marginRight: 5,
    width: 10,
    display: 'inherit',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    marginTop: 20,
  },
  searchBox: {
    paddingTop: 10,
    width: 240,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.colors.black,
  },
  button: {
    width: '100%',
  },
  buttonDisabled: {
    width: '100%',
    opacity: 0.7,
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    lineHeight: 2,
  },
  text: {
    fontWeight: 'normal',
    fontSize: theme.constants.fontSizes.regular,
    lineHeight: 1.6,
  },
  toolTipBoxContainer: {
    paddingTop: 10,
    height: 50,
  },
  toolTipBox: {
    width: 217,
    height: 50,
    borderRadius: 2,
    boxShadow: '0 0 5px 3px rgba(237, 237, 237, 0.5)',
    border: 'solid 1px #d4d4d4',
    backgroundColor: theme.palette.primary.contrastText,
    margin: '10px 0 0 0 ',
    fontSize: theme.constants.fontSizes.small,
    color: theme.palette.primary.lightLabel,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '0 10px 0 10px',
  },
});

export default styles;
