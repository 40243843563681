import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';
import {
  GET_COMPARE_PERIOD_SUCCESS,
  RESET_COMPARE_PERIOD,
  COMPARE_PERIOD_FILTERS,
} from 'Store/Areas/Review/ComparePeriodActions';

const initialState = {
  loading: false,
  comparePeriod: null,
  filters: {
    comparePeriodId: 0,
  },
};

export default function comparePeriodReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPARE_PERIOD_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        comparePeriod: action.response,
      });
    case COMPARE_PERIOD_FILTERS:
      return Object.assign({}, state, {
        filters: {
          comparePeriodId: action.comparePeriodId,
        },
      });
    case RESET_PERIOD:
    case RESET_COMPARE_PERIOD:
      return initialState;
    default:
      return state;
  }
}
