export const GET_ALL_VERSIONS = 'ML/GET_ALL_VERSIONS';
export const GET_ALL_VERSIONS_SUCCESS = 'ML/GET_ALL_VERSIONS_SUCCESS';
export const ACTIVATE_VERSION = 'ML/ACTIVATE_VERSION';
export const ACTIVATE_VERSION_SUCCESS = 'ML/ACTIVATE_VERSION_SUCCESS';
export const GET_MODELS_OF_LATEST_VERSION = 'ML/GET_MODELS_OF_LATEST_VERSION';
export const GET_MODELS_OF_LATEST_VERSION_SUCCESS = 'ML/GET_MODELS_OF_LATEST_VERSION_SUCCESS';
export const ADD_MODEL = 'ML/ADD_MODEL';
export const EDIT_MODEL = 'ML/EDIT_MODEL';
export const CANCEL_ADD_MODEL = 'ML/CANCEL_ADD_MODEL';
export const POST_ADD_MODEL = 'ML/POST_ADD_MODEL';
export const POST_ADD_MODEL_SUCCESS = 'ML/POST_ADD_MODEL_SUCCESS';
export const POST_EDIT_MODEL = 'ML/POST_EDIT_MODEL';
export const POST_EDIT_MODEL_SUCCESS = 'ML/POST_EDIT_MODEL_SUCCESS';
export const POST_DELETE_MODEL = 'ML/POST_DELETE_MODEL';
export const POST_DELETE_MODEL_SUCCESS = 'ML/POST_DELETE_MODEL_SUCCESS';
export const CHECK_IF_MODEL_IS_USED = 'ML/CHECK_IF_MODEL_IS_USED';
export const CHECK_IF_MODEL_IS_USED_SUCCESS = 'ML/CHECK_IF_MODEL_IS_USED_SUCCESS';
export const COPY_MODELS = 'ML/COPY_MODELS';
export const COPY_MODELS_SUCCESS = 'ML/COPY_MODELS_SUCCESS';

export function getAllVersions() {
  return {
    type: GET_ALL_VERSIONS,
  };
}

export function getAllVersionsSuccess(versions) {
  return {
    type: GET_ALL_VERSIONS_SUCCESS,
    versions: versions,
  };
}

export function getModelsOfLatestVersion(projectId) {
  return {
    type: GET_MODELS_OF_LATEST_VERSION,
    projectId,
  };
}

export function getModelsOfLatestVersionSuccess(models) {
  return {
    type: GET_MODELS_OF_LATEST_VERSION_SUCCESS,
    models: models,
  };
}

export function activateVersion(versionId, versionName) {
  return {
    type: ACTIVATE_VERSION,
    payload: {
      versionId: versionId,
    },
    versionName: versionName,
  };
}

export function activateVersionSuccess() {
  return {
    type: ACTIVATE_VERSION_SUCCESS,
  };
}
export function addModel(version) {
  return {
    type: ADD_MODEL,
    version,
  };
}

export function editModel(model, version) {
  return {
    type: EDIT_MODEL,
    editModel: model,
    version,
  };
}

export function cancelAddModel() {
  return {
    type: CANCEL_ADD_MODEL,
  };
}

export function postAddModel(
  name, description, categories, mlKey, taxContextId,
  licenseType, groupIds, versionId,
) {
  return {
    type: POST_ADD_MODEL,
    payload: {
      name: name,
      description: description,
      categories: categories,
      machineLearningKey: mlKey,
      taxContextId: taxContextId,
      licenseType: licenseType,
      groupIds: groupIds,
      versionId: versionId,
    },
  };
}

export function postAddModelSuccess() {
  return {
    type: POST_ADD_MODEL_SUCCESS,
  };
}

export function postEditModel(modelId, name, description) {
  return {
    type: POST_EDIT_MODEL,
    payload: {
      id: modelId,
      name: name,
      description: description,
    },
  };
}

export function postEditModelSuccess() {
  return {
    type: POST_EDIT_MODEL_SUCCESS,
  };
}
export function postDeleteModel(modelId) {
  return {
    type: POST_DELETE_MODEL,
    payload: {
      id: modelId,
    },
  };
}

export function postDeleteModelSuccess() {
  return {
    type: POST_DELETE_MODEL_SUCCESS,
  };
}

export function checkIfModelIsUsed(modelId) {
  return {
    type: CHECK_IF_MODEL_IS_USED,
    payload: {
      id: modelId,
    },
  };
}

export function checkIfModelIsUsedSuccess(modelUsed) {
  return {
    type: CHECK_IF_MODEL_IS_USED_SUCCESS,
    modelUsed,
  };
}

export function copyModels(versionId) {
  return {
    type: COPY_MODELS,
    payload: {
      versionId,
    },
  };
}

export function copyModelsSuccess() {
  return {
    type: COPY_MODELS_SUCCESS,
  };
}