import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { toggleSidebar } from 'Store/Areas/Projects/UserTreePickerActions';
import SidebarStructure from 'Components/Shared/Sidebar/SidebarStructure';

class UserSidebarStructure extends React.PureComponent {
  toggleCollapse = () => {
    const { collapsed, isCollapsible, dispatch } = this.props;
    if (isCollapsible) {
      dispatch(toggleSidebar(!collapsed));
    }
  }

  render() {
    const {
      mainContent,
      sidebarContent,
      mainContentLoading,
      mainContentLoadingStatus,
      sidebarLoading,
      isCollapsible,
      collapsed,
    } = this.props;
    return (
      <SidebarStructure
        mainContent={mainContent}
        sidebarContent={sidebarContent}
        mainContentLoading={mainContentLoading}
        mainContentLoadingStatus={mainContentLoadingStatus}
        sidebarLoading={sidebarLoading}
        isCollapsible={isCollapsible}
        collapsed={collapsed}
        toggleCollapse={this.toggleCollapse}
      />
    );
  }
}

UserSidebarStructure.defaultProps = {
  collapsed: false,
  isCollapsible: false,
  mainContentLoading: false,
  mainContentLoadingStatus: null,
  sidebarLoading: false,
};

UserSidebarStructure.propTypes = {
  collapsed: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  mainContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  mainContentLoading: PropTypes.bool,
  mainContentLoadingStatus: PropTypes.string,
  sidebarContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  sidebarLoading: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    collapsed: state.projects.userTreePicker.collapsed,
  };
}

export default compose(connect(mapStateToProps))(UserSidebarStructure);
