export const styles = theme => ({
  content: {
    width: 450,
    padding: 20,
  },
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  text: {
    fontWeight: 'normal',
    fontSize: theme.constants.fontSizes.regular,
    marginBottom: 10,
  },
  ruleText: {
    borderBottom: `solid 1px ${theme.palette.primary.greyBorder}`,
    fontSize: theme.constants.fontSizes.small,
    lineHeight: '30px',
    padding: 5,
  },
  ruleList: {
    marginTop: 10,
    marginBottom: 20,
    maxHeight: '60vh',
    overflow: 'auto',
  },
  loader: {
    marginBottom: 15,
    overflow: 'hidden',
  },
  entryList: {
    maxHeight: 400,
    overflow: 'auto',
  },
});
