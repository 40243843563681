import { put } from 'redux-saga/effects';
import { userActions } from 'Constants/UserActions';
import { resetUploadFile } from 'Store/Areas/FileUpload/FileUploadActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { setFileUploadResponse, getReferenceListItems } from 'Store/Areas/Categorisation/ReferenceListActions';
import { updatePeriodStatusInRedux } from 'Store/Areas/Period/PeriodActions';
import { fileStatuses } from 'Constants/FileStatuses';
import { strings } from 'Constants/Categorization/Strings';
import { periodStatuses } from 'Constants/PeriodStatuses';
import SignalrStrategyHandler from './SignalrStrategyHandler';

function duplicateReferenceListFound(payload) {
  return payload.processed && payload.fileStatus &&
  payload.fileStatus === fileStatuses.passedScan &&
  payload.duplicates && payload.duplicates.length &&
  payload.cloudReference;
}

export const referenceListFileUploadHandler = new SignalrStrategyHandler(
  userActions.ReferenceListFileUpload,
  function* handler(payload, action) {
    if (payload.error || duplicateReferenceListFound(payload)) {
      yield put(setFileUploadResponse(payload));
    } else if (!payload.processed) {
      switch (payload.fileStatus) {
        case fileStatuses.corrupt:
          yield this.handleCorruptFile(action);
          break;
        case fileStatuses.failedScan:
          yield this.handleVirusDetected();
          break;
        default:
      }
    } else {
      yield put(resetUploadFile());
      yield put(getReferenceListItems(action.selectedPeriodId, true));
      yield put(displayToastNotification(strings.referenceListUploadSuccess));
    }

    if (payload.processed) {
      yield put(updatePeriodStatusInRedux(periodStatuses.readyForUserCategorisation));
    }
  },
);
