import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';
import { REQUEST_DELETE_RULE_SUCCESS } from 'Store/Areas/Rule/DeleteRuleActions';
import { SUCCESS_EDIT_RULE_NOTE } from 'Store/Areas/Rule/EditRuleNoteActions';
import { commonStrings } from 'Constants/CommonStrings';

import {
  INSPECT_RULE,
  CLOSE_INSPECT_RULE,
  GET_RULE_LINE_ITEMS,
  GET_RULE_LINE_ITEMS_SUCCESS,
  RESET_RULE_LINE_ITEMS,
} from 'Store/Areas/Rule/InspectRuleActions';

const initialState = {
  ruleLineItems: [],
  ruleLineItemsColumns: [],
  ruleLineItemsColumnHeaders: [],
  ruleLineItemsColumnIds: [],
  periodId: 0,
  inspectedRule: null,
  showInspectRuleOverlay: false,
  loading: true,
  hasMore: true,
  scrollId: null,
  amountColumnIndex: -1,
};

export default function inspectRuleReducer(state = initialState, action) {
  switch (action.type) {
    case INSPECT_RULE:
      return Object.assign({}, state, {
        inspectedRule: action.payload.rule,
        showInspectRuleOverlay: true,
      });
    case GET_RULE_LINE_ITEMS:
      return Object.assign({}, state, {
        loading: action.loading,
        hasMore: action.hasMore,
      });
    case GET_RULE_LINE_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        hasMore: action.hasMore,
        scrollId: action.scrollId,
        ruleLineItems: action.scrollId ?
          state.ruleLineItems.concat(action.ruleLineItems) : action.ruleLineItems,
        ruleLineItemsColumns: action.ruleLineItemsColumns,
        ruleLineItemsColumnHeaders: action.ruleLineItemsColumnHeaders,
        ruleLineItemsColumnIds: action.ruleLineItemsColumnIds,
        amountColumnIndex: action.ruleLineItemsColumns != null &&
          action.ruleLineItemsColumns.length === 0 ?
          -1 : action.ruleLineItemsColumns.findIndex(x => x === commonStrings.amount),
      });
    case SUCCESS_EDIT_RULE_NOTE:
      return Object.assign({}, state, {
        inspectedRule: {
          ...state.inspectedRule,
          notes: action.note.addedItem,
        },
      });
    case RESET_RULE_LINE_ITEMS:
      return Object.assign({}, state, {
        scrollId: null,
        ruleLineItems: [],
      });
    case CLOSE_INSPECT_RULE:
    case REQUEST_DELETE_RULE_SUCCESS:
    case RESET_PERIOD:
      return initialState;
    default:
      return state;
  }
}
