export const INITIALISE_SUPPORTING_DOCUMENT_MAPPING_STATE = 'SUPPORTING_DOCS/INITIALISE_SUPPORTING_DOCUMENT_MAPPING_STATE';
export const MAP_ITEM_TO_FILE = 'SUPPORTING_DOCS/MAP_ITEM_TO_FILE';
export const UNMAP_ALL_FILE_ITEMS = 'SUPPORTING_DOCS/UNMAP_ALL_FILE_ITEMS';
export const RESET_SUPPORTING_DOCUMENT_MAPPING_STATE = 'SUPPORTING_DOCS/RESET_SUPPORTING_DOCUMENT_MAPPING_STATE';
export const GET_MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS = 'SUPPORTING_DOCS/GET_MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS';
export const GET_MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS_SUCCESS = 'SUPPORTING_DOCS/GET_MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS_SUCCESS';
export const IS_MATCHED_SUPPORTING_AND_MAIN_DOCS_PRO_RATED = 'SUPPORTING_DOCS/IS_MATCHED_SUPPORTING_AND_MAIN_DOCS_PRO_RATED';
export const PROCESS_MATCHED_SUPPORTING_AND_MAIN_DOCS = 'SUPPORTING_DOCS/PROCESS_MATCHED_SUPPORTING_AND_MAIN_DOCS';


export function initialiseSupportingDocumentMappingState({
  files,
}) {
  return {
    type: INITIALISE_SUPPORTING_DOCUMENT_MAPPING_STATE,
    files: files,
  };
}

export function mapItemToFile({
  fileId,
  lineItemIds,
  lineItemsAmount,
}) {
  return {
    type: MAP_ITEM_TO_FILE,
    fileId: fileId,
    lineItemIds: lineItemIds,
    lineItemsAmount: lineItemsAmount,
  };
}

export function unmapAllFileItems({
  fileId,
}) {
  return {
    type: UNMAP_ALL_FILE_ITEMS,
    fileId: fileId,
  };
}

export function resetSupportingDocumentMappingState() {
  return {
    type: RESET_SUPPORTING_DOCUMENT_MAPPING_STATE,
  };
}

export function getMatchedSupportingAndMainDocDetails({
  supportingFiles,
  periodId,
  mainDocTagId,
  supportingDocTagId,
  supportingDocFileId,
}) {
  const mappings = supportingFiles.map(file => ({
    supportingDocumentFileId: file.id,
    mainFileIds: file.lineItemIds,
    balancingValue: file.balancingValue,
    balancingNotes: file.balancingNotes,
  }));
  return {
    type: GET_MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS,
    payload: {
      mappings: mappings,
      periodId: periodId,
      mappedTags: {
        mainDocTagId: mainDocTagId,
        supportingDocTagId: supportingDocTagId,
        supportingDocFileId: supportingDocFileId,
      },
    },
  };
}

export function getMatchedSupportingAndMainDocDetailsSuccess(data) {
  return {
    type: GET_MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS_SUCCESS,
    payload: {
      allMatchedAndUnmatchedDocsData: data,
    },
  };
}

export function isMatchedSupportingAndMainDocsProRated(
  periodId,
  rowId,
  headerName,
  isSelectAll,
  isAllProRatedSelected,
  isAllBalancingFigureSelected,
) {
  return {
    type: IS_MATCHED_SUPPORTING_AND_MAIN_DOCS_PRO_RATED,
    payload: {
      periodId: periodId,
      rowId: rowId,
      headerName: headerName,
      isSelectAll: isSelectAll,
      isAllProRatedSelected: isAllProRatedSelected,
      isAllBalancingFigureSelected: isAllBalancingFigureSelected,
    },
  };
}

export function processMatchedSupportingAndMainDocs(
  periodId,
  proRateDetails,
  changeSupportingDocsAfterPeriodCreation,
) {
  return {
    type: PROCESS_MATCHED_SUPPORTING_AND_MAIN_DOCS,
    payload: {
      periodId: periodId,
      proRateDetails: proRateDetails,
      changeSupportingDocsAfterPeriodCreation: changeSupportingDocsAfterPeriodCreation,
    },
  };
}