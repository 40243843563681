import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import Modal from 'Components/Shared/Modal/StyledModal';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import ErrorLabel, { constants } from 'Components/Shared/Label/ErrorLabel';
import { strings } from 'Constants/Setup/Admin/Strings';
import { levels } from 'Constants/EntityLevels';
import { commonStrings } from 'Constants/CommonStrings';
import styles from './RemoveAccessModal.styles';

/* eslint-disable no-nested-ternary */

class RemoveAccessModal extends React.PureComponent {
  render() {
    const {
      classes,
      open,
      onCancel,
      onAccept,
      loading,
      errorMessage,
      itemLevel,
      multipleUsers,
    } = this.props;
    const modalMessage = itemLevel === levels.group ?
      (multipleUsers ? strings.removeAdminModalMessagePlural : strings.removeAdminModalMessage) :
      (multipleUsers ? strings.removeUserModalMessagePlural : strings.removeUserModalMessage);
    return (
      <Modal open={open} className={classes.root} isSubmitting={loading}>
        <div className={classes.content}>
          {modalMessage}
          <ErrorLabel
            error={errorMessage}
            verticalPadding
            textColor={constants.textColor.darkRed}
            preserveSpacing
          />
        </div>
        <Buttons
          isSubmitting={loading}
          onAccept={onAccept}
          onCancel={onCancel}
          acceptText={strings.removeAccess}
          cancelText={commonStrings.cancel}
        />
      </Modal>
    );
  }
}

RemoveAccessModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  itemLevel: PropTypes.number.isRequired,
  multipleUsers: PropTypes.bool.isRequired,
};

export default compose(withStyles(styles), withTheme())(RemoveAccessModal);
