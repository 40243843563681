import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import colors from 'Style/BrandingChartColors';
import styles from './CheckableIcon.styles';

class CheckableIcon extends React.PureComponent {
  render() {
    const { classes, selected, color } = this.props;
    const colorStyle = { backgroundColor: colors[color].color };
    return (
      <React.Fragment>
        <Choose>
          <When condition={selected}>
            <div
              className={classes.selected}
              style={colorStyle}
            />
          </When>
          <Otherwise>
            <div
              className={classes.unselected}
              style={colorStyle}
            />
          </Otherwise>
        </Choose>
      </React.Fragment>
    );
  }
}

CheckableIcon.defaultProps = {
  selected: false,
};

CheckableIcon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  color: PropTypes.oneOf(Object.keys(colors)).isRequired,
  selected: PropTypes.bool,
};

export default withStyles(styles)(CheckableIcon);
