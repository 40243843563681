export const styles = theme => ({
  root: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    outline: 0,
    flexShrink: 0,
    borderRadius: '10px 10px 0px 0px',
    boxShadow: '1px -2px 3px -1px rgba(0,0,0,0.2), -1px -1px 3px -1px rgba(0,0,0,0.2)',
    transform: 'translate(0, 3px)',
    zIndex: 1000,
  },
  middle: {
    paddingLeft: 25,
    paddingRight: 25,
    lineHeight: '40px',
    borderRadius: '10px 10px 0px 0px',
    fontSize: theme.constants.fontSizes.regular,
    whiteSpace: 'nowrap',
  },
  active: {
    backgroundColor: theme.palette.colors.white,
    fontWeight: 'bold',
    color: theme.palette.primary.darkText,
  },
  selectable: {
    color: `${theme.palette.primary.main}!important`,
    fontWeight: 'bold',
    cursor: 'pointer',
    backgroundColor: theme.palette.colors.white,
  },
  unselectable: {
    backgroundColor: theme.palette.colors.white,
    color: theme.palette.primary.darkText,
  },
  cursor: {
    cursor: 'pointer',
  },
});
