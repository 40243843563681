const styles = {
  errorContainer: {
    position: 'absolute',
    top: 50,
    left: 180,
    right: 20,
    bottom: 0,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  recommendations: {
    flexGrow: 1,
    overflow: 'auto',
  },
};

export default styles;
