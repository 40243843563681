export const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'Calc(100vh - 350px)',
    overflow: 'hidden',
  },
  overflow: {
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 4,
  },
  margin: {
    marginTop: 10,
    marginBottom: 10,
  },
});
