import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import DragnDrop from 'Constants/DragnDrop';
import { connect as reduxConnect } from 'react-redux';
import { categoryListTypes } from 'Constants/Categorization/CategoryListTypes';
import { categoriseItems } from 'Store/Areas/Rule/CategorisationActions';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import styles from './Category.styles';


class PriorityDropZone extends React.PureComponent {
  render() {
    const {
      connectDropTarget, hovered, classes, className, title,
    } = this.props;

    const hoveredClassName = hovered ? classes.priorityHovered : '';

    const renderedComponent = (
      <div className={`${classes.priority} ${className} ${hoveredClassName}`}>
        {title}
      </div>
    );

    return connectDropTarget(renderedComponent);
  }
}

PriorityDropZone.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  hovered: PropTypes.bool.isRequired,
  periodId: PropTypes.number.isRequired, // eslint-disable-line
  category: PropTypes.shape({
    id: PropTypes.number,
    colorCode: PropTypes.string,
    name: PropTypes.string,
    amountValue: PropTypes.number,
  }).isRequired, // eslint-disable-line
};

PriorityDropZone.defaultProps = {
  className: '',
};

function buildKeywords(item) {
  const rootKeywordTagPair = item.rootKeyword && item.rootTag
    ? [{ keyword: item.rootKeyword.name, tag: item.rootTag }]
    : [];

  return item.keywords.map(k => [
    ...rootKeywordTagPair,
    { keyword: k.name, tag: item.currentTag },
  ]);
}

const spec = {
  drop(props, monitor) {
    const item = monitor.getItem();

    const categories = props.category.id === 0 ? [] : [{ ...props.category, percentage: 1 }];
    let payload = null;
    switch (props.categoryType) {
      case categoryListTypes.categoryType.keywordRule:
        payload = {
          isHighPriority: props.isHighPriority,
          keywords: buildKeywords(item),
          isApportionable: item.isApportionable,
        };
        break;
      case categoryListTypes.categoryType.customRule:
        payload = {
          ruleText: item.text,
          note: item.note,
          isHighPriority: props.isHighPriority,
          ruleId: item.ruleId,
          isApportionable: item.isApportionable,
        };
        break;
      case categoryListTypes.categoryType.lineItems:
        payload = {
          lineItemIds: item.lineItemIds,
          lineItemId: item.lineItemId,
          searchTerm: item.searchTerm,
          contraShelfLineItemIds: item.contraShelfLineItemIds,
          categorizationType: item.categorizationType,
          isApportionable: item.isApportionable,
          previousCategoryId: item.previousCategoryId,
        };
        break;
      case categoryListTypes.categoryType.referenceList:
        payload = {
          referenceListName: item.keyword,
          isHighPriority: props.isHighPriority,
        };
        break;
      default:
    }
    return {
      onDrop: () => props.dispatch(categoriseItems(
        props.periodId,
        categories,
        props.categoryType,
        payload,
      )),
    };
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    keyword: monitor.getItem(),
  };
}

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
  };
}

export default compose(
  reduxConnect(mapStateToProps),
  DropTarget([
    DragnDrop.customRule,
    DragnDrop.keyword,
    DragnDrop.lineItem.uncategorised,
    DragnDrop.referenceListItem,
    DragnDrop.allSearchResultsLineItem,
  ], spec, collect),
  withStyles(styles),
  withTheme(),
)(PriorityDropZone);
