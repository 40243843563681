import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import {
  withStyles,
  withTheme,
} from '@material-ui/core';
import { cancelProjectCreation, postProject, deleteProject } from 'Store/Areas/Setup/Admin/ProjectActions';
import { commonStrings } from 'Constants/CommonStrings';
import { strings } from 'Constants/Setup/Admin/Strings';
import { taxContexts } from 'Constants/Projects/TaxContexts';
import { getCreateProjectSchema } from 'Constants/Setup/Admin/CreateProjectSchema';
import { getErrorsFromValidationError } from 'Constants/ValidationHelpers';
import Button, { constants as buttonConstants } from 'Components/Shared/Buttons/Button';
import SidebarInput from 'Components/Shared/Inputs/SidebarInput';
import Select from 'Components/Shared/Selects/Select';
import SimpleButton from 'Components/Shared/Buttons/SimpleButton/SimpleButton';
import DisabledSelect from 'Components/Shared/Selects/DisabledSelect';
import AdminDeleteModal from 'Components/Setup/Admin/AdminDeleteModal';
import styles from './ProjectCreationSidebar.styles';

class ProjectCreationSidebar extends React.PureComponent {
  state = {
    isDeleteModalOpen: false,
  };

  onRemoveProject = () => {
    this.setState({
      isDeleteModalOpen: true,
    });
  }
  onConfirmDelete = () => {
    const { dispatch, admin } = this.props;
    dispatch(deleteProject(
      admin.project.payload.id,
      admin.adminTreePicker.selectedGroupId,
      admin.project.payload.name,
    ));
    this.onCloseDelete();
  }

  onCloseDelete = () => {
    this.setState({
      isDeleteModalOpen: false,
    });
  }
  getProjectNamesForEntity(groupId, entityId) {
    const { payload } = this.props.admin.project;
    if (entityId === 0) {
      return [];
    }
    return this.props.admin.adminTreePicker
      .data.find(item => item.id === groupId)
      .entities.find(item => item.id === entityId)
      .projects.filter(item => item.id !== payload.id)
      .map(item => item.name);
  }

  cancelCreateProject() {
    this.props.dispatch(cancelProjectCreation());
  }

  postNewProject(payload) {
    const { selectedGroupId, selectedEntityId } = this.props.admin.adminTreePicker;
    const { project } = this.props.admin;

    this.props.dispatch(postProject(
      payload,
      selectedGroupId,
      selectedEntityId,
      project.isEdit,
    ));
  }

  render() {
    const {
      classes,
      admin,
    } = this.props;
    const { isEdit } = admin.project;
    const {
      isDeleteModalOpen,
    } = this.state;

    return (
      <Fragment>
        {
          admin.project.payload.periods &&
          <AdminDeleteModal
            onConfirm={this.onConfirmDelete}
            onCancel={this.onCloseDelete}
            isModalOpen={isDeleteModalOpen}
            title={strings.deleteProject}
            message={admin.project.payload.periods.length > 0 ?
              strings.deleteProjectChild : strings.deleteProjectNoChild}
            canDelete={!admin.project.payload.periods.length > 0}
          />
        }
        <Formik
          validateOnChange
          validateOnBlur
          initialValues={{
            group: admin.project.selection.selectedGroup.id,
            entity: admin.project.selection.selectedEntity.id,
            project: isEdit ? admin.project.payload.name : '',
            context: isEdit ? admin.project.payload.taxContextId : 0,
          }}
          validate={(values) => {
            const projectNames = this.getProjectNamesForEntity(values.group, values.entity);
            const validationSchema = getCreateProjectSchema(projectNames);
            try {
              validationSchema.validateSync(values, { abortEarly: false });
              return {};
            } catch (error) {
              return getErrorsFromValidationError(error);
            }
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            this.postNewProject({
              id: isEdit ? admin.project.payload.id : 0,
              name: values.project,
              entityId: values.entity,
              taxContextId: values.context,
              groupId: admin.project.selection.selectedGroup.id,
            });
          }}
          render={({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            touched,
          }) => {
            const entities = values.group
              ? admin.adminTreePicker.data.find(item => item.id === values.group).entities
              : [];

            return (
              <form className={`${classes.form}`}>
                <div className={`${classes.flex} ${classes.padded} ${classes.scrollable}`}>
                  <div className={`${classes.fieldMargin} ${classes.title}`}>
                    {isEdit ? strings.editProject : strings.createProject}
                  </div>
                  <Choose>
                    <When condition={!isEdit}>
                      <Select
                        id="group"
                        label={strings.groupLabel}
                        value={values.group}
                        error={touched.group ? errors.group : ''}
                        onChange={(value) => {
                          setFieldValue('group', value);
                          setFieldValue('entity', 0);
                        }}
                        data={admin.adminTreePicker.data}
                        className={classes.fieldMargin}
                      />
                    </When>
                    <Otherwise>
                      <DisabledSelect
                        text={admin.adminTreePicker.data.find(x => x.id === values.group).name}
                        label={strings.groupLabel}
                        className={classes.fieldMargin}
                      />
                    </Otherwise>
                  </Choose>
                  <Choose>
                    <When condition={!isEdit}>
                      <Select
                        id="entity"
                        label={strings.entityLabel}
                        value={values.entity}
                        error={touched.entity ? errors.entity : ''}
                        onChange={(value) => {
                          setFieldValue('entity', value);
                        }}
                        data={entities}
                        className={classes.fieldMargin}
                      />
                    </When>
                    <Otherwise>
                      <DisabledSelect
                        text={entities.find(x => x.id === values.entity).name}
                        label={strings.entityLabel}
                        className={classes.fieldMargin}
                      />
                    </Otherwise>
                  </Choose>
                  <SidebarInput
                    id="project"
                    label={strings.projectNameLabel}
                    value={values.project}
                    error={touched.project ? errors.project : ''}
                    onChange={(value) => {
                      setFieldValue('project', value);
                      setFieldTouched('project', true);
                    }}
                    className={classes.fieldMargin}
                    placeholder={strings.projectNamePlaceholder}
                  />
                  <Choose>
                    <When condition={!isEdit}>
                      <Select
                        id="context"
                        label={strings.contextLabel}
                        value={values.context}
                        error={touched.context ? errors.context : ''}
                        onChange={(value) => {
                          setFieldValue('context', value);
                        }}
                        data={taxContexts}
                        className={classes.fieldMargin}
                        placeholder={strings.contextOptionPlaceholder}
                      />
                    </When>
                    <Otherwise>
                      <DisabledSelect
                        text={taxContexts.find(x => x.id === values.context).name}
                        label={strings.contextLabel}
                        className={classes.fieldMargin}
                      />
                    </Otherwise>
                  </Choose>
                  <If condition={isEdit}>
                    <SimpleButton
                      className={classes.deleteButton}
                      onClickEvent={this.onRemoveProject}
                    >
                      {strings.deleteProject}
                    </SimpleButton>
                  </If>
                </div>
                <div>
                  <Button
                    backgroundColor={buttonConstants.backgroundColor.dark}
                    height={buttonConstants.height.big}
                    className={classes.cancel}
                    onClick={() => this.cancelCreateProject()}
                    rounded={false}
                  >
                    {commonStrings.cancel}
                  </Button>
                  <Button
                    className={classes.accept}
                    backgroundColor={buttonConstants.backgroundColor.main}
                    height={buttonConstants.height.big}
                    onClick={handleSubmit}
                    rounded={false}
                    type="submit"
                  >
                    {isEdit ? commonStrings.saveChanges : strings.createProject}
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </Fragment>
    );
  }
}

ProjectCreationSidebar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  admin: PropTypes.shape({
    project: PropTypes.shape({
      isEdit: PropTypes.bool,
      payload: PropTypes.shape({
        id: PropTypes.number,
        periods: PropTypes.array,
      }),
      selection: PropTypes.shape({
        selectedGroup: PropTypes.object,
        selectedEntity: PropTypes.object,
      }),
    }).isRequired,
    adminTreePicker: PropTypes.shape({
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
      selectedProjectId: PropTypes.number,
      selectedItemLevel: PropTypes.number,
      loading: PropTypes.bool,
      data: PropTypes.array,
    }).isRequired,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    admin: state.setup.admin,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(ProjectCreationSidebar);
