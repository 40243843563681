import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme, Paper, Grid, Input } from '@material-ui/core';
import PropTypes from 'prop-types';
import { strings, noteValue } from 'Constants/MachineLearning/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { title } from 'Constants/App/Titles/Strings';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Sidebar from 'Components/UserSidebar/Sidebar';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import ArrowButton, { constants as arrowButtonConstants } from 'Components/Shared/Buttons/ArrowButton/ArrowButton';
import Select, { constants as constantsSelect } from 'Components/Shared/Selects/Select';
import { queueMachineLearningPredictions, requestSkipMachineLearning, cancelImport } from 'Store/Areas/Import/ProcessActions';
import { getModelsOfLatestVersion } from 'Store/Areas/MachineLearning/MachineLearningActions';
import { getHighestValueLineItem, setLineItemThreshold } from 'Store/Areas/Import/LineItemValueActions';
import { machineLearningSelector } from 'Store/Areas/MachineLearning/MachineLearningSelectors';
import {
  periodIdSelector,
  periodInUseSelector,
  periodLockedByUserIdSelector,
  periodEntityMachineLearningEnabled,
} from 'Store/Areas/Period/PeriodSelectors';
import UploadBreadcrumb, { constants as uploadConstants } from 'Components/Shared/UploadBreadcrumb/UploadBreadcrumb';
import styles from './MachineLearningSetup.styles';

class MachineLearningSetup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedModel: 0,
      lineItemValueThreshold: '',
    };
  }

  componentDidMount() {
    const {
      periodId,
      projectId,
      dispatch,
      machineLearningEnabled,
    } = this.props;

    if (machineLearningEnabled) {
      document.title = title.machineLearning;
      dispatch(getModelsOfLatestVersion(projectId));
      dispatch(getHighestValueLineItem(periodId));
      dispatch(getTreePickerData());
    } else {
      this.skipMl();
    }
  }

  onProceedClick = () => {
    const { periodId, dispatch } = this.props;
    const { lineItemValueThreshold, selectedModel } = this.state;
    dispatch(setLineItemThreshold(lineItemValueThreshold));
    dispatch(queueMachineLearningPredictions(periodId, selectedModel, lineItemValueThreshold));
  }

  onCancelImportClick = () => {
    const { periodId, dispatch, projects } = this.props;
    dispatch(cancelImport(periodId, projects.userTreePicker.selectedGroupId));
  }

  setLineLimit = ({ target: { value } }) => {
    const { lineItemValue } = this.props;

    const newLineLimit = !value || +value <= 0 ? '' : Math.min(+value, +lineItemValue.value);

    if (this.state.lineItemValueThreshold !== newLineLimit) {
      this.setState({ lineItemValueThreshold: newLineLimit });
    }
  }
  skipMl = () => {
    const { dispatch, periodId } = this.props;
    dispatch(requestSkipMachineLearning(periodId));
  }
  selectModel = (value) => {
    if (this.state.selectedModel !== value) {
      this.setState({
        selectedModel: value,
      });
    }
  }

  render() {
    const {
      classes,
      machineLearning,
      loading,
      lineItemValue,
      projects,
      currencySymbol,
      periodInUse,
      periodLockedByUserId,
      permissions,
      machineLearningEnabled,
    } = this.props;
    const { selectedModel, lineItemValueThreshold } = this.state;

    const canProceed = !(selectedModel !== 0 && lineItemValueThreshold !== '');
    const isLoading = loading ||
      lineItemValue.valueLoading ||
      machineLearning.loading ||
      lineItemValue.value === null;
    const availableModels = machineLearning.models ? machineLearning.models.map(model => ({
      ...model,
      name: model.displayName,
    })) : [];

    const sidebarLoading = projects.treePicker.loading;

    return (
      <UserSidebarStructure
        mainContent={
          <If condition={machineLearningEnabled}>
            <div className={classes.contraListWrapper}>
              <UploadBreadcrumb index={uploadConstants.indexes.machineLearningSetup} />
              <Paper className={`${classes.paperContainer} ${(periodInUse && periodLockedByUserId !== permissions.id) ? classes.disabled : ''}`}>
                <Grid container direction="row" alignItems="stretch">
                  <Grid container justify="flex-end">
                    <Grid item xs={12} className={classes.gridRow}>
                      <div className={classes.flexContainer}>
                        <div className={classes.arrowButton}>
                          <ArrowButton
                            direction={arrowButtonConstants.direction.forwards}
                            text={strings.machineLearningSkip}
                            onClickEvent={this.skipMl}
                            className={classes.skipButton}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.title}>
                        {strings.machineLearningSetup}
                      </div>
                    </Grid>
                    <Grid item xs={9}>
                      <div className={classes.description}>
                        {strings.machineLearningSetupNote}
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        className={canProceed ? classes.buttonDisabled : classes.proceedButton}
                        onClick={this.onProceedClick}
                        backgroundColor={constants.backgroundColor.main}
                        height={constants.height.big}
                        disabled={canProceed}
                      >
                        {commonStrings.proceed}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Paper className={`${classes.bottomContainer} ${(periodInUse && periodLockedByUserId !== permissions.id) ? classes.disabled : ''}`}>
                <Grid container direction="row" alignItems="stretch">
                  <Choose>
                    <When condition={!isLoading}>
                      <Grid item xs={4} className={classes.mlModelSelector}>
                        <Select
                          id={strings.machineLearningModelSelectPlaceholder}
                          label={strings.machineLearningModel}
                          placeholder={strings.machineLearningModelSelectPlaceholder}
                          disabledPlaceholder
                          data={availableModels}
                          onChange={this.selectModel}
                          value={selectedModel}
                          colorScheme={constantsSelect.colorScheme.lightBlue}
                        />
                      </Grid>
                      <Grid item xs={4} className={classes.mlModelSelector}>
                        <div className={classes.thresholdArea}>
                          <div className={classes.textArea}>
                            <span className={classes.note}>
                              {noteValue(lineItemValue.value, currencySymbol)}
                            </span>
                          </div>
                          <div className={classes.textBox}>
                            <Input
                              className={classes.input}
                              classes={{ input: classes.textInput }}
                              type="number"
                              id="lineLimit"
                              value={lineItemValueThreshold}
                              placeholder={strings.machineLearningModalPlaceholder}
                              inputProps={{ min: 0, step: 10000 }}
                              onChange={this.setLineLimit}
                              disableUnderline
                            />
                          </div>
                        </div>
                      </Grid>
                      {selectedModel !== 0 &&
                        <Fragment>
                          <Grid item xs={12} className={classes.greyBoxTitle}>
                            {strings.machineLearningSelectedModel}
                          </Grid>
                          <Grid item xs={12} className={classes.greyBox}>
                            <div className={classes.greyBoxDescriptor}>
                              {strings.description}
                              <div className={classes.greyBoxData}>
                                {availableModels.find(x => x.id === selectedModel).description}
                              </div>
                            </div>
                            <div className={classes.greyBoxDescriptorBottom}>
                              {strings.categories}
                              <div className={classes.greyBoxData}>
                                {availableModels.find(x => x.id === selectedModel).categories}
                              </div>
                            </div>
                          </Grid>
                        </Fragment>
                      }
                    </When>
                    <Otherwise>
                      <CircularLoader />
                    </Otherwise>
                  </Choose>
                </Grid>
              </Paper>
            </div>
          </If>
        }
        sidebarContent={<Sidebar />}
        sidebarContentLoading={false}
        mainContentLoading={sidebarLoading}
        isCollapsible
        collapsed
      />
    );
  }
}

MachineLearningSetup.defaultProps = {
  loading: false,
  periodLockedByUserId: -1,
};

MachineLearningSetup.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  machineLearning: PropTypes.shape({
    loading: PropTypes.bool,
    models: PropTypes.arrayOf(PropTypes.shape({
      displayName: PropTypes.string,
      categories: PropTypes.string,
      description: PropTypes.string,
    })),
  }).isRequired,
  lineItemValue: PropTypes.shape({
    valueLoading: PropTypes.bool,
    value: PropTypes.number,
  }).isRequired,
  loading: PropTypes.bool,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  periodInUse: PropTypes.bool.isRequired,
  periodLockedByUserId: PropTypes.number,
  permissions: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  machineLearningEnabled: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    periodId: periodIdSelector(state),
    projectId: state.periods.period.data.projectId,
    machineLearning: machineLearningSelector(state),
    lineItemValue: state.import.machineLearning.lineItemValue,
    currencySymbol: currencySymbolSelector(state),
    periodInUse: periodInUseSelector(state),
    periodLockedByUserId: periodLockedByUserIdSelector(state),
    permissions: state.user.permissions.data,
    machineLearningEnabled: periodEntityMachineLearningEnabled(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTheme(),
)(MachineLearningSetup);
