export const styles = () => ({
  root: {
    padding: 20,
  },
  valueBar: {
    marginBottom: 18,
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
  },
  categories: {
    height: '100%',
  },
  categoriesListWrapper: {
    height: 'Calc(100vh - 6em)',
    overflowY: 'auto',
  },
  keywords: {
    marginLeft: 0,
    width: '100%',
  },
});
