const styles = theme => ({
  root: {
  },
  slider: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  labelRow: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
  },
  input: {
    border: 'solid 1px #cccccc !important',
    borderRadius: 4,
    padding: '0 3px',
    width: 65,
    fontSize: theme.constants.fontSizes.small,
  },
  inputInput: {
    paddingTop: 3,
    paddingBottom: 3,
    width: 47,
  },
  endAdornment: {
    margin: 0,
  },
  labelArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    lineHeight: '30px',
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.small,
  },
});

export default styles;
