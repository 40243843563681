import React from 'react';
import { Button, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styles from './Button.styles';

export const constants = {
  backgroundColor: {
    main: 'main',
    dark: 'dark',
    red: 'red',
    green: 'green',
  },
  height: {
    regular: 'regular',
    big: 'big',
  },
};

class CustomButton extends React.PureComponent {
  getBackgroundColorClasses(color) {
    const { classes } = this.props;
    switch (color) {
      case constants.backgroundColor.dark:
        return classes.backgroundDark;
      case constants.backgroundColor.red:
        return classes.backgroundRed;
      case constants.backgroundColor.green:
        return classes.backgroundGreen;
      default:
        return classes.backgroundMain;
    }
  }

  getHeightClasses() {
    const { height, classes } = this.props;
    switch (height) {
      case constants.height.big:
        return classes.heightBig;
      default:
        return classes.heightRegular;
    }
  }

  render() {
    const {
      backgroundColor,
      children,
      classes,
      onClick,
      className,
      disableRipple,
      rounded,
      ...extra
    } = this.props;

    const backgroundColorClasses = this.getBackgroundColorClasses(backgroundColor);
    const height = this.getHeightClasses();
    const rounding = rounded ? classes.rounded : classes.square;

    return (
      <Button
        {...extra}
        disableRipple={disableRipple}
        className={`
          ${backgroundColorClasses}
          ${height}
          ${rounding}
          ${classes.root}
          ${className}
        `}
        onClick={onClick}
      >
        {children}
      </Button>
    );
  }
}

CustomButton.defaultProps = {
  backgroundColor: constants.backgroundColor.main,
  onClick: () => {},
  disableRipple: false,
  height: constants.height.regular,
  rounded: true,
  className: '',
};

CustomButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  backgroundColor: PropTypes.oneOf([
    constants.backgroundColor.main,
    constants.backgroundColor.dark,
    constants.backgroundColor.red,
    constants.backgroundColor.green,
  ]),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disableRipple: PropTypes.bool,
  height: PropTypes.oneOf([constants.height.regular, constants.height.big]),
  rounded: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(CustomButton);
