import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import { strings } from 'Constants/Projects/CreatePeriod/Strings';
import { commonStrings } from 'Constants/CommonStrings';

class PeriodControlTakenOverlay extends React.PureComponent {
  render() {
    const {
      onConfirm, onCancel, isModalOpen, userName,
    } = this.props;

    return (
      <ConfirmationModal
        open={isModalOpen}
        title={strings.periodControlRequest}
        message={strings.periodControlRequestMessage(userName)}
        acceptText={commonStrings.yes}
        cancelText={commonStrings.no}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
      />
    );
  }
}

PeriodControlTakenOverlay.defaultProps = {
  userName: '',
};

PeriodControlTakenOverlay.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  userName: PropTypes.string,
};

export default (PeriodControlTakenOverlay);
