import {
  GET_PREVIEW_ROWS_SUCCESS,
  GET_PREVIEW_ROWS,
} from 'Store/Areas/Import/PreviewActions';

const initialState = {
  loading: false,
  rows: [],
  expectedDataTypes: {},
};

export default function tagMappingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PREVIEW_ROWS:
      return Object.assign({}, state, {
        loading: action.loading,
        rows: action.rows,
      });
    case GET_PREVIEW_ROWS_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        rows: action.rows,
        expectedDataTypes: action.expectedDataTypes,
      });
    default:
      return state;
  }
}
