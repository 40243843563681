import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import { getUserPermissions } from 'Store/Areas/User/PermissionsActions';
import Loader from 'Components/Shared/Loaders/CircularLoader';
import styles from './Layout.styles';

class InitialLoading extends React.PureComponent {
  componentDidMount() {
    this.props.dispatch(getUserPermissions());
  }

  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.centralised}>
        <Loader size={60} />
      </div>
    );
  }
}

InitialLoading.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default compose(
  withTheme(),
  withStyles(styles),
  connect(mapStateToProps),
)(InitialLoading);
