import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Sidebar from 'Components/UserSidebar/Sidebar';
import ExportPage from './ExportPage/ExportPage';

class ExportManager extends React.PureComponent {
  componentDidMount() {
    this.props.dispatch(getTreePickerData());
  }

  render() {
    const {
      projects,
      currencySymbol,
    } = this.props;

    const treePickerLoading = projects.treePicker.loading;

    return (
      <UserSidebarStructure
        mainContent={<ExportPage currencySymbol={currencySymbol} />}
        sidebarContent={<Sidebar />}
        sidebarContentLoading={false}
        mainContentLoading={treePickerLoading}
        isCollapsible
        collapsed
      />
    );
  }
}

ExportManager.propTypes = {
  dispatch: PropTypes.func.isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    currencySymbol: currencySymbolSelector(state),
  };
}

export default compose(connect(mapStateToProps))(ExportManager);
