export const bulkGroupEntityProjectUploadImportStages = {
  uploading: 0,
  passedScan: 1,
  bulkGroupEntityProjectsUploaded: 2,
  failedScan: 3,
  error: 4,
};

export const bulkGroupEntityProjectUploadProcessingStages = [
  {
    stageId: bulkGroupEntityProjectUploadImportStages.uploading,
    stageName: 'Scanning File',
    startMessage: 'Scanning File',
  },
  {
    stageId: bulkGroupEntityProjectUploadImportStages.passedScan,
    stageName: 'Uploading Groups, Entities & Projects',
    startMessage: 'Uploading Groups, Entities & Projects',
  },
  {
    stageId: bulkGroupEntityProjectUploadImportStages.bulkGroupEntityProjectsUploaded,
    stageName: 'Finishing Up',
    startMessage: 'Finishing Up',
  },
];
