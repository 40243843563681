import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Grid } from '@material-ui/core';
import { compose } from 'recompose';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import styles from './UploadStatus.styles';

class UploadStatus extends React.PureComponent {
  render() {
    const {
      classes,
      buttonText,
      onClick,
      children,
      isButtonDisabled,
    } = this.props;
    return (
      <Grid container direction="row" alignItems="stretch" className={classes.root}>
        <Grid item xs={9}>
          {children}
        </Grid>
        <Grid item xs={3}>
          <Button
            onClick={onClick}
            height={constants.height.big}
            className={`${classes.button} ${isButtonDisabled && classes.disabled}`}
            disabled={isButtonDisabled}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

UploadStatus.defaultProps = {
  buttonText: '',
  onClick: () => {},
  isButtonDisabled: false,
};

UploadStatus.propTypes = {
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isButtonDisabled: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(UploadStatus);
