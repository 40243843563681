import {
  REQUEST_TOAST_NOTIFICATION,
  RESET_TOAST_NOTIFICATION,
} from './ToastNotificationActions';

const initialState = {
  message: '',
  showNotification: false,
  linkText: null,
  linkUrl: null,
  hasLink: false,
};

export default function toastNotificationReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_TOAST_NOTIFICATION:
      return Object.assign({}, state, {
        message: action.message,
        showNotification: action.showNotification,
        linkText: action.linkText,
        linkUrl: action.linkUrl,
        hasLink: action.linkText !== null,
      });
    case RESET_TOAST_NOTIFICATION:
      return initialState;
    default:
      return state;
  }
}
