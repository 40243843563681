import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { useAuthRouter } from '@kpmg-uk/auth-router-react/dist';
import Loader from 'Components/Shared/Loaders/CircularLoader';
import Layout from './Components/Layout/Layout';
import ProjectsContainer from './Components/Projects/ProjectsContainer';
import ErrorPage from './Components/ErrorPages/ErrorPage';
import UploadManager from './Components/Upload/UploadManager';
import AdminContainer from './Components/Setup/Admin/AdminContainer';
import CategorizationManager from './Components/Categorization/CategorizationManager';
import ConflictsManager from './Components/Conflicts/ConflictManager';
import InitialLoading from './Components/Layout/InitialLoading';
import TemplateManager from './Components/Templates/TemplateManager';
import { routes } from './Constants/Routes';
import CreatePeriodContainer from './Components/CreatePeriod/CreatePeriodContainer';
import ProcessingContainer from './Components/Processing/ProcessingContainer';
import CategoryReview from './Components/CategoryReview/CategoryReview';
import ApportionableManager from './Components/Apportionables/ApportionableManager';
import ReviewManager from './Components/Review/ReviewManager';
import MachineLearningPage from './Components/MachineLearning/MachineLearningPage';
import MachineLearningSetup from './Components/MachineLearning/MachineLearningSetup';
import PeriodImportDetails from './Components/PeriodImportDetails/PeriodImportDetails';
import LineItemsCategorisationReviewManager from './Components/CategoryReview/LineItemsCategorisation/LineItemsCategorisationReviewManager';
import TemplateProcessingContainer from './Components/Templates/TemplateProcessingContainer';
import BulkUserUpload from './Components/BulkUpload/BulkUpload';
import BulkUserUploadProcessingContainer from './Components/BulkUpload/BulkUserUploadProcessingContainer';
import BulkGroupEntityProjectUploadProcessingContainer from './Components/BulkUpload/BulkGroupEntityProjectUploadProcessingContainer';
import ImportSummaryManager from './Components/ImportSummary/ImportSummaryManager';
import ContrasReview from './Components/Contras/ContrasReview';
import ContrasSetup from './Components/Contras/ContrasSetup';
import FailedRulesReview from './Components/FailedRules/FailedRulesReview';
import ImportErrorPage from './Components/Upload/ImportError/ImportErrorPage';
import ExportManager from './Components/Export/ExportManager';
import ExportPivotTableManager from './Components/Export/ExportPivotTable/ExportPivotTableManager';
import ExternalReviewManager from './Components/Export/ExternalReview/ExternalReviewManager';
import SupportingDocumentsMapper from './Components/SupportingDocuments/SupportingDocumentsMapper';
import ModelList from './Components/ModelManagement/ModelList/ModelList';
import CreateModel from './Components/ModelManagement/CreateModel/CreateModel';
import ExternalReviewImportErrorPage from './Components/Export/ExternalReview/ExternalReviewImportErrorPage';
import Settings from './Components/Settings/Settings';
import Register from './Components/Register/Register';
import { saveAuthContext, newUserSession } from './Store/Areas/App/ConfigActions';

function App(props) {
  const auth = useAuthRouter();
  auth.events.addUserLoaded(() => {
    if (auth.isAuthenticated && props.authContext &&
      props.authContext.user.access_token !== auth.user.access_token) {
      props.dispatch(saveAuthContext(auth));
      props.dispatch(newUserSession());
    }
  });
  if (auth.isLoading) {
    return (
      <div>
        <Loader size={60} />
      </div>
    );
  }
  if (auth.isAuthenticated) {
    if (!props.authContext) {
      props.dispatch(saveAuthContext(auth));
    }
    const periodSelected = props.projects.userTreePicker.selectedPeriodId !== -1;
    const userPermissions = props.user.permissions.data;
    const { message } = props.errorPage;
    return (
      <Switch>
        <Choose>
          <When condition={!props.user.permissions.populated}>
            <InitialLoading />
          </When>
          <Otherwise>
            <Layout>
              <Switch>
                <Route
                  path={routes.error}
                  component={message ? ErrorPage : () => <Redirect to={routes.home} />}
                />
                <Route exact path={routes.home} component={ProjectsContainer} />
                <Route exact path={routes.archive} component={ProjectsContainer} />
                <Route path={routes.register} component={Register} />

                <Route
                  path={routes.setup}
                  component={
                    userPermissions.isGroupAdmin || userPermissions.isSysAdmin
                    || userPermissions.isEntityAdmin
                      ? AdminContainer
                      : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.createModel}
                  component={userPermissions.isSysAdmin
                    ? CreateModel
                    : () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.models}
                  component={userPermissions.isSysAdmin
                    ? ModelList
                    : () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.templates}
                  component={
                    userPermissions.isGroupAdmin || userPermissions.isSysAdmin
                      ? TemplateManager
                      : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.templateProcessing}
                  component={
                    userPermissions.isGroupAdmin || userPermissions.isSysAdmin
                      ? TemplateProcessingContainer
                      : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.bulkUpload}
                  component={
                    userPermissions.isGroupAdmin ||
                    userPermissions.isSysAdmin ||
                    userPermissions.isEntityAdmin
                      ? BulkUserUpload
                      : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.bulkUserUploadProcessing}
                  component={
                    userPermissions.isGroupAdmin ||
                    userPermissions.isSysAdmin ||
                    userPermissions.isEntityAdmin
                      ? BulkUserUploadProcessingContainer
                      : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.bulkGroupEntityProjectUploadProcessing}
                  component={
                    userPermissions.isGroupAdmin ||
                    userPermissions.isSysAdmin ||
                    userPermissions.isEntityAdmin
                      ? BulkGroupEntityProjectUploadProcessingContainer
                      : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.settings}
                  component={userPermissions.isSysAdmin
                    ? Settings
                    : () => <Redirect to={routes.home} />}
                />
                <Route path={routes.createPeriod} component={CreatePeriodContainer} />
                {/* Pages that require a period to be selected before being accessible */}
                <Route
                  path={routes.import.root}
                  component={periodSelected ? UploadManager : () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.categorisation.root}
                  component={
                    periodSelected ? CategorizationManager : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.categoryReview}
                  component={periodSelected ? CategoryReview : () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.apportionableManager}
                  component={periodSelected ? ApportionableManager :
                     () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.lineItemsCategorisationReview.root}
                  component={
                    periodSelected
                      ? LineItemsCategorisationReviewManager
                      : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.conflicts}
                  component={
                    periodSelected ? ConflictsManager : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.processing}
                  component={
                    periodSelected ? ProcessingContainer : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.review}
                  component={periodSelected ? ReviewManager : () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.export}
                  component={periodSelected ? ExportManager : () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.pivotTable}
                  component={periodSelected
                    ? ExportPivotTableManager
                    : () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.externalReview}
                  component={periodSelected
                    ? ExternalReviewManager
                    : () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.machineLearning}
                  component={
                    periodSelected ? MachineLearningPage : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.machineLearningSetup}
                  component={
                    periodSelected ? MachineLearningSetup : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.periodImportDetails}
                  component={
                    periodSelected ? PeriodImportDetails : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.contrasSetup}
                  component={periodSelected ? ContrasSetup : () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.contrasReview}
                  component={periodSelected ? ContrasReview : () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.supportingDocuments}
                  component={periodSelected ? SupportingDocumentsMapper :
                    () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.importError}
                  component={periodSelected ? ImportErrorPage : () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.externalReviewImportError}
                  component={periodSelected ? ExternalReviewImportErrorPage :
                    () => <Redirect to={routes.home} />}
                />
                <Route
                  path={routes.importSummary}
                  component={
                    periodSelected ? ImportSummaryManager : () => <Redirect to={routes.home} />
                  }
                />
                <Route
                  path={routes.failedRulesReview}
                  component={
                    periodSelected ? FailedRulesReview : () => <Redirect to={routes.home} />
                  }
                />
                <Redirect to={routes.home} />
              </Switch>
            </Layout>
          </Otherwise>
        </Choose>
      </Switch>
    );
  }
  if (!auth.isAuthenticated && !auth.isLoading && !props.isLoggingOut) {
    return (
      <Switch>
        <Route path={routes.register} component={Register} />;
        <Route exact path={routes.Home} component={auth.signinRedirect} />
      </Switch>
    );
  }
}

/* eslint-disable react/no-unused-prop-types */
/* App takes route so that it will change when the route does, but doesn't
manipulate or perform any logic on route. */

App.propTypes = {
  route: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  user: PropTypes.shape({
    permissions: PropTypes.object,
  }).isRequired,
  projects: PropTypes.shape({
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
    }),
  }).isRequired,
  errorPage: PropTypes.shape({
    message: PropTypes.string.isRequired,
    recommendation: PropTypes.string.isRequired,
  }).isRequired,
  authContext: PropTypes.shape({
    user: PropTypes.object.isRequired,
  }).isRequired,
  isLoggingOut: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    route: state.router,
    projects: state.projects,
    errorPage: state.app.errorPage,
    authContext: state.app.config.authContext,
    isLoggingOut: state.app.config.isLoggingOut,
  };
}

export default compose(
  DragDropContext(HTML5Backend),
  connect(mapStateToProps),
)(App);
