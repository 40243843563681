import { CANCEL_EXTERNAL_REVIEW } from 'Store/Areas/Export/ExternalReviewActions';
import { CHECK_APPORTIONMENT_CALCULATION_SUCCESS } from 'Store/Areas/Apportionables/ApportionablesActions';
import * as PeriodActions from 'Store/Areas/Period/PeriodActions';

const initialState = {
  loading: false,
  success: false,
  periodId: 0,
  data: {
    status: '',
    isReadOnly: false,
    isLocked: false,
    isExternalReviewLocked: false,
    canLock: false,
    hasConflicts: false,
    fileSchema: null,
    categories: [],
    tags: [],
    rules: [],
    files: [],
    inUse: false,
    isAnyLineItemFlagged: false,
    changeSupportingDocsAfterPeriodCreation: false,
    lineItems: 0,
    periodLockReason: '',
  },
  notification: {},
  isLockedByUserId: null,
  isLockedByUser: null,
  checkApportionmentResponse: {
    isCalculationCompleted: false,
    isDefinitionNotCompleted: false,
  },
};

export default function periodReducer(state = initialState, action) {
  switch (action.type) {
    case PeriodActions.GET_PERIOD:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        periodId: action.payload.periodId,
      });
    case PeriodActions.GET_PERIOD_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        periodId: action.periodId,
        data: Object.assign({}, state.data, action.response),
      });
    case PeriodActions.UPDATE_PERIOD_STATUS_IN_REDUX:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          status: action.status,
        },
      });
    case PeriodActions.PERIOD_GET_PERIOD_LOCKED_BY_USERID:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        periodId: action.periodId,
      });
    case PeriodActions.PERIOD_GET_PERIOD_LOCKED_BY_USERID_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        isLockedByUserId: action.isLockedByUserId,
      });
    case PeriodActions.PERIOD_GET_PERIOD_LOCKED_BY_USER:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        periodId: action.periodId,
      });
    case PeriodActions.PERIOD_GET_PERIOD_LOCKED_BY_USER_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        isLockedByUser: action.isLockedByUser,
      });
    case PeriodActions.RESET_PERIOD_IN_USE:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        periodId: action.payload.periodId,
        userId: action.payload.userId,
      });
    case PeriodActions.SET_PERIOD_IN_USE:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        periodId: action.payload.periodId,
        userId: action.payload.userId,
        periodControlStatus: action.payload.periodControlStatus,
      });
    case PeriodActions.RESET_PERIOD_IN_USE_SUCCESS:
    case PeriodActions.SET_PERIOD_IN_USE_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        data: {
          ...state.data,
          inUse: action.inUse,
        },
      });
    case PeriodActions.SHOW_PERIOD_IN_USE_POPUP:
      return Object.assign({}, state, {
        notification: {
          periodName: action.periodName,
          userName: action.userName,
          requestUserId: action.requestUserId,
          periodControlStatus: action.periodControlStatus,
        },
      });
    case PeriodActions.RESET_PERIOD_IN_USE_POPUP:
      return Object.assign({}, state, {
        notification: {},
      });
    case PeriodActions.RESET_PERIOD:
      return initialState;
    case PeriodActions.REQUEST_CHANGE_SUPPORTING_DOCS_AFTER_PERIOD_CREATION:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          changeSupportingDocsAfterPeriodCreation: action.changeSupportingDocsAfterPeriodCreation,
        },
      });
    case CANCEL_EXTERNAL_REVIEW:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          isExternalReviewLocked: false,
          isReadOnly: false,
          isLocked: false,
        },
      });
    case CHECK_APPORTIONMENT_CALCULATION_SUCCESS:
      return Object.assign({}, state, {
        checkApportionmentResponse: {
          ...action.checkApportionmentResponse,
        },
      });
    default:
      return state;
  }
}
