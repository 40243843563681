import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import { strings } from 'Constants/Projects/CreatePeriod/Strings';

class ClearPeriodDataOverlay extends React.PureComponent {
  render() {
    const {
      onConfirm, onCancel, isModalOpen,
    } = this.props;

    return (
      <ConfirmationModal
        open={isModalOpen}
        title={strings.clearPeriodData}
        message={strings.clearPeriodNote}
        acceptText={strings.clearData}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
      />
    );
  }
}

ClearPeriodDataOverlay.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

export default (ClearPeriodDataOverlay);
