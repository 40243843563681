import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Card, Grid, withStyles, withTheme } from '@material-ui/core';
import Slider from 'Components/Shared/Slider/Slider';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import { Formik } from 'formik';
import { strings, valueString } from 'Constants/MachineLearning/Strings';
import { title } from 'Constants/App/Titles/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { taxContexts } from 'Constants/Projects/TaxContexts';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Sidebar from 'Components/UserSidebar/Sidebar';
import { getGraphData, getHighestValueLineItem, getPeriodLineItemThreshold } from 'Store/Areas/Import/LineItemValueActions';
import {
  requestApplyMachineLearning,
  requestSkipMachineLearning,
} from 'Store/Areas/Import/ProcessActions';
import { periodIdSelector, periodSelector } from 'Store/Areas/Period/PeriodSelectors';
import MachineLearningModal from './Modals/MachineLearningModal/MachineLearningModal';
import DistributionChart from './DistributionChart';
import styles from './MachineLearningPage.styles';
import MLSliderToolTip from './MLSliderToolTip';
import SampleTable from './SampleTable/SampleTable';

class MachineLearningPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      threshold: null,
      limitModalOpen: false,
      confidenceLevel: 90,
    };
  }

  componentDidMount() {
    const {
      periodId,
      lineItemValue,
      dispatch,
    } = this.props;

    document.title = title.machineLearning;

    dispatch(getTreePickerData());

    if (lineItemValue.value === null) {
      dispatch(getHighestValueLineItem(periodId));
    }

    if (lineItemValue.threshold !== null) {
      this.getDistributionGraphData(lineItemValue.threshold);
    } else {
      dispatch(getPeriodLineItemThreshold(periodId));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lineItemValue.threshold === null && this.props.lineItemValue.threshold !== null) {
      this.getDistributionGraphData(this.props.lineItemValue.threshold);
    }
  }

  onProceedClick = () => {
    const { periodId, dispatch, lineItems } = this.props;
    const { threshold, confidenceLevel } = this.state;
    const lineItemIds = lineItems.map((x) => { return x.id; });
    dispatch(requestApplyMachineLearning(periodId, +threshold, confidenceLevel, lineItemIds));
  }

  getDistributionGraphData = (threshold) => {
    const { periodId, dispatch } = this.props;

    this.setState({
      threshold: threshold.toString(),
      limitModalOpen: false,
    });

    dispatch(getGraphData(periodId, threshold));
  }

  skipMl = () => {
    const { periodId, dispatch } = this.props;
    dispatch(requestSkipMachineLearning(periodId));
  }

  openValueLimitModal = () => {
    this.setState({
      limitModalOpen: true,
    });
  }

  render() {
    const {
      classes,
      projects,
      lineItemValue,
      distributionGraph,
      categories,
      currencySymbol,
      period,
    } = this.props;

    const {
      limitModalOpen,
      confidenceLevel,
      threshold,
    } = this.state;

    const isLoading = lineItemValue.valueLoading ||
      lineItemValue.thresholdLoading ||
      distributionGraph.loading ||
      lineItemValue.value === null ||
      lineItemValue.threshold === null ||
      threshold === null ||
      !distributionGraph.data.length;

    const sidebarLoading = projects.treePicker.loading;
    const entity = projects.treePicker.data.filter(g => !!g.entities && g.entities.length)
      .map(g => g.entities && g.entities
        .find(e => e.projects && e.projects.find(p => p.projectId === period.data.projectId)))
      .find(p => !!p);
    const entityProjects = entity ? entity.projects : [];
    const project = period.data.projectId &&
      entityProjects.find(x => x.id === period.data.projectId);
    const taxContext = project ? project.taxContext.name : '';
    const isEmploymentTaxContext = taxContexts.find(tc => tc.id === 3)
      .name.toLowerCase() === taxContext.toLowerCase();

    return (
      <UserSidebarStructure
        isCollapsible
        collapsed
        sidebarLoading={false}
        mainContentLoading={sidebarLoading}
        sidebarContent={<Sidebar />}
        mainContent={
          <Fragment>
            <Choose>
              <When condition={!isLoading}>
                <div className={classes.root}>
                  <Grid container direction="row" alignItems="stretch">
                    <Grid className={classes.gridRow} item xs={12}>
                      <span className={classes.title}>{strings.machineLearningTitle}</span>
                    </Grid>
                    <Card className={classes.card}>
                      <Grid container justify="flex-end">
                        <Grid className={classes.gridRow} item xs={9}>
                          <p className={classes.description}>
                            {strings.machineLearningDescription}
                          </p>
                        </Grid>
                        <Grid className={classes.gridRowRight} item xs={3}>
                          <Button
                            className={classes.fullWidth}
                            onClick={this.onProceedClick}
                            backgroundColor={constants.backgroundColor.main}
                            height={constants.height.big}
                          >
                            {commonStrings.proceed}
                          </Button>
                        </Grid>
                        <Grid className={classes.gridRowGraph} item xs={9}>
                          <Formik
                            initialValues={{
                              percent: isEmploymentTaxContext ? 0 : 90,
                            }}
                            onSubmit={() => { }}
                            render={({ handleSubmit, values, setFieldValue }) => (
                              <form onSubmit={handleSubmit}>
                                <Slider
                                  inputStep="1"
                                  className={classes.slider}
                                  label={
                                    <span className={classes.sliderLabel}>
                                      <span className={classes.sliderColor} />
                                    </span>
                                  }
                                  onValueChange={(value) => {
                                    setFieldValue('percent', Number(value));
                                    this.forceUpdate();
                                    this.setState({
                                      confidenceLevel: Number(value) ? Number(value) : 0,
                                    });
                                  }}
                                  value={values.percent}
                                  endInputAdornment="%"
                                  min={0}
                                  max={100}
                                  size={100}
                                  step={1}
                                />
                              </form>
                            )}
                          />
                          <MLSliderToolTip
                            currencySymbol={currencySymbol}
                            confidenceLevel={confidenceLevel}
                            mlGraphData={distributionGraph.data}
                          />
                          <DistributionChart
                            confidenceLevel={confidenceLevel}
                            distributionGraph={distributionGraph}
                          />
                        </Grid>
                        <Grid className={classes.gridRowRight} item xs={3}>
                          <span className={classes.title}>{strings.machineLearningLineLimit}</span>
                          <p className={classes.description}>
                            {valueString(lineItemValue.threshold, currencySymbol)}
                            <button
                              className={classes.button}
                              size="small"
                              onClick={this.openValueLimitModal}
                            >
                              <span className={classes.change}>{commonStrings.change}</span>
                            </button>
                          </p>
                        </Grid>
                        <Grid className={classes.gridRowTable} item xs={12}>
                          <SampleTable
                            currencySymbol={currencySymbol}
                            lineItemLimit={lineItemValue.threshold}
                            confidenceThreshold={confidenceLevel}
                            categories={categories}
                          />
                        </Grid>
                      </Grid>
                      <MachineLearningModal
                        currencySymbol={currencySymbol}
                        setValueLimit={this.getDistributionGraphData}
                        skipMl={this.skipMl}
                        open={limitModalOpen}
                        maxValue={lineItemValue.value}
                        currentValue={threshold}
                      />
                    </Card>
                  </Grid>
                </div>
              </When>
              <Otherwise>
                <CircularLoader />
              </Otherwise>
            </Choose>
          </Fragment>
        }
      />
    );
  }
}

MachineLearningPage.propTypes = {
  periodId: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  lineItemValue: PropTypes.shape({
    valueLoading: PropTypes.bool,
    value: PropTypes.number,
    thresholdLoading: PropTypes.bool,
    threshold: PropTypes.number,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  distributionGraph: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      documentCount: PropTypes.number,
    })),
  }).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  })).isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  period: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    periodId: periodIdSelector(state),
    period: periodSelector(state),
    categories: state.periods.period.data.categories,
    lineItemValue: state.import.machineLearning.lineItemValue,
    distributionGraph: state.import.machineLearning.distributionGraph,
    lineItems: state.categorisation.lineItems.lineItems,
    currencySymbol: currencySymbolSelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(MachineLearningPage);
