import { select, put, call, take, fork, cancel, cancelled } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import { START_PERIOD_STATUS_POLLING, STOP_PERIOD_STATUS_POLLING, stopPolling } from 'Store/Areas/App/PeriodStatusPollingActions';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';

const pollingInterval = 30 * 1000;

function* pollForStatus(periodId, maintainRoute) {
  while (true) {
    yield call(delay, pollingInterval);
    if (yield cancelled()) {
      yield put(stopPolling());
      break;
    }

    const currentPeriodId = yield select(periodIdSelector);

    if (currentPeriodId && currentPeriodId !== periodId) {
      yield put(stopPolling());
      break;
    }
    yield put(getPeriod(periodId, maintainRoute));
  }
}

// Watcher
export function* periodStatusPollingSagas() {
  while (true) {
    const action = yield take(START_PERIOD_STATUS_POLLING);
    const bgTask = yield fork(pollForStatus, action.periodId, action.maintainRoute);
    yield take(STOP_PERIOD_STATUS_POLLING);
    yield cancel(bgTask);
  }
}
