import { SET_ERROR } from './ErrorPageActions';

const initialState = {
  message: '',
  recommendation: '',
};

export default function errorPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR:
      return Object.assign({}, state, {
        message: action.message,
        recommendation: action.recommendation,
      });
    default:
      return state;
  }
}
