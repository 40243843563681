const date = new Date();
const year = date.getFullYear();

export const strings = {
  welcomeMessage: 'Welcome back ',
  choosePeriod: 'Please choose a period on the left to start.',
  kpmgLegal: `\u00A9 ${year} KPMG LLP, a UK limited liability partnership and a member firm of the
           KPMG network of independent member firms affiliated with KPMG International
           Cooperative("KPMG International"), a Swiss entity. All rights reserved. 
           KPMG International Cooperative, a Swiss entity. Member firms of the KPMG network
           of independent firms are affiliated with KPMG International. KPMG International
           provides no client services. No member firm has any authority to obligate or
           bind KPMG International or any other member firm vis-\u00E0-vis third parties, nor
           does KPMG International have any such authority to obligate or bind any member
           firm. `,
  version: 'Version:',
};
