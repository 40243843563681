import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import {
  GET_SETTINGS_URL,
  UPDATE_SETTINGS_URL,
  GET_SITE_MESSAGE_URL,
} from 'Store/Api/ApiEndpoints';
import { Get, Post } from 'Store/Api/CallApi';
import {
  GET_SETTINGS_DATA,
  getSettingsDataSuccess,
  UPDATE_SETTINGS_DATA,
  updateSettingsDataSuccess,
  GET_SITE_MESSAGE,
  getSiteMessageSuccess,
} from 'Store/Areas/Settings/SettingsActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { strings as errorStrings } from 'Constants/Error/Strings';
import { strings } from 'Constants/Settings/Strings';

/* Worker Sagas */
function* getSettingsData() {
  const response = yield Get(GET_SETTINGS_URL);
  if (response.ok) {
    const data = yield response.json();
    yield put(getSettingsDataSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* updateSettingsData(action) {
  const response = yield Post(UPDATE_SETTINGS_URL, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(updateSettingsDataSuccess(data));
    yield put(displayToastNotification(strings.settingsSaved));
  } else {
    yield put(displayToastNotification(errorStrings.errorMessageRetry));
    yield handleStatusCode(response.status);
  }
}

function* getSiteMessage() {
  const response = yield Get(GET_SITE_MESSAGE_URL);
  if (response.ok) {
    const data = yield response.json();
    yield put(getSiteMessageSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* settingSagas() {
  yield takeLatest(GET_SETTINGS_DATA, getSettingsData);
  yield takeLatest(UPDATE_SETTINGS_DATA, updateSettingsData);
  yield takeLatest(GET_SITE_MESSAGE, getSiteMessage);
}
