import React from 'react';
import { withTheme, withStyles, IconButton } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Chevron from 'Assets/Images/chevron-down-blue-ic.svg';
import { styles } from './ExpansionPanel.styles';

class ExpansionPanel extends React.PureComponent {
  render() {
    const {
      classes,
      header,
      children,
      className,
      activeIndex,
      index,
      onExpand,
    } = this.props;
    const expanded = activeIndex.includes(index);
    return (
      <div className={`${classes.root} ${className}`}>
        <div
          className={classes.header}
          onClick={() => onExpand(index)}
          role="button"
          tabIndex="-1"
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              onExpand(index);
            }
          }}
        >
          <div className={classes.title}>{header}</div>
          <IconButton className={classes.icon}>
            <img alt="Expand" src={Chevron} className={expanded ? classes.rotate : ''} />
          </IconButton>
        </div>
        <div className={`${classes.content} ${!expanded ? classes.hidden : ''}`}>{children}</div>
      </div>
    );
  }
}

ExpansionPanel.defaultProps = {
  header: '',
  children: '',
  className: '',
};

ExpansionPanel.propTypes = {
  className: PropTypes.string,
  header:
    PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.string]),
  children:
    PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.string]),
  index: PropTypes.number.isRequired,
  activeIndex: PropTypes.arrayOf(PropTypes.number).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onExpand: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTheme())(ExpansionPanel);

