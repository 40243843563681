export const GET_PERIOD_IMPORT_DETAILS = 'PERIODIMPORTDETAILS/GET_PERIOD_IMPORT_DETAILS';
export const GET_PERIOD_IMPORT_DETAILS_SUCCESS = 'PERIODIMPORTDETAILS/GET_PERIOD_IMPORT_DETAILS_SUCCESS';
export const SET_REDIRECTED_FROM_REVIEW_PAGE = 'SET_REDIRECTED_FROM_REVIEW_PAGE';

export function getPeriodImportDetails(periodId) {
  return {
    type: GET_PERIOD_IMPORT_DETAILS,
    loading: true,
    payload: {
      periodId: periodId,
    },
  };
}

export function getPeriodImportDetailsSuccess(data) {
  return {
    type: GET_PERIOD_IMPORT_DETAILS_SUCCESS,
    loading: false,
    importDetails: data,
  };
}

export function setRedirectedFromReviewPage(value) {
  return {
    type: SET_REDIRECTED_FROM_REVIEW_PAGE,
    isRedirectedFromReviewPage: value,
  };
}