const styles = theme => ({
  overlayDialogPaper: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    padding: 30,
  },
  closeIcon: {
    height: '30px',
    width: '30px',
  },
  closeButton: {
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    flex: 'auto',
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.large,
  },
  headerToolbar: {
    padding: 0,
  },
  headerContainer: {
    width: '100%',
    minHeight: '200px',
    padding: 15,
    backgroundColor: 'rgba(0, 51, 141, 0.12)',
    flexWrap: 'nowrap',
  },
  headerButton: {
    width: 180,
    height: 40,
    margin: '10px 10px 10px 0',
  },
  headerText: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  mainText: {
    fontWeight: 'normal',
    fontSize: theme.constants.fontSizes.regular,
  },
  noteContainer: {
    padding: '32px 0',
  },
  loader: {
    marginBottom: 15,
    overflow: 'hidden',
  },
});

export default styles;
