import handleStatusCode from 'Store/Api/HandleStatusCode';
import { takeLatest, put } from 'redux-saga/effects';
import {
  CATEGORY_GET_POPULAR_KEYWORDS,
  CATEGORY_GET_POPULAR_KEYWORDS_BY_TAG,
} from 'Store/Api/ApiEndpoints';
import { Get, Post } from 'Store/Api/CallApi';
import {
  REVIEW_CATEGORY,
  GET_POPULAR_KEYWORDS_BY_TAG,
  getPopularKeywordsSuccess,
  getPopularKeywordsByTagSuccess,
  selectTag,
} from 'Store/Areas/Categorisation/ReviewCategoryActions';

/* Worker Sagas */
function* getPopularKeywords(action) {
  const response =
    yield Get(CATEGORY_GET_POPULAR_KEYWORDS(action.payload.category.id, action.payload.periodId));
  if (response.ok) {
    const popularKeywords = yield response.json();
    yield put(getPopularKeywordsSuccess(popularKeywords));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getPopularKeywordsByTagSaga(action) {
  const response = yield Post(CATEGORY_GET_POPULAR_KEYWORDS_BY_TAG, action.payload);
  if (response.ok) {
    const popularKeywords = yield response.json();
    yield put(getPopularKeywordsByTagSuccess(popularKeywords));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* resetPopularKeywordsByTag() {
  yield put(selectTag(-1));
}

/* Watcher Sagas */
export function* reviewCategorySaga() {
  yield takeLatest(REVIEW_CATEGORY, getPopularKeywords);
  yield takeLatest(REVIEW_CATEGORY, resetPopularKeywordsByTag);
  yield takeLatest(GET_POPULAR_KEYWORDS_BY_TAG, getPopularKeywordsByTagSaga);
}
