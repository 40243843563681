export const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 1,
    marginBottom: 0,
  },
  filledBar: {
    backgroundColor: theme.palette.primary.darkAlt,
  },
  unfilledBar: {
    backgroundColor: 'transparent',
  },
});

