import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme, Card, Grid } from '@material-ui/core';
import styles from './PopularKeywords.styles';
import TagPicker from './TagPicker';
import KeywordValuesList from './KeywordValuesList';

class PopularKeywords extends React.PureComponent {
  render() {
    const {
      classes,
      currencySymbol,
    } = this.props;
    return (
      <Card className={classes.card}>
        <Grid container direction="row">
          <Grid item xs={6} className={classes.header}>
            Filter popular keywords by tag
            <TagPicker />
          </Grid>
          <Grid item xs={6} className={classes.header}>
            <KeywordValuesList currencySymbol={currencySymbol} />
          </Grid>
        </Grid>
      </Card>
    );
  }
}

PopularKeywords.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

export default compose(
  withTheme(),
  withStyles(styles),
)(PopularKeywords);
