export const styles = theme => ({
  root: {
    fontSize: theme.constants.fontSizes.small,
    height: '1em',
    maxHeight: '1em',
    marginBottom: 5,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  valid: {
    cursor: 'pointer',
  },
  error: {
    opacity: 0.5,
  },
  dragText: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  hidden: {
    display: 'none',
  },
});
