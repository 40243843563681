import { strings } from 'Constants/Error/Strings';

export const SET_ERROR = 'ERRORPAGE/SET_ERROR';

export function setError(message, recommendation) {
  return {
    type: SET_ERROR,
    message: message,
    recommendation: recommendation,
  };
}

export function setImportFailedError(
  message = strings.importFailed,
  recommendation = strings.importFailedRecommendation,
) {
  return setError(message, recommendation);
}
