export const styles = theme => ({
  root: {
    padding: 20,
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '88vh',
    overflowY: 'auto',
  },
  breadcrumb: {
    marginLeft: 20,
    marginBottom: 20,
  },
  paper: {
    marginTop: 10,
  },
  title: {
    fontSize: theme.constants.fontSizes.large,
    color: theme.palette.primary.darkText,
    marginLeft: 20,
  },
  apportionableWarning: {
    fontSize: theme.constants.fontSizes.large,
    color: theme.palette.primary.darkText,
    marginLeft: 20,
    backgroundColor: '#e09002',
    marginRight: 20,
    textAlign: 'center',
  },
  description: {
    fontSize: theme.constants.fontSizes.regular,
  },
  descriptionNote: {
    paddingTop: 10,
  },
  exportList: {
    maxWidth: 450,
  },
  note: {
    marginTop: 30,
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  proceedContainer: {
    marginLeft: 20,
    marginTop: 10,
    marginRight: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingTop: 30,
  },
  exportButton: {
    width: '100%',
  },
  checkbox: {
    padding: 0,
  },
  lineItem: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.messageBackground,
    paddingLeft: 0,
    marginTop: 0,
  },
  validationMessage: {
    marginTop: 15,
    fontSize: theme.constants.fontSizes.small,
    color: theme.palette.primary.error,
    height: 10,
  },
  progressBar: {
    margin: '30px auto 0 auto',
    width: '65%',
    border: '4px solid white',
    marginBottom: 40,
  },
  fileListContainer: {
    margin: '10px 20px 0px 20px',
    padding: '30px 20px 30px 20px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 200,
  },
  disabledFileType: {
    opacity: 0.3,
  },
  uploadContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.messageBackground,
    paddingBottom: 10,
  },
  customReportFile: {
    maxWidth: 414,
    flexGrow: 1,
    margin: '10px 0px 10px 36px',
    color: theme.palette.primary.main,
    fontSize: theme.constants.fontSizes.regular,
  },
  icon: {
    padding: 5,
  },
});
