export const strings = {
  title: 'File upload and management',
  subtitle: 'Upload new files',
  bulkUploadNote: 'You can use this screen to bulk upload data.',
  bulkUploadSubNote: 'This could be multiple users to a project, multiple new Groups, Entities or Projects.',
  bulkUploadMessagePart1: 'Your documents are now being processed',
  bulkUploadMessagePart2: 'To make the Bulk Upload work smoothly, the system needs to do some ground work first.',
  bulkUploadMessagePart3: 'This initial processing might take some time.',
  bulkUploadMessagePart4: 'Thank you for your patience. ',
  bulkUserUploadSuccessful: 'Bulk Users successfully uploaded. ',
  bulkUserUploadFailed: 'Bulk Users failed to upload. ',
  bulkUserUploadSuccessfulNote: 'Users have been successfully uploaded. Please click \'Upload more Users\' to add more users.',
  bulkUserUploadFailedNote: 'Users have failed to upload. Please click \'Upload more Users\' to add  more users.',
  outcomeBulkUserUploadButton: 'Upload more Users',
  failedScanTitle: 'Corrupted file',
  failedScanDescription: 'This file is corrupted and cannot be uploaded',
  warnings: 'Warnings',
  itemWarnings: 'The following entries have been excluded from the import:',
  bulkUploadTypeSelector: 'Document Type',
  bulkGroupEntityProjectUploadSuccessful: 'Bulk Groups, Entities and Projects successfully uploaded. ',
  bulkGroupEntityProjectUploadFailed: 'Bulk Groups, Entities and Projects failed to upload. ',
  bulkGroupEntityProjectUploadSuccessfulNote: 'Groups, Entities and Projects have been successfully uploaded. Please click \'Upload more Groups, Entities and Projects\' to add more groups, entities and projects.',
  bulkGroupEntityProjectUploadFailedNote: 'Groups have failed to upload. Please click \'Upload more Groups, Entities and Projects\' to add  more groups, entities and projects.',
  outcomeBulkGroupEntityProjectUploadButton: 'Upload more Groups, Entities and Projects',
};

export const bulkUserUploadMessageArray =
  [strings.bulkUploadMessagePart1,
    strings.bulkUploadMessagePart2,
    strings.bulkUploadMessagePart3,
    strings.bulkUploadMessagePart4];

export const bulkGroupEntityProjectUploadMessageArray =
  [strings.bulkUploadMessagePart1,
    strings.bulkUploadMessagePart2,
    strings.bulkUploadMessagePart3,
    strings.bulkUploadMessagePart4];

