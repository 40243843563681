import { OPEN_CREATE_RULE, CLOSE_CREATE_RULE, RESET_CREATE_RULE } from 'Store/Areas/Rule/CreateRuleActions';

const initialState = {
  open: false,
  rule: '',
  note: '',
};

export default function createRuleReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_CREATE_RULE:
    case CLOSE_CREATE_RULE:
      return Object.assign({}, state, {
        open: action.open,
        rule: action.rule,
        note: action.note,
      });
    case RESET_CREATE_RULE:
      return initialState;
    default:
      return state;
  }
}

