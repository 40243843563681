import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { strings } from 'Constants/Categorization/Strings';
import styles from './KeywordRule.styles';

class KeywordRule extends React.PureComponent {
  render() {
    const {
      classes,
      hoveredKeyword,
      rootKeyword,
      rootTag,
      secondTag,
      keywordSelections,
    } = this.props;
    return (
      <Choose>
        <When condition={rootKeyword == null}>
          <div className={classes.ruleContainer}>
            <Choose>
              <When condition={keywordSelections > 1}>
                {strings.singleKeywordRuleDescription(
                  rootTag,
                  strings.singleKeywordMultipleSelection(keywordSelections),
                )}
              </When>
              <When condition={hoveredKeyword == null}>
                {strings.currentlyCategorising(rootTag)}
              </When>
              <Otherwise>
                {strings.singleKeywordRuleDescription(rootTag, hoveredKeyword)}
              </Otherwise>
            </Choose>
          </div>
        </When>
        <Otherwise>
          <Choose>
            <When condition={hoveredKeyword == null}>
              <div className={classes.ruleContainer}>
                {strings.singleKeywordRuleDescription(rootTag, rootKeyword)}
              </div>
            </When>
            <Otherwise>
              <div className={classes.ruleContainer}>
                {strings.comboKeywordRuleDescription(
                  rootTag,
                  rootKeyword,
                  secondTag,
                  hoveredKeyword,
                )}
              </div>
            </Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    );
  }
}

KeywordRule.defaultProps = {
  hoveredKeyword: null,
  rootKeyword: null,
  rootTag: null,
  secondTag: '',
  keywordSelections: 0,
};

KeywordRule.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  hoveredKeyword: PropTypes.string,
  rootKeyword: PropTypes.string,
  rootTag: PropTypes.string,
  secondTag: PropTypes.string,
  keywordSelections: PropTypes.number,
};
function mapStateToProps(state) {
  return {
    period: state.periods.period,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(KeywordRule);
