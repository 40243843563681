import React from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { successfulTableColumns, unsuccessfulTableColumns } from 'Constants/ImportSummary/Strings';
import DataGrid from 'Components/Shared/DataGrid/DataGrid';
import SimpleCell from 'Components/Shared/DataGrid/Cells/SimpleCell/SimpleCell';
import SimpleHeader from 'Components/Shared/DataGrid/Cells/SimpleHeader/SimpleHeader';
import styles from './SummaryItemTable.styles';

class SummaryItemTable extends React.PureComponent {
  getCellValue = ({ rowIndex, columnIndex }) => {
    const { importErrors } = this.props;
    const row = importErrors[rowIndex];
    switch (columnIndex) {
      case 0:
        return row.fileName;
      case 1:
        return row.row;
      case 2:
        return row.tag.name;
      case 3:
        return row.invalidValue;
      case 4:
        return row.tag.type;
      default:
        return '';
    }
  };

  getWidth = ({ index }) => {
    switch (index) {
      case 0:
        return 250;
      case 1:
        return 105;
      case 4:
        return 130;
      default:
        return 115;
    }
  };

  render() {
    const {
      importErrors,
      classes,
      currencySymbol,
      loading,
      successful,
    } = this.props;

    let summaryColumns = [];
    summaryColumns = successful ? successfulTableColumns : unsuccessfulTableColumns;
    const sortedHeaders = summaryColumns.sort((a, b) => {
      return a.columnIndex - b.columnIndex;
    });
    return (
      <div className={classes.tableContainer}>
        <DataGrid
          isNextPageLoading={loading}
          columns={sortedHeaders}
          rows={importErrors}
          getCellValue={this.getCellValue}
          getWidth={this.getWidth}
          renderHeaderCell={props => <SimpleHeader {...props} className={classes.headerCell} />}
          renderCell={props => (<SimpleCell
            {...props}
            currencySymbol={currencySymbol}
            className={classes.cell}
          />)}
        />
      </div>
    );
  }
}

SummaryItemTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  loading: PropTypes.bool.isRequired,
  successful: PropTypes.bool.isRequired,
  importErrors: PropTypes.arrayOf(PropTypes.object).isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: state.periods.period.periodId,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(SummaryItemTable);
