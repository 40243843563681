const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    maxHeight: '100%',
    height: '100%',
  },
  text: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.constants.fontSizes.regular,
    marginBottom: 10,
  },
  treePicker: {
    flexGrow: 1,
    paddingTop: 30,
    paddingLeft: 30,
    overflowY: 'auto',
  },
});

export default styles;
