const styles = theme => ({
  card: {
    padding: 25,
    paddingBottom: 20,
    width: '50%',
    margin: '60px auto 0 auto',
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  title: {
    fontSize: theme.constants.fontSizes.large,
    fontWeight: 'bold',
    marginBottom: 5,
    paddingTop: 20,
  },
  textArea: {
    paddingTop: 15,
    fontSize: theme.constants.fontSizes.regular,
  },
  button: {
    minWidth: 140,
    marginTop: 30,
    marginRight: 10,
    marginBottom: 10,
  },
  tick: {
    paddingRight: 5,
  },
  note: {
    paddingRight: 20,
  },
  buttonContainer: {
    paddingBottom: 10,
  },
});

export default styles;
