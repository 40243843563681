const styles = theme => ({
  root: {
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.greyBorder,
    paddingBottom: '2px',
  },
  input: {
    fontSize: theme.constants.fontSizes.small,
    backgroundColor: theme.palette.colors.white,
    color: theme.palette.colors.black,
    height: '50px',
    '& input[type=text]::-ms-clear': {
      display: 'none',
    },
  },
  searchIconWrapper: {
    padding: 0,
    marginLeft: -16,
  },
  searchButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    outline: 'none',
  },
  label: {
    color: theme.palette.primary.lightLabel,
    fontSize: theme.constants.fontSizes.small,
    opacity: '0.6',
  },
  icon: {
    marginRight: 10,
    paddingTop: 10,
  },
  img: {
    width: '100%',
  },
});

export default styles;
