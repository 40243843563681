import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import DragnDrop from 'Constants/DragnDrop';
import { DragSource } from 'react-dnd';
import { isCtrlKeyActive, isShiftKeyActive } from 'Helpers/SelectionHelpers';
import withDndPreviewContext from 'Components/Shared/DragNDrop/DndPreviewContextProvider';
import { styles } from './BorderedRow.styles';

class BorderedRow extends React.PureComponent {
  componentDidMount() {
    this.props.connectDragPreview(this.props.dndPreview);
  }

  onClick = (e) => {
    const { onSelectionChange } = this.props;
    onSelectionChange(
      BorderedRow.getDragProps(this.props).lineItemId,
      isCtrlKeyActive(e),
      isShiftKeyActive(e),
    );
  }

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.onClick(e);
    }
  }

  static getDragProps(props) {
    return props.dragProps || props.onGetDragProps();
  }

  render() {
    const {
      classes,
      style,
      children,
      tooltip,
      connectDragSource,
      isSelected,
      canDrag,
    } = this.props;
    return (
      <div
        style={style}
        className={`${classes.root} ${isSelected ? classes.selectedItem : ''} ${canDrag ? classes.canDrag : ''}`}
        role="button"
        tabIndex="0"
        onKeyDown={this.onKeyDown}
        onClick={this.onClick}
        title={tooltip}
      >
        {canDrag &&
          connectDragSource(<div className={classes.dragSource} />)}
        {children}
      </div>
    );
  }
}

// Implements the drag source contract.
const cardSource = {
  beginDrag(props) {
    if (props.onBeginDrag) {
      props.onBeginDrag(BorderedRow.getDragProps(props));
    }

    return {
      ...BorderedRow.getDragProps(props),
    };
  },

  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const { onDrop } = monitor.getDropResult();

      if (props.onEndDrag) {
        props.onEndDrag();
      }

      onDrop(props.dragProps);
    }
  },
};

// Specifies the props to inject into your component
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

BorderedRow.defaultProps = {
  onBeginDrag: () => { },
  onEndDrag: () => { },
  onSelectionChange: () => { },
  isSelected: false,
  canDrag: true,
};

BorderedRow.propTypes = {
  canDrag: PropTypes.bool,
  onBeginDrag: PropTypes.func, // eslint-disable-line
  onEndDrag: PropTypes.func, // eslint-disable-line
  isSelected: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]).isRequired,
  tooltip: PropTypes.string.isRequired,
  // DragnDrop props
  connectDragSource: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  dragType: PropTypes.oneOf(Object.values(DragnDrop.lineItem)).isRequired, //eslint-disable-line
  dragProps: PropTypes.object, // eslint-disable-line
  onGetDragProps: PropTypes.func, // eslint-disable-line
  dndPreview: PropTypes.object.isRequired, // eslint-disable-line
};

export default compose(
  DragSource(x => x.dragType, cardSource, collect),
  withStyles(styles),
  withTheme(),
  withDndPreviewContext(),
)(BorderedRow);
