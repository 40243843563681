const styles = {
  toolTipBoxContainer: {
    width: '100%',
    height: 77.4,
    position: 'relative',
  },
  toolTipBox: {
    width: 217,
    height: 77.4,
    borderRadius: 2,
    boxShadow: '0 0 5px 3px rgba(237, 237, 237, 0.5)',
    border: 'solid 1px #d4d4d4',
    backgroundColor: '#ffffff',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    margin: '10px 0 0 0 ',
    fontFamily: 'Arial',
    fontSize: 12,
    color: '#656565',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '0 0 0 10px',
  },
};

export default styles;
