import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { strings } from 'Constants/Categorization/Strings';
import { setFileUploadResponse } from 'Store/Areas/Categorisation/ReferenceListActions';
import { referenceListsSelector } from 'Store/Areas/Categorisation/ReferenceListSelectors';
import UploadFailedModal from 'Components/Shared/Modal/ErrorModal';
import { styles } from './ReferenceListUploadFailedModal.styles';

class ReferenceListUploadFailedModal extends PureComponent {
  onFailedUploadConfirmation = () => {
    const { dispatch } = this.props;
    dispatch(setFileUploadResponse());
  }

  render() {
    const {
      referenceLists,
      classes,
    } = this.props;

    if (!referenceLists.fileUploadResponse.error) { return null; }

    return (
      <UploadFailedModal
        open={!!referenceLists.fileUploadResponse.error}
        onClose={this.onFailedUploadConfirmation}
        title={strings.referenceListUploadFailedTitle}
      >
        <Choose>
          <When condition={referenceLists.fileUploadResponse.importMessages &&
              referenceLists.fileUploadResponse.importMessages.length}
          >
            <ul>
              {// eslint-disable-next-line react/no-array-index-key
                referenceLists.fileUploadResponse.importMessages.map((x, i) => <li key={`import_message_${i}`} className={classes.importMessage}>{x}</li>)
              }
            </ul>
          </When>
          <Otherwise>
            {strings.referenceListUploadFailedBody}
          </Otherwise>
        </Choose>
      </UploadFailedModal>
    );
  }
}

ReferenceListUploadFailedModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  referenceLists: PropTypes.shape({
    fileUploadResponse: PropTypes.shape({
      error: PropTypes.bool,
      duplicates: PropTypes.arrayOf(PropTypes.string),
      cloudReference: PropTypes.string,
    }).isRequired,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

function mapStateToProps(state) {
  return {
    referenceLists: referenceListsSelector(state),
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(ReferenceListUploadFailedModal);
