const styles = theme => ({
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 15,
    marginTop: 15,
  },
  paperContainer: {
    padding: '20px 20px 30px 20px',
    margin: '0 20px 0 20px',
  },
  bottomContainer: {
    padding: '20px 20px 30px 20px',
    margin: '5px 20px 10px 20px',
  },
  description: {
    fontSize: theme.constants.fontSizes.regular,
    lineHeight: 1.5,
    marginRight: 10,
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  arrowButton: {
    flex: 1,
    width: '100%',
    display: 'flex',
  },
  skipButton: {
    width: '100%',
    justifyContent: 'flex-end',
  },
  greyBox: {
    backgroundColor: '#f4f4f4',
    padding: 30,
    marginBottom: 20,
    borderRadius: 4,
  },
  greyBoxTitle: {
    marginTop: 20,
    marginBottom: 10,
    fontSize: theme.constants.fontSizes.small,
  },
  greyBoxDescriptor: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  greyBoxDescriptorBottom: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
  },
  greyBoxData: {
    marginBottom: 5,
    marginTop: 5,
    fontWeight: 'normal',
  },
  textBox: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  input: {
    height: 40,
    minWidth: 230,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  textInput: {
    padding: '10px 0px 10px 10px',
    fontSize: theme.constants.fontSizes.small,
  },
  textArea: {
    fontSize: theme.constants.fontSizes.small,
  },
  thresholdArea: {
    marginLeft: 20,
  },
  proceedButton: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.constants.fontSizes.regular,
    cursor: 'pointer',
    fontWeight: 'bold',
    border: 'none',
    width: '100%',
  },
  buttonDisabled: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    border: 'none',
    opacity: 0.7,
    '&:hover': {
      cursor: 'not-allowed',
    },
    width: '100%',
  },
  disabled: {
    pointerEvents: 'none',
  },
});

export default styles;
