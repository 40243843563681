const styles = {
  successContainer: {
    display: 'flex',
    margin: 'auto',
    width: 500,
    height: 260,
    backgroundColor: 'white',
    opacity: 0.9,
  },
  itemContainer: {
    margin: '0 auto',
  },
};

export default styles;
