import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Input, InputAdornment } from '@material-ui/core';
import MaterialSlider from '@material-ui/lab/Slider';
import styles from './Slider.styles';

class Slider extends React.PureComponent {
  static insertString(oldString, value, start, end) {
    return (
      oldString.substring(0, start) + value + oldString.substring(end || start, oldString.length)
    );
  }

  static round(value, step) {
    const inv = 1.0 / step;
    return Math.round(value * inv) / inv;
  }

  static highlightOnFocus(event) {
    event.target.select();
  }

  constructor(props) {
    super(props);

    this.invalidValue = undefined;
    this.state = {
      value: 0,
    };
  }

  belowMin(value) {
    const { min } = this.props;

    return min !== undefined && value < min;
  }

  aboveMax(value) {
    const { max } = this.props;

    return max !== undefined && value > max;
  }

  handleKeyDown(event) {
    const { target, key, keyCode } = event;
    const newValueString = Slider.insertString(
      target.value.toString(),
      key.toString(),
      target.selectionStart,
      target.selectionEnd,
    );

    const isValidKey =
      keyCode === 8 || // Backspace
      keyCode === 46 || // Delete
      keyCode === 37 || // Left Arrow
      keyCode === 38 || // Up Arrow
      keyCode === 39 || // Right Arrow
      keyCode === 40 || // Down Arrow
      keyCode === 110 || // Numpad Dot
      keyCode === 190 || // Dot
      keyCode === 109 || // Numpad Subtract
      keyCode === 173; // Subtract

    const isValidNumber = !!newValueString.match(/^-?\d\d*(\.\d+)?$/);
    const isInvalidButStillNumber = !!newValueString.match(/^-?(\d\d*\.)?$/);
    this.invalidValue =
      isValidKey && !isValidNumber && isInvalidButStillNumber ? newValueString : undefined;

    return undefined;
  }

  handleChange(event, value) {
    const { invalidValue } = this;
    const { min, max, step } = this.props;
    const targetValue = event.target.value;

    if (invalidValue) {
      this.forceUpdate();
      return;
    }

    let val =
      value !== undefined // eslint-disable-line
        ? value
        : invalidValue
          ? targetValue
          : parseFloat(targetValue);

    if (this.belowMin(val)) {
      val = min;
    } else if (this.aboveMax(val)) {
      val = max;
    }

    val = Slider.round(val, step);

    this.props.onValueChange(val);
  }

  render() {
    const { invalidValue } = this;
    const { value } = this.state;
    const {
      classes,
      className,
      min,
      max,
      step,
      size,
      label,
      startInputAdornment,
      endInputAdornment,
      inputStep,
    } = this.props;

    const validValue = value === 0 || !!value;
    const val = invalidValue || (validValue ? value : min);

    return (
      <div className={`${classes.root} ${className}`}>
        <div className={classes.labelRow}>
          <Typography id="label">{label}</Typography>
          <Input
            className={classes.input}
            classes={{
              input: classes.inputInput,
            }}
            disableUnderline
            value={val}
            onKeyDown={e => this.handleKeyDown(e)}
            onChange={e => this.handleChange(e)}
            onFocus={Slider.highlightOnFocus}
            startAdornment={
              <InputAdornment className={classes.endAdornment} position="start">
                {startInputAdornment}
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment className={classes.endAdornment} position="end">
                {endInputAdornment}
              </InputAdornment>
            }
            inputProps={{
              'aria-label': startInputAdornment + endInputAdornment,
              step: inputStep,
            }}
            type="number"
          />
        </div>
        <MaterialSlider
          aria-labelledby="label"
          className={classes.slider}
          min={min}
          max={size === undefined ? max : size}
          step={step}
          value={validValue ? value : min}
          onChange={(e, v) => this.handleChange(e, v)}
        />
      </div>
    );
  }
}

Slider.getDerivedStateFromProps = (props, state) => {
  const newState = {};

  if (props.value !== state.value) {
    newState.value = props.value;
  }

  return Object.keys(newState).length > 0 ? newState : null;
};

Slider.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  size: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  startInputAdornment: PropTypes.string,
  endInputAdornment: PropTypes.string,
  inputStep: PropTypes.string,
  onValueChange: PropTypes.func.isRequired,
};

Slider.defaultProps = {
  className: '',
  min: 0,
  max: 100,
  step: 0.5,
  size: undefined,
  label: '',
  startInputAdornment: '',
  endInputAdornment: '',
  inputStep: '0.01',
};

export default withStyles(styles)(Slider);
