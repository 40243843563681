import React, { PureComponent, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { Grid, AutoSizer, ScrollSync, InfiniteLoader } from 'react-virtualized';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { commonStrings } from 'Constants/CommonStrings';
import { styles } from './DataGrid.styles';

function createOnSectionRendered(onRowsRendered) {
  return function getOnRowsRendered({ rowStartIndex, rowStopIndex }) {
    return onRowsRendered({ startIndex: rowStartIndex, stopIndex: rowStopIndex });
  };
}

class DataGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.headerRangeRenderer = this.headerRangeRenderer.bind(this);
    this.cellRangeRenderer = this.cellRangeRenderer.bind(this);
    this.isRowLoaded = this.isRowLoaded.bind(this);
    this.cachedFunc = () => { };
    this.cellGridRef = React.createRef();
    this.headerGridRef = React.createRef();
    this.stickyColumnRef = React.createRef();
    this.stickyColumnFaderRef = React.createRef();
    this.scrollTimeout = null;
    this.scrollTop = 0;
    this.scrollLeft = 0;
    this.state = {
      scrollbarWidth: 0,
    };
  }

  componentDidMount() {
    if (this.props.initialLoad) {
      this.props.loadNextPage();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.recomputeGridSizeKey !== this.props.recomputeGridSizeKey) {
      this.recomputeGridSize();
    }
  }

  onGridScroll = ({ scrollLeft, scrollTop, ...rest }) => {
    // clientHeight: number;
    // clientWidth: number;
    // scrollHeight: number;
    // scrollLeft: number;
    // scrollTop: number;
    // scrollWidth: number;

    if (this.scrollTop === scrollTop) {
      this.scrollLeft = scrollLeft;
    } else {
      this.scrollTop = scrollTop;
    }

    return {
      scrollTop: this.scrollTop,
      scrollLeft: this.scrollLeft,
      ...rest,
    };
  }


  setScrollbarWidth = ({ vertical, size }) => {
    if (vertical) {
      this.setState({ scrollbarWidth: size });
    }
  }

  /* eslint-disable react/no-find-dom-node */
  recomputeGridSize() {
    if (this.headerGridRef.current) {
      this.headerGridRef.current.recomputeGridSize();
    }
    if (this.cellGridRef.current) {
      this.cellGridRef.current.recomputeGridSize();
    }
    if (this.stickyColumnFaderRef.current && this.stickyColumnFaderRef.current) {
      const faderDomNode = ReactDOM.findDOMNode(this.stickyColumnFaderRef.current);
      const domNode = ReactDOM.findDOMNode(this.stickyColumnRef.current);
      if (domNode && faderDomNode) {
        domNode.addEventListener('scroll', () => this.handleScroll(faderDomNode));
      }
    }
  }

  /* eslint-disable no-param-reassign */
  handleScroll = (domNode) => {
    domNode.style.opacity = 1;
    domNode.style.transition = 'none';

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      domNode.style.opacity = 0;
      domNode.style.transition = 'opacity 0.7s linear';
    }, 200);
  }

  registerCellGrid = (ref) => {
    this.cellGridRef.current = ref;
  }

  registerHeaderGrid = (ref) => {
    this.headerGridRef.current = ref;
  }

  isRowLoaded({ index }) {
    const { rows, hasNextPage } = this.props;
    return !hasNextPage || index < rows.length;
  }

  headerRangeRenderer(props) {
    const {
      rowSizeAndPositionManager,
      columnSizeAndPositionManager,
      horizontalOffsetAdjustment,
      verticalOffsetAdjustment,
      columnStartIndex,
      columnStopIndex,
    } = props;
    const {
      renderHeaderRow,
      renderHeaderCell,
      columns,
      headerRowHeight,
    } = this.props;

    if (columnStopIndex <= 0) {
      return [];
    }

    const children = [];
    const rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(0);

    for (let column = columnStartIndex; column <= columnStopIndex; column += 1) {
      const columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(column);
      children.push(renderHeaderCell({
        style: {
          height: headerRowHeight,
          width: columnDatum.size,
          display: 'inline-block',
          lineHeight: `${headerRowHeight}px`,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        width: columnDatum.size,
        height: headerRowHeight,
        children: columns[column],
        key: `${column}`,
        index: column,
      }));
    }

    const top = rowDatum.offset + verticalOffsetAdjustment;
    const firstColumnDatum =
      columnSizeAndPositionManager.getSizeAndPositionOfCell(columnStartIndex);
    const stopColumnDatum =
      columnSizeAndPositionManager.getSizeAndPositionOfCell(columnStopIndex);
    const firstColumnLeft =
      firstColumnDatum.offset + horizontalOffsetAdjustment;
    const stopColumnleft =
      stopColumnDatum.offset + horizontalOffsetAdjustment + stopColumnDatum.size;

    return [
      renderHeaderRow({
        style: {
          left: firstColumnLeft,
          width: stopColumnleft - firstColumnLeft,
          position: 'absolute',
          top: top,
          lineHeight: `${headerRowHeight}px`,
          height: headerRowHeight,
        },
        width: stopColumnleft - firstColumnLeft,
        height: rowDatum.size,
        children: children,
        key: 0,
      }),
    ];
  }

  /* eslint-disable no-underscore-dangle */
  cellRangeRenderer(props) {
    const {
      renderRow,
      renderCell,
      getCellValue,
      childRowHeight,
      rowMargin,
    } = this.props;
    const {
      rowStartIndex,
      rowStopIndex,
      rowSizeAndPositionManager,
      columnSizeAndPositionManager,
      horizontalOffsetAdjustment,
      verticalOffsetAdjustment,
      columnStartIndex,
      columnStopIndex,
      parent,
    } = props;
    const clientWidth =
      parent._scrollingContainer ? parent._scrollingContainer.clientWidth - 2 : 0;
    const children = [];
    for (let row = rowStartIndex; row <= rowStopIndex; row += 1) {
      const columns = [];
      const rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(row);
      const top = rowDatum.offset + verticalOffsetAdjustment;
      for (let column = columnStartIndex; column <= columnStopIndex; column += 1) {
        const columnDatum = columnSizeAndPositionManager.getSizeAndPositionOfCell(column);
        columns.push(renderCell({
          style: {
            height: this.dynamicRowHeight({ index: row }) - rowMargin,
            width: columnDatum.size,
            display: 'inline-block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            position: 'relative',
          },
          width: columnDatum.size,
          height: this.dynamicRowHeight({ index: row }) - rowMargin,
          children: getCellValue({ rowIndex: row, columnIndex: column }),
          key: `${row}-${column}`,
          index: column,
          rowIndex: row,
        }));
      }
      const firstColumnDatum =
        columnSizeAndPositionManager.getSizeAndPositionOfCell(columnStartIndex);
      const stopColumnDatum =
        columnSizeAndPositionManager.getSizeAndPositionOfCell(columnStopIndex);
      const firstColumnLeft =
        firstColumnDatum.offset + horizontalOffsetAdjustment;
      const stopColumnleft =
        stopColumnDatum.offset + horizontalOffsetAdjustment + stopColumnDatum.size;

      children.push(renderRow({
        style: {
          left: firstColumnLeft,
          width: stopColumnleft - firstColumnLeft,
          minWidth: clientWidth,
          position: 'absolute',
          top: top,
          height: this.dynamicRowHeight({ index: row }) - rowMargin,
          lineHeight: `${childRowHeight}px`,
        },
        index: row,
        width: stopColumnleft - firstColumnLeft,
        endColumnPosition: stopColumnleft,
        height: this.dynamicRowHeight({ index: row }) - rowMargin,
        children: columns,
        key: row,
      }));
    }

    return children;
  }

  stickyRightCellRangeRenderer = ({
    rowStartIndex,
    rowStopIndex,
    rowSizeAndPositionManager,
    columnSizeAndPositionManager,
    horizontalOffsetAdjustment,
    verticalOffsetAdjustment,
    columnStartIndex,
  }) => {
    const {
      renderStickyCell,
      stickyColumnWidth,
      rowMargin,
    } = this.props;

    const children = [];

    const firstColumnDatum =
      columnSizeAndPositionManager.getSizeAndPositionOfCell(columnStartIndex);
    const firstColumnLeft = firstColumnDatum.offset + horizontalOffsetAdjustment;

    for (let row = rowStartIndex; row <= rowStopIndex; row += 1) {
      const rowHeight = this.dynamicRowHeight({ index: row }) - rowMargin;
      const rowDatum = rowSizeAndPositionManager.getSizeAndPositionOfCell(row);
      const top = rowDatum.offset + verticalOffsetAdjustment;
      children.push(renderStickyCell({
        style: {
          left: firstColumnLeft,
          top: top,
          width: stickyColumnWidth,
          position: 'absolute',
          height: rowHeight,
          lineHeight: `${rowHeight}px`,
          minWidth: stickyColumnWidth,
        },
        index: row,
        width: stickyColumnWidth,
        height: rowHeight,
        key: row,
      }));
    }

    return children;
  }

  dynamicRowHeight = ({ index }) => {
    const { calculatedRowHeight, childRowHeight, rowMargin } = this.props;
    if (calculatedRowHeight === null) {
      return childRowHeight + rowMargin;
    }
    return calculatedRowHeight(index) + rowMargin;
  }

  render() {
    const {
      rows,
      columns,
      classes,
      hasNextPage,
      isNextPageLoading,
      loadNextPage,
      headerRowHeight,
      getWidth,
      sortedByKey,
      fixedHeight,
      fixedWidth,
      headerClass,
      bodyClass,
      showLoadingOverlay,
      noDataMessage,
      showDefaultNoDataMessage,
      stickyColumnWidth,
      stickyBackgroundColor,
    } = this.props;

    const {
      scrollbarWidth,
    } = this.state;

    if (!columns || !rows) {
      return null;
    }

    const rowCount = hasNextPage ? rows.length + 1 : rows.length;
    const loadMoreRows = isNextPageLoading || !hasNextPage ? () => { } : loadNextPage;
    const showNoDataMessage = noDataMessage && rows.length === 0 && !isNextPageLoading;

    return (
      <ScrollSync>
        {({
          onScroll,
          scrollLeft,
          scrollTop,
        }) => {
          return (
            <AutoSizer>
              {({ width, height }) => {
                const fullGridWidth = fixedWidth > 0 ? fixedWidth : width;
                const mainGridHeight = fixedHeight > 0 ? fixedHeight : (height - headerRowHeight);
                const mainGridWidth = fullGridWidth - stickyColumnWidth;
                const headerGridWidth = mainGridWidth - scrollbarWidth;
                return (
                  <Fragment>
                    {/* no data message */}
                    <If condition={showNoDataMessage && showDefaultNoDataMessage}>
                      <div style={{ width: width }}>
                        <div className={classes.message}>
                          {noDataMessage}
                        </div>
                      </div>
                    </If>
                    <If condition={!showNoDataMessage}>
                      <div>
                        {/* Header Grid */}
                        <div>
                          <Grid
                            containerStyle={{
                              marginLeft: stickyColumnWidth,
                            }}
                            ref={this.registerHeaderGrid}
                            className={`${classes.headerGrid} ${headerClass}`}
                            cellRangeRenderer={this.headerRangeRenderer}
                            columnWidth={getWidth}
                            columnCount={columns.length}
                            height={headerRowHeight}
                            width={headerGridWidth + stickyColumnWidth}
                            cellRenderer={this.cachedFunc}
                            rowCount={1}
                            rowHeight={headerRowHeight}
                            scrollLeft={scrollLeft}
                            sortedByKey={sortedByKey}
                          />
                          <div
                            className={classes.blankingContainer}
                            style={{
                              width: stickyColumnWidth,
                              backgroundColor: stickyBackgroundColor,
                            }}
                          />
                        </div>
                        <If condition={rows.length > 0}>
                          <InfiniteLoader
                            isRowLoaded={this.isRowLoaded}
                            loadMoreRows={loadMoreRows}
                            rowCount={rowCount}
                            threshold={10}
                          >
                            {({ onRowsRendered, registerChild }) => {
                              const onSectionRendered = createOnSectionRendered(onRowsRendered);
                              return (
                                <div style={{
                                  minWidth: fullGridWidth,
                                  height: mainGridHeight,
                                  position: 'relative',
                                }}
                                >
                                  {/* Fader overlay for sticky left column */}
                                  <div
                                    ref={this.stickyColumnFaderRef}
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      zIndex: 2,
                                      width: stickyColumnWidth,
                                      height: mainGridHeight,
                                      opacity: 0,
                                      pointerEvents: 'none',
                                      backgroundColor: stickyBackgroundColor,
                                    }}
                                  />
                                  {/* Sticky left column */}
                                  <Grid
                                    containerStyle={{
                                      position: 'absolute',
                                      left: 0,
                                      top: 0,
                                    }}
                                    className={`${classes.hideOutline} ${classes.hideHorizontalScroll} ${classes.hideVerticalScroll}`}
                                    cellRangeRenderer={this.stickyRightCellRangeRenderer}
                                    cellRenderer={this.cachedFunc}
                                    scrollTop={scrollTop}
                                    width={stickyColumnWidth}
                                    columnWidth={stickyColumnWidth}
                                    columnCount={1}
                                    height={mainGridHeight}
                                    rowCount={rows.length}
                                    rowHeight={this.dynamicRowHeight}
                                    ref={this.stickyColumnRef}
                                  />
                                  {/* Main Grid */}
                                  <div style={{
                                    marginLeft: stickyColumnWidth,
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                  }}
                                  >
                                    <Grid
                                      containerStyle={{
                                        minWidth: mainGridWidth,
                                      }}
                                      className={`${classes.hideOutline} ${bodyClass}`}
                                      ref={(r) => { registerChild(r); this.registerCellGrid(r); }}
                                      onSectionRendered={onSectionRendered}
                                      cellRangeRenderer={this.cellRangeRenderer}
                                      cellRenderer={this.cachedFunc}
                                      columnCount={columns.length}
                                      columnWidth={getWidth}
                                      height={mainGridHeight}
                                      rowCount={rows.length}
                                      rowHeight={this.dynamicRowHeight}
                                      width={mainGridWidth}
                                      onScroll={scrollParams =>
                                        onScroll(this.onGridScroll(scrollParams))}
                                      onScrollbarPresenceChange={this.setScrollbarWidth}
                                    />
                                  </div>
                                </div>
                              );
                            }}
                          </InfiniteLoader>
                        </If>
                        {/* Loading message overlay */}
                        <If condition={showLoadingOverlay && isNextPageLoading}>
                          <div
                            className={classes.loadingMask}
                            style={{
                              width: width,
                              height: fixedHeight > 0 ? fixedHeight : (height - headerRowHeight),
                            }}
                          />
                          <div
                            className={classes.loadingBox}
                            style={{
                              top: fixedHeight > 0 ?
                                fixedHeight * 0.3 : (height - headerRowHeight) * 0.3,
                              left: (width / 2) - 100,
                            }}
                          >
                            <div>{commonStrings.loading}</div>
                            <CircularLoader className={classes.loader} size={24} />
                          </div>
                        </If>
                      </div>
                    </If>
                  </Fragment>
                );
              }}
            </AutoSizer>
          );
        }}
      </ScrollSync>
    );
  }
}

DataGrid.defaultProps = {
  rowMargin: 0,
  childRowHeight: 40,
  headerRowHeight: 40,
  getWidth: () => 50,
  stickyColumnWidth: 0,
  isNextPageLoading: false,
  hasNextPage: false,
  showLoadingOverlay: true,
  headerClass: '',
  bodyClass: '',
  loadNextPage: () => { },
  calculatedRowHeight: null,
  initialLoad: false,
  renderHeaderCell: ({ style, children, key }) => { return (<div style={{ ...style }} key={key}>{children}</div>); }, // eslint-disable-line
  renderHeaderRow: ({ style, children, key }) => { return (<div style={{ ...style }} key={key}>{children}</div>); }, // eslint-disable-line
  renderCell: ({ style, children, key }) => { return (<div style={{ ...style }} key={key} title={typeof(children) == typeof 'string' ? children : ''}>{children}</div>); }, // eslint-disable-line
  renderRow: ({ style, children, key }) => { return (<div style={{ ...style }} key={key}>{children}</div>); }, // eslint-disable-line
  renderStickyCell: ({ style, key }) => (<div style={style} key={key} />),// eslint-disable-line
  sortedByKey: '',
  fixedHeight: 0,
  fixedWidth: 0,
  noDataMessage: commonStrings.noResults,
  showDefaultNoDataMessage: true,
  recomputeGridSizeKey: '',
  stickyBackgroundColor: '#ffffff',
};

DataGrid.propTypes = {
  // required props
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  getCellValue: PropTypes.func.isRequired,
  // infinite loader props
  initialLoad: PropTypes.bool,
  hasNextPage: PropTypes.bool,
  isNextPageLoading: PropTypes.bool,
  loadNextPage: PropTypes.func,
  showLoadingOverlay: PropTypes.bool,
  // render & style props
  getWidth: PropTypes.func,
  stickyColumnWidth: PropTypes.number,
  stickyBackgroundColor: PropTypes.string,
  renderHeaderCell: PropTypes.func,
  renderHeaderRow: PropTypes.func,
  renderCell: PropTypes.func,
  renderRow: PropTypes.func,
  renderStickyCell: PropTypes.func,
  childRowHeight: PropTypes.number,
  calculatedRowHeight: PropTypes.func,
  headerRowHeight: PropTypes.number,
  rowMargin: PropTypes.number,
  sortedByKey: PropTypes.string,
  fixedHeight: PropTypes.number,
  fixedWidth: PropTypes.number,
  headerClass: PropTypes.string,
  bodyClass: PropTypes.string,
  noDataMessage: PropTypes.string,
  recomputeGridSizeKey: PropTypes.string,
  // HOC props
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  showDefaultNoDataMessage: PropTypes.bool,
};

export default compose(withStyles(styles), withTheme())(DataGrid);
