import {
  REQUEST_TOGGLE_SITE_LOADER,
  RESET_SITE_LOADER,
} from 'Store/Areas/App/SiteLoaderActions';

const initialState = {
  message: '',
  isLoading: false,
};

export default function siteLoaderReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_TOGGLE_SITE_LOADER:
      return Object.assign({}, state, {
        message: action.message,
        isLoading: action.isLoading,
      });
    case RESET_SITE_LOADER:
      return initialState;
    default:
      return state;
  }
}
