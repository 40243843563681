const styles = theme => ({
  content: {
    padding: 20,
    width: 475,
  },
  title: {
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  message: {
    fontSize: theme.constants.fontSizes.regular,
    marginBottom: 10,
    marginTop: 5,
  },
});

export default styles;
