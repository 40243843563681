import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect as reduxConnect } from 'react-redux';
import { DragSource } from 'react-dnd';
import { withStyles, withTheme, Paper } from '@material-ui/core';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import DragnDrop from 'Constants/DragnDrop';
import { anyTag, TagTypes } from 'Constants/Categorization/Tags';
import { textOperators, joinWords } from 'Constants/Rules/RuleConstants';
import { strings } from 'Constants/Categorization/Strings';
import DragIcon from 'Assets/Images/drag-icon.svg';
import { styles } from './RuleFilterDragLayer.styles';

class RuleFilterDragLayer extends React.PureComponent {
  render() {
    const {
      classes,
      allowDrag,
      connectDragSource,
    } = this.props;

    return (
      <Paper className={classes.paperRoot}>
        <div className={classes.contentRoot} title={strings.dragRuleFilterTooltip}>
          <div className={classes.dragTextWrapper}>
            {allowDrag &&
              connectDragSource(<div className={classes.dragSource} />)
            }
            <span className={classes.dragRuleFilterText}>
              {strings.dragRuleFilterText}
            </span>
            &nbsp;
            <img
              src={DragIcon}
              alt={strings.dragRuleFilterText}
              className={classes.icon}
            />
          </div>
        </div>
      </Paper>
    );
  }
}

RuleFilterDragLayer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  connectDragSource: PropTypes.func.isRequired,
  allowDrag: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  searchTerm: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
  };
}

function getRuleText({ searchTerm, tagFilters, periodTags }) {
  if (searchTerm !== '') {
    return `[${anyTag.name}] ${textOperators.substring} ("${searchTerm}")`;
  }

  const rules = [];
  tagFilters.forEach((tagFilter) => {
    const matchedTag = periodTags.find((periodTag) => {
      return periodTag.id === tagFilter.tagId;
    });
    if (matchedTag !== undefined) {
      let tagSearchTerm = tagFilter.searchTerm;
      if (matchedTag.type !== TagTypes.Numeric) {
        tagSearchTerm = `"${tagFilter.searchTerm}"`;
      }
      rules.push(`[${matchedTag.name}] ${tagFilter.operator} (${tagSearchTerm})`);
    }
  });
  return rules.join(`${` ${joinWords.and} `}`);
}

const spec = {
  beginDrag(props) {
    props.onBeginDrag();

    return {
      text: getRuleText(props),
      type: DragnDrop.customRule,
    };
  },
  endDrag(props, monitor) {
    props.onEndDrag();

    if (monitor.didDrop()) {
      const { onDrop } = monitor.getDropResult();
      onDrop();
    }
  },
};

function collect(connect) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  };
}

export default compose(
  DragSource(DragnDrop.customRule, spec, collect),
  withStyles(styles),
  withTheme(),
  reduxConnect(mapStateToProps),
)(RuleFilterDragLayer);