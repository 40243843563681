export const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: '0 2px 4px 1px rgba(194, 194, 194, 0.5)',
  },
  hidden: {
    display: 'none',
  },
  title: {
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.small,
    flexGrow: 1,
    height: 40,
    lineHeight: '40px',
    paddingLeft: 10,
    whitespace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    height: 40,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    outline: 'none',
  },
  rotate: {
    transform: 'rotate(270deg)',
  },
  content: {
    paddingBottom: 10,
  },
});
