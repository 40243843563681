import React from 'react';
import PropTypes from 'prop-types';
import {
  withTheme,
  withStyles,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import StickyTable from 'Components/Shared/Table/StickyTable';
import SortableHeader from 'Components/Shared/DataGrid/Cells/SortableHeader/SortableHeader';
import { sortFields } from 'Components/Shared/LineItemsProvider/LineItemsProvider';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { formatCurrency } from 'Helpers/NumberHelpers';
import { getRuleLineItems, resetRuleLineItems } from 'Store/Areas/Rule/InspectRuleActions';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import InfiniteScroll from 'react-infinite-scroller';
import { commonStrings } from 'Constants/CommonStrings';
import { formatCellValue } from 'Helpers/LineItemHelpers';
import { sortOrder as sortOrders } from 'Constants/Categorization/QueryTermFilters';
import styles from './LineItemTable.styles';

class LineItemTable extends React.PureComponent {
  componentDidMount() {
    this.loadRuleLineItems(null);
  }

  constructor(props) {
    super(props);
    this.state = {
      sortOrder: sortOrders.valueDescending,
      sortField: sortFields.amount,
      sortTagId: -1,
      sortColumnIndex: -1,
    };
  }

  static getCell(row, col) {
    if (col in row) {
      return formatCellValue(row[col]);
    }
    return '';
  }

  loadRuleLineItems = () => {
    const {
      dispatch,
      periodId,
      ruleId,
      scrollId,
    } = this.props;
    const {
      sortOrder,
      sortField,
      sortTagId,
    } = this.state;
    dispatch(getRuleLineItems(
      periodId,
      ruleId,
      scrollId,
      sortOrder,
      sortField,
      sortTagId,
    ));
  }

  onSort = (colIndex) => {
    const {
      dispatch,
      periodId,
      ruleId,
      ruleLineItemsColumnIds,
    } = this.props;

    const sortingField = this.determineSortField(colIndex);

    this.setState({
      sortField: sortingField,
      sortColumnIndex: colIndex,
    });

    const sortingOrder = this.toggleSortOrder(sortingField);
    const sortingTagId = (sortingField === sortFields.tag) ? ruleLineItemsColumnIds[colIndex] : -1;

    this.setState(
      {
        sortOrder: sortingOrder,
        sortTagId: sortingTagId,
      },
      () => {
        dispatch(resetRuleLineItems());
        dispatch(getRuleLineItems(
          periodId,
          ruleId,
          null,
          sortingOrder,
          sortingField,
          sortingTagId,
        ));
      },
    );
  }

  toggleSortOrder = (colIndex) => {
    const { sortField, sortOrder } = this.state;
    if (sortField === colIndex) {
      return sortOrder === sortOrders.valueAscending ? sortOrders.valueDescending :
        sortOrders.valueAscending;
    }
    return sortOrders.valueDescending;
  }

  determineSortField = (columnIndex) => {
    const { amountColumnIndex } = this.props;

    if (columnIndex === -1) {
      return sortFields.rowId;
    }

    switch (columnIndex) {
      case amountColumnIndex:
        return sortFields.amount;
      default:
        return sortFields.tag;
    }
  }

  render() {
    const {
      ruleLineItemsColumnHeaders,
      ruleLineItemsColumns,
      ruleLineItems,
      classes,
      currencySymbol,
      hasMore,
      loading,
      amountColumnIndex,
    } = this.props;
    const {
      sortOrder,
      sortColumnIndex,
      sortField,
    } = this.state;
    return (
      <div className={classes.scrollContainer}>
        <StickyTable bodyHeight={400}>
          <TableHead>
            <TableRow>
              {ruleLineItemsColumnHeaders.map((col, index) => {
                const headerKey = `${col}_${index}`;
                return (
                  <TableCell
                    className={`${classes.headerText} ${classes.tableCell} 
                      ${col === commonStrings.description ? classes.descriptionCellWidth : classes.otherCellWidth}`}
                    key={headerKey}
                  >
                    {col}
                    <SortableHeader
                      sortOrder={sortOrder}
                      isSorting={index === sortColumnIndex || (index === amountColumnIndex
                        && sortField === sortFields.amount)}
                      onClick={() => this.onSort(index)}
                      width={50}
                      index={index}
                    />
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <InfiniteScroll
            element="tbody"
            initialLoad={false}
            pageStart={0}
            loadMore={this.loadRuleLineItems}
            hasMore={hasMore}
            useWindow={false}
            threshold={10}
          >
            {ruleLineItems.map((row, rowIndex) => {
              const rowKey = rowIndex;
              return (
                <TableRow key={rowKey}>
                  {ruleLineItemsColumns.map((col, cellIndex) => {
                    const cellKey = `${rowKey}_${cellIndex}`;
                      return (
                        <TableCell
                          className={`${classes.tableCell} 
                            ${col === commonStrings.description ? classes.descriptionCellWidth : classes.otherCellWidth}`}
                          key={cellKey}
                        >
                          <If condition={col === commonStrings.amount}>
                            {formatCurrency(row.columns[col].value, currencySymbol)}
                          </If>
                          <If condition={col !== commonStrings.amount}>
                            {LineItemTable.getCell(row.columns, col)}
                          </If>
                        </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </InfiniteScroll>
        </StickyTable>
        <If condition={loading}>
          <div key="loading-icon" className={classes.loaderIcon}>
            <CircularLoader />
          </div>
        </If>
      </div>
    );
  }
}
LineItemTable.defaultProps = {
  scrollId: null,
};

LineItemTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
  ruleId: PropTypes.number.isRequired,
  scrollId: PropTypes.string,
  hasMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  ruleLineItemsColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  ruleLineItemsColumnHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  ruleLineItemsColumnIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  ruleLineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  amountColumnIndex: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: state.periods.period.periodId,
    ruleId: state.rules.inspectRule.inspectedRule.id,
    hasMore: state.rules.inspectRule.hasMore,
    loading: state.rules.inspectRule.loading,
    ruleLineItemsColumns: state.rules.inspectRule.ruleLineItemsColumns,
    ruleLineItemsColumnHeaders: state.rules.inspectRule.ruleLineItemsColumnHeaders,
    ruleLineItemsColumnIds: state.rules.inspectRule.ruleLineItemsColumnIds,
    ruleLineItems: state.rules.inspectRule.ruleLineItems,
    scrollId: state.rules.inspectRule.scrollId,
    amountColumnIndex: state.rules.inspectRule.amountColumnIndex,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(LineItemTable);