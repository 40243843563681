const styles = {
  list: {
    fontWeight: 'normal',
    marginTop: 14,
    maxHeight: 500,
    width: 'inherit',
  },
  listWithContent: {
    overflowY: 'auto',
  },
  loader: {
    height: 'auto !important',
    paddingTop: 5,
  },
  keyword: {
    paddingBottom: 10,
  },
  value: {
    paddingBottom: 10,
    paddingLeft: 10,
  },
};

export default styles;
