import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { goBack } from 'connected-react-router';
import { categorisationLineItemsReviewModes as modes } from 'Constants/Routes';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Sidebar from 'Components/UserSidebar/Sidebar';
import ArrowButton from 'Components/Shared/Buttons/ArrowButton/ArrowButton';
import ContrasReview from 'Components/CategoryReview/ContrasReview/ContrasReview';
import LineItemsCategorisationReview from './LineItemsCategorisationReview';
import { styles } from './LineItemsCategorisationReviewManager.styles';

class LineItemsCategorisationReviewManager extends React.PureComponent {
  onBackClicked = () => {
    this.props.dispatch(goBack());
  }

  render() {
    const {
      classes,
      projects,
      period,
      match: { params: { mode } },
    } = this.props;

    const sidebarLoading = projects.treePicker.loading;

    const mainContentLoading = (
      !period
        || period.loading
        || !period.data);

    return (
      <UserSidebarStructure
        isCollapsible
        collapsed
        sidebarLoading={sidebarLoading}
        mainContentLoading={mainContentLoading}
        sidebarContent={
          <Sidebar />
        }
        mainContent={
          <div className={classes.root}>
            <Choose>
              <When condition={mode === modes.contra}>
                <ContrasReview />
              </When>
              <Otherwise>
                <ArrowButton onClickEvent={this.onBackClicked} />
                <LineItemsCategorisationReview mode={mode} />
              </Otherwise>
            </Choose>
          </div>
        }
      />
    );
  }
}

LineItemsCategorisationReviewManager.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
    }),
  }).isRequired,
  period: PropTypes.shape({
    data: PropTypes.shape({
      categories: PropTypes.array,
    }),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      mode: PropTypes.string,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    period: state.periods.period,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(LineItemsCategorisationReviewManager);
