import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme, Button } from '@material-ui/core';
import { strings } from 'Constants/App/MenuToggle/Strings';
import styles from './MenuToggle.styles';

class MenuToggle extends React.PureComponent {
  onToggleModeButtonClick = () => {
    this.props.clickMenuToggle(!this.props.isAdminMode);
  }

  render() {
    const { classes, isAdminMode } = this.props;
    return (
      <div className={classes.root}>
        <div className={`${classes.switchText}`}>
          {strings.switchTo}
        </div>
        <Button classes={{ root: classes.button }} size="small" onClick={this.onToggleModeButtonClick}>
          {strings.toggleModeButtonText(isAdminMode)}
        </Button>
      </div>
    );
  }
}

MenuToggle.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  clickMenuToggle: PropTypes.func.isRequired,
  isAdminMode: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(MenuToggle);
