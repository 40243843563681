import { takeLatest, put } from 'redux-saga/effects';
import { Post, Get } from 'Store/Api/CallApi';
import {
  POST_ENTITY,
  editLoadEntitySuccess,
  EDIT_ENTITY,
  postEntitySuccess,
  DELETE_ENTITY,
} from 'Store/Areas/Setup/Admin/EntityActions';
import { ADMIN_POST_ENTITY_URL, ADMIN_GET_ENTITY_URL, ADMIN_DELETE_ENTITY } from 'Store/Api/ApiEndpoints';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import {
  getAdminTreePickerData,
  editNode,
} from 'Store/Areas/Setup/Admin/AdminTreePickerActions';
import { levels } from 'Constants/EntityLevels';

function* postEntity(action) {
  const response = yield Post(ADMIN_POST_ENTITY_URL, action.payload);
  if (response.ok) {
    const data = yield response.json();
    if (data.success) {
      yield put(postEntitySuccess());
      yield put(getAdminTreePickerData());
      yield put(editNode(levels.entity, data.addedItem));
    }
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getEntity(action) {
  const response = yield Get(ADMIN_GET_ENTITY_URL(action.payload.id));
  if (response.ok) {
    const data = yield response.json();
    yield put(editLoadEntitySuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* deleteEntity(action) {
  const response = yield Post(ADMIN_DELETE_ENTITY, action.payload);
  if (response.ok) {
    yield put(getAdminTreePickerData(true));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* entitySagas() {
  yield takeLatest(POST_ENTITY, postEntity);
  yield takeLatest(EDIT_ENTITY, getEntity);
  yield takeLatest(DELETE_ENTITY, deleteEntity);
}

