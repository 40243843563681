import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withTheme, withStyles } from '@material-ui/core';
import { commonStrings } from 'Constants/CommonStrings';
import { connect } from 'react-redux';
import { userSessionLogout, setLogout } from 'Store/Areas/App/ConfigActions';
import { downloadUserGuide } from 'Store/Areas/User/PermissionsActions';
import styles from './LayoutActions.styles';

class LayoutActions extends React.PureComponent {
  onHelpClick = () => {
    this.props.dispatch(downloadUserGuide());
  }

  onLogoutClick = () => {
    this.props.dispatch(setLogout(true));
    this.props.dispatch(userSessionLogout());
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <div className={classes.layoutActions}>
          <button onClick={this.onHelpClick}>
            {commonStrings.help}
          </button>
          <span>|</span>
          <button onClick={this.onLogoutClick}>
            {commonStrings.logOut}
          </button>
        </div>
      </div>
    );
  }
}

LayoutActions.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default compose(
  connect(),
  withTheme(),
  withStyles(styles),
)(LayoutActions);

