export const styles = theme => ({
  header: {
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
  },
  cellBorder: {
    borderBottomColor: theme.palette.primary.messageBackground,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    fontSize: theme.constants.fontSizes.regular,
  },
  exportLink: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',
    whiteSpace: 'normal',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  loader: {
    alignSelf: 'center',
    margin: 10,
  },
});

