export const POST_IDENTIFY_CONTRAS = 'CONTRA/POST_IDENTIFY_CONTRAS';
export const GET_REVIEW_CONTRAS = 'CONTRA/GET_REVIEW_CONTRAS';
export const GET_REVIEW_CONTRAS_SUCCESS = 'CONTRA/GET_REVIEW_CONTRAS_SUCCESS';
export const UPDATE_REVIEW_CONTRAS = 'CONTRA/UPDATE_REVIEW_CONTRAS';
export const UPDATE_REVIEW_CONTRAS_SUCCESS = 'CONTRA/UPDATE_REVIEW_CONTRAS_SUCCESS';
export const POST_REMOVE_CONTRA = 'CONTRA/POST_REMOVE_CONTRA';
export const POST_REMOVE_CONTRA_RERUN_CATEGORISATION = 'CONTRA/POST_REMOVE_CONTRA_RERUN_CATEGORISATION';
export const POST_REMOVE_CONTRA_SUCCESS = 'CONTRA/POST_REMOVE_CONTRA_SUCCESS';
export const UPDATE_SEARCH_TERM = 'CONTRA/UPDATE_SEARCH_TERM';
export const RESET_REVIEW_CONTRAS = 'CONTRA/RESET_REVIEW_CONTRAS';

export function postIdentifyContras(periodId, tagIds, maxLineItemsInContra) {
  return {
    type: POST_IDENTIFY_CONTRAS,
    payload: {
      periodId: periodId,
      tagIds: tagIds,
      maxLineItemsInContra: maxLineItemsInContra,
    },
  };
}

export function getReviewContras(periodId, scrollId, sortOrder, searchTerm, hasSearched) {
  return {
    type: GET_REVIEW_CONTRAS,
    loading: true,
    hasSearched,
    payload: {
      periodId: periodId,
      scrollId: scrollId,
      sortOrder: sortOrder,
      searchTerm: searchTerm,
    },
  };
}

export function getReviewContrasSuccess({
  completeGroups,
  incompleteGroups,
  scrollId,
  columns,
  columnHeaders,
  hasMore,
  totalContras,
  totalLinesWithinContras,
}, periodId) {
  return {
    type: GET_REVIEW_CONTRAS_SUCCESS,
    loading: false,
    scrollId: scrollId,
    completeGroups: completeGroups,
    incompleteGroups: incompleteGroups,
    columns: columns,
    columnHeaders: columnHeaders,
    hasMore: hasMore,
    totalContras: totalContras,
    totalLinesWithinContras: totalLinesWithinContras,
    periodId: periodId,
  };
}

export function updateReviewContras(periodId, scrollId, sortOrder, searchTerm, hasSearched) {
  return {
    type: UPDATE_REVIEW_CONTRAS,
    loading: true,
    sortOrder: sortOrder,
    hasSearched: hasSearched,
    payload: {
      periodId: periodId,
      scrollId: scrollId,
      sortOrder: sortOrder,
      searchTerm: searchTerm,
    },
  };
}

export function updateReviewContrasSuccess({
  completeGroups,
  incompleteGroups,
  scrollId,
  columns,
  columnHeaders,
  hasMore,
}, periodId) {
  return {
    type: UPDATE_REVIEW_CONTRAS_SUCCESS,
    loading: false,
    scrollId: scrollId,
    completeGroups: completeGroups,
    incompleteGroups: incompleteGroups,
    columns: columns,
    columnHeaders: columnHeaders,
    hasMore: hasMore,
    periodId: periodId,
  };
}

export function removeContra(periodId, contraId) {
  return {
    type: POST_REMOVE_CONTRA,
    submitting: true,
    payload: {
      periodId: periodId,
      contraId: contraId,
    },
  };
}

export function removeContraAndRerunCategorisation(periodId, contraId) {
  return {
    type: POST_REMOVE_CONTRA_RERUN_CATEGORISATION,
    submitting: true,
    payload: {
      periodId: periodId,
      contraId: contraId,
    },
  };
}

export function removeContraSuccess(contraId) {
  return {
    type: POST_REMOVE_CONTRA_SUCCESS,
    submitting: false,
    contraId: contraId,
  };
}

export function updateSearchTerm(searchTerm) {
  return {
    type: UPDATE_SEARCH_TERM,
    searchTerm: searchTerm,
  };
}

export function resetReviewContras() {
  return {
    type: RESET_REVIEW_CONTRAS,
  };
}
