import React from 'react';
import { Input, InputAdornment, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { commonStrings } from 'Constants/CommonStrings';
import SearchIcon from 'Assets/Images/search-ic.svg';
import CloseIcon from 'Assets/Images/exit-ic.svg';
import styles from './Search.styles';

class Search extends React.Component {
  state = {
    hasSearched: false,
  };

  handleChange = ({ target: { value: fieldValue } }) => {
    let newValue = fieldValue.replace('"', '');

    if (!newValue.trim()) {
      newValue = '';
    }

    if (this.props.value === newValue) {
      return;
    }

    this.props.onChange(newValue, this.props.id);

    if (newValue.length === 0) {
      this.setState({
        hasSearched: false,
      });
    }
  }

  search() {
    const { value } = this.props;

    this.props.onSearch(value, this.props.id);

    if (value.length > 0) {
      this.setState({
        hasSearched: true,
      });
    }
  }

  handleKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      this.search();
    }
  }

  handleIconClick = () => {
    if (this.props.value.length > 0 && this.state.hasSearched) {
      this.props.onReset(this.props.id);
      this.setState({
        hasSearched: false,
      });
    } else {
      this.search();
    }
  }

  renderInputAdornment(classes, value) {
    const renderSearchIcon = value.length === 0 || !this.state.hasSearched;

    return (
      <InputAdornment position="end" className={classes.icon} >
        <div className={classes.searchIconWrapper}>
          {
            <Choose>
              <When condition={this.props.renderInputAdornment}>
                {this.props.renderInputAdornment(renderSearchIcon)}
              </When>
              <Otherwise>
                <button
                  onClick={this.handleIconClick}
                  className={classes.searchButton}
                >
                  <With
                    alt={renderSearchIcon ? commonStrings.search : commonStrings.cancel}
                    icon={renderSearchIcon ? SearchIcon : CloseIcon}
                  >
                    <img
                      className={classes.img}
                      alt={alt}
                      title={alt}
                      src={icon}
                    />
                  </With>
                </button>
              </Otherwise>
            </Choose>
          }
        </div>
      </InputAdornment>
    );
  }

  render() {
    const {
      classes,
      value,
    } = this.props;

    return (
      <div className={`${classes.root} ${this.props.className}`}>
        <div className={classes.label}>{this.props.label}</div>
        <Input
          classes={{
            root: classes.input,
          }}
          autoComplete="off"
          type={this.props.type}
          id={this.props.id}
          placeholder={this.props.placeholder}
          onChange={this.handleChange}
          value={value}
          fullWidth
          disableUnderline
          onKeyPress={this.handleKeyPress}
          endAdornment={this.renderInputAdornment(classes, value)}
        />
      </div>
    );
  }
}

Search.defaultProps = {
  label: '',
  placeholder: '',
  className: '',
  renderInputAdornment: null,
  type: 'text',
  onReset: () => {},
};

Search.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  type: PropTypes.string,
  renderInputAdornment: PropTypes.func,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(Search);
