import { takeLatest, put } from 'redux-saga/effects';
import {
  GET_NEXT_CONFLICT,
  PUT_ALLOW_CONFLICT,
  PUT_ALLOW_ALL_CONFLICT,
  PUT_RESOLVE_CONFLICT,
  PUT_DELETE_CONFLICT,
  getNextConflictSuccess,
} from 'Store/Areas/Conflict/ConflictActions';
import { Get, Post } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import {
  RULE_GET_NEXT_CONFLICT,
  RULE_PUT_RESOLVE_CONFLICT,
  RULE_PUT_ALLOW_CONFLICT,
  RULE_PUT_ALLOW_ALL_CONFLICT,
  RULE_REQUEST_DELETE_RULE,
} from 'Store/Api/ApiEndpoints';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';

function* getNextConflictAction(action) {
  const response = yield Get(RULE_GET_NEXT_CONFLICT(action.payload.periodId));
  if (response.ok) {
    const data = yield response.json();
    yield put(getNextConflictSuccess(action.payload.periodId, data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* handleRuleConflictAction(endpoint, action) {
  yield put(setProcessing(true));
  const response = yield Post(endpoint, action.payload);
  if (!response.ok) {
    yield handleStatusCode(response.status);
  }
}

export function* conflictSagas() {
  yield takeLatest(GET_NEXT_CONFLICT, getNextConflictAction);
  yield takeLatest(PUT_RESOLVE_CONFLICT, handleRuleConflictAction, RULE_PUT_RESOLVE_CONFLICT);
  yield takeLatest(PUT_ALLOW_CONFLICT, handleRuleConflictAction, RULE_PUT_ALLOW_CONFLICT);
  yield takeLatest(PUT_ALLOW_ALL_CONFLICT, handleRuleConflictAction, RULE_PUT_ALLOW_ALL_CONFLICT);
  yield takeLatest(PUT_DELETE_CONFLICT, handleRuleConflictAction, RULE_REQUEST_DELETE_RULE);
}
