import { takeLatest, put } from 'redux-saga/effects';
import { Get, Post } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { responseStatusCodes } from 'Store/Api/StatusCodes';
import { LINE_ITEM_GET_HIGHEST_VALUE_LINE_ITEM, ML_GET_GRAPH_DATA, ML_GET_CATEGORISATION_REASON, ML_GET_MAX_LINE_ITEM_THRESHOLD } from 'Store/Api/ApiEndpoints';
import {
  GET_HIGHEST_VALUE_LINE_ITEM,
  getHighestValueLineItemSuccess,
  GET_GRAPH_DATA,
  getGraphDataSuccess,
  GET_CATEGORISATION_LOGIC,
  GET_PERIOD_LINE_ITEM_THRESHOLD,
  getPeriodLineItemThresholdSuccess,
  getGraphData,
} from './LineItemValueActions';

/* Worker Sagas */
function* getHighestValueSaga(action) {
  const response = yield Get(LINE_ITEM_GET_HIGHEST_VALUE_LINE_ITEM(action.payload.periodId));
  if (response.ok) {
    const data = yield response.json();
    yield put(getHighestValueLineItemSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getPeriodLineItemValueSaga(action) {
  const response = yield Get(ML_GET_MAX_LINE_ITEM_THRESHOLD(action.payload.periodId));
  if (response.ok) {
    const data = yield response.json();
    yield put(getPeriodLineItemThresholdSuccess(data));
    yield put(getGraphData(action.payload.periodId, data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getDistributionGraphSaga(action) {
  const response = yield Post(ML_GET_GRAPH_DATA, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getGraphDataSuccess(data));
    yield put(getPeriodLineItemThresholdSuccess(action.payload.lineItemValueThreshold));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getCategorisationLogicSaga(action) {
  const response = yield Post(ML_GET_CATEGORISATION_REASON, action.payload);
  if (!response.ok) {
    yield handleStatusCode(response.status === responseStatusCodes.ok
      ? responseStatusCodes.badRequest : response.status);
  }
}

/* Watcher Sagas */
export function* lineItemValueSagas() {
  yield takeLatest(GET_HIGHEST_VALUE_LINE_ITEM, getHighestValueSaga);
  yield takeLatest(GET_GRAPH_DATA, getDistributionGraphSaga);
  yield takeLatest(GET_CATEGORISATION_LOGIC, getCategorisationLogicSaga);
  yield takeLatest(GET_PERIOD_LINE_ITEM_THRESHOLD, getPeriodLineItemValueSaga);
}
