import { formatCurrencyString } from 'Helpers/NumberHelpers';

export const strings = {
  machineLearningTitle: 'Machine Learning Categorisation',
  machineLearningSetup: 'Machine learning setup',
  machineLearningSkip: 'Skip machine learning categorisation',
  machineLearningSetupNote: 'You are about to be taken to the machine learning (ML) categorisation steps. In order to proceed please set a value threshold. ' +
    'This threshold will only apply the machine learning to line items below this limit. High value items can therefore be dealt with manually. ' +
    'Use the arrows to change the value by increments of 10,000.',
  machineLearningApportionmentMessage:
    'You are about to be taken to the machine learning (ML) categorisation steps.' +
    'In order to proceed please set a threshold. The Machine Learning model will only be applied to line items that are equal to or below this threshold.' +
    ' High value items can therefore be dealt with manually.',
  machineLearningApply: 'Apply threshold',
  machineLearningCancel: 'Skip ML categorisation',
  machineLearningModel: 'Machine learning model',
  machineLearningSelectedModel: 'Key facts about the selected model',
  description: 'Description:',
  categories: 'Categories:',
  machineLearningDescription:
    'The line items have all been assigned a suggested category and a confidence level. ' +
    'Please select a confidence level threshold. All data above this threshold will be ' +
    'categorised automatically on being processed according ' +
    'to their assigned category. All line items below this threshold will be available ' +
    'in next steps to be categorise using keyword rules.',
  machineLearningLineLimit: 'Line Item threshold',
  machineLearningModalPlaceholder: 'Enter a line item value threshold',
  machineLearningModelSelectPlaceholder: 'Select a template',
  tooltipConfidenceRange: 'Confidence level  range',
  tooltipTotalValue: 'Total value',
  tooltipAbsValue: 'Absolute value',
  tooltipLineItems: 'Line items',
  yAxisLabel: 'Number of Line Items',
  xAxisLabel: 'Confidence Level %',
  lineItemResults: 'Line item results',
  lineItemsButtonNote: 'You can view the keyword that was used to assign each individual line item to a specified category.  To do so, select the line items that you would like to inspect and click the button to the right hand side.',
  showCategorisationLogic: 'Show categorisation logic',
};

export function valueString(amount, currencySymbol) {
  return `Value: ${formatCurrencyString(amount, currencySymbol)}`;
}

export function noteValue(amount, currencySymbol) {
  return `Maximum line item value is ${formatCurrencyString(amount, currencySymbol)}`;
}
