import { takeLatest, put, select } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { Post } from 'Store/Api/CallApi';
import { IMPORT_IDENTIFY_CONTRAS, CONTRA_GET_REVIEW_CONTRAS, CONTRA_POST_REMOVE_CONTRAS, CONTRA_POST_REMOVE_CONTRAS_RERUN_CATEGORISATION } from 'Store/Api/ApiEndpoints';
import {
  POST_IDENTIFY_CONTRAS,
  GET_REVIEW_CONTRAS,
  getReviewContrasSuccess,
  getReviewContras,
  GET_REVIEW_CONTRAS_SUCCESS,
  POST_REMOVE_CONTRA,
  POST_REMOVE_CONTRA_RERUN_CATEGORISATION,
  UPDATE_REVIEW_CONTRAS,
  updateReviewContras,
  updateReviewContrasSuccess,
  UPDATE_REVIEW_CONTRAS_SUCCESS,
  removeContraSuccess,
} from 'Store/Areas/Import/ContraActions';
import { reportProcessingProgress } from 'Store/Areas/Import/ProcessActions';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';
import { startPolling } from 'Store/Areas/App/PeriodStatusPollingActions';
import { routes } from 'Constants/Routes';
import { importStages } from 'Constants/Processing/ImportStages';
import { push } from 'connected-react-router';

function* postIdentifyContrasSaga(action) {
  const response = yield Post(IMPORT_IDENTIFY_CONTRAS, action.payload);
  if (response.ok) {
    yield put(reportProcessingProgress('', importStages.identifyingContras));
    yield put(push(routes.processing));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* matchIncompleteGroups(apiIncompleteGroups) {
  const result = {
    completeGroups: [],
    incompleteGroups: [],
  };

  if (!apiIncompleteGroups || apiIncompleteGroups.length === 0) {
    return result;
  }

  const stateIncompleteGroups = yield select(state => state.import.contras.incompleteGroups);

  const matchedIds = [];

  stateIncompleteGroups.forEach((stateGroup) => {
    if (!stateGroup) {
      return;
    }

    const apiGroup = apiIncompleteGroups.find(x => x.contraId === stateGroup.contraId);
    if (!apiGroup) {
      result.incompleteGroups.push(stateGroup);
    } else {
      const joinedGroup = {
        contraId: stateGroup.contraId,
        documents: stateGroup.documents.concat(apiGroup.documents),
        groupCount: stateGroup.groupCount,
      };

      joinedGroup.isFullyPopulated = joinedGroup.documents.length === joinedGroup.groupCount;

      matchedIds.push(apiGroup.contraId);

      if (joinedGroup.isFullyPopulated) {
        result.completeGroups.push(joinedGroup);
      } else {
        result.incompleteGroups.push(joinedGroup);
      }
    }
  });

  result.incompleteGroups = result.incompleteGroups.concat(apiIncompleteGroups.filter(g =>
    !matchedIds.includes(g.contraId)));

  return result;
}

function* getReviewContrasSaga(action) {
  const response = yield Post(CONTRA_GET_REVIEW_CONTRAS, action.payload);
  if (response.ok) {
    const data = yield response.json();

    const {
      contraGroups,
      incompleteContraGroups,
      hasMore,
      scrollId,
      totalContras,
      totalLinesWithinContras,
      ...rest
    } = data;
    const matchedGroups = yield matchIncompleteGroups(incompleteContraGroups);
    yield put(getReviewContrasSuccess({
      completeGroups: matchedGroups.completeGroups.concat(contraGroups),
      incompleteGroups: matchedGroups.incompleteGroups,
      hasMore: hasMore,
      scrollId: scrollId,
      totalContras: totalContras,
      totalLinesWithinContras: totalLinesWithinContras,
      ...rest,
    }, action.payload.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* updateReviewContrasSaga(action) {
  const response = yield Post(CONTRA_GET_REVIEW_CONTRAS, action.payload);
  if (response.ok) {
    const data = yield response.json();

    const {
      contraGroups, incompleteContraGroups, hasMore, scrollId, ...rest
    } = data;
    const matchedGroups = yield matchIncompleteGroups(incompleteContraGroups);

    yield put(updateReviewContrasSuccess({
      completeGroups: matchedGroups.completeGroups.concat(contraGroups),
      incompleteGroups: matchedGroups.incompleteGroups,
      hasMore: hasMore,
      scrollId: scrollId,
      ...rest,
    }, action.payload.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getReviewContrasSuccessSaga(action) {
  if (action.completeGroups.length === 0 &&
    action.hasMore &&
    action.incompleteGroups.length === 1
  ) {
    yield put(getReviewContras(action.periodId, action.scrollId));
  }
}

function* updateReviewContrasSuccessSaga(action) {
  if (action.completeGroups.length === 0 &&
    action.hasMore &&
    action.incompleteGroups.length === 1
  ) {
    yield put(updateReviewContras(action.periodId, action.scrollId));
  }
}

function* postRemoveContra(action) {
  const response = yield Post(CONTRA_POST_REMOVE_CONTRAS, action.payload);
  if (response.ok) {
    yield put(removeContraSuccess(action.payload.contraId));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* postRemoveContraRerunCategorisation(action) {
  yield put(setProcessing(true));

  const response = yield Post(CONTRA_POST_REMOVE_CONTRAS_RERUN_CATEGORISATION, action.payload);
  if (response.ok) {
    yield put(startPolling(action.payload.periodId));
    yield put(push(routes.categorisation.referenceLists));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* contraWatcher() {
  yield takeLatest(POST_IDENTIFY_CONTRAS, postIdentifyContrasSaga);
  yield takeLatest(GET_REVIEW_CONTRAS, getReviewContrasSaga);
  yield takeLatest(GET_REVIEW_CONTRAS_SUCCESS, getReviewContrasSuccessSaga);
  yield takeLatest(POST_REMOVE_CONTRA, postRemoveContra);
  yield takeLatest(POST_REMOVE_CONTRA_RERUN_CATEGORISATION, postRemoveContraRerunCategorisation);
  yield takeLatest(UPDATE_REVIEW_CONTRAS, updateReviewContrasSaga);
  yield takeLatest(UPDATE_REVIEW_CONTRAS_SUCCESS, updateReviewContrasSuccessSaga);
}
