const styles = theme => ({
  headerClass: {
    marginTop: 5,
    backgroundColor: theme.palette.colors.white,
  },
  bodyClass: {
    marginBottom: 20,
  },
  imgButton: {
    paddingBottom: '8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  tableCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: theme.constants.fontSizes.small,
    height: 39,
    backgroundColor: theme.palette.colors.white,
    marginBottom: 6,
    boxShadow: '0 2px 0 0 rgba(194, 194, 194, 0.5)',
    borderBottom: '10px solid #efefef',
  },
  ruleConditionCell: {
    position: 'relative',
  },
  ruleNote: {
    position: 'absolute',
    top: 0,
    right: 10,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 11px 11px 0',
    borderColor: 'transparent red transparent transparent',
    display: 'inline-block',
  },
  searchContainer: {
    marginTop: 20,
    padding: 10,
  },
  searchBox: {
    maxWidth: 400,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.colors.black,
  },
});

export default styles;
