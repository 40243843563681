export const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    padding: 30,
  },
  headerToolbar: {
    padding: 0,
  },
  title: {
    flex: 'auto',
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.large,
  },
  closeButton: {
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  closeIcon: {
    height: '30px',
    width: '30px',
  },
});
