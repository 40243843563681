import SSF from 'ssf';

export function formatCellValue({ format, value } = {}) {
  if (!format && !value) {
    return '';
  }

  if (format) {
    try {
      return SSF.format(format, value);
    } catch (e) {
    // Ignore formatting errors.
    }
  }
  return value;
}
