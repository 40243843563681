import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from 'recharts';
import { withStyles, withTheme } from '@material-ui/core';
import { getMethodOfCategorisation } from 'Store/Areas/Review/MethodOfCategorisationActions';
import { strings } from 'Constants/Export/Strings';
import { styles } from './RevCounter.styles';

class RevCounter extends React.PureComponent {
  componentDidMount() {
    const { dispatch, periodId } = this.props;
    dispatch(getMethodOfCategorisation(periodId));
  }

  getRevCounterData = () => {
    const { graphData } = this.props;
    return [
      { name: strings.legendMl, value: graphData.machineLearningCount },
      { name: strings.legendManual, value: graphData.manualCount },
      { name: strings.legendRules, value: graphData.ruleCount },
      { name: strings.legendUncategorised, value: graphData.uncategorisedCount },
    ];
  }

  revCounterColours = () => {
    const { theme } = this.props;
    return [
      theme.palette.graphs.blue,
      theme.palette.graphs.orange,
      theme.palette.graphs.green,
      theme.palette.graphs.grey,
    ];
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <ResponsiveContainer height={280} width="100%">
          <PieChart>
            <Pie
              dataKey="value"
              isAnimationActive
              animationDuration={800}
              data={this.getRevCounterData()}
              startAngle={180}
              endAngle={0}
              innerRadius="60%"
              outerRadius="100%"
              paddingAngle={0}
              cy={165}
              cx="55%"
              legendType="circle"
            >
              {
                this.getRevCounterData().map((entry, index) =>
                  <Cell key={entry.name} fill={this.revCounterColours()[index % 4]} stroke={0} />)
              }
            </Pie>
            <Legend
              wrapperStyle={{ fontSize: theme.constants.fontSizes.small, lineHeight: 1.8 }}
              layout="vertical"
              verticalAlign="top"
              align="right"
              iconSize={11}
            />
            <Tooltip isAnimationActive={false} />
          </PieChart>
        </ResponsiveContainer>
      </div>);
  }
}

RevCounter.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      graphs: PropTypes.shape({
        blue: PropTypes.string,
        orange: PropTypes.string,
        green: PropTypes.string,
        grey: PropTypes.string,
      }),
    }),
    constants: PropTypes.shape({
      fontSizes: PropTypes.shape({
        small: PropTypes.number,
      }),
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  graphData: PropTypes.objectOf(PropTypes.number).isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: state.periods.period.periodId,
    graphData: state.review.methodOfCategorisation.data,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(RevCounter);
