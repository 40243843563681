import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { Post } from 'Store/Api/CallApi';
import {
  GET_RULE_LINE_ITEMS,
  getRuleLineItemsSuccess,
} from 'Store/Areas/Rule/InspectRuleActions';
import { LINE_ITEM_GET_LINE_ITEMS } from 'Store/Api/ApiEndpoints';

function* getRuleLineItemsSaga(action) {
  const response = yield Post(LINE_ITEM_GET_LINE_ITEMS, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getRuleLineItemsSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* inspectRuleSagas() {
  yield takeLatest(GET_RULE_LINE_ITEMS, getRuleLineItemsSaga);
}
