import { takeLatest, put, select } from 'redux-saga/effects';
import {
  getPeriod,
  resetPeriod,
  resetPeriodInUse,
  connectToSignalRPeriodHub,
} from 'Store/Areas/Period/PeriodActions';
import { levels } from 'Constants/EntityLevels';
import {
  UPDATE_TREEPICKER,
  selectNode,
  resetGroup,
  resetEntity,
  resetProject,
  SELECT_PERIOD,
} from './UserTreePickerActions';

/* Worker Sagas */
function* onSelectNode(action) {
  const currentGroupId = action.userTreePicker.selectedGroupId;
  const currentEntityId = action.userTreePicker.selectedEntityId;
  const currentProjectId = action.userTreePicker.selectedProjectId;

  const {
    selectedGroupId,
    selectedEntityId,
    selectedProjectId,
    selectedItemLevel,
  } = action;

  if (selectedItemLevel === levels.group &&
    selectedGroupId === currentGroupId) {
    yield put(resetGroup());
  } else if (selectedItemLevel === levels.entity &&
    selectedEntityId === currentEntityId) {
    yield put(resetEntity(selectedGroupId));
  } else if (selectedItemLevel === levels.project &&
    selectedProjectId === currentProjectId) {
    yield put(resetProject(selectedGroupId, selectedEntityId));
  } else {
    yield put(selectNode(selectedGroupId, selectedEntityId, selectedProjectId, selectedItemLevel));
  }
}

function* selectPeriod(action) {
  const { previousPeriodId, isProjectReadOnlyUser } = yield select(state => ({
    previousPeriodId: state.periods.period.periodId,
    isProjectReadOnlyUser: state.periods.period.data.isProjectReadOnlyUser,
  }));
  if (previousPeriodId !== 0 && previousPeriodId !== action.selectedPeriodId
    && !isProjectReadOnlyUser) {
    yield put(resetPeriodInUse(previousPeriodId));
  }
  yield put(resetPeriod());
  yield put(connectToSignalRPeriodHub(action.selectedPeriodId));
  yield put(getPeriod(action.selectedPeriodId));
}

/* Watcher Sagas */
export function* userTreePickerSagas() {
  yield takeLatest(SELECT_PERIOD, selectPeriod);
  yield takeLatest(UPDATE_TREEPICKER, onSelectNode);
}
