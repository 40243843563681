const styles = theme => ({
  loader: {
    marginBottom: 15,
    overflow: 'hidden',
  },
  overlayDialogPaper: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    padding: 30,
  },
  closeIcon: {
    height: '30px',
    width: '30px',
  },
  closeButton: {
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    flex: 'auto',
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.large,
  },
  headerToolbar: {
    padding: 0,
  },
  headerContainer: {
    width: '100%',
    minHeight: '200px',
    padding: 15,
    backgroundColor: 'rgba(0, 51, 141, 0.12)',
    flexWrap: 'nowrap',
  },
  headerButton: {
    width: 180,
    height: 40,
    margin: '10px 10px 10px 0',
  },
  headerText: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  mainText: {
    fontWeight: 'normal',
    fontSize: theme.constants.fontSizes.regular,
  },
  noteContainer: {
    padding: '32px 0',
  },
  content: {
    padding: 20,
    height: 'Calc(100vh - 200px)',
    overflowY: 'auto',
    display: 'flex',
  },
  innerContent: {
    flexGrow: 1,
  },
  operators: {
    width: '30%',
  },
  grid: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  builder: {
    width: '70%',
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    width: 'calc(100% - 55px)',
  },
  textSize: {
    fontSize: theme.constants.fontSizes.small,
  },
  boldText: {
    fontWeight: 'bold',
  },
  categoriesListWrapper: {
    overflowY: 'auto',
    height: '100%',
    overflowX: 'hidden',
  },
  rulesBuilderWrapper: {
    flexShrink: 0,
    flexBasis: '500px',
  },
  ruleInfoWrapper: {
    flexGrow: 0,
    flexShrink: 0,
    fontSize: theme.constants.fontSizes.small,
  },
  ruleInfo: {
    marginTop: 10,
    marginBottom: 15,
  },
  ruleInfoList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '10px',
    '& > div': {
      position: 'relative',
      paddingLeft: '10px',
      '&::before': {
        top: 'calc(50% - 3px)',
        content: '" "',
        position: 'absolute',
        backgroundColor: 'black',
        left: '0px',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
      },
    },
  },
});

export default styles;
