const styles = () => ({
  loaderContainer: {
    position: 'absolute',
    bottom: '50%',
    left: 160,
    right: 0,
  },
  message: {
    textAlign: 'center',
    paddingTop: 20,
  },
});

export default styles;
