import {
  GET_IMPORT_FAILED_RULES,
  GET_IMPORT_FAILED_RULES_SUCCESS,
} from 'Store/Areas/Import/ImportFailedRulesActions';

const initialState = {
  loading: false,
  failedRules: [],
};

export default function importFailedRulesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_IMPORT_FAILED_RULES:
      return Object.assign({}, state, {
        loading: action.payload.loading,
      });
    case GET_IMPORT_FAILED_RULES_SUCCESS:
      return Object.assign({}, state, {
        loading: action.payload.loading,
        failedRules: action.payload.failedRules,
      });
    default:
      return state;
  }
}
