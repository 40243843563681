import { ADD_SIGNALR_SIDE_EFFECT, RESET_SIGNALR_SIDE_EFFECTS } from 'Store/Areas/App/SignalrSideEffectActions';

const initialState = {
  periodStatusTrigger: null,
  payload: null,
};

export default function signalrSideEffectReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_SIGNALR_SIDE_EFFECT:
      return Object.assign({}, state, {
        periodStatusTrigger: action.periodStatusTrigger,
        payload: action.payload,
      });
    case RESET_SIGNALR_SIDE_EFFECTS:
      return initialState;
    default:
      return state;
  }
}
