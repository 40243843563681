const styles = ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '93%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  slider: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

export default styles;
