import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { chunkTypes } from 'Store/Areas/Rule/RuleBuilderReducer';
import { strings } from 'Constants/Rules/Strings';
import { styles } from './BuilderBrackets.styles';
import { BuilderRowDropzone } from '../';

class BuilderBrackets extends React.PureComponent {
  render() {
    const {
      type,
      classes,
      setIsOver,
      index,
      isOver,
    } = this.props;

    return (
      <div className={`${classes.root} ${isOver ? classes.isOver : ''}`}>
        <BuilderRowDropzone
          setIsOver={setIsOver}
          index={index}
          isOver={isOver}
        />
        {type === chunkTypes.startBracket ? strings.openBrackets : strings.closeBrackets}
      </div>
    );
  }
}

BuilderBrackets.propTypes = {
  type: PropTypes.oneOf([chunkTypes.endBracket, chunkTypes.startBracket]).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  setIsOver: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isOver: PropTypes.bool.isRequired,
};

export default withStyles(styles)(BuilderBrackets);
