import { referenceListFileUploadHandler } from 'Store/Areas/Signalr/Handlers/ReferenceListFileUploadHandler';
import { periodFileUploadHandler } from 'Store/Areas/Signalr/Handlers/PeriodFileUploadHandler';
import { customReportFileUploadHandler } from 'Store/Areas/Signalr/Handlers/CustomReportFileUploadHandler';
import { archiveHandler } from 'Store/Areas/Signalr/Handlers/ArchiveHandler';
import { deleteRuleHandler } from 'Store/Areas/Signalr/Handlers/DeleteRuleHandler';
import { importProgressHandler } from 'Store/Areas/Signalr/Handlers/ImportProgressHandler';
import { exportHandler } from 'Store/Areas/Signalr/Handlers/ExportHandler';
import { genericCategorisationHandler } from 'Store/Areas/Signalr/Handlers/CategorisationHandlers';
import { genericConflictHandler } from 'Store/Areas/Signalr/Handlers/ConflictHandlers';
import { mlCategorisationReasonHandler } from 'Store/Areas/Signalr/Handlers/MlCategorisationReasonHandler';
import { externalReviewFileUploadHandler } from 'Store/Areas/Signalr/Handlers/ExternalReviewFileUploadHandler';
import { externalReviewFileImportHandler } from 'Store/Areas/Signalr/Handlers/ExternalReviewFileImportHandler';
import { deleteSupportingDocHandler } from 'Store/Areas/Signalr/Handlers/DeleteSupportingDocHandler';
import { genericApportionablesHandler } from 'Store/Areas/Signalr/Handlers/ApportionablesHandler';
import { periodResetHandler } from 'Store/Areas/Signalr/Handlers/PeriodResetHandler';

export class SignalrStrategy {
  constructor() {
    this.handlers = [
      archiveHandler,
      customReportFileUploadHandler,
      deleteRuleHandler,
      exportHandler,
      importProgressHandler,
      periodFileUploadHandler,
      referenceListFileUploadHandler,
      mlCategorisationReasonHandler,
      genericCategorisationHandler,
      genericConflictHandler,
      externalReviewFileUploadHandler,
      externalReviewFileImportHandler,
      deleteSupportingDocHandler,
      genericApportionablesHandler,
      periodResetHandler,
    ];
  }
  getHandler(userAction) {
    return this.handlers.find(x => (Array.isArray(x.userAction)
      ? x.userAction.includes(userAction)
      : x.userAction === userAction));
  }
}
