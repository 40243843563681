export const GET_CATEGORY_RULES = 'CATEGORY_RULES/ET_CATEGORY_RULES';
export const GET_APPORTIONABLE_CATEGORY_RULES = 'CATEGORY_RULES/GET_APPORTIONABLE_CATEGORY_RULES';
export const RESET_CATEGORY_RULES = 'CATEGORY_RULES/RESET_CATEGORY_RULES';
export const GET_CATEGORY_RULES_SUCCESS = 'CATEGORY_RULES/GET_CATEGORY_RULES_SUCCESS';
export const GET_APPORTIONABLE_CATEGORY_RULES_SUCCESS = 'CATEGORY_RULES/GET_APPORTIONABLE_CATEGORY_RULES_SUCCESS';

export function getCategoryRules(
  periodId,
  categoryId,
  pageNumber,
  sortColumn,
  sortOrder,
  tagFilter,
  searchTerm,
  hasSearched,
) {
  return {
    type: GET_CATEGORY_RULES,
    loading: true,
    hasSearched,
    morePagesAvailable: false,
    payload: {
      periodId: periodId,
      categoryId: categoryId,
      pageNumber: pageNumber,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      tagFilter: tagFilter,
      searchTerm: searchTerm,
    },
  };
}

export function getApportionableCategoryRules(
  periodId,
  categoryId,
  pageNumber,
) {
  return {
    type: GET_APPORTIONABLE_CATEGORY_RULES,
    loading: true,
    morePagesAvailable: false,
    payload: {
      periodId: periodId,
      categoryId: categoryId,
      pageNumber: pageNumber,
    },
  };
}

export function getCategoryRulesSuccess(data, pageNumber, hasSearched) {
  return {
    type: GET_CATEGORY_RULES_SUCCESS,
    loading: false,
    rules: data.rules,
    morePagesAvailable: data.hasMore,
    pageNumber,
    hasSearched,
  };
}

export function getApportionableCategoryRulesSuccess(data) {
  return {
    type: GET_APPORTIONABLE_CATEGORY_RULES_SUCCESS,
    loading: false,
    rules: data.rules,
    morePagesAvailable: data.hasMore,
  };
}

export function resetCategoryRules() {
  return {
    type: RESET_CATEGORY_RULES,
  };
}
