/* eslint-disable no-param-reassign */
import {
  RESET_SUPPORTING_DOCUMENT_MAPPING_STATE,
  INITIALISE_SUPPORTING_DOCUMENT_MAPPING_STATE,
  MAP_ITEM_TO_FILE, UNMAP_ALL_FILE_ITEMS,
  GET_MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS_SUCCESS,
  PROCESS_MATCHED_SUPPORTING_AND_MAIN_DOCS,
  IS_MATCHED_SUPPORTING_AND_MAIN_DOCS_PRO_RATED,
} from 'Store/Areas/Import/SupportingDocumentMappingActions';
import { strings } from 'Constants/SupportingDocuments/Strings';

const initialState = {
  files: [],
  loading: false,
  allMatchedAndUnmatchedDocsData: {
    matchedDocs: [],
    unmatchedDocs: [],
  },
};

function getUpdatedMatchedSupportingAndMainDocsData(originalMatchedDetails, payload) {
  const updatedMatchedDetails = [...originalMatchedDetails];
  if (payload.isSelectAll) {
    if (payload.isAllProRatedSelected) {
      updatedMatchedDetails.forEach((matchedDetail) => {
        matchedDetail.isProRate = true;
        matchedDetail.isBalancingFigure = false;
      });
    } else {
      updatedMatchedDetails.forEach((matchedDetail) => {
        matchedDetail.isProRate = false;
      });
    }
    if (payload.isAllBalancingFigureSelected) {
      updatedMatchedDetails.forEach((matchedDetail) => {
        matchedDetail.isProRate = false;
        matchedDetail.isBalancingFigure = true;
      });
    } else {
      updatedMatchedDetails.forEach((matchedDetail) => {
        matchedDetail.isBalancingFigure = false;
      });
    }
  } else {
    const detail = updatedMatchedDetails.filter(x => x.id === payload.rowId);
    if (detail !== null && detail.length > 0) {
      if (payload.headerName === strings.isProRate) {
        detail[0].isProRate = !detail[0].isProRate;
        detail[0].isBalancingFigure = !detail[0].isProRate;
      } else {
        detail[0].isBalancingFigure = !detail[0].isBalancingFigure;
        detail[0].isProRate = !detail[0].isBalancingFigure;
      }
    }
  }
  return updatedMatchedDetails;
}

export default function SupportingDocumentMappingReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALISE_SUPPORTING_DOCUMENT_MAPPING_STATE:
      return Object.assign({}, state, {
        files: action.files.map((file) => {
          return {
            id: file.id,
            name: file.name,
            initialAmount: file.amountValue,
            currentAmount: file.amountValue,
            lineItemIds: [],
            balancingValue: 0,
            balancingNotes: '',
          };
        }),
      });
    case MAP_ITEM_TO_FILE:
      return Object.assign({}, state, {
        files: state.files.map((file) => {
          if (file.id === action.fileId) {
            return {
              ...file,
              balancingValue: 0,
              balancingNotes: '',
              currentAmount: file.currentAmount - action.lineItemsAmount.toFixed(2),
              lineItemIds: [...file.lineItemIds.concat(action.lineItemIds)],
            };
          }
          return file;
        }),
      });
    case UNMAP_ALL_FILE_ITEMS:
      return Object.assign({}, state, {
        files: state.files.map((file) => {
          if (action.fileId === file.id) {
            return {
              id: file.id,
              name: file.name,
              initialAmount: file.initialAmount,
              currentAmount: file.initialAmount.toFixed(2),
              lineItemIds: [],
              balancingValue: 0,
              balancingNotes: '',
            };
          }
          return file;
        }),
      });
    case PROCESS_MATCHED_SUPPORTING_AND_MAIN_DOCS:
      return Object.assign({}, state, {
        loading: true,
      });
    case RESET_SUPPORTING_DOCUMENT_MAPPING_STATE:
      return initialState;
    case IS_MATCHED_SUPPORTING_AND_MAIN_DOCS_PRO_RATED:
      return Object.assign({}, state, {
        allMatchedAndUnmatchedDocsData: {
          ...state.allMatchedAndUnmatchedDocsData,
          matchedDocs: getUpdatedMatchedSupportingAndMainDocsData(
            state.allMatchedAndUnmatchedDocsData.matchedDocs,
            action.payload,
          ),
        },
      });
    case GET_MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        allMatchedAndUnmatchedDocsData: action.payload.allMatchedAndUnmatchedDocsData,
      });
    default:
      return state;
  }
}
