import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import TabItem from './TabItem';
import { styles } from './Tab.styles';

class Breadcrumb extends React.PureComponent {
  render() {
    const {
      items,
      activeIndex,
      classes,
      className,
      onClicks,
      selectableIndexes,
    } = this.props;
    return (
      <div className={`${classes.root} ${className}`}>
        <For each="item" index="index" of={items}>
          <With selectable={selectableIndexes.indexOf(index) > -1}>
            <TabItem
              key={item}
              active={index === activeIndex}
              firstItem={index === 0}
              selectable={selectable}
              text={item}
              onClick={selectable ? onClicks[index] : null}
            />
          </With>
        </For>
      </div>
    );
  }
}

Breadcrumb.defaultProps = {
  className: '',
  onClicks: [],
  selectableIndexes: [],
};

Breadcrumb.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeIndex: PropTypes.number.isRequired,
  // array of funcs to be passed down to the breadcrumb items if the item is selectable
  onClicks: PropTypes.arrayOf(PropTypes.func),
  // array of the selectabled indexes
  selectableIndexes: PropTypes.arrayOf(PropTypes.number),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(Breadcrumb);
