import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import {
  withStyles,
  withTheme,
} from '@material-ui/core';
import { cancelEntityCreation, postEntity, deleteEntity } from 'Store/Areas/Setup/Admin/EntityActions';
import { getCreateEntitySchema } from 'Constants/Setup/Admin/CreateEntitySchema';
import { getErrorsFromValidationError } from 'Constants/ValidationHelpers';
import { commonStrings } from 'Constants/CommonStrings';
import { strings } from 'Constants/Setup/Admin/Strings';
import { currencies } from 'Constants/Entities/Currencies';
import { machineLearningOptions } from 'Constants/Entities/MachineLearningOptions';
import Button, { constants as buttonConstants } from 'Components/Shared/Buttons/Button';
import SidebarInput from 'Components/Shared/Inputs/SidebarInput';
import SimpleButton from 'Components/Shared/Buttons/SimpleButton/SimpleButton';
import Select from 'Components/Shared/Selects/Select';
import DisabledSelect from 'Components/Shared/Selects/DisabledSelect';
import AdminDeleteModal from 'Components/Setup/Admin/AdminDeleteModal';
import styles from './EntityCreationSidebar.styles';

class EntityCreationSidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
    };
  }

  onRemoveEntity = () => {
    this.setState({
      isDeleteModalOpen: true,
    });
  }
  onConfirmDelete = () => {
    const { dispatch, admin } = this.props;
    dispatch(deleteEntity(
      admin.entity.payload.id,
      admin.adminTreePicker.selectedGroupId,
      admin.entity.payload.name,
      admin.entity.payload.currencyId,
    ));
    this.onCloseDelete();
  }

  onCloseDelete = () => {
    this.setState({
      isDeleteModalOpen: false,
    });
  }

  getEntityNames(groupId) {
    const { payload } = this.props.admin.entity;
    if (groupId === 0) {
      return [''];
    }
    return this.props.adminTreePicker
      .data.find(item => item.id === groupId)
      .entities.filter(item => item.id !== payload.id)
      .map(item => item.name);
  }

  cancelCreateEntity() {
    this.props.dispatch(cancelEntityCreation());
  }

  postNewEntity(payload) {
    this.props.dispatch(postEntity(payload));
  }

  render() {
    const {
      classes,
      adminTreePicker,
      admin,
      permissions,
    } = this.props;
    const groupData = adminTreePicker.data.filter(group =>
      permissions.groupAdmins.includes(group.id));
    const { isEdit } = admin.entity;
    const {
      isDeleteModalOpen,
    } = this.state;

    return (
      <Fragment>
        {
          admin.entity.payload.projects &&
          <AdminDeleteModal
            onConfirm={this.onConfirmDelete}
            onCancel={this.onCloseDelete}
            isModalOpen={isDeleteModalOpen}
            title={strings.deleteEntity}
            message={admin.entity.payload.projects.length > 0 ?
              strings.deleteEntityChild : strings.deleteEntityNoChild}
            canDelete={!admin.entity.payload.projects.length > 0}
          />
        }
        <Formik
          validateOnChange
          validateOnBlur
          initialValues={{
            group: admin.entity.selection.selectedGroup.id,
            entity: isEdit ? admin.entity.payload.name : '',
            currency: isEdit ? admin.entity.payload.currencyId : 0,
            machineLearningEnabled: isEdit ? admin.entity.payload.machineLearningEnabled : 1,
          }}
          validate={(values) => {
            const entityNames = this.getEntityNames(values.group);
            const validationSchema = getCreateEntitySchema(entityNames);
            try {
              validationSchema.validateSync(values, { abortEarly: false });
              return {};
            } catch (error) {
              return getErrorsFromValidationError(error);
            }
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            this.postNewEntity({
              id: isEdit ? admin.entity.payload.id : 0,
              name: values.entity,
              groupId: values.group,
              currencyId: values.currency,
              machineLearningEnabled: values.machineLearningEnabled,
            });
          }}
          render={({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            touched,
            setFieldTouched,
          }) => {
            return (
              <form className={`${classes.form}`}>
                <div className={`${classes.flex} ${classes.padded}`}>
                  <div className={`${classes.fieldMargin} ${classes.title}`}>
                    {isEdit ? strings.editEntity : strings.createEntity}
                  </div>
                  <Choose>
                    <When condition={!isEdit}>
                      <Select
                        id="group"
                        label={strings.groupLabel}
                        value={values.group}
                        error={touched.group ? errors.group : ''}
                        onChange={(value) => {
                          setFieldValue('group', value);
                        }}
                        data={groupData}
                        className={classes.fieldMargin}
                      />
                    </When>
                    <Otherwise>
                      <DisabledSelect
                        text={groupData.find(x => x.id === values.group).name}
                        label={strings.groupLabel}
                        className={classes.fieldMargin}
                      />
                    </Otherwise>
                  </Choose>
                  <SidebarInput
                    id="entity"
                    label={strings.entityLabel}
                    value={values.entity}
                    error={touched.entity ? errors.entity : ''}
                    onChange={(value) => {
                      setFieldValue('entity', value);
                      setFieldTouched('entity', true);
                    }}
                    className={classes.fieldMargin}
                    placeholder={strings.entityNamePlaceholder}
                  />
                  <Select
                    id="currency"
                    label={strings.currencyLabel}
                    value={values.currency}
                    error={touched.currency ? errors.currency : ''}
                    onChange={(value) => {
                      setFieldValue('currency', value);
                    }}
                    data={currencies}
                    className={classes.fieldMargin}
                    placeholder={strings.currencyOptionPlaceholder}
                  />
                  <Select
                    id="machineLearningEnabled"
                    label={strings.machineLearningLabel}
                    value={values.machineLearningEnabled}
                    error={touched.machineLearningEnabled ? errors.machineLearningEnabled : ''}
                    onChange={(value) => {
                      setFieldValue('machineLearningEnabled', value);
                    }}
                    data={machineLearningOptions}
                    className={classes.fieldMargin}
                    preventPlaceholder
                  />
                  <If condition={isEdit}>
                    <SimpleButton
                      className={classes.deleteButton}
                      onClickEvent={this.onRemoveEntity}
                    >
                      {strings.deleteEntity}
                    </SimpleButton>
                  </If>
                </div>
                <div>
                  <Button
                    backgroundColor={buttonConstants.backgroundColor.dark}
                    height={buttonConstants.height.big}
                    className={classes.cancel}
                    onClick={() => this.cancelCreateEntity()}
                    rounded={false}
                  >
                    {commonStrings.cancel}
                  </Button>
                  <Button
                    className={classes.accept}
                    backgroundColor={buttonConstants.backgroundColor.main}
                    height={buttonConstants.height.big}
                    onClick={handleSubmit}
                    rounded={false}
                    type="submit"
                  >
                    {isEdit ? commonStrings.saveChanges : strings.addEntity}
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </Fragment>
    );
  }
}

EntityCreationSidebar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  adminTreePicker: PropTypes.shape({
    selectedGroupId: PropTypes.number,
    selectedEntityId: PropTypes.number,
    selectedProjectId: PropTypes.number,
    selectedCurrencyId: PropTypes.number,
    selectedItemLevel: PropTypes.number,
    loading: PropTypes.bool,
    data: PropTypes.array,
  }).isRequired,
  admin: PropTypes.shape({
    entity: PropTypes.shape({
      selection: PropTypes.shape({
        selectedGroup: PropTypes.object,
      }),
      isEdit: PropTypes.bool,
      payload: PropTypes.shape({
        id: PropTypes.number,
        projects: PropTypes.array,
        currency: PropTypes.number,
      }),
    }).isRequired,
  }).isRequired,
  permissions: PropTypes.shape({
    groupsCanEdit: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    admin: state.setup.admin,
    adminTreePicker: state.setup.admin.adminTreePicker,
    permissions: state.user.permissions.data,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(EntityCreationSidebar);
