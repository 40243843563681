import React from 'react';
import { withStyles, withTheme, IconButton } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Modal from 'Components/Shared/Modal/StyledModal';
// import Button, { constants } from 'Components/Shared/Buttons/Button';
import StyledModalButtons from 'Components/Shared/Modal/StyledModal.Buttons';

import Close from 'Assets/Images/exit-ic.svg';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { strings } from 'Constants/Categorization/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { referenceListsSelector } from 'Store/Areas/Categorisation/ReferenceListSelectors';
import { closeViewReferenceList, resetReferenceListEntries, deleteReferenceLisEntities } from 'Store/Areas/Categorisation/ReferenceListActions';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import { styles } from './ViewReferenceListModal.styles';

class ViewReferenceListModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      referenceListEntries: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.referenceLists.referenceListEntries !== this.state.referenceListEntries) {
      this.setState({ referenceListEntries: nextProps.referenceLists.referenceListEntries });
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (prevState.referenceListEntries !== this.state.referenceListEntries) {
      return true;
    }

    return false;
  }

  onCancel = () => {
    this.props.dispatch(closeViewReferenceList());
    this.props.dispatch(resetReferenceListEntries());
  }

  onSubmit = () => {
    const { referenceListEntries } = this.state;
    const {
      dispatch,
      periodId,
      referenceLists,
    } = this.props;

    if (referenceListEntries !== referenceLists.referenceListEntries) {
      const referenceListEntryIds = referenceLists.referenceListEntries.filter(r =>
        !referenceListEntries.includes(r)).map(m => m.id);
      dispatch(deleteReferenceLisEntities(
        periodId,
        referenceLists.referenceListId,
        referenceListEntryIds,
      ));
      this.props.dispatch(closeViewReferenceList());
      this.props.dispatch(resetReferenceListEntries());
    }
  }

  onDeleteButtonClick = (entry) => {
    const { referenceListEntries } = this.state;
    this.setState({
      referenceListEntries: referenceListEntries.filter(enty => enty !== entry),
    });
  }

  render() {
    const { referenceListEntries } = this.state;
    const {
      classes,
      referenceLists,
    } = this.props;

    return (
      <Modal open={referenceLists.open}>
        <div className={classes.content}>
          <div className={classes.title}>
            {referenceLists.referenceListName}
          </div>
          <div className={classes.entryList}>
            <Choose>
              <When condition={!referenceLists.entriesLoading}>
                <Choose>
                  <When condition={referenceLists.referenceListEntries.length !== 0}>
                    <For each="entry" of={referenceListEntries}>
                      <div className={classes.text} key={entry.id}>
                        {entry.name}
                        <div className={classes.rightAlign}>
                          <IconButton
                            className={classes.removeIcon}
                            onClick={() => this.onDeleteButtonClick(entry)}
                          >
                            <img src={Close} alt="remove" />
                          </IconButton>
                        </div>
                      </div>
                    </For>
                  </When>
                  <Otherwise>
                    <div className={classes.text}>
                      {strings.noRefListEntries}
                    </div>
                  </Otherwise>
                </Choose>
              </When>
              <Otherwise>
                <div className={classes.loader}>
                  <CircularLoader />
                </div>
              </Otherwise>
            </Choose>
          </div>
        </div>
        <StyledModalButtons
          onCancel={() => this.onCancel()}
          onAccept={() => this.onSubmit()}
          acceptText={commonStrings.delete}
          cancelText={commonStrings.cancel}
          submitDisabled={referenceListEntries === referenceLists.referenceListEntries}
        />
      </Modal>
    );
  }
}

ViewReferenceListModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  referenceLists: PropTypes.shape({
    referenceLists: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })).isRequired,
    loading: PropTypes.bool.isRequired,
    fileLoading: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    entriesLoading: PropTypes.bool.isRequired,
    referenceListName: PropTypes.string.isRequired,
    referenceListId: PropTypes.number.isRequired,
    referenceListEntries: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
    })),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    referenceLists: referenceListsSelector(state),
    periodId: periodIdSelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(ViewReferenceListModal);
