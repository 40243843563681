export const categorisationLocations = {
  referenceLists: 'referenceLists',
  singleKeywords: 'singleKeywords',
  combinationKeywords: 'combinationKeywords',
  customRules: 'customRules',
  lineItems: 'lineItems',
  conflictingLineItems: 'conflictingLineItems',
  flaggedLineItems: 'flaggedLineItems',
};

export const categorisationLineItemsReviewModes = {
  ml: 'ml',
  manual: 'manual',
  conflicts: 'conflicts',
  contra: 'contra',
  rules: 'rules',
  lineItems: 'allLineItems',
};

export const routes = {
  home: '/',
  setup: '/setup',
  import: {
    root: '/import/:stage',
    upload: '/import/upload',
    mappingMain: '/import/mapping-main',
    mappingSupporting: '/import/mapping-supporting',
    review: '/import/review',
  },
  importError: '/importError',
  externalReviewImportError: '/externalReviewImportError',
  error: '/error',
  apportionableManager: '/apportionableManager',
  categorisation: {
    root: '/categorisation/:stage',
    referenceLists: `/categorisation/${categorisationLocations.referenceLists}`,
    singleKeywords: `/categorisation/${categorisationLocations.singleKeywords}`,
    combinationKeywords: `/categorisation/${categorisationLocations.combinationKeywords}`,
    customKeywords: `/categorisation/${categorisationLocations.customRules}`,
    lineItems: `/categorisation/${categorisationLocations.lineItems}`,
    conflictingLineItems: `/categorisation/${categorisationLocations.conflictingLineItems}`,
    flaggedLineItems: `/categorisation/${categorisationLocations.flaggedLineItems}`,
  },
  machineLearning: '/machineLearning',
  machineLearningSetup: '/machineLearningSetup',
  periodImportDetails: '/periodImportDetails',
  categoryReview: '/categoryreview',
  lineItemsCategorisationReview: {
    root: '/lineItems/:mode',
    ml: `/lineItems/${categorisationLineItemsReviewModes.ml}`,
    manual: `/lineItems/${categorisationLineItemsReviewModes.manual}`,
    conflicts: `/lineItems/${categorisationLineItemsReviewModes.conflicts}`,
    contra: `/lineItems/${categorisationLineItemsReviewModes.contra}`,
    rules: `/lineItems/${categorisationLineItemsReviewModes.rules}`,
    lineItems: `/lineItems/${categorisationLineItemsReviewModes.lineItems}`,
  },
  templates: '/templates',
  templateProcessing: '/templateProcessing',
  bulkUpload: '/bulkUpload',
  bulkUserUploadProcessing: '/bulkUserUploadProcessing',
  bulkGroupEntityProjectUploadProcessing: '/bulkGroupEntityProjectUploadProcessing',
  conflicts: '/conflicts',
  processing: '/processing',
  supportingDocuments: '/supportingDocuments',
  contrasSetup: '/contrasSetup',
  contrasReview: '/contrasReview',
  failedRulesReview: '/failedRulesReview',
  importSummary: '/importSummary',
  review: '/review',
  export: '/export',
  externalReview: '/externalReview',
  pivotTable: '/pivotTable',
  log: '/log',
  createPeriod: '/periodForm',
  pageNotFound: '/pageNotFound',
  archive: '/archive',
  models: '/models',
  createModel: '/models/create',
  settings: '/settings',
  register: '/user/migrate',
};
