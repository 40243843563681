import { DonutHeight } from 'Constants/Categorization/CategoryReview/DonutChartConstants';

const styles = ({
  root: {
    borderRadius: 4,
  },
  text: {
    fontSize: 12,
    textAnchor: 'middle',
    fill: '#666',
    lineHeight: 1.5,
  },
  titleText: {
    fontSize: 14,
    fontWeight: 'bold',
    textAnchor: 'start',
  },
  arcPath: {
    stroke: '#efefef',
  },
  emptyDonut: {
    fill: '#D2D2D2',
  },
  insideText: {
    fill: '#666',
  },
  amountTotalText: {
    fontSize: 25,
    fontWeight: 'bold',
    lineHeight: 1.5,
  },
  rowCountText: {
    fontSize: 14,
    lineHeight: 1.29,
  },
  svg: {
    width: '100%',
    minHeight: DonutHeight,
  },
});

export default styles;
