import React from 'react';
import PropTypes from 'prop-types';
import { Paper, withStyles, IconButton } from '@material-ui/core';
import Chevron from 'Assets/Images/chevron-down-blue-ic.svg';
import { styles } from './DashboardTab.styles';

class DashboardTab extends React.PureComponent {
  render() {
    const {
      classes,
      title,
      content,
      className,
      onClick,
      hideChevron,
    } = this.props;
    return (
      <Paper className={`${classes.root} ${className}`}>
        <div className={classes.textContainer}>
          <div className={classes.title}>{title}</div>
          <For each="item" of={content}>
            <div key={item} className={classes.text}>{item}</div>
          </For>
        </div>
        <If condition={!hideChevron}>
          <div className={classes.icon}>
            <IconButton onClick={onClick} disableRipple>
              <img alt="Expand" src={Chevron} className={classes.rotate} />
            </IconButton>
          </div>
        </If>
      </Paper>
    );
  }
}

DashboardTab.defaultProps = {
  title: '',
  content: [''],
  className: '',
  hideChevron: false,
};

DashboardTab.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  hideChevron: PropTypes.bool,
};

export default withStyles(styles)(DashboardTab);
