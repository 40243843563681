/* eslint-disable no-underscore-dangle */
import CryptoJS from 'crypto-js';
import LZString from 'lz-string/libs/lz-string';

export default class EncryptedLocalStorage {
  constructor(storage) {
    Storage.prototype._getItem = storage.prototype.getItem;
    Storage.prototype._setItem = storage.prototype.setItem;
    Storage.prototype._removeItem = storage.prototype.removeItem;

    this.apikey = 'r4u7x!A%D*G-KaNd';
  }

  setItem = (key, obj) => {
    const keyEncoded = window.btoa(key);
    let compressedData = null;

    if (obj !== null && obj !== undefined) {
      const data = JSON.stringify(obj);
      const encryptedValue = CryptoJS.AES.encrypt(data, this.apikey);
      compressedData = LZString.compressToUTF16(encryptedValue.toString());
    }

    // store in native Local Storage
    Storage.prototype._setItem.call(window.sessionStorage, keyEncoded, compressedData);
  };

  getItem = (key) => {
    const keyEncoded = window.btoa(key);

    // retrieve encyrpted data from native Local Storage
    const encryptedValue = Storage.prototype._getItem.call(window.sessionStorage, keyEncoded);

    if (encryptedValue !== undefined && encryptedValue !== null) {
      const deCompressedData = LZString.decompressFromUTF16(encryptedValue);
      const bytes = CryptoJS.AES.decrypt(deCompressedData, this.apikey);
      const byteData = bytes.toString(CryptoJS.enc.Utf8);
      const data = JSON.parse(byteData);
      return data;
    }

    return null;
  }

  removeItem = (key) => {
    const keyEncoded = window.btoa(key);

    // delete encyrpted data from native Local Storage
    Storage.prototype._removeItem.call(window.sessionStorage, keyEncoded);
  }
}
