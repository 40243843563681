import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getComboKeywords, updateSearchTerm, updateKeywordFilters } from 'Store/Areas/Categorisation/ComboKeywordActions';
import KeywordFiltersPanel from 'Components/Categorization/Shared/KeywordFiltersPanel/KeywordFiltersPanel';

class ComboKeywordFiltersPanel extends React.PureComponent {
  onSortOrderChanged(value) {
    const {
      keywordFilters,
      dispatch,
      period,
      rootKeyword,
      rootTagFilter,
    } = this.props;

    dispatch(updateKeywordFilters(
      period.data.id,
      rootKeyword,
      rootTagFilter,
      1,
      value,
      keywordFilters.tagFilter,
      keywordFilters.searchTerm,
    ));
  }

  onTagFilterChanged(value) {
    const {
      keywordFilters,
      dispatch,
      period,
      rootKeyword,
      rootTagFilter,
    } = this.props;

    dispatch(updateKeywordFilters(
      period.data.id,
      rootKeyword,
      rootTagFilter,
      1,
      keywordFilters.sortOrder,
      value,
      keywordFilters.searchTerm,
    ));
  }

  onSearchTermChanged(value) {
    const { dispatch } = this.props;
    dispatch(updateSearchTerm(value));
  }

  onSearchIconClicked() {
    const {
      dispatch,
      keywordFilters,
      period,
      rootKeyword,
      rootTagFilter,
    } = this.props;
    dispatch(getComboKeywords(
      period.data.id,
      rootKeyword,
      rootTagFilter,
      1,
      keywordFilters.sortOrder,
      keywordFilters.tagFilter,
      keywordFilters.searchTerm,
      !!keywordFilters.searchTerm.trim(),
    ));
  }

  onSearchReset() {
    const {
      dispatch,
      keywordFilters,
      period,
      rootKeyword,
      rootTagFilter,
    } = this.props;
    dispatch(updateSearchTerm(''));
    dispatch(getComboKeywords(
      period.data.id,
      rootKeyword,
      rootTagFilter,
      1,
      keywordFilters.sortOrder,
      keywordFilters.tagFilter,
      '',
      false,
    ));
  }

  render() {
    const {
      keywordFilters,
      period,
    } = this.props;
    return (
      <KeywordFiltersPanel
        keywordFilters={keywordFilters}
        period={period}
        onSortOrderChanged={value => this.onSortOrderChanged(value)}
        onTagFilterChanged={value => this.onTagFilterChanged(value)}
        onSearchTermChanged={value => this.onSearchTermChanged(value)}
        onSearchIconClicked={() => this.onSearchIconClicked()}
        onSearchReset={() => this.onSearchReset()}
      />
    );
  }
}

ComboKeywordFiltersPanel.propTypes = {
  dispatch: PropTypes.func.isRequired,
  keywordFilters: PropTypes.shape({
    sortOrder: PropTypes.number.isRequired,
    tagFilter: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired,
  }).isRequired,
  period: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  rootKeyword: PropTypes.string.isRequired,
  rootTagFilter: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    keywordFilters: state.categorisation.comboKeywords.filters,
    rootKeyword: state.categorisation.comboKeywords.rootKeyword.name,
    rootTagFilter: state.categorisation.comboKeywords.rootTag.id,
    period: state.periods.period,
  };
}

export default connect(mapStateToProps)(ComboKeywordFiltersPanel);
