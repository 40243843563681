
import { GET_USER_PERMISSIONS, GET_USER_PERMISSIONS_SUCCESS } from './PermissionsActions';

const initialState = {
  data: {},
  populated: false,
  init: false,
};

export default function permissionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_PERMISSIONS:
    case GET_USER_PERMISSIONS_SUCCESS:
      return Object.assign({}, state, {
        data: action.payload.data,
        populated: action.payload.populated,
        init: state.init || action.payload.populated,
      });
    default:
      return state;
  }
}
