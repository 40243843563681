import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import styles from './FileUploader.styles';

class FileUploader extends React.PureComponent {
  render() {
    const {
      classes,
      fileTypes,
      onFileSelected,
      onDropRejected,
      children,
      className,
    } = this.props;
    return (
      <Dropzone
        className={`${classes.dropzone} ${className}`}
        accept={fileTypes}
        onDropAccepted={onFileSelected}
        onDropRejected={onDropRejected}
      >
        {children}
      </Dropzone>
    );
  }
}

FileUploader.defaultProps = {
  fileTypes: '', // By default we accept all file types
  className: '',
  onDropRejected: () => {},
};

FileUploader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  onFileSelected: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  fileTypes: PropTypes.string, // Must be a comma separated string of file types ('.xlsx,.xls,.csv')
};

export default compose(
  withStyles(styles),
  withTheme(),
)(FileUploader);
