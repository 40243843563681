import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';
import {
  POST_NEW_CATEGORY,
  POST_CATEGORY_SUCCESS,
  POST_CATEGORY_FAILURE,
  ADDING_NEW_CATEGORY,
  CANCEL_ADD_CATEGORY,
  RESET_FAILED_ADD,
  EDIT_CATEGORY,
} from './CategoryActions';

const initialState = {
  loading: false,
  success: false,
  adding: false,
  errorMessage: '',
};

export default function addUsersReducer(state = initialState, action) {
  switch (action.type) {
    case POST_NEW_CATEGORY:
    case POST_CATEGORY_SUCCESS:
    case POST_CATEGORY_FAILURE:
    case RESET_FAILED_ADD:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        errorMessage: action.errorMessage,
        adding: action.adding,
      });
    case ADDING_NEW_CATEGORY:
    case CANCEL_ADD_CATEGORY:
      return Object.assign({}, state, {
        adding: action.adding,
        isEdit: action.isEdit,
      });
    case EDIT_CATEGORY:
      return Object.assign({}, state, {
        adding: action.adding,
        isEdit: action.isEdit,
        payload: action.payload,
      });
    case RESET_PERIOD:
      return initialState;
    default:
      return state;
  }
}
