import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { apportionmentStrings } from 'Components/Apportionables/ApportionablesStrings';
import { Post, Get } from 'Store/Api/CallApi';
import {
  GET_APPORTIONABLE_LINE_ITEMS,
  UPDATE_APPORTIONMENT_DEFINITION_URL,
  CALCULATE_APPORTIONMENT_URL,
  CHECK_FOR_APPORTIONMNET_CALCULATION_URL,
} from 'Store/Api/ApiEndpoints';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';
import {
  getApportionableItemsSuccess,
  GET_APPORTIONABLE_ITEMS,
  UPDATE_APPORTIONMENT_DEFINITION,
  resetApportionableItems,
  CALCULATE_APPORTIONMENT,
  CHECK_APPORTIONMENT_CALCULATION,
  calculateApportionment,
  checkApportionmentCalculationSuccess,
  getApportionableItemsSuccessAfterUpdate,
} from 'Store/Areas/Apportionables/ApportionablesActions';

function* getApportionableItemsSaga(action) {
  const response = yield Post(GET_APPORTIONABLE_LINE_ITEMS, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getApportionableItemsSuccess(data));
    if (data.apportionables.length > 0 &&
      data.apportionables.some(x => x.apportionableErrorMessage !== null
        && x.apportionableErrorMessage !== undefined
        && x.apportionableErrorMessage.trim().length > 0)) {
      yield put(displayToastNotification(apportionmentStrings.apportionmentErrorMessage));
    }
  } else {
    yield handleStatusCode(response.status);
  }
}

function* UpdateApportionmentDefinition(action) {
  yield put(resetApportionableItems());
  const response = yield Post(UPDATE_APPORTIONMENT_DEFINITION_URL, action.payload);
  if (!response.ok) {
    yield put(displayToastNotification(apportionmentStrings.apportionableUpdationFailed));
  } else {
    const data = yield response.json();
    yield put(getApportionableItemsSuccessAfterUpdate(data, action.payload.lineItemIds));
    yield put(displayToastNotification(apportionmentStrings.apportionableUpdated));
  }
}

function* calculateAllApportionment(action) {
  yield put(setProcessing(true));
  const response = yield Get(CALCULATE_APPORTIONMENT_URL(action.payload.periodId));
  if (!response.ok) {
    yield put(displayToastNotification(apportionmentStrings.calculationFailed));
  } else {
    yield put(displayToastNotification(apportionmentStrings.calculationQueued));
  }
}

function* checkApportionmentCalculationIsReady(action) {
  const response = yield Get(CHECK_FOR_APPORTIONMNET_CALCULATION_URL(action.payload.periodId));
  if (response.ok) {
    const checkApportionmentResponse = yield response.json();
    if (checkApportionmentResponse.isReadyForCalculation) {
      yield put(calculateApportionment(action.payload.periodId));
    } else {
      yield put(checkApportionmentCalculationSuccess(checkApportionmentResponse));
    }
  }
}

/* Watcher Sagas */
export function* apportionablesSagas() {
  yield takeLatest(GET_APPORTIONABLE_ITEMS, getApportionableItemsSaga);
  yield takeLatest(UPDATE_APPORTIONMENT_DEFINITION, UpdateApportionmentDefinition);
  yield takeLatest(CALCULATE_APPORTIONMENT, calculateAllApportionment);
  yield takeLatest(CHECK_APPORTIONMENT_CALCULATION, checkApportionmentCalculationIsReady);
}
