import { operatorTypes, textOperators, numericOperators } from 'Constants/Rules/RuleConstants';
import { TagTypes } from 'Constants/Categorization/Tags';

export function getOperatorType(operator, tagType) {
  switch (operator) {
    case textOperators.contains:
    case textOperators.fuzzyContains:
    case textOperators.fuzzyEquals:
    case textOperators.in:
    case textOperators.matchRegex:
    case textOperators.notContains:
    case textOperators.substring:
    case textOperators.startsWith:
    case textOperators.endsWith:
      return operatorTypes.keyword;
    case textOperators.equals:
    case textOperators.notEquals:
      if (tagType === TagTypes.Text || tagType === TagTypes.TextWholeValue) {
        return operatorTypes.keyword;
      }
      return operatorTypes.arithmetic;
    case numericOperators.greaterThan:
    case numericOperators.greaterThanOrEqual:
    case numericOperators.lessThan:
    case numericOperators.lessThanOrEqual:
      return operatorTypes.arithmetic;
    default:
      return undefined;
  }
}

export function isMultiKeywordOperator(operator, tagType) {
  switch (operator) {
    case textOperators.contains:
    case textOperators.in:
      return true;
    case textOperators.equals:
    case textOperators.notEquals:
      return getOperatorType(operator, tagType) === operatorTypes.keyword;
    default:
      return false;
  }
}

export function isOperatorValidWithTagType(tagType, operator) {
  switch (tagType) {
    case TagTypes.Text:
    case TagTypes.TextWholeValue:
      return Object.values(textOperators).includes(operator);
    case TagTypes.Numeric:
    case TagTypes.Date:
      return Object.values(numericOperators)
        .concat([textOperators.equals, textOperators.notEquals])
        .includes(operator);
    default:
      return false;
  }
}

export function operatorAllowsEmptyValues(operator, tagType) {
  switch (operator) {
    case textOperators.equals:
    case textOperators.notEquals:
      return getOperatorType(operator, tagType) === operatorTypes.keyword;
    default:
      return false;
  }
}