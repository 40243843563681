import React from 'react';
import { connect } from 'react-redux';
import { deleteJoin, updateJoin, updateCondition, deleteOperator, updateOperator, deleteTag, updateTag, addSegment, deleteSegment, addBrackets, moveSegment } from 'Store/Areas/Rule/RuleBuilderActions';
import PropTypes from 'prop-types';

const withRulesBuilder = () => (Component) => {
  class WithRulesBuilder extends React.PureComponent {
    onTagChanged = (metadata, id) => {
      this.props.dispatch(updateTag(id, metadata));
      this.onConditionChanged('', id);
    }

    onOperatorChanged = (value, id) => {
      this.props.dispatch(updateOperator(id, value));
      this.onConditionChanged('', id);
    }

    onConditionChanged = (value, id) => {
      this.props.dispatch(updateCondition(id, value));
    }

    onJoinChanged = (value, id) => {
      this.props.dispatch(updateJoin(id, value));
    }

    onAddSegment = () => {
      this.props.dispatch(addSegment());
    }

    onRemoveSegment = (id) => {
      this.props.dispatch(deleteSegment(id));
    }

    onRemoveJoin = (id) => {
      this.props.dispatch(deleteJoin(id));
    }

    onRemoveOperator = (id) => {
      this.props.dispatch(deleteOperator(id));
      this.onConditionChanged('', id);
    }

    onRemoveTag = (id) => {
      this.props.dispatch(deleteTag(id));
      this.onConditionChanged('', id);
    }

    onAddBrackets = (type) => {
      this.props.dispatch(addBrackets(type));
    }

    onMoveSegment = (fromIndex, toIndex) => {
      this.props.dispatch(moveSegment(fromIndex, toIndex));
    }

    render() {
      const {
        onConditionChanged,
        onJoinChanged,
        onJoinMoved,
        onOperaterMoved,
        onOperatorChanged,
        onTagChanged,
        onTagMoved,
        onAddSegment,
        onRemoveSegment,
        onRemoveJoin,
        onRemoveOperator,
        onRemoveTag,
        onAddBrackets,
        onMoveSegment,
      } = this;
      const {
        ...extra
      } = this.props;
      return (
        <Component
          onAddSegment={onAddSegment}
          onConditionChanged={onConditionChanged}
          onJoinChanged={onJoinChanged}
          onJoinMoved={onJoinMoved}
          onOperaterMoved={onOperaterMoved}
          onOperatorChanged={onOperatorChanged}
          onRemoveSegment={onRemoveSegment}
          onRemoveJoin={onRemoveJoin}
          onRemoveOperator={onRemoveOperator}
          onRemoveTag={onRemoveTag}
          onTagMoved={onTagMoved}
          onTagChanged={onTagChanged}
          onAddBrackets={onAddBrackets}
          onMoveSegment={onMoveSegment}
          {...extra}
        />
      );
    }
  }

  WithRulesBuilder.propTypes = {
    dispatch: PropTypes.func.isRequired,
    rule: PropTypes.string.isRequired,
    ruleChunks: PropTypes.arrayOf(PropTypes.object).isRequired,
    ruleError: PropTypes.string.isRequired,
    ruleHasError: PropTypes.bool.isRequired,
  };

  const mapStateToProps = (state) => {
    return {
      rule: state.rules.ruleBuilder.rule,
      ruleId: state.rules.ruleBuilder.initialRuleId,
      ruleChunks: state.rules.ruleBuilder.chunks,
      ruleHasError: state.rules.ruleBuilder.hasError,
      ruleError: state.rules.ruleBuilder.error,
    };
  };

  return connect(mapStateToProps)(WithRulesBuilder);
};

export default withRulesBuilder;
