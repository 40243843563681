import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { updateTreePickerSelection, selectPeriod } from 'Store/Areas/Projects/UserTreePickerActions';
import ProjectsSidebar from 'Components/Projects/ProjectsSidebar';
import { routes } from 'Constants/Routes';
import { resetPeriodLock } from 'Store/Areas/App/ConfigActions';
import { editPeriod, selectCreatePeriod, resetCreatePeriod } from 'Store/Areas/Projects/CreatePeriodActions';

class Sidebar extends React.PureComponent {
  onTreeItemClick(groupId, entityId, projectId, selectedItemLevel) {
    const { dispatch, projects } = this.props;
    dispatch(updateTreePickerSelection(
      groupId, entityId, projectId,
      selectedItemLevel, projects.userTreePicker,
    ));
  }

  onPeriodSelection(periodId, index) {
    this.props.dispatch(push(`${routes.import.upload}`));
    this.props.dispatch(selectPeriod(periodId, index));
  }

  onEditPeriodClick(groupId, entityId, projectId, periodId, taxContextId, templateId) {
    this.props.dispatch(editPeriod(
      groupId,
      entityId,
      projectId,
      periodId,
      taxContextId,
      templateId,
    ));
    this.props.dispatch(push(routes.createPeriod));
  }

  onAddPeriodClick(group, entity, project) {
    this.props.dispatch(resetPeriodLock());
    if (group) {
      this.props.dispatch(selectCreatePeriod(group, entity, project));
    } else {
      this.props.dispatch(resetCreatePeriod(group, entity, project));
    }
    this.props.dispatch(push(routes.createPeriod));
  }

  render() {
    const {
      projects,
    } = this.props;
    return (
      <ProjectsSidebar
        treePickerData={projects.treePicker}
        userTreePicker={projects.userTreePicker}
        onEditPeriodClick={
          (groupId, entityId, projectId, periodId, taxContextId, templateId) =>
            this.onEditPeriodClick(
              groupId,
              entityId,
              projectId,
              periodId,
              taxContextId,
              templateId,
            )}
        onEditProjectClick={() => { }}
        onPeriodSelection={(periodId, index) => this.onPeriodSelection(periodId, index)}
        onTreeItemClick={(groupId, entityId, projectId, itemLevel) =>
          this.onTreeItemClick(groupId, entityId, projectId, itemLevel)}
        onAddPeriodClick={(groupId, entityId, projectId) =>
          this.onAddPeriodClick(groupId, entityId, projectId)}
      />
    );
  }
}

Sidebar.propTypes = {
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
  };
}

export default connect(mapStateToProps)(Sidebar);
