import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency, formatPercentage } from 'Helpers/NumberHelpers';

class SimpleCell extends React.PureComponent {
  render() {
    const {
      childrenBefore,
      children,
      childrenAfter,
      currencySymbol,
      padding,
      style,
      width,
      padLeft,
      padRight,
      formatAsCurrency,
      formatAsPercentage,
      spacing,
      className,
    } = this.props;

    let displayValue = children;
    if (formatAsCurrency) {
      displayValue = formatCurrency(children, currencySymbol);
    } else if (formatAsPercentage) {
      displayValue = formatPercentage(children);
    }

    const actualPadLeft = padLeft ? padding : 0;
    const actualPadRight = padRight ? padding : spacing;
    return (
      <div
        style={{
          ...style,
          width: width - actualPadLeft - actualPadRight,
          paddingLeft: actualPadLeft,
          paddingRight: actualPadRight,
        }}
        className={className}
      >
        {childrenBefore}
        {displayValue}
        {childrenAfter}
      </div>
    );
  }
}

SimpleCell.defaultProps = {
  padding: 10,
  spacing: 0,
  formatAsPercentage: false,
  formatAsCurrency: false,
  padRight: false,
  padLeft: false,
  className: '',
  childrenBefore: null,
  childrenAfter: null,
  currencySymbol: null,
};

SimpleCell.propTypes = {
  padding: PropTypes.number,
  spacing: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
  childrenBefore: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
    PropTypes.func,
  ]),
  childrenAfter: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
    PropTypes.func,
  ]),
  width: PropTypes.number.isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  padLeft: PropTypes.bool,
  padRight: PropTypes.bool,
  formatAsCurrency: PropTypes.bool,
  formatAsPercentage: PropTypes.bool,
  className: PropTypes.string,
  currencySymbol: PropTypes.string,
};

export default SimpleCell;
