export const strings = {
  title: 'File upload and management',
  subtitle: 'Upload new files',
  templateUploadNote: 'You can use this screen to manage and upload templates for your groups.',
  templateMessagePart1: 'Your documents are now being processed',
  templateMessagePart2: 'To make the templates work smoothly, the system needs to do some ground work first.',
  templateMessagePart3: 'This initial processing might take some time.',
  templateMessagePart4: 'Thank you for your patience. ',
  templateSuccessful: 'Template successfully uploaded. ',
  templateFailed: 'Template failed to upload. ',
  templateSuccessfulNote: 'Template has been successfully uploaded. Please click `Upload Another Template` to add more templates.',
  templateFailedNote: 'Template has failed to upload. Please click `Upload Another Template` to add a different template.',
  outcomeTemplateButton: 'Upload Another Template',
  tableTitle: 'Available Templates',
  failedScanTitle: 'Corrupted file',
  failedScanDescription: 'This file is corrupted and cannot be uploaded',
  sharedTemplates: 'Shared',
  deleteModalTitle: 'Delete template',
  deleteModalNote: templateName => `Deleting '${templateName}' template will remove it from all associated Groups. Any Periods using this template will not be affected.`,
  templateTabTitle: 'Templates',
  referenceListsTabTitle: 'Reference Lists',
};

export const templateTableHeaders = ['Template Name', 'Tax Context', 'Group(s)', 'Type', 'Date', 'Download', ''];

export const templateMessageArray =
  [strings.templateMessagePart1,
    strings.templateMessagePart2,
    strings.templateMessagePart3,
    strings.templateMessagePart4];

