export const levels = {
  group: 0,
  entity: 1,
  project: 2,
  period: 3,
};

export function levelName(level) {
  switch (level) {
    case 0:
      return 'group';
    case 1:
      return 'entity';
    case 2:
      return 'project';
    case 3:
      return 'period';
    default:
      return undefined;
  }
}
