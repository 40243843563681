export const styles = theme => ({
  lineItems: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  header: {
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.regular,
  },
  searchBox: {
    width: 240,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.colors.black,
  },
  gridContainer: {
    flexGrow: 1,
  },
});
