import { TOGGLE_DELETE_TAG_MODAL, DELETE_TAG, DELETE_TAG_SUCCESS } from 'Store/Areas/Import/TagMappingActions';

const initialState = {
  loading: false,
  success: false,
  showModal: false,
  tagName: '',
  tagId: 0,
  errorMessage: '',
};

export default function deleteTagReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_DELETE_TAG_MODAL:
    case DELETE_TAG:
    case DELETE_TAG_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        showModal: action.showModal,
        tagName: action.tagName,
        tagId: action.tagId,
        errorMessage: action.errorMessage,
      });
    default:
      return state;
  }
}
