export const styles = theme => ({
  root: {
    height: 'Calc(100vh - 130px)',
    display: 'flex',
    flexDirection: 'column',
  },
  breadcrumb: {
    marginBottom: 10,
  },
  content: {
    flexGrow: 1,
    padding: '10px 10px 0 10px',
  },
  contentHeader: {
    padding: 10,
    marginBottom: 5,
  },
  searchBox: {
    width: 240,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.colors.black,
  },
  message: {
    backgroundColor: theme.palette.primary.messageBackground,
    color: theme.palette.primary.darkText,
    lineHeight: 1.43,
    fontSize: theme.constants.fontSizes.regular,
  },
  noSearchResults: {
    paddingTop: 20,
  },
  simpleMessage: theme.simpleMessage,
  imgButton: {
    zIndex: '200',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
    marginTop: '-5px',
    fontSize: theme.constants.fontSizes.small,
    background: 'transparent',
    border: 'none',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  flaggedIcon: {
    zIndex: '200',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
    marginTop: '-5px',
    fontSize: theme.constants.fontSizes.small,
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  paperRoot: {
    marginTop: 10,
    marginBottom: 10,
    height: '30px',
  },
  createRulePane: {
    display: 'inline-flex',
  },
  createRuleButton: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: theme.constants.fontSizes.small,
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
  },
});
