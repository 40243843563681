import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import {
  resetCreatePeriod,
  postPeriod,
  selectPeriodTemplate,
  selectPeriodTemplateRule,
  selectPeriod,
  selectCreatePeriod,
  getPeriodInfo,
} from 'Store/Areas/Projects/CreatePeriodActions';
import { setWorkInProgress } from 'Store/Areas/App/MenuModeActions';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { getTemplatesData, resetTemplatesData } from 'Store/Areas/Projects/TemplatesActions';
import CreatePeriodForm from './CreatePeriodForm';
import TemplateViewer from './TemplateViewer';
import PeriodViewer from './PeriodViewer';

class CreatePeriodContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.resetTemplates(true);
  }

  componentDidMount() {
    const { selectedGroup, selectedProject } = this.props.createPeriod.selection;
    const { loading, data } = this.props.treePicker;

    if (selectedGroup.id === 0 && loading && !data.length) {
      this.props.dispatch(getTreePickerData());
    }

    this.props.dispatch(getTemplatesData(selectedProject.taxContextId, selectedGroup.id));
    this.props.dispatch(setWorkInProgress(true));
  }

  componentDidUpdate() {
    if (this.props.createPeriod &&
      this.props.createPeriod.success &&
      !this.props.createPeriod.isEdit) {
      this.props.dispatch(resetCreatePeriod());
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setWorkInProgress(false));
  }

  onCancelClick = () => {
    this.props.dispatch(resetCreatePeriod(true));
  }

  setGroupEntityAndProject = (groupId, entityId, projectId) => {
    const { treePicker } = this.props;
    const group = (groupId && treePicker.data.find(item => item.id === groupId))
      || undefined;

    const entities = group ? group.entities : [];
    const entity = (entityId && entities.find(x => x.id === entityId))
      || undefined;

    const projects = entity ? entity.projects : [];
    const project = (projectId && projects.find(x => x.id === projectId))
      || undefined;

    if (group && entity && project) {
      this.props.dispatch(selectCreatePeriod(group, entity, project));
    }
  }

  fetchTemplates = (taxContextId, groupId) => {
    this.props.dispatch(getTemplatesData(taxContextId, groupId));
    this.props.dispatch(selectPeriodTemplate(0));
  }

  selectTemplate = (templateId) => {
    this.props.dispatch(selectPeriodTemplateRule(templateId));
  }

  selectTemplateRule = (templateId) => {
    this.props.dispatch(selectPeriodTemplateRule(templateId));
  }

  resetTemplates = (loading = false) => {
    this.props.dispatch(resetTemplatesData(loading));
  }

  selectPeriod = (periodId) => {
    if (periodId && this.props.createPeriod.form.selectedPeriodId !== periodId) {
      this.props.dispatch(getPeriodInfo(periodId));
    }

    this.props.dispatch(selectPeriod(periodId));
  }

  submitForm = (payload, selection) => {
    this.props.dispatch(postPeriod(payload, selection, this.props.createPeriod.isEdit));
  }

  render() {
    const {
      createPeriod,
      treePicker,
      templates,
    } = this.props;

    return (
      <UserSidebarStructure
        mainContent={
          <Choose>
            <When condition={createPeriod.form.selectedTemplateId}>
              <TemplateViewer template={
                templates.data
                  .find(item => item.id === createPeriod.form.selectedTemplateId)
              }
              />
            </When>
            <When condition={createPeriod.form.selectedPeriodId}>
              <PeriodViewer
                periodInfo={createPeriod.periodInfo}
                project={createPeriod.selection.selectedProject}
              />
            </When>
            <Otherwise>
              <div />
            </Otherwise>
          </Choose>
        }
        mainContentLoading={templates.loading}
        sidebarContent={<CreatePeriodForm
          treePicker={treePicker}
          templates={templates}
          createPeriod={createPeriod}
          fetchTemplates={this.fetchTemplates}
          selectTemplate={this.selectTemplate}
          selectTemplateRule={this.selectTemplateRule}
          resetTemplates={this.resetTemplates}
          selectPeriod={this.selectPeriod}
          setGroupEntityAndProject={this.setGroupEntityAndProject}
          submitForm={this.submitForm}
          onCancelClick={this.onCancelClick}
        />}
        sidebarContentLoading={false}
      />
    );
  }
}

CreatePeriodContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  treePicker: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.array,
  }).isRequired,
  createPeriod: PropTypes.shape({
    success: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    selection: PropTypes.shape({
      selectedGroup: PropTypes.object,
      selectedEntity: PropTypes.object,
      selectedProject: PropTypes.object,
    }).isRequired,
    form: PropTypes.shape({
      selectedTemplateId: PropTypes.number.isRequired,
      selectedPeriodId: PropTypes.number.isRequired,
    }).isRequired,
    periodInfo: PropTypes.shape({
      id: PropTypes.number.isRequired,
      mappedTags: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        isMandatory: PropTypes.bool.isRequired,
      })).isRequired,
      rulesCount: PropTypes.number.isRequired,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
      loading: PropTypes.bool.isRequired,
      success: PropTypes.bool.isRequired,
    }).isRequired,
    isEdit: PropTypes.bool,
  }).isRequired,
  templates: PropTypes.shape({
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    createPeriod: state.projects.createPeriod,
    treePicker: state.projects.treePicker,
    templates: state.projects.templates,
  };
}

export default compose(
  withTheme(),
  connect(mapStateToProps),
)(CreatePeriodContainer);
