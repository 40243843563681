import { OPEN_EDIT_RULE, CLOSE_EDIT_RULE } from 'Store/Areas/Rule/EditRuleActions';

const initialState = {
  open: false,
};

export default function editRuleReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_EDIT_RULE:
    case CLOSE_EDIT_RULE:
      return Object.assign({}, state, {
        open: action.open,
      });
    default:
      return state;
  }
}

