import moment from 'moment';

export const dateFormat = 'DD/MM/YYYY';
export const dateTimeFormat = 'DD/MM/YYYY HH:mm';
export const ukDateRegex = /^(([0-9])|([0-2][0-9])|(3[0-1]))\/(([1-9])|(0[1-9])|(1[0-2]))\/(([0-9][0-9])|([1-2][0,9][0-9][0-9]))$/gm;

export const getLocalDateString = (date, format = dateTimeFormat) => {
  return moment.utc(date).local().format(format);
};

export const getCurrentUtcDateString = (format = dateTimeFormat) => {
  return moment.utc().format(format);
};

export const isDateBefore = (date, dateToCompare) => {
  return moment(date).isBefore(dateToCompare);
};

export const isValidDate = (date) => {
  return ukDateRegex.test(date);
};
