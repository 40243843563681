export const styles = theme => ({
  proceedContainer: {
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingTop: 30,
  },
  proceedButton: {
    width: '100%',
  },
  description: {
    fontSize: theme.constants.fontSizes.regular,
    lineHeight: 1.5,
    marginRight: 10,
  },
  contraListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 56px)',
  },
  contraList: {
    flexGrow: 1,
    margin: 20,
  },
  headerCell: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  noDataContainer: {
    padding: '20px 20px 30px 20px',
    margin: '0 20px 20px 20px',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  flex: {
    flexGrow: 1,
  },
  marginTop: {
    marginTop: 20,
  },
  marginBottom: {
    marginBottom: 10,
  },
  arrowButton: {
    flex: 1,
    width: '100%',
    display: 'flex',
  },
  skipButton: {
    width: '100%',
    justifyContent: 'flex-end',
  },
});

