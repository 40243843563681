const styles = theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  padded: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
  },
  title: {
    color: theme.palette.colors.white,
    fontSize: theme.constants.fontSizes.regular,
    paddingBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
  },
  flex: {
    flexGrow: 1,
  },
  scrollable: {
    overflowY: 'auto',
  },
  cancel: {
    width: '33%',
  },
  accept: {
    width: '67%',
  },
  fieldMargin: {
    marginBottom: 30,
  },
  twoColumns: {
    display: 'flex',
    flexDirection: 'row',
  },
  twoColumnField: {
    width: 160,
  },
  paddedRight: {
    paddingRight: 20,
  },
  marginRight: {
    marginRight: 20,
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioElement: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 6,
    marginTop: 4,
    width: 160,
  },
  actionButtonsContainer: {
    paddingBottom: 20,
  },
  disabledInput: {
    opacity: '0.6 !important',
  },
});

export default styles;
