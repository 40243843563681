import { takeLatest, put } from 'redux-saga/effects';
import {
  GET_IMPORT_SUMMARY,
  getImportSummarySuccess,
} from 'Store/Areas/Import/ImportSummaryActions';
import { SUMMARY_GET_IMPORT_SUMMARY, REMOVE_FILE_IMPORT_ROWS } from 'Store/Api/ApiEndpoints';
import { Get, Post } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';

/* Worker Sagas */
function* getImportSummarySaga(action) {
  const response = yield Get(SUMMARY_GET_IMPORT_SUMMARY(action.periodId));
  if (response.ok) {
    const data = yield response.json();
    yield put(getImportSummarySuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* removeFileImportRows(action) {
  const response = yield Post(REMOVE_FILE_IMPORT_ROWS, action.payload);
  if (!response.ok) {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* importSummarySagas() {
  yield takeLatest(GET_IMPORT_SUMMARY, getImportSummarySaga);
  yield takeLatest(REMOVE_FILE_IMPORT_ROWS, removeFileImportRows);
}
