export const styles = theme => ({
  card: {
    marginLeft: 20,
    marginRight: 20,
    padding: 20,
    marginBottom: 18,
  },
  fullWidth: {
    width: '100%',
  },
  description: {
    margin: 0,
    marginRight: 39,
    textAlign: 'justify',
    fontSize: theme.constants.fontSizes.regular,
    lineHeight: '22px',
  },
  title: {
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  gridRow: {
    paddingBottom: 15,
  },
  previewTableGrid: {
    width: 0, /* this seems to fix a layout issue causing the grid to stretch out beyond 100% */
  },
  fullHeight: {
    height: '90vh',
  },
  disabled: {
    pointerEvents: 'none',
  },
  fontBold: {
    fontWeight: 'bold',
  },
});
