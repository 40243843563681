import {
  GET_SETTINGS_DATA,
  GET_SETTINGS_DATA_SUCCESS,
  UPDATE_SETTINGS_DATA,
  UPDATE_SETTINGS_DATA_SUCCESS,
  GET_SITE_MESSAGE,
  GET_SITE_MESSAGE_SUCCESS,
} from 'Store/Areas/Settings/SettingsActions';

const initialState = {
  data: [],
  loading: true,
  siteMessage: '',
};

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS_DATA_SUCCESS:
    case UPDATE_SETTINGS_DATA_SUCCESS:
      return Object.assign({}, state, {
        data: action.data,
        loading: action.loading,
      });
    case GET_SETTINGS_DATA:
    case UPDATE_SETTINGS_DATA:
      return Object.assign({}, state, {
        loading: action.loading,
      });
    case GET_SITE_MESSAGE:
      return Object.assign({}, state, {
        siteMessage: '',
        loading: action.loading,
      });
    case GET_SITE_MESSAGE_SUCCESS:
      return Object.assign({}, state, {
        siteMessage: action.siteMessage,
        loading: action.loading,
      });
    default:
      return state;
  }
}
