import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect as reduxConnect } from 'react-redux';
import { DragSource } from 'react-dnd';
import { withStyles, withTheme } from '@material-ui/core';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import DragnDrop from 'Constants/DragnDrop';
import { strings } from 'Constants/Categorization/Strings';
import DragIcon from 'Assets/Images/drag-icon.svg';
import { styles } from './CreateRuleDragLayer.styles';

class CreateRuleDragLayer extends React.PureComponent {
  render() {
    const {
      classes,
      allowDrag,
      connectDragSource,
    } = this.props;

    return (
      <div className={classes.contentRoot} title={strings.createRuleTooltip}>
        <div className={classes.createRuleTextWrapper}>
          {allowDrag &&
              connectDragSource(<div className={classes.dragSource} />)
          }
          <span className={classes.createRuleText}>
            {strings.createRuleText}
          </span>
          &nbsp;
          <img
            src={DragIcon}
            alt={strings.createRuleText}
            className={classes.icon}
          />
        </div>
      </div>
    );
  }
}

CreateRuleDragLayer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  connectDragSource: PropTypes.func.isRequired,
  allowDrag: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  rule: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
  };
}

const spec = {
  beginDrag(props) {
    props.onBeginDrag();

    return {
      text: props.rule,
      note: props.note,
      type: DragnDrop.customRule,
    };
  },
  endDrag(props, monitor) {
    props.onEndDrag();

    if (monitor.didDrop()) {
      const { onDrop } = monitor.getDropResult();
      onDrop();
    }
  },
};

function collect(connect) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  };
}

export default compose(
  DragSource(DragnDrop.customRule, spec, collect),
  withStyles(styles),
  withTheme(),
  reduxConnect(mapStateToProps),
)(CreateRuleDragLayer);