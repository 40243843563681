import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { title } from 'Constants/App/Titles/Strings';
import { withStyles, withTheme, Paper } from '@material-ui/core';
import { strings } from 'Constants/Welcome/Strings';
import { getSiteMessage } from 'Store/Areas/Settings/SettingsActions';
import { siteMessageSelector } from 'Store/Areas/Settings/SettingsSelector';
import styles from './Welcome.styles';

class Welcome extends React.PureComponent {
  componentDidMount() {
    document.title = title.welcome;
    this.props.dispatch(getSiteMessage());
  }

  getVersion =(version) => {
    const index = version.lastIndexOf('-');
    if (index !== -1) {
      const buildNo = version.substr(index + 1);
      return `${version.substr(0, index)} Build - ${buildNo}`;
    }
    return version;
  }

  render() {
    const {
      classes,
      name,
      productVersion,
      siteMessage,
    } = this.props;
    return (
      <div className={classes.wrapper}>
        <If condition={siteMessage !== ''}>
          <Paper className={classes.siteMessageWrapper}>
            <h1 className={classes.siteMessage}>{siteMessage}</h1>
          </Paper>
        </If>
        <div className={classes.root}>
          <span className={classes.largeText}>
            {strings.welcomeMessage}<span className={classes.userName}>{name}</span>!
          </span>
          <p>{strings.choosePeriod}</p>
        </div>
        <div>
          <span>
            <p className={classes.smallText}>{strings.kpmgLegal}</p>
            <p className={`${classes.smallText} ${classes.version}`}>{strings.version}{this.getVersion(productVersion)}</p>
          </span>
        </div>
      </div>
    );
  }
}

Welcome.defaultProps = {
  siteMessage: '',
};

Welcome.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  productVersion: PropTypes.string.isRequired,
  siteMessage: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    name: state.user.permissions.data.name,
    productVersion: state.app.config.currentVersion,
    siteMessage: siteMessageSelector(state),
  };
}

export default compose(
  withTheme(),
  withStyles(styles),
  connect(mapStateToProps),
)(Welcome);
