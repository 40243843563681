import { put } from 'redux-saga/effects';
import { userActions } from 'Constants/UserActions';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import SignalrStrategyHandler from './SignalrStrategyHandler';

function* handleConflictEvent(payload, action) {
  if (payload.error) {
    yield this.handleError(action);
  } else {
    yield put(getPeriod(action.selectedPeriodId));
  }
}

export const genericConflictHandler = new SignalrStrategyHandler(
  [userActions.ResolveConflict,
    userActions.AllowConflict,
    userActions.AllowAllConflicts,
    userActions.EditRulePriority],
  function* handler(payload, action) {
    yield handleConflictEvent(payload, action);
  },
);
