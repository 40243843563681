import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bulkUserUploadProcessingStages, bulkUserUploadImportStages } from 'Constants/BulkUpload/BulkUserUploadStages';
import { bulkUserUploadMessageArray } from 'Constants/BulkUpload/Strings';
import ProgressBar from 'Components/Processing/ProgressBar/ProgressBar';
import ProcessingStatus from 'Components/Shared/ProcessingStatus/ProcessingStatus';
import BulkUserUploadResult from './BulkUserUploadResult';
import FailedBulkUserUploadScanModal from './FailedBulkUserUploadScanModal';

class BulkUserUploadProcessingContainer extends React.PureComponent {
  render() {
    const {
      currentStage,
    } = this.props;

    return (
      <Fragment>
        <Choose>
          <When condition={
            currentStage === bulkUserUploadImportStages.uploading ||
            currentStage === bulkUserUploadImportStages.passedScan}
          >
            <ProcessingStatus
              processingMessageArray={bulkUserUploadMessageArray}
              renderProgressBar={() => (<ProgressBar
                stage={currentStage}
                processingStages={bulkUserUploadProcessingStages}
              />)}
            />
          </When>
          <When condition={currentStage === bulkUserUploadImportStages.failedScan}>
            <FailedBulkUserUploadScanModal />
          </When>
          <Otherwise>
            <BulkUserUploadResult />
          </Otherwise>
        </Choose>
      </Fragment>
    );
  }
}

BulkUserUploadProcessingContainer.propTypes = {
  currentStage: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    currentStage: state.bulkUserUpload.stage,
  };
}

export default connect(mapStateToProps)(BulkUserUploadProcessingContainer);
