export const UPDATE_TAG_EXPORT_PIVOT_TABLE = 'EXPORT_PIVOT_TABLE/UPDATE_TAG_EXPORT_PIVOT_TABLE';
export const GET_FILTER_TAG_VALUES = 'EXPORT_PIVOT_TABLE/GET_FILTER_TAG_VALUES';
export const GET_FILTER_TAG_VALUES_SUCCESS = 'EXPORT_PIVOT_TABLE/GET_FILTER_TAG_VALUES_SUCCESS';

export function updateTagExportPivotTable(tag, column, isAdding) {
  return {
    type: UPDATE_TAG_EXPORT_PIVOT_TABLE,
    tag: tag,
    column: column,
    isAdding: isAdding,
  };
}

export function getFilterTagValues(periodId, filterTag, filterQuery) {
  return {
    type: GET_FILTER_TAG_VALUES,
    filterLoading: true,
    payload: {
      periodId: periodId,
      filterTag: filterTag,
      filterQuery: filterQuery,
    },
  };
}

export function getFilterTagValuesSuccess(filterValues) {
  return {
    type: GET_FILTER_TAG_VALUES_SUCCESS,
    filterLoading: false,
    filterValues: filterValues,
  };
}