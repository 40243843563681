const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 150,
    margin: 10,
    overflow: 'hidden',
  },
  message: {
    fontSize: theme.constants.fontSizes.small,
    marginRight: 50,
  },
  loader: {
    margin: 0,
  },
});

export default styles;
