import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { strings } from 'Constants/Conflicts/Strings';
import { rulePriorities } from 'Constants/Categorization/RulePriorities';
import { commonStrings } from 'Constants/CommonStrings';
import DiscreteSlider from 'Components/Shared/Slider/DiscreteSlider';
import Modal from 'Components/Shared/Modal/StyledModal';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import { closeEditPriorityModal, editRulePriority } from 'Store/Areas/Rule/RulePriorityActions';
import { setConflictAction } from 'Store/Areas/Conflict/ConflictActions';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import styles from './EditPriorityModal.styles';

class EditPriorityModal extends React.PureComponent {
  state = {
    sliderValue: this.props.rulePriority.priorityLevel,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.rulePriority.priorityLevel !== this.props.rulePriority.priorityLevel) {
      this.updateSliderValue();
    }
  }

  onCancelClick = () => {
    this.props.dispatch(closeEditPriorityModal());
  }

  onEditPriorityClick = () => {
    const { sliderValue } = this.state;
    const {
      dispatch,
      periodId,
      rulePriority: { ruleId, ruleText },
      isConflictAction,
    } = this.props;

    if (isConflictAction) {
      dispatch(setConflictAction());
    }

    dispatch(editRulePriority(
      periodId,
      ruleId,
      ruleText,
      sliderValue,
    ));
  }

  onValueChange = (value) => {
    this.setState({
      sliderValue: Number(value),
    });
    this.forceUpdate();
  }

  updateSliderValue() {
    this.setState({
      sliderValue: this.props.rulePriority.priorityLevel,
    });
  }

  render() {
    const {
      classes,
      rulePriority,
    } = this.props;

    const { sliderValue } = this.state;
    return (
      <Fragment>
        <div className={classes.root}>
          <Modal open={rulePriority.open} width={false} fullWidth>
            <div className={classes.content}>
              <div className={classes.title}>
                {strings.rulePriority}
              </div>
              <div className={classes.textArea}>
                <span className={classes.note}>
                  {strings.ruleDescription}
                </span>
              </div>
              <div className={classes.textArea}>
                <span className={classes.noteBold}>
                  {strings.changePriorityLevel}
                </span>
              </div>
              <div>
                <div className={classes.labelArea}>
                  <For each="priority" of={rulePriorities}>
                    <span
                      key={priority.value}
                      className={sliderValue === priority.value
                        ? classes.activeLabel
                        : classes.label}
                    >
                      {priority.label}
                    </span>
                  </For>
                </div>
                <DiscreteSlider
                  className={classes.slider}
                  onValueChange={this.onValueChange}
                  value={sliderValue}
                  min={0}
                  max={45}
                  step={5}
                />
              </div>
              <div className={classes.textArea}>
                <span className={classes.noteBold}>
                  {strings.rule}
                </span>
              </div>
              <div className={classes.textArea}>
                <span className={classes.note}>
                  {rulePriority.ruleText}
                </span>
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  disableRipple
                  backgroundColor={constants.backgroundColor.dark}
                  className={classes.button}
                  onClick={this.onCancelClick}
                >
                  {commonStrings.cancel}
                </Button>
                <Button
                  disableRipple
                  backgroundColor={constants.backgroundColor.main}
                  className={classes.button}
                  onClick={this.onEditPriorityClick}
                >
                  {strings.editPriorityButton}
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

EditPriorityModal.defaultProps = {
  isConflictAction: false,
};

EditPriorityModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
  rulePriority: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    ruleText: PropTypes.string.isRequired,
    ruleId: PropTypes.number.isRequired,
    priorityLevel: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
  }).isRequired,
  isConflictAction: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    rulePriority: state.rules.rulePriority,
    periodId: periodIdSelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(EditPriorityModal);
