import React from 'react';
import PropTypes from 'prop-types';

class BarSegment extends React.PureComponent {
  render() {
    const {
      className,
      category,
      totalAmount,
      viewLineItems,
      handlePopoverClose,
      handlePopoverOpen,
    } = this.props;

    const width = ((viewLineItems ? category.lineItems : category.amountValue) / totalAmount) * 100;

    return (
      <If condition={width}>
        <div
          key={category.name}
          className={className}
          style={{ backgroundColor: category.color, width: `${width}%` }}
          onMouseOver={x => handlePopoverOpen(x)}
          onFocus={x => handlePopoverOpen(x)}
          onMouseOut={() => handlePopoverClose()}
          onBlur={() => handlePopoverClose()}
        />
      </If>
    );
  }
}

BarSegment.defaultProps = {
  className: '',
  viewLineItems: false,
};

BarSegment.propTypes = {
  className: PropTypes.string,
  viewLineItems: PropTypes.bool,
  category: PropTypes.shape({
    color: PropTypes.string,
    name: PropTypes.string,
    amountValue: PropTypes.number,
  }).isRequired,
  totalAmount: PropTypes.number.isRequired,
  handlePopoverOpen: PropTypes.func.isRequired,
  handlePopoverClose: PropTypes.func.isRequired,
};

export default BarSegment;
