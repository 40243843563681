const styles = theme => ({
  paperRoot: {
    display: 'flow-root',
    flexDirection: 'column',
    flex: '1 1 auto',
    minHeight: 0,
    overflowX: 'hidden',
    maxWidth: 'Calc(150vh - 0px)',
  },
  paper: {
    backgroundColor: theme.palette.primary.messageBackground,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    minHeight: 0,
    paddingTop: 20,
    width: 400,
    margin: '0 20px 0 20px',
    overflowX: 'hidden',
  },
  header: {
    margin: '10px 10px 10px 10px',
    fontSize: theme.constants.fontSizes.regular,
  },
  note: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    color: theme.palette.primary.error,
    overflowWrap: 'break-word',
    maxWidth: 300,
  },
  keywordRule: {
    fontSize: theme.constants.fontSizes.small,
  },
  definition: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: theme.constants.fontSizes.small,
  },
  checkbox: {
    padding: 0,
  },
  buttons: {
    height: 60,
    paddingTop: 30,
  },
  card: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    maxWidth: 370,
  },
  input: {
    background: 'transparent',
    fontSize: theme.constants.fontSizes.small,
    minWidth: 75,
    maxWidth: 300,
    minHeight: 30,
    maxHeight: 70,
    padding: '1px 2px',
    borderStyle: 'solid',
    borderColor: '#c8c8c8',
    marginLeft: 5,
    borderWidth: 1,
    '& > div': {
      overflowY: 'auto',
      display: 'block',
      minHeight: 0,
      marginBottom: 0,
      '& input': {
        margin: 0,
        padding: 0,
      },
    },
  },
  customChip: {
    backgroundColor: '#E6EBF3',
    width: 'auto',
    height: 30,
  },
  scroll: {
    width: '100%',
    marginTop: 20,
  },
  priorityTable: {
    width: '100%',
  },
  priorityNote: {
    fontSize: theme.constants.fontSizes.regular,
    paddingLeft: 350,
  },
  amountInput: {
    width: 'Calc(100% - 10px)',
    minHeight: 30,
    maxHeight: 70,
    background: 'transparent',
    fontSize: theme.constants.fontSizes.small,
    padding: '1px 2px',
    borderStyle: 'solid',
    borderColor: '#c8c8c8',
    marginLeft: 5,
    borderWidth: 1,
    '& > div': {
      overflowY: 'auto',
      display: 'block',
      minHeight: 0,
      marginBottom: 0,
      '& input': {
        margin: 0,
        padding: 0,
      },
    },
  },
  priorityInput: {
    minWidth: 30,
    maxWidth: 50,
    height: 25,
    background: 'transparent',
    fontSize: theme.constants.fontSizes.small,
    padding: '1px 2px',
    borderStyle: 'solid',
    borderColor: '#c8c8c8',
    marginLeft: 5,
    borderWidth: 1,
    '& > div': {
      overflowY: 'auto',
      display: 'block',
      minHeight: 0,
      marginBottom: 0,
      '& input': {
        margin: 0,
        padding: 0,
      },
    },
  },
  textInput: {
    padding: '10px 0px 10px 10px',
    fontSize: theme.constants.fontSizes.small,
  },
  tableCell: {
    padding: '5px 25px 5px 0px',
    fontSize: theme.constants.fontSizes.small,
  },
  keywordcard: {
    paddingTop: 0,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    minHeight: 'Calc(48vh - 61px)',
    maxHeight: 'Calc(53vh - 110px)',
    overflowX: 'auto',
    overflowY: 'auto',
  },
  headerText: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.small,
  },
  sequenceText: {
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.small,
  },
  headerTableCell: {
    padding: '0 10px 0 0',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:first-child': {
      width: '40%',
    },
    '&:last-child': {
      width: '100%',
    },
    overflow: 'hidden',
  },
  descriptionCellWidth: {
    minWidth: 50,
    maxWidth: 50,
  },
  tableHeaderRow: {
    height: 25,
  },
  marginTop: {
    marginTop: 10,
  },
});

export default styles;
