const styles = theme => ({
  drawerPaper: {
    height: '100%',
    position: 'fixed',
    marginLeft: theme.constants.sidebarWidths.primary,
    backgroundColor: theme.palette.primary.darkAlt,
  },
  sidebarExpanded: {
    width: theme.constants.sidebarWidths.secondary,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sidebarCollapsed: {
    width: theme.constants.sidebarWidths.secondaryCollapsed,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    flexGrow: 1,
    marginLeft: theme.constants.sidebarWidths.secondaryCollapsed,
    paddingTop: 40,
  },
  staticSidebarContent: {
    marginLeft: theme.constants.sidebarWidths.secondary,
  },
  toggle: {
    zIndex: theme.zIndex.drawer + 1,
    width: theme.constants.sidebarWidths.secondaryCollapsed,
    position: 'absolute',
    top: 0,
    right: 0,
  },
  toggleExtended: {
    zIndex: theme.zIndex.drawer + 1,
    width: theme.constants.sidebarWidths.secondaryCollapsed,
    position: 'absolute',
    top: 20,
    right: 0,
    marginRight: 21,
  },
  sidebarWrapper: {
    flexGrow: 1,
    height: '100%',
  },
  loaderWrapper: {
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  loader: {
    alignSelf: 'center',
    margin: 10,
  },
  bannerShift: {
    marginTop: '6vh',
  },
});

export default styles;
