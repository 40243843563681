export const numericOperators = {
  greaterThan: '>',
  lessThan: '<',
  greaterThanOrEqual: '>=',
  lessThanOrEqual: '<=',
};
export const textOperators = {
  in: 'IN',
  equals: 'EQUALS',
  contains: 'CONTAINS',
  notEquals: 'NOT_EQUALS',
  notContains: 'NOT_CONTAINS',
  substring: 'SUBSTRING',
  fuzzyContains: 'FUZZY_CONTAINS',
  matchRegex: 'MATCHES_REGEX',
  startsWith: 'STARTS_WITH',
  endsWith: 'ENDS_WITH',
};
export const joinWords = {
  and: 'AND',
  or: 'OR',
  andNot: 'AND NOT',
  orNot: 'OR NOT',
};
export const operatorTypes = {
  arithmetic: 'arithmetic',
  keyword: 'keyword',
};

export const ruleTypes = {
  dragAndDropRule: 0,
  customRule: 1,
  referenceListRule: 2,
  ApportionableRule: 3,
};

export const validationRules = {
  luceneRegexMaxLength: 255,
};