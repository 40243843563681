import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import { sortOrder as sortOrders } from 'Constants/Categorization/QueryTermFilters';
import ChevronDown from 'Assets/Images/chevron-down-blue-ic.svg';
import ChevronUp from 'Assets/Images/chevron-up-blue-ic.svg';
import Line from 'Assets/Images/line-blue.svg';
import { styles } from './SortIcon.styles';

class SortIcon extends PureComponent {
  render() {
    const {
      isSorting,
      sortOrder,
      classes,
    } = this.props;
    return (
      <span className={classes.root}>
        <If condition={!isSorting}>
          <img src={Line} alt="" className={classes.sortIcon} />
        </If>
        <If condition={isSorting}>
          <img src={sortOrder === sortOrders.valueAscending ? ChevronUp : ChevronDown} alt="" className={classes.sortIcon} />
        </If>
      </span>
    );
  }
}


SortIcon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isSorting: PropTypes.bool.isRequired,
  sortOrder: PropTypes.oneOf(Object.values(sortOrders)).isRequired,
};


export default compose(withStyles(styles), withTheme())(SortIcon);
