import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Card, Grid } from '@material-ui/core';
import ArrowButton from 'Components/Shared/Buttons/ArrowButton/ArrowButton';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import TagsPanel from 'Components/Upload/MappingPage/TagsPanel/TagsPanel';
import AddTagModal from 'Components/Upload/MappingPage/TagsPanel/AddTagModal';
import DeleteTagModal from 'Components/Upload/MappingPage/TagsPanel/DeleteTagModal';
import PreviewTable from 'Components/Upload/MappingPage/PreviewTable/PreviewTable';
import { strings as tagMappingStrings } from 'Constants/Upload/TagMapping';
import ErrorModal from 'Components/Shared/Modal/ErrorModal';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import { toggleAddTagModal, postNewTag, toggleDeleteTagModal, deleteTag, toggleMapMandatoryTagsModal } from 'Store/Areas/Import/TagMappingActions';
import { commonStrings } from 'Constants/CommonStrings';
import { selectMappedTagsFromPeriod } from 'Constants/Categorization/Tags';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { supporting, main } from 'Constants/FileTypes';
import {
  periodInUseSelector,
  periodLockedByUserIdSelector,
} from 'Store/Areas/Period/PeriodSelectors';
import { styles } from './PageContent.styles';

class PageContent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      nnmappedTagsModalOpen: false,
      isInvalidTag: false,
      invalidTags: '',
    };
  }

  onProceedClick = () => {
    if (!this.checkInvalidTagNameExists()) {
      if (this.checkMappedTags(true)) {
        this.onProceedConfirmClick(false);
      } else {
        this.toggleMandatoryTagModal(true);
      }
    } else {
      this.toggleInvalidTagModal(true);
    }
  }

  onProceedConfirmClick = (bypassNonMandatory) => {
    if (this.checkMappedTags(false) || bypassNonMandatory) {
      this.toggleNonMandatoryTagModal(false);
      this.props.onProceedClick();
    } else {
      this.toggleNonMandatoryTagModal(true);
    }
  }

  checkInvalidTagNameExists() {
    const { tags } = this.props;
    let isInvalidTagsPresent = false;
    const matchedTags = tags.filter((tag) => {
      return commonStrings.elasticDocVariables.some(elasticVariable =>
        tag.name.toLowerCase() === elasticVariable);
    }).map(t => t.name);
    if (matchedTags.length > 0) {
      this.setState({
        invalidTags: matchedTags.toString(),
      });
      isInvalidTagsPresent = true;
    }
    return isInvalidTagsPresent;
  }
  checkMappedTags(checkMandatoryTags) {
    const { schema, tags, fileType } = this.props;

    let mappedIds = [];

    if (schema && schema.headers) {
      mappedIds = schema.headers.filter(x => x.fileType === fileType).map(x => x.tagId);
    }
    let isValid = true;

    tags
      .filter(x => x.isMandatory === !!checkMandatoryTags)
      .forEach((tag) => {
        isValid = isValid && mappedIds.indexOf(tag.id) > -1;
      });

    return isValid;
  }

  toggleMandatoryTagModal(active) {
    this.props.dispatch(toggleMapMandatoryTagsModal(active));
  }

  toggleInvalidTagModal(active) {
    this.setState({
      isInvalidTag: active,
    });
  }

  toggleAddTagModal(active) {
    this.props.dispatch(toggleAddTagModal(active));
  }

  toggleDeleteTagModal(active, tagName, tagId) {
    this.props.dispatch(toggleDeleteTagModal(active, tagName, tagId));
  }

  toggleNonMandatoryTagModal(active) {
    this.setState({
      nnmappedTagsModalOpen: active,
    });
  }

  addNewTag(tagName, dataType) {
    if (!commonStrings.elasticDocVariables.some(v => v === tagName.toLowerCase())) {
      this.props.dispatch(postNewTag(tagName, dataType, this.props.periodId));
      this.toggleInvalidTagModal(false);
    } else {
      this.setState({
        invalidTags: tagName,
      });
      this.toggleInvalidTagModal(true);
    }
  }

  deleteTag(tagName, tagId) {
    this.props.dispatch(deleteTag(tagName, tagId, this.props.periodId));
  }

  render() {
    const {
      classes,
      tagMapping,
      existingTags,
      title,
      description,
      renderBreadcrumb,
      onBackClick,
      fileType,
      periodInUse,
      periodLockedByUserId,
      permissions,
    } = this.props;

    const { nnmappedTagsModalOpen, isInvalidTag, invalidTags } = this.state;

    const showAddTagModal = tagMapping.addTag.showModal;
    const showDeleteTagModal = tagMapping.deleteTag.showModal;
    const { showMandatoryTagModal, autoMapLoading } = tagMapping.mapTag;

    if (autoMapLoading) {
      return (<CircularLoader size={60} className={classes.fullHeight} />);
    }

    return (
      <Fragment>
        {renderBreadcrumb()}
        <Card className={`${classes.card} ${(periodInUse && periodLockedByUserId !== permissions.id) ? classes.disabled : ''}`}>
          <Grid container direction="row" alignItems="stretch">
            <Grid container justify="flex-end">
              <Grid item xs={12} className={classes.gridRow} >
                <ArrowButton onClickEvent={onBackClick} />
              </Grid>
              <Grid item xs={9} >
                <div className={classes.title}>
                  {title}
                </div>
                <div className={classes.description} >
                  {description}
                </div>
              </Grid>
              <Grid item xs={3} >
                <Button
                  className={classes.fullWidth}
                  onClick={this.onProceedClick}
                  backgroundColor={constants.backgroundColor.main}
                  height={constants.height.big}
                >
                  {commonStrings.proceed}
                </Button>
              </Grid>
              <Grid item xs={12} >
                <TagsPanel fileType={fileType} isInvalidTag={isInvalidTag} />
              </Grid>
              <Grid item xs={12} className={classes.previewTableGrid}>
                <PreviewTable fileType={fileType} />
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <AddTagModal
          open={showAddTagModal}
          onCancel={() => this.toggleAddTagModal(false)}
          onAccept={(tagName, dataType) => this.addNewTag(tagName, dataType)}
          errorMessage={tagMapping.addTag.errorMessage}
          loading={tagMapping.addTag.loading}
          existingTags={existingTags}
          isInvalidTag={isInvalidTag}
        />
        <DeleteTagModal
          open={showDeleteTagModal}
          onCancel={() => this.toggleDeleteTagModal(false, '', 0)}
          onAccept={(tagName, tagId) => this.deleteTag(tagName, tagId)}
          errorMessage={tagMapping.deleteTag.errorMessage}
          loading={tagMapping.deleteTag.loading}
          tagName={tagMapping.deleteTag.tagName}
          tagId={tagMapping.deleteTag.tagId}
        />
        <ErrorModal
          open={showMandatoryTagModal || isInvalidTag}
          onClose={() => this.toggleMandatoryTagModal(false)}
          title={tagMappingStrings.mandatoryTagsModalTitle}
          error={tagMappingStrings.mandatoryTagsModalText}
        />
        <ErrorModal
          open={isInvalidTag}
          onClose={() => this.toggleInvalidTagModal(false)}
          title={tagMappingStrings.invalidTagsModalTitle}
        >
          <div>
            {tagMappingStrings.invalidTagsModalText}
            <div className={classes.fontBold}>
              {invalidTags}
            </div>
          </div>
        </ErrorModal>
        <ConfirmationModal
          open={nnmappedTagsModalOpen}
          title={tagMappingStrings.unmappedTagsModalTitle}
          message={tagMappingStrings.unmappedTagsModalText}
          acceptText={commonStrings.proceed}
          onConfirm={() => this.onProceedConfirmClick(true)}
          onCancel={() => this.toggleNonMandatoryTagModal(false)}
        />
      </Fragment>
    );
  }
}

PageContent.defaultProps = {
  periodLockedByUserId: -1,
};

PageContent.propTypes = {
  fileType: PropTypes.oneOf([supporting, main]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  renderBreadcrumb: PropTypes.func.isRequired,
  onProceedClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  /* HOC props */
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  /* Redux props */
  existingTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  tagMapping: PropTypes.shape({
    addTag: PropTypes.shape({
      showModal: PropTypes.bool,
    }).isRequired,
    deleteTag: PropTypes.shape({
      showModal: PropTypes.bool,
      tagName: PropTypes.string,
      tagId: PropTypes.number,
    }).isRequired,
    mapTag: PropTypes.shape({
      loading: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  periodId: PropTypes.number.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  schema: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      tagId: PropTypes.int,
      id: PropTypes.int,
    })),
  }).isRequired,
  periodInUse: PropTypes.bool.isRequired,
  periodLockedByUserId: PropTypes.number,
  permissions: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    tagMapping: state.import.tagMapping,
    existingTags: selectMappedTagsFromPeriod(state).map(t => t.name),
    periodId: state.periods.period.periodId,
    tags: selectMappedTagsFromPeriod(state),
    schema: state.periods.period.data.fileSchema,
    periodInUse: periodInUseSelector(state),
    periodLockedByUserId: periodLockedByUserIdSelector(state),
    permissions: state.user.permissions.data,
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTheme(),
)(PageContent);
