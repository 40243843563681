import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, IconButton } from '@material-ui/core';
import Close from 'Assets/Images/exit-ic.svg';
import DataGrid from 'Components/Shared/DataGrid/DataGrid';
import withSize from 'Components/Shared/SizeProvider/SizeProvider';
import { strings, exportFilesColumns, exportPageType } from 'Constants/Export/Strings';
import { periodStatuses } from 'Constants/PeriodStatuses';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import SimpleCell from 'Components/Shared/DataGrid/Cells/SimpleCell/SimpleCell';
import SimpleButton from 'Components/Shared/Buttons/SimpleButton/SimpleButton';
import { periodIdSelector, periodNameSelector, periodStatusSelector } from 'Store/Areas/Period/PeriodSelectors';
import {
  exportLoadingSelector,
  exportFilesSelector,
  externalReviewExportedFilesSelector,
  exportLockedSelector,
} from 'Store/Areas/Export/ExportSelectors';
import { downloadExportFile, getExportFiles, deleteExportFile } from 'Store/Areas/Export/ExportActions';
import {
  downloadExternalReviewExportFile,
  deleteExternalReviewExportFile,
  cancelExternalReview,
} from 'Store/Areas/Export/ExternalReviewActions';
import { getLocalDateString } from 'Helpers/DateHelpers';
import { styles } from './FileList.styles';
import DeleteExportFileModal from './DeleteExportFileModal';

class FileList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
      selectedId: 0,
    };
  }

  componentDidMount() {
    const { dispatch, periodId, exportType } = this.props;
    if (exportType === exportPageType.exportReports) {
      dispatch(getExportFiles(periodId));
    }
  }

  onDownloadLinkClicked = rowIndex => () => {
    const {
      periodId, dispatch, files, exportType, externalReviewFiles,
    } = this.props;
    if (exportType === exportPageType.exportReports) {
      dispatch(downloadExportFile(periodId, files[rowIndex].id));
    } else if (exportType === exportPageType.exportExternalReview) {
      dispatch(downloadExternalReviewExportFile(periodId, externalReviewFiles[rowIndex].id));
    }
  }

  onDeleteExportedFile = () => {
    const { selectedId } = this.state;
    const {
      periodId,
      dispatch,
      exportType,
      periodName,
    } = this.props;
    if (exportType === exportPageType.exportReports) {
      dispatch(deleteExportFile(periodId, selectedId));
    } else if (exportType === exportPageType.exportExternalReview) {
      dispatch(deleteExternalReviewExportFile(periodId, selectedId, periodName));
      dispatch(cancelExternalReview());
    }
    this.onCloseDelete();
  }

  onOpenDelete = (id) => {
    this.setState({
      isDeleteModalOpen: true,
      selectedId: id,
    });
  }

  onCloseDelete = () => {
    this.setState({
      isDeleteModalOpen: false,
    });
  }

  getWidth = () => {
    const { width } = this.props;
    return width / 3;
  };

  getValue = ({ columnIndex, rowIndex }) => {
    const {
      files, classes, externalReviewFiles, exportType, showDelete,
    } = this.props;
    const file = exportType === exportPageType.exportReports ?
      files[rowIndex] :
      externalReviewFiles[rowIndex];
    switch (columnIndex) {
      case 0:
        return file.fileName;
      case 1:
        return getLocalDateString(file.creationDate);
      case 2:
        if (showDelete) {
          return (
            <div className={classes.flexContainer}>
              <div className={classes.text}>
                {file.expiryDays} days
              </div>
              <div className={classes.deleteButton}>
                <IconButton
                  onClick={() => this.onOpenDelete(file.id)}
                  className={classes.icon}
                >
                  <img src={Close} alt="remove" />
                </IconButton>
              </div>
            </div>
          );
        }
        return (
          <div className={classes.flexContainer}>
            <div className={classes.text}>
              {file.expiryDays} days
            </div>
          </div>
        );
      default:
        return '';
    }
  };

  render() {
    const {
      currencySymbol,
      loading,
      exportLocked,
      files,
      externalReviewFiles,
      width,
      classes,
      exportType,
      periodStatus,
    } = this.props;
    const { isDeleteModalOpen } = this.state;

    return (
      <Fragment>
        <Choose>
          <When condition={!loading
            && (!exportLocked || periodStatus === periodStatuses.periodArchived)}
          >
            <DataGrid
              recomputeGridSizeKey={`${width}`}
              columns={exportFilesColumns}
              rows={exportType === exportPageType.exportReports ? files : externalReviewFiles}
              getCellValue={this.getValue}
              getWidth={this.getWidth}
              hasNextPage={loading}
              isNextPageLoading={loading}
              rowMargin={10}
              childRowHeight={40}
              parentRowHeight={40}
              noDataMessage={
                exportType === exportPageType.exportExternalReview ?
                  strings.externalReviewExportedFileExpiredMessage
                : strings.noExportFilesMessage}
              renderHeaderCell={props => (<SimpleCell
                {...props}
                currencySymbol={currencySymbol}
                className={classes.header}
              />)}
              renderCell={(props) => {
                if (props.index === 0) {
                  return (
                    <SimpleCell {...props} currencySymbol={currencySymbol} className={`${classes.cellBorder}`}>
                      <SimpleButton
                        className={classes.exportLink}
                        onClickEvent={this.onDownloadLinkClicked(props.rowIndex)}
                      >
                        {props.children}
                      </SimpleButton>
                    </SimpleCell>
                  );
                }
                return (<SimpleCell
                  {...props}
                  currencySymbol={currencySymbol}
                  className={classes.cellBorder}
                />);
              }}
            />
          </When>
          <Otherwise>
            <div className={classes.loader}>
              <CircularLoader />
            </div>
          </Otherwise>
        </Choose>
        <DeleteExportFileModal
          onConfirm={this.onDeleteExportedFile}
          onCancel={this.onCloseDelete}
          isModalOpen={isDeleteModalOpen}
        />
      </Fragment>
    );
  }
}

FileList.defaultProps = {
  exportType: 0,
  showDelete: true,
};

FileList.propTypes = {
  width: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  exportLocked: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  externalReviewFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
  periodName: PropTypes.string.isRequired,
  periodStatus: PropTypes.string.isRequired,
  exportType: PropTypes.number,
  showDelete: PropTypes.bool,
  currencySymbol: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
    periodName: periodNameSelector(state),
    periodStatus: periodStatusSelector(state),
    files: exportFilesSelector(state),
    externalReviewFiles: externalReviewExportedFilesSelector(state),
    loading: exportLoadingSelector(state),
    exportLocked: exportLockedSelector(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withSize({ style: { flexGrow: 1 } }),
  withStyles(styles),
)(FileList);
