import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styles from './WelcomeMessage.styles';

class WelcomeMessage extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <span className={classes.welcome}>You are in Admin Mode</span>
        <span className={classes.message}>Please manage groups on the left.</span>
      </div>
    );
  }
}

WelcomeMessage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(WelcomeMessage);

