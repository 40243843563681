export const OPEN_EDIT_RULE_NOTE = 'EDITRULENOTE/OPEN_EDIT_RULE_NOTE';
export const CLOSE_EDIT_RULE_NOTE = 'EDITRULENOTE/CLOSE_EDIT_RULE_NOTE';
export const REQUEST_EDIT_RULE_NOTE = 'EDITRULENOTE/REQUEST_EDIT_RULE_NOTE';
export const SUCCESS_EDIT_RULE_NOTE = 'EDITRULENOTE/SUCCESS_EDIT_RULE_NOTE';

export function openEditRuleNote() {
  return {
    type: OPEN_EDIT_RULE_NOTE,
    open: true,
  };
}

export function closeEditRuleNote() {
  return {
    type: CLOSE_EDIT_RULE_NOTE,
    open: false,
    loading: false,
  };
}

export function requestEditRuleNote(periodId, ruleIds, noteText, index) {
  return {
    type: REQUEST_EDIT_RULE_NOTE,
    loading: true,
    index,
    payload: {
      periodId: periodId,
      ruleIds: ruleIds,
      note: noteText,
    },
  };
}

export function requestEditRuleNoteSuccess(data, index) {
  return {
    type: SUCCESS_EDIT_RULE_NOTE,
    open: false,
    loading: false,
    note: data,
    index,
  };
}
