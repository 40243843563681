export const strings = {
  pageTitle: 'Supporting document line item mapping',
  pageDescription:
    'Please map each supporting document to the corresponding line item. You can use the search bar to ' +
    'look for specific information and can drag more than one line item to the same supporting file. ' +
    'Use the Shift or Ctrl keys to select more than 1 line item at once.',
  automatedMapLineItemsDescription:
    'Choose a tag from the supporting documents and a tag in the main documents to merge supporting ' +
    'documents into the main document. Once complete, click process. You can also manually map line ' +
    'items using the link below.',
  matchedSupportingAndMainDocDetailsDescription:
    'Any differences between the sum of each Supporting Document breakdown and the line(s) they replace ' +
    'in your main data can either be added as a balancing figure to be manually categorised, or automatically ' +
    'prorated in line with the analysed expenditure in each Supporting Document.',
  matchedSupportingAndMainDocDetailsDescription2:
    'Descriptions for any balancing figures will be automatically generated based on the value of the tag used to ' +
    'link each Supporting Document(e.g. "Balancing_Figure_Project_ABC"). Please choose the appropriate option ' +
    'for each matching value found in the [Tag] column below.',
  process: 'Process',
  automatedMapLineItemsButton: 'Map line items',
  supportingDocTagSelector: 'Select a Supporting Tag',
  mainDocTagSelector: 'Select a Main Tag',
  supportingDocTagLabel: 'supporting document tag',
  mainDocTagLabel: 'main document tag',
  manuallyMapLineItemsLabel: 'Manually map line items',
  addLineItems: 'Add line items',
  lineItemsAdded: 'line item(s) added',
  supporting: 'Supporting',
  search: 'Search',
  searchPlaceholder: 'Start typing to filter line items',
  noResults: 'No results match your search. Please try again.',
  balancingTitle: 'Line item and supporting document amounts do not match',
  balancingModalDescription: replacementLineItem => `The line item(s) that you have chosen to replace with '${replacementLineItem}' equate to a different value. If you would like to proceed with your current selected line items then we will need to add a balancing figure. If you want to re-select line items press cancel.`,
  balancingFigureLabel: 'Balancing figure:',
  balancingDescriptionLabel: 'Please add a description for reference',
  balancingDescriptionHint: 'e.g. Balancing_Figure_Description',
  addBalancingFigure: 'Add balancing figure',
  isProRate: 'isProRate',
  isBalancingFigure: 'isBalancingFigure',
  matchingValue: 'matchingValue',
  unmatchedValue: 'unmatchedValue',
  proRateCheckBoxAlternateText: 'Pro Rate CheckBox',
  unmatchedSupportingDocs: 'Unmatched Rows from Supporting Document',
  noMatchedRows: 'There are no matched rows from Supporting Document.',
};
