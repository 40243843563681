import { GET_CATEGORY_RULES, GET_CATEGORY_RULES_SUCCESS, RESET_CATEGORY_RULES, GET_APPORTIONABLE_CATEGORY_RULES, GET_APPORTIONABLE_CATEGORY_RULES_SUCCESS } from 'Store/Areas/Rule/CategoryRulesActions';
import { SUCCESS_EDIT_RULE_NOTE } from 'Store/Areas/Rule/EditRuleNoteActions';

const initialState = {
  loading: false,
  rules: [],
  pageNumber: 0,
  morePagesAvailable: true,
  hasSearched: false,
};

export default function categoryRulesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_RULES:
      return Object.assign({}, state, {
        loading: action.loading,
        morePagesAvailable: action.morePagesAvailable,
        hasSearched: action.hasSearched,
      });
    case GET_APPORTIONABLE_CATEGORY_RULES:
      return Object.assign({}, state, {
        loading: action.loading,
        morePagesAvailable: action.morePagesAvailable,
      });
    case GET_CATEGORY_RULES_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        morePagesAvailable: action.morePagesAvailable,
        hasSearched: action.hasSearched,
        pageNumber: action.pageNumber,
        rules: action.pageNumber > 1 ? state.rules.concat(action.rules) : action.rules,
      });
    case GET_APPORTIONABLE_CATEGORY_RULES_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        morePagesAvailable: action.morePagesAvailable,
        rules: action.pageNumber > 1 ? state.rules.concat(action.rules) : action.rules,
      });
    case SUCCESS_EDIT_RULE_NOTE: {
      const { rules } = state;
      const key = action.index;
      rules[key].notes = action.note.addedItem;
      rules[key] = state.rules[key];
      return Object.assign({}, state, {
        rules,
      });
    }
    case RESET_CATEGORY_RULES:
      return initialState;
    default:
      return state;
  }
}
