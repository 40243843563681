import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { strings } from 'Constants/Categorization/Strings';
import { beginAdding, editCategory } from 'Store/Areas/Categorisation/CategoryActions';
import { isReadOnlySelector, periodSelector } from 'Store/Areas/Period/PeriodSelectors';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { categoryListTypes } from 'Constants/Categorization/CategoryListTypes';
import { taxContexts } from 'Constants/Projects/TaxContexts';
import ApportionablesModal from 'Components/Apportionables/ApportionablesModal/ApportionablesModal';
import SplitCategorizationModal from 'Components/Categorization/Modals/SplitCategorizationModal/SplitCategorization';
import LineItemsCategorisationReasonModal from 'Components/Categorization/Modals/LineItemsCategorisationReasonModal/LineItemsCategorisationReasonModal';
import { styles } from './CategoryList.styles';
import Category from './Category';

class CategoryList extends React.PureComponent {
  onEdit = (category) => {
    this.props.dispatch(editCategory(category));
  }

  onAdd = () => {
    this.props.dispatch(beginAdding());
  }

  render() {
    const {
      classes,
      currencySymbol,
      period,
      categoryType,
      projects,
    } = this.props;

    const entity = projects.treePicker.data.filter(g => !!g.entities && g.entities.length)
      .map(g => g.entities && g.entities
        .find(e => e.projects && e.projects.find(p => p.projectId === period.data.projectId)))
      .find(p => !!p);

    const entityProjects = entity ? entity.projects : [];
    const project = period.data.projectId &&
      entityProjects.find(x => x.id === period.data.projectId);
    const taxContext = project ? project.taxContext.name : '';
    const isCorporateTaxContext = taxContexts.find(tc => tc.id === 1)
      .name.toLowerCase() === taxContext.toLowerCase();
    const apportionableCategory = period.data.categories.find(c =>
      c.name.toLowerCase() === strings.apportionable.toLowerCase()
      && c.color === strings.apportionableColor);
    const isEditable = !this.props.isReadOnly && this.props.editable;
    return (
      <div className={classes.root}>
        <Choose>
          <When condition={period.data && period.data.id}>
            <div className={classes.categoriesPanel}>
              <If condition={taxContext && apportionableCategory && isCorporateTaxContext}>
                <Category
                  currencySymbol={currencySymbol}
                  categoryType={categoryType}
                  className={classes.category}
                  category={apportionableCategory}
                  editable={false}
                />
              </If>
              <Category
                currencySymbol={currencySymbol}
                categoryType={categoryType}
                className={classes.category}
                category={{ id: 0, name: strings.splitCategories }}
                editable={false}
              />
              <If condition={period.data.categories && period.data.categories.length}>
                <For
                  each="x"
                  of={period.data.categories.filter(c => c.name.toLowerCase()
                  !== strings.apportionable.toLowerCase()
                  && c.color !== strings.apportionableColor)}
                >
                  <Category
                    currencySymbol={currencySymbol}
                    categoryType={categoryType}
                    key={x.name}
                    className={classes.category}
                    category={x}
                    editable={isEditable}
                    selectable
                    onEdit={this.onEdit}
                  />
                </For>
              </If>
            </div>
            <If condition={isEditable}>
              <button
                className={classes.links}
                onClick={this.onAdd}
              >
                {strings.addNewCategory}
              </button>
            </If>
          </When>
          <Otherwise>
            <CircularLoader />
          </Otherwise>
        </Choose>
        <SplitCategorizationModal />
        <ApportionablesModal />
        <LineItemsCategorisationReasonModal />
      </div>
    );
  }
}

CategoryList.defaultProps = {
  editable: true,
};

CategoryList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  period: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    data: PropTypes.object,
  }).isRequired,
  editable: PropTypes.bool,
  isReadOnly: PropTypes.bool.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  categoryType: PropTypes.oneOf(Object.values(categoryListTypes.categoryType)).isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    period: periodSelector(state),
    isReadOnly: isReadOnlySelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(CategoryList);
