const styles = theme => ({
  root: {
    backgroundColor: theme.palette.colors.white,
    boxShadow: '0 2px 4px 0 rgba(194, 194, 194, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Arial',
    fontSize: theme.constants.fontSizes.small,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    padding: 8,
    width: 300,
  },
  titleRow: {
    alignItems: 'center',
    color: theme.palette.colors.black,
    display: 'flex',
    height: 14,
    marginBottom: 12,
  },
  color: {
    borderRadius: '50%',
    height: 10,
    marginRight: 5,
    width: 10,
  },
  title: {
    fontWeight: 'bold',
    height: 14,
    width: 63,
    flexGrow: '1',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 10,
  },
  value: {
    fontWeight: 'normal',
    textAlign: 'center',
  },
  priorityRow: {
    display: 'flex',
  },
  priority: {
    border: '.5px dashed grey',
    borderRadius: 4,
    color: theme.palette.primary.darkText,
    height: 14,
    letterSpacing: 0,
    opacity: '0.6',
    padding: 8,
    textAlign: 'center',
  },
  highPriority: {
    flex: '0.5 0 auto',
    marginRight: 8,
  },
  regularPriority: {
    flex: '1 0 auto',
  },
  fullWidth: {
    flexGrow: 1,
  },
  priorityHovered: {
    backgroundColor: 'rgba(0, 48, 135, 0.1)',
    border: '.5px dashed #003087',
  },
  edit: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    marginLeft: 16,
    zIndex: theme.zIndex.drawer - 2, // prevents click on div underneath
  },
  editHidden: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    marginLeft: 16,
    visibility: 'hidden',
    zIndex: theme.zIndex.drawer - 2, // prevents click on div underneath
  },
  surroundButton: {
    textDecoration: 'none',
  },
});

export default styles;
