export const strings = {
  importProcessingMessagePart1: 'Your files are now being processed',
  importProcessingMessagePart2: 'To make the categorisation work smoothly, the system needs to do some ground work first.',
  importProcessingMessagePart3: 'This initial processing might take some time.',
  importProcessingMessagePart4: 'Thank you for your patience.',
  applyMachineLearning: 'Applying Machine Learning...',
  contraProcessingMessagePart1: 'Identifying contras',
  contraProcessingMessagePart2: 'Identifying contras might take some time.',
  contraProcessingMessagePart3: 'Thank you for your patience.',
  deleteSupportingDocMessagePart1: 'Deleting Supporting Documents...',
  deleteSupportingDocMessagePart2: 'Deleting Supporting Documents might take some time.',
  deleteSupportingDocMessagePart3: 'Thank you for your patience.',
  startAgain: 'Start again',
  retryImport: 'Retry the import',
  externalReviewImportProcessing: 'Processing External Review File',
};

export const importProcessingArray =
  [strings.importProcessingMessagePart1,
    strings.importProcessingMessagePart2,
    strings.importProcessingMessagePart3,
    strings.importProcessingMessagePart4];

export const contraProcessingArray =
  [strings.contraProcessingMessagePart1,
    strings.contraProcessingMessagePart2,
    strings.contraProcessingMessagePart3,
    ''];
export const deleteSupportingDocArray =
  [strings.deleteSupportingDocMessagePart1,
    strings.deleteSupportingDocMessagePart2,
    strings.deleteSupportingDocMessagePart3,
    ''];

