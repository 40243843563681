const styles = theme => ({
  root: {
    marginTop: -46,
  },
  amountsBar: {
    fontSize: theme.constants.fontSizes.small,
    marginBottom: 12,
    marginRight: 15,
    paddingRight: 91,
    textAlign: 'right',
  },
  categoriesRoot: {
    borderRadius: 2,
    display: 'flex',
    height: 14,
    overflow: 'hidden',
  },
  category: {
    flex: '0 0 auto',
    height: '100%',
    marginRight: 3,
    '&:last-child': {
      marginRight: 0,
    },
    minWidth: 10,
  },
  unmappedCategory: {
    flex: '1 0 auto',
    minWidth: 0,
  },
  paper: {
    padding: theme.spacing.unit,
  },
  popover: {
    color: theme.palette.colors.black,
    fontSize: theme.constants.fontSizes.small,
    lineHeight: '16px',
    pointerEvents: 'none',
  },
  amountValue: {
    color: theme.palette.primary.darkText,
  },
  button: {
    marginLeft: 5,
    marginRight: 5,
    border: 'none',
    padding: 0,
    backgroundColor: '#efefef',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '1.0em',
    cursor: 'pointer',
  },
  selectedButton: {
    marginLeft: 5,
    marginRight: 5,
    border: 'none',
    padding: 0,
    backgroundColor: '#efefef',
    color: theme.palette.colors.black,
    fontWeight: 'bold',
    textDecoration: 'underline',
    fontSize: '1.0em',
    cursor: 'default',
  },
});

export default styles;
