import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { Post } from 'Store/Api/CallApi';
import {
  GET_CATEGORY_RULES,
  GET_APPORTIONABLE_CATEGORY_RULES,
  getCategoryRulesSuccess,
  getCategoryRules,
  getApportionableCategoryRulesSuccess,
} from 'Store/Areas/Rule/CategoryRulesActions';
import { UPDATE_CATEGORY_RULE_FILTERS } from 'Store/Areas/Rule/CategoryRulesFilterActions';
import { RULE_GET_RULES } from 'Store/Api/ApiEndpoints';

function* getCategoryRulesSaga(action) {
  const response = yield Post(RULE_GET_RULES, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getCategoryRulesSuccess(data, action.payload.pageNumber));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getApportionableCategoryRulesSaga(action) {
  const response = yield Post(RULE_GET_RULES, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getApportionableCategoryRulesSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* queryTermsUpdated(action) {
  yield put(getCategoryRules(
    action.payload.periodId,
    action.payload.categoryId,
    1,
    action.sortColumn,
    action.sortOrder,
    action.tagFilter,
    action.payload.searchTerm,
    !!action.payload.searchTerm,
  ));
}

/* Watcher Sagas */
export function* categoryRulesSagas() {
  yield takeLatest(GET_CATEGORY_RULES, getCategoryRulesSaga);
  yield takeLatest(GET_APPORTIONABLE_CATEGORY_RULES, getApportionableCategoryRulesSaga);
  yield takeLatest(UPDATE_CATEGORY_RULE_FILTERS, queryTermsUpdated);
}
