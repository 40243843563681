/* eslint-disable class-methods-use-this */
import { put } from 'redux-saga/effects';
import { strings } from 'Constants/Error/Strings';
import { toggleCorruptedFileDetectedModal } from 'Store/Areas/FileUpload/FileUploadActions';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';

export default class SignalrStrategyHandler {
  constructor(userAction, handler) {
    this.userAction = userAction;
    this.handle = handler.bind(this);
  }

  * handleVirusDetected() {
    yield put(toggleCorruptedFileDetectedModal({
      showModal: true,
      fileContainsVirus: true,
    }));
  }

  * handleCorruptFile(action) {
    yield put(getPeriod(action.selectedPeriodId));
    yield put(toggleCorruptedFileDetectedModal({
      showModal: true,
    }));
  }

  * handleError(action) {
    yield put(getPeriod(action.selectedPeriodId, true));
    yield put(displayToastNotification(strings.errorMessageRetry));
  }
}
