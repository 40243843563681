export const GET_IMPORT_SUMMARY = 'IMPORT_SUMMARY/GET_IMPORT_SUMMARY';
export const GET_IMPORT_SUMMARY_SUCCESS = 'IMPORT_SUMMARY/GET_IMPORT_SUMMARY_SUCCESS';
export const REMOVE_FILE_IMPORT_ROWS = 'IMPORT_SUMMARY/REMOVE_FILE_IMPORT_ROWS';
export const REMOVE_FILE_IMPORT_ROWS_SUCCESS = 'IMPORT_SUMMARY/REMOVE_FILE_IMPORT_ROWS_SUCCESS';

export function getImportSummary(periodId) {
  return {
    type: GET_IMPORT_SUMMARY,
    periodId: periodId,
    loading: true,
    importErrors: [],
    tooManyErrors: false,
    tooManyClauses: false,
    ruleText: '',
    userCanProceed: true,
  };
}

export function getImportSummarySuccess(data) {
  return {
    type: GET_IMPORT_SUMMARY_SUCCESS,
    loading: false,
    importErrors: data.importErrors,
    tooManyErrors: data.tooManyErrors,
    tooManyClauses: data.tooManyClauses,
    ruleText: data.ruleText,
    userCanProceed: data.userCanProceed,
  };
}

export function removeFileImportRows(periodId) {
  return {
    type: REMOVE_FILE_IMPORT_ROWS,
    payload: {
      periodId: periodId,
    },
  };
}
