const styles = theme => ({
  root: {
    textAlign: 'center',
  },
  button: {
    textTransform: 'none',
    minHeight: 20,
    padding: 0,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontSize: theme.constants.fontSizes.small,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  switchText: {
    color: '#888888',
    fontWeight: 'normal',
    textAlign: 'center',
    fontSize: theme.constants.fontSizes.small,
  },
});

export default styles;
