export const styles = theme => ({
  root: {
    fontSize: theme.constants.fontSizes.small,
  },
  whiteContainer: {
    height: 'calc(100% - 10px)',
    boxShadow: '0 2px 4px 0 rgba(194, 194, 194, 0.5)',
    backgroundColor: 'white',
  },
  dragSource: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0,
    zIndex: 100,
    right: 0,
  },
});
