export const TagTypes = {
  Text: 'Text',
  TextWholeValue: 'TextWholeValue',
  Numeric: 'Numeric',
  Date: 'Date',
};

export const selectMappedTagsFromPeriod =
  state => state.periods.period.data.tags.filter(x => x.type !== 'NotMapped');

export const anyTag = { id: 0, name: 'ANY_TAG', type: TagTypes.Text };

export function tagTypeLabel(tagType) {
  switch (tagType) {
    case TagTypes.Text:
      return 'Text - Individual Keywords';
    case TagTypes.TextWholeValue:
      return 'Text - Whole Value';
    case TagTypes.Numeric:
      return 'Numeric';
    case TagTypes.Date:
      return 'Date';
    default:
      return 'Unknown tag type';
  }
}
