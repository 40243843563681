import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, IconButton } from '@material-ui/core';
import ReferenceListIcon from 'Assets/Images/reference-list-icon.svg';
import Close from 'Assets/Images/exit-ic.svg';
import { compose } from 'recompose';
import { connect as reduxConnect } from 'react-redux';
import { openViewReferenceList, getReferenceListEntries, checkReferenceListRules } from 'Store/Areas/Categorisation/ReferenceListActions';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import DragnDrop from 'Constants/DragnDrop';
import { commonStrings } from 'Constants/CommonStrings';
import { DragSource } from 'react-dnd';
import { styles } from './ReferenceListItem.styles';

class ReferenceListItem extends React.PureComponent {
  onViewButtonClick = () => {
    const {
      dispatch,
      periodId,
      referenceLists,
    } = this.props;

    dispatch(openViewReferenceList(referenceLists.id, referenceLists.name));
    dispatch(getReferenceListEntries(periodId, referenceLists.id));
  }

  onDeleteButtonClick = () => {
    const {
      dispatch,
      referenceLists,
      periodId,
    } = this.props;

    dispatch(checkReferenceListRules(periodId, referenceLists.id, referenceLists.name));
  }

  render() {
    const {
      referenceLists,
      classes,
      allowDrag,
      connectDragSource,
    } = this.props;
    const renderedComponent = (
      <div className={classes.root}>
        {allowDrag &&
          connectDragSource(<div className={classes.dragSource} />)
        }
        <div className={classes.iconContainer}>
          <img alt="Reference List Icon" className={classes.icon} src={ReferenceListIcon} />
        </div>

        <span className={classes.flex}>
          {referenceLists.name}
        </span>
        <button
          className={classes.button}
          onClick={this.onViewButtonClick}
        >
          {commonStrings.view}
        </button>
        <IconButton className={classes.removeIcon} onClick={this.onDeleteButtonClick}>
          <img src={Close} alt="remove" />
        </IconButton>
      </div>
    );

    return renderedComponent;
  }
}


ReferenceListItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
  referenceLists: (PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  connectDragSource: PropTypes.func.isRequired,
  allowDrag: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
  };
}

const spec = {
  beginDrag(props) {
    return {
      keyword: props.referenceLists.name,
      type: DragnDrop.referenceListItem,
      rootKeyword: props.referenceLists.name,
    };
  },
  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const { onDrop } = monitor.getDropResult();
      onDrop();
    }
  },
};

function collect(connect) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  };
}


export default compose(
  DragSource(DragnDrop.referenceListItem, spec, collect),
  withStyles(styles),
  withTheme(),
  reduxConnect(mapStateToProps),
)(ReferenceListItem);
