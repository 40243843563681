import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme, TextField } from '@material-ui/core';
import { buildRuleSuccess } from 'Store/Areas/Rule/RuleBuilderActions';
import { replaceNewLines } from 'Helpers/StringHelpers';
import { styles } from './FreeTextRule.styles';
import { withRulesBuilder } from '../';

class FreeTextRule extends React.PureComponent {
  onRuleChange = ({ target: { value } }) => {
    this.props.dispatch(buildRuleSuccess(replaceNewLines(value)));
  }

  render() {
    const {
      width,
      height,
      classes,
    } = this.props;

    const rootWidth = width - 20;
    const handleWidth = 15;
    const removeWidth = 20;

    return (
      <TextField
        margin="dense"
        InputProps={{
          classes: {
            input: classes.textSize,
          },
          style: {
            width: rootWidth - handleWidth - removeWidth,
          },
          multiline: true,
          inputProps: {
            style: {
              height: height - 150,
              maxHeight: height - 150,
            },
          },
        }}
        variant="outlined"
        value={this.props.rule}
        onChange={this.onRuleChange}
      />
    );
  }
}

FreeTextRule.propTypes = {
  dispatch: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  rule: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme(), withRulesBuilder())(FreeTextRule);
