export const REQUEST_TOGGLE_SITE_LOADER = 'SITE_LOADER/REQUEST_TOGGLE_SITE_LOADER';
export const RESET_SITE_LOADER = 'SITE_LOADER/RESET_SITE_LOADER';

export function toggleSiteLoader(message, isLoading = true) {
  return {
    type: REQUEST_TOGGLE_SITE_LOADER,
    message,
    isLoading,
  };
}

export function resetSiteLoader() {
  return {
    type: RESET_SITE_LOADER,
  };
}
