import moment from 'moment';
import Yup from 'yup';
import { allowedCharacters, beginsOrEndsInSpaces, caseInsensitiveDuplicateTestOptions } from '../../ValidationHelpers';
/* eslint-disable prefer-arrow-callback */
/* eslint-disable  func-names */

export function getCreatePeriodSchema(values, periods, isEditing, isRollForward) { // eslint-disable-line
  const dateFrom = moment(values.dateFrom, moment.ISO_8601, true);
  const dateTo = moment(values.dateTo, moment.ISO_8601, true);

  const validation = isEditing ? {} : {
    group: Yup.number()
      .min(1, 'Group is Required.')
      .required(),
    entity: Yup.number()
      .min(1, 'Entity is Required.')
      .required(),
    project: Yup.number()
      .min(1, 'Project is Required.')
      .required(),
    template: Yup.number()
      .test('isRequired', 'Please select a template.', function (value) {
        if (!isRollForward && value < 1) {
          return false;
        }
        return true;
      }),
    priorPeriod: Yup.number()
      .test('isRequired', 'Please select a period.', function (value) {
        if (isEditing) {
          return true;
        }
        if (isRollForward && value < 1) {
          return false;
        }
        return true;
      }),
  };

  return Yup.object().shape({
    ...validation,
    period: Yup.string()
      .required('Period is Required.')
      .test(caseInsensitiveDuplicateTestOptions(
        periods,
        'Period name must be unique (irrespective of capitalisation).',
      ))
      .notOneOf(periods, 'Period name must be unique.')
      .matches(allowedCharacters, 'Period name contains forbidden characters.')
      .matches(beginsOrEndsInSpaces, 'Period name must not begin or end with spaces.')
      .max(50, 'Max length is 50 characters.'),
    dateFrom: Yup.string()
      .nullable()
      .test('isDate', 'Dates must be in \'DD/MM/YYYY\' format.', function (value) {
        if (value === '' || value === null) {
          return true;
        }
        return dateFrom.isValid();
      })
      .test('optionalRequired', '\'Start Date\' is required if \'End Date\' is supplied.', function (value) {
        if (!dateTo.isValid()) {
          return true;
        }
        if (value !== '' && value !== null) {
          return true;
        }
        return false;
      }),
    dateTo: Yup.string()
      .nullable()
      .test('isDate', 'Dates must be in \'DD/MM/YYYY\' format.', function (value) {
        if (value === '' || value === null) {
          return true;
        }
        return dateTo.isValid();
      })
      .test('laterThan', '\'End Date\' must be later than \'Start Date\'.', function () {
        if (!dateFrom.isValid() || !dateTo.isValid()) {
          return true;
        }
        if (dateTo.isAfter(dateFrom)) {
          return true;
        }
        return false;
      }),
  });
}
