import {
  APPORTIONMENT_RESOURCE_FROM_KEYWORD,
  APPORTIONMENT_RESOURCE_FROM_LINE_ITEM,
  REQUEST_CREATE_APPORTIONABLE,
  CLOSE_APPORTIONMENT_MODAL,
  APPORTION_LINE_ITEM_SUCCESS,
  APPORTIONMENT_RESOURCE_FROM_REFLIST,
  APPORTIONMENT_RESOURCE_FROM_CUSTOM_RULE,
} from './ApportionablesModalActions';

const initialState = {
  loading: false,
  displayApportionmentModal: false,
  apportionableTags: [],
  keywordRules: '',
  rules: [],
  keywords: [],
  lineItemIds: [],
  tags: [],
  tagValuesForDisplay: [],
  apportionmentTotalAmount: 0,
  isSingleLineItem: false,
  isLineItems: false,
  apportionableType: 0,
  ruleType: 0,
  isHighPriority: false,
  categories: [],
};

export default function apportionablesModalReducer(state = initialState, action) {
  switch (action.type) {
    case APPORTIONMENT_RESOURCE_FROM_REFLIST:
    case APPORTIONMENT_RESOURCE_FROM_CUSTOM_RULE:
    case APPORTIONMENT_RESOURCE_FROM_KEYWORD:
      return Object.assign({}, state, {
        displayApportionmentModal: true,
        keywordRules: action.payload.keywordRule,
        rules: action.payload.rules,
        loading: true,
        apportionableType: 2, // Rule
        ruleType: action.payload.ruleType,
        isHighPriority: action.payload.isHighPriority,
        categories: action.payload.categories,
      });
    case APPORTIONMENT_RESOURCE_FROM_LINE_ITEM:
      return Object.assign({}, state, {
        displayApportionmentModal: true,
        lineItemIds: action.payload.lineItemIds,
        isSingleLineItem: action.payload.isSingleLineItem,
        isLineItems: true,
        loading: true,
        apportionableType: 1, // Manual
        categories: action.payload.categories,
      });
    case APPORTION_LINE_ITEM_SUCCESS:
      return Object.assign({}, state, {
        lineItemIds: action.data.lineItemIds,
        tagValuesForDisplay: action.data.tagValues,
        apportionmentTotalAmount: action.data.totalAmount,
        loading: false,
      });
    case CLOSE_APPORTIONMENT_MODAL:
    case REQUEST_CREATE_APPORTIONABLE:
      return initialState;
    default:
      return state;
  }
}