import React from 'react';
import { DndPreviewContext } from '../../../index';

const withDndPreviewContext = () => (Component) => {
  class WithDndPreviewContext extends React.PureComponent {
    render() {
      return (
        <DndPreviewContext.Consumer>
          {value => (
            <Component dndPreview={value} {...this.props} />
          )}
        </DndPreviewContext.Consumer>
      );
    }
  }

  return WithDndPreviewContext;
};

export default withDndPreviewContext;
