import { take, fork, cancel, cancelled } from 'redux-saga/effects';
import * as PeriodActions from 'Store/Areas/Period/PeriodActions';
import { SignalrStrategy } from 'Store/Areas/Signalr/Strategies/SignalrStrategy';
import { watchSignalREvents } from 'Store/Api/CallSignalR';

const signalrStrategy = new SignalrStrategy();

function* handleSignalRMessage(payload, action) {
  const strategy = signalrStrategy.getHandler(payload.action);
  yield strategy.handle(payload, action);
}

export function* periodSignalRSagas() {
  while (true) {
    const action = yield take(PeriodActions.CONNECT_TO_SIGNALR_PERIOD_HUB);
    const bgTask = yield fork(watchSignalREvents, {
      action,
      processSignalRPayload: {
        hubUrl: `period?groupid=period-${action.selectedPeriodId}`,
        name: 'ReportImportProgress',
        endConnection: (connection, name) => connection.stop(name),
      },
      handleSignalRMessage,
      cancelled,
    });
    yield take([PeriodActions.RESET_PERIOD]);
    yield cancel(bgTask);
  }
}
