import React from 'react';
import { strings } from 'Constants/Upload/Strings';
import { title } from 'Constants/App/Titles/Strings';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import { hasSupportingFilesSelector, changeSupportingDocsAfterPeriodCreationSelector } from 'Store/Areas/Period/PeriodSelectors';
import { supporting } from 'Constants/FileTypes';
import UploadBreadcrumb, { constants } from 'Components/Shared/UploadBreadcrumb/UploadBreadcrumb';
import PropTypes from 'prop-types';
import PageContent from './PageContent/PageContent';

class SupportingMappingPage extends React.PureComponent {
  constructor(props) {
    super(props);
    if (!this.props.hasSupportingFiles && !this.props.changeSupportingDocsAfterPeriodCreation) {
      this.onProceedClick();
    }
  }

  componentDidMount() {
    document.title = title.mapping;
  }

  onProceedClick = () => {
    this.props.dispatch(push(routes.import.review));
  }

  onBackToMainMappingClick = () => {
    this.props.dispatch(push(routes.import.mappingMain));
  }

  onBackToUploadClick = () => {
    this.props.dispatch(push(routes.import.upload));
  }

  render() {
    return (
      <PageContent
        fileType={supporting}
        title={strings.mappingSupportingTitle}
        description={strings.mappingSupportingDescription}
        renderBreadcrumb={() => (
          <UploadBreadcrumb index={constants.indexes.supportMapping} />
        )}
        onProceedClick={this.onProceedClick}
        onBackClick={this.onBackToMainMappingClick}
      />
    );
  }
}

SupportingMappingPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  hasSupportingFiles: PropTypes.bool.isRequired,
  changeSupportingDocsAfterPeriodCreation: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    hasSupportingFiles: hasSupportingFilesSelector(state),
    changeSupportingDocsAfterPeriodCreation: changeSupportingDocsAfterPeriodCreationSelector(state),
  };
};


export default connect(mapStateToProps)(SupportingMappingPage);
