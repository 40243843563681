import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { PostFile } from 'Store/Api/CallApi';
import { ADMIN_BULK_USER_UPLOAD } from 'Store/Api/ApiEndpoints';
import { REQUEST_BULK_USER_UPLOAD, bulkUserUploadSuccess } from 'Store/Areas/BulkUpload/BulkUserUploadActions';

/* Worker Sagas */

function* postSelectedFile(action) {
  const formData = new FormData();
  formData.append('file', action.file);

  const response = yield PostFile(ADMIN_BULK_USER_UPLOAD, formData);
  if (response.ok) {
    const data = yield response.json();
    yield put(bulkUserUploadSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* bulkUserUploadSagas() {
  yield takeLatest(REQUEST_BULK_USER_UPLOAD, postSelectedFile);
}
