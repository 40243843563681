export const styles = theme => ({
  root: {
    padding: '10px 20px 5px 20px',
  },
  valueBar: {
    marginBottom: 18,
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    height: 'Calc(94vh - 6em)',
  },
  conditions: {
    height: 'Calc(93vh - 6em)',
    overflowY: 'auto',
  },
  keywords: {
    marginLeft: 0,
    width: '100%',
  },
  arrowAlignment: {
    marginLeft: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  message: {
    backgroundColor: theme.palette.primary.messageBackground,
    color: theme.palette.primary.darkText,
    lineHeight: 1.43,
    fontSize: theme.constants.fontSizes.regular,
    marginBottom: 3,
  },
});
