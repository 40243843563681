export const COL_FILENAME = 0;
export const COL_MAIN = 1;
export const COL_SUPPORTING = 2;
export const COL_UPLOAD_DATE = 3;
export const COL_DELETE = 4;

export const columns = [
  {
    text: 'Uploaded file(s)',
    sm: 6,
    lg: 6,
  },
  {
    text: 'Main',
    sm: 1,
    lg: 1,
  },
  {
    text: 'Supporting',
    sm: 1,
    lg: 1,
  },
  {
    text: '',
    sm: 3,
    lg: 3,
  },
  {
    text: '',
    sm: 1,
    lg: 1,
  },
];
