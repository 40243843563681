export const strings = {
  mainTitleMl: 'Machine learning categorised line items',
  mainTitleManual: 'Manually categorised line items',
  mainTitleConflicts: 'Conflicted line items',
  mainTitleLineItems: 'All line items (Excluding Contras)',
  mainTitleRules: (category, amount, rows) => `${category} items categorised by rules: ${amount} from ${rows} rows`,
  description: 'You can re-categorise the items by dragging and dropping them into a different category.',
  descriptionConflicts: 'These items were ‘allowed’ when identified as conflicting and must therefore be categorised individually.',
  showRule: 'Show Rule',
  categorisationReason: reason => `Reason: "${reason}"`,
  categoryTypeSelectPlaceholder: 'All categories',
  categoryType: 'Category Type',
  advancedSearch: 'Advanced Search',
  createRule: 'Create Rule',
  tagFilters: 'Tag Filters',
  removeAllTagFilters: 'Remove All',
  addTagFilter: 'Add Filter',
  exactMatch: 'Exact Match',
};
