export const GET_CURRENT_RULE_PRIORITY = 'RULE_PRIORITY/GET_CURRENT_RULE_PRIORITY';
export const GET_CURRENT_RULE_PRIORITY_SUCCESS = 'RULE_PRIORITY/GET_CURRENT_RULE_PRIORITY_SUCCESS';
export const EDIT_RULE_PRIORITY = 'RULE_PRIORITY/EDIT_RULE_PRIORITY';
export const CLOSE_EDIT_PRIORITY_MODAL = 'RULE_PRIORITY/CLOSE_EDIT_PRIORITY_MODAL';

export function getCurrentRulePriority(periodId, ruleId, ruleText) {
  return {
    type: GET_CURRENT_RULE_PRIORITY,
    payload: {
      periodId,
      ruleId,
    },
    ruleText,
    loading: true,
    success: false,
    open: false,
    priorityLevel: null,
  };
}

export function getCurrentRulePrioritySuccess(priorityLevel) {
  return {
    type: GET_CURRENT_RULE_PRIORITY_SUCCESS,
    loading: false,
    success: true,
    open: true,
    priorityLevel,
  };
}

export function editRulePriority(
  periodId,
  ruleId,
  ruleText,
  priorityLevel,
) {
  return {
    type: EDIT_RULE_PRIORITY,
    payload: {
      periodId,
      ruleId,
      ruleText,
      priorityLevel,
    },
    loading: true,
    success: false,
  };
}

export function closeEditPriorityModal() {
  return {
    type: CLOSE_EDIT_PRIORITY_MODAL,
  };
}
