import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';
import { SET_PROCESSING } from './ProcessingActions';

const initialState = {
  processing: false,
};

export default function processingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROCESSING:
      return Object.assign({}, state, {
        processing: action.processing,
      });
    case RESET_PERIOD:
      return initialState;
    default:
      return state;
  }
}
