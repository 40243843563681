import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { strings } from 'Constants/Setup/Admin/Strings';
import { commonStrings } from 'Constants/CommonStrings';

class AddUserConfirmModal extends React.PureComponent {
  render() {
    const { open, onAccept, onCancel } = this.props;
    return (
      <ConfirmationModal
        open={open}
        message={strings.addUserConfirmMessage}
        title={strings.addUserConfirmTitle}
        acceptText={commonStrings.Add}
        onConfirm={onAccept}
        onCancel={onCancel}
      />
    );
  }
}

AddUserConfirmModal.defaultProps = {
  open: false,
};

AddUserConfirmModal.propTypes = {
  open: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default connect()(AddUserConfirmModal);
