const styles = theme => ({
  externalReviewBanner: {
    height: '6vh',
    backgroundColor: theme.palette.primary.orangeBanner,
    display: 'flex',
    justifyContent: 'center',
  },
  lockedBannerText: {
    alignSelf: 'center',
    color: theme.palette.colors.white,
    lineHeight: 1.43,
    fontSize: theme.constants.fontSizes.regular,
  },
  lockedBanner: {
    height: '6vh',
    backgroundColor: theme.palette.primary.orangeBanner,
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    minWidth: '100vw',
    zIndex: theme.zIndex.drawer + 1,
    top: '0vh',
  },
  linkText: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  hidden: {
    display: 'none',
  },
});

export default styles;