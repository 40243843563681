import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme, TableRow, TableCell } from '@material-ui/core';
import styles from './TableRowMessage.styles';

class TableRowMessage extends React.PureComponent {
  render() {
    const {
      text,
      classes,
    } = this.props;

    return (
      <TableRow>
        <TableCell className={classes.cell} colSpan={3}>
          {text}
        </TableCell>
      </TableRow>
    );
  }
}

TableRowMessage.defaultProps = {
  text: 'No records found',
};

TableRowMessage.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(TableRowMessage);

