import { takeLatest, put } from 'redux-saga/effects';
import {
  EXPORT_GET_EXPORT_FILES,
  DELETE_EXTERNAL_REVIEW_EXPORT_REQUEST,
  EXPORT_GET_EXTERNAL_REVIEW_IMPORTED_FILE,
  EXPORT_GET_UNCATEGORISED_LINE_ITEMS_COUNT,
  EXPORT_PROCESS_EXTERNAL_REVIEW_IMPORTED_FILE,
  IS_EXPORTED_FILE_EXPIRED,
} from 'Store/Api/ApiEndpoints';
import { Post, Get } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { togglePeriodLock } from 'Store/Areas/Projects/CreatePeriodActions';
import { toggleCanRenderReadOnlyBanner } from 'Store/Areas/Projects/UserTreePickerActions';
import {
  GET_EXTERNAL_REVIEW_EXPORTED_FILES,
  getExternalReviewExportedFilesSuccess,
  DELETE_EXTERNAL_REVIEW_EXPORTED_FILE,
  deleteExternalReviewExportFileSuccess,
  getExternalReviewExportedFiles,
  GET_EXTERNAL_REVIEW_IMPORTED_FILE,
  GET_NUMBER_OF_UNCATEGORISED_LINE_ITEMS,
  IS_EXTERNAL_REVIEW_EXPORTED_FILE_EXPIRED,
  getNumberOfUncategorisedLineItemsSuccess,
  isExternalReviewExportedFileExpiredSuccess,
  getExternalReviewImportedFileSuccess,
  REQUEST_EXTERNAL_REVIEW_PROCESSING,
  requestExternalReviewProcessingSuccess,
  CANCEL_EXTERNAL_REVIEW,
} from './ExternalReviewActions';

function* getExternalReviewExportedFilesSaga(action) {
  const response = yield Get(EXPORT_GET_EXPORT_FILES(
    action.payload.periodId,
    action.payload.isExternalReview,
  ));
  if (response.ok) {
    const data = yield response.json();
    yield put(getExternalReviewExportedFilesSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getExternalReviewImportedFileSaga(action) {
  const response = yield Get(EXPORT_GET_EXTERNAL_REVIEW_IMPORTED_FILE(action.payload.periodId));
  if (response.ok) {
    const data = yield response.json();
    yield put(getExternalReviewImportedFileSuccess(data !== null ? data : {}));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* deleteSelectedExternalReviewFile(action) {
  const response = yield Post(DELETE_EXTERNAL_REVIEW_EXPORT_REQUEST, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(deleteExternalReviewExportFileSuccess(data));
    yield put(togglePeriodLock(
      action.payload.periodId,
      { periodName: action.periodName },
      false,
      true,
    ));

    yield put(getExternalReviewExportedFiles(action.payload.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getNumberOfUncategorisedDataLineItemsSaga(action) {
  const response = yield Get(EXPORT_GET_UNCATEGORISED_LINE_ITEMS_COUNT(action.payload.periodId));
  if (response.ok) {
    const data = yield response.json();
    yield put(getNumberOfUncategorisedLineItemsSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* isExternalReviewExportedFileExpiredSaga(action) {
  const response = yield Get(IS_EXPORTED_FILE_EXPIRED(action.payload.periodId));
  if (response.ok) {
    const data = yield response.json();
    yield put(isExternalReviewExportedFileExpiredSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* requestExternalReviewImportProcessing(action) {
  const response = yield Post(EXPORT_PROCESS_EXTERNAL_REVIEW_IMPORTED_FILE, action.payload);
  if (response.ok) {
    yield put(requestExternalReviewProcessingSuccess());
  } else {
    yield handleStatusCode(response.status);
  }
}

function* cancelExternalReviewSaga() {
  yield put(toggleCanRenderReadOnlyBanner(false));
}

export function* externalReviewSagas() {
  yield takeLatest(GET_EXTERNAL_REVIEW_EXPORTED_FILES, getExternalReviewExportedFilesSaga);
  yield takeLatest(DELETE_EXTERNAL_REVIEW_EXPORTED_FILE, deleteSelectedExternalReviewFile);
  yield takeLatest(GET_EXTERNAL_REVIEW_IMPORTED_FILE, getExternalReviewImportedFileSaga);
  yield takeLatest(
    GET_NUMBER_OF_UNCATEGORISED_LINE_ITEMS,
    getNumberOfUncategorisedDataLineItemsSaga,
  );
  yield takeLatest(
    IS_EXTERNAL_REVIEW_EXPORTED_FILE_EXPIRED,
    isExternalReviewExportedFileExpiredSaga,
  );
  yield takeLatest(REQUEST_EXTERNAL_REVIEW_PROCESSING, requestExternalReviewImportProcessing);
  yield takeLatest(CANCEL_EXTERNAL_REVIEW, cancelExternalReviewSaga);
}
