import { takeLatest, select, put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { Post } from 'Store/Api/CallApi';
import {
  NEW_USER_SESSION,
  NEW_USER_SESSION_SUCCESS,
  USER_SESSION_LOGOUT,
  RESET_PERIOD,
  newUserSessionSuccess,
  userSessionLogout,
  saveAuthContext,
} from 'Store/Areas/App/ConfigActions';
import { USER_STORE_NEW_USER_SESSION_URL, USER_VALIDATE_USER_SESSION, USER_INVALIDATE_USER_SESSION, PERIOD_RESET_IN_USE } from 'Store/Api/ApiEndpoints';

const pollingInterval = 10 * 1000;

function* pollForSessionValidation() {
  try {
    let polling = true;

    while (polling) {
      polling = false;
      yield call(delay, pollingInterval);

      const response = yield Post(USER_VALIDATE_USER_SESSION);
      if (response.ok) {
        const data = yield response.json();
        polling = !!data && data.success === true && data.result === true;
      }
    }
  } catch (error) {
    // suppress
  }
  yield put(userSessionLogout());
}

function* newUserSession() {
  const response = yield Post(USER_STORE_NEW_USER_SESSION_URL);

  let success = false;

  try {
    if (response.ok) {
      const data = yield response.json();
      success = !!data && data.success === true && data.result === true;
    }
  } catch (error) {
    // suppress
    success = false;
  }

  yield put(success === true ? newUserSessionSuccess() : userSessionLogout());
}

function* sessionLogout() {
  try {
    const { previousPeriodId, isProjectReadOnlyUser } = yield select(state => ({
      previousPeriodId: state.periods.period.periodId,
      isProjectReadOnlyUser: state.periods.period.data.isProjectReadOnlyUser,
    }));
    if (previousPeriodId !== 0 && !isProjectReadOnlyUser) {
      const response = yield Post(PERIOD_RESET_IN_USE, { periodId: previousPeriodId });
      if (response.ok) {
        const init = yield select(state => state.user.permissions.init);
        if (init === true) {
          yield Post(USER_INVALIDATE_USER_SESSION);
        }
      }
    }
  } catch (error) {
    // suppress
  }
  const authContext = yield select(state => state.app.config.authContext);
  authContext.removeUser();
  saveAuthContext(null);
  const authRouterConfig = yield select(state => state.app.config.authRouterConfig);
  const email = yield select(state => state.user.permissions.data.emailAddress);
  authRouterConfig.onRemoveUser(email);
}

function* resetPeriod(action) {
  const { previousPeriodId, isProjectReadOnlyUser } = yield select(state => ({
    previousPeriodId: state.periods.period.periodId,
    isProjectReadOnlyUser: state.periods.period.data.isProjectReadOnlyUser,
  }));
  if (previousPeriodId !== 0 && !isProjectReadOnlyUser) {
    const response = yield Post(PERIOD_RESET_IN_USE, { periodId: previousPeriodId });
    if (response.ok && action.payload.page !== undefined) {
      window.location.href = action.payload.page;
    }
  } else if (action.payload.page !== undefined) {
    window.location.href = action.payload.page;
  }
}

export function* userSessionSagas() {
  yield takeLatest(NEW_USER_SESSION, newUserSession);
  yield takeLatest(NEW_USER_SESSION_SUCCESS, pollForSessionValidation);
  yield takeLatest(USER_SESSION_LOGOUT, sessionLogout);
  yield takeLatest(RESET_PERIOD, resetPeriod);
}
