export const styles = theme => ({
  root: {
    height: 39,
    display: 'flex',
    flexDirection: 'row',
    outline: 0,
    flexShrink: 0,
  },
  middle: {
    paddingLeft: 25,
    paddingRight: 25,
    lineHeight: '40px',
    fontSize: theme.constants.fontSizes.regular,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.colors.white,
  },
  image: {
    width: 8,
  },
  active: {
    backgroundColor: '#f4f4f4',
    fontWeight: 'bold',
    color: theme.palette.primary.darkText,
  },
  selectable: {
    color: '#0091da !important',
    fontWeight: 'bold',
    cursor: 'pointer',
    backgroundColor: theme.palette.colors.white,
  },
  unselectable: {
    backgroundColor: theme.palette.colors.messageBackground,
    color: theme.palette.primary.darkText,
  },
  cursor: {
    cursor: 'pointer',
  },
  spaceRight: {
    marginRight: 20,
    borderRadius: 5,
    boxShadow: '0 2px 2px rgba(0,0,0,0.25)',
  },
});
