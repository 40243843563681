export const apportionmentStrings = {
  save: 'Save',
  later: 'Later',
  apportionable: 'Apportionable Rule:',
  apportionmentCondition: 'Apportionment Conditions:',
  isInherited: 'Inherit tag values?   ',
  apportionmentPriority: 'Sequence',
  inheritedFromTagValueText: 'Inherited from Tag value',
  apportionableUpdated: 'Apportionable Updated Successfully',
  apportionableUpdationFailed: 'Apportionable could not be updated. Please check with administrator.',
  apportionableStarted: 'Apportionable creation started',
  apportionableCreated: 'Apportionable Created Successfully',
  apportionableCreationFailed: 'Apportionable could not be created. Please check with administrator.',
  apportionmentModalPriority: 'Sequence (lowest first)',
  enterApportionableTagValues: 'Please enter at least one tag value for apportionable condition and then click Save',
  noLineitemFound: 'No new line item to apportion found for this rule.',
  lineItemAlreadyApportioned: 'Line item is already an apportionable.',
  goBack: 'Go Back',
  instructionToGoBack: 'Click on Back Button',
  enterPriority: 'Please enter Sequence to Save.',
  entertag: 'Please enter atleast one tag value.',
  condition: 'Condition',
  method: 'Method',
  definitionText: 'Condition String',
  calculateApportionment: 'Calculate',
  calculationFailed: 'Apportionment calculation failed. Please check with administrator.',
  calculationCompleted: 'Apportionment calculation completed.',
  calculationQueued: 'Apportionment calculation queued.',
  calculationRunning: 'Apportionment calculation running.',
  apportionmentErrorMessage: 'At least one item could not be calculated. Please see pop-over text on items marked as X.',
  saveDefinition: 'Save',
  didYouKnowMessage:
    'Apportionable items are those that are categorised depending on the categorisation of other items, rather ' +
    'than having a set category themselves. In the section on the left Apportionment Conditions can be set to determine ' +
    'which other items the categorisation depends on, which will then be calculated on ' +
    'a pro-rata basis and the results shown in newly created line items within the relevant categories. ' +
    'The sequencing value allows you to layer apportionable items over each other, with apportionable items ' +
    'of a higher sequence value. You can view the full details of line items currently within ' +
    'the apportionables category by clicking on ‘Summary’ at the top right of the screen.',
};

export const apportionmentTypes = {
  1: 'Manual',
  2: 'Rule',
  3: 'Machine Learning',
};
