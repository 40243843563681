export const REQUEST_UPLOAD_TEMPLATE = 'UPLOAD_TEMPLATE/REQUEST_UPLOAD_TEMPLATE';
export const REQUEST_UPLOAD_TEMPLATE_SUCCESS = 'UPLOAD_TEMPLATE/REQUEST_UPLOAD_TEMPLATE_SUCCESS';
export const SET_UPLOAD_TEMPLATE_STAGE = 'UPLOAD_TEMPLATE/SET_UPLOAD_TEMPLATE_STAGE';
export const RESET_UPLOAD_TEMPLATE_STATE = 'UPLOAD_TEMPLATE/RESET_UPLOAD_TEMPLATE_STATE';

export function uploadTemplate(file) {
  return {
    type: REQUEST_UPLOAD_TEMPLATE,
    file: file,
  };
}

export function uploadTemplateSuccess(response) {
  return {
    type: REQUEST_UPLOAD_TEMPLATE_SUCCESS,
    loading: false,
    userId: response.userId,
  };
}

export function setUploadTemplateStage(payload) {
  return {
    type: SET_UPLOAD_TEMPLATE_STAGE,
    stage: payload.stage,
    importMessages: payload.importMessages,
  };
}

export function resetUploadTemplateState() {
  return {
    type: RESET_UPLOAD_TEMPLATE_STATE,
  };
}
