import { SELECT_PERIOD } from 'Store/Areas/Projects/UserTreePickerActions';
import { GET_HIGHEST_VALUE_LINE_ITEM, GET_HIGHEST_VALUE_LINE_ITEM_SUCCESS, SET_LINE_ITEM_THRESHOLD, GET_PERIOD_LINE_ITEM_THRESHOLD, GET_PERIOD_LINE_ITEM_THRESHOLD_SUCCESS } from './LineItemValueActions';

const initialState = {
  valueloading: false,
  thresholdloading: false,
  value: null,
  threshold: null,
};

export default function lineItemValueReducer(state = initialState, action) {
  switch (action.type) {
    case GET_HIGHEST_VALUE_LINE_ITEM:
    case GET_HIGHEST_VALUE_LINE_ITEM_SUCCESS:
      return Object.assign({}, state, {
        valueloading: action.loading,
        value: action.value,
      });
    case SET_LINE_ITEM_THRESHOLD:
    case GET_PERIOD_LINE_ITEM_THRESHOLD:
    case GET_PERIOD_LINE_ITEM_THRESHOLD_SUCCESS:
      return Object.assign({}, state, {
        thresholdloading: action.loading,
        threshold: action.lineItemValueThreshold,
      });
    case SELECT_PERIOD:
      return initialState;
    default:
      return state;
  }
}
