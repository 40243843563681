import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, IconButton, withTheme } from '@material-ui/core';
import Close from 'Assets/Images/exit-ic.svg';
import { commonStrings } from 'Constants/CommonStrings';
import { title } from 'Constants/App/Titles/Strings';
import { strings, exportPageType } from 'Constants/Export/Strings';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Sidebar from 'Components/UserSidebar/Sidebar';
import FileList from 'Components/Export/ExportPage/FileList/FileList';
import LockedPeriodBanner from 'Components/Shared/LockedPeriodBanner/LockedPeriodBanner';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { externalReviewSteps } from 'Constants/Export/ExternalReviewSteps';
import {
  periodIdSelector,
  periodLockedByUserIdSelector,
  periodNameSelector,
  periodStatusSelector,
  periodInUseSelector,
  isReadOnlySelector,
} from 'Store/Areas/Period/PeriodSelectors';
import { togglePeriodLock } from 'Store/Areas/Projects/CreatePeriodActions';
import {
  externalReviewExportedFilesSelector,
  externalReviewFileSelector,
  externalReviewCloudRefSelector,
  externalReviewCanExportSelector,
  externalReviewUncategorisedLineItemCountSelector,
  externalReviewExportedFileExpiredSelector,
} from 'Store/Areas/Export/ExportSelectors';
import { beginDeleteFile } from 'Store/Areas/Import/UploadActions';
import {
  getExternalReviewExportedFiles,
  getExternalReviewImportedFile,
  deleteExternalReviewExportFile,
  resetExternalReviewFiles,
  getNumberOfUncategorisedLineItems,
  isExternalReviewExportedFileExpired,
  requestExternalReviewProcessing,
  cancelExternalReview,
} from 'Store/Areas/Export/ExternalReviewActions';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import { getPeriodLockedByUserId } from 'Store/Areas/Period/PeriodActions';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { userTreePickerSelector } from 'Store/Areas/Projects/UserTreePickerSelectors';
import ExternalReviewScreen from './ExternalReviewScreen';
import ExternalReviewModal from './ExternalReviewModal';
import ExternalReviewImportDatasheet from './ExternalReviewImportDatasheet';
import { styles } from './ExternalReviewManager.styles';

class ExternalReviewManager extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      step: externalReviewSteps.initialExternalReviewScreen,
      step1ModalOpen: false,
      cancelExternalReviewModalOpen: false,
      hideReimportOnBanner: true,
      hidePeriodLockedBanner: true,
    };
  }

  componentDidMount() {
    const {
      periodId,
      dispatch,
    } = this.props;
    document.title = title.externalReview;
    dispatch(getTreePickerData());
    dispatch(resetExternalReviewFiles());
    dispatch(getPeriodLockedByUserId(periodId));
    dispatch(getExternalReviewExportedFiles(periodId));
    dispatch(getNumberOfUncategorisedLineItems(periodId));
  }

  componentDidUpdate(prevProps) {
    const {
      externalReviewExportedFiles,
      externalReviewFilename,
      externalReviewCloudRef,
      periodLockedByUserId,
      periodInUse,
      periodStatus,
      periodId,
      dispatch,
      isExportedFileExpired,
    } = this.props;

    if (this.getHasPermissions()) {
      dispatch(isExternalReviewExportedFileExpired(periodId));
      if (prevProps.periodStatus === periodStatuses.exportRunning
        && periodStatus === periodStatuses.periodCategorisationComplete) {
        dispatch(getExternalReviewExportedFiles(periodId));
      }

      if (prevProps.periodLockedByUserId !== periodLockedByUserId
        || prevProps.periodInUse !== periodInUse) {
        this.handlePeriodLockStatus();
      }

      if (externalReviewFilename !== '' && externalReviewCloudRef !== '') {
        this.handleImportedExternalReview();
      }

      if (
        prevProps.externalReviewCloudRef !== externalReviewCloudRef
        && externalReviewFilename === '' && externalReviewCloudRef === '') {
        this.handleProceedToStep2();
      }

      if (isExportedFileExpired) {
        this.handleProceedToStep2();
      } else if (externalReviewExportedFiles &&
        prevProps.externalReviewExportedFiles !== externalReviewExportedFiles
        && externalReviewExportedFiles.length > 0) {
        this.handleProceedToStep2();
      } else if (externalReviewExportedFiles &&
        prevProps.externalReviewExportedFiles !== externalReviewExportedFiles
        && externalReviewExportedFiles.length === 0) {
        this.handleResetToInitialStage();
      }
    } else {
      this.handleResetToInitialStage();
    }
  }

  onDelete = () => {
    const {
      dispatch,
      externalReviewFilename,
      externalReviewCloudRef,
      periodId,
    } = this.props;
    dispatch(beginDeleteFile(externalReviewCloudRef, externalReviewFilename, periodId, false));
  }

  getHasPermissions = () => {
    const {
      groupId,
      permissions,
      periodLockedByUserId,
      periodIsReadOnly,
    } = this.props;

    if (permissions.isGroupAdmin && permissions.groupAdmins.includes(groupId)) {
      return true;
    } else if (periodLockedByUserId > 0 && (permissions.id === periodLockedByUserId)) {
      return true;
    } else if (periodIsReadOnly) {
      return false;
    }

    return false;
  }

  handleClickCancelExternalReview = () => {
    this.setState({
      cancelExternalReviewModalOpen: true,
    });
  }

  handleCancelCancelExternalReview = () => {
    this.setState({
      cancelExternalReviewModalOpen: false,
    });
  }

  handleConfirmCancelExternalReview = () => {
    const {
      externalReviewExportedFiles,
      periodId,
      periodName,
      dispatch,
    } = this.props;

    dispatch(deleteExternalReviewExportFile(
      periodId,
      externalReviewExportedFiles.length === 0 ? 0 : externalReviewExportedFiles[0].id,
      periodName,
    ));

    dispatch(cancelExternalReview());
    this.handleResetToInitialStage();
  }

  handleClickStep1 = () => {
    this.setState({
      step1ModalOpen: true,
    });
  }

  handleCancelStep1 = () => {
    this.setState({
      step1ModalOpen: false,
    });
  }

  handleClickExportExternalReview = () => {
    const {
      periodId,
      periodName,
      dispatch,
    } = this.props;

    dispatch(togglePeriodLock(
      periodId,
      { periodName: periodName },
      true,
      true,
    ));

    this.handleProceedToStep2();
  }

  handleProceedToStep2 = () => {
    const { periodId, dispatch } = this.props;

    this.setState({
      step: externalReviewSteps.exportCompletedUncategorisedDataSheet,
      step1ModalOpen: false,
      hideReimportOnBanner: true,
      hidePeriodLockedBanner: false,
    });

    dispatch(getExternalReviewImportedFile(periodId));
  }

  handleProcessingStep = () => {
    const {
      periodId,
      dispatch,
      externalReviewCloudRef,
    } = this.props;
    dispatch(requestExternalReviewProcessing(periodId, externalReviewCloudRef));
    dispatch(cancelExternalReview());
  }

  handleImportedExternalReview = () => {
    this.setState({
      step: externalReviewSteps.importCompletedUncategorisedDataSheet,
      hideReimportOnBanner: false,
    });
  }

  handlePeriodLockStatus = () => {
    const { dispatch } = this.props;
    dispatch(getTreePickerData());
  }

  handleResetToInitialStage = () => {
    this.setState({
      step: externalReviewSteps.initialExternalReviewScreen,
      cancelExternalReviewModalOpen: false,
      hideReimportOnBanner: true,
      hidePeriodLockedBanner: true,
    });
  }

  renderFileList = (classes, showDelete, symbol) => {
    return (
      <div className={classes.fileListContainer}>
        <FileList
          currencySymbol={symbol}
          exportType={exportPageType.exportExternalReview}
          showDelete={showDelete}
        />
      </div>
    );
  }

  renderStep1ExternalReviewScreen = (isDisabled, symbol) => {
    return (
      <ExternalReviewScreen
        title={strings.exportUncategorisedDataStep1}
        buttonText={strings.exportUncategorisedData}
        description={this.props.uncategorisedLineItemCount
          && strings.exportUncategorisedDataStep1Description(this.props.uncategorisedLineItemCount)}
        onClick={this.handleClickStep1}
        disabled={isDisabled || !this.props.canExport}
      >
        {isDisabled && this.renderFileList(this.props.classes, this.getHasPermissions(), symbol)}
      </ExternalReviewScreen>
    );
  }

  renderImportedFile = (classes) => {
    return (
      <div className={classes.externalReviewFile}>
        <div className={classes.flexContainer}>
          <div className={classes.text}>
            {this.props.externalReviewFilename}
          </div>
          <div className={classes.deleteButton}>
            <IconButton
              onClick={() => this.onDelete()}
              className={classes.icon}
            >
              <img src={Close} alt="remove" />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }

  renderPeriodLockedBanner = () => {
    if (!this.state.hidePeriodLockedBanner) {
      return (
        <LockedPeriodBanner
          shouldHide={this.state.hideReimportOnBanner}
          deleteImportFile={this.onDelete}
          externalReviewLocked
          isLocked
        />
      );
    }
    return (<Fragment />);
  }

  render() {
    const {
      projects,
      currencySymbol,
    } = this.props;

    const mainContentLoading = projects.treePicker.loading;

    return (
      <UserSidebarStructure
        mainContent={
          <Fragment>
            {this.renderPeriodLockedBanner()}
            <Choose>
              <When
                condition={
                  this.state.step === externalReviewSteps.initialExternalReviewScreen
                }
              >
                <ExternalReviewModal
                  isModalOpen={this.state.step1ModalOpen}
                  onConfirm={this.handleClickExportExternalReview}
                  onCancel={this.handleCancelStep1}
                  title={strings.exportUncategorisedData}
                  message={strings.exportModalDescription}
                  acceptText={strings.export}
                />
                {this.renderStep1ExternalReviewScreen(!this.getHasPermissions(), currencySymbol)}
              </When>
              <When
                condition={
                  this.state.step === externalReviewSteps.exportCompletedUncategorisedDataSheet
                }
              >
                <ExternalReviewModal
                  isModalOpen={this.state.cancelExternalReviewModalOpen}
                  onConfirm={this.handleConfirmCancelExternalReview}
                  onCancel={this.handleCancelCancelExternalReview}
                  title={strings.cancelExternalReview}
                  message={strings.cancelExternalReviewModalDescription}
                  acceptText={commonStrings.proceed}
                />
                {this.renderStep1ExternalReviewScreen(true, currencySymbol)}
                <ExternalReviewImportDatasheet
                  onCancelExternalReview={this.handleClickCancelExternalReview}
                />
              </When>
              <Otherwise>
                <ExternalReviewScreen
                  title={strings.exportUncategorisedDataProcessDataTitle}
                  description={strings.exportUncategorisedDataProcessDataDescription}
                  buttonText={strings.processDataButton}
                  onClick={this.handleProcessingStep}
                >
                  {this.renderImportedFile(this.props.classes)}
                </ExternalReviewScreen>
              </Otherwise>
            </Choose>
          </Fragment>
        }
        sidebarContent={<Sidebar />}
        sidebarContentLoading={false}
        mainContentLoading={mainContentLoading}
        isCollapsible
        collapsed
      />
    );
  }
}

ExternalReviewManager.defaultProps = {
  externalReviewFilename: '',
  externalReviewCloudRef: '',
  periodLockedByUserId: -1,
  uncategorisedLineItemCount: null,
  periodInUse: false,
};

ExternalReviewManager.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  groupId: PropTypes.number.isRequired,
  periodId: PropTypes.number.isRequired,
  periodName: PropTypes.string.isRequired,
  periodStatus: PropTypes.string.isRequired,
  externalReviewExportedFiles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  isExportedFileExpired: PropTypes.bool.isRequired,
  externalReviewFilename: PropTypes.string,
  externalReviewCloudRef: PropTypes.string,
  periodLockedByUserId: PropTypes.number,
  periodInUse: PropTypes.bool,
  uncategorisedLineItemCount: PropTypes.number,
  canExport: PropTypes.bool.isRequired,
  permissions: PropTypes.shape({
    isGroupAdmin: PropTypes.bool,
    groupAdmins: PropTypes.arrayOf(PropTypes.number).isRequired,
    id: PropTypes.number,
  }).isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  periodIsReadOnly: PropTypes.bool.isRequired,
  currencySymbol: PropTypes.string.isRequired,
};
function mapStateToProps(state) {
  return {
    projects: state.projects,
    groupId: userTreePickerSelector(state).selectedGroupId,
    periodId: periodIdSelector(state),
    periodName: periodNameSelector(state),
    periodStatus: periodStatusSelector(state),
    externalReviewExportedFiles: externalReviewExportedFilesSelector(state),
    isExportedFileExpired: externalReviewExportedFileExpiredSelector(state),
    externalReviewFilename: externalReviewFileSelector(state),
    externalReviewCloudRef: externalReviewCloudRefSelector(state),
    periodLockedByUserId: periodLockedByUserIdSelector(state),
    periodInUse: periodInUseSelector(state),
    uncategorisedLineItemCount: externalReviewUncategorisedLineItemCountSelector(state),
    canExport: externalReviewCanExportSelector(state),
    permissions: state.user.permissions.data,
    periodIsReadOnly: isReadOnlySelector(state),
    currencySymbol: currencySymbolSelector(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTheme(),
)(ExternalReviewManager);