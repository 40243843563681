export const categoryTypes = [
  {
    id: 1,
    name: 'Assigned Categories',
  }, {
    id: 2,
    name: 'Flagged Line items (Categorized)',
  }, {
    id: 3,
    name: 'Flagged Line items (Uncategorized)',
  }, {
    id: 4,
    name: 'Uncategorized Line items',
  },
];
