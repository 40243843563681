import handleStatusCode from 'Store/Api/HandleStatusCode';
import { takeLatest, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  PROJECTS_POST_PERIOD_URL,
  PERIODS_GET_PERIOD,
  PROJECTS_EDIT_PERIOD_URL,
  PERIOD_DELETE_PERIOD,
  PERIOD_CLEAR_DATA,
  PERIOD_ARCHIVE_PERIOD,
  TOGGLE_PERIOD_LOCK_URL,
  PERIODS_GET_PERIOD_INFO,
  GET_TEMPLATE_RULES_WHILE_CREATE_PERIOD,
} from 'Store/Api/ApiEndpoints';
import { Post, Get } from 'Store/Api/CallApi';
import {
  POST_PERIOD,
  postPeriodSuccess,
  EDIT_PERIOD,
  editLoadPeriodSuccess,
  DELETE_PERIOD,
  CLEAR_PERIOD_DATA,
  ARCHIVE_PERIOD,
  RESET_CREATE_PERIOD,
  EDIT_LOAD_PERIOD_SUCCESS,
  TOGGLE_PERIOD_LOCK,
  editPeriod,
  getPeriodInfoSuccess,
  GET_PERIOD_INFO,
  SELECT_PERIOD_TEMPLATE_RULE,
  selectPeriodTemplateRuleSuccess,
} from 'Store/Areas/Projects/CreatePeriodActions';
import { exportTypes } from 'Constants/Export/ExportTypes';
import { requestExport, setExportLocked } from 'Store/Areas/Export/ExportActions';
import { currentRouteSelector } from 'Store/Areas/Router/RouteSelectors';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import {
  resetPeriod,
  connectToSignalRPeriodHub,
  getPeriodLockedByUserId,
  updatePeriodStatusInRedux,
} from 'Store/Areas/Period/PeriodActions';
import { selectPeriod, selectNode } from 'Store/Areas/Projects/UserTreePickerActions';
import { SET_USER_WIP } from 'Store/Areas/App/MenuModeActions';
import { addSignalrSideEffect } from 'Store/Areas/App/SignalrSideEffectActions';
import { toggleSiteLoader } from 'Store/Areas/App/SiteLoaderActions';
import { startPolling } from 'Store/Areas/App/PeriodStatusPollingActions';
import { createPeriodSelectionSelector, createPeriodPayloadSelector } from 'Store/Areas/Projects/CreatePeriodSelectors';
import { exportCustomReportCloudRefSelector } from 'Store/Areas/Export/ExportSelectors';
import { commonStrings } from 'Constants/CommonStrings';
import { levels } from 'Constants/EntityLevels';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { routes } from 'Constants/Routes';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { strings } from 'Constants/Projects/CreatePeriod/Strings';
import { clearPeriodFiles } from 'Store/Areas/Import/UploadActions';

/* Worker Sagas */
function* getPeriodInfo(action) {
  const response = yield Get(PERIODS_GET_PERIOD_INFO(action.payload.periodId));

  if (response.ok) {
    const data = yield response.json();

    yield put(getPeriodInfoSuccess(
      action.payload.periodId,
      data,
    ));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* postPeriod(action) {
  const url = action.isEdit ? PROJECTS_EDIT_PERIOD_URL : PROJECTS_POST_PERIOD_URL;
  const response = yield Post(url, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(selectNode(
      action.selection.groupId,
      action.selection.entityId,
      action.selection.projectId,
      levels.project,
    ));
    yield put(selectPeriod(data.id));
    yield put(postPeriodSuccess());
    yield put(getTreePickerData());
  } else {
    yield handleStatusCode(response.status);
  }
}

function* editPeriodSaga(action) {
  const response = yield Get(PERIODS_GET_PERIOD(action.payload.selectedPeriodId));
  if (response.ok) {
    const data = yield response.json();
    yield put(editLoadPeriodSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* triggerPeriodArchive(payload) {
  const selection = yield select(createPeriodSelectionSelector);

  yield put(toggleSiteLoader(commonStrings.siteLoader.archiving));
  yield put(addSignalrSideEffect(payload.id, periodStatuses.periodArchived, {
    groupId: selection.selectedGroup.id,
    entityId: selection.selectedEntity.id,
    projectId: selection.selectedProject.id,
    periodId: payload.id,
    periodName: payload.name,
  }));
  yield put(startPolling(payload.id));
}

function* editPeriodLoaded({ payload }) {
  if (payload.status === periodStatuses.archiveQueued ||
    payload.status === periodStatuses.archiveRunning) {
    yield triggerPeriodArchive(payload);
  }
}

function* deletePeriod(action) {
  const response = yield Post(PERIOD_DELETE_PERIOD, action.payload);
  if (response.ok) {
    yield put(getTreePickerData());
  } else {
    yield handleStatusCode(response.status);
  }
}

function* clearPeriodData(action) {
  const response = yield Post(PERIOD_CLEAR_DATA, action.payload);
  if (response.ok) {
    yield put(clearPeriodFiles(action.payload.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* archivePeriod(action) {
  const payload = yield select(createPeriodPayloadSelector);
  yield put(connectToSignalRPeriodHub(payload.id));
  yield triggerPeriodArchive(payload);

  const response = yield Post(PERIOD_ARCHIVE_PERIOD, action.payload);
  if (!response.ok) {
    yield handleStatusCode(response.status);
  }
}

function* togglePeriodLock(action) {
  const response = yield Post(
    TOGGLE_PERIOD_LOCK_URL(!action.payload.isExport, action.payload.isLocking),
    action.payload,
  );

  if (response.ok) {
    const {
      selectedGroupId,
      selectedEntityId,
      selectedProjectId,
      selectedTaxContextId,
      selectedTemplateId,
      periodName,
    } = action.editingPeriodPayload;

    if (!action.payload.isExport) {
      yield put(editPeriod(
        selectedGroupId,
        selectedEntityId,
        selectedProjectId,
        action.payload.periodId,
        selectedTaxContextId,
        selectedTemplateId,
      ));
    } else if (action.payload.isExport && action.payload.isLocking) {
      const customReportCloudRef = yield select(exportCustomReportCloudRefSelector);

      yield put(setExportLocked(true));
      yield put(updatePeriodStatusInRedux(periodStatuses.exportRunning));
      yield put(requestExport(
        action.payload.periodId,
        [exportTypes.uncategorisedData],
        customReportCloudRef,
      ));
    } else if (!action.payload.isLocking && action.payload.isExport) {
      yield put(updatePeriodStatusInRedux(periodStatuses.readyForUserCategorisation));
    }

    yield put(getTreePickerData());
    yield put(displayToastNotification(strings.toggleLockPeriodNotificationText(
      periodName,
      action.payload.isLocking,
    )));
    yield put(getPeriodLockedByUserId(action.payload.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* resetCreatePeriod(action) {
  const route = yield select(currentRouteSelector);

  if (action.redirectToHome && route !== routes.home) {
    yield put(push(routes.home));
  }
}

function* resetStalePeriodData(action) {
  if (action.workInProgress) {
    yield put(resetPeriod());
  }
}

function* selectPeriodTemplateRule(action) {
  const response =
    yield Get(GET_TEMPLATE_RULES_WHILE_CREATE_PERIOD(action.form.selectedTemplateId));
  if (response.ok) {
    const data = yield response.json();
    yield put(selectPeriodTemplateRuleSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* createPeriodSagas() {
  yield takeLatest(GET_PERIOD_INFO, getPeriodInfo);
  yield takeLatest(POST_PERIOD, postPeriod);
  yield takeLatest(EDIT_PERIOD, editPeriodSaga);
  yield takeLatest(EDIT_LOAD_PERIOD_SUCCESS, editPeriodLoaded);
  yield takeLatest(DELETE_PERIOD, deletePeriod);
  yield takeLatest(CLEAR_PERIOD_DATA, clearPeriodData);
  yield takeLatest(ARCHIVE_PERIOD, archivePeriod);
  yield takeLatest(RESET_CREATE_PERIOD, resetCreatePeriod);
  yield takeLatest(SET_USER_WIP, resetStalePeriodData);
  yield takeLatest(TOGGLE_PERIOD_LOCK, togglePeriodLock);
  yield takeLatest(SELECT_PERIOD_TEMPLATE_RULE, selectPeriodTemplateRule);
}
