import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import Modal from 'Components/Shared/Modal/StyledModal';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import Checkbox from 'Components/Shared/Checkbox/Checkbox';
import { commonStrings } from 'Constants/CommonStrings';
import Select, { constants } from 'Components/Shared/Selects/Select';
import PropTypes from 'prop-types';
import { TagTypes } from 'Constants/Categorization/Tags';
import { referenceListsSelector } from 'Store/Areas/Categorisation/ReferenceListSelectors';
import { connect } from 'react-redux';
import { categoriseItems, resetCategorisationCreation } from 'Store/Areas/Rule/CategorisationActions';
import { periodIdSelector, tagSelector } from 'Store/Areas/Period/PeriodSelectors';
import { categorisationSelector } from 'Store/Areas/Rule/CategorisationSelectors';
import { strings } from 'Constants/Categorization/Strings';
import { styles } from './TagSelectionModal.styles';

const placeholderTag = { id: 0, name: strings.referenceListSelectTag };

class TagSelectionModal extends React.PureComponent {
  state = {
    selectedTag: placeholderTag,
    isSubstring: false,
  };

  onAccept = () => {
    const { selectedTag, isSubstring } = this.state;
    if (this.state.selectedTag.id === 0) {
      return;
    }

    const {
      dispatch,
      periodId,
      categoryType,
      categories,
      ruleProperties,
      referenceLists,
    } = this.props;
    const ruleCategories = categories.map(x => ({
      id: x.categoryId,
      percentage: x.percentage,
      name: x.categoryName,
    }));
    const selectedReferenceList = referenceLists.referenceLists.find(x =>
      x.name === ruleProperties.referenceListName);
    dispatch(categoriseItems(periodId, ruleCategories, categoryType, {
      ...ruleProperties,
      selectedTag: selectedTag.name,
      referenceListId: selectedReferenceList.id,
    }, '', '', [], 0, isSubstring));
    this.setState({
      selectedTag: placeholderTag,
      isSubstring: false,
    });
  }

  onCancel = () => {
    this.setState({
      selectedTag: placeholderTag,
      isSubstring: false,
    });
    this.props.dispatch(resetCategorisationCreation());
  }

  onSelection = (value) => {
    this.setState({
      selectedTag: value === 0 ? placeholderTag : this.props.tags.find(x => x.id === value),
    });
  }
  onClick = () => {
    this.setState({
      isSubstring: !this.state.isSubstring,
    });
  }

  render() {
    const {
      classes,
      tags,
      open,
      ruleProperties,
    } = this.props;
    const {
      selectedTag,
      isSubstring,
    } = this.state;
    return (
      <Modal open={open}>
        <div className={classes.root}>
          <div className={classes.title}>
            {strings.referenceListTitle}{ruleProperties.referenceListName}
          </div>
          <div className={classes.content}>
            {strings.referenceListDescription}
          </div>
          <Select
            id="Tags"
            label={commonStrings.tags}
            placeholder={placeholderTag.name}
            onChange={this.onSelection}
            data={tags}
            value={selectedTag.id}
            colorScheme={constants.colorScheme.lightBlue}
          />
          <Checkbox
            className={classes.checkbox}
            id="Substring"
            onClick={this.onClick}
            checked={isSubstring}
          />
          <span className={classes.content}>
            {commonStrings.subString}
          </span>
        </div>
        <Buttons
          onAccept={this.onAccept}
          onCancel={this.onCancel}
          acceptText={commonStrings.proceed}
          cancelText={commonStrings.cancel}
          submitDisabled={selectedTag.id === 0}
        />
      </Modal>
    );
  }
}

TagSelectionModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    categoryId: PropTypes.number,
    name: PropTypes.string,
    percentage: PropTypes.number,
    color: PropTypes.string,
  })).isRequired,
  periodId: PropTypes.number.isRequired,
  ruleProperties: PropTypes.object.isRequired, // eslint-disable-line
  dispatch: PropTypes.func.isRequired,
  categoryType: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  referenceLists: PropTypes.shape({
    referenceLists: PropTypes.array,
  }).isRequired,
};


const mapStateToProps = (state) => {
  const categorisationState = categorisationSelector(state);
  return {
    tags: tagSelector(state)
      .filter(t => t.type === TagTypes.Text || t.type === TagTypes.TextWholeValue)
      .map(t => ({ name: t.name, id: t.id })),
    periodId: periodIdSelector(state),
    open: categorisationState.displayTagSelection,
    ruleProperties: categorisationState.ruleProperties,
    categoryType: categorisationState.categoryType,
    categories: categorisationState.categories,
    referenceLists: referenceListsSelector(state),
  };
};

export default compose(withStyles(styles), connect(mapStateToProps))(TagSelectionModal);

