const styles = theme => ({
  header: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  card: {
    padding: 25,
    paddingBottom: 20,
  },
  list: {
    fontWeight: 'normal',
    marginTop: 14,
    maxHeight: 500,
    width: 'inherit',
  },
  listWithContent: {
    overflowY: 'auto',
  },
  loader: {
    height: 'auto !important',
    paddingTop: 5,
  },
  keyword: {
    paddingBottom: 10,
  },
  value: {
    paddingBottom: 10,
    paddingLeft: 10,
  },
});

export default styles;
