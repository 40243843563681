import { call, take, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as signalR from '@aspnet/signalr';
import { process } from 'process';
import { getAdalToken } from './CallApi';
import { apiUrl } from '../../index';

function* processSignalR({
  hubUrl,
  name,
  endConnection,
}) {
  let token = yield call(getAdalToken);
  token = [...token];

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${apiUrl}/${hubUrl}`, {
      transport: signalR.HttpTransportType.WebSockets,
      skipNegotiation: true,
      accessTokenFactory: () => token.reduce((x, y, i) => y + ((token[token.length - i - 1].charCodeAt(0) & 1) ? '~' : '!') + x, ''), // eslint-disable-line no-bitwise
    })
    .configureLogging(process && process.env && process.env.NODE_ENV === 'development' ? signalR.LogLevel.Trace : signalR.LogLevel.Debug)
    .build();

  const serverTimeoutInSeconds =
    yield select(state => state.app.config.signalRKeepAliveIntervalInSeconds);

  connection.serverTimeoutInMilliseconds = (serverTimeoutInSeconds * 2.5) * 1000;
  connection.start();

  return eventChannel((emitter) => {
    connection.on(name, (x) => {
      emitter(x);
    });

    return () => endConnection(connection, name);
  });
}

/* Watcher Sagas */
export function* watchSignalREvents({
  action,
  processSignalRPayload,
  handleSignalRMessage,
  cancelled,
}) {
  const channel = yield call(processSignalR, processSignalRPayload);
  try {
    while (true) {
      const payload = JSON.parse(yield take(channel));
      yield handleSignalRMessage(payload, action);
    }
  } finally {
    if (cancelled && (yield cancelled())) {
      channel.close();
    }
  }
}
