import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Sidebar from 'Components/UserSidebar/Sidebar';
import withCorruptedFileProvider from 'Components/Shared/CorruptedFileProvider/WithCorruptedFileProvider';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import { toggleAntiVirusLoader } from 'Store/Areas/FileUpload/FileUploadActions';
import { hasFilesAwaitingScanSelector } from 'Store/Areas/Period/PeriodSelectors';
import DeleteFileModal from './UploadPage/Modals/DeleteFileModal';
import DeleteSupportingDocsModal from './UploadPage/Modals/DeleteSupportingDocsModal';
import ReviewPage from './ReviewPage/ReviewPage';
import UploadErrorModal from './ErrorModal/ErrorModal';
import UploadPage from './UploadPage/UploadPage';
import VirusLoader from './UploadPage/VirusLoader';
import SupportingMappingPage from './MappingPage/SupportingMappingPage';
import MainMappingPage from './MappingPage/MainMappingPage';


class UploadManager extends React.PureComponent {
  componentDidMount() {
    const { hasFilesAwaitingScan, dispatch } = this.props;
    if (hasFilesAwaitingScan) {
      dispatch(toggleAntiVirusLoader(true));
    }
  }

  componentDidUpdate(prevProps) {
    const { hasFilesAwaitingScan, dispatch } = this.props;
    if (hasFilesAwaitingScan && !prevProps.hasFilesAwaitingScan) {
      dispatch(toggleAntiVirusLoader(true));
    }
  }

  render() {
    const { projects, fileUpload, upload } = this.props;
    const location = this.props.match.params.stage;
    const sidebarLoading = projects.treePicker.loading;

    return (
      <Fragment>
        <UserSidebarStructure
          collapsed
          isCollapsible
          sidebarLoading={sidebarLoading}
          mainContentLoading={upload.loading}
          sidebarContent={<Sidebar />}
          mainContent={
            <Fragment>
              <DeleteFileModal />
              <DeleteSupportingDocsModal />
              <Choose>
                <When condition={fileUpload.checkingForViruses}>
                  <VirusLoader />
                </When>
                <Otherwise>
                  <Choose>
                    <When condition={location === 'upload'}>
                      <UploadPage currencySymbol="" />
                    </When>
                    <When condition={location === 'mapping-main'}>
                      <MainMappingPage />
                    </When>
                    <When condition={location === 'mapping-supporting'}>
                      <SupportingMappingPage />
                    </When>
                    <When condition={location === 'review'}>
                      <ReviewPage />
                    </When>
                  </Choose>
                </Otherwise>
              </Choose>
            </Fragment>
          }
        />
        <UploadErrorModal />
      </Fragment>
    );
  }
}

UploadManager.propTypes = {
  dispatch: PropTypes.func.isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
    }),
  }).isRequired,
  upload: PropTypes.shape({
    loading: PropTypes.bool,
  }).isRequired,
  fileUpload: PropTypes.shape({
    uploadResponse: PropTypes.object,
    showCorruptedFileModal: PropTypes.bool,
    checkingForViruses: PropTypes.bool,
    fileContainsVirus: PropTypes.bool,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      stage: PropTypes.string,
    }),
  }).isRequired,
  hasFilesAwaitingScan: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    period: state.periods.period,
    fileUpload: state.app.fileUpload,
    upload: state.import.upload,
    hasFilesAwaitingScan: hasFilesAwaitingScanSelector(state),
  };
}

export default compose(withCorruptedFileProvider(), connect(mapStateToProps))(UploadManager);
