export const styles = theme => ({
  root: {
    padding: 20,
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '50vh',
    overflowY: 'auto',
  },
  paperContainer: {
    padding: '20px 20px 30px 20px',
    margin: '0 20px 0 20px',
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: theme.constants.fontSizes.small,
  },
  note: {
    marginTop: 10,
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  description: {
    fontSize: theme.constants.fontSizes.regular,
    lineHeight: 1.5,
    marginRight: 10,
    marginBottom: 10,
  },
  loader: {
    alignSelf: 'center',
    margin: 10,
  },
});

export default styles;
