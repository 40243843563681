const styles = theme => ({
  root: {
    marginLeft: 20,
    marginTop: 10,
    marginRight: 20,
  },
  card: {
    padding: 20,
    marginBottom: 18,
    minHeight: '90vh',
    width: '100%',
  },
  title: {
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.large,
  },
  breadcrumb: {
    margin: 20,
  },
  fullWidth: {
    width: '100%',
    padding: '0 20px',
  },
  description: {
    margin: 0,
    marginRight: 39,
    textAlign: 'justify',
    fontSize: theme.constants.fontSizes.regular,
    lineHeight: '22px',
  },
  gridRow: {
    paddingBottom: 18,
  },
  gridRowTable: {
    paddingTop: 18,
  },
  gridRowRight: {
    paddingLeft: 18,
    paddingBottom: 18,
  },
  gridRowGraph: {
    padding: '0 120px 40px 120px',
    position: 'relative',
  },
  previewTableGrid: {
    width: 0 /* this seems to fix a layout issue causing the grid to stretch out beyond 100% */,
  },
  sliders: {
    flexGrow: 1,
    maxHeight: '70vh',
    overflow: 'auto',
    padding: '0 20px',
  },
  slider: {
    marginBottom: 30,
  },
  sliderLabel: {
    alignItems: 'center',
    color: '#656565',
    display: 'flex',
    fontSize: theme.constants.fontSizes.small,
  },
  sliderColor: {
    display: 'inline-block',
    borderRadius: 40,
    height: 10,
    marginRight: 7,
    width: 10,
  },
  change: {
    fontWeight: 'bold',
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  button: {
    marginLeft: 5,
    border: 'none',
    padding: 0,
    backgroundColor: 'white',
  },
});

export default styles;
