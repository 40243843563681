export const styles = theme => ({
  root: {
    flexDirection: 'column',
  },
  buttonBase: {
    height: 30,
    textAlign: 'left',
    outline: 'none',
    width: 'fit-content',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
  },
  button: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: theme.constants.fontSizes.small,
  },
  buttonDisabled: {
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.small,
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  flex: {
    flexGrow: 1,
  },
});
