import handleStatusCode from 'Store/Api/HandleStatusCode';
import { takeEvery, put } from 'redux-saga/effects';
import { LINE_ITEM_GET_LINE_ITEM_METADATA } from 'Store/Api/ApiEndpoints';
import { Post } from 'Store/Api/CallApi';
import { GET_LINE_ITEM_METADATA, getLineItemMetadataSuccess } from 'Store/Areas/Review/LineItemMetadataActions';

function* getLineItemMetadata(action) {
  const response = yield Post(LINE_ITEM_GET_LINE_ITEM_METADATA, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getLineItemMetadataSuccess(
      data.lineItemSum,
      data.lineItemCount,
      action.categorizationType,
    ));
  } else {
    handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* lineItemMetadataSaga() {
  yield takeEvery(GET_LINE_ITEM_METADATA, getLineItemMetadata);
}
