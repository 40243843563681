export const COMPARE_PERIOD_FILTERS = 'COMPARE_PERIOD/COMPARE_PERIOD_FILTERS';
export const GET_COMPARE_PERIOD_SUCCESS = 'COMPARE_PERIOD/GET_COMPARE_PERIOD_SUCCESS';
export const RESET_COMPARE_PERIOD = 'COMPARE_PERIOD/RESET_COMPARE_PERIOD';

export function getComparePeriodSuccess(
  comparePeriodId,
  response,
) {
  return {
    type: GET_COMPARE_PERIOD_SUCCESS,
    loading: false,
    success: true,
    comparePeriodId: comparePeriodId,
    response: response,
  };
}

export function resetComparePeriod() {
  return {
    type: RESET_COMPARE_PERIOD,
  };
}

export function updateComparePeriodFilters(comparePeriodId) {
  return {
    type: COMPARE_PERIOD_FILTERS,
    comparePeriodId: comparePeriodId,
  };
}
