import React from 'react';
import { withTheme, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Loader from 'Components/Shared/Loaders/CircularLoader';
import { strings } from 'Constants/Upload/Strings';
import styles from './VirusLoader.styles';

class VirusLoader extends React.PureComponent {
  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.loaderContainer}>
        <Loader
          size={60}
        />
        <div className={classes.message}>
          {strings.virusMessage}
        </div>
      </div>
    );
  }
}

VirusLoader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  withTheme(),
  withStyles(styles),
)(VirusLoader);

