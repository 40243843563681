const styles = theme => ({
  root: {
    fontSize: theme.constants.fontSizes.regular,
  },
  darkRed: {
    color: '#bb1f4a',
  },
  pink: {
    color: '#f198b1',
  },
  padding: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  bold: {
    fontWeight: 'bold',
  },
});

export default styles;
