import Yup from 'yup';
import { allowedCharacters, beginsOrEndsInSpaces, caseInsensitiveDuplicateTestOptions } from '../../ValidationHelpers';

export function getCreateEntitySchema(entityNames) {
  return (
    Yup.object().shape({
      group: Yup.number().min(1, 'Group is required.'),
      entity: Yup.string()
        .required('Entity is required.')
        .test(caseInsensitiveDuplicateTestOptions(
          entityNames,
          'Entity names must be unique (irrespective of capitalisation).',
        ))
        .notOneOf(entityNames, 'Entity names must be unique.')
        .max(50, 'Entity names must be less than 50 characters long.')
        .matches(allowedCharacters, 'Entity name contains forbidden characters.')
        .matches(beginsOrEndsInSpaces, 'Entity name must not begin or end with spaces.'),
      currency: Yup.number().min(1, 'Currency is required.'),
    })
  );
}
