const styles = theme => ({
  labelLight: {
    opacity: '0.6',
    color: theme.palette.colors.white,
    fontSize: theme.constants.fontSizes.small,
  },
  labelDark: {
    opacity: '0.6',
    color: '#656565',
    fontSize: theme.constants.fontSizes.small,
  },
  whiteInput: {
    color: theme.palette.colors.white,
    fontSize: theme.constants.fontSizes.regular,
  },
  blueInput: {
    color: theme.palette.primary.main,
    fontSize: theme.constants.fontSizes.small,
  },
  icon: {
    position: 'absolute',
    right: 10,
    bottom: 20,
    pointerEvents: 'none',
  },
  noPadding: {
    padding: 0,
  },
  whiteMenu: {
    paddingRight: 30,
    height: 50,
    borderBottomColor: theme.palette.colors.white,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
  },
  blueMenu: {
    paddingRight: 30,
    height: 50,
    borderBottomColor: theme.palette.primary.main,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
  },
  menuItem: {
    fontSize: theme.constants.fontSizes.regular,
    height: '45px',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.colors.white,
    color: theme.palette.colors.black,
  },
  selected: {
    backgroundColor: theme.palette.primary.main + ' !important', // eslint-disable-line
    color: theme.palette.colors.white,
  },
  placeholder: {
    color: `${theme.palette.primary.darkText} !important`,
    fontSize: theme.constants.fontSizes.small,
  },
});

export default styles;