export const styles = theme => ({
  root: {
    padding: 0,
  },
  content: {
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
    marginTop: 10,
  },
  form: {
    width: '100%',
  },
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  input: {
    marginBottom: 10,
  },
  logo: {
    height: 120,
    maxWidth: 100,
  },
  loader: {
    height: 'auto !important',
    paddingTop: 5,
  },
  grid: {
    marginTop: 70,
    color: theme.palette.primary.darkText,
    fontWeight: 20,
    fontSize: theme.constants.fontSizes.large,
    padding: 0,
    maxWidth: '60%',
    maxHeight: '50%',
    marginLeft: 250,
  },
  gridWithLogo: {
    backgroundColor: '#453998',
    padding: 30,
  },
  gridlogginInArea: {
    backgroundColor: '#f5f5f5',
    display: 'inline-flex',
  },
  brandImage: {
    marginLeft: 140,
    marginRight: 140,
    marginTop: 50,
    marginBottom: 50,
  },
  signUpText: {
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Arial',
    fontSize: 20,
    fontWeight: 300,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    marginTop: 50,
  },
  messageText: {
    fontFamily: 'Arial',
    marginBottom: 30,
    marginLeft: 10,
    marginTop: 150,
    padding: 0,
    background: 'none',
    border: 20,
    fontSize: '18px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.06,
    letterSpacing: 1.31,
    color: '#353535',
  },
  button: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    padding: 0,
    margin: 0,
    borderRadius: 0,
    height: 60,
    '&[disabled]': {
      color: theme.palette.primary.contrastText,
      opacity: 0.6,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonSpace: {
    marginLeft: 10,
    maxHeight: 50,
  },
  acceptButton: {
    flexGrow: 3,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.colors.mainLightened,
    },
    width: '100%',
  },
  footerCard: {
    marginTop: 50,
    padding: '20px 20px 0 20px',
    backgroundColor: 'none',
    width: 'Calc(100% - 150px)',
    overflowY: 'auto',
    marginLeft: 70,
  },
  footerLinks: {
    display: 'block',
    marginBottom: 20,
    color: '#353535',
    paddingBottom: 6,
    textDecoration: 'none',
    marginRight: 20,
  },
  textLink: {
    marginRight: 20,
    borderBottom: '1px solid #353535',
  },
  footerText: {
    fontSize: 12,
  },
});

export default styles;