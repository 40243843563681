export const userActions = {
  Import: 'Import',
  RunSingleRule: 'RunSingleRule',
  DeleteRule: 'DeleteRule',
  EditRulePriority: 'EditRulePriority',
  ResolveConflict: 'ResolveConflict',
  AllowConflict: 'AllowConflict',
  AllowAllConflicts: 'AllowAllConflicts',
  ManualCategorisation: 'ManualCategorisation',
  PeriodFileUpload: 'PeriodFileUpload',
  TemplateFileUpload: 'TemplateFileUpload',
  Export: 'Export',
  ProcessSupportingDocuments: 'ProcessSupportingDocuments',
  CustomReportFileUpload: 'CustomReportFileUpload',
  Archive: 'Archive',
  ReferenceListFileUpload: 'ReferenceListFileUpload',
  MlCategorisationReason: 'MlCategorisationReason',
  ExternalReviewFileUpload: 'ExternalReviewFileUpload',
  ExternalReviewFileImport: 'ExternalReviewFileImport',
  RemoveContra: 'RemoveContra',
  DeleteSupportingDoc: 'DeleteSupportingDoc',
  RunSingleRuleForApportionable: 'RunSingleRuleForApportionable',
  ApportionmentCalculationProcess: 'ApportionmentCalculationProcess',
  DeleteCategory: 'DeleteCategory',
  ResetPeriod: 'ResetPeriod',
  UnCategoriseLineItem: 'UnCategoriseLineItem',
};
