import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles, withTheme, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { compose } from 'recompose';
import { strings } from 'Constants/Upload/Strings';
import Check from 'Assets/Images/small-check-ic.svg';
import { selectMappedTagsFromPeriod } from 'Constants/Categorization/Tags';
import Cross from 'Assets/Images/small-cross-ic.svg';
import styles from './TagInstructions.styles';

class TagsInstructions extends React.PureComponent {
  render() {
    const { tags, classes, schema } = this.props;

    let mappedIds = [];

    if (schema && schema.headers) {
      mappedIds = schema.headers.map(x => x.tagId);
    }

    return (
      <div className={classes.tagContainer}>
        <p className={classes.tagsTitle}>{strings.tagSectionTitle}</p>
        <div className={classes.tableContainer}>
          <Table>
            <TableBody>
              <If condition={tags.length > 0}>
                <For each="tag" of={tags}>
                  <With mapped={mappedIds.indexOf(tag.id) > -1}>
                    <TableRow className={classes.row} key={`tag_${tag.id}`}>
                      <TableCell className={classes.cell}>
                        <span
                          className={`
                            ${classes.flex}
                            ${!mapped && mappedIds.length > 0 ? classes.bold : ''}`
                          }
                        >
                          {tag.name}
                        </span>
                        <If condition={mapped && mappedIds.length > 0}>
                          <img src={Check} alt="" className={classes.icon} />
                        </If>
                        <If condition={!mapped && mappedIds.length > 0}>
                          <img src={Cross} alt="" className={classes.icon} />
                        </If>
                      </TableCell>
                    </TableRow>
                  </With>
                </For>
              </If>
              <If condition={tags.length === 0}>
                <TableRow className={classes.row} key="no_tags">
                  <TableCell className={classes.cell}>{strings.noTagsSpecified}</TableCell>
                </TableRow>
              </If>
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

TagsInstructions.defaultProps = {
  schema: null,
};

TagsInstructions.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  schema: PropTypes.shape({
    headers: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      tagId: PropTypes.int,
      id: PropTypes.int,
    })),
  }),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};


function mapStateToProps(state) {
  return {
    tags: selectMappedTagsFromPeriod(state),
    schema: state.periods.period.data.fileSchema,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(TagsInstructions);
