import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  previousLocation: '',
  currentLocation: '',
};

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        previousLocation: state.currentLocation,
        currentLocation: action.payload.location.pathname,
      };
    default:
      return state;
  }
}
