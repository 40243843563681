import { takeLatest, put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { Post } from 'Store/Api/CallApi';
import {
  LINE_ITEM_GET_LINE_ITEMS,
  LINE_ITEM_UNCATEGORISE_LINE_ITEM,
  LINE_ITEM_MARK_LINE_ITEMS_AS_CONTRA,
  LINE_ITEM_MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED,
} from 'Store/Api/ApiEndpoints';
import {
  getLineItemsSuccess,
  GET_LINE_ITEMS,
  UNCATEGORISE_LINE_ITEM,
  MARK_LINE_ITEMS_AS_CONTRA,
  markLineItemsAsContraSuccess,
  MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED,
  markLineItemsAsFlaggedOrUnflaggedSuccess,
} from 'Store/Areas/Categorisation/LineItemActions';
import { checkApportionmentCalculation } from 'Store/Areas/Apportionables/ApportionablesActions';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import { getLineItemMetadata } from 'Store/Areas/Review/LineItemMetadataActions';
import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';
import { periodStatuses } from 'Constants/PeriodStatuses';

function* getRuleItemsSaga(action) {
  yield call(delay, action.debounceDelay);
  const response = yield Post(LINE_ITEM_GET_LINE_ITEMS, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getLineItemsSuccess(data));
    if (data.rows.length === 0 &&
      (data.periodStatus === periodStatuses.readyForUserCategorisation)) {
      yield put(checkApportionmentCalculation(action.payload.periodId));
    }
  } else {
    yield handleStatusCode(response.status);
  }
}
function* uncategoriseLineItemSaga(action) {
  const response = yield Post(LINE_ITEM_UNCATEGORISE_LINE_ITEM, action.payload);
  if (!response.ok) {
    yield handleStatusCode(response.status);
  }
}

function* markLineItemsAsContraSaga(action) {
  const response = yield Post(LINE_ITEM_MARK_LINE_ITEMS_AS_CONTRA, action.payload);
  if (response.ok) {
    yield put(markLineItemsAsContraSuccess());
    yield put(getLineItemMetadata(action.payload.periodId, categorizationTypes.contra, null));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* markLineItemsAsFlaggedOrUnflaggedSaga(action) {
  const response = yield Post(LINE_ITEM_MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED, action.payload);
  if (response.ok) {
    yield put(markLineItemsAsFlaggedOrUnflaggedSuccess());
    yield put(getPeriod(action.payload.periodId, true));
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* lineItemSagas() {
  yield takeLatest(GET_LINE_ITEMS, getRuleItemsSaga);
  yield takeLatest(UNCATEGORISE_LINE_ITEM, uncategoriseLineItemSaga);
  yield takeLatest(MARK_LINE_ITEMS_AS_CONTRA, markLineItemsAsContraSaga);
  yield takeLatest(MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED, markLineItemsAsFlaggedOrUnflaggedSaga);
}
