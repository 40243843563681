const styles = theme => ({
  content: {
    padding: 20,
    height: 'calc(100vh - 200px)',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  operators: {
    width: '30%',
  },
  grid: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  builder: {
    width: '70%',
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    width: 'calc(100% - 55px)',
  },
  textSize: {
    fontSize: theme.constants.fontSizes.small,
  },
  boldText: {
    fontWeight: 'bold',
  },
  rulesBuilderWrapper: {
    flexShrink: 0,
    flexBasis: '300px',
  },
  ruleInfoWrapper: {
    flexGrow: 0,
    flexShrink: 0,
    fontSize: theme.constants.fontSizes.small,
  },
});

export default styles;
