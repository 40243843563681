import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { styles } from './SimpleDragCard.styles';

export const constants = {
  borderColor: {
    blue: 'blueBorder',
    grey: 'greyBorder',
  },
};

const SimpleDragCard = (props) => {
  const {
    classes,
    children,
    borderColor,
    preventHoverShadow,
    className,
  } = props;
  return (
    <div
      className={`
        ${className}
        ${classes.root}
        ${classes[borderColor]}
        ${preventHoverShadow ? '' : classes.shadow}
      `}
    >
      {children}
    </div>
  );
};

SimpleDragCard.defaultProps = {
  borderColor: constants.borderColor.grey,
  preventHoverShadow: false,
  className: '',
};

SimpleDragCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
  ]).isRequired,
  borderColor: PropTypes.oneOf(Object.values(constants.borderColor)),
  preventHoverShadow: PropTypes.bool,
};

export default compose(withStyles(styles), withTheme())(SimpleDragCard);
