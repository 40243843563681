import React from 'react';
import { Select as MuiSelect, Checkbox, MenuItem, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import ChevronWhite from 'Assets/Images/chevron-down-ic.svg';
import ChevronBlue from 'Assets/Images/chevron-down-blue-ic.svg';
import ErrorLabel, { constants as labelConstants } from 'Components/Shared/Label/ErrorLabel';
import styles from './CheckboxSelect.styles';

export const constants = {
  colorScheme: {
    white: 'white',
    lightBlue: 'lightBlue',
  },
};

class CheckboxSelect extends React.PureComponent {
  getStyleClasses() {
    const {
      colorScheme,
      classes,
    } = this.props;

    if (colorScheme === constants.colorScheme.white) {
      return {
        input: classes.whiteInput,
        menu: classes.whiteMenu,
        label: classes.labelLight,
        chevron: ChevronWhite,
        errorColor: labelConstants.textColor.pink,
      };
    }

    return {
      input: classes.blueInput,
      menu: classes.blueMenu,
      label: classes.labelDark,
      chevron: ChevronBlue,
      errorColor: labelConstants.textColor.darkRed,
    };
  }

  render() {
    const {
      classes,
      className,
      label,
      data,
      value,
      id,
      onChange,
      error,
    } = this.props;

    const styleClasses = this.getStyleClasses();

    return (
      <div className={className}>
        <div className={styleClasses.label}>{label}</div>
        <MuiSelect
          id={id}
          disableUnderline
          classes={{
            root: styleClasses.input,
            selectMenu: styleClasses.menu,
            select: classes.noPadding,
          }}
          multiple
          fullWidth
          renderValue={selected => `Items selected: ${selected.length}`}
          IconComponent={() => <img src={styleClasses.chevron} alt="Select" className={classes.icon} />}
          value={value}
          onChange={event => onChange(event.target.value)}
          MenuProps={{
            MenuListProps: {
              className: classes.noPadding,
            },
          }}
        >
          {data.map((item) => {
            return (
              <MenuItem
                value={item.id}
                name={item.name}
                className={classes.menuItem}
                classes={{ selected: classes.selected }}
                key={item.id}
              >
                <Checkbox checked={value.indexOf(item.id) > -1} />
                {item.name}
              </MenuItem>
            );
          })}
        </MuiSelect>
        <ErrorLabel error={error} verticalPadding textColor={styleClasses.errorColor} />
      </div>
    );
  }
}
CheckboxSelect.defaultProps = {
  className: '',
  label: '',
  data: [],
  error: '',
  colorScheme: constants.colorScheme.white,
};

CheckboxSelect.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string]).isRequired, // unique identifying id
    name: PropTypes.string.isRequired, // Text to display in the dropdown
  })),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array]).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  colorScheme: PropTypes.oneOf(Object.values(constants.colorScheme)),
};

export default compose(withStyles(styles), withTheme())(CheckboxSelect);