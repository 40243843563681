export const bulkUserUploadImportStages = {
  uploading: 0,
  passedScan: 1,
  bulkUsersUploaded: 2,
  failedScan: 3,
  error: 4,
};

export const bulkUserUploadProcessingStages = [
  {
    stageId: bulkUserUploadImportStages.uploading,
    stageName: 'Scanning File',
    startMessage: 'Scanning File',
  },
  {
    stageId: bulkUserUploadImportStages.passedScan,
    stageName: 'Uploading Users',
    startMessage: 'Uploading Users',
  },
  {
    stageId: bulkUserUploadImportStages.bulkUsersUploaded,
    stageName: 'Finishing Up',
    startMessage: 'Finishing Up',
  },
];
