import React from 'react';
import { connect } from 'react-redux';
import {
  withStyles,
  withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import StyledModal from 'Components/Shared/Modal/StyledModal';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import styles from './SiteLoader.styles';

class SiteLoader extends React.PureComponent {
  render() {
    const {
      classes,
      siteLoader,
    } = this.props;
    return (
      <StyledModal open={siteLoader.isLoading}>
        <div className={classes.root}>
          {
            siteLoader.message &&
            <div className={classes.message}>{siteLoader.message}</div>
          }
          <CircularLoader className={classes.loader} fullWidth={false} />
        </div>
      </StyledModal>
    );
  }
}

SiteLoader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  siteLoader: PropTypes.shape({
    message: PropTypes.string,
    isLoading: PropTypes.bool,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    siteLoader: state.app.siteLoader,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(SiteLoader);
