export const styles = theme => ({
  root: {
    height: '120vh',
    padding: '20px 30px 30px 30px',
    margin: '15px 30px 0 20px',
    display: 'flex',
    flexDirection: 'column',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  flex: {
    flexGrow: 1,
  },
  header: {
    marginTop: 10,
    lineHeight: '24px',
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  description: {
    fontSize: theme.constants.fontSizes.regular,
    marginRight: 50,
    lineHeight: '22px',
  },
  proceedButton: {
    width: 350,
  },
  titleRow: {
    alignItems: 'center',
    color: theme.palette.colors.black,
    display: 'flex',
    height: 14,
    marginBottom: 12,
  },
  title: {
    fontWeight: 'bold',
    height: 14,
    width: 63,
    flexGrow: '1',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 10,
  },
  arrowButton: {
    flex: 1,
    width: '100%',
    display: 'flex',
  },
  columnHeader: {
    fontSize: theme.constants.fontSizes.small,
    color: theme.palette.primary.darkText,
  },
  cell: {
    padding: '0 30px 0 0',
    minWidth: 169,
    maxWidth: 169,
    maxHeight: 60,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'right',
  },
  imgButton: {
    zIndex: '200',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
    marginTop: '-5px',
    fontSize: theme.constants.fontSizes.small,
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  marginTop: {
    marginTop: '40px',
  },
  noMatchedRows: {
    fontWeight: 'bold',
  },
});

