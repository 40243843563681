import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Grid, withStyles } from '@material-ui/core';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import { connect } from 'react-redux';
import FullPageModal from 'Components/Shared/Modal/FullPageModal/FullPageModal';
import { updatePeriodStatus, unlockPeriod } from 'Store/Areas/Projects/ChangePeriodStatusActions';
import { strings } from 'Constants/Projects/Strings';
import { periodStatuses } from 'Constants/PeriodStatuses';
import styles from './ChangePeriodStatus.styles';

class ChangePeriodStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChangeStatusButtonClicked: false,
      isUnlockButtonClicked: false,
      selectedReason: '',
      error: '',
    };
  }

  handleValidation = () => {
    let error = '';
    const { period } = this.props;
    const { selectedReason } = this.state;
    let isValid = true;
    if (selectedReason === '') {
      error = strings.emptyStatusValidationText;
      isValid = false;
      this.setState({ error: error });
    } else if (selectedReason === period.status) {
      error = strings.sameStatusValidationText;
      isValid = false;
      this.setState({ error: error });
    }
    return isValid;
  }

  handleChangeStatus = () => {
    if (this.handleValidation()) {
      const {
        period,
      } = this.props;
      const { selectedReason } = this.state;
      this.setState({
        isChangeStatusButtonClicked: true,
      });
      this.props.dispatch(updatePeriodStatus(period.id, selectedReason, period.name));
    }
  }

  handleUnlock = () => {
    const {
      period,
    } = this.props;
    this.setState({
      isUnlockButtonClicked: true,
    });
    this.props.dispatch(unlockPeriod(period.id, period.name));
  }

  selectReason = (event) => {
    if (this.state.selectedReason !== event.target.value) {
      this.setState({
        selectedReason: event.target.value,
        error: '',
      });
    }
  }

  getPeriodStatuses() {
    return Object.values(periodStatuses).map((status) => {
      return (
        <option
          key={status}
          value={status}
          className={this.props.classes.optionSelected}
        >
          {status}
        </option>
      );
    });
  }

  render() {
    const {
      classes,
      period,
      isOpen,
      onClose,
    } = this.props;
    const { selectedReason } = this.state;
    return (
      <FullPageModal
        open={isOpen}
        onClose={onClose}
        title={strings.changePeriodStatusTitle}
      >
        <div>
          <If condition={period}>
            <div className={classes.descriptionWarning}>
              {strings.changePeriodStatusText(period.name)}
            </div>
            <Grid>
              <Grid container direction="row">
                <Grid item xs>
                  <div>
                    <span className={classes.headerText}>{strings.currentPeriodStatus}</span>
                  </div>
                  <div><span className={classes.mainText}>{period.status}</span></div>
                </Grid>
                <Grid item xs>
                  <div>
                    <span className={classes.headerText}>{strings.selectPeriodStatus}</span>
                  </div>
                  <div><span className={classes.validationError}>{this.state.error}</span></div>
                  <select
                    onChange={this.selectReason}
                    value={selectedReason}
                    className={this.props.classes.menuItem}
                  >
                    <option value="" key={0} />
                    {this.getPeriodStatuses()}
                  </select>
                </Grid>
                <Grid item xs>
                  <Button
                    disabled={this.state.isChangeStatusButtonClicked}
                    className={classes.submitButton}
                    onClick={this.handleChangeStatus}
                    backgroundColor={constants.backgroundColor.dark}
                  >
                    {strings.updateStatus}
                  </Button>
                </Grid>
              </Grid>
              <Grid container direction="row">
                <Grid item xs>
                  <div>
                    <span className={classes.headerText}>{strings.isPeriodLockedByUser}</span>
                  </div>
                  <div><span className={classes.mainText}>{period.isLocked ? 'True' : 'False'}</span></div>
                </Grid>
                <Grid item xs>
                  <div><span className={classes.headerText}>{strings.periodLockReason}</span></div>
                  <div><span className={classes.mainText}>{period.periodLockReason}</span></div>
                </Grid>
                <Grid item xs>
                  <Button
                    disabled={!period.isLocked || this.state.isUnlockButtonClicked}
                    className={classes.submitButton}
                    onClick={this.handleUnlock}
                    backgroundColor={constants.backgroundColor.dark}
                  >
                    {strings.unlockPeriod}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </If>
        </div>
      </FullPageModal>
    );
  }
}

ChangePeriodStatus.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  period: PropTypes.shape({
    data: PropTypes.shape({
      isLocked: PropTypes.bool,
      status: PropTypes.string,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default compose(
  connect(),
  withStyles(styles),
)(ChangePeriodStatus);