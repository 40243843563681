import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'recompose';
import { Formik } from 'formik';
import { Card, withStyles, TextField, Grid } from '@material-ui/core';
import { machineLearningSelector } from 'Store/Areas/MachineLearning/MachineLearningSelectors';
import { cancelAddModel, postAddModel, postEditModel, postDeleteModel, checkIfModelIsUsed } from 'Store/Areas/MachineLearning/MachineLearningActions';
import Button, { constants as buttonConstants } from 'Components/Shared/Buttons/Button';
import Select, { constants as selectConstants } from 'Components/Shared/Selects/Select';
import CheckboxSelect, { constants as checkboxSelectConstants } from 'Components/Shared/Selects/CheckboxSelect';
import SidebarInput from 'Components/Shared/Inputs/SidebarInput';
import ErrorLabel, { constants } from 'Components/Shared/Label/ErrorLabel';
import DisabledSelect from 'Components/Shared/Selects/DisabledSelect';
import { routes } from 'Constants/Routes';
import { strings } from 'Constants/ModelManagement/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { taxContexts } from 'Constants/Projects/TaxContexts';
import { licenseTypes } from 'Constants/LicenseTypes';
import { createModelSchema } from 'Constants/ModelManagement/CreateModelSchema';
import { getAdminTreePickerData } from 'Store/Areas/Setup/Admin/AdminTreePickerActions';
import { adminTreePickerDataSelector } from 'Store/Areas/Setup/Admin/SetupAdminSelectors';
import { styles } from './CreateModel.styles';
import DeleteModelModal from './DeleteModelModal';

class CreateModel extends React.PureComponent {
  state = {
    isDeleteModalOpen: false,
    filterSelection: [],
  };

  componentWillMount() {
    const { modelVersion, dispatch } = this.props;

    if (!modelVersion) {
      dispatch(push(routes.models));
    }
  }

  componentDidMount() {
    const { dispatch, editModel } = this.props;
    if (editModel) {
      dispatch(checkIfModelIsUsed(editModel.id));
    }
    dispatch(getAdminTreePickerData(false, 0, ''));
  }

  onCancel = () => {
    const { dispatch } = this.props;

    dispatch(cancelAddModel());
    dispatch(push(routes.models));
  }

  onOpenDelete = () => {
    this.setState({
      isDeleteModalOpen: true,
    });
  }

  onCloseDelete = () => {
    this.setState({
      isDeleteModalOpen: false,
    });
  }

  onDeleteModelConfirm = () => {
    const { dispatch, editModel } = this.props;
    dispatch(postDeleteModel(editModel.id));
    this.onCloseDelete();
  }

  onSubmitAdd = ({
    name,
    key,
    description,
    taxContext,
    categories,
    licenseType,
  }) => {
    const { dispatch, modelVersion } = this.props;
    const licenseTypeId = licenseType === 0 ? 1 : licenseType;
    dispatch(postAddModel(
      name, description, categories, key, taxContext,
      licenseTypeId, this.state.filterSelection, modelVersion.id,
    ));
  }

  onSubmitEdit = ({
    name,
    description,
  }) => {
    const { dispatch, editModel } = this.props;
    dispatch(postEditModel(editModel.id, name, description));
  }

  handleFilterSelection = (selection) => {
    this.setState({
      filterSelection: selection,
    });
  }

  render() {
    const {
      classes,
      modelVersion,
      editModel,
      modelUsed,
      adminTreePicker,
    } = this.props;

    const { isDeleteModalOpen, filterSelection } = this.state;
    const isEditing = !!editModel;

    let licenseTypeSelected;
    let groupSelectedText;

    if (editModel !== null) {
      licenseTypeSelected = licenseTypes.find(x => x.id === editModel.licenseType);
      groupSelectedText = editModel.machineLearningModelGroups !== [] ? editModel.machineLearningModelGroups.map(x => x.group).map(g => g.name).join(', ') : '';
    }
    return modelVersion && (
      <Fragment>
        <Formik
          isInitialValid={isEditing}
          initialValues={{
            name: isEditing ? editModel.displayName : '',
            key: isEditing ? editModel.machineLearningKey : '',
            taxContext: isEditing ? editModel.taxContext.id : 0,
            description: isEditing ? editModel.description : '',
            categories: isEditing ? editModel.categories : '',
            licenseType: isEditing ? editModel.licenseType : 0,
          }}
          validationSchema={createModelSchema}
          onSubmit={values => (isEditing ? this.onSubmitEdit(values) : this.onSubmitAdd(values))}
          render={({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isValid,
            handleSubmit,
          }) => {
            return (
              <div className={classes.root}>
                <div className={classes.title}>
                  {isEditing ?
                    strings.editModelText(editModel.displayName, modelVersion.name) :
                    strings.addModelText(modelVersion.name)
                  }
                </div>
                <Card className={classes.card}>
                  <Grid container>
                    <Grid item xs={4} className={classes.padding}>
                      <div className={classes.label}>{strings.nameLabel}</div>
                      <SidebarInput
                        darkColor
                        placeholder={strings.enterModelName}
                        inputClassName={classes.input}
                        id="name"
                        value={values.name}
                        error={touched.name ? errors.name : ''}
                        onChange={(value) => {
                          setFieldValue('name', value);
                          setFieldTouched('name', true);
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.padding}>
                      <div className={classes.label}>{strings.keyLabel}</div>
                      {!isEditing &&
                        <SidebarInput
                          darkColor
                          placeholder={strings.enterMlKey}
                          inputClassName={classes.input}
                          id="key"
                          value={values.key}
                          error={touched.key ? errors.key : ''}
                          onChange={(value) => {
                            setFieldValue('key', value);
                            setFieldTouched('key', true);
                          }}
                        />
                      }
                      {isEditing &&
                        <DisabledSelect
                          text={values.key}
                          label="Context"
                          selectClassName={classes.disabledSelect}
                        />
                      }
                    </Grid>
                  </Grid>
                </Card>
                <Card className={classes.card}>
                  <Grid container>
                    <Grid item xs={4} className={classes.padding}>
                      <div className={classes.label}>{strings.contextLabel}</div>
                      {!isEditing &&
                        <Select
                          placeholder={strings.selectContext}
                          colorScheme={selectConstants.colorScheme.lightBlue}
                          id="taxContext"
                          value={values.taxContext}
                          error={touched.taxContext ? errors.taxContext : ''}
                          onChange={(value) => {
                            setFieldValue('taxContext', value);
                            setFieldTouched('taxContext', true);
                            const { dispatch } = this.props;
                            if (value !== 0 && values.licenseType !== (0 || 1)) {
                            dispatch(getAdminTreePickerData(false, value, '', values.licenseType));
                            this.handleFilterSelection([]);
                            }
                          }}
                          data={taxContexts}
                        />
                      }
                      {isEditing &&
                        <DisabledSelect
                          text={editModel.taxContext.name}
                          label="Context"
                          selectClassName={classes.disabledSelect}
                        />
                      }
                    </Grid>
                    <Grid item xs={4} className={classes.padding}>
                      <div className={classes.label}>{strings.licenseTypeLabel}</div>
                      {!isEditing &&
                        <Select
                          placeholder={strings.selectLicenseType}
                          colorScheme={selectConstants.colorScheme.lightBlue}
                          id="licenseType"
                          value={values.licenseType}
                          onChange={(value) => {
                            setFieldValue('licenseType', value);
                            const { dispatch } = this.props;
                            if (values.taxContext !== 0 && value !== (0 || 1)) {
                            dispatch(getAdminTreePickerData(false, values.taxContext, '', value));
                            this.handleFilterSelection([]);
                            }
                          }}
                          data={licenseTypes}
                        />
                      }
                      {isEditing &&
                        <DisabledSelect
                          text={licenseTypeSelected === undefined ? '' : licenseTypeSelected.name}
                          label="LicenseType"
                          selectClassName={classes.disabledSelect}
                        />
                      }
                    </Grid>
                    <Grid item xs={4} className={classes.padding}>
                      <div className={classes.label}>{strings.groupLabel}</div>
                      {!isEditing &&
                        <CheckboxSelect
                          placeholder={strings.selectGroup}
                          colorScheme={checkboxSelectConstants.colorScheme.lightBlue}
                          id="group"
                          multiple
                          value={filterSelection}
                          onChange={this.handleFilterSelection}
                          data={adminTreePicker.data}
                        />
                      }
                      {isEditing &&
                        <DisabledSelect
                          text={groupSelectedText}
                          label="Group"
                          selectClassName={classes.disabledSelect}
                        />
                      }
                    </Grid>
                  </Grid>
                </Card>
                <Card className={classes.card}>
                  <div className={classes.label}>{strings.descriptionLabel}</div>
                  <TextField
                    value={values.description}
                    fullWidth
                    placeholder={strings.enterDescription}
                    margin="dense"
                    InputProps={{
                      classes: {},
                      multiline: true,
                      rows: 2,
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      setFieldValue('description', e.target.value);
                      setFieldTouched('description', true);
                    }}
                  />
                  {touched.description && errors.description &&
                    <ErrorLabel
                      error={errors.description}
                      verticalPadding
                      textColor={constants.textColor.pink}
                    />
                  }
                </Card>
                <Card className={classes.card}>
                  <div className={classes.label}>{strings.categoriesLabel}</div>
                  <TextField
                    disabled={isEditing}
                    value={values.categories}
                    fullWidth
                    placeholder={strings.enterCategories}
                    margin="dense"
                    InputProps={{
                      classes: {
                      },
                      multiline: true,
                      rows: 2,
                    }}
                    variant="outlined"
                    onChange={(e) => {
                      setFieldValue('categories', e.target.value);
                      setFieldTouched('categories', true);
                    }}
                  />
                  {touched.categories && errors.categories &&
                    <ErrorLabel
                      error={errors.categories}
                      verticalPadding
                      textColor={constants.textColor.pink}
                    />
                  }
                </Card>
                <div className={classes.flexContainer}>
                  <Button
                    onClick={this.onCancel}
                    className={classes.button}
                    height={buttonConstants.height.regular}
                    backgroundColor={buttonConstants.backgroundColor.dark}
                  >
                    {commonStrings.cancel}
                  </Button>
                  <Button
                    disabled={!isValid}
                    onClick={handleSubmit}
                    className={isValid ? classes.button : classes.buttonDisabled}
                    height={buttonConstants.height.regular}
                  >
                    {isEditing ? commonStrings.saveChanges : strings.createModel}
                  </Button>
                  {isEditing &&
                    <React.Fragment>
                      <div style={{ flexGrow: 1 }} />
                      <Button
                        onClick={this.onOpenDelete}
                        className={modelUsed ? classes.buttonDisabled : classes.button}
                        height={buttonConstants.height.regular}
                        backgroundColor={buttonConstants.backgroundColor.dark}
                        disabled={modelUsed}
                      >
                        {commonStrings.delete}
                      </Button>
                    </React.Fragment>
                  }
                </div>
              </div>
            );
          }}
        />
        <DeleteModelModal
          onConfirm={this.onDeleteModelConfirm}
          onCancel={this.onCloseDelete}
          isModalOpen={isDeleteModalOpen}
        />
      </Fragment>
    );
  }
}

CreateModel.defaultProps = {
  modelVersion: null,
  editModel: null,
};

CreateModel.propTypes = {
  modelVersion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
  }),
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  modelUsed: PropTypes.bool.isRequired,
  editModel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    displayName: PropTypes.string.isRequired,
    categories: PropTypes.string,
    description: PropTypes.string,
  }),
  adminTreePicker: PropTypes.shape({
    data: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    editModel: machineLearningSelector(state).editModel,
    modelVersion: machineLearningSelector(state).modelVersion,
    modelUsed: machineLearningSelector(state).modelUsed,
    adminTreePicker: adminTreePickerDataSelector(state),
  };
};

export default compose(connect(mapStateToProps), withStyles(styles))(CreateModel);
