import { GET_METHOD_OF_CATEGORISATION, GET_METHOD_OF_CATEGORISATION_SUCCESS } from 'Store/Areas/Review/MethodOfCategorisationActions';

const initialState = {
  loading: true,
  data: {},
};

export default function methodOfCategorisationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_METHOD_OF_CATEGORISATION:
      return Object.assign({}, state, {
        loading: true,
        data: {},
      });
    case GET_METHOD_OF_CATEGORISATION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.response,
      });
    default:
      return state;
  }
}
