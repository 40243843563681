import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withTheme, withStyles, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { selectTag } from 'Store/Areas/Categorisation/ReviewCategoryActions';
import { TagTypes } from 'Constants/Categorization/Tags';
import styles from './TagPicker.styles';

class TagPicker extends React.PureComponent {
  handleChange(event) {
    this.props.dispatch(selectTag(parseInt(event.target.value, 10)));
  }

  render() {
    const { classes, value, tags } = this.props;
    const tagsList = [{ name: 'All', id: -1 }].concat(tags);
    return (
      <RadioGroup
        className={classes.root}
        value={value.toString()}
        onChange={event => this.handleChange(event)}
      >
        <For each="tag" of={tagsList}>
          <FormControlLabel
            className={(tag.id === value) ? `${classes.label} ${classes.checkedLabel}` : classes.label}
            value={tag.id.toString()}
            control={
              <Radio
                className={classes.radio}
                disableRipple
              />
            }
            label={tag.name}
            key={tag.id}
          />
        </For>
      </RadioGroup>
    );
  }
}

TagPicker.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  value: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    value: state.categorisation.reviewCategory.tagId,
    tags: state.periods.period.data.tags.filter(x =>
      x.type === TagTypes.Text || x.type === TagTypes.TextWholeValue),
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTheme(),
)(TagPicker);
