import React from 'react';

const defaultOptions = {
  delay: 500,
  style: {},
};

const withSize = (options = defaultOptions) => (Component) => {
  options = { ...defaultOptions, ...options }; // eslint-disable-line
  return class WithSize extends React.Component {
    state = {
      width: 0,
      height: 0,
    };

    componentRef = React.createRef(); // eslint-disable-line react/sort-comp

    componentDidMount() {
      this.setWidth();
      window.addEventListener('resize', this.onResize);
    }

    componentDidUpdate() {
      this.setWidth();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize);
    }

    timeout = null; // eslint-disable-line react/sort-comp
    onResize = () => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.setWidth();
      }, options.delay);
    }

    setWidth = () => {
      if (this.componentRef.current) {
        const { width, height } = (this.componentRef.current.getBoundingClientRect());
        if (width !== this.state.width || height !== this.state.height) {
          this.setState({
            width,
            height,
          });
        }
      }
    }

    render() {
      const {
        width,
        height,
      } = this.state;
      return (
        <div ref={this.componentRef} style={{ ...options.style, width: '100%', height: '100%' }}>
          <Component
            width={width}
            height={height}
            {...this.props}
          />
        </div>
      );
    }
  };
};

export default withSize;
