export const styles = theme => ({
  button: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    padding: 0,
    margin: 0,
    borderRadius: 0,
    height: 35,
    minWidth: '100%',
    '&[disabled]': {
      color: theme.palette.primary.contrastText,
      opacity: 0.6,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  acceptButton: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.colors.mainLightened,
    },
  },
});