import { OPEN_DELETE_RULE, CLOSE_DELETE_RULE, REQUEST_DELETE_RULE, REQUEST_DELETE_RULE_SUCCESS } from 'Store/Areas/Rule/DeleteRuleActions';
import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';

const initialState = {
  loading: false,
  open: false,
};

export default function deleteRuleReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_DELETE_RULE:
      return Object.assign({}, state, {
        open: action.open,
      });
    case REQUEST_DELETE_RULE:
      return Object.assign({}, state, {
        loading: action.loading,
      });
    case RESET_PERIOD:
    case CLOSE_DELETE_RULE:
    case REQUEST_DELETE_RULE_SUCCESS:
      return initialState;
    default:
      return state;
  }
}

