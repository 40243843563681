import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme, Card } from '@material-ui/core';
import FileUploader from 'Components/Shared/FileUploader/FileUploader';
import UploadDropzoneContent from 'Components/Upload/Shared/UploadDropzoneContent';
import withCorruptedFileProvider from 'Components/Shared/CorruptedFileProvider/WithCorruptedFileProvider';
import { strings } from 'Constants/BulkUpload/Strings';
import { strings as adminStrings } from 'Constants/Setup/Admin/Strings';
import { strings as uploadStrings } from 'Constants/Upload/Strings';
import Select, { constants as constantsSelect } from 'Components/Shared/Selects/Select';
import { xlsFileTypes } from 'Constants/FileTypes';
import { routes } from 'Constants/Routes';
import { bulkUploadDocumentTypes } from 'Constants/BulkUpload/BulkUploadDocumentTypes';
import { bulkUserUpload } from 'Store/Areas/BulkUpload/BulkUserUploadActions';
import { bulkGroupEntityProjectUpload } from 'Store/Areas/BulkUpload/BulkGroupEntityProjectUploadActions';
import { toggleCorruptedFileDetectedModal } from 'Store/Areas/FileUpload/FileUploadActions';
import { push } from 'connected-react-router';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import styles from './BulkUpload.styles';

class BulkUpload extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bulkUploadType: 1,
    };
  }
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getTreePickerData());
  }

  onFileSelected = (file) => {
    const { dispatch } = this.props;
    const { bulkUploadType } = this.state;
    if (bulkUploadType === 1) {
      dispatch(bulkUserUpload(file));
      dispatch(push(routes.bulkUserUploadProcessing));
    } else {
      dispatch(bulkGroupEntityProjectUpload(file));
      dispatch(push(routes.bulkGroupEntityProjectUploadProcessing));
    }
  }

  onFileRejected = () => {
    this.props.dispatch(toggleCorruptedFileDetectedModal({
      showModal: true,
      fileTypes: xlsFileTypes,
    }));
  }

  setBulkUploadType = (value) => {
    if (this.state.bulkUploadType !== value) {
      this.setState({
        bulkUploadType: value,
      });
    }
  };

  render() {
    const {
      classes,
    } = this.props;
    const { bulkUploadType } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>{strings.title}</span>
          <span className={classes.description}>
            {adminStrings.youAreIn}<b>{adminStrings.adminMode}</b>
          </span>
        </div>
        <Card className={classes.card}>
          <div className={classes.message}>
            <b>{strings.subtitle}</b>
          </div>
          <div className={classes.message}>
            {strings.bulkUploadNote}
          </div>
          <div className={classes.message}>
            {strings.bulkUploadSubNote}
          </div>
          <div className={classes.bulkUploadTypeContainer}>
            <Select
              id={strings.bulkUploadTypeSelector}
              label={strings.bulkUploadTypeSelector}
              preventPlaceholder
              data={bulkUploadDocumentTypes}
              onChange={this.setBulkUploadType}
              value={bulkUploadType}
              colorScheme={constantsSelect.colorScheme.lightBlue}
            />
          </div>
          <FileUploader
            onFileSelected={files => this.onFileSelected(files[0])}
            onDropRejected={this.onFileRejected}
            fileTypes={xlsFileTypes}
          >
            <UploadDropzoneContent
              description={uploadStrings.dragAndDropDescriptionTemplate}
              buttonDescription={uploadStrings.templateUploadButtonDescription}
            />
          </FileUploader>
        </Card>
      </div >
    );
  }
}

BulkUpload.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default compose(
  withCorruptedFileProvider(),
  withStyles(styles),
  withTheme(),
)(BulkUpload);
