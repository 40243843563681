const styles = {
  sticky_table: {
    display: 'block',
    width: 'auto',
  },
  sticky_tableHead: {
    display: 'block',
    overflow: 'hidden',
  },
  sticky_tableBody: {
    display: 'block',
    overflow: 'auto',
  },
};

export default styles;
