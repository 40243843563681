import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  withStyles,
  withTheme,
  Radio,
} from '@material-ui/core';
import {
  columns,
  COL_FILENAME,
  COL_MAIN,
  COL_SUPPORTING,
  COL_DELETE,
  COL_UPLOAD_DATE,
} from 'Constants/Upload/FileListColumns';
import Check from 'Assets/Images/small-check-ic.svg';
import {
  requestDeleteFile,
  mapFileToSchema,
  requestDeleteSupportingDoc,
} from 'Store/Areas/Import/UploadActions';
import {
  supporting,
  main,
  newSupportingDocAfterPeriodCreation,
  unmapped,
} from 'Constants/FileTypes';
import { getLocalDateString, dateFormat } from 'Helpers/DateHelpers';
import DeleteButton from './DeleteButton';
import styles from './FileList.styles';

class FileList extends React.PureComponent {
  onDeleteButtonClick = (fileId, name, amount) => {
    const { dispatch, changeSupportingDocsAfterPeriodCreation } = this.props;
    if (changeSupportingDocsAfterPeriodCreation && amount !== 0) {
      dispatch(requestDeleteSupportingDoc(fileId, name));
    } else {
      dispatch(requestDeleteFile(fileId, name));
    }
  }
  mapFileToSchema = (fileId, fileType, fileName) => {
    const { period, dispatch } = this.props;
    dispatch(mapFileToSchema(fileId, fileType, fileName, period.id));
  }

  renderGridHeaderCell(colIndex, customClass) {
    const { classes } = this.props;
    const { text } = columns[colIndex];

    return (
      <Grid
        item
        sm={columns[colIndex].sm}
        lg={columns[colIndex].lg}
        className={`${classes.headerCell} ${classes.gridCell} ${customClass}`}
        tabIndex="-1"
      >
        {text}
      </Grid>
    );
  }

  renderGridCell(text, colIndex, key, customClass) {
    const { classes } = this.props;

    return (
      <Grid item key={`${key}${colIndex}`} className={`${classes.rowCell} ${classes.gridCell} ${customClass}`} sm={columns[colIndex].sm} lg={columns[colIndex].lg}>{text}</Grid>
    );
  }
  render() {
    const {
      classes, period, changeSupportingDocsAfterPeriodCreation,
    } = this.props;
    return (
      <div className={classes.root}>
        <Grid container direction="row" justify="flex-start">
          {this.renderGridHeaderCell(COL_FILENAME)}
          {this.renderGridHeaderCell(COL_MAIN, classes.alignCenter)}
          {this.renderGridHeaderCell(COL_SUPPORTING, classes.alignCenter)}
          {this.renderGridHeaderCell(COL_UPLOAD_DATE)}
          {this.renderGridHeaderCell(COL_DELETE, classes.alignCenter)}
          <For each="x" of={period.files}>
            {this.renderGridCell(x.name, COL_FILENAME, x.id, classes.fileNameCell)}
            {this.renderGridCell(<Radio onClick={() => this.mapFileToSchema(x.id, main, x.name)} checked={x.fileType === main} checkedIcon={<img src={Check} alt="" />} />, COL_MAIN, x.id, classes.alignCenter)}
            {this.renderGridCell(<Radio onClick={() => this.mapFileToSchema(x.id, supporting, x.name)} checked={x.fileType === supporting || x.fileType === newSupportingDocAfterPeriodCreation} checkedIcon={<img src={Check} alt="" />} />, COL_SUPPORTING, x.id, classes.alignCenter)}
            {this.renderGridCell(`uploaded ${getLocalDateString(x.uploadDate, dateFormat)}`, COL_UPLOAD_DATE, x.id, classes.dateColumn)}
            <If condition={!changeSupportingDocsAfterPeriodCreation}>
              {this.renderGridCell(
                <DeleteButton
                  cloudReference={x.cloudReference}
                  onClick={fileId => this.onDeleteButtonClick(fileId, x.name, x.amountValue)}
                />,
                COL_DELETE,
                x.id,
                classes.closeCell,
              )}
            </If>
            <If condition={changeSupportingDocsAfterPeriodCreation &&
              (x.fileType === supporting ||
                x.fileType === newSupportingDocAfterPeriodCreation ||
                x.fileType === unmapped
              )}
            >
              {this.renderGridCell(
                <DeleteButton
                  cloudReference={x.cloudReference}
                  onClick={fileId => this.onDeleteButtonClick(fileId, x.name, x.amountValue)}
                />,
                COL_DELETE,
                x.id,
                classes.closeCell,
              )}
            </If>
          </For>
        </Grid>
      </div>
    );
  }
}

FileList.defaultProps = {
  changeSupportingDocsAfterPeriodCreation: false,
};

FileList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  period: PropTypes.shape({
    id: PropTypes.number.isRequired,
    files: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.int,
      cloudReference: PropTypes.string,
      fileType: PropTypes.string,
    })).isRequired,
  }).isRequired,
  changeSupportingDocsAfterPeriodCreation: PropTypes.bool,
};

export default compose(withStyles(styles), withTheme(), connect())(FileList);
