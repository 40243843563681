const styles = () => ({
  dropzone: {
    width: '100%',
    height: '100%',
    border: 'dashed 2px #666666',
    borderRadius: 5,
    textAlign: 'center',
    opacity: 0.8,
  },
});

export default styles;
