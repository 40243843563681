import { takeLatest, put, call } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { PERIOD_GET_KEYWORDS } from 'Store/Api/ApiEndpoints';
import { Post } from 'Store/Api/CallApi';
import { delay } from 'redux-saga';
import {
  GET_KEYWORDS,
  UPDATE_KEYWORD_FILTERS,
  getKeywordsSuccess,
  getKeywords,
} from 'Store/Areas/Categorisation/KeywordActions';
import { checkApportionmentCalculation } from 'Store/Areas/Apportionables/ApportionablesActions';
import { periodStatuses } from 'Constants/PeriodStatuses';

/* Worker Sagas */
function* getKeywordsSaga(action) {
  const response = yield Post(PERIOD_GET_KEYWORDS, action.payload);
  if (response.ok) {
    const data = yield response.json();
    if (data.keywords.length === 0 &&
      (data.periodStatus === periodStatuses.readyForUserCategorisation)) {
      yield put(checkApportionmentCalculation(action.payload.periodId));
    }
    yield call(delay, 1000);
    yield put(getKeywordsSuccess(data, action.hasSearched, action.isLoadMoreKeywords));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* queryTermsUpdated(action) {
  yield put(getKeywords(
    action.payload.periodId,
    action.payload.currentPageNumber,
    action.sortOrder,
    action.tagFilter,
    action.payload.searchTerm,
    !!action.payload.searchTerm,
  ));
}

/* Watcher Sagas */
export function* keywordSagas() {
  yield takeLatest(GET_KEYWORDS, getKeywordsSaga);
  yield takeLatest(UPDATE_KEYWORD_FILTERS, queryTermsUpdated);
}
