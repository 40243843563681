import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import StyledModal from 'Components/Shared/Modal/StyledModal';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import Slider from 'Components/Shared/Slider/Slider';
import { strings } from 'Constants/Categorization/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { connect } from 'react-redux';
import { periodCategoriesSelector, periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import { categoriseItems, resetCategorisationCreation } from 'Store/Areas/Rule/CategorisationActions';
import { apportionmentOpenSelector, categorisationRulePropertiesSelector, categorisationTypeSelector, inspectedRuleSelector } from 'Store/Areas/Rule/CategorisationSelectors';
import styles from './SplitCategorization.styles';

class SplitCategorization extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      error: '',
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.categories === state.categories) return null;
    return {
      categories:
        SplitCategorization.getCategoriesWithPercentage(
          props.categories,
          props.inspectedRule,
        ) || [],
    };
  }

  static getCategoriesWithPercentage(categories, inspectedRule) {
    if (inspectedRule !== null) {
      categories.forEach((category) => {
        const categoryApportionment = inspectedRule.categoriesApportionmentForRule
          .filter(x => x.categoryName === category.name)[0];
        category.percentage = ( // eslint-disable-line no-param-reassign
          categoryApportionment === undefined
            ? 0 : categoryApportionment.apportionmentPercentage
        );
      });
    } else {
      categories.forEach((category) => {
        category.percentage = 0; // eslint-disable-line no-param-reassign
      });
    }
    return categories;
  }

  getRemainingPercentage() {
    const p = 100 - (this.props.categories.reduce((sum, c) => sum + (c.percentage || 0), 0) * 100);
    return Math.round(p * 100) / 100;
  }

  handleAccept() {
    const remainingPercentage = this.getRemainingPercentage();

    if (remainingPercentage) {
      this.setState({
        error: strings.splitKeywordsToCategories3(remainingPercentage),
      });

      return;
    }
    this.handleSubmit();
  }

  handleSubmit() {
    const {
      dispatch,
      ruleProperties,
      periodId,
      categoryType,
      inspectedRule,
    } = this.props;
    ruleProperties.ruleId = inspectedRule !== null ? inspectedRule.id : 0;
    dispatch(categoriseItems(
      periodId,
      this.state.categories.filter(x => x.percentage),
      categoryType,
      ruleProperties,
    ));
  }

  handleCancel() {
    const { dispatch } = this.props;
    dispatch(resetCategorisationCreation());
  }

  renderSlider(category, index) {
    const { categories } = this.state;
    const { classes } = this.props;
    const remainingPercentage = this.getRemainingPercentage();

    const normalizedValue = Math.round(category.percentage * 10000) / 100;

    const categoryList = categories.filter(f => f.name.toLowerCase() !==
      strings.apportionable.toLowerCase() && f.color !== strings.apportionableColor);

    return (<Slider
      className={classes.slider}
      key={category.name}
      label={(
        <span className={classes.sliderLabel}>
          <span className={classes.sliderColor} style={{ backgroundColor: category.color }} />
          <span>{category.name}</span>
        </span>
      )}
      value={normalizedValue}
      endInputAdornment="%"
      min={0}
      max={normalizedValue + remainingPercentage}
      size={100}
      step={0.01}
      inputStep="0.5"
      onValueChange={(v) => {
        categoryList[index].percentage = Math.round(v * 100) / 10000;
        this.forceUpdate();
      }}
    />);
  }

  render() {
    const { categories, error } = this.state;
    const { classes, open } = this.props;

    if (!open) {
      return null;
    }

    return (
      <StyledModal open={open}>
        <div className={classes.root}>
          <div className={classes.header}>
            <div className={classes.note}>
              <b>{strings.splitKeywordsToCategories1}</b>
              <br />
              {strings.splitKeywordsToCategories2}
            </div>
            <If condition={error}>
              <div className={`${classes.note} ${classes.errorNote}`}>{error}</div>
            </If>
          </div>
          <div className={classes.sliders}>
            {
              categories.filter(f => f.name.toLowerCase() !== strings.apportionable.toLowerCase()
                && f.color !== strings.apportionableColor).map((x, i) => this.renderSlider(x, i))
            }
          </div>
          <Buttons
            className={classes.buttons}
            isSubmitting={false}
            onAccept={() => this.handleAccept()}
            onCancel={() => this.handleCancel()}
            acceptText={strings.applyApportionment}
            cancelText={commonStrings.cancel}
          />
        </div>
      </StyledModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: periodCategoriesSelector(state),
    periodId: periodIdSelector(state),
    open: apportionmentOpenSelector(state),
    ruleProperties: categorisationRulePropertiesSelector(state),
    categoryType: categorisationTypeSelector(state),
    inspectedRule: inspectedRuleSelector(state),
  };
};

SplitCategorization.defaultProps = {
  inspectedRule: {},
};

SplitCategorization.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    categoryId: PropTypes.number,
    name: PropTypes.string,
    percentage: PropTypes.number,
    color: PropTypes.string,
  })).isRequired,
  periodId: PropTypes.number.isRequired,
  ruleProperties: PropTypes.object.isRequired, // eslint-disable-line
  dispatch: PropTypes.func.isRequired,
  categoryType: PropTypes.string.isRequired,
  inspectedRule: PropTypes.shape({
    id: PropTypes.number,
    categoriesApportionmentForRule: PropTypes.arrayOf(PropTypes.shape({
      categoryName: PropTypes.string,
      apportionmentPercentage: PropTypes.number,
    })),
  }),
};

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(SplitCategorization);
