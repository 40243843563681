import {
  GET_REVIEW_CONTRAS,
  GET_REVIEW_CONTRAS_SUCCESS,
  UPDATE_REVIEW_CONTRAS,
  UPDATE_REVIEW_CONTRAS_SUCCESS,
  UPDATE_SEARCH_TERM,
  POST_REMOVE_CONTRA_SUCCESS,
  POST_REMOVE_CONTRA,
  RESET_REVIEW_CONTRAS,
  POST_REMOVE_CONTRA_RERUN_CATEGORISATION,
} from 'Store/Areas/Import/ContraActions';
import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';
import { commonStrings } from 'Constants/CommonStrings';
import { SELECT_PERIOD } from 'Store/Areas/Projects/UserTreePickerActions';
import { sortOrder } from 'Constants/Contras/QueryTermFilters';

const initialState = {
  loading: false,
  scrollId: '',
  contraGroups: [],
  incompleteGroups: [],
  columns: [],
  columnHeaders: [],
  hasMore: true,
  amountColumnIndex: -1,
  descriptionColumnIndex: -1,
  submitting: false,
  totalContras: 0,
  totalLinesWithinContras: 0,
  filters: {
    sortOrder: sortOrder.valueDescending,
    tagFilter: 0,
    searchTerm: '',
  },
};

export default function contrasReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REVIEW_CONTRAS:
      return Object.assign({}, state, {
        loading: action.loading,
      });
    case POST_REMOVE_CONTRA:
    case POST_REMOVE_CONTRA_RERUN_CATEGORISATION:
      return Object.assign({}, state, {
        submitting: action.submitting,
      });
    case GET_REVIEW_CONTRAS_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        scrollId: action.scrollId,
        contraGroups: state.contraGroups.concat(action.completeGroups),
        incompleteGroups: action.incompleteGroups,
        columns: action.columns,
        columnHeaders: action.columnHeaders,
        hasMore: action.hasMore,
        amountColumnIndex: action.columns.indexOf(commonStrings.amount),
        descriptionColumnIndex: action.columns.indexOf(commonStrings.description),
        totalContras: action.totalContras,
        totalLinesWithinContras: action.totalLinesWithinContras,
      });
    case UPDATE_REVIEW_CONTRAS:
      return Object.assign({}, state, {
        loading: action.loading,
        scrollId: action.scrollId,
        filters: {
          sortOrder: action.sortOrder,
          tagFilter: action.tagFilter,
          searchTerm: state.filters.searchTerm,
        },
      });
    case UPDATE_REVIEW_CONTRAS_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        scrollId: action.scrollId,
        contraGroups: action.completeGroups,
        incompleteGroups: action.incompleteGroups,
        columns: action.columns,
        columnHeaders: action.columnHeaders,
        hasMore: action.hasMore,
        amountColumnIndex: action.columns.indexOf(commonStrings.amount),
        descriptionColumnIndex: action.columns.indexOf(commonStrings.description),
      });
    case UPDATE_SEARCH_TERM:
      return Object.assign({}, state, {
        filters: {
          sortOrder: state.filters.sortOrder,
          searchTerm: action.searchTerm,
        },
      });
    case POST_REMOVE_CONTRA_SUCCESS:
      return Object.assign({}, state, {
        submitting: action.submitting,
        contraGroups: state.contraGroups.filter(x => x.contraId !== action.contraId),
      });
    case RESET_REVIEW_CONTRAS:
    case SELECT_PERIOD:
    case RESET_PERIOD:
      return initialState;
    default:
      return state;
  }
}
