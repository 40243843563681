import { takeLatest, put, select } from 'redux-saga/effects';
import { Get, Post } from 'Store/Api/CallApi';
import { push } from 'connected-react-router';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import {
  PERIODS_GET_PERIOD,
  PERIOD_UPDATE_STATUS,
  PERIOD_GET_PERIOD_LOCKED_BY_USERID,
  PERIOD_GET_PERIOD_LOCKED_BY_USER,
  PERIOD_SET_IN_USE,
  PERIOD_RESET_IN_USE,
} from 'Store/Api/ApiEndpoints';
import { setExportLocked } from 'Store/Areas/Export/ExportActions';
import { setImportFailedError } from 'Store/Areas/App/ErrorPageActions';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';
import { addSignalrSideEffect } from 'Store/Areas/App/SignalrSideEffectActions';
import { startPolling, stopPolling } from 'Store/Areas/App/PeriodStatusPollingActions';
import { toggleSiteLoader } from 'Store/Areas/App/SiteLoaderActions';
import * as PeriodActions from 'Store/Areas/Period/PeriodActions';
import { userTreePickerSelector } from 'Store/Areas/Projects/UserTreePickerSelectors';
import { resetConflict, getNextConflict } from 'Store/Areas/Conflict/ConflictActions';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { commonStrings } from 'Constants/CommonStrings';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { routes } from 'Constants/Routes';
import { strings as errorStrings } from 'Constants/Error/Strings';
import { periodControlStatuses } from 'Constants/PeriodControlStatuses';

/* Worker Sagas */
function* getPeriod(action) {
  const response = yield Get(PERIODS_GET_PERIOD(action.payload.periodId));

  if (response.ok) {
    const data = yield response.json();

    const { previousPeriodId, previousPeriodStatus } = yield select(state => ({
      previousPeriodId: state.periods.period.periodId,
      previousPeriodStatus: state.periods.period.data.status,
    }));

    yield put(PeriodActions.getPeriodLockedByUserId(action.payload.periodId));
    yield put(PeriodActions.getPeriodLockedByUser(action.payload.periodId));
    yield put(PeriodActions.getPeriodSuccess(
      action.payload.periodId,
      data,
      previousPeriodId,
      previousPeriodStatus,
      action.maintainRoute,
    ));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* handlePeriodArchiving(action) {
  yield put(toggleSiteLoader(commonStrings.siteLoader.archiving));

  const { selectedGroupId, selectedEntityId, selectedProjectId } =
    yield select(userTreePickerSelector);

  const sideEffectPayload = {
    groupId: selectedGroupId,
    entityId: selectedEntityId,
    projectId: selectedProjectId,
    periodId: action.response.id,
    periodName: action.response.name,
  };

  yield put(addSignalrSideEffect(
    action.response.id,
    periodStatuses.periodArchived,
    sideEffectPayload,
  ));
}

function* handlePeriodConflictState({ id: periodId, hasConflicts }) {
  // Check if conflict action was performed.
  const isConflictAction = yield select(x => x.periods.periodConflicts.isConflictAction);

  let route = null;

  if (hasConflicts) {
    route = routes.conflicts;
    if (isConflictAction) {
      yield put(getNextConflict(periodId));
    }
  } else {
    // Period no longer has conflicts. Reset conflicts data in reducer.
    yield put(resetConflict());
  }
  return route;
}

function* handlePeriodSelection(action) {
  let route = null;
  let shouldPollPeriod = false;
  let lockExport = null;
  let isProcessing = null;

  switch (action.response.status) {
    case periodStatuses.notStarted:
      route = routes.import.upload;
      break;
    case periodStatuses.mapping:
      route = routes.import.mappingMain;
      break;
    case periodStatuses.importSummary:
      route = routes.importSummary;
      break;
    case periodStatuses.supportingDocumentsSetup:
      route = routes.supportingDocuments;
      break;
    case periodStatuses.contrasSetup:
      route = routes.contrasSetup;
      shouldPollPeriod = true;
      break;
    case periodStatuses.contrasComplete:
      route = routes.contrasReview;
      break;
    case periodStatuses.importFailedRules:
      route = routes.failedRulesReview;
      break;
    case periodStatuses.initialCategorizationComplete:
      route = routes.machineLearningSetup;
      break;
    case periodStatuses.importQueued:
    case periodStatuses.importRunning:
    case periodStatuses.keywordExtractionQueued:
    case periodStatuses.keywordExtractionRunning:
    case periodStatuses.initialCategorizationRunning:
    case periodStatuses.machineLearningPredictionsQueued:
    case periodStatuses.machineLearningPredictionsRunning:
    case periodStatuses.applyMachineLearningRunning:
    case periodStatuses.applyMachineLearningQueued:
    case periodStatuses.supportingDocumentsQueued:
    case periodStatuses.supportingDocumentsRunning:
    case periodStatuses.contrasQueued:
    case periodStatuses.contrasRunning:
    case periodStatuses.externalReviewImportQueued:
    case periodStatuses.externalReviewImportRunning:
    case periodStatuses.supportingDocDeleteQueued:
    case periodStatuses.supportingDocDeleteRunning:
      shouldPollPeriod = true;
      route = routes.processing;
      break;
    case periodStatuses.apportionmentCalculationQueued:
    case periodStatuses.apportionmentCalculationRunning:
      shouldPollPeriod = true;
      route = routes.apportionableManager;
      break;
    case periodStatuses.machineLearningPredictionsComplete:
      route = routes.machineLearning;
      break;
    case periodStatuses.userCategorizationRunning:
    case periodStatuses.userCategorizationQueued:
      isProcessing = true;
      shouldPollPeriod = true;
      route = routes.categorisation.referenceLists;
      break;
    case periodStatuses.periodReferenceListImportQueued:
    case periodStatuses.periodReferenceListImportRunning:
      shouldPollPeriod = true;
      route = routes.apportionableManager;
      break;
    case periodStatuses.readyForUserCategorisation:
      yield put(getTreePickerData());
      isProcessing = false;
      route = (yield handlePeriodConflictState(action.response))
        || routes.categorisation.referenceLists;
      break;
    case periodStatuses.periodCategorisationComplete:
      isProcessing = false;
      route = yield handlePeriodConflictState(action.response);

      if (!route
        && action.previousPeriodId === action.periodId
        && action.previousPeriodStatus === periodStatuses.externalReviewImportFailed) {
        route = routes.externalReview;
      } else {
        route = routes.categorisation.referenceLists;
      }
      break;
    case periodStatuses.exportRunning:
    case periodStatuses.exportQueued:
      lockExport = true;
      shouldPollPeriod = true;
      route = routes.export;
      break;
    case periodStatuses.archiveRunning:
    case periodStatuses.archiveQueued:
      lockExport = true;
      yield handlePeriodArchiving(action);
      break;
    case periodStatuses.periodArchived:
      lockExport = true;
      route = routes.export;
      break;
    case periodStatuses.importFailed:
    case periodStatuses.categorizationFailed:
    case periodStatuses.keywordExtractionFailed:
    case periodStatuses.initialCategorizationFailed:
    case periodStatuses.machineLearningPredictionsFailed:
    case periodStatuses.userCategorizationFailed:
    case periodStatuses.contrasFailed:
    case periodStatuses.supportingDocumentsFailed:
    case periodStatuses.periodReferenceListImportFailed:
    case periodStatuses.supportingDocDeleteFailed:
      yield put(setImportFailedError());
      route = routes.importError;
      break;
    case periodStatuses.externalReviewImportFailed:
      if (!(yield select(state => state.app.errorPage.message))) {
        yield put(setImportFailedError(
          errorStrings.externalReviewImportFailed,
          errorStrings.externalReviewImportFailedRecommendation,
        ));
      }
      route = routes.externalReviewImportError;
      break;
    default:
      route = routes.error;
      break;
  }

  if (isProcessing !== null) {
    yield put(setProcessing(isProcessing));
  }

  if (lockExport !== null) {
    yield put(setExportLocked(lockExport));
  }

  if (shouldPollPeriod) {
    yield put(startPolling(action.periodId));
  } else {
    yield put(stopPolling());
  }

  const confirmSetInUse = !(action.response.inUse
    || action.response.isReadOnly
    || action.response.isLocked);

  if (confirmSetInUse && !action.response.isProjectReadOnlyUser) {
    yield put(PeriodActions.setPeriodInUse(
      action.periodId,
      periodControlStatuses.none,
      action.userId,
    ));
  }

  if (route !== null && (route === routes.conflicts || !action.maintainRoute)) {
    yield put(push(route));
  }
}

function* updatePeriodStatus(action) {
  const response = yield Post(PERIOD_UPDATE_STATUS, action.payload);
  if (response.ok) {
    yield put(PeriodActions.updatePeriodStatusSuccess(action.payload.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* setPeriodInUse(action) {
  const response = yield Post(PERIOD_SET_IN_USE, action.payload);
  if (response.ok) {
    yield put(PeriodActions.resetPeriodInUsePopup());
    yield put(PeriodActions.setPeriodInUseSuccess());
    yield put(PeriodActions.getPeriodLockedByUserId(action.payload.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* resetPeriodInUse(action) {
  const response = yield Post(PERIOD_RESET_IN_USE, action.payload);
  if (response.ok) {
    yield put(PeriodActions.resetPeriodInUseSuccess());
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getIsPeriodLocked(action) {
  const response = yield Get(PERIOD_GET_PERIOD_LOCKED_BY_USERID(action.periodId));

  if (response.ok) {
    const data = yield response.json();

    yield put(PeriodActions.getPeriodLockedByUserIdSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getPeriodLockedByUser(action) {
  const response = yield Get(PERIOD_GET_PERIOD_LOCKED_BY_USER(action.periodId));

  if (response.ok) {
    const data = yield response.json();

    yield put(PeriodActions.getPeriodLockedByUserSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* periodSagas() {
  yield takeLatest(PeriodActions.GET_PERIOD, getPeriod);
  yield takeLatest(PeriodActions.GET_PERIOD_SUCCESS, handlePeriodSelection);
  yield takeLatest(PeriodActions.UPDATE_PERIOD_STATUS_SUCCESS, getPeriod);
  yield takeLatest(PeriodActions.UPDATE_PERIOD_STATUS, updatePeriodStatus);
  yield takeLatest(PeriodActions.PERIOD_GET_PERIOD_LOCKED_BY_USERID, getIsPeriodLocked);
  yield takeLatest(PeriodActions.PERIOD_GET_PERIOD_LOCKED_BY_USER, getPeriodLockedByUser);
  yield takeLatest(PeriodActions.SET_PERIOD_IN_USE, setPeriodInUse);
  yield takeLatest(PeriodActions.RESET_PERIOD_IN_USE, resetPeriodInUse);
}
