export const commonStrings = {
  add: 'Add',
  yes: 'Yes',
  no: 'No',
  cancel: 'Cancel',
  ok: 'OK',
  search: 'Search',
  proceed: 'Proceed',
  saveChanges: 'Save changes',
  didYouKnow: 'Did you know?',
  delete: 'Delete',
  wasDeleted: 'was successfully deleted.',
  remove: 'Remove',
  smallAnd: 'and',
  change: 'Change',
  loading: 'Loading more data',
  noResults: 'There are no results matching your search criteria.',
  noLineItems: 'No line items match your search. Please try again.',
  noApportionableLineItems: 'No apportionable line items added yet. Please try again.',

  maxNumberOfLineItems: 'You have selected the maximum number of line items.',
  logOut: 'Log Out',
  momentDateDisplayFormat: 'DD/MM/YYYY',
  amount: 'Amount',
  description: 'Description',
  view: 'view',
  siteLoader: {
    archiving: 'Archiving',
  },
  tags: 'Tags',
  replaceAll: 'Replace All',
  created: 'created',
  updated: 'updated',
  invalidDescription: 'Model Description Field required.',
  invalidCategories: 'Categories Field required',
  help: 'Help',
  linkNotAvailable: 'Link not available. Please try again later.',
  subString: 'Substring',
  elasticDocVariables: ['categorizationtype',
    'categories',
    'matchedrules',
    'obj',
    'objformat',
    'isconflicted',
    'isflagged',
    'categorisedbypriority',
    'categorisedbyruleid',
    'machinelearningfield',
    'contrasearchfield',
    'contragroupcount',
    'contragrouphighestvalue',
    'filetype',
    'fileid',
    'isapportionable',
    'apportionmentdefinitionid',
    'apportionmentvalue',
    'isapportionment',
    'apportionmentpriority',
    'isreceivedapportionment',
    'isapportionmentcalculationcompleted',
    'id',
    'keywords',
    'contraid',
    'category'],
};
