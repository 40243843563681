import { SELECT_PERIOD } from 'Store/Areas/Projects/UserTreePickerActions';
import { GET_GRAPH_DATA, GET_GRAPH_DATA_SUCCESS } from './LineItemValueActions';

const initialState = {
  loading: false,
  data: [],
};

export default function distributionGraphReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GRAPH_DATA:
    case GET_GRAPH_DATA_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        data: action.data,
      });
    case SELECT_PERIOD:
      return initialState;
    default:
      return state;
  }
}
