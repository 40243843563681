export const rulePriorities = [
  {
    value: 0,
    label: 'Machine Learning',
  },
  {
    value: 5,
    label: 'Low Priority',
  },
  {
    value: 10,
    label: 'Keyword',
  },
  {
    value: 15,
    label: 'Keyword (high)',
  },
  {
    value: 20,
    label: 'Keyword combination',
  },
  {
    value: 25,
    label: 'Keyword combination (high)',
  },
  {
    value: 30,
    label: 'Custom rule',
  },
  {
    value: 35,
    label: 'Custom rule (high)',
  },
  {
    value: 40,
    label: 'High Priority',
  },
  {
    value: 45,
    label: 'Super High Priority',
  },
];
