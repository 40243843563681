import { formatCurrencyString } from 'Helpers/NumberHelpers';

export function summaryString(name, amount, currencySymbol, rows) {
  return `${name} summary: ${formatCurrencyString(amount, currencySymbol)} from ${rows} rows`;
}
export const strings = {
  noData: 'There are no rules for this category.',
  noSearchResultData: 'No results match your search.',
  categorisationReason: reason => `Reason: "${reason}"`,
  searchCategoryRules: 'Search Category Rules',
  searchPlaceholder: 'Start typing to search',
};
