export const styles = theme => ({
  input: {
    background: 'transparent',
    fontSize: theme.constants.fontSizes.small,
    width: 'Calc(100% - 10px)',
    height: 66,
    padding: '2px 5px',
    borderStyle: 'solid',
    borderColor: '#c8c8c8',
    borderWidth: 1,
    '& > div': {
      overflowY: 'auto',
      display: 'block',
      minHeight: 0,
      marginBottom: 0,
      '& input': {
        margin: 0,
        padding: 0,
      },
    },
  },
  customChip: {
    backgroundColor: '#E6EBF3',
  },
});
