import {
  REQUEST_UPLOAD_PERIOD_FILE,
  REQUEST_UPLOAD_CUSTOM_REPORT_FILE,
  REQUEST_UPLOAD_REFERENCE_LIST_FILE,
  REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE,
  REQUEST_UPLOAD_FILE_SUCCESS,
  TOGGLE_CORRUPTED_FILE_DETECTED_MODAL,
  TOGGLE_ANTIVIRUS_LOADER,
  RESET_UPLOAD_FILE,
} from 'Store/Areas/FileUpload/FileUploadActions';
import { SELECT_PERIOD } from 'Store/Areas/Projects/UserTreePickerActions';
import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';

const initialState = {
  uploadResponse: null,
  checkingForViruses: false,
  showCorruptedFileModal: false,
  fileContainsVirus: false,
};

export default function fileUploadReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_UPLOAD_PERIOD_FILE:
    case REQUEST_UPLOAD_CUSTOM_REPORT_FILE:
    case REQUEST_UPLOAD_REFERENCE_LIST_FILE:
    case REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE:
      return Object.assign({}, state, {
        checkingForViruses: action.checkingForViruses,
      });
    case REQUEST_UPLOAD_FILE_SUCCESS:
      return Object.assign({}, state, {
        uploadResponse: action.uploadResponse,
      });
    case TOGGLE_ANTIVIRUS_LOADER:
      return Object.assign({}, state, {
        checkingForViruses: action.checkingForViruses,
      });
    case TOGGLE_CORRUPTED_FILE_DETECTED_MODAL:
      return Object.assign({}, state, {
        uploadResponse: action.uploadResponse,
        showCorruptedFileModal: action.showCorruptedFileModal,
        fileContainsVirus: action.fileContainsVirus,
        fileTypes: action.fileTypes,
      });
    case SELECT_PERIOD:
    case RESET_PERIOD:
    case RESET_UPLOAD_FILE:
      return initialState;
    default:
      return state;
  }
}

