import { GET_LINE_ITEM_METADATA_SUCCESS, GET_LINE_ITEM_METADATA, RESET_LINE_ITEM_METADATA, SET_REQUIRES_SEARCH } from 'Store/Areas/Review/LineItemMetadataActions';
import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';

const initialState = {
  requiresSearch: false,
  mlMetadata: {
    loading: false,
    count: 0,
    amount: 0,
  },
  manualMetadata: {
    loading: false,
    count: 0,
    amount: 0,
  },
  conflictsMetadata: {
    loading: false,
    count: 0,
    amount: 0,
  },
  contraMetadata: {
    loading: false,
    count: 0,
    amount: 0,
  },
  ruleMetadata: {
    loading: false,
    count: 0,
    amount: 0,
  },
  allExceptContrasMetadata: {
    loading: false,
    count: 0,
    amount: 0,
  },
};

function getField(categorizationType) {
  switch (categorizationType) {
    case categorizationTypes.machineLearning:
      return 'mlMetadata';
    case categorizationTypes.manual:
      return 'manualMetadata';
    case categorizationTypes.allowedConflicts:
      return 'conflictsMetadata';
    case categorizationTypes.contra:
      return 'contraMetadata';
    case categorizationTypes.rule:
      return 'ruleMetadata';
    case categorizationTypes.allExceptContras:
      return 'allExceptContrasMetadata';
    default:
      throw new Error(`Unknown Categorization Type ${categorizationType}`);
  }
}

export default function lineItemMetadataReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LINE_ITEM_METADATA:
    case GET_LINE_ITEM_METADATA_SUCCESS:
      return Object.assign({}, state, {
        requiresSearch: false,
        [getField(action.categorizationType)]: {
          loading: action.loading,
          count: action.count,
          amount: action.amount,
        },
      });
    case SET_REQUIRES_SEARCH:
      return Object.assign({}, state, {
        requiresSearch: true,
      });
    case RESET_LINE_ITEM_METADATA:
      return initialState;
    default:
      return state;
  }
}
