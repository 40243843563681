import React from 'react';
import { Input, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styles from './DatePickerInput.styles';

class DatePickerInput extends React.PureComponent {
  render() {
    const {
      id,
      classes,
      value,
      onChange,
      onIconClicked,
      placeholder,
      iconComponent,
      disabled,
    } = this.props;

    return (
      <div className={classes.root}>
        <Input
          disabled={disabled}
          autoComplete="off"
          id={id}
          placeholder={placeholder}
          value={value}
          fullWidth
          disableUnderline
          onChange={event => onChange(event)}
          classes={{ input: `${classes.input} ${disabled && classes.disabledInput}` }}
          endAdornment={
            <div
              className={classes.icon}
              onClick={onIconClicked}
              onKeyPress={onIconClicked}
              role="button"
              tabIndex={0}
            >
              {iconComponent}
            </div>
          }
        />
      </div>
    );
  }
}

DatePickerInput.defaultProps = {
  id: '',
  iconComponent: '',
  onIconClicked: () => {},
  placeholder: '',
  value: '',
  disabled: false,
};

DatePickerInput.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  value: PropTypes.string,
  iconComponent: PropTypes.node,
  onIconClicked: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(DatePickerInput);
