export const exportLockedSelector = state => state.export.export.exportLocked;

export const exportFilesSelector = state => state.export.export.files;

export const externalReviewExportedFilesSelector = state => state.export.externalReview.files;

export const externalReviewExportedFileExpiredSelector = state =>
  state.export.externalReview.isExternalReviewExportedFileExpired;

export const exportLoadingSelector = state => state.export.export.loading;

export const exportCustomReportCloudRefSelector = state => state.export.export.customReportCloudRef;

export const exportCustomReportCloudNameSelector = state =>
  state.export.export.customReportOriginalFileName;

export const exportFileLoadingSelector = state =>
  state.export.export.fileLoading;

export const externalReviewFileLoadingSelector = state =>
  state.export.externalReview.fileLoading;

export const externalReviewFileSelector = state =>
  state.export.externalReview.externalReviewFilename;

export const externalReviewCloudRefSelector = state =>
  state.export.externalReview.externalReviewCloudRef;

export const externalReviewLoadingSelector = state =>
  state.export.externalReview.loading;

export const externalReviewUncategorisedLineItemCountSelector = state =>
  state.export.externalReview.uncategorisedLineItemCount;

export const externalReviewUncategorisedLineItemLoadingSelector = state =>
  state.export.externalReview.uncategorisedLineItemLoading;

export const externalReviewCanExportSelector = state =>
  state.export.externalReview.canExport;

export const exportPivotTableTagSelector = state =>
  state.export.exportPivotTable.tag;

export const exportPivotTableColumnSelector = state =>
  state.export.exportPivotTable.column;

export const exportPivotTableIsAddingTagSelector = state =>
  state.export.exportPivotTable.isAdding;

export const exportPivotTableFilterLoadingSelector = state =>
  state.export.exportPivotTable.filterLoading;

export const exportPivotTableFilterValuesSelector = state =>
  state.export.exportPivotTable.filterValues;