import { takeLatest, put } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { resetPeriod } from 'Store/Areas/Period/PeriodActions';
import { resetReturnRoute } from 'Store/Areas/App/MenuModeActions';
import { resetToInitialState } from 'Store/Areas/Projects/UserTreePickerActions';
import { stopPolling } from 'Store/Areas/App/PeriodStatusPollingActions';
import { routes } from 'Constants/Routes';

function* setReturnRoute(action) {
  if (action && action.payload.action === 'POP') {
    yield put(resetReturnRoute());
  }

  if (action && action.payload.location.pathname === routes.home) {
    yield put(resetToInitialState()); // reset treepicker
    yield put(resetPeriod()); // reset period-specific reducers
  }

  if (action && action.payload.action === 'PUSH' && (action.payload.location.pathname === routes.createPeriod || action.payload.location.pathname === routes.setup)) {
    yield put(stopPolling());
  }
}

/* Watcher Sagas */
export function* watchReactRouterLocationChange() {
  yield takeLatest(LOCATION_CHANGE, setReturnRoute);
}
