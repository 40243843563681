export const styles = theme => ({
  root: {
    marginBottom: 30,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: theme.constants.fontSizes.large,
    paddingTop: 6,
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  modelButton: {
    marginTop: 20,
    marginRight: 10,
    paddingLeft: 30,
    paddingRight: 30,
  },
  flex: {
    flexGrow: 1,
  },
  modelContainer: {
    padding: 20,
    marginTop: 10,
  },
  textContainer: {
    width: '90%',
  },
  buttonContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  taxContext: {
    fontSize: theme.constants.fontSizes.regular,
  },
  description: {
    overflowWrap: 'break-word',
    fontSize: theme.constants.fontSizes.regular,
    paddingTop: 10,
    margin: 0,
  },
  modelTitle: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
  },
  verticalFlex: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  newVersionContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  newModelDescription: {
    fontSize: theme.constants.fontSizes.regular,
    flexGrow: 1,
  },
  newVersionButton: {
    width: 290,
  },
  editModelButton: {
    height: 35,
    width: 35,
  },
  expandVersionButton: {
    marginTop: 2,
    marginLeft: 3,
    padding: 0,
    height: 30,
    width: 30,
  },
});
