import React from 'react';
import PropTypes from 'prop-types';
import { Input, withTheme, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import styles from './SidebarInput.styles';
import ErrorLabel, { constants } from '../Label/ErrorLabel';

class SidebarInput extends React.PureComponent {
  render() {
    const {
      classes,
      label,
      className,
      error,
      value,
      id,
      placeholder,
      onChange,
      disabled,
      darkColor,
      inputClassName,
    } = this.props;
    return (
      <div className={className}>
        <div className={classes.label}>{label}</div>
        <Input
          classes={{ disabled: classes.disabled }}
          disabled={disabled}
          autoComplete="off"
          value={value}
          placeholder={placeholder}
          fullWidth
          id={id}
          label={label}
          onChange={event => onChange(event.target.value)}
          className={`${classes.root} ${darkColor ? classes.dark : classes.light} ${inputClassName}`}
          disableUnderline
        />
        <ErrorLabel error={error} verticalPadding textColor={constants.textColor.pink} />
      </div>
    );
  }
}

SidebarInput.defaultProps = {
  label: '',
  className: '',
  inputClassName: '',
  error: '',
  placeholder: '',
  disabled: false,
  darkColor: false,
};

SidebarInput.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  darkColor: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(SidebarInput);
