import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import {
  withStyles,
  withTheme,
  Paper,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
} from '@material-ui/core';
import { title } from 'Constants/App/Titles/Strings';
import Breadcrumb from 'Components/Shared/Breadcrumbs/ChevronBreadcrumb/Breadcrumb';
import Select, { constants as constantsSelect } from 'Components/Shared/Selects/Select';
import CheckboxSelect from 'Components/Shared/Selects/CheckboxSelect';
import { selectMappedTagsFromPeriod } from 'Constants/Categorization/Tags';
import {
  exportPivotTableTagSelector,
  exportPivotTableColumnSelector,
  exportPivotTableIsAddingTagSelector,
  exportPivotTableFilterLoadingSelector,
  exportPivotTableFilterValuesSelector,
  exportLockedSelector,
} from 'Store/Areas/Export/ExportSelectors';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import Search from 'Components/Shared/Inputs/Search';
import GenericDragLayer from 'Components/Shared/DragNDrop/GenericDragLayer';
import DragnDrop from 'Constants/DragnDrop';
import DragTag from 'Components/Upload/MappingPage/TagsPanel/DragTag';
import Tag from 'Components/Upload/MappingPage/TagsPanel/Tag';
import Button, { constants as buttonConstants } from 'Components/Shared/Buttons/Button';
import ProcessingStatus from 'Components/Shared/ProcessingStatus/ProcessingStatus';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { strings, exportProcessingArray } from 'Constants/Export/Strings';
import { DataTypes } from 'Constants/Upload/DataTypes';
import { exportTypes } from 'Constants/Export/ExportTypes';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { pivotTableFunctionTypes } from 'Constants/Export/PivotTableFunctionTypes';
import { requestExport, setExportLocked } from 'Store/Areas/Export/ExportActions';
import { getFilterTagValues } from 'Store/Areas/Export/ExportPivotTableActions';
import { updatePeriodStatusInRedux } from 'Store/Areas/Period/PeriodActions';
import PivotTableParametersCell from './PivotTableParametersCell';
import { styles } from './PivotTableBuilder.styles';

class PivotTableBuilder extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      availableTags: [{}],
      tableHeaders: strings.exportPivotTableHeaders,
      filter: [],
      filterSelection: [],
      cols: [],
      rows: [],
      values: [],
      functionType: 1,
      subDimensions: 0,
      showFilter: false,
      filterSearchTerm: '',
    };
  }

  componentDidMount() {
    document.title = title.pivotTable;
    this.handleAvailableTags(null);
  }

  componentDidUpdate(prevProps) {
    const {
      tag,
      column,
      isAdding,
    } = this.props;

    if ((tag !== null && column !== '') && (tag !== prevProps.tag || isAdding !== prevProps.isAdding)) {
      this.handleUpdateTable(column, tag, isAdding);
      this.handleAvailableTags(tag);
    }
  }

  onFunctionSelection = (value) => {
    this.setState({
      functionType: value,
    });
  }

  getCellTagData = (col, subDim) => {
    const {
      tableHeaders,
      filter,
      cols,
      rows,
      values,
    } = this.state;

    switch (col) {
      case tableHeaders[0]:
        return filter[0];
      case tableHeaders[1]:
        return cols[subDim];
      case tableHeaders[2]:
        return rows[subDim];
      case tableHeaders[3]:
        return values[0];
      default:
        return null;
    }
  }

  onExportsClick = () => {
    this.props.dispatch(push(routes.export));
  }

  handleClickGenerate = () => {
    const {
      dispatch,
      periodId,
    } = this.props;
    const {
      cols,
      rows,
      values,
      functionType,
      filterSelection,
      filter,
    } = this.state;
    dispatch(setExportLocked(true));
    dispatch(updatePeriodStatusInRedux(periodStatuses.exportRunning));
    dispatch(requestExport(
      periodId,
      [exportTypes.pivotTable],
      null, {
        columns: cols,
        rows,
        valuesTag: values[0],
        functionType,
        filterValues: filterSelection,
        filterTag: filter[0],
      },
    ));
  }

  handleAvailableTags = (tag) => {
    const { availableTags } = this.state;

    if (tag === null) {
      this.setState({
        availableTags: this.props.tags,
      });
    }

    if (tag !== null) {
      const isTagAvailable = availableTags.find(item => item.id === tag.id);
      if (isTagAvailable) {
        const newTags = availableTags.filter(item => item.id !== tag.id);
        this.setState({
          availableTags: newTags,
        });
      } else {
        availableTags.push(tag);
        this.setState({
          availableTags: availableTags,
        });
      }
    }
  }

  handleUpdateTable = (column, tag, isAdding) => {
    const {
      dispatch,
      periodId,
    } = this.props;

    const {
      tableHeaders,
      cols,
      rows,
    } = this.state;

    let flattenedCols = cols.filter(elem => elem !== undefined);
    let flattenedRows = rows.filter(elem => elem !== undefined);

    if (column === tableHeaders[0]) {
      const filter = [];
      let showFilter = false;
      if (isAdding) {
        filter[0] = tag;
        showFilter = true;
        dispatch(getFilterTagValues(periodId, tag, ''));
      } else {
        this.handleClickResetFilter();
      }
      this.setState({
        filter: filter,
        showFilter: showFilter,
      });
    }
    if (column === tableHeaders[1]) {
      if (isAdding) {
        flattenedCols.push(tag);
      } else {
        flattenedCols = flattenedCols.filter(item => item.id !== tag.id);
      }
      this.setState({
        cols: flattenedCols,
      });
    }
    if (column === tableHeaders[2]) {
      if (isAdding) {
        flattenedRows.push(tag);
      } else {
        flattenedRows = flattenedRows.filter(item => item.id !== tag.id);
      }
      this.setState({
        rows: flattenedRows,
      });
    }
    if (column === tableHeaders[3]) {
      let values = [];
      if (isAdding) {
        values[0] = tag;
      } else {
        values = [];
      }
      this.setState({
        values: values,
      });
    }
  }

  handleClickNewRow = () => {
    const { subDimensions } = this.state;

    this.setState({
      subDimensions: subDimensions + 1,
    });
  }

  handleClickDeleteRow = () => {
    const { subDimensions } = this.state;

    if (subDimensions > 0) {
      this.setState({
        subDimensions: subDimensions - 1,
      });
    }
  }

  handleFilterSelection = (selection) => {
    this.setState({
      filterSelection: selection,
    });
  }

  hideCell = (col, subDim) => {
    const headers = this.state.tableHeaders;
    if (subDim > 0 && (col === headers[0] || col === headers[3] || col === headers[4])) {
      return true;
    }
    return false;
  }

  handleOnSearch = () => {
    const {
      dispatch,
      periodId,
    } = this.props;

    const {
      filter,
      filterSearchTerm,
    } = this.state;

    dispatch(getFilterTagValues(periodId, filter[0], filterSearchTerm));
  }

  handleSetSearchTerm = (searchTerm) => {
    this.setState({
      filterSearchTerm: searchTerm,
    });
  }

  handleClickResetFilter = () => {
    this.handleReset();

    this.setState({
      filterSelection: [],
    });
  }

  handleResetSearch = () => {
    this.handleReset();
  }

  handleReset = () => {
    const {
      dispatch,
      periodId,
    } = this.props;

    const {
      filter,
    } = this.state;

    this.setState({
      filterSearchTerm: '',
    });
    dispatch(getFilterTagValues(periodId, filter[0], ''));
  }

  renderDragLayer(item, itemType) {
    if (itemType === DragnDrop.mappingTag) {
      return (
        <Tag
          tag={item}
          showDelete={false}
        />
      );
    }

    return null;
  }

  renderSubDimensionRow(subDim) {
    const { classes } = this.props;
    const { tableHeaders, functionType } = this.state;

    return (
      <TableRow>
        {tableHeaders.map((col, index) => {
          const headerKey = `${col}_${index}_${subDim}`;
          return (
            <TableCell
              className={`${classes.headerText} ${classes.tableCell} ${classes.cellWidth}`}
              key={headerKey}
            >
              <If condition={!this.hideCell(col, subDim)}>
                <If condition={col !== tableHeaders[4]}>
                  <PivotTableParametersCell
                    expectedDataType={col === 'Values' ? [DataTypes.Number] : [DataTypes.Text, DataTypes.Date]}
                    colName={col}
                    tag={this.getCellTagData(col, subDim)}
                  />
                </If>
                <If condition={col === tableHeaders[4]}>
                  <Select
                    id={`Functions-${subDim}`}
                    preventPlaceholder
                    onChange={this.onFunctionSelection}
                    data={pivotTableFunctionTypes}
                    value={functionType}
                    colorScheme={constantsSelect.colorScheme.lightBlue}
                  />
                </If>
              </If>
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  renderDimensionRows() {
    const { subDimensions } = this.state;
    const children = [];

    for (let subDim = 0; subDim <= subDimensions; subDim += 1) {
      children.push(this.renderSubDimensionRow(subDim));
    }

    return children;
  }

  render() {
    const {
      classes,
      dispatch,
      filterLoading,
      filterValues,
      exportLocked,
    } = this.props;

    const {
      tableHeaders,
      availableTags,
      cols,
      rows,
      values,
      subDimensions,
      showFilter,
      filterSelection,
      filterSearchTerm,
    } = this.state;

    const isEnabled = (cols.length > 0) && (rows.length > 0) && (values.length > 0);
    const canRemoveSubDimension = (cols.length - subDimensions < 1)
      && (rows.length - subDimensions < 1) && (subDimensions > 0);

    return (
      <div className={classes.root}>
        <Breadcrumb
          items={[
            strings.exportReports,
            strings.exportPivotTable,
          ]}
          activeIndex={1}
          className={classes.breadcrumb}
          selectableIndexes={[0]}
          onClicks={
            [
              () => dispatch(push(routes.export)),
              null,
            ]
          }
          isSelectable
          withTails={false}
        />
        <Choose>
          <When condition={!exportLocked}>
            <Paper className={classes.paperContainer}>
              <Grid container direction="row" alignItems="stretch">
                <Grid container justify="flex-end">
                  <Grid item xs={12} >
                    <div className={classes.note}>
                      {strings.exportPivotTableTitle}
                    </div>
                  </Grid>
                  <Grid item xs={12} >
                    <div className={classes.description}>
                      <span>
                        {strings.exportPivotTableDescription}
                        <span className={classes.linkText} onClick={this.onExportsClick} role="presentation">
                          {strings.exportReports}
                        </span>
                      </span>
                    </div>
                  </Grid>
                  <GenericDragLayer>
                    {this.renderDragLayer}
                  </GenericDragLayer>
                  <Grid item xs={9} >
                    <div className={classes.operators}>
                      <div className={classes.title}>{strings.tagsHeader}</div>
                      <For each="tag" of={availableTags} >
                        <DragTag
                          tag={tag}
                          key={tag.id}
                          showDelete={false}
                        />
                      </For>
                    </div>
                  </Grid>
                  <Grid item xs={3} >
                    <Button
                      className={isEnabled
                        ? classes.generateButton
                        : classes.generateButtonDisabled}
                      onClick={this.handleClickGenerate}
                      backgroundColor={buttonConstants.backgroundColor.main}
                      height={buttonConstants.height.big}
                      disabled={!isEnabled}
                    >
                      {strings.exportPivotTableButton}
                    </Button>
                  </Grid>
                  <Grid item xs={12} >
                    <Table>
                      <TableHead>
                        <TableRow>
                          {tableHeaders.map((col, index) => {
                            const headerKey = `${col}_${index}`;
                            return (
                              <TableCell
                                className={`${classes.headerText} ${classes.tableCell} ${classes.cellWidth}`}
                                key={headerKey}
                              >
                                {col}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.renderDimensionRows()}
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid item xs={12}>
                    <button
                      className={`${classes.buttonBase} ${classes.button}`}
                      onClick={this.handleClickNewRow}
                    >
                      {strings.exportPivotTableAddRowButton}
                    </button>
                    <button
                      className={canRemoveSubDimension ?
                        `${classes.buttonBase} ${classes.button}`
                      : `${classes.buttonBase} ${classes.buttonDisabled}`}
                      onClick={this.handleClickDeleteRow}
                      disabled={!canRemoveSubDimension}
                    >
                      {strings.exportPivotTableRemoveRowButton}
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.paperContainerFilter}>
              <Grid container direction="row" alignItems="stretch">
                <Grid container justify="flex-end">
                  <Grid item xs={12}>
                    <Choose>
                      <When condition={showFilter}>
                        <Choose>
                          <When condition={filterLoading}>
                            <CircularLoader />
                          </When>
                          <Otherwise>
                            <Grid container direction="row" alignItems="stretch">
                              <Grid container justify="flex-end">
                                <Grid item xs={12}>
                                  <Grid item xs={2}>
                                    <Button
                                      className={classes.resetFilterButton}
                                      onClick={this.handleClickResetFilter}
                                      backgroundColor={buttonConstants.backgroundColor.main}
                                      height={buttonConstants.height.regular}
                                    >
                                      {strings.exportPivotTableResetFilterButton}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Search
                                      className={classes.searchBox}
                                      id={strings.searchFilter}
                                      placeholder={strings.searchPlaceholder}
                                      onChange={this.handleSetSearchTerm}
                                      onReset={this.handleResetSearch}
                                      onSearch={this.handleOnSearch}
                                      value={filterSearchTerm}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <CheckboxSelect
                              id={strings.filterSelectPlaceholder}
                              label={strings.filterPivotTable}
                              multiple
                              placeholder={strings.filterSelectPlaceholder}
                              data={filterValues}
                              onChange={this.handleFilterSelection}
                              value={filterSelection}
                              colorScheme={constantsSelect.colorScheme.lightBlue}
                            />
                          </Otherwise>
                        </Choose>
                      </When>
                      <Otherwise>
                        {strings.exportPivotTableNoFilter}
                      </Otherwise>
                    </Choose>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </When>
          <Otherwise>
            <ProcessingStatus
              processingMessageArray={exportProcessingArray}
              message={strings.exporting}
              renderProgressBar={() => (<LinearProgress className={classes.progressBar} />)}
            />
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

PivotTableBuilder.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  tag: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  column: PropTypes.string.isRequired,
  isAdding: PropTypes.bool.isRequired,
  periodId: PropTypes.number.isRequired,
  filterValues: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  filterLoading: PropTypes.bool.isRequired,
  exportLocked: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    tags: selectMappedTagsFromPeriod(state),
    tag: exportPivotTableTagSelector(state),
    column: exportPivotTableColumnSelector(state),
    isAdding: exportPivotTableIsAddingTagSelector(state),
    periodId: periodIdSelector(state),
    filterLoading: exportPivotTableFilterLoadingSelector(state),
    filterValues: exportPivotTableFilterValuesSelector(state),
    exportLocked: exportLockedSelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(PivotTableBuilder);