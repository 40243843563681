import { GET_ALL_VERSIONS, GET_ALL_VERSIONS_SUCCESS, ACTIVATE_VERSION, ACTIVATE_VERSION_SUCCESS, GET_MODELS_OF_LATEST_VERSION, GET_MODELS_OF_LATEST_VERSION_SUCCESS, CANCEL_ADD_MODEL, ADD_MODEL, POST_ADD_MODEL, POST_ADD_MODEL_SUCCESS, EDIT_MODEL, POST_EDIT_MODEL_SUCCESS, CHECK_IF_MODEL_IS_USED_SUCCESS, POST_EDIT_MODEL, POST_DELETE_MODEL_SUCCESS } from './MachineLearningActions';

const initialState = {
  loading: false,
  modelUsed: false,
  versions: [],
  models: [],
  modelVersion: null,
  editModel: null,
};

export default function machineLearningReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_VERSIONS:
      return Object.assign({}, state, {
        loading: true,
        versions: [],
      });
    case GET_ALL_VERSIONS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        versions: action.versions,
      });
    case GET_MODELS_OF_LATEST_VERSION:
      return Object.assign({}, state, {
        loading: true,
        models: [],
      });
    case GET_MODELS_OF_LATEST_VERSION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        models: action.models,
      });
    case ACTIVATE_VERSION:
      return Object.assign({}, state, {
        loading: true,
      });
    case ACTIVATE_VERSION_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      });
    case ADD_MODEL:
      return Object.assign({}, state, {
        modelVersion: action.version,
      });
    case EDIT_MODEL:
      return Object.assign({}, state, {
        editModel: action.editModel,
        modelVersion: action.version,
      });
    case CANCEL_ADD_MODEL:
    case POST_DELETE_MODEL_SUCCESS:
      return Object.assign({}, state, {
        modelVersion: null,
        editModel: null,
        loading: false,
      });
    case POST_ADD_MODEL:
    case POST_EDIT_MODEL:
      return Object.assign({}, state, {
        loading: true,
      });
    case POST_ADD_MODEL_SUCCESS:
    case POST_EDIT_MODEL_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
      });
    case CHECK_IF_MODEL_IS_USED_SUCCESS:
      return Object.assign({}, state, {
        modelUsed: action.modelUsed,
      });
    default:
      return state;
  }
}
