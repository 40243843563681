export const POST_NEW_CATEGORY = 'CATEGORY/POST_NEW_CATEGORY';
export const POST_CATEGORY_SUCCESS = 'CATEGORY/POST_CATEGORY_SUCCESS';
export const POST_CATEGORY_FAILURE = 'CATEGORY/POST_CATEGORY_FAILURE';
export const ADDING_NEW_CATEGORY = 'CATEGORY/ADDING_NEW_CATEGORY';
export const CANCEL_ADD_CATEGORY = 'CATEGORY/CANCEL_ADD_CATEGORY';
export const RESET_FAILED_ADD = 'CATEGORY/RESET_FAILED_ADD';
export const EDIT_CATEGORY = 'CATEGORY/EDIT_CATEGORY';
export const POST_EDIT_CATEGORY = 'CATEGORY/POST_EDIT_CATEGORY';

export function postNewCategory(request) {
  return {
    type: POST_NEW_CATEGORY,
    loading: true,
    success: false,
    errorMessage: '',
    payload: request,
    adding: true,
  };
}

export function postEditCategory(request) {
  return {
    type: POST_EDIT_CATEGORY,
    loading: true,
    success: false,
    errorMessage: '',
    payload: request,
    adding: true,
  };
}

export function postCategorySuccess(category) {
  return {
    type: POST_CATEGORY_SUCCESS,
    loading: false,
    success: true,
    adding: false,
    errorMessage: '',
    category: category,
  };
}

export function postCategoryFailure(errorMessage) {
  return {
    type: POST_CATEGORY_FAILURE,
    adding: true,
    loading: false,
    success: false,
    errorMessage: errorMessage,
  };
}

export function beginAdding() {
  return {
    type: ADDING_NEW_CATEGORY,
    adding: true,
    isEdit: false,
  };
}

export function cancelAdding() {
  return {
    type: CANCEL_ADD_CATEGORY,
    adding: false,
    isEdit: false,
  };
}

export function resetFailure() {
  return {
    type: RESET_FAILED_ADD,
    adding: false,
    errorMessage: '',
  };
}

export function editCategory(category) {
  return {
    type: EDIT_CATEGORY,
    adding: true,
    isEdit: true,
    payload: category,
  };
}
