export const strings = {
  importedDocsText: 'Imported Documents',
  importedDocsDescription: 'Below is a summary of the documents that were originally uploaded for this period.Choosing to make a change will mean you are able to add to or remove the supporting documents that were originally uploaded.',
  summaryText: 'Summary',
  mainDocText: 'Main document',
  supportingDocText: 'Supporting documents',
  categoriesText: 'Categories',
  referenceListsText: 'Reference lists',
  machineLearningTemplate: 'Machine learning template',
  mappedTagsText: 'Mapped tags',
  makeChangeButton: 'Make a change',
  tagNameHeader: 'Tag Name',
  tagTypeHeader: 'Tag Type',
  tagDataTypes: 'Tags and their corresponding data types',
  documentColumnName: 'Document Column Name',
  isNecessary: 'Necessary',
};
