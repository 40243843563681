import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, Paper } from '@material-ui/core';
import { strings } from 'Constants/Review/Strings';
import RevCounter from './RevCounter';
import { styles } from './RevCounterCard.styles';

class RevCounterCard extends React.PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <span className={classes.title}>{strings.revCounterTitle}</span>
        <RevCounter />
      </Paper>
    );
  }
}

RevCounterCard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles))(RevCounterCard);
