const styles = theme => ({
  tableContainer: {
    height: 'Calc(80vh - 280px)',
    minHeight: 120,
    fontSize: 12,
    paddingTop: 10,
  },
  headerCell: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  cell: {
    borderBottom: `1px solid ${theme.palette.colors.darkGrey}`,
  },
});

export default styles;
