export const styles = theme => ({
  content: {
    width: 450,
    padding: 20,
  },
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  text: {
    fontWeight: 'normal',
    fontSize: theme.constants.fontSizes.regular,
    marginBottom: 10,
    lineHeight: 2,
  },
  loader: {
    marginBottom: 15,
    overflow: 'hidden',
  },
  entryList: {
    maxHeight: 400,
    overflow: 'auto',
  },
  rightAlign: {
    float: 'right',
  },
});
