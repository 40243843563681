import { SELECT_PERIOD } from 'Store/Areas/Projects/UserTreePickerActions';
import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';
import { REQUEST_UPLOAD_CUSTOM_REPORT_FILE, TOGGLE_CORRUPTED_FILE_DETECTED_MODAL } from 'Store/Areas/FileUpload/FileUploadActions';
import { SET_EXPORT_LOCKED, GET_EXPORT_FILES, GET_EXPORT_FILES_SUCCESS, SET_CUSTOM_REPORT_FILE_NAME, DELETE_EXPORT_FILE, DELETE_EXPORT_FILE_SUCCESS, SET_CUSTOM_REPORT_LOADING, RESET_CUSTOM_REPORT_FILES } from './ExportActions';

const initialState = {
  exportLocked: false,
  loading: false,
  fileLoading: false,
  files: [],
  customReportCloudRef: '',
  customReportOriginalFileName: '',
};

export default function exportReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EXPORT_LOCKED:
      return Object.assign({}, state, {
        exportLocked: action.isLocked,
      });
    case GET_EXPORT_FILES:
    case GET_EXPORT_FILES_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        files: action.files,
      });
    case SET_CUSTOM_REPORT_FILE_NAME:
      return Object.assign({}, state, {
        customReportCloudRef: action.customReportCloudRef,
        customReportOriginalFileName: action.customReportOriginalFileName,
        fileLoading: action.fileLoading,
      });
    case SET_CUSTOM_REPORT_LOADING:
    case REQUEST_UPLOAD_CUSTOM_REPORT_FILE:
    case TOGGLE_CORRUPTED_FILE_DETECTED_MODAL:
      return Object.assign({}, state, {
        fileLoading: action.fileLoading,
      });
    case DELETE_EXPORT_FILE:
    case DELETE_EXPORT_FILE_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
      });
    case RESET_CUSTOM_REPORT_FILES:
      return Object.assign({}, state, {
        fileLoading: initialState.fileLoading,
        customReportCloudRef: initialState.customReportCloudRef,
        customReportOriginalFileName: initialState.customReportOriginalFileName,
      });
    case RESET_PERIOD:
    case SELECT_PERIOD:
      return initialState;
    default:
      return state;
  }
}
