export function moveItemInArray(array, fromIndex, toIndex) {
  const modifiedArray = array.slice();
  const element = array[fromIndex];
  modifiedArray.splice(fromIndex, 1);
  modifiedArray.splice(toIndex, 0, element);
  return modifiedArray;
}

// https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays
export function flatten(arr, result = []) {
  for (let i = 0, length = arr.length; i < length; i += 1) { // eslint-disable-line
    const value = arr[i];
    if (Array.isArray(value)) {
      flatten(value, result);
    } else {
      result.push(value);
    }
  }
  return result;
}

export function sort(arr, selector, ascending = true, replaceOriginalArray = true) {
  return (replaceOriginalArray ? arr : [...arr])
    .sort((a, b) => {
      let firstValue = ascending ? a : b;
      let secondValue = ascending ? b : a;

      if (selector) {
        firstValue = selector(firstValue);
        secondValue = selector(secondValue);
      }

      return typeof firstValue === 'string' ? firstValue.localeCompare(secondValue) : (firstValue - secondValue);
    });
}

export function union(arrA, arrB, selector) {
  return arrA.concat(arrB.filter(b => !arrA.find((a) => {
    if (selector) {
      return selector(b) === selector(a);
    }
    return b === a;
  })));
}
