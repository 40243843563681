import React, { Fragment } from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { strings } from 'Constants/Categorization/Strings';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import Breadcrumb from 'Components/Shared/Breadcrumbs/ChevronBreadcrumb/Breadcrumb';
import SingleKeywordFiltersPanel from 'Components/Categorization/Shared/Keywords/KeywordFilters/SingleKeywordFiltersPanel';
import KeywordsList from 'Components/Categorization/Shared/Keywords/KeywordsList';
import { getKeywords, resetKeywordFilters } from 'Store/Areas/Categorisation/KeywordActions';
import { setComboKeywordRule } from 'Store/Areas/Categorisation/ComboKeywordActions';
import { navigateToReferenceLists } from 'Store/Areas/Categorisation/ReferenceListActions';
import { periodIdSelector, isAnyLineItemFlaggedSelector } from 'Store/Areas/Period/PeriodSelectors';
import { styles } from './SingleKeywords.styles';

class SingleKeywords extends React.PureComponent {
  componentDidMount() {
    const { dispatch, periodId } = this.props;
    dispatch(getKeywords(periodId));
    dispatch(resetKeywordFilters());
  }

  onKeywordClick = (keyword, currentTag) => {
    this.props.dispatch(setComboKeywordRule(keyword, currentTag));
    this.props.dispatch(push(routes.categorisation.combinationKeywords));
  }

  render() {
    const {
      classes,
      currencySymbol,
      keywords,
      periodId,
      keywordFilters,
      isAnyLineItemFlagged,
    } = this.props;
    return (
      <Fragment>
        <Breadcrumb
          items={[
            strings.referenceLists,
            strings.keyword,
            strings.keywordCombination,
            strings.customRules,
            strings.lineItems,
            strings.conflictedLineItems,
            strings.flaggedLineItems,
          ]}
          activeIndex={1}
          className={classes.breadcrumb}
          selectableIndexes={[0, 3, 4, 5, ...isAnyLineItemFlagged ? [6] : []]}
          onClicks={
            [
              () => this.props.dispatch(navigateToReferenceLists()),
              null,
              null,
              () => this.props.dispatch(push(routes.categorisation.customKeywords)),
              () => this.props.dispatch(push(routes.categorisation.lineItems)),
              () => this.props.dispatch(push(routes.categorisation.conflictingLineItems)),
              () => this.props.dispatch(push(routes.categorisation.flaggedLineItems)),
            ]
          }
          isSelectable
        />
        <SingleKeywordFiltersPanel />
        <KeywordsList
          currencySymbol={currencySymbol}
          onKeywordClick={this.onKeywordClick}
          keywords={keywords}
          periodId={periodId}
          keywordFilters={keywordFilters}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    keywords: state.categorisation.keywords,
    keywordFilters: state.categorisation.keywords.filters,
    periodId: periodIdSelector(state),
    isAnyLineItemFlagged: isAnyLineItemFlaggedSelector(state),
  };
}

SingleKeywords.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  keywords: PropTypes.shape({
    name: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      totalSum: PropTypes.number,
      morePagesAvailable: PropTypes.bool,
      loading: PropTypes.bool,
    })),
    totalSum: PropTypes.number,
    currentPageNumber: PropTypes.number,
  }).isRequired,
  periodId: PropTypes.number.isRequired,
  keywordFilters: PropTypes.shape({
    sortOrder: PropTypes.number.isRequired,
    tagFilter: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired,
  }).isRequired,
  isAnyLineItemFlagged: PropTypes.bool.isRequired,
};

export default compose(connect(mapStateToProps), withStyles(styles), withTheme())(SingleKeywords);
