import React, { Fragment } from 'react';
import { withStyles, withTheme, Card, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { getTagFilters } from 'Helpers/CategorizationHelpers';
import { strings } from 'Constants/Categorization/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import GenericDragLayer from 'Components/Shared/DragNDrop/GenericDragLayer';
import { getComboKeywords, resetComboKeywords, resetKeywordFilters } from 'Store/Areas/Categorisation/ComboKeywordActions';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { routes } from 'Constants/Routes';
import DragnDrop from 'Constants/DragnDrop';
import ArrowButton from 'Components/Shared/Buttons/ArrowButton/ArrowButton';
import KeywordRule from 'Components/Categorization/Shared/KeywordRule/KeywordRule';
import { onPeriodReloaded } from 'Components/Shared/PeriodProviders/onPeriodReloaded';
import { push } from 'connected-react-router';
import { isReadOnlySelector } from 'Store/Areas/Period/PeriodSelectors';
import { styles } from './ComboKeywordsList.styles';
import Keyword from './Keyword/Keyword';

class ComboKeywordsList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tags = getTagFilters(this.props.period.data.tags);

    this.state = {
      hoveredKeyword: null,
      currentTag: this.tags[0],
    };
  }

  componentDidMount() {
    if (this.props.keywordFilters.tagFilter) {
      this.updateCurrentTag();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.keywordFilters.tagFilter !== this.props.keywordFilters.tagFilter) {
      this.updateCurrentTag();
    }
  }

  onKeywordHover = ({ name }) => {
    if (this.state.hoveredKeyword !== name) {
      this.setState({
        hoveredKeyword: name,
      });
    }
  }
  onKeywordOut = () => {
    this.onKeywordHover({ name: null });
  }
  loadMoreKeywords = () => {
    this.props.dispatchGetKeywords(true);
  }

  updateCurrentTag() {
    this.setState({
      currentTag: this.tags.find(x => x.id === this.props.keywordFilters.tagFilter),
    });
  }

  renderDragLayer = (item) => {
    if (item && item.type === DragnDrop.keyword) {
      return (
        <Keyword
          isDragging
          currencySymbol={this.props.currencySymbol}
          rootKeyword={item.rootKeyword}
          rootTag={item.rootTag}
          keyword={item.keywords[0]}
          currentTag={item.currentTag}
          showProgress={this.props.showProgress}
        />
      );
    }

    return null;
  }

  render() {
    const {
      classes,
      currencySymbol,
      keywords,
      isReadOnly,
    } = this.props;
    const {
      hoveredKeyword,
      currentTag,
    } = this.state;
    const { currentPageNumber, morePagesAvailable } = keywords;
    return (
      <Fragment>
        <If condition={!isReadOnly}>
          <GenericDragLayer disableIE={false}>
            {this.renderDragLayer}
          </GenericDragLayer>
        </If>
        <Card className={classes.card}>
          <div className={classes.rootKeyword}>
            <ArrowButton
              onClickEvent={this.props.onNavigateBack}
            />
            <Grid container direction="row" spacing={8} className={classes.grid}>
              <Grid item xl={3} lg={4} md={6} xs={12}>
                <KeywordRule
                  rootKeyword={keywords.rootKeyword.name}
                  rootTag={keywords.rootTag.name}
                  secondTag={currentTag.name}
                  hoveredKeyword={hoveredKeyword}
                />
                <Keyword
                  currencySymbol={currencySymbol}
                  keyword={keywords.rootKeyword}
                  currentTag={keywords.rootTag}
                  key={keywords.rootKeyword.name}
                  showProgress={false}
                  allowDrag={false}
                />
              </Grid>
            </Grid>
            <div className={classes.andWord}>{commonStrings.smallAnd}</div>
          </div>
          <div className={classes.scrollDiv}>
            <InfiniteScroll
              initialLoad
              pageStart={0}
              loadMore={this.loadMoreKeywords}
              hasMore={keywords.morePagesAvailable}
              useWindow={false}
              threshold={30}
            >
              <Grid container direction="row" spacing={8} className={classes.keywordsGrid}>
                <If condition={!keywords.loading
                  && !keywords.keywords.length}
                >
                  <div className={classes.searchMatchesNoKeywordsMessage}>
                    <p className={classes.simpleMessage}>{strings.comboKeywordsNoneFound}</p>
                  </div>
                </If>
                <For each="keyword" of={keywords.keywords}>
                  <Grid item xl={3} lg={4} md={6} xs={12} key={`{grid_${keyword.name}}`}>
                    <Keyword
                      currencySymbol={currencySymbol}
                      allowDrag={!isReadOnly}
                      keyword={keyword}
                      currentTag={currentTag}
                      key={keyword.name}
                      showProgress={this.props.showProgress}
                      rootKeyword={keywords.rootKeyword}
                      rootTag={keywords.rootTag}
                      transparentSource
                      onMouseOver={this.onKeywordHover}
                      onFocus={this.onKeywordHover}
                      onMouseOut={this.onKeywordOut}
                      onBlur={this.onKeywordOut}
                    />
                  </Grid>
                </For>
              </Grid>

              <If condition={keywords.loading}>
                <div key="loading-icon" className={classes.loaderIcon}><CircularLoader className={classes.loader} /></div>
              </If>
            </InfiniteScroll>
          </div>
          <If condition={!morePagesAvailable && currentPageNumber > 20}>
            <div className={classes.lastKeywordsPageMessage}>
              <p className={classes.simpleMessage}>{strings.lastKeywordsPageMessage}</p>
            </div>
          </If>
        </Card>
      </Fragment>
    );
  }
}

ComboKeywordsList.defaultProps = {
  showProgress: true,
};

/* eslint-disable react/require-default-props */
ComboKeywordsList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  showProgress: PropTypes.bool,
  keywords: PropTypes.shape({
    loading: PropTypes.bool,
    currentPageNumber: PropTypes.number,
    morePagesAvailable: PropTypes.bool,
    keywords: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalSum: PropTypes.number.isRequired,
    })),
    rootKeyword: PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalSum: PropTypes.number.isRequired,
    }).isRequired,
    rootTag: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  keywordFilters: PropTypes.shape({
    sortOrder: PropTypes.number.isRequired,
    tagFilter: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired,
  }).isRequired,
  dispatchGetKeywords: PropTypes.func.isRequired,
  onNavigateBack: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  periodId: PropTypes.number.isRequired, // eslint-disable-line
  period: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      tags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
    }),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    isReadOnly: isReadOnlySelector(state),
    period: state.periods.period,
    comboKeywords: state.categorisation.comboKeywords,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const {
    keywords,
    keywordFilters,
    periodId,
  } = ownProps;
  return {
    dispatchGetKeywords: (isLoadMoreKeywords = false) => dispatch(getComboKeywords(
      periodId,
      keywords.rootKeyword.name,
      keywords.rootTag.id,
      keywords.currentPageNumber,
      keywordFilters.sortOrder,
      keywordFilters.tagFilter,
      keywordFilters.searchTerm,
      !!(keywordFilters.searchTerm && keywordFilters.searchTerm.trim()),
      isLoadMoreKeywords,
    )),
    onNavigateBack: () => {
      dispatch(push(routes.categorisation.singleKeywords));
      dispatch(resetComboKeywords());
      dispatch(resetKeywordFilters());
    },
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps, mapDispatchToProps),
  onPeriodReloaded(props => props.dispatchGetKeywords()),
)(ComboKeywordsList);
