import React, { Fragment } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { strings } from 'Constants/Rules/Strings';
import DragnDrop from 'Constants/DragnDrop';
import { constants } from 'Components/Shared/DragNDrop/SimpleDragCard';
import ExpansionPanel from 'Components/Shared/ExpansionPanel/ExpansionPanel';
import ExpansionPanelManager from 'Components/Shared/ExpansionPanel/ExpansionPanelManager';
import { styles } from './KeywordsPanel.styles';
import { Operator } from '../';

class KeywordsPanel extends React.PureComponent {
  render() {
    const { classes, keywords, isReadOnly } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.overflow}>
          <ExpansionPanelManager defaultExpandedIndex={[1, 2, 3]}>
            {({ active, onExpand }) => (
              <Fragment>
                <ExpansionPanel
                  header={strings.keywords}
                  className={classes.margin}
                  index={3}
                  activeIndex={active}
                  onExpand={onExpand}
                >
                  <For each="item" of={Object.values(keywords)}>
                    <Operator
                      canDrag={!isReadOnly}
                      borderColor={constants.borderColor.grey}
                      key={item}
                      type={DragnDrop.operator.keyword}
                      metadata={item}
                    >
                      {item}
                    </Operator>
                  </For>
                </ExpansionPanel>
              </Fragment>
            )}
          </ExpansionPanelManager>
        </div>
      </div>
    );
  }
}

KeywordsPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(KeywordsPanel);
