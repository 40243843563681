import { supporting, main, newSupportingDocAfterPeriodCreation } from 'Constants/FileTypes';
import { fileStatuses } from 'Constants/FileStatuses';

export const periodSelector = state => state.periods.period;

export const periodConflictsSelector = state => state.periods.periodConflicts;

export const getAllFilesForTypeSelector = (
  state,
  fileType,
  isChangeSupportingDocsAfterPeriodCreation,
) => {
  if (isChangeSupportingDocsAfterPeriodCreation) {
    return periodSelector(state).data.files
      .filter(x => x.fileType === newSupportingDocAfterPeriodCreation);
  }
  return periodSelector(state).data.files.filter(x => x.fileType === fileType);
};

export const getAllSupportingFilesCount = (state) => {
  return periodSelector(state).data.files
    .filter(x => x.fileType === supporting ||
      x.fileType === newSupportingDocAfterPeriodCreation).length;
};

export const hasSupportingFilesSelector = state =>
  getAllFilesForTypeSelector(state, supporting, false).length > 0;

export const hasMainFilesSelector = state =>
  periodSelector(state).data.files.filter(x => x.fileType === main).length > 0;

export const periodStatusSelector = state =>
  periodSelector(state).data.status;

export const changeSupportingDocsAfterPeriodCreationSelector = state =>
  periodSelector(state).data.changeSupportingDocsAfterPeriodCreation;

export const periodIsLockedSelector = state =>
  periodSelector(state).data.isLocked;

export const periodIsExternalReviewLockedSelector = state =>
  periodSelector(state).data.isExternalReviewLocked;

export const periodCanLockSelector = state =>
  periodSelector(state).data.canLock;

export const periodIdSelector = state =>
  periodSelector(state).data.id;

export const periodLoadingSelector = state =>
  periodSelector(state).loading;

export const periodCategoriesSelector = state =>
  periodSelector(state).data.categories;

export const projectIdSelector = state =>
  state.periods.period.data.projectId;

export const tagSelector = state => state.periods.period.data.tags;

export const projectIsReadOnlyAccess = state =>
  state.user.permissions.data.projectsCanAccessAsReadOnly.some(projectId =>
    projectId === projectIdSelector(state));

export const hasFilesAwaitingScanSelector = state =>
  periodSelector(state).data.files.filter(file =>
    file.fileStatus === fileStatuses.awaitingScan).length > 0;

export const isReadOnlySelector = state =>
  periodSelector(state).data.isReadOnly || state.projects.userTreePicker.canRenderReadOnlyBanner
  || projectIsReadOnlyAccess(state);

export const periodLockedByUserIdSelector = state => state.periods.period.isLockedByUserId;

export const periodLockedByUserSelector = state => state.periods.period.isLockedByUser;

export const isAnyLineItemFlaggedSelector = state =>
  state.periods.period.data.isAnyLineItemFlagged;

export const periodInUseSelector = state => state.periods.period.data.inUse;

export const periodNameSelector = state => state.periods.period.data.name;

export const periodControlNotificationSelector = state => state.periods.period.notification;

export const periodEntityMachineLearningEnabled = state =>
  periodSelector(state).data.entityMachineLearningEnabled;
