import React from 'react';
import { withStyles, withTheme, Paper, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Close from 'Assets/Images/exit-ic.svg';
import withLineItems from 'Components/Shared/LineItemsProvider/LineItemsProvider';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { strings } from 'Constants/Categorization/Strings';
import { routes } from 'Constants/Routes';
import Breadcrumb from 'Components/Shared/Breadcrumbs/ChevronBreadcrumb/Breadcrumb';
import CategorizationComplete from 'Components/Categorization/Shared/Robot/CategorizationComplete';
import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';
import CheckboxIcon from 'Assets/Images/check-box-ic.svg';
import FlaggedBlackIcon from 'Assets/Images/flagged-black.svg';
import Button from 'Components/Shared/Buttons/Button';
import { title } from 'Constants/App/Titles/Strings';
import DataGrid from 'Components/Shared/DataGrid/DataGrid';
import SimpleHeader from 'Components/Shared/DataGrid/Cells/SimpleHeader/SimpleHeader';
import BorderedRow from 'Components/Shared/DataGrid/Rows/BorderedRow';
import SortableHeader from 'Components/Shared/DataGrid/Cells/SortableHeader/SortableHeader';
import SimpleCell from 'Components/Shared/DataGrid/Cells/SimpleCell/SimpleCell';
import DragnDrop from 'Constants/DragnDrop';
import GenericDragLayer from 'Components/Shared/DragNDrop/GenericDragLayer';
import PreviewRule from 'Components/Shared/DragNDrop/DragPreviewComponents/PreviewRule';
import { formatCurrency } from 'Helpers/NumberHelpers';
import Search from 'Components/Shared/Inputs/Search';
import { formatCellValue } from 'Helpers/LineItemHelpers';
import { getTooltip } from 'Helpers/DataGridHelpers';
import { isReadOnlySelector, isAnyLineItemFlaggedSelector } from 'Store/Areas/Period/PeriodSelectors';
import { navigateToReferenceLists } from 'Store/Areas/Categorisation/ReferenceListActions';
import { onPeriodReloaded } from 'Components/Shared/PeriodProviders/onPeriodReloaded';
import { uncategoriseLineItems, moveLineItemToContraShelf } from 'Store/Areas/Categorisation/LineItemActions';
import { commonStrings } from 'Constants/CommonStrings';
import CreateRuleOverlay from 'Components/CategoryReview/CreateRuleOverlay/CreateRuleOverlay';
import CreateRuleDragLayer from 'Components/CategoryReview/LineItemsCategorisation/CreateRuleDragLayer';
import { openCreateRule } from 'Store/Areas/Rule/CreateRuleActions';
import { styles } from './LineItems.styles';
import AllSearchResultsDragLayer from './AllSearchResultsDragLayer';
import ContraShelfLineItems from './ContraShelfLineItems';

class LineItems extends React.PureComponent {
  componentDidMount() {
    this.props.setOptions({
      categorizationType: this.props.categorizationType,
      includeContraFlagColumn: this.props.includeContraFlagColumn,
      includeFlaggedColumn: this.props.includeFlaggedColumn,
      showOnlyFlaggedLineItems: this.props.showOnlyFlaggedLineItems,
    });
    document.title = title.lineItems(this.props.categorizationType);
  }

  getCellValue = ({ rowIndex, columnIndex }) => {
    const {
      classes,
      lineItems,
      lineItemsColumns,
      amountColumnIndex,
      includeContraFlagColumn,
      includeFlaggedColumn,
      isReadOnly,
    } = this.props;

    const lineItem = lineItems[rowIndex];
    const cell = lineItems[rowIndex].columns[lineItemsColumns[columnIndex]];
    if (columnIndex === amountColumnIndex) {
      return cell.value;
    }

    if (columnIndex === lineItemsColumns.length - 1 && includeContraFlagColumn) {
      return (
        <Button
          onClick={() => this.moveLineItemToContraShelf(lineItem.id)}
          className={classes.imgButton}
          disabled={isReadOnly}
          disableRipple
        >
          <img alt={strings.moveToContraShelfText} src={CheckboxIcon} />
        </Button>
      );
    }
    if (includeFlaggedColumn && lineItemsColumns[columnIndex] === commonStrings.description
      && lineItem.columns.IsFlagged && lineItem.columns.IsFlagged.value === true) {
      return (
        <div>
          <Button
            className={classes.flaggedIcon}
            title={strings.flaggedIconTooltip}
            disableRipple
          >
            <img
              alt={strings.flaggedItem}
              src={FlaggedBlackIcon}
            />
          </Button>
          {formatCellValue(cell)}
        </div>
      );
    }
    return formatCellValue(cell);
  }

  getWidth = ({ index }) => {
    const {
      amountColumnIndex,
    } = this.props;
    return index === amountColumnIndex ? 150 : 300;
  }

  getDragProps = (props) => {
    const {
      lineItems,
      selectedRowIds,
      selectedRows,
      selectedRowsTotalAmount,
    } = this.props;

    return {
      index: props.index,
      lineItemId: lineItems[props.index].id,
      lineItemIds: selectedRowIds,
      selectedRows,
      selectedRowsTotalAmount,
    };
  }

  getActiveTabIndex = (categorizationType, showOnlyFlaggedLineItems) => {
    if (showOnlyFlaggedLineItems) { return 6; }
    if (categorizationType === categorizationTypes.uncategorized) { return 4; }
    return 5;
  }

  getSelectableIndexes = (categorizationType, showOnlyFlaggedLineItems, isAnyLineItemFlagged) => {
    if (showOnlyFlaggedLineItems) { return [0, 1, 3, 4, 5]; }
    if (categorizationType === categorizationTypes.uncategorized) {
      return [0, 1, 3, 5, ...isAnyLineItemFlagged ? [6] : []];
    }
    return [0, 1, 3, 4, ...isAnyLineItemFlagged ? [6] : []];
  }

  isReloadLineItems = (categorizationType, showOnlyFlaggedLineItems) => {
    if (showOnlyFlaggedLineItems) { return true; }
    if (categorizationType === categorizationTypes.uncategorized) { return false; }
    return true;
  }

  moveLineItemToContraShelf(lineItemId) {
    const { dispatch } = this.props;
    dispatch(moveLineItemToContraShelf(Number(lineItemId)));
  }

  handleIconClick = (lineItemId) => {
    const { dispatch, periodId } = this.props;
    dispatch(uncategoriseLineItems(periodId, [lineItemId]));
  }

  openCreateRuleOverlay = () => {
    this.props.dispatch(openCreateRule());
  }

  renderStickyCell(categorizationType, lineItems, classes, theme) {
    if (categorizationType === categorizationTypes.allowedConflicts) {
      return {
        renderStickyCell: props => (
          <div {...props}>
            <IconButton
              onClick={() => {
                this.handleIconClick(lineItems[props.index].id);
              }}
              className={classes.removeIcon}
            >
              <img src={Close} alt="remove" style={{ cursor: 'pointer' }} />
            </IconButton>
          </div>
        ),
        stickyColumnWidth: 40,
        stickyBackgroundColor: theme.palette.primary.background,
      };
    }

    return null;
  }

  renderDragLayer = (item, itemType) => {
    const {
      selectedRowIds,
      lineItems,
    } = this.props;

    if (Object.values(DragnDrop.lineItem).includes(itemType) &&
    selectedRowIds.length > 0) {
      return (
        <PreviewRule
          leftAlignedText={selectedRowIds.length > 1  // eslint-disable-line
            ? `${this.props.selectedRows.length} items`
            : lineItems.find(x => x.id === selectedRowIds[0]).columns.Description ?
            lineItems.find(x => x.id === selectedRowIds[0]).columns.Description.value : ''}
          rightAlignedText={
            formatCurrency(this.props.selectedRowsTotalAmount, this.props.currencySymbol)
          }
        />
      );
    }

    return null;
  }

  render() {
    const {
      classes,
      currencySymbol,
      lineItems,
      lineItemsColumnHeaders,
      lineItemsHasMore,
      lineItemsLoadMore,
      lineItemsLoading,
      dispatch,
      amountColumnIndex,
      descriptionColumnIndex,
      setSearchTerm,
      onSearch,
      resetSearch,
      searchTerm,
      isReadOnly,
      categorizationType,
      theme,
      onSort,
      sortOrder,
      sortedColIndex,
      selectedRowIds,
      onBeginDrag,
      onEndDrag,
      onRowSelectionChange,
      isFilteredLineItems,
      isSearchTermPresent,
      contraShelfLineItems,
      includeContraFlagColumn,
      showOnlyFlaggedLineItems,
      isAnyLineItemFlagged,
      showCreateRuleOverlay,
      selectedRows,
      rule,
      note,
    } = this.props;
    return (
      <div className={classes.root}>
        <If condition={!isReadOnly}>
          <GenericDragLayer disableIE={false}>
            {this.renderDragLayer}
          </GenericDragLayer>
        </If>
        <Breadcrumb
          items={[
            strings.referenceLists,
            strings.keyword,
            strings.keywordCombination,
            strings.customRules,
            strings.lineItems,
            strings.conflictedLineItems,
            strings.flaggedLineItems,
          ]}
          activeIndex={
            this.getActiveTabIndex(categorizationType, showOnlyFlaggedLineItems)
          }
          className={classes.breadcrumb}
          selectableIndexes={
            this.getSelectableIndexes(
              categorizationType,
              showOnlyFlaggedLineItems,
              isAnyLineItemFlagged,
            )
          }
          onClicks={
            [
              () => this.props.dispatch(navigateToReferenceLists()),
              () => dispatch(push(routes.categorisation.singleKeywords)),
              null,
              () => dispatch(push(routes.categorisation.customKeywords)),
              (this.isReloadLineItems(categorizationType, showOnlyFlaggedLineItems) === false
                ? null
                : () => dispatch(push(routes.categorisation.lineItems))),
              (categorizationType === categorizationTypes.uncategorized
                ? () => dispatch(push(routes.categorisation.conflictingLineItems))
                : null),
                () => dispatch(push(routes.categorisation.flaggedLineItems)),
            ]
          }
          isSelectable
        />
        <If condition={includeContraFlagColumn && contraShelfLineItems.length > 0}>
          <ContraShelfLineItems
            lineItemsColumnHeaders={lineItemsColumnHeaders}
            lineItems={contraShelfLineItems}
            currencySymbol={currencySymbol}
            showOnlyFlaggedLineItems={showOnlyFlaggedLineItems}
          />
        </If>
        <Paper className={classes.contentHeader}>
          <Search
            className={classes.searchBox}
            id={strings.searchKeywords}
            label={strings.searchKeywords}
            placeholder={strings.searchPlaceholder}
            onChange={value => setSearchTerm(value)}
            onReset={() => resetSearch()}
            onSearch={() => onSearch()}
            value={searchTerm}
          />
        </Paper>
        <div>
          <CreateRuleOverlay
            showCreateRuleOverlay={showCreateRuleOverlay}
            selectedRows={selectedRows}
          />
        </div>
        <Paper className={classes.paperRoot}>
          <div className={classes.createRulePane}>
            <button
              className={classes.createRuleButton}
              onClick={this.openCreateRuleOverlay}
            >
              Create Rule
            </button>
            <If condition={rule !== ''}>
              <CreateRuleDragLayer
                allowDrag={!isReadOnly}
                rule={rule}
                note={note}
                onBeginDrag={this.props.setCategoryType}
                onEndDrag={this.props.clearCategoryType}
              />
            </If>
          </div>
        </Paper>
        <If condition={!lineItemsLoading && isFilteredLineItems && lineItems.length}>
          <AllSearchResultsDragLayer
            allowDrag={!isReadOnly}
            searchTerm={searchTerm}
            contraShelfLineItemIds={contraShelfLineItems.map(x => x.id)}
            categorizationType={categorizationType}
          />
        </If>
        <Paper className={classes.content}>
          <If condition={!lineItemsLoading && !lineItems.length}>
            <If condition={isSearchTermPresent}>
              <div className={classes.noSearchResults}>
                <p className={classes.simpleMessage}>{strings.noSearchResultsMessage}</p>
              </div>
            </If>
            <If condition={!isSearchTermPresent
              && categorizationType !== categorizationTypes.allowedConflicts}
            >
              <CategorizationComplete message={strings.lineItemsCategorised} />
            </If>
            <If condition={!isSearchTermPresent
              && categorizationType === categorizationTypes.allowedConflicts}
            >
              <div className={classes.noSearchResults}>
                <p className={classes.simpleMessage}>
                  {strings.noItemsToDisplayMessage}
                </p>
              </div>
            </If>
          </If>
          <DataGrid
            recomputeGridSizeKey={`${amountColumnIndex}_${descriptionColumnIndex}`}
            className={classes.grid}
            columns={lineItemsColumnHeaders}
            rows={lineItems}
            getCellValue={this.getCellValue}
            hasNextPage={lineItemsHasMore}
            isNextPageLoading={lineItemsLoading}
            loadNextPage={lineItemsLoadMore}
            getWidth={this.getWidth}
            rowMargin={10}
            childRowHeight={30}
            parentRowHeight={30}
            sortedByKey={`${sortOrder}_${sortedColIndex}`}
            showDefaultNoDataMessage={false}
            renderHeaderCell={props => (
              <Choose>
                <When
                  condition={
                    props.index === lineItemsColumnHeaders.length - 1
                  }
                >
                  <SimpleHeader {...props} />
                </When>
                <Otherwise>
                  <SortableHeader
                    sortOrder={sortOrder}
                    isSorting={sortedColIndex === props.index}
                    onClick={onSort}
                    {...props}
                  />
                </Otherwise>
              </Choose>
            )}
            renderRow={props => (
              <BorderedRow
                {...props}
                canDrag={!isReadOnly}
                tooltip={getTooltip(lineItems[props.index].columns, currencySymbol)}
                dragType={DragnDrop.lineItem.uncategorised}
                onGetDragProps={() => this.getDragProps(props)}
                onBeginDrag={onBeginDrag}
                onEndDrag={onEndDrag}
                onSelectionChange={onRowSelectionChange}
                isSelected={selectedRowIds.includes(lineItems[props.index].id)}
              />
            )}
            renderCell={props => (
              <SimpleCell
                {...props}
                currencySymbol={currencySymbol}
                padLeft={props.index === 0}
                padRight={props.index === lineItemsColumnHeaders.length - 1}
                padding={10}
                formatAsCurrency={props.index === amountColumnIndex}
              />
            )}
            {...this.renderStickyCell(categorizationType, lineItems, classes, theme)}
          />
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    periodId: state.periods.period.periodId,
    isReadOnly: isReadOnlySelector(state),
    isAnyLineItemFlagged: isAnyLineItemFlaggedSelector(state),
    showCreateRuleOverlay: state.rules.createRule.open,
    rule: state.rules.createRule.rule,
    note: state.rules.createRule.note,
  };
}

LineItems.defaultProps = {
  categorizationType: categorizationTypes.uncategorized,
  showOnlyFlaggedLineItems: false,
};

LineItems.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  lineItemsColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineItemsColumnHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineItemsHasMore: PropTypes.bool.isRequired,
  lineItemsLoadMore: PropTypes.func.isRequired,
  lineItemsLoading: PropTypes.bool.isRequired,
  amountColumnIndex: PropTypes.number.isRequired,
  descriptionColumnIndex: PropTypes.number.isRequired,
  categorizationType: PropTypes.number,
  setSearchTerm: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isAnyLineItemFlagged: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  sortedColIndex: PropTypes.number.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRowsTotalAmount: PropTypes.number.isRequired,
  selectedRowIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onBeginDrag: PropTypes.func.isRequired,
  onEndDrag: PropTypes.func.isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        background: PropTypes.string,
      }),
    }),
  }).isRequired,
  isFilteredLineItems: PropTypes.bool.isRequired,
  isSearchTermPresent: PropTypes.bool.isRequired,
  contraShelfLineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  includeContraFlagColumn: PropTypes.bool.isRequired,
  includeFlaggedColumn: PropTypes.bool.isRequired,
  showOnlyFlaggedLineItems: PropTypes.bool,
  showCreateRuleOverlay: PropTypes.bool.isRequired,
  rule: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
};

export default compose(
  withLineItems(),
  withStyles(styles),
  connect(mapStateToProps),
  withTheme(),
  onPeriodReloaded(props => props.setOptions({})), // triggers line items refresh
)(LineItems);
