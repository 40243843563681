import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import {
  MAP_TAG,
  POST_NEW_TAG,
  postNewTagSuccess,
  toggleAddTagModal,
  DELETE_TAG,
  deleteTagSuccess,
  toggleDeleteTagModal,
  mapTagSuccess,
  autoMapSupportingTagsSuccess,
  AUTO_MAP_SUPPORTING_TAGS,
} from 'Store/Areas/Import/TagMappingActions';
import { Post } from 'Store/Api/CallApi';
import { TAG_ADD_TAG, TAG_MAP_TAG, TAG_DELETE_TAG, TAG_AUTO_MAP_SUPPORTING_HEADERS } from 'Store/Api/ApiEndpoints';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import { strings } from 'Constants/Upload/TagMapping';
import { routes } from 'Constants/Routes';
import { push } from 'connected-react-router';

function* postNewTag(action) {
  const response = yield Post(TAG_ADD_TAG, action.payload);
  if (response.ok) {
    const data = yield response.json();
    if (data.success) {
      yield put(postNewTagSuccess());
    } else {
      yield put(displayToastNotification(strings.addTagError));
      yield put(toggleAddTagModal(false));
    }
    yield put(getPeriod(action.payload.periodId, true));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* deleteTag(action) {
  const response = yield Post(TAG_DELETE_TAG, action.payload);
  if (response.ok) {
    const data = yield response.json();
    if (data.success) {
      yield put(deleteTagSuccess());
    } else {
      yield put(displayToastNotification(data.error));
      yield put(toggleDeleteTagModal(false, action.payload.tagName, action.payload.tagId));
    }
    yield put(getPeriod(action.payload.periodId, true));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* mapTag(action) {
  const response = yield Post(TAG_MAP_TAG, action.payload);
  if (response.ok) {
    const data = yield response.json();
    if (data.success) {
      yield put(mapTagSuccess());
    } else {
      yield put(displayToastNotification(strings.mapTagError));
    }
    yield put(getPeriod(action.payload.periodId, true));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* autoMapSupportingTags(action) {
  const response = yield Post(TAG_AUTO_MAP_SUPPORTING_HEADERS, action.payload);
  if (response.ok) {
    yield put(autoMapSupportingTagsSuccess());
    yield put(getPeriod(action.payload.periodId, true));
    yield put(push(routes.import.mappingSupporting));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* tagMappingSagas() {
  yield takeLatest(DELETE_TAG, deleteTag);
  yield takeLatest(POST_NEW_TAG, postNewTag);
  yield takeLatest(MAP_TAG, mapTag);
  yield takeLatest(AUTO_MAP_SUPPORTING_TAGS, autoMapSupportingTags);
}
