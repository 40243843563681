import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './ErrorMessage.styles';

class ErrorMessage extends React.PureComponent {
  renderErrorDetails(recommendation, classes) {
    // eslint-disable-next-line quotes
    const recommendations = recommendation.split("\n");

    if (recommendations.length === 1) {
      return recommendation;
    }

    return (
      <ul>
        {recommendations.map(r => <li className={classes.recommendations}>{r}</li>)}
      </ul>
    );
  }
  render() {
    const {
      message,
      recommendation,
      classes,
      messageClassName,
      recommendationClassName,
    } = this.props;
    return (
      <Fragment>
        <div className={`${classes.message} ${messageClassName}`}>{message}</div>
        <div className={`${classes.recommendationContainer} ${recommendationClassName}`}>
          {this.renderErrorDetails(recommendation, classes)}
        </div>
      </Fragment>
    );
  }
}

ErrorMessage.defaultProps = {
  messageClassName: '',
  recommendationClassName: '',
};

ErrorMessage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  message: PropTypes.string.isRequired,
  recommendation: PropTypes.string.isRequired,
  messageClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.string),
  ]),
  recommendationClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.string),
  ]),
};

export default compose(
  withStyles(styles),
  withTheme(),
)(ErrorMessage);
