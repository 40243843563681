import {
  CHECK_EXTERNAL_USER_EXISTS,
  CHECK_EXTERNAL_USER_EXISTS_SUCCESS,
  ADD_EXTERNAL_USER,
  ADD_EXTERNAL_USER_RESULT,
  UPDATE_STATE_TO_DEFAULT,
} from 'Store/Areas/Register/RegisterActions';

const initialState = {
  existsUser: 0,
  loading: false,
  addUserSuccess: false,
  showMessage: false,
  supportEmail: 'support@kpmg',
};

export default function registerReducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_EXTERNAL_USER_EXISTS:
    case ADD_EXTERNAL_USER:
      return Object.assign({}, state, {
        loading: action.loading,
      });
    case CHECK_EXTERNAL_USER_EXISTS_SUCCESS:
      return Object.assign({}, state, {
        existsUser: action.payload.existsUser,
        loading: action.loading,
      });
    case ADD_EXTERNAL_USER_RESULT:
      return Object.assign({}, state, {
        loading: action.loading,
        addUserSuccess: action.payload.addUserSuccess,
        showMessage: action.payload.showMessage,
        supportEmail: action.payload.supportEmail,
      });
    case UPDATE_STATE_TO_DEFAULT:
      return initialState;
    default:
      return state;
  }
}
