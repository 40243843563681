import { withStyles, withTheme, Paper, TextField } from '@material-ui/core';
import { strings } from 'Constants/Categorization/CategoryReview/CreateRuleOverlayConstants';
import { strings as categorizationStrings } from 'Constants/Categorization/Strings';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { currentRouteSelector } from 'Store/Areas/Router/RouteSelectors';
import { closeCreateRule } from 'Store/Areas/Rule/CreateRuleActions';
import { isReadOnlySelector } from 'Store/Areas/Period/PeriodSelectors';
import { selectMappedTagsFromPeriod } from 'Constants/Categorization/Tags';
import { OperatorsPanel } from 'Components/Categorization/Pages/CustomRules/RulesBuilder/Components';
import RulesBuilder from 'Components/Categorization/Pages/CustomRules/RulesBuilder/RulesBuilder';
import FullPageModal from 'Components/Shared/Modal/FullPageModal/FullPageModal';
import AddElementsRow from 'Components/Categorization/Pages/CustomRules/RulesBuilder/Components/AddElementsRow/AddElementsRow';
import KeywordsPanel from 'Components/Categorization/Pages/CustomRules/RulesBuilder/Components/KeywordsPanel/KeywordsPanel';
import styles from './CreateRuleOverlay.styles';

class CreateRuleOverlay extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      note: props.note,
    };
  }

  onChange = (event) => {
    this.setState({
      note: event.target.value,
    });
  }
  handleClose = () => {
    const { dispatch, rule } = this.props;
    dispatch(closeCreateRule(rule, this.state.note));
  }

  getKeywords = () => {
    const keywords = [];
    this.props.selectedRows.forEach((row) => {
      row.keywords.forEach((keyword) => {
        if (keywords.indexOf(keyword) === -1) {
          keywords.push(keyword);
        }
      });
    });
    return keywords;
  };

  render() {
    if (!this.props.showCreateRuleOverlay) {
      return null;
    }

    const {
      classes,
      tags,
    } = this.props;

    const { note } = this.state;

    return (
      <FullPageModal
        open
        onClose={this.handleClose}
        title={strings.title}
      >
        <Paper className={classes.content}>
          <div className={classes.grid}>
            <div className={classes.builder}>
              <div className={classes.rulesBuilderWrapper}>
                <RulesBuilder
                  note={note}
                  isDragRuleHandleVisible={false}
                  keywordPanelAvailable
                />
              </div>
              <div className={classes.buttons}><AddElementsRow /></div>
              <div className={classes.ruleInfoWrapper}>
                <span className={classes.boldText}>{strings.notes}</span>
                <div>
                  <TextField
                    className={classes.textField}
                    defaultValue={note}
                    placeholder={categorizationStrings.customRuleNotePlaceholder}
                    margin="dense"
                    InputProps={{
                        classes: {
                          input: classes.textSize,
                        },
                        inputProps: {
                          maxLength: '255',
                        },
                      }}
                    variant="outlined"
                    onChange={this.onChange}
                  />
                </div>
              </div>
            </div>
            <div className={classes.operators}>
              <OperatorsPanel
                tags={tags}
                isReadOnly={this.props.isReadOnly}
              />
              <KeywordsPanel
                keywords={this.getKeywords()}
                isReadOnly={this.props.isReadOnly}
              />
            </div>
          </div>
        </Paper>
      </FullPageModal>
    );
  }
}

CreateRuleOverlay.defaultProps = {
  rule: '',
  note: '',
  selectedRows: [],
};

CreateRuleOverlay.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  showCreateRuleOverlay: PropTypes.bool.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  rule: PropTypes.string,
  note: PropTypes.string,
  selectedRows: PropTypes.arrayOf(PropTypes.object),
};

function mapStateToProps(state) {
  return {
    period: state.periods.period,
    isReadOnly: isReadOnlySelector(state),
    currentRoute: currentRouteSelector(state),
    tags: selectMappedTagsFromPeriod(state),
    rule: state.rules.ruleBuilder.rule,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(CreateRuleOverlay);
