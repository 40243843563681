const styles = theme => ({
  archived: {
    color: theme.palette.colors.orange,
  },
  locked: {
    color: theme.palette.colors.orange,
  },
  inuse: {
    color: theme.palette.colors.green,
  },
});

export default styles;
