const styles = theme => ({
  content: {
    padding: 20,
    width: 475,
  },
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  text: {
    fontSize: theme.constants.fontSizes.regular,
    lineHeight: 1.71,
  },
  red: {
    color: theme.palette.primary.error,
  },
  delete: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 12,
    float: 'right',
  },
  textArea: {
    paddingTop: 30,
    fontSize: theme.constants.fontSizes.small,
  },
  textBox: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  input: {
    height: 40,
    minWidth: 230,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  textInput: {
    padding: '10px 0px 10px 10px',
    fontSize: theme.constants.fontSizes.small,
  },
});

export default styles;
