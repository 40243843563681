import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { styles } from './DragRule.styles';

class DragRule extends React.PureComponent {
  render() {
    const {
      classes,
      text,
    } = this.props;
    return (
      <div className={classes.root}>
        <span className={classes.flex}>{text}</span>
      </div>
    );
  }
}

DragRule.propTypes = {
  text: PropTypes.string.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(DragRule);
