const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    minHeight: 0,
    paddingTop: 20,
    width: 560,
    overflowX: 'hidden',
  },
  header: {
    margin: '0 20px 20px',
  },
  note: {
    fontSize: theme.constants.fontSizes.regular,
    lineHeight: 1.71,
  },
  errorNote: {
    color: theme.palette.primary.error,
    fontSize: theme.constants.fontSizes.small,
    marginTop: 6,
  },
  sliders: {
    flexGrow: 1,
    maxHeight: '70vh',
    overflow: 'auto',
    padding: '0 20px',
  },
  slider: {
    marginBottom: 30,
  },
  sliderLabel: {
    alignItems: 'center',
    color: '#656565',
    display: 'flex',
    fontSize: theme.constants.fontSizes.small,
  },
  sliderColor: {
    display: 'inline-block',
    borderRadius: 40,
    height: 10,
    marginRight: 7,
    width: 10,
  },
  buttons: {
    height: 60,
    paddingTop: 30,
  },
});

export default styles;
