const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    marginTop: '1em',
  },
  rootWithBanner: {
    display: 'flex',
    position: 'relative',
    marginTop: '6vh',
  },
  drawerPaper: {
    height: '100%',
    position: 'fixed',
    width: theme.constants.sidebarWidths.primary,
  },
  content: {
    height: '90vh',
    flexGrow: 1,
    marginLeft: theme.constants.sidebarWidths.primary,
    width: `calc(100% - ${theme.constants.sidebarWidths.primary}px)`,
  },
  centralised: {
    height: '100%',
  },
  banner: {
    position: 'relative',
  },
  bannerShift: {
    marginTop: '6vh',
  },
});

export default styles;
