import {
  REQUEST_UPLOAD_TEMPLATE, REQUEST_UPLOAD_TEMPLATE_SUCCESS,
  SET_UPLOAD_TEMPLATE_STAGE, RESET_UPLOAD_TEMPLATE_STATE,
} from 'Store/Areas/Template/UploadTemplateActions';
import { templateImportStages } from 'Constants/Templates/TemplateProcessingStages';

const initialState = {
  loading: false,
  stage: templateImportStages.uploading,
  importMessages: [],
  hasImportMessages: false,
  userId: -1,
};

export default function templateReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_UPLOAD_TEMPLATE:
      return Object.assign({}, state, {
        loading: true,
      });
    case REQUEST_UPLOAD_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        userId: action.userId,
      });
    case SET_UPLOAD_TEMPLATE_STAGE:
      return Object.assign({}, state, {
        stage: action.stage,
        importMessages: action.importMessages,
        hasImportMessages: action.importMessages && action.importMessages.length > 0,
        loading: action.stage === templateImportStages.uploading
          || action.stage === templateImportStages.passedScan,
      });
    case RESET_UPLOAD_TEMPLATE_STATE:
      return initialState;
    default:
      return state;
  }
}
