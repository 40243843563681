import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './ErrorLabel.styles';

export const constants = {
  textColor: {
    darkRed: 'dark red',
    pink: 'pink',
  },
};

class ErrorLabel extends React.PureComponent {
  getTextColor() {
    const { textColor, classes } = this.props;
    switch (textColor) {
      case constants.textColor.pink:
        return classes.pink;
      default:
        return classes.darkRed;
    }
  }

  render() {
    const {
      error,
      className,
      classes,
      verticalPadding,
      bold,
      preserveSpacing,
    } = this.props;

    const textColor = this.getTextColor();

    if (!error && !preserveSpacing) {
      return null;
    }

    return (
      <div className={`
          ${className}
          ${textColor}
          ${classes.root}
          ${verticalPadding ? classes.padding : ''}
          ${bold ? classes.bold : ''}
        `}
      >
        {error} {preserveSpacing && <Fragment>&nbsp;</Fragment>}
      </div>
    );
  }
}

ErrorLabel.defaultProps = {
  className: '',
  textColor: constants.textColor.darkRed,
  verticalPadding: false,
  bold: false,
  error: '',
  preserveSpacing: false,
};

ErrorLabel.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  textColor: PropTypes.oneOf(Object.values(constants.textColor)),
  verticalPadding: PropTypes.bool,
  bold: PropTypes.bool,
  preserveSpacing: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(ErrorLabel);
