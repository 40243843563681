const styles = theme => ({
  root: {
    position: 'relative',
    minHeight: 150,
  },
  wrapper: {
    height: 150,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  flex: {
    flexGrow: 1,
  },
  button: {
    minWidth: '10%',
    maxWidth: '50%',
  },
  description: {
    marginTop: 30,
    marginBottom: 8,
    fontSize: theme.constants.fontSizes.large,
    whiteSpace: 'pre',
  },
});

export default styles;
