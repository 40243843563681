export const importStages = {
  gettingStarted: 0,
  preparingData: 1,
  importingData: 2,
  importSummary: 3,
  applyingSupportingDocuments: 4,
  identifyingContras: 5,
  extractingKeywords: 6,
  savingKeywords: 7,
  runningRules: 8,
  gettingMachineLearningPredictions: 9,
  applyingMachineLearning: 10,
  externalReviewGettingStarted: 11,
  loadingData: 12,
  parsingData: 13,
  categorising: 14,
  error: 15,
  deleteSupportingDoc: 16,
};

export const importStageMessages = [
  {
    stageId: importStages.gettingStarted,
    stageName: 'Getting started',
    startMessage: 'Getting started...',
  },
  {
    stageId: importStages.preparingData,
    stageName: 'Preparing your data',
    startMessage: 'Preparing your data',
  },
  {
    stageId: importStages.importingData,
    stageName: 'Importing your data',
    startMessage: 'Importing your data',
  },
  {
    stageId: importStages.importSummary,
    stageName: 'Creating Import Summary',
    startMessage: 'Creating Import Summary',
  },
];

export const analysisStageMessages = [
  {
    stageId: importStages.extractingKeywords,
    stageName: 'Identifying keywords',
    startMessage: 'Identifying keywords 0%',
  },
  {
    stageId: importStages.savingKeywords,
    stageName: 'Saving keywords',
    startMessage: 'Saving Keywords 0%',
  },
  {
    stageId: importStages.runningRules,
    stageName: 'Running rules',
    startMessage: 'Categorizing data',
  },
  {
    stageId: importStages.gettingMachineLearningPredictions,
    stageName: 'Machine learning',
    startMessage: 'Getting machine learning predictions',
  },
];

export const externalReviewImportStageMessages = [
  {
    stageId: importStages.externalReviewGettingStarted,
    stageName: 'Getting started',
    startMessage: 'Getting started...',
  },
  {
    stageId: importStages.loadingData,
    stageName: 'Loading your data',
    startMessage: 'Loading your data',
  },
  {
    stageId: importStages.parsingData,
    stageName: 'Parsing your data',
    startMessage: 'Parsing your data',
  },
  {
    stageId: importStages.categorising,
    stageName: 'Categorising',
    startMessage: 'Categorising your data',
  },
];
