export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  overflow: {
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 4,
  },
  title: {
    marginTop: 20,
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.small,
  },
  margin: {
    marginTop: 10,
    marginBottom: 10,
  },
  tags: {
    flexGrow: 1,
  },
  addButton: {
    width: 90,
    marginTop: 30,
    marginBottom: 5,
  },
});
