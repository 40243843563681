export const styles = theme => ({
  root: {
    height: 80,
    paddingTop: 4,
    paddingBottom: 4,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
  },
  box: {
    height: 80,
  },
  complete: {
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  incomplete: {
    borderTopColor: theme.palette.primary.darkText,
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderBottomColor: theme.palette.primary.darkText,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  borderLeft: {
    borderLeftColor: theme.palette.primary.darkText,
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
  },
  borderLeftRadius: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  borderRight: {
    borderRightColor: theme.palette.primary.darkText,
    borderRightStyle: 'solid',
    borderRightWidth: 1,
  },
  borderRightRadius: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  hidden: {
    display: 'none',
  },
  icon: {
    borderRadius: 0,
  },
});
