import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { ADMIN_GET_USERS_URL } from 'Store/Api/ApiEndpoints';
import { Post } from 'Store/Api/CallApi';
import {
  GET_USERS,
  getUsersSuccess,
  getUsers,
} from 'Store/Areas/Setup/Admin/GetUserActions';
import { EDIT_NODE } from 'Store/Areas/Setup/Admin/AdminTreePickerActions';

/* Worker Sagas */
function* getUserList(action) {
  const response = yield Post(ADMIN_GET_USERS_URL, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getUsersSuccess(data.users));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* adminItemClick(action) {
  yield put(getUsers(action.editingItemLevel, action.editingItem.id, action.emailFilter));
}

/* Watcher Sagas */
export function* getUsersSagas() {
  yield takeLatest(GET_USERS, getUserList);
  yield takeLatest(EDIT_NODE, adminItemClick);
}
