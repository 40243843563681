import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { onMultiSelectionChange } from 'Helpers/SelectionHelpers';
import { getLineItems, resetLineItems } from 'Store/Areas/Categorisation/LineItemActions';
import { commonStrings } from 'Constants/CommonStrings';
import { sortOrder as sortOrders } from 'Constants/Categorization/QueryTermFilters';

/*
Example Usage
e.g. for ML uncategorised items
  export default withLineItems({
    categorizationType: categorizationTypes.uncategorized,
    includeMlPredictions: true,
    confidenceThreshold: 50,
    sortOrder: sortOrders.ascending,
    sortField: sortFields.confidence,
  })(Component)

Updating fields from props:
  componentDidMount() {
    this.setLineItemOptions({
      confidenceThreshold: this.props.confidence,
    })
  }
*/

export const sortFields = {
  amount: 0,
  confidence: 1,
  tag: 2,
  category: 3,
  rowId: 4,
};

const defaultOptions = {
  sortOrder: sortOrders.valueDescending,
  sortField: sortFields.amount,
  sortTagId: -1,
  ruleId: -1,
  matchedRuleIds: [],
  categoryId: -1,
  categorizationType: -1,
  confidenceThreshold: -1,
  includeMlPredictions: false,
  includeCategoryColumn: false,
  includeRuleColumn: false,
  includeContraFlagColumn: false,
  includeFlaggedColumn: false,
  showOnlyFlaggedLineItems: false,
  categoryType: 0,
  maxLineItemValue: null,
  minLineItemValue: null,
  debounceDelay: 0,
  scrollId: null,
  searchTerm: '',
  mapSortField: null,
  fileType: null,
  tagFilters: [],
};

const withLineItems = (options = defaultOptions) => (Component) => {
  options = { ...defaultOptions, ...options }; // eslint-disable-line
  class WithLineItems extends React.PureComponent {
    constructor(props) {
      super(props);

      this.noSelection = {
        activeRowId: null,
        selectedRows: [],
        selectedRowIds: [],
        selectedRowsTotalAmount: 0,
      };

      this.state = {
        sortOrder: options.sortOrder,
        sortField: options.sortField,
        sortTagId: options.sortTagId,
        ruleId: options.ruleId,
        categoryId: options.categoryId,
        categorizationType: options.categorizationType,
        confidenceThreshold: options.confidenceThreshold,
        includeMlPredictions: options.includeMlPredictions,
        includeCategoryColumn: options.includeCategoryColumn,
        maxLineItemValue: options.maxLineItemValue,
        minLineItemValue: options.minLineItemValue,
        debounceDelay: options.debounceDelay,
        searchTerm: options.searchTerm,
        showOnlyFlaggedLineItems: options.showOnlyFlaggedLineItems,
        categoryType: options.categoryType,
        tagFilters: options.tagFilters,
        descriptionColumnIndex: -1,
        amountColumnIndex: -1,
        confidenceColumnIndex: -1,
        categoryColumnIndex: -1,
        sortedColIndex: -1,
        hasColumnData: false,
        fileType: options.fileType,
        lineItemsToFilter: [],
        ...this.noSelection,
      };
    }

    componentDidUpdate() {
      const { lineItems } = this.props;
      const { includeMlPredictions, includeCategoryColumn, hasColumnData } = this.state;
      if (!hasColumnData) {
        const amountColumnIndex = lineItems.columns.findIndex(x => x === commonStrings.amount);
        const confidenceColumnIndex = includeMlPredictions ? 0 : -1;
        const categoryColumnIndex = includeCategoryColumn ? (includeMlPredictions ? 1 : 0) : -1;//eslint-disable-line
        const descriptionColumnIndex =
          lineItems.columns.findIndex(x => x === commonStrings.description);
        if (amountColumnIndex > 0 || descriptionColumnIndex > 0) {
          this.setState({ // eslint-disable-line
            hasColumnData: true,
            amountColumnIndex: amountColumnIndex,
            confidenceColumnIndex: confidenceColumnIndex,
            categoryColumnIndex: categoryColumnIndex,
            descriptionColumnIndex: descriptionColumnIndex,
            sortedColIndex:
              this.getSortColumn(amountColumnIndex, confidenceColumnIndex, categoryColumnIndex),
          });
        }
      }
    }

    componentWillUnmount() {
      this.props.dispatch(resetLineItems());
    }

    onSearch = () => {
      this.setOptions();
    }

    onSort = (colIndex) => {
      const {
        lineItems,

      } = this.props;
      const {
        includeMlPredictions,
        categoryColumnIndex,
      } = this.state;
      if (!includeMlPredictions && colIndex === categoryColumnIndex) {
        // disable sorting for the `Category` column
        // on the Manually Categorised line items review screen
        return;
      }
      const sortField = this.determineSortField(colIndex);
      this.setOptions({
        sortOrder: this.toggleSortOrder(sortField),
        sortField: sortField,
        sortTagId: (sortField === sortFields.tag) ? lineItems.columnTagIds[colIndex] : -1,
        sortedColIndex: colIndex,
      });
    }

    onBeginDrag = ({ lineItemId }) => {
      if (this.state.selectedRowIds.indexOf(lineItemId) === -1) {
        this.onRowSelectionChange(lineItemId, false, false);
      }
    }

    onEndDrag = () => {
      this.setState({
        ...this.noSelection,
      });
    }

    onRowSelectionChange = (rowId, ctrlKeyActive, shiftKeyActive) => {
      const resultantState = onMultiSelectionChange(
        this.props.lineItems.lineItems.filter(x =>
          this.state.lineItemsToFilter.indexOf(x.id) === -1),
        rowId,
        ctrlKeyActive,
        shiftKeyActive,
        this.state.selectedRowIds,
        this.state.activeRowId,
        r => r.id,
      );

      const newState = {
        selectedRows: resultantState.selectedItems,
        selectedRowIds: resultantState.selections,
        selectedRowsTotalAmount: resultantState.selectedItems.reduce((a, b) =>
          a + Number(b.columns.Amount.value), 0),
      };

      if (resultantState.activeSelection) {
        newState.activeRowId = resultantState.activeSelection;
      }

      this.setState(newState);
    }

    getSortColumn = (amountColumnIndex, confidenceColumnIndex, categoryColumnIndex) => {
      const { sortField } = this.state;
      switch (sortField) {
        case sortFields.amount:
          return amountColumnIndex;
        case sortFields.confidence:
          return confidenceColumnIndex;
        case sortFields.category:
          return categoryColumnIndex;
        default:
          return -1;
      }
    }

    setOptions = (newOptions) => {
      this.setState(
        {
          ...newOptions,
          ...this.noSelection,
        },
        () => {
          this.props.dispatch(resetLineItems());
          this.loadItems();
        },
      );
    }

    setSearchTerm = (searchTerm) => {
      this.setState({
        searchTerm: searchTerm,
      });
    }

    setTagFilters = (tagFilters) => {
      this.setState({
        tagFilters,
      });
    }

    setFilteredItems = (itemsToFilter) => {
      this.setState({
        lineItemsToFilter: itemsToFilter,
      });
    }

    toggleSortOrder = (colIndex) => {
      if (this.state.sortField === colIndex) {
        return this.state.sortOrder === sortOrders.valueAscending ?
          sortOrders.valueDescending : sortOrders.valueAscending;
      }

      return sortOrders.valueDescending;
    }

    resetSearch = () => {
      this.setOptions({
        searchTerm: '',
        tagFilters: [],
      });
    }

    determineSortField = (columnIndex) => {
      const {
        amountColumnIndex,
        confidenceColumnIndex,
        categoryColumnIndex,
      } = this.state;

      if (columnIndex === -1) {
        return sortFields.rowId;
      }

      switch (columnIndex) {
        case amountColumnIndex:
          return sortFields.amount;
        case categoryColumnIndex:
          return sortFields.category;
        case confidenceColumnIndex:
          return sortFields.confidence;
        default:
          return sortFields.tag;
      }
    }

    loadMoreItems = () => {
      this.loadItems(this.props.lineItems.scrollId);
    }

    loadItems = (scrollId) => {
      const { dispatch, periodId, lineItems } = this.props;
      const {
        sortOrder,
        sortField,
        ruleId,
        categoryId,
        categorizationType,
        confidenceThreshold,
        includeMlPredictions,
        includeCategoryColumn,
        includeRuleColumn,
        includeContraFlagColumn,
        includeFlaggedColumn,
        showOnlyFlaggedLineItems,
        categoryType,
        maxLineItemValue,
        minLineItemValue,
        debounceDelay,
        searchTerm,
        sortTagId,
        matchedRuleIds,
        fileType,
        tagFilters,
      } = this.state;

      dispatch(getLineItems({
        periodId,
        ruleId,
        categoryId,
        categorizationType,
        includeMlPredictions,
        includeCategoryColumn,
        includeRuleColumn,
        includeContraFlagColumn,
        includeFlaggedColumn,
        showOnlyFlaggedLineItems,
        categoryType,
        confidenceThreshold,
        sortOrder,
        sortField,
        sortTagId,
        maxLineItemValue,
        minLineItemValue,
        debounceDelay,
        scrollId,
        searchTerm,
        matchedRuleIds,
        fileType,
        tagFilters,
        contraShelfLineItems: lineItems.contraShelfLineItems,
      }));
    }

    render() {
      const {
        searchTerm,
        descriptionColumnIndex,
        amountColumnIndex,
        confidenceColumnIndex,
        categoryColumnIndex,
        sortedColIndex,
        sortOrder,
        sortField,
        sortTagId,
        selectedRows,
        selectedRowIds,
        selectedRowsTotalAmount,
      } = this.state;
      const { periodId, lineItems, ...rest } = this.props;

      const { lineItemsToFilter } = this.state;
      const filteredItems = lineItems.lineItems.filter(x => lineItemsToFilter.indexOf(x.id) === -1);
      return (
        <Component
          lineItemsLoadMore={this.loadMoreItems}
          lineItems={filteredItems}
          sortedColIndex={sortedColIndex}
          sortOrder={sortOrder}
          sortField={sortField}
          sortTagId={sortTagId}
          lineItemsHasMore={lineItems.hasMore}
          lineItemsLoading={lineItems.loading}
          lineItemsColumns={lineItems.columns}
          lineItemsColumnHeaders={lineItems.columnHeaders}
          totalLineItemCount={lineItems.totalLineItemCount}
          setLineItemOptions={this.setOptions}
          descriptionColumnIndex={descriptionColumnIndex}
          amountColumnIndex={amountColumnIndex}
          confidenceColumnIndex={confidenceColumnIndex}
          categoryColumnIndex={categoryColumnIndex}
          setSearchTerm={this.setSearchTerm}
          setTagFilters={this.setTagFilters}
          onSearch={this.onSearch}
          onSort={colIndex => this.onSort(colIndex)}
          resetSearch={this.resetSearch}
          searchTerm={searchTerm}
          setOptions={this.setOptions}
          setFilteredItems={this.setFilteredItems}
          selectedRows={selectedRows}
          selectedRowIds={selectedRowIds}
          selectedRowsTotalAmount={selectedRowsTotalAmount}
          onBeginDrag={this.onBeginDrag}
          onEndDrag={this.onEndDrag}
          onRowSelectionChange={this.onRowSelectionChange}
          isFilteredLineItems={lineItems.isFilteredLineItems}
          isSearchTermPresent={lineItems.isSearchTermPresent}
          contraShelfLineItems={lineItems.contraShelfLineItems}
          {...rest}
        />
      );
    }
  }

  WithLineItems.propTypes = {
    dispatch: PropTypes.func.isRequired,
    periodId: PropTypes.number.isRequired,
    lineItems: PropTypes.shape({
      lineItems: PropTypes.array.isRequired,
      columns: PropTypes.array.isRequired,
      columnHeaders: PropTypes.array.isRequired,
      columnTagIds: PropTypes.array.isRequired,
      loading: PropTypes.bool.isRequired,
      hasMore: PropTypes.bool.isRequired,
      scrollId: PropTypes.string,
      totalLineItemCount: PropTypes.number.isRequired,
    }).isRequired,
    columnTagIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  };

  function mapStateToProps(state) {
    return {
      periodId: state.periods.period.periodId,
      lineItems: state.categorisation.lineItems,
      columnTagIds: state.categorisation.lineItems.columnTagIds,
    };
  }

  return connect(mapStateToProps)(WithLineItems);
};

export default withLineItems;
