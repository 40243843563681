const styles = {
  robotContainer: {
    display: 'flex',
    margin: '0 auto',
  },
  robot: {
    margin: 'auto',
    padding: 20,
    minHeight: 80,
  },
};

export default styles;
