const styles = theme => ({
  deleteButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    marginLeft: 16,
    outline: 'none',
    zIndex: theme.zIndex.drawer - 2, // prevents click on div underneath
  },
  closeIcon: {
    height: 13,
    margin: 'auto',
  },
});

export default styles;
