import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { ADMIN_POST_TOGGLE_ACCESS_URL } from 'Store/Api/ApiEndpoints';
import { Post } from 'Store/Api/CallApi';
import {
  POST_TOGGLE_ACCESS,
  postToggleAccessSuccess,
} from 'Store/Areas/Setup/Admin/ToggleAccessActions';

/* Worker Sagas */
function* postToggleAccess(action) {
  const response = yield Post(ADMIN_POST_TOGGLE_ACCESS_URL, action.payload);
  if (response.ok) {
    const user = yield response.json();
    yield put(postToggleAccessSuccess(user));
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* toggleAccessSagas() {
  yield takeLatest(POST_TOGGLE_ACCESS, postToggleAccess);
}
