import { takeLatest, put } from 'redux-saga/effects';
import {
  GET_PREVIEW_ROWS,
  getPreviewRowsSuccess,
} from 'Store/Areas/Import/PreviewActions';
import { MAPPING_GET_PREVIEW_ROWS } from 'Store/Api/ApiEndpoints';
import { Get } from 'Store/Api/CallApi';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { updatePeriodStatus } from 'Store/Areas/Period/PeriodActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { strings } from 'Constants/Upload/Strings';


/* Worker Sagas */
function* getPreviewRowsSaga(action) {
  const response = yield Get(MAPPING_GET_PREVIEW_ROWS(action.periodId, action.fileType));
  if (response.ok) {
    const data = yield response.json();
    yield put(getPreviewRowsSuccess(data));
  } else {
    yield put(updatePeriodStatus(action.periodId, periodStatuses.notStarted));
    yield put(displayToastNotification(strings.problemWithFilesDescription));
  }
}

/* Watcher Sagas */
export function* previewSagas() {
  yield takeLatest(GET_PREVIEW_ROWS, getPreviewRowsSaga);
}
