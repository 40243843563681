const styles = theme => ({
  message: {
    fontSize: 30,
    textAlign: 'center',
    paddingBottom: 25,
  },
  recommendationContainer: {
    fontSize: theme.constants.fontSizes.regular,
    padding: 20,
  },
  recommendations: {
    paddingBottom: 10,
  },
});

export default styles;
