import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import colors from 'Style/BrandingChartColors';
import CheckableIcon from './CheckableIcon';
import styles from './ColorPicker.styles';

class ColorPicker extends React.PureComponent {
  handleChange(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    const { classes, value } = this.props;
    return (
      <RadioGroup
        row
        onChange={event => this.handleChange(event)}
        value={value}
        classes={{
          root: classes.root,
        }}
      >
        {Object.keys(colors).map((colorName) => {
          return (<Radio
            value={colors[colorName].color}
            key={colorName}
            icon={<CheckableIcon color={colorName} />}
            checkedIcon={<CheckableIcon color={colorName} selected />}
            className={classes.radio}
          />);
        })}
      </RadioGroup>
    );
  }
}

ColorPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
};

export default withStyles(styles)(ColorPicker);
