import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { styles } from './ContraRow.styles';

class ContraRow extends React.PureComponent {
  render() {
    const {
      classes,
      style,
      children,
    } = this.props;
    return (
      <div style={style} className={classes.root}>
        <div className={classes.whiteContainer}>
          {children}
        </div>
      </div>
    );
  }
}

ContraRow.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]).isRequired,
};

export default compose(withStyles(styles))(ContraRow);
