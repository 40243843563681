// This function takes a string and searches for all paris of quotes within that string.
// This is used in the chip Input component in order to separate each individual chip

export function pairTokenizer(str) {
  const regex = /".*?"/gm;

  let matched;
  const results = [];
  // eslint-disable-next-line no-cond-assign
  while ((matched = regex.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (matched.index === regex.lastIndex) {
      regex.lastIndex += 1;
    }

    // The result can be accessed through the `m`-variable.
    matched.forEach((match) => {
      results.push(match.substr(1, match.length - 2));
    });
  }

  return results;
}

export function isCaseInsensitiveEqual(a, b, ignoreLocale = false) {
  if (!ignoreLocale) {
    return a.toUpperCase() === b.toUpperCase();
  }

  return a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0;
}

export function replaceNewLines(str, replace = '') {
  return str.replace(/\r?\n|\r+/gm, replace);
}
