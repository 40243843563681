export const styles = theme => ({
  root: {
    fontSize: theme.constants.fontSizes.small,
    height: 40,
    marginBottom: 5,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    lineHeight: '30px',
    position: 'relative',
    marginRight: 55,
    cursor: 'not-allowed',
  },
  valid: {
    cursor: 'pointer',
  },
  error: {
    opacity: 0.5,
  },
  dragText: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  icon: {
    verticalAlign: 'sub',
  },
  dragLayer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
});
