import { put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { userActions } from 'Constants/UserActions';
import { routes } from 'Constants/Routes';
import { stopPolling } from 'Store/Areas/App/PeriodStatusPollingActions';
import { currentSignalrSideEffect } from 'Store/Areas/App/SignalrSideEffectsSelector';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { resetCreatePeriod } from 'Store/Areas/Projects/CreatePeriodActions';
import { resetSiteLoader } from 'Store/Areas/App/SiteLoaderActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { strings } from 'Constants/Export/Strings';
import { selectNode } from 'Store/Areas/Setup/Admin/AdminTreePickerActions';
import { levels } from 'Constants/EntityLevels';
import { resetSignalrSideEffects } from 'Store/Areas/App/SignalrSideEffectActions';
import SignalrStrategyHandler from './SignalrStrategyHandler';

export const archiveHandler = new SignalrStrategyHandler(
  userActions.Archive,
  function* handler(payload) {
    if (payload.error) {
      yield put(resetSignalrSideEffects());
      yield put(resetSiteLoader());
    } else {
      yield put(stopPolling());

      const signalrSideEffect = yield select(currentSignalrSideEffect);

      if (!signalrSideEffect ||
          signalrSideEffect.periodStatusTrigger !== periodStatuses.periodArchived) {
        yield put(push(routes.export));
      } else {
        yield put(getTreePickerData());
        yield put(resetCreatePeriod());
        yield put(resetSiteLoader());

        const { periodName } = signalrSideEffect.payload;
        yield put(displayToastNotification(strings.archiveCompleteNotificationBody(periodName)));

        yield put(selectNode(
          signalrSideEffect.payload.groupId,
          signalrSideEffect.payload.entityId,
          signalrSideEffect.payload.projectId,
          levels.project,
        ));

        yield put(resetSignalrSideEffects());
        yield put(push(routes.archive));
      }
    }
  },
);
