import React from 'react';
import PropTypes from 'prop-types';
import {
  withTheme,
  withStyles,
  IconButton,
  Grid,
  Paper,
} from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getCategoryRules, resetCategoryRules } from 'Store/Areas/Rule/CategoryRulesActions';
import { updateCategoryRuleFilters, resetCategoryRuleFilters } from 'Store/Areas/Rule/CategoryRulesFilterActions';
import { inspectRule } from 'Store/Areas/Rule/InspectRuleActions';
import ChevronRight from 'Assets/Images/chevron-right-blue-ic.svg';
import { rulePriorities } from 'Constants/Categorization/RulePriorities';
import { columns } from 'Constants/Categorization/CategoryReview/CategoryRuleListColumns';
import { strings } from 'Constants/Categorization/CategoryReview/CategoryReviewConstants';
import { sortOrder } from 'Constants/Categorization/QueryTermFilters';
import Search from 'Components/Shared/Inputs/Search';
import DataGrid from 'Components/Shared/DataGrid/DataGrid';
import SortableHeader from 'Components/Shared/DataGrid/Cells/SortableHeader/SortableHeader';
import SimpleHeader from 'Components/Shared/DataGrid/Cells/SimpleHeader/SimpleHeader';
import SimpleCell from 'Components/Shared/DataGrid/Cells/SimpleCell/SimpleCell';
import styles from './CategoryReviewRuleList.styles';

class CategoryReviewRuleList extends React.PureComponent {
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetCategoryRules());
    dispatch(resetCategoryRuleFilters());
  }

  constructor(props) {
    super(props);

    this.state = {
      ruleSearchTerm: '',
    };
  }

  onSortIconClicked(colIndex) {
    const {
      dispatch,
      categoryRulesFilter,
      periodId,
      category,
    } = this.props;

    dispatch(updateCategoryRuleFilters(
      periodId,
      category.id,
      colIndex,
      this.toggleSortOrder(colIndex),
      categoryRulesFilter.tagFilter,
      categoryRulesFilter.searchTerm,
    ));
  }

  getCellValue = ({ rowIndex, columnIndex }) => {
    const {
      categoryRules,
      classes,
    } = this.props;

    if (columnIndex === 6) {
      const rule = categoryRules.rules[rowIndex];
      return (
        <IconButton color="inherit" className={classes.imgButton} onClick={() => this.showInspectRuleOverlay(rule)} aria-label="Inspect">
          <img alt="Inspect Rule" src={ChevronRight} />
        </IconButton>
      );
    }

    const { propertyName } = columns[columnIndex];

    const cell = categoryRules.rules[rowIndex][propertyName];

    if (cell === undefined || cell === null) {
      return '';
    }

    if (propertyName === 'priority') {
      return rulePriorities.find(x => x.value === cell).label;
    }
    return cell;
  }

  getWidth = ({ index }) => {
    const { width } = this.props;
    const ruleColumnWidth = width - 70 - (5 * 150);
    switch (index) {
      case 0:
        return Math.max(ruleColumnWidth, 400);
      case 6:
        return 70;
      default:
        return 150;
    }
  }

  showInspectRuleOverlay(rule) {
    const { dispatch } = this.props;
    dispatch(inspectRule(rule));
  }

  toggleSortOrder(colIndex) {
    const { categoryRulesFilter } = this.props;

    if (categoryRulesFilter.sortColumn === colIndex) {
      return categoryRulesFilter.sortOrder === sortOrder.valueAscending ?
        sortOrder.valueDescending : sortOrder.valueAscending;
    }

    return sortOrder.valueDescending;
  }

  loadRules = () => {
    const {
      dispatch,
      categoryRulesFilter,
      periodId,
      category,
      categoryRules,
    } = this.props;
    dispatch(getCategoryRules(
      periodId,
      category.id,
      categoryRules.pageNumber + 1,
      categoryRulesFilter.sortColumn,
      categoryRulesFilter.sortOrder,
      categoryRulesFilter.tagFilter,
      this.state.ruleSearchTerm,
    ));
  }

  setSearchTerm = (value) => {
    this.setState({
      ruleSearchTerm: value,
    });
  }

  resetSearch = () => {
    this.setState(
      {
        ruleSearchTerm: '',
      },
      () => {
        this.onSearch();
      },
    );
  }

  onSearch = () => {
    const {
      dispatch,
      categoryRulesFilter,
      periodId,
      category,
    } = this.props;
    dispatch(getCategoryRules(
      periodId,
      category.id,
      1,
      categoryRulesFilter.sortColumn,
      categoryRulesFilter.sortOrder,
      categoryRulesFilter.tagFilter,
      this.state.ruleSearchTerm,
    ));
  }

  render() {
    const {
      classes,
      currencySymbol,
      categoryRules,
      categoryRulesFilter,
      width,
    } = this.props;

    return (
      <Grid>
        <Paper className={classes.searchContainer}>
          <Search
            id={strings.searchCategoryRules}
            className={classes.searchBox}
            label={strings.searchCategoryRules}
            placeholder={strings.searchPlaceholder}
            onChange={this.setSearchTerm}
            onReset={this.resetSearch}
            onSearch={this.onSearch}
            value={this.state.ruleSearchTerm}
          />
        </Paper>
        <DataGrid
          recomputeGridSizeKey={`${width}`}
          columns={columns.map(x => x.displayText)}
          rows={categoryRules.rules}
          getCellValue={this.getCellValue}
          hasNextPage={categoryRules.morePagesAvailable}
          isNextPageLoading={categoryRules.loading}
          loadNextPage={this.loadRules}
          getWidth={this.getWidth}
          rowMargin={10}
          initialLoad
          childRowHeight={50}
          headerRowHeight={50}
          sortedByKey={`${categoryRulesFilter.sortOrder}_${categoryRulesFilter.sortColumn}`}
          fixedHeight={315}
          fixedWidth={width}
          headerClass={classes.headerClass}
          bodyClass={classes.bodyClass}
          noDataMessage={this.state.ruleSearchTerm === '' ? strings.noData : strings.noSearchResultData}
          renderHeaderCell={(props) => {
            if (props.index === 6) {
              return (
                <SimpleHeader
                  padding={10}
                  padLeft
                  padRight
                  {...props}
                />
              );
            }
            return (
              <SortableHeader
                sortOrder={categoryRulesFilter.sortOrder}
                isSorting={categoryRulesFilter.sortColumn === props.index}
                padding={10}
                padLeft
                padRight
                onClick={index => this.onSortIconClicked(index)}
                {...props}
              />
            );
          }}
          renderRow={({
            style,
            children,
            key,
            index,
          }) => (
            <div className={`${classes.tableCell}`} style={{ ...style }} title={categoryRules.rules[index].keywordRule} key={key}>{children}</div>
          )}
          renderCell={props => (
            <SimpleCell
              {...props}
              currencySymbol={currencySymbol}
              padLeft
              padRight
              padding={10}
              formatAsCurrency={props.index === 3}
              formatAsPercentage={props.index === 5}
              className={`${props.index === 0 ? classes.ruleConditionCell : ''}`}
              childrenAfter={props.index === 0 && typeof props.children === 'string' &&
                <With
                  categorisationReason={categoryRules.rules
                    .filter(x => x.keywordRule === props.children)[0]
                    .notes}
                >
                  <If condition={!!categorisationReason}>
                    <div
                      className={classes.ruleNote}
                      title={strings.categorisationReason(categorisationReason)}
                    />
                  </If>
                </With>
              }
            />
          )}
        />
      </Grid>
    );
  }
}

CategoryReviewRuleList.defaultProps = {
  inspectedRule: {},
};

CategoryReviewRuleList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  category: PropTypes.shape({
    id: PropTypes.number,
    colorCode: PropTypes.string,
    name: PropTypes.string,
    amountValue: PropTypes.number,
  }).isRequired,
  periodId: PropTypes.number.isRequired,
  categoryRules: PropTypes.shape({
    name: PropTypes.string,
    rules: PropTypes.arrayOf(PropTypes.shape({
      keywordRule: PropTypes.string,
      addedBy: PropTypes.string,
      dateCreated: PropTypes.string,
      value: PropTypes.number,
      priority: PropTypes.number,
      notes: PropTypes.string,
    })),
    morePagesAvailable: PropTypes.bool,
    loading: PropTypes.bool,
    totalSum: PropTypes.number,
    currentPageNumber: PropTypes.number,
  }).isRequired,
  categoryRulesFilter: PropTypes.shape({
    sortColumn: PropTypes.number.isRequired,
    sortOrder: PropTypes.number.isRequired,
    tagFilter: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired,
  }).isRequired,
  inspectedRule: PropTypes.shape({
    notes: PropTypes.string,
  }),
  isApportionable: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: state.periods.period.periodId,
    category: state.categorisation.reviewCategory.category,
    categoryRules: state.rules.categoryRules,
    categoryRulesFilter: state.rules.categoryRulesFilter,
    inspectedRule: state.rules.inspectRule.inspectedRule,
    isApportionable: state.categorisation.reviewCategory.isApportionable,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(CategoryReviewRuleList);
