import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import { strings } from 'Constants/ModelManagement/Strings';
import { commonStrings } from 'Constants/CommonStrings';

class DeleteModelModal extends React.PureComponent {
  render() {
    const {
      onConfirm, onCancel, isModalOpen,
    } = this.props;

    return (
      <ConfirmationModal
        open={isModalOpen}
        title={commonStrings.delete}
        message={strings.deleteModel}
        acceptText={commonStrings.delete}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
      />
    );
  }
}

DeleteModelModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

export default (DeleteModelModal);
