const styles = theme => ({
  headerText: {
    fontSize: theme.constants.fontSizes.small,
    color: theme.palette.primary.darkText,
  },
  headerTagDrop: {
    height: '30px',
    margin: '10px 0 0 0',
  },
  closeIcon: {
    height: 13,
    width: 13,
  },
  closeButton: {
    width: 'auto',
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  headerTagDropWithTag: {
    borderRadius: '2px',
    border: 'solid 1px #0091da',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 5px 0 10px',
  },
  headerTagType: {
    height: '30px',
    borderRadius: '5px',
    backgroundColor: '#f4f4f4',
    margin: '20px 0 30px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
