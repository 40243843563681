export const styles = theme => ({
  root: {
    padding: 20,
    paddingTop: 0,
  },
  paper: {
    marginTop: 10,
  },
  headerContainer: {
    backgroundColor: theme.palette.colors.white,
    padding: 20,
  },
  contentContainer: {
    backgroundColor: theme.palette.colors.grey,
    padding: 20,
  },
  title: {
    fontSize: theme.constants.fontSizes.large,
    color: theme.palette.primary.darkText,
  },
  largeDescription: {
    fontSize: theme.constants.fontSizes.large,
    color: theme.palette.primary.darkText,
  },
  regularDescription: {
    fontSize: theme.constants.fontSizes.regular,
    color: theme.palette.primary.darkText,
  },
  bold: {
    fontWeight: 'bold',
  },
  paddingBottom: {
    paddingBottom: 10,
  },
  paddingTop: {
    paddingTop: 10,
  },
});
