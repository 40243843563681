import React from 'react';
import Modal from 'Components/Shared/Modal/StyledModal';
import PropTypes from 'prop-types';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { Formik } from 'formik';
import WrappedInput from 'Components/Shared/Inputs/Input';
import Select from 'Components/Shared/Selects/Select';
import { strings } from 'Constants/Upload/TagMapping';
import { getAddTagSchema } from 'Constants/Upload/AddTagSchema';
import { tagTypeLabel, TagTypes } from 'Constants/Categorization/Tags';
import styles from './AddTagModal.styles';

class AddTagModal extends React.PureComponent {
  render() {
    const {
      classes,
      open,
      onCancel,
      loading,
      existingTags,
      onAccept,
    } = this.props;

    return (
      <Modal open={open} className={classes.root} isSubmitting={loading}>
        <Formik
          initialValues={{
            newTagName: '',
            dataType: '',
          }}
          validationSchema={getAddTagSchema(existingTags, Object.values(TagTypes))}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            onAccept(values.newTagName, values.dataType);
          }}
          render={({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            touched,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.content}>
                <div className={classes.title}>{strings.addTagModalTitle}</div>
                <div className={classes.description}>{strings.addTagModalText}</div>
                <WrappedInput
                  id="newTagName"
                  placeholder="Enter tag name"
                  value={values.newTagName}
                  onChange={(field, value) => {
                    setFieldValue(field, value);
                    setFieldTouched(field, true);
                  }}
                  preserveSpacing
                  error={touched.newTagName ? errors.newTagName : ''}
                  touched={touched.newTagName}
                />
                <Select
                  id="dataType"
                  label="Data type"
                  value={values.dataType}
                  error={touched.dataType ? errors.dataType : ''}
                  data={Object.values(TagTypes).map(v => ({ id: v, name: tagTypeLabel(v) }))}
                  colorScheme="lightBlue"
                  onChange={(value) => {
                    setFieldTouched('dataType', true);
                    setFieldValue('dataType', value);
                  }}
                  touched={touched.dataType}
                />
              </div>
              <Buttons
                isSubmitting={loading}
                onAccept={handleSubmit}
                onCancel={onCancel}
                acceptText={strings.addTagModalAccept}
                cancelText={strings.addTagModalCancel}
              />
            </form>
            )}
        />
      </Modal>
    );
  }
}

AddTagModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  existingTags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(AddTagModal);
