import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { strings } from 'Constants/Upload/Strings';
import { toggleCorruptedFileDetectedModal } from 'Store/Areas/FileUpload/FileUploadActions';
import CorruptedFileModal from 'Components/Shared/Modal/ErrorModal';
import { xlsxOnly, xlsFileTypes, acceptedSpreadsheetTypes } from 'Constants/FileTypes';

const withCorruptedFileProvider = () => (Component) => {
  class WithCorruptedFileProvider extends React.PureComponent {
    toggleCorruptedFileDetectedModal(active) {
      this.props.dispatch(toggleCorruptedFileDetectedModal({ showModal: active }));
    }

    fileTypeErrorMessage = (fileTypes) => {
      switch (fileTypes) {
        case xlsxOnly:
          return strings.invalidFormatXlsxOnlyModalDescription;
        case xlsFileTypes:
          return strings.invalidFormatAllXlsFileTypeModalDescription;
        case acceptedSpreadsheetTypes:
          return strings.invalidFormatModalDescription;
        default:
          return strings.invalidFormatModalDescription;
      }
    }

    render() {
      const { fileUpload } = this.props;

      return (
        <Fragment>
          <Component {...this.props} />
          <CorruptedFileModal
            open={fileUpload.showCorruptedFileModal}
            onClose={() => this.toggleCorruptedFileDetectedModal()}
            title={fileUpload.fileContainsVirus ?
              strings.virusFileModalCaption : strings.corruptedFileModalCaption}
            error={fileUpload.fileContainsVirus ?
              strings.virusFileModalDescription :
              this.fileTypeErrorMessage(fileUpload.fileTypes)}
          />
        </Fragment>
      );
    }
  }

  WithCorruptedFileProvider.propTypes = {
    dispatch: PropTypes.func.isRequired,
    fileUpload: PropTypes.shape({
      uploadResponse: PropTypes.object,
      showCorruptedFileModal: PropTypes.bool,
      checkingForViruses: PropTypes.bool,
      fileContainsVirus: PropTypes.bool,
      fileTypes: PropTypes.string,
    }).isRequired,
  };

  function mapStateToProps(state) {
    return {
      fileUpload: state.app.fileUpload,
    };
  }

  return connect(mapStateToProps)(WithCorruptedFileProvider);
};

export default withCorruptedFileProvider;
