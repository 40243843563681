const styles = theme => ({
  root: {
    padding: 20,
    paddingTop: 0,
  },
  titleContainer: {
    display: 'flex',
    marginBottom: 10,
  },
  title: {
    flexGrow: 1,
    fontSize: theme.constants.fontSizes.large,
    color: theme.palette.primary.darkText,
  },
  description: {
    fontSize: theme.constants.fontSizes.regular,
    color: theme.palette.primary.darkText,
  },
  card: {
    padding: 40,
  },
  button: {
    minWidth: 140,
    marginTop: 30,
    marginRight: 10,
    marginBottom: 10,
  },
  message: {
    backgroundColor: '#f4f4f4',
    color: '#666666',
  },
  messageContent: {
    padding: 20,
  },
});

export default styles;
