import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Grid, Button } from '@material-ui/core';
import { compose } from 'recompose';
import { Formik } from 'formik';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import KPMGLogo from 'Assets/Images/logo-white.svg';
import Yup from 'yup';
import WrappedInput from 'Components/Shared/Inputs/Input';
import { strings } from 'Constants/Register/Strings';
import { checkExternalUserExists, AddExternalUser, setToDefaultState } from 'Store/Areas/Register/RegisterActions';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import { styles } from './Register.styles';

class Register extends React.PureComponent {
  componentDidMount() {
    const { dispatch, location } = this.props;
    if (location && location.search) {
      dispatch(checkExternalUserExists(location.search.substring(7)));
    }
  }

  handlingSubmit = (firstName, lastName, emailAddress) => {
    this.props.dispatch(AddExternalUser(firstName, lastName, emailAddress));
  }

  onRedirectHome = () => {
    this.props.dispatch(setToDefaultState());
    this.props.dispatch(push(routes.home));
  }

  render() {
    const {
      classes,
      loading,
      location,
      showMessage,
      existsUser,
      addUserSuccess,
      supportEmail,
    } = this.props;

    if (existsUser === (0 || 402)) {
      this.onRedirectHome();
    }
    const emailAddress = location.search.substring(7);
    return (
      <div>
        <Choose>
          <When condition={loading}>
            <div className={classes.loader}>
              <CircularLoader />
            </div>
          </When>
          <Otherwise>
            <Grid container direction="row" className={classes.grid}>
              <Grid item xs={6} className={classes.gridWithLogo}>
                <div className="col-lg-6 d-flex flex-column">
                  <img alt="brand" className={classes.logo} src={KPMGLogo} />
                  <div className={classes.signUpText}>{strings.welcomeMessage}</div>
                </div>
              </Grid>
              <Grid item xs={6} className={classes.gridlogginInArea}>
                <Choose>
                  <When condition={showMessage || (existsUser === 401)}>
                    <Grid container direction="column">
                      <Grid>
                        <Choose>
                          <When condition={existsUser === 401}>
                            <div className={classes.messageText}>
                              {strings.userMigrationPeriodOver}
                            </div>
                          </When>
                          <When condition={addUserSuccess}>
                            <div className={classes.messageText}>
                              {strings.userMigratedSuccessMessage}
                            </div>
                          </When>
                          <Otherwise>
                            <div className={classes.messageText}>
                              {strings.userMigratedFailureMessage(supportEmail)}
                            </div>
                          </Otherwise>
                        </Choose>
                      </Grid>
                      <Grid>
                        <Choose>
                          <When condition={existsUser !== 401}>
                            <Button
                              disableRipple
                              className={`${classes.acceptButton} ${classes.button}`}
                              onClick={this.onRedirectHome}
                              color="primary"
                              type="submit"
                            >
                              {addUserSuccess ? strings.loginButtonText : strings.retryButtonText}
                            </Button>
                          </When>
                        </Choose>
                      </Grid>
                    </Grid>
                  </When>
                  <Otherwise>
                    <Formik
                      initialValues={{
                        firstname: '',
                        lastname: '',
                        email: emailAddress,
                      }}
                      validationSchema={Yup.object().shape({
                        firstname: Yup.string()
                          .required('First Name is required'),
                        lastname: Yup.string()
                          .required('Last Name is required'),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        this.handlingSubmit(values.firstname, values.lastname, emailAddress);
                      }}
                      render={({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        setFieldValue,
                        isValid,
                      }) => (
                        <form className={classes.form} onSubmit={handleSubmit}>
                          <div className={classes.content}>
                            <div className={classes.title}>{'Please enter the user\'s first name'}</div>
                            <WrappedInput
                              id="firstname"
                              placeholder="First Name"
                              value={values.firstname}
                              onChange={(field, value) => setFieldValue(field, value)}
                              touched={touched.firstname}
                              preserveSpacing
                              error={errors.firstname}
                            />
                          </div>
                          <div className={classes.content}>
                            <div className={classes.title}>{'Please enter the user\'s last name'}</div>
                            <WrappedInput
                              id="lastname"
                              placeholder="Last Name"
                              value={values.lastname}
                              onChange={(field, value) => setFieldValue(field, value)}
                              touched={touched.lastname}
                              preserveSpacing
                              error={errors.lastname}
                            />
                          </div>
                          <div className={classes.content}>
                            <div className={classes.title}>{'User\'s email address'}</div>
                            <WrappedInput
                              id="email"
                              placeholder={emailAddress}
                              value={values.email}
                              onChange={() => {}}
                              disabled
                              touched={touched.email}
                              preserveSpacing
                              error={errors.email}
                            />
                          </div>
                          <Buttons
                            submitDisabled={!isValid && !touched.error}
                            buttonClassName={classes.buttonSpace}
                            isSubmitting={loading}
                            onAccept={handleSubmit}
                            onCancel={this.onRedirectHome}
                            acceptText="Submit"
                            cancelText="Cancel"
                          />
                        </form>
                      )}
                    />
                  </Otherwise>
                </Choose>
              </Grid>
            </Grid>
            <div>
              <footer className={classes.footerCard}>
                <div className={`${classes.footerLinks} ${classes.footerText}`}>
                  <span className={`${classes.textLink} ${classes.footerText}`}>
                    <a
                      align="right"
                      href="https://home.kpmg.com/uk/en/home/misc/legal.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                        Legal
                    </a>
                  </span>
                  <span className={classes.textLink}>
                    <a
                      href="https://kpmg.com/uk/en/home/misc/privacy.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                        Privacy
                    </a>
                  </span>
                </div>
                <div className={classes.footerText}>{strings.footerText}</div>
              </footer>
            </div>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

Register.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.objectOf(PropTypes.string).isRequired,
  addUserSuccess: PropTypes.bool.isRequired,
  showMessage: PropTypes.bool.isRequired,
  existsUser: PropTypes.number,
  supportEmail: PropTypes.string.isRequired,
};

Register.defaultProps = {
  existsUser: 0,
};

function mapStateToProps(state) {
  return {
    loading: state.register.loading,
    addUserSuccess: state.register.addUserSuccess,
    showMessage: state.register.showMessage,
    existsUser: state.register.existsUser,
    supportEmail: state.register.supportEmail,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(Register);