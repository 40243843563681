import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTheme, withStyles } from '@material-ui/core';
import { dismissWorkInProgressWarning } from 'Store/Areas/App/MenuModeActions';
import StyledModal from 'Components/Shared/Modal/StyledModal';
import StyledModalButtons from 'Components/Shared/Modal/StyledModal.Buttons';
import { commonStrings } from 'Constants/CommonStrings';
import { strings } from 'Constants/App/WorkInProgress/Strings';
import { getAbsoluteUrlForRoute, getAdminToggleRoute } from 'Helpers/RouteHelpers';
import styles from './WorkInProgress.styles';

class WorkInProgress extends React.PureComponent {
  onCancel = () => {
    this.props.dispatch(dismissWorkInProgressWarning());
  }

  onContinue = () => {
    const { isAdminMode } = this.props;
    window.location.href = getAbsoluteUrlForRoute(getAdminToggleRoute(!isAdminMode));
  }

  render() {
    const { classes } = this.props;
    const { workInProgress, userHasDismissedWarning, menuModeToggled } = this.props.menuMode;
    return (
      <StyledModal
        open={workInProgress && menuModeToggled && !userHasDismissedWarning}
        className={classes.root}
      >
        <div className={classes.content}>
          <div className={classes.title}>{strings.workInProgressTitle}</div>
          <p>{strings.workInProgressBodyLine1}</p>
          <p>{strings.workInProgressBodyLine2}</p>
        </div>
        <StyledModalButtons
          onAccept={this.onContinue}
          onCancel={this.onCancel}
          acceptText={commonStrings.yes}
          cancelText={commonStrings.no}
        />
      </StyledModal>
    );
  }
}

WorkInProgress.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  menuMode: PropTypes.shape({
    workInProgress: PropTypes.bool.isRequired,
    userHasDismissedWarning: PropTypes.bool.isRequired,
    menuModeToggled: PropTypes.bool.isRequired,
    returnRoute: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  route: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  isAdminMode: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    menuMode: state.app.menuMode,
    route: state.router,
  };
}

export default compose(
  withTheme(),
  withStyles(styles),
  connect(mapStateToProps),
)(WorkInProgress);
