export const styles = theme => ({
  root: {
    padding: 20,
    margin: '10px 20px 20px 20px',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 155px)',
  },
  title: {
    fontSize: theme.constants.fontSizes.large,
    margin: '20px 20px 0px 20px',
  },
  description: {
    fontSize: theme.constants.fontSizes.regular,
  },
  noteBold: {
    fontWeight: 'bold',
    color: '#ff0000',
  },
  conflictTable: {
    flexGrow: 1,
    minHeight: 150,
    paddingTop: 30,
    paddingLeft: 10,
  },
  rulesContainer: {
    paddingRight: 40,
    marginTop: 10,
  },
  button: {
    width: 180,
    marginTop: 30,
    marginRight: 15,
  },
  disabledButton: {
    width: 180,
    marginTop: 30,
    marginRight: 15,
    opacity: 0.6,
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioGroup: {
    width: '100%',
  },
  showError: {
    display: 'block',
  },
  hideError: {
    display: 'none',
  },
  rule: {
    paddingRight: 150,
    fontSize: theme.constants.fontSizes.small,
    flexGrow: 1,
    color: theme.palette.primary.darkText,
  },
  text: {
    height: 14,
    fontWeight: 'normal',
    fontSize: 12,
  },
  red: {
    color: theme.palette.primary.error,
  },
  marginTop: {
    marginTop: 10,
  },
  increase: {
    color: theme.palette.colors.black,
  },
  decrease: {
    color: theme.palette.colors.blue,
  },
});