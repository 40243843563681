export const strings = {
  dragAndDropDescription: 'Drag & drop a file here or',
  dragAndDropDescriptionTemplate: 'Drag & drop a file(s) here or',
  uploadReportDescription: 'Drag & drop a custom report template here\nor',
  uploadReferenceDescription: 'Drag & drop a reference list\nor',
  reuploadDescription: 'Drag & drop data file(s) here or',
  uploadButtonDescription: 'Select a file on the hard drive',
  templateUploadButtonDescription: 'Select a file',
  reuploadButtonDescription: 'Select a different file',
  uploadSuccess: ' was uploaded successfully',
  uploadMessagePt1: 'Your files were uploaded successfully.',
  uploadMessagePt2: 'You can now proceed to categorisation and start processing the uploaded files. Please note that once this has taken place you will not be able to make amendments to your files. If you wish to make changes, please do so now.',
  tagSectionTitle:
    'Please ensure the column headers in the uploaded file match the following tags:',
  noTagsSpecified: 'No tags have been specified.',
  processButton: 'Process the file',
  uploadError: 'File upload error',
  proceedToMapping: 'Proceed to mapping',
  proceedToLineItemMapping: 'Proceed to Line Item mapping',
  processFileButton: 'Process file(s)',
  upload: 'Upload',
  mappingMain: 'Tag mapping - main',
  mappingMainTitle: 'Main document tag mapping',
  mappingMainDescription: 'Please map the main document data by dragging the appropriate tag to important columns. Columns with datatypes ' +
    'that do not match a tag will be unavailable when dragging. All mandatory tags must be mapped in order to proceed. We have provided a ' +
    'preview of the first 100 lines of every column so you can check you have the correct data. Mapping will be stored and automatically ' +
    'applied in future periods. All columns within your uploaded file will be imported, but the contents of any columns not mapped will not ' +
    'be available for the purposes of writing categorisation rules.',
  mappingSupporting: 'Tag mapping - supporting',
  mappingSupportingTitle: 'Supporting document tag mapping',
  mappingSupportingDescription: 'Please map the main document data by dragging the appropriate tag to important columns. Columns with datatypes ' +
    'that do not match a tag will be unavailable when dragging. All mandatory tags must be mapped in order to proceed. We have provided a ' +
    'preview of the first 100 lines of every column so you can check you have the correct data. Mapping will be stored and automatically ' +
    'applied in future periods. All columns within your uploaded file will be imported, but the contents of any columns not mapped will not ' +
    'be available for the purposes of writing categorisation rules.',
  review: 'Review',
  contras: 'Contras',
  failedRules: 'Failed Rules',
  machineLearning: 'Machine learning',
  periodImportDetails: 'Import Summary',
  supportingDocuments: 'Line item mapping',
  deleteFileConfirmation: 'Are you sure you want to delete file',
  deleteSupportingDocConfirmation: fileName => `Are you sure you want to delete '${fileName}' ? This will delete all metadata related to this supporting document. This cannot be changed later.`,
  deleteFileConfirmationTitle: 'Delete file',
  deleteSupportingDocConfirmationTitle: 'Delete Supporting Doc',
  virusFileModalCaption: 'File upload error',
  corruptedFileModalCaption: 'File format error',
  corruptedFileModalDescription:
    'Please ensure the spreadsheet is using the default font (Calibri) and font size (11).',
  virusFileModalDescription:
    'Virus detected within uploaded file. Please take the necessary actions and upload a different file.',
  invalidFormatModalDescription:
    'Please ensure you are uploading a file in the format of XLS, XLSX, XLSM, CSV',
  invalidFormatXlsxOnlyModalDescription:
    'Please ensure you are uploading a file in the format of XLSX',
  invalidFormatAllXlsFileTypeModalDescription:
    'Please ensure you are uploading a file in the format of XLS, XLSX, XLSM.',
  virusMessage: 'Scanning and uploading your file...',
  invalidFilesModalTitle: 'Invalid files',
  invalidFilesModalDescription:
    'There is a problem with some of your files. Please re-upload your files and follow the on-screen instructions to rectify any issues.',
  didYouKnowLine1: 'Files designated as Main will be categorised according to the rules created in this period. Supporting files can be used to provide a breakdown of specific line items in your Main data, and each one must be mapped to one or more line items in your Main data.',
  didYouKnowLine2: 'You can upload multiple Main and Supporting data files, but each Main file must have the same schema (layout), as must each Supporting file.',
  schemaMappingErrorModalTitle: 'Invalid schema',
  schemaMappingErrorDescriptionMain: 'This file does not match the main file schema. Please upload a file with the same schema.',
  schemaMappingErrorDescriptionSupporting: 'This file does not match the supporting file schema. Please upload a file with the same schema.',
  noFileTypeSelectedModalTitle: 'No File Type Selected',
  noFileTypeSelectedModalDescription: 'Please select a file type for every file to continue.',
  noMainModalTitle: 'No Main File Selected',
  noMainModalDescription: 'Please assign a main file in order to proceed.',
  problemWithFilesDescription: 'There was a problem with one or more files.',
};
