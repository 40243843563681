const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    lineHeight: '34px',
    height: '100vh',
    overflow: 'none',
  },
  welcome: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.darkText,
    fontSize: 32,
  },
  message: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.darkText,
    fontSize: 16,
  },
});

export default styles;
