import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { ADMIN_POST_USER_URL } from 'Store/Api/ApiEndpoints';
import { Post } from 'Store/Api/CallApi';
import {
  POST_NEW_USER,
  postNewUserSuccess,
  postNewUserFailure,
  postNewUserConfirm,
} from 'Store/Areas/Setup/Admin/AddUserActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { getAdminTreePickerData } from 'Store/Areas/Setup/Admin/AdminTreePickerActions';
import { getUserPermissions } from 'Store/Areas/User/PermissionsActions';
import { responseStatusCodes } from 'Store/Api/StatusCodes';
import { strings } from 'Constants/Error/Strings';

/* Worker Sagas */
function* postNewUser(action) {
  const response = yield Post(ADMIN_POST_USER_URL, action.payload);
  if (response.ok) {
    const user = yield response.json();
    yield put(postNewUserSuccess(user.addedItem));
    yield put(displayToastNotification(`Successfully added ${user.addedItem.emailAddress}.`));
    if (action.addingSelf) {
      yield put(getUserPermissions());
      yield put(getAdminTreePickerData());
    }
  } else if (response.status === responseStatusCodes.badRequest) {
    const data = yield response.json();
    if (data.error === strings.addNewUserErrorMsg) {
      yield put(postNewUserConfirm(data.error));
    } else {
      yield put(postNewUserFailure(data.error));
    }
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* addUsersSagas() {
  yield takeLatest(POST_NEW_USER, postNewUser);
}
