const styles = theme => ({
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 15,
    marginTop: 15,
  },
  makeChangeButton: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.constants.fontSizes.regular,
    cursor: 'pointer',
    fontWeight: 'bold',
    border: 'none',
    width: '100%',
    marginTop: '-30px',
  },
  importedDocs: {
    height: '70px',
    marginBottom: '20px !important',
  },
  summary: {
    height: '70px',
  },
  summaryDescription: {
    fontSize: theme.constants.fontSizes.large,
    fontWeight: 'bold',
  },
  summaryItems: {
    backgroundColor: theme.palette.primary.messageBackground,
  },
  summaryItemDescription: {
    fontSize: theme.constants.fontSizes.regular,
  },
  summaryItemPosition: {
    right: '0% !important',
  },
  commonPaddingAndMargin: {
    padding: '20px 20px 30px 20px',
    margin: '0 20px 0 20px',
  },
  commonDescription: {
    lineHeight: 1.5,
    marginRight: 10,
    position: 'relative',
    right: '33%',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  headerText: {
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.regular,
  },
  tableCell: {
    padding: '0 50px 0 0',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:first-child': {
      width: '100%',
    },
    '&:last-child': {
      width: '100%',
    },
    overflow: 'hidden',
    minWidth: 150,
    maxWidth: 300,
  },
});

export default styles;
