import React from 'react';
import PropTypes from 'prop-types';

class ExpansionPanelManager extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      active: props.defaultExpandedIndex,
    };
  }

  onExpand = (index) => {
    this.setState({
      active: this.state.active.indexOf(index) !== -1 ?
        this.state.active.filter((ind) => {
          return ind !== index;
        }) : [...this.state.active, index],
    });
  }

  render() {
    const {
      children,
    } = this.props;
    const {
      active,
    } = this.state;
    return (
      children({
        active: active,
        onExpand: this.onExpand,
      })
    );
  }
}

ExpansionPanelManager.propTypes = {
  children: PropTypes.func.isRequired,
  defaultExpandedIndex: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ExpansionPanelManager;
