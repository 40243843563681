import { put } from 'redux-saga/effects';
import { strings } from 'Constants/Processing/Strings';
import { strings as errorStrings } from 'Constants/Error/Strings';
import { userActions } from 'Constants/UserActions';
import { reportProcessingProgress } from 'Store/Areas/Import/ProcessActions';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import { setImportFailedError } from 'Store/Areas/App/ErrorPageActions';
import SignalrStrategyHandler from './SignalrStrategyHandler';

export const externalReviewFileImportHandler = new SignalrStrategyHandler(
  userActions.ExternalReviewFileImport,
  function* handler(payload, action) {
    if (payload.error) {
      if (payload.importMessages && payload.importMessages.length) {
        yield put(setImportFailedError(
          errorStrings.externalReviewImportFailed,
          // eslint-disable-next-line quotes
          payload.importMessages.join("\n"),
        ));
      }
    } else {
      yield put(reportProcessingProgress(
        strings.externalReviewImportProcessing,
        payload.importStage,
        payload.forceClientPoll,
      ));
      if (payload.forceClientPoll) {
        yield put(getPeriod(action.selectedPeriodId));
      }
    }
  },
);