import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { resetLineItemMetadata, getLineItemMetadata } from 'Store/Areas/Review/LineItemMetadataActions';
import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';

const defaultOptions = {
  includeMl: false,
  includeManual: false,
  includeRule: false,
  includeConflicts: false,
  includeContra: false,
  includeAllExceptContras: false,
  categoryId: null,
};

const withLineItemMetadata = (options = defaultOptions) => (Component) => {
  options = { ...defaultOptions, ...options }; // eslint-disable-line
  class WithLineItemMetadata extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        categoryId: options.categoryId,
      };

      this.componentRef = React.createRef();
    }

    componentDidMount() {
      const { dispatch } = this.props;
      dispatch(resetLineItemMetadata());
    }

    componentDidUpdate(prevProps) {
      if (this.props.requiresSearch && !prevProps.requiresSearch) {
        this.loadItems();
      }
    }

    setOptions = (newOptions) => {
      this.setState({
        ...newOptions,
      }, () => this.loadItems());
    }

    loadItems = () => {
      const { dispatch, periodId } = this.props;
      const { categoryId } = this.state;

      if (options.includeManual) {
        dispatch(getLineItemMetadata(
          periodId,
          categorizationTypes.manual,
          categoryId,
        ));
      }
      if (options.includeMl) {
        dispatch(getLineItemMetadata(periodId, categorizationTypes.machineLearning, categoryId));
      }
      if (options.includeRule) {
        dispatch(getLineItemMetadata(
          periodId,
          categorizationTypes.rule,
          categoryId,
        ));
      }
      if (options.includeConflicts) {
        dispatch(getLineItemMetadata(periodId, categorizationTypes.allowedConflicts, categoryId));
      }
      if (options.includeContra) {
        dispatch(getLineItemMetadata(periodId, categorizationTypes.contra, categoryId));
      }
      if (options.includeAllExceptContras) {
        dispatch(getLineItemMetadata(periodId, categorizationTypes.allExceptContras, categoryId));
      }
    }


    render() {
      const {
        ruleMetadata,
        mlMetadata,
        manualMetadata,
        conflictsMetadata,
        contraMetadata,
        allExceptContrasMetadata,
        ...rest
      } = this.props;
      const {
        loadItems,
        setOptions,
      } = this;
      return (
        <Component
          ref={this.setRef}
          loadMetadata={loadItems}
          setMetadataOptions={setOptions}
          ruleMetadata={ruleMetadata}
          mlMetadata={mlMetadata}
          manualMetadata={manualMetadata}
          conflictsMetadata={conflictsMetadata}
          contraMetadata={contraMetadata}
          allExceptContrasMetadata={allExceptContrasMetadata}
          {...rest}
        />
      );
    }
  }

  WithLineItemMetadata.propTypes = {
    dispatch: PropTypes.func.isRequired,
    periodId: PropTypes.number.isRequired,
    ruleMetadata: PropTypes.shape({
      loading: PropTypes.bool,
      count: PropTypes.number,
      amount: PropTypes.number,
    }).isRequired,
    mlMetadata: PropTypes.shape({
      loading: PropTypes.bool,
      count: PropTypes.number,
      amount: PropTypes.number,
    }).isRequired,
    manualMetadata: PropTypes.shape({
      loading: PropTypes.bool,
      count: PropTypes.number,
      amount: PropTypes.number,
    }).isRequired,
    conflictsMetadata: PropTypes.shape({
      loading: PropTypes.bool,
      count: PropTypes.number,
      amount: PropTypes.number,
    }).isRequired,
    contraMetadata: PropTypes.shape({
      loading: PropTypes.bool,
      count: PropTypes.number,
      amount: PropTypes.number,
    }).isRequired,
    allExceptContrasMetadata: PropTypes.shape({
      count: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      loading: PropTypes.bool.isRequired,
    }).isRequired,
    requiresSearch: PropTypes.bool.isRequired,
  };

  function mapStateToProps(state) {
    const {
      mlMetadata,
      manualMetadata,
      conflictsMetadata,
      contraMetadata,
      ruleMetadata,
      allExceptContrasMetadata,
      requiresSearch,
    } = state.review.lineItemMetadata;
    return {
      periodId: state.periods.period.periodId,
      mlMetadata,
      manualMetadata,
      conflictsMetadata,
      contraMetadata,
      ruleMetadata,
      allExceptContrasMetadata,
      requiresSearch,
    };
  }

  return connect(mapStateToProps)(WithLineItemMetadata);
};

export default withLineItemMetadata;
