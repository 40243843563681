import { routes } from 'Constants/Routes';
import { navLinks } from 'Constants/NavLinks';
import { baseUrl } from '../index';

export function userInAdminMode(currentRoute) {
  const adminRoutes = [].concat(...navLinks.filter(x => x.admin).map(y => y.links));
  return adminRoutes.indexOf(currentRoute) > -1;
}

export function getAbsoluteUrlForRoute(siteRoute) {
  const cleanUrlEndRegex = /\/+$/g;

  let siteBaseUrl = document.getElementById('root').baseURI || baseUrl;
  siteBaseUrl = siteBaseUrl.replace(cleanUrlEndRegex, '');

  let absoluteSiteUrl;

  if (siteBaseUrl.includes('://')) {
    absoluteSiteUrl = siteBaseUrl;
  } else {
    absoluteSiteUrl = window.location.origin.replace(cleanUrlEndRegex, '');

    if (siteBaseUrl) {
      absoluteSiteUrl += `/${siteBaseUrl}`;
    }
  }

  if (siteRoute && siteRoute[0] !== '/') {
    absoluteSiteUrl += '/';
  }

  return absoluteSiteUrl + siteRoute;
}

export function getAdminToggleRoute(togglingToAdmin) {
  return togglingToAdmin ? routes.setup : routes.home;
}

export function getAdminToggleReturnRoute(returnRoute, togglingToAdmin) {
  if (returnRoute) {
    return returnRoute;
  }
  return togglingToAdmin ? routes.setup : routes.home;
}

export function getSubRoutes({ root, ...rest }) {
  return Object.values(rest).filter(r => !r.includes(':'));
}
