export const styles = () => ({
  root: {
    position: 'relative',
    display: 'inline-block',
  },
  dragLayer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
  },
  readOnly: {
    cursor: 'not-allowed',
  },
  textCursor: {
    cursor: 'text',
  },
});

