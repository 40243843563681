import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { PERIOD_POST_ADD_CATEGORY, PERIOD_POST_EDIT_CATEGORY } from 'Store/Api/ApiEndpoints';
import { Post } from 'Store/Api/CallApi';
import {
  POST_NEW_CATEGORY,
  postCategorySuccess,
  postCategoryFailure,
  POST_EDIT_CATEGORY,
} from 'Store/Areas/Categorisation/CategoryActions';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { closeEditingRule } from 'Store/Areas/Rule/EditRuleActions';
import { closeInspectRule } from 'Store/Areas/Rule/InspectRuleActions';

/* Worker Sagas */
function* postNewCategory(action) {
  const response = yield Post(PERIOD_POST_ADD_CATEGORY, action.payload);
  if (response.ok) {
    const data = yield response.json();
    if (data.success) {
      yield put(closeEditingRule());
      yield put(closeInspectRule());
      yield put(postCategorySuccess(data.addedItem));
      yield put(displayToastNotification(`Successfully added category ${action.payload.name}.`));
      yield put(getPeriod(action.payload.periodId));
    } else {
      yield put(postCategoryFailure(data.error));
    }
  } else {
    yield handleStatusCode(response.status);
  }
}

function* postEditCategory(action) {
  const response = yield Post(PERIOD_POST_EDIT_CATEGORY, action.payload);
  if (response.ok) {
    const data = yield response.json();
    if (data.success) {
      yield put(postCategorySuccess(data.addedItem));
      yield put(displayToastNotification(`Successfully edited category ${action.payload.name}.`));
      yield put(getPeriod(action.payload.periodId));
    } else {
      yield put(postCategoryFailure(data.error));
    }
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* categorySagas() {
  yield takeLatest(POST_NEW_CATEGORY, postNewCategory);
  yield takeLatest(POST_EDIT_CATEGORY, postEditCategory);
}
