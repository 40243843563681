import { takeLatest, put, call } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { REQUEST_SEARCH, requestSearchSuccess } from 'Store/Areas/Categorisation/KeywordSearchActions';
import { Post } from 'Store/Api/CallApi';
import { PERIOD_GET_SEARCH_KEYWORDS, PERIOD_GET_SEARCH_WHOLE_CELL_VALUES, PERIOD_GET_REFERENCE_LIST_VALUES } from 'Store/Api/ApiEndpoints';
import { textOperators } from 'Constants/Rules/RuleConstants';

function* requestSearchSaga(action) {
  yield call(delay, 1000);
  let searchUrl;
  switch (action.operator) {
    case textOperators.equals:
    case textOperators.notEquals:
      searchUrl = PERIOD_GET_SEARCH_WHOLE_CELL_VALUES;
      break;
    case textOperators.in:
      searchUrl = PERIOD_GET_REFERENCE_LIST_VALUES;
      break;
    case textOperators.contains:
    case textOperators.notContains:
      searchUrl = PERIOD_GET_SEARCH_KEYWORDS;
      break;
    default:
      searchUrl = null;
  }
  if (!searchUrl) return;

  const response = yield Post(searchUrl, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(requestSearchSuccess(data.map(x => x.name)));
  } else {
    yield put(requestSearchSuccess([]));
  }
}

export function* keywordSearchWatcher() {
  yield takeLatest(REQUEST_SEARCH, requestSearchSaga);
}
