import { levelName } from 'Constants/EntityLevels';

export const RESET_NEW_USER = 'ADDUSER/RESET_NEW_USER';
export const POST_NEW_USER = 'ADDUSER/POST_NEW_USER';
export const POST_NEW_USER_SUCCESS = 'ADDUSER/POST_NEW_USER_SUCCESS';
export const POST_NEW_USER_FAILURE = 'ADDUSER/POST_NEW_USER_FAILURE';
export const TOGGLE_ADD_USER_MODAL = 'ADDUSER/TOGGLE_ADD_USER_MODAL';
export const POST_NEW_USER_CONFIRM = 'ADDUSER/POST_NEW_USER_CONFIRM';

export function toggleAddUserModal(showModal) {
  return {
    type: TOGGLE_ADD_USER_MODAL,
    loading: false,
    success: false,
    errorMessage: '',
    showModal: showModal,
  };
}

export function resetNewUser() {
  return {
    type: RESET_NEW_USER,
    loading: false,
    success: false,
    errorMessage: '',
    showModal: false,
  };
}

export function postNewUser(
  email, firstName, lastName, id, level,
  addingSelf, groupId, confirmAddUser,
) {
  return {
    type: POST_NEW_USER,
    loading: true,
    success: false,
    errorMessage: '',
    payload: {
      emailAddress: email,
      firstName: firstName,
      lastName: lastName,
      entityLevelId: id,
      entityLevel: levelName(level),
      groupId: groupId,
      confirmAddUser: confirmAddUser,
    },
    addingSelf: addingSelf,
    showModal: true,
  };
}

export function postNewUserSuccess(user) {
  return {
    type: POST_NEW_USER_SUCCESS,
    loading: false,
    success: true,
    errorMessage: '',
    user: user,
    showModal: false,
  };
}

export function postNewUserFailure(errorMessage) {
  return {
    type: POST_NEW_USER_FAILURE,
    loading: false,
    success: false,
    errorMessage: errorMessage,
    showModal: true,
  };
}

export function postNewUserConfirm(errorMessage) {
  return {
    type: POST_NEW_USER_CONFIRM,
    loading: false,
    success: false,
    errorMessage: errorMessage,
    showModal: false,
    showAddUserConfirmModal: true,
  };
}
