import { takeLatest, put } from 'redux-saga/effects';
import { Post, Get } from 'Store/Api/CallApi';
import {
  POST_PROJECT,
  postProjectSuccess,
  editLoadProjectSuccess,
  EDIT_PROJECT,
  DELETE_PROJECT,
} from 'Store/Areas/Setup/Admin/ProjectActions';
import {
  selectNode,
  editNode,
  getAdminTreePickerData,
} from 'Store/Areas/Setup/Admin/AdminTreePickerActions';
import { getUsers } from 'Store/Areas/Setup/Admin/GetUserActions';
import { ADMIN_POST_PROJECT_URL, ADMIN_GET_PROJECT_URL, ADMIN_DELETE_PROJECT } from 'Store/Api/ApiEndpoints';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { levels } from 'Constants/EntityLevels';

function* postProject(action) {
  const response = yield Post(ADMIN_POST_PROJECT_URL, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(postProjectSuccess());
    yield put(getAdminTreePickerData());
    yield put(selectNode(action.groupId, action.entityId, data.id, levels.project));
    const newProject = {
      id: data.id,
      name: data.name,
    };
    yield put(editNode(levels.project, newProject));
    yield put(getUsers(levels.project, data.id));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getProject(action) {
  const response = yield Get(ADMIN_GET_PROJECT_URL(action.payload.id));
  if (response.ok) {
    const data = yield response.json();
    yield put(editLoadProjectSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* deleteProject(action) {
  const response = yield Post(ADMIN_DELETE_PROJECT, action.payload);
  if (response.ok) {
    yield put(getAdminTreePickerData(true));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* projectSagas() {
  yield takeLatest(POST_PROJECT, postProject);
  yield takeLatest(EDIT_PROJECT, getProject);
  yield takeLatest(DELETE_PROJECT, deleteProject);
}
