import React from 'react';
import { DropTarget } from 'react-dnd';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import DragnDrop from 'Constants/DragnDrop';
import PropTypes from 'prop-types';
import { styles } from './BuilderRowDropzone.styles';

class BuilderRowDropzone extends React.Component {
  render() {
    const {
      connectDropTarget,
      classes,
      canDrop,
      isOver,
    } = this.props;

    if (!canDrop) {
      return null;
    }
    return (
      connectDropTarget(<div className={`${classes.root} ${isOver ? classes.isOver : ''}`} />)
    );
  }
}

BuilderRowDropzone.propTypes = {
  connectDropTarget: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired, // eslint-disable-line
  isOver: PropTypes.bool.isRequired, // eslint-disable-line
  setIsOver: PropTypes.func.isRequired, // eslint-disable-line
  canDrop: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const spec = {
  // drop implemented in BuilderDragHandle
  hover(props, monitor) {
    const isOver = monitor.isOver({ shallow: true });
    if (isOver && !props.isOver) {
      props.setIsOver(props.index);
    }

    return isOver;
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

export default compose(
  DropTarget(DragnDrop.customRuleSegment, spec, collect),
  withStyles(styles),
  withTheme(),
)(BuilderRowDropzone);
