import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import styles from './MenuItem.styles';

class MenuItem extends React.PureComponent {
  linkContent() {
    const {
      classes,
      image,
      text,
      active,
      enabled,
    } = this.props;

    return (
      <div className={`${classes.container} ${active ? classes.active : ''} ${enabled ? classes.enabled : classes.disabled}`}>
        <img alt={text} src={image} className={classes.image} />
        <div className={classes.text}>{text}</div>
      </div>
    );
  }

  render() {
    const {
      classes,
      link,
      enabled,
    } = this.props;

    if (enabled) {
      return (
        <Link to={link} className={`${classes.root}`}>
          {this.linkContent()}
        </Link>);
    }
    return (
      <div className={classes.disabled}>
        {this.linkContent()}
      </div>
    );
  }
}

MenuItem.propTypes = {
  link: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  enabled: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(MenuItem);
