export const REQUEST_DELETE_FILE = 'UPLOAD/REQUEST_DELETE_FILE';
export const CANCEL_DELETING = 'UPLOAD/CANCEL_DELETING';
export const BEGIN_DELETE_FILE = 'UPLOAD/BEGIN_DELETE_FILE';
export const DELETE_FILE_SUCCESS = 'UPLOAD/DELETE_FILE_SUCCESS';
export const REQUEST_DELETE_SUPPORTING_DOC = 'UPLOAD/REQUEST_DELETE_SUPPORTING_DOC';
export const CANCEL_DELETE_SUPPORTING_DOC = 'UPLOAD/CANCEL_DELETE_SUPPORTING_DOC';
export const BEGIN_DELETE_SUPPORTING_DOC = 'UPLOAD/BEGIN_DELETE_SUPPORTING_DOC';
export const DELETE_SUPPORTING_DOC_SUCCESS = 'UPLOAD/DELETE_SUPPORTING_DOC_SUCCESS';
export const MAP_FILE_TO_SCHEMA = 'UPLOAD/MAP_FILE_TO_SCHEMA';
export const MAP_FILE_TO_SCHEMA_COMPLETED = 'UPLOAD/MAP_FILE_TO_SCHEMA_COMPLETED';
export const CLEAR_PERIOD_FILES = 'UPLOAD/CLEAR_PERIOD_FILES';

export function requestDeleteFile(cloudReference, fileName) {
  return {
    type: REQUEST_DELETE_FILE,
    cloudReference,
    fileName,
    isDelete: true,
  };
}

export function cancelDeleting() {
  return {
    type: CANCEL_DELETING,
    isDelete: false,
    loading: false,
  };
}

export function beginDeleteFile(
  cloudReference,
  fileName,
  periodId,
  changeSupportingDocsAfterPeriodCreation,
  isValidationError,
) {
  return {
    type: BEGIN_DELETE_FILE,
    cloudReference,
    periodId,
    fileName,
    loading: true,
    isDelete: true,
    changeSupportingDocsAfterPeriodCreation,
    isValidationError,
  };
}

export function deleteFileSuccess(periodId) {
  return {
    type: DELETE_FILE_SUCCESS,
    periodId,
    loading: false,
    isDelete: false,
  };
}

export function requestDeleteSupportingDoc(cloudReference, fileName) {
  return {
    type: REQUEST_DELETE_SUPPORTING_DOC,
    cloudReference,
    fileName,
    isDelete: true,
    loading: false,
  };
}

export function cancelDeleteSupportingDoc() {
  return {
    type: CANCEL_DELETE_SUPPORTING_DOC,
    cloudReference: null,
    fileName: null,
    isDelete: false,
    loading: false,
  };
}

export function beginDeleteSupportingDoc(
  cloudReference,
  fileName,
  periodId,
) {
  return {
    type: BEGIN_DELETE_SUPPORTING_DOC,
    cloudReference,
    periodId,
    fileName,
    loading: true,
    isDelete: true,
  };
}

export function deleteSupportingDocSuccess(periodId) {
  return {
    type: DELETE_SUPPORTING_DOC_SUCCESS,
    cloudReference: null,
    fileName: null,
    periodId,
    loading: false,
    isDelete: false,
  };
}


export function mapFileToSchema(fileId, fileType, fileName, periodId) {
  return {
    type: MAP_FILE_TO_SCHEMA,
    payload: {
      fileId: fileId,
      fileType: fileType,
      periodId: periodId,
      fileName: fileName,
    },
  };
}

export function mapFileToSchemaCompleted(success) {
  return {
    type: MAP_FILE_TO_SCHEMA_COMPLETED,
    success: success,
  };
}

export function clearPeriodFiles(periodId) {
  return {
    type: CLEAR_PERIOD_FILES,
    payload: {
      periodId: periodId,
    },
  };
}