import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Grid, Button } from '@material-ui/core';
import { compose } from 'recompose';
import MandatoryAsterisk from 'Components/Shared/MandatoryAsterisk/MandatoryAsterisk';
import GenericDragLayer from 'Components/Shared/DragNDrop/GenericDragLayer';
import DragnDrop from 'Constants/DragnDrop';
import { strings } from 'Constants/Upload/TagMapping';
import { toggleAddTagModal, toggleDeleteTagModal } from 'Store/Areas/Import/TagMappingActions';
import { selectMappedTagsFromPeriod } from 'Constants/Categorization/Tags';
import { supporting, main } from 'Constants/FileTypes';

import styles from './TagsPanel.styles';
import DragTag from './DragTag';
import Tag from './Tag';


class TagsPanel extends React.PureComponent {
  toggleDeleteTagModal(active, tagName, tagId) {
    this.props.dispatch(toggleDeleteTagModal(active, tagName, tagId));
  }

  toggleAddTagModal(active) {
    this.props.dispatch(toggleAddTagModal(active));
  }

  renderDragLayer(item, itemType) {
    if (itemType === DragnDrop.mappingTag) {
      return (
        <Tag
          tag={item}
        />
      );
    }

    return null;
  }

  render() {
    const {
      classes, unmappedTags, mappingTagId,
    } = this.props;
    return (
      <Grid container justify="flex-end">
        <GenericDragLayer>
          {this.renderDragLayer}
        </GenericDragLayer>
        <Grid item xs={12} >
          <p>
            <b className={classes.mandatoryTags}>{strings.tagsTitle} </b>
            <MandatoryAsterisk />
            <span className={classes.mandatoryTags}> {strings.mandatoryTags}</span>
          </p>
        </Grid>
        <Grid item xs={12}>
          <For each="tag" of={unmappedTags} >
            <If condition={tag.id !== mappingTagId}>
              <DragTag
                tag={tag}
                key={tag.id}
                onClick={() => this.toggleDeleteTagModal(true, tag.name, tag.id)}
              />
            </If>
          </For>
          <Button
            variant="outlined"
            className={classes.addTagButton}
            onClick={() => this.toggleAddTagModal(true)}
          >
            {strings.addTagButton}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

TagsPanel.defaultProps = {
  mappingTagId: -1,
};

TagsPanel.propTypes = {
  fileType: PropTypes.oneOf([supporting, main]).isRequired, // eslint-disable-line
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  unmappedTags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  mappingTagId: PropTypes.number,
};


function mapStateToProps(state, componentProps) {
  const mappedTagIds =
    state.periods.period.data.fileSchema.headers
      .filter(x => x.fileType === componentProps.fileType)
      .filter(x => x.tagId != null)
      .map(x => x.tagId);
  return {
    unmappedTags: selectMappedTagsFromPeriod(state).filter(x => !mappedTagIds.includes(x.id)),
    mappingTagId: state.import.tagMapping.mapTag.mappingTagId,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(TagsPanel);
