import { REQUEST_SEARCH, RESET_SEARCH, REQUEST_SEARCH_SUCCESS } from 'Store/Areas/Categorisation/KeywordSearchActions';

const initialState = {
  query: '',
  searchId: -1,
  results: [],
};

export default function keywordSearchReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SEARCH:
      return Object.assign({}, state, {
        query: action.payload.searchTerm,
        searchId: action.searchId,
        results: [],
      });
    case REQUEST_SEARCH_SUCCESS:
      return Object.assign({}, state, {
        results: action.results,
      });
    case RESET_SEARCH:
      return Object.assign({}, state, {
        query: initialState.searchTerm,
        searchId: initialState.searchId,
        results: [],
      });
    default:
      return state;
  }
}
