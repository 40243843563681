import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { styles } from './CheckboxLineItem.styles';

const CheckboxLineItem = ({
  active,
  classes,
  onClick,
  text,
  renderSelectionIcon,
  className,
  shadeBackground,
}) => (
  <div
    className={`${classes.root} ${className} ${active && shadeBackground ? classes.active : ''}`}
    onClick={onClick}
    role="button"
    tabIndex={0}
    onKeyDown={onClick}
  >
    <div className={classes.radioContainer}>
      {renderSelectionIcon({ active })}
    </div>
    <div className={classes.rowItem}>{text}</div>
  </div>
);

CheckboxLineItem.defaultProps = {
  className: '',
  shadeBackground: true,
};

CheckboxLineItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  text: PropTypes.string.isRequired,
  renderSelectionIcon: PropTypes.func.isRequired,
  className: PropTypes.string,
  shadeBackground: PropTypes.bool,
};

export default compose(withStyles(styles))(CheckboxLineItem);
