export const styles = theme => ({
  root: {
    height: 40,
    lineHeight: '40px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.colors.white,
  },
  cell: {
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    fontSize: theme.constants.fontSizes.small,
  },
  borderLeftRadius: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  borderRightRadius: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
});
