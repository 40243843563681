const styles = theme => ({
  button: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    textAlign: 'left',
    outline: 'none',
    padding: 0,
    fontWeight: 'bold',
  },
  disabledButton: {
    padding: 0,
    fontSize: theme.constants.fontSizes.small,
    fontWeight: 'bold',
  },
  disabled: {
    color: theme.palette.colors.white,
    opacity: 0.6,
    cursor: 'not-allowed',
  },
  description: {
    paddingTop: 5,
    color: theme.palette.colors.white,
    marginTop: 0,
    marginBottom: '1.5em',
    fontSize: theme.constants.fontSizes.small,
  },
});

export default styles;
