export const GET_USER_PERMISSIONS = 'PERMISSIONS/GET_USER_PERMISSIONS';
export const GET_USER_PERMISSIONS_SUCCESS = 'PERMISSIONS/GET_USER_PERMISSIONS_SUCCESS';
export const CONNECT_TO_SIGNALR_USER_HUB = 'PERMISSIONS/CONNECT_TO_SIGNALR_USER_HUB';
export const DOWNLOAD_USER_GUIDE = 'PERMISSIONS/DOWNLOAD_USER_GUIDE';

export function getUserPermissions() {
  return {
    type: GET_USER_PERMISSIONS,
    payload: {
      data: {},
      populated: false,
    },
  };
}

export function getUserPermissionsSuccess(data) {
  return {
    type: GET_USER_PERMISSIONS_SUCCESS,
    payload: {
      data: data,
      populated: true,
    },
  };
}

export function connectToSignalRUserHub(userId) {
  return {
    type: CONNECT_TO_SIGNALR_USER_HUB,
    userId: userId,
  };
}

export function downloadUserGuide() {
  return {
    type: DOWNLOAD_USER_GUIDE,
  };
}