import { flatten } from 'Helpers/ArrayHelpers';

export const getSupportingDocumentMappingFiles = state =>
  state.import.supportingDocumentMapping.files;

export const getSupportingDocumentMappedLineItemIds = (state) => {
  const result = flatten(state.import.supportingDocumentMapping.files
    .map(x => x.lineItemIds).filter(x => x.length > 0));
  return result;
};

export const isSupportingDocumentMappingLoadingSelector = state =>
  state.import.supportingDocumentMapping.loading;

export const getMatchedSupportingAndMainDocsDataSelector = state =>
  state.import.supportingDocumentMapping.allMatchedAndUnmatchedDocsData.matchedDocs;

export const getUnmatchedSupportingDocsSelector = state =>
  state.import.supportingDocumentMapping.allMatchedAndUnmatchedDocsData.unmatchedDocs;
