export const TOGGLE_ADD_TAG_MODAL = 'TAG_MAPPING/TOGGLE_ADD_TAG_MODAL';
export const POST_NEW_TAG = 'TAG_MAPPING/POST_NEW_TAG';
export const POST_NEW_TAG_SUCCESS = 'TAG_MAPPING/POST_NEW_TAG_SUCCESS';
export const MAP_TAG = 'TAG_MAPPING/MAP_TAG';
export const MAP_TAG_SUCCESS = 'TAG_MAPPING/MAP_TAG_SUCCESS';
export const TOGGLE_MANDATORY_TAGS_MODAL = 'TAG_MAPPING/TOGGLE_MANDATORY_TAGS_MODAL';
export const DRAG_TAG = 'TAG_MAPPING/DRAG_TAG';
export const AUTO_MAP_SUPPORTING_TAGS = 'TAG_MAPPING/AUTO_MAP_SUPPORTING_TAGS';
export const AUTO_MAP_SUPPORTING_TAGS_SUCCESS = 'TAG_MAPPING/AUTO_MAP_SUPPORTING_TAGS_SUCCESS';
export const TOGGLE_DELETE_TAG_MODAL = 'TAG_MAPPING/TOGGLE_DELETE_TAG_MODAL';
export const DELETE_TAG = 'TAG_MAPPING/DELETE_TAG';
export const DELETE_TAG_SUCCESS = 'TAG_MAPPING/DELETE_TAG_SUCCESS';


export function toggleAddTagModal(showModal) {
  return {
    type: TOGGLE_ADD_TAG_MODAL,
    loading: false,
    success: false,
    errorMessage: '',
    showModal: showModal,
  };
}

export function toggleMapMandatoryTagsModal(showModal) {
  return {
    type: TOGGLE_MANDATORY_TAGS_MODAL,
    showMandatoryTagModal: showModal,
    loading: false,
    success: false,
    errorMessage: '',
  };
}

export function postNewTag(tagName, dataType, periodId) {
  return {
    type: POST_NEW_TAG,
    loading: true,
    success: false,
    errorMessage: '',
    showModal: true,
    payload: {
      tagName,
      periodId,
      tagType: dataType,
    },
  };
}

export function postNewTagSuccess() {
  return {
    type: POST_NEW_TAG_SUCCESS,
    loading: false,
    success: true,
    showModal: false,
    errorMessage: '',
  };
}

export function mapTag(tagId, headerId, periodId, headerName, tagName, tagType) {
  return {
    type: MAP_TAG,
    showMandatoryTagModal: false,
    loading: true,
    success: false,
    errorMessage: '',
    mappingTagId: tagId,
    payload: {
      periodId,
      headerId,
      tagId,
      headerName,
      tagName,
      tagType,
    },
  };
}

export function mapTagSuccess() {
  return {
    type: MAP_TAG_SUCCESS,
    showMandatoryTagModal: false,
    loading: false,
    success: true,
    errorMessage: '',
  };
}

export function dragTag(tagType) {
  return {
    type: DRAG_TAG,
    dragTagType: tagType,
  };
}

export function autoMapSupportingTags(periodId) {
  return {
    type: AUTO_MAP_SUPPORTING_TAGS,
    payload: {
      periodId: periodId,
    },
    loading: true,
  };
}

export function autoMapSupportingTagsSuccess() {
  return {
    type: AUTO_MAP_SUPPORTING_TAGS_SUCCESS,
    loading: false,
  };
}

export function toggleDeleteTagModal(showModal, tagName, tagId) {
  return {
    type: TOGGLE_DELETE_TAG_MODAL,
    loading: false,
    success: false,
    errorMessage: '',
    showModal: showModal,
    tagName: tagName,
    tagId: tagId,
  };
}

export function deleteTag(tagName, tagId, periodId) {
  return {
    type: DELETE_TAG,
    loading: true,
    success: false,
    errorMessage: '',
    showModal: true,
    tagName: tagName,
    tagId: tagId,
    payload: {
      tagName,
      periodId,
      tagId,
    },
  };
}

export function deleteTagSuccess() {
  return {
    type: DELETE_TAG_SUCCESS,
    loading: false,
    success: true,
    showModal: false,
    tagName: '',
    errorMessage: '',
  };
}