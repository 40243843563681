export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  breadcrumb: {
    marginLeft: 40,
    marginTop: 0,
    fontFamily: 'Arial',
    fontSize: 12,
    color: theme.palette.primary.darkText,
    paddingTop: 14,
    paddingLeft: 20,
    position: 'absolute',
  },
  change: {
    fontWeight: 'bold',
    fontSize: 12,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  button: {
    marginLeft: 5,
    border: 'none',
    padding: 0,
    backgroundColor: '#efefef',
  },
});

export default styles;
