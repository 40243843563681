import { put } from 'redux-saga/effects';
import { userActions } from 'Constants/UserActions';
import { strings } from 'Constants/Contras/Strings';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import { uncategoriseLineItemSuccess } from 'Store/Areas/Categorisation/LineItemActions';
import { commonStrings } from 'Constants/CommonStrings';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { apportionmentStrings } from 'Components/Apportionables/ApportionablesStrings';
import SignalrStrategyHandler from './SignalrStrategyHandler';

function* handleCategorisationEvent(payload, action) {
  if (payload.error) {
    yield this.handleError(action);
  } else {
    yield put(getPeriod(action.selectedPeriodId, true));
    yield put(setProcessing(false));

    if (action === userActions.RemoveContra) {
      yield put(displayToastNotification(strings.removeContraSuccessfulMessage));
    }
    if (action === userActions.RunSingleRuleForApportionable) {
      yield put(displayToastNotification(apportionmentStrings.apportionableCreated));
    }
    if (action === userActions.DeleteCategory) {
      yield put(displayToastNotification(`${action.payload.categoryName} ${commonStrings.wasDeleted}`));
    }
    if (action === userActions.UnCategoriseLineItem) {
      yield put(uncategoriseLineItemSuccess());
    }
  }
}

export const genericCategorisationHandler = new SignalrStrategyHandler(
  [userActions.ManualCategorisation,
    userActions.RunSingleRule,
    userActions.RemoveContra,
    userActions.RunSingleRuleForApportionable,
    userActions.DeleteCategory,
    userActions.UnCategoriseLineItem],
  function* handler(payload, action) {
    yield handleCategorisationEvent(payload, action);
  },
);
