import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { push } from 'connected-react-router';
import { Card, Grid, withStyles, withTheme } from '@material-ui/core';
import { requestUploadPeriodFile, toggleCorruptedFileDetectedModal } from 'Store/Areas/FileUpload/FileUploadActions';
import { requestProcess } from 'Store/Areas/Import/ProcessActions';
import { routes } from 'Constants/Routes';
import UploadBreadcrumb, { constants } from 'Components/Shared/UploadBreadcrumb/UploadBreadcrumb';
import { strings } from 'Constants/Upload/Strings';
import { title } from 'Constants/App/Titles/Strings';
import ArrowButton from 'Components/Shared/Buttons/ArrowButton/ArrowButton';
import {
  hasSupportingFilesSelector,
  periodInUseSelector,
  periodLockedByUserIdSelector,
} from 'Store/Areas/Period/PeriodSelectors';
import TagInstructions from '../Shared/TagInstructions';
import UploadStatus from '../Shared/UploadStatus';
import styles from './ReviewPage.styles';


class ReviewPage extends React.PureComponent {
  componentDidMount() {
    document.title = title.review;
  }

  onFileSelected(file) {
    const { dispatch, projects } = this.props;
    dispatch(requestUploadPeriodFile(file, projects.userTreePicker.selectedPeriodId));
  }

  onFileRejected = () => {
    this.props.dispatch(toggleCorruptedFileDetectedModal({
      showModal: true,
      fileContainsVirus: true,
    }));
  }

  onProceedClick = () => {
    const { process, dispatch, projects } = this.props;
    if (!process.loading) {
      dispatch(requestProcess(projects.userTreePicker.selectedPeriodId));
      dispatch(push(routes.processing));
    }
  }

  onBackToTagMappingClick = () => {
    const { hasSupportingFiles } = this.props;
    if (hasSupportingFiles) {
      this.props.dispatch(push(routes.import.mappingSupporting));
    } else {
      this.props.dispatch(push(routes.import.mappingMain));
    }
  }

  render() {
    const {
      classes,
      period,
      periodInUse,
      periodLockedByUserId,
      permissions,
    } = this.props;
    return (
      <Fragment>
        <UploadBreadcrumb index={constants.indexes.review} />
        <Card className={`${classes.card} ${(periodInUse && periodLockedByUserId !== permissions.id) ? classes.disabled : ''}`}>
          <Grid container direction="row" alignItems="stretch">
            <Grid item xs={12} className={classes.gridRow} >
              <ArrowButton onClickEvent={this.onBackToTagMappingClick} />
            </Grid>
            <If condition={period.data}>
              <Grid item xs={6} sm={6} md={3} lg={3}>
                <TagInstructions />
              </Grid>
            </If>
            <Grid item xs={6} sm={6} md={9} lg={9}>
              <Grid item xs={12}>
                <UploadStatus
                  onClick={this.onProceedClick}
                  buttonText={strings.processFileButton}
                >
                  <div className={classes.description}>{strings.uploadMessagePt1}</div>
                  <div className={classes.message}>{strings.uploadMessagePt2}</div>
                </UploadStatus>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Fragment>
    );
  }
}

ReviewPage.defaultProps = {
  periodLockedByUserId: -1,
};

ReviewPage.propTypes = {
  period: PropTypes.shape({
    loading: PropTypes.bool,
    success: PropTypes.bool,
    periodId: PropTypes.number,
    data: PropTypes.shape({
      tags: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
      })).isRequired,
    }),
  }).isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
    }),
  }).isRequired,
  process: PropTypes.shape({
    loading: PropTypes.bool,
    progress: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  hasSupportingFiles: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodInUse: PropTypes.bool.isRequired,
  periodLockedByUserId: PropTypes.number,
  permissions: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    hasSupportingFiles: hasSupportingFilesSelector(state),
    period: state.periods.period,
    process: state.import.process,
    projects: state.projects,
    periodInUse: periodInUseSelector(state),
    periodLockedByUserId: periodLockedByUserIdSelector(state),
    permissions: state.user.permissions.data,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(ReviewPage);
