import { takeLatest, put } from 'redux-saga/effects';
import {
  GET_PERIOD_IMPORT_DETAILS,
  getPeriodImportDetailsSuccess,
} from 'Store/Areas/Import/PeriodImportDetailsActions';
import { PERIOD_IMPORT_DETAILS } from 'Store/Api/ApiEndpoints';
import { Get } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';

/* Worker Sagas */
function* getPeriodImportDetailsSaga(action) {
  const response = yield Get(PERIOD_IMPORT_DETAILS(action.payload.periodId));
  if (response.ok) {
    const data = yield response.json();
    yield put(getPeriodImportDetailsSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* periodImportDetailsSagas() {
  yield takeLatest(GET_PERIOD_IMPORT_DETAILS, getPeriodImportDetailsSaga);
}
