import { takeLatest, put } from 'redux-saga/effects';
import { EXPORT_REQUEST_EXPORT, EXPORT_GET_EXPORT_FILES, DELETE_EXPORT_REQUEST, CLEAR_EXPORT_REQUEST } from 'Store/Api/ApiEndpoints';
import { Post, Get } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { startPolling } from 'Store/Areas/App/PeriodStatusPollingActions';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { REQUEST_EXPORT, GET_EXPORT_FILES, CLEAR_EXPORT_FILES, getExportFilesSuccess, DELETE_EXPORT_FILE, deleteExportFileSuccess, getExportFiles } from './ExportActions';

function* requestExportSaga(action) {
  const response = yield Post(EXPORT_REQUEST_EXPORT, action.payload);
  if (response.ok) {
    yield put(startPolling(action.payload.periodId, true));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* getExportFilesSaga(action) {
  const response = yield Get(EXPORT_GET_EXPORT_FILES(
    action.payload.periodId,
    action.payload.isExternalReview,
  ));
  if (response.ok) {
    const data = yield response.json();
    yield put(getExportFilesSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* deleteSelectedExportFile(action) {
  const response = yield Post(DELETE_EXPORT_REQUEST, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(deleteExportFileSuccess(data));
    yield put(getExportFiles(action.payload.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* clearExportFiles(action) {
  const response = yield Post(CLEAR_EXPORT_REQUEST, action.payload);
  if (response.ok) {
    yield put(getTreePickerData());
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* exportSagas() {
  yield takeLatest(REQUEST_EXPORT, requestExportSaga);
  yield takeLatest(GET_EXPORT_FILES, getExportFilesSaga);
  yield takeLatest(DELETE_EXPORT_FILE, deleteSelectedExportFile);
  yield takeLatest(CLEAR_EXPORT_FILES, clearExportFiles);
}
