export const styles = theme => ({
  root: {
    height: 'Calc(100vh - 130px)',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
  },
  imgButton: {
    zIndex: '200',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
    marginTop: '-5px',
    fontSize: theme.constants.fontSizes.small,
    background: 'transparent',
    border: 'none',
    display: 'flex',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    flexGrow: 1,
    padding: '10px 10px 0 10px',
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
  },
  categories: {
    height: '100%',
  },
  categoriesListWrapper: {
    height: 'Calc(100vh - 240px)',
    overflowY: 'auto',
    padding: '10px',
  },
  listItemsSection: {
    width: '100%',
    marginLeft: 0,
    marginRight: '10px',
  },
  listItems: {
    marginLeft: '1em',
    marginTop: '10px',
    height: 'Calc(100vh - 240px)',
    display: 'flex',
    flexDirection: 'column',
  },
  mainTitleGrid: {
    marginBottom: 10,
    marginTop: 10,
  },
  mainTitle: {
    fontSize: theme.constants.fontSizes.large,
    lineHeight: '22px',
    margin: 0,
  },
  descriptionTitle: {
    fontSize: theme.constants.fontSizes.regular,
    margin: 0,
    marginTop: '12px',
    textAlign: 'justify',
    paddingRight: '10px',
  },
  tabGrid: {
    marginTop: 0,
    overflow: 'auto',
  },
  headerGrid: {
    marginLeft: '12px',
    marginRight: '12px',
  },
  contentHeader: {
    padding: '10px',
    paddingLeft: '20px',
    marginBottom: '5px',
    width: '100%',
  },
  searchBox: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.colors.black,
  },
  searchContainer: {
    alignItems: 'flex-end',
    rowGap: '10px',
  },
  mlShowLogicButton: {
    fontSize: theme.constants.fontSizes.small,
  },
  mlShowLogicButtonDisabled: {
    opacity: 0.7,
    '&:hover': {
      cursor: 'not-allowed',
    },
    fontSize: theme.constants.fontSizes.small,
  },
  toolTipBoxContainer: {
    height: 50,
    display: 'inline-block',
    textAlign: 'left',
    marginTop: 10,
  },
  toolTipBox: {
    width: 120,
    height: 50,
    borderRadius: 2,
    boxShadow: '0 0 5px 3px rgba(237, 237, 237, 0.5)',
    border: 'solid 1px #d4d4d4',
    backgroundColor: theme.palette.primary.contrastText,
    fontSize: theme.constants.fontSizes.small,
    color: theme.palette.primary.lightLabel,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '0 10px 0 10px',
  },
  stickyIcon: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    height: 30,
    width: 30,
    padding: '0 5px 0 5px',
  },
  stickyIconPlaceholder: {
    width: 30,
    display: 'inline-block',
  },
  flagIcon: {
    paddingLeft: '8px',
  },
  categorisationReason: {
    position: 'absolute',
    top: 0,
    right: 10,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 11px 11px 0',
    borderColor: 'transparent red transparent transparent',
    display: 'inline-block',
    zIndex: '200',
  },
  noSearchResults: {
    paddingTop: 20,
  },
  simpleMessage: theme.simpleMessage,
  totalLineItemCount: {
    fontSize: theme.constants.fontSizes.regular,
    textAlign: 'right',
  },
  categoryTypes: {
    position: 'relative',
    top: '10px',
  },
  advancedSearch: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: theme.constants.fontSizes.small,
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
  },
  tagSearchFilter: {
    maxHeight: '150px',
    overflowY: 'auto',
  },
  rightAligned: {
    textAlign: 'right',
  },
  mlShowLogicWrapper: {
    alignSelf: 'center',
  },
  createRulePane: {
    display: 'inline-flex',
  },
  createRuleButton: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: theme.constants.fontSizes.small,
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
  },
  paperRoot: {
    marginTop: 10,
    marginBottom: 10,
    height: '30px',
  },
});
