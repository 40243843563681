import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  withTheme,
} from '@material-ui/core';
import { compose } from 'recompose';
import Modal from './StyledModal';
import Button, { constants } from '../Buttons/Button';
import { commonStrings } from '../../../Constants/CommonStrings';
import styles from './ErrorModal.styles';

class ErrorModal extends React.PureComponent {
  render() {
    const {
      open,
      title,
      error,
      children,
      onClose,
      classes,
    } = this.props;
    return (
      <Modal open={open} className={classes.root}>
        <div className={classes.content}>
          <div className={classes.title}>{title}</div>
          <div className={classes.error}>{children || error}</div>
        </div>
        <Button
          disableRipple
          backgroundColor={constants.backgroundColor.main}
          height={constants.height.big}
          rounded={false}
          onClick={onClose}
        >
          {commonStrings.ok}
        </Button>
      </Modal>
    );
  }
}

ErrorModal.defaultProps = {
  error: '',
  title: '',
  children: null,
  onClose: () => {},
};

ErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string,
  children: PropTypes.element,
  title: PropTypes.string,
  onClose: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(ErrorModal);
