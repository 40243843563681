import React from 'react';
import { strings } from 'Constants/Upload/Strings';
import { title } from 'Constants/App/Titles/Strings';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import { hasSupportingFilesSelector, periodIdSelector, changeSupportingDocsAfterPeriodCreationSelector } from 'Store/Areas/Period/PeriodSelectors';
import PropTypes from 'prop-types';
import { autoMapSupportingTags } from 'Store/Areas/Import/TagMappingActions';
import { main } from 'Constants/FileTypes';
import UploadBreadcrumb, { constants } from 'Components/Shared/UploadBreadcrumb/UploadBreadcrumb';
import PageContent from './PageContent/PageContent';

class MainMappingPage extends React.PureComponent {
  componentDidMount() {
    document.title = title.mapping;
  }

  onProceedClick = () => {
    const {
      hasSupportingFiles, dispatch, periodId, changeSupportingDocsAfterPeriodCreation,
    } = this.props;
    if (hasSupportingFiles || changeSupportingDocsAfterPeriodCreation) {
      dispatch(autoMapSupportingTags(periodId));
    } else {
      dispatch(push(routes.import.review));
    }
  }

  onBackToUploadClick = () => {
    this.props.dispatch(push(routes.import.upload));
  }

  render() {
    return (
      <PageContent
        fileType={main}
        title={strings.mappingMainTitle}
        description={strings.mappingMainDescription}
        renderBreadcrumb={() => (
          <UploadBreadcrumb index={constants.indexes.mainMapping} />
        )}
        onProceedClick={this.onProceedClick}
        onBackClick={this.onBackToUploadClick}
      />
    );
  }
}

MainMappingPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  hasSupportingFiles: PropTypes.bool.isRequired,
  periodId: PropTypes.number.isRequired,
  changeSupportingDocsAfterPeriodCreation: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    periodId: periodIdSelector(state),
    hasSupportingFiles: hasSupportingFilesSelector(state),
    files: state.periods.period.data.files,
    changeSupportingDocsAfterPeriodCreation: changeSupportingDocsAfterPeriodCreationSelector(state),
  };
};

export default connect(mapStateToProps)(MainMappingPage);
