import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import DocumentsIcon from 'Assets/Images/document-ic.svg';
import UploadIcon from 'Assets/Images/upload.svg';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import { strings } from 'Constants/Upload/Strings';
import styles from './DropzoneContent.styles';

class DropzoneContent extends React.PureComponent {
  render() {
    const {
      classes,
      inReview,
    } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <img alt="" src={DocumentsIcon} className={classes.documentsIcon} />
          <img alt="" src={UploadIcon} className={classes.iconImg} />
          <div className={classes.description}>
            <Choose>
              <When condition={!inReview}>
                {strings.dragAndDropDescription}
              </When>
              <Otherwise>
                {strings.reuploadDescription}
              </Otherwise>
            </Choose>
          </div>
          <Button
            backgroundColor={constants.backgroundColor.main}
            height={constants.height.big}
            className={classes.button}
          >
            <Choose>
              <When condition={!inReview}>
                {strings.uploadButtonDescription}
              </When>
              <Otherwise>
                {strings.reuploadButtonDescription}
              </Otherwise>
            </Choose>
          </Button>
        </div>
      </div >
    );
  }
}

DropzoneContent.defaultProps = {
  inReview: false,
};

DropzoneContent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  inReview: PropTypes.bool,
};

export default compose(withStyles(styles), withTheme())(DropzoneContent);
