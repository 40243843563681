export const styles = theme => ({
  labelDark: {
    opacity: '0.6',
    color: '#656565',
    fontSize: theme.constants.fontSizes.small,
  },
  linkButton: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: theme.constants.fontSizes.small,
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
  },
  searchBox: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.colors.black,
  },
  label: {
    marginLeft: 0,
    color: `${theme.palette.primary.darkText} !important`,
    paddingLeft: 5,
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.primary.messageBackground,
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: theme.constants.fontSizes.small,
  },
  tagSelectWrapper: {
    position: 'relative',
  },
  removeIcon: {
    position: 'absolute',
    left: -5,
    top: 15,
    zIndex: 50,
    height: 10,
    width: 10,
    '&:hover': {
      backgroundColor: 'unset',
      filter: 'brightness(-100%)',
    },
  },
});