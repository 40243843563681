/* eslint-disable no-debugger */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { DropTarget } from 'react-dnd';
import DragnDrop from 'Constants/DragnDrop';
import KeywordSearchProvider from 'Components/Shared/KeywordSearchProvider/KeywordSearchProvider';
import ChipInput from 'material-ui-chip-input';
import Autocomplete from 'Components/Shared/Autocomplete/Autocomplete';
import { withStyles } from '@material-ui/core';
import { textOperators, numericOperators, operatorTypes } from 'Constants/Rules/RuleConstants';
import { isMultiKeywordOperator, getOperatorType, operatorAllowsEmptyValues } from 'Helpers/OperatorHelpers';
import { TagTypes } from 'Constants/Categorization/Tags';
import { pairTokenizer } from 'Helpers/StringHelpers';
import { styles } from './BuilderInput.styles';

class BuilderInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      requiresSearch: false,
      requiresReset: false,
      textValue: null,
    };

    this.singleLineInputRef = React.createRef();
  }

  onTextChange = ({ target: { value } }) => {
    const {
      operator,
      onChange,
      id,
      tag,
    } = this.props;

    const textValue = value.replace('"', '');

    if (!isMultiKeywordOperator(operator, tag.type)) {
      onChange(textValue, id);
    }

    this.setState({
      textValue,
    });
  }

  setRequiresSearch = () => {
    this.setState({
      requiresSearch: false,
    });
  }

  setRequiresReset = (requiresReset = false) => {
    this.setState({
      requiresReset,
    });
  }

  addChip = (chip) => {
    const {
      operator,
      onChange,
      id,
      tag,
    } = this.props;

    const chipValue = chip.trim();

    if (isMultiKeywordOperator(operator, tag.type)
      && (chipValue.length || operatorAllowsEmptyValues(operator, tag.type))) {
      const chips = this.getChipsFromChildren();
      chips.push(chipValue || ' ');

      onChange(chips.join('","'), id);

      this.setState({
        textValue: null,
      });
    } else {
      onChange(chipValue, id);
    }
  }

  removeChip = (chip, index) => {
    const {
      operator,
      onChange,
      id,
      tag,
    } = this.props;

    if (!isMultiKeywordOperator(operator, tag.type)) {
      return;
    }

    const chips = this.getChipsFromChildren();
    chips.splice(index, 1);

    onChange(chips.join('","'), id);
  }

  getChipsFromChildren = () => {
    const { children } = this.props;

    return children ? pairTokenizer(`"${children}"`) : [];
  }

  render() {
    const {
      keywordPanelAvailable,
      tag,
      connectDropTarget,
      operator,
    } = this.props;
    if (keywordPanelAvailable && operator && (tag.type === TagTypes.Text ||
      tag.type === TagTypes.TextWholeValue)) {
      return connectDropTarget((
        <div>
          {this.renderComponent()}
        </div>
      ));
    }

    return this.renderComponent();
  }

  renderComponent() {
    const {
      tag,
      operator,
      className,
      width,
      placeholder,
      id,
      children,
      classes,
    } = this.props;

    const {
      requiresSearch,
      requiresReset,
      textValue,
    } = this.state;

    const preventKeywordSearch = getOperatorType(operator, tag.type) === operatorTypes.arithmetic;

    return (
      <KeywordSearchProvider
        minQueryLength={3}
        value={textValue || ''}
        tagId={tag.id}
        operator={operator}
        requiresSearch={requiresSearch}
        setRequiresSearch={this.setRequiresSearch}
        setRequiresReset={this.setRequiresReset}
        searchId={id}
        requiresReset={requiresReset}
        preventSearch={preventKeywordSearch}
      >
        {results => (
          <Autocomplete
            rootClassName={className}
            style={{ width: width }}
            suggestions={results}
            onSuggestionChosen={this.addChip}
            placeholder={placeholder}
            blurOnSelection={!isMultiKeywordOperator(operator, tag.type)}
          >
            {(getInputProps, setInputRef) => (
              <Choose>
                <When condition={isMultiKeywordOperator(operator, tag.type)}>
                  <ChipInput
                    value={this.getChipsFromChildren()}
                    onAdd={this.addChip}
                    onDelete={this.removeChip}
                    className={classes.input}
                    clearInputValueOnChange
                    inputRef={setInputRef}
                    disableUnderline
                    disabled={!operator || !tag}
                    classes={{ chip: classes.customChip }}
                    InputProps={{
                      ...getInputProps({
                        onBlur: () => this.setRequiresReset(true),
                        onChange: this.onTextChange,
                        value: this.state.textValue || '',
                      }),
                    }}
                  />
                </When>
                <Otherwise>
                  <input
                    disabled={!operator || !tag}
                    {...getInputProps({
                      ref: setInputRef,
                      type: 'text',
                      className: classes.input,
                      onChange: this.onTextChange,
                      value: children,
                      onBlur: () => this.setRequiresReset(true),
                      autoComplete: 'off',
                    })}
                  />
                </Otherwise>
              </Choose>
            )}
          </Autocomplete>
        )}
      </KeywordSearchProvider>
    );
  }
}

BuilderInput.defaultProps = {
  operator: '',
};

BuilderInput.propTypes = {
  children: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  operator: PropTypes.oneOf(Object.values(textOperators).concat(Object.values(numericOperators).concat(''))),
  keywordPanelAvailable: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  id: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
};

const spec = {
  drop(props) {
    return {
      onDrop: (value, metadata) => props.onDrop(metadata, props.id),
    };
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    hovered: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

export default compose(
  withStyles(styles),
  DropTarget(DragnDrop.operator.keyword, spec, collect),
)(BuilderInput);
