export const CHECK_EXTERNAL_USER_EXISTS = 'CHECK_EXTERNAL_USER_EXISTS';
export const CHECK_EXTERNAL_USER_EXISTS_SUCCESS = 'CHECK_EXTERNAL_USER_EXISTS_SUCCESS';
export const ADD_EXTERNAL_USER = 'ADD_EXTERNAL_USER';
export const ADD_EXTERNAL_USER_RESULT = 'ADD_EXTERNAL_USER_RESULT';
export const UPDATE_STATE_TO_DEFAULT = 'UPDATE_STATE_TO_DEFAULT';

export function checkExternalUserExists(emailAddress) {
  return {
    type: CHECK_EXTERNAL_USER_EXISTS,
    payload: {
      emailAddress: emailAddress,
    },
    loading: true,
  };
}

export function checkExternalUserExistsSuccess(existsUser) {
  return {
    type: CHECK_EXTERNAL_USER_EXISTS_SUCCESS,
    payload: {
      existsUser: existsUser,
    },
    loading: false,
  };
}

export function AddExternalUser(firstname, lastName, emailAddress) {
  return {
    type: ADD_EXTERNAL_USER,
    payload: {
      firstname: firstname,
      lastName: lastName,
      emailAddress: emailAddress,
    },
    loading: true,
  };
}

export function AddExternalUserResult(addUserSuccess) {
  return {
    type: ADD_EXTERNAL_USER_RESULT,
    payload: {
      addUserSuccess: !!addUserSuccess.item1,
      showMessage: true,
      supportEmail: addUserSuccess.item2,
    },
    loading: false,
  };
}

export function setToDefaultState() {
  return {
    type: UPDATE_STATE_TO_DEFAULT,
  };
}
