export const categorizationTypes = {
  allExceptContras: -1,
  uncategorized: 0,
  manual: 1,
  rule: 2,
  machineLearning: 3,
  contra: 4,
  supportingDocument: 5,
  allowedConflicts: 6,
};

export function lineItemCanBeUncategorised(categorizationType) {
  switch (categorizationType) {
    case categorizationTypes.manual:
    case categorizationTypes.machineLearning: {
      return true;
    }

    default: {
      return false;
    }
  }
}