import { put } from 'redux-saga/effects';
import { userActions } from 'Constants/UserActions';
import { resetUploadFile } from 'Store/Areas/FileUpload/FileUploadActions';
import { fileStatuses } from 'Constants/FileStatuses';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import SignalrStrategyHandler from './SignalrStrategyHandler';

export const periodFileUploadHandler = new SignalrStrategyHandler(
  userActions.PeriodFileUpload,
  function* handler(payload, action) {
    if (payload.error) {
      yield this.handleError(action);
    } else {
      yield put(resetUploadFile());
      switch (payload.fileStatus) {
        case fileStatuses.corrupt:
          yield this.handleCorruptFile(action);
          break;
        case fileStatuses.failedScan:
          yield this.handleVirusDetected();
          break;
        case fileStatuses.passedScan:
          yield put(getPeriod(action.selectedPeriodId));
          break;
        default:
      }
    }
  },
);
