export const styles = () => ({
  root: {
    display: 'inline-block',
    fontSize: 12,
    height: 30,
    minWidth: 30,
    lineHeight: '30px',
    textAlign: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    borderRadius: 2,
    margin: 5,
  },
  shadow: {
    '&:hover': {
      boxShadow: '0 2px 4px 0 rgba(128, 128, 128, 0.5)',
    },
  },
  blueBorder: {
    border: 'solid 1px #0091da',
  },
  greyBorder: {
    border: 'solid 1px #d4d4d4',
  },
});
