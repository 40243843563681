const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    minHeight: 0,
    paddingTop: 20,
    width: 560,
    overflowX: 'hidden',
  },
  header: {
    margin: '0 20px 20px',
  },
  buttons: {
    height: 60,
  },
  textBox: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  input: {
    height: 40,
    minWidth: 500,
    borderRadius: 4,
    borderStyle: 'solid',
    borderWidth: 1,
  },
  textInput: {
    padding: '10px 0px 10px 10px',
    fontSize: theme.constants.fontSizes.small,
  },
  validationError: {
    color: 'red',
    fontSize: theme.constants.fontSizes.regular,
  },
});

export default styles;
