export const styles = theme => ({
  root: {
    borderWidth: 1,
    borderColor: theme.palette.primary.greyBorder,
    borderStyle: 'solid',
    borderRadius: 2,
    fontSize: theme.constants.fontSizes.small,
    outline: 'none',
  },
  canDrag: {
    cursor: 'pointer',
  },
  dragSource: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0,
    zIndex: 100,
    right: 0,
  },
  selectedItem: {
    backgroundColor: theme.palette.primary.background,
  },
});
