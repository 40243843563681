export const TRIGGER_WIP_WARNING = 'MENUMODE/TRIGGER_WIP_WARNING';
export const CHANGE_MENU_MODE = 'MENUMODE/CHANGE_MENU_MODE';
export const SET_USER_WIP = 'MENUMODE/SET_USER_WIP';
export const DISMISS_WIP_WARNING = 'MENUMODE/DISMISS_WIP_WARNING';
export const RESET_RETURN_ROUTE = 'MENUMODE/RESET_RETURN_ROUTE';

export function triggerWorkInProgressWarning() {
  return {
    type: TRIGGER_WIP_WARNING,
    userHasDismissedWarning: false,
    menuModeToggled: true,
  };
}

export function changeMenuMode(returnRoute) {
  return {
    type: CHANGE_MENU_MODE,
    userHasDismissedWarning: false,
    menuModeToggled: false,
    workInProgress: false,
    returnRoute: returnRoute,
  };
}

export function setWorkInProgress(workInProgress) {
  return {
    type: SET_USER_WIP,
    workInProgress: workInProgress,
    userHasDismissedWarning: false,
  };
}

export function dismissWorkInProgressWarning() {
  return {
    type: DISMISS_WIP_WARNING,
    userHasDismissedWarning: true,
    menuModeToggled: false,
  };
}

export function resetReturnRoute() {
  return {
    type: RESET_RETURN_ROUTE,
    returnRoute: null,
  };
}
