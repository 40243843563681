export const styles = theme => ({
  header: {
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.regular,
  },
  cellBorder: {
    borderBottomColor: theme.palette.primary.messageBackground,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    fontSize: theme.constants.fontSizes.regular,
  },
});
