const styles = theme => ({
  root: {
    position: 'relative',
    minHeight: 500,
  },
  wrapper: {
    height: 300,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  flex: {
    flexGrow: 1,
  },
  iconImg: {
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
  button: {
    marginTop: 50,
    width: '40%',
  },
  description: {
    marginTop: 50,
    fontSize: theme.constants.fontSizes.large,
  },
});

export default styles;
