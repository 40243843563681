import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme, Paper, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import withCorruptedFileProvider from 'Components/Shared/CorruptedFileProvider/WithCorruptedFileProvider';
import FileUploader from 'Components/Shared/FileUploader/FileUploader';
import { requestUploadExternalReviewFile, toggleCorruptedFileDetectedModal } from 'Store/Areas/FileUpload/FileUploadActions';
import { externalReviewFileLoadingSelector } from 'Store/Areas/Export/ExportSelectors';
import UploadDropzoneContent from 'Components/Upload/Shared/UploadDropzoneContent';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { xlsFileTypes } from 'Constants/FileTypes';
import { strings as uploadStrings } from 'Constants/Upload/Strings';
import { strings } from 'Constants/Export/Strings';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import { styles } from './ExternalReviewImportDatasheet.styles';

class ExternalReviewImportDatasheet extends React.PureComponent {
  onFileSelected = (file) => {
    const { dispatch, periodId } = this.props;
    dispatch(requestUploadExternalReviewFile(file, periodId));
  }

  onFileRejected = () => {
    this.props.dispatch(toggleCorruptedFileDetectedModal({
      showModal: true,
      fileTypes: xlsFileTypes,
    }));
  }

  render() {
    const {
      classes,
      onCancelExternalReview,
      fileLoading,
    } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paperContainer}>
          <Grid item xs={12} className={classes.buttonContent}>
            <button
              className={classes.button}
              onClick={() => onCancelExternalReview()}
            >
              {strings.cancelExternalReview}
            </button>
          </Grid>
          <Grid item xs={12} >
            <div className={classes.note}>
              {strings.exportUncategorisedDataStep2}
            </div>
          </Grid>
          <Grid item xs={9} >
            <div className={classes.description}>
              {strings.exportUncategorisedDataStep2Description}
            </div>
          </Grid>
          <Grid item xs={12} >
            <Choose>
              <When condition={!fileLoading}>
                <FileUploader
                  className={classes.flex}
                  onFileSelected={files => this.onFileSelected(files[0])}
                  onDropRejected={this.onFileRejected}
                  fileTypes={xlsFileTypes}
                >
                  <UploadDropzoneContent
                    description={uploadStrings.dragAndDropDescriptionTemplate}
                    buttonDescription={uploadStrings.templateUploadButtonDescription}
                  />
                </FileUploader>
              </When>
              <Otherwise>
                <div className={classes.loader}>
                  <CircularLoader />
                </div>
              </Otherwise>
            </Choose>
          </Grid>
        </Paper>
      </div>
    );
  }
}

ExternalReviewImportDatasheet.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onCancelExternalReview: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  fileUpload: PropTypes.shape({
    uploadResponse: PropTypes.object,
    showCorruptedFileModal: PropTypes.bool,
    checkingForViruses: PropTypes.bool,
    fileContainsVirus: PropTypes.bool,
    isXlsOnly: PropTypes.bool,
  }).isRequired,
  fileLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
    fileLoading: externalReviewFileLoadingSelector(state),
    fileUpload: state.app.fileUpload,
  };
}

export default compose(
  withCorruptedFileProvider(),
  connect(mapStateToProps),
  withStyles(styles),
  withTheme(),
)(ExternalReviewImportDatasheet);