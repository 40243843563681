import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getSettingsData, updateSettingsData } from 'Store/Areas/Settings/SettingsActions';
import { allSettingsSelector } from 'Store/Areas/Settings/SettingsSelector';
import {
  withStyles,
  withTheme,
  Card,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Input,
} from '@material-ui/core';
import Checkbox from 'Components/Shared/Checkbox/Checkbox';
import AuthorisedComponent, { constants as permissions } from 'Components/Shared/AuthorisedComponent/AuthorisedComponent';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import Message from 'Components/Shared/Message/Message';
import TableRowMessage from 'Components/Shared/TableRowMessage/TableRowMessage';
import { strings } from 'Constants/Settings/Strings';
import { strings as adminStrings } from 'Constants/Setup/Admin/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { settingDataTypes } from 'Constants/Settings/SettingDataTypes';
import { sort } from 'Helpers/ArrayHelpers';
import { styles } from './Settings.styles';

class Settings extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      settings: {},
    };
  }
  componentDidMount() {
    this.props.dispatch(getSettingsData());
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.handleUpdateStateWithSettings();
    }
  }

  onBooleanChanged = (checked, id) => {
    this.setSettingValue(id, checked);
  }

  onStringChanged = ({ target: { id, value } }) => {
    this.setSettingValue(id, value);
  }

  setSettingValue(id, value) {
    const { settings } = this.state;
    settings[id] = value;
    this.setState({ settings: { ...settings } });
  }

  handleUpdateStateWithSettings() {
    const { data } = this.props;
    const settings = {};
    data.forEach((x) => {
      if (x.settingDataType === settingDataTypes.boolean) {
        settings[x.id] = x.settingValue ? (x.settingValue.toLowerCase() === 'true') : false;
      } else if (x.settingDataType === settingDataTypes.integer) {
        settings[x.id] = Number(x.settingValue || 0);
      } else {
        settings[x.id] = x.settingValue;
      }
    });
    this.setState({ settings });
  }

  saveSettings = () => {
    const { settings } = this.state;
    const data = Object.keys(settings).map((key) => {
      return ({ id: Number(key), settingValue: settings[key] });
    });
    this.props.dispatch(updateSettingsData(data));
  }

  render() {
    const { classes } = this.props;
    const { settings } = this.state;
    const requiredPermissions = permissions.permissionLevels.requireSysAdmin;

    const data = sort(this.props.data, x => x.settingName, true, false);

    return (
      <div className={classes.root}>
        <React.Fragment>
          <AuthorisedComponent requiredPermissions={requiredPermissions}>
            <div className={classes.root}>
              <div className={classes.titleContainer}>
                <span className={classes.description}>
                  {adminStrings.youAreIn}<b>{adminStrings.adminMode}</b>
                </span>
              </div>
              <Card className={classes.card}>
                <Message
                  title={commonStrings.didYouKnow}
                  className={classes.message}
                  contentClassName={classes.messageContent}
                  message={strings.didYouKnowSettingsMessage}
                />
                <div>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.header}>
                          {strings.settingNameHeader}
                        </TableCell>
                        <TableCell className={classes.header}>
                          {strings.settingValueHeader}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <Choose>
                        <When condition={data.length}>
                          {data.map((item) => {
                            return (
                              <TableRow key={item.id}>
                                <TableCell className={classes.text}>
                                  {item.settingName}
                                </TableCell>
                                <Choose>
                                  <When condition={
                                      item.settingDataType === settingDataTypes.boolean
                                    }
                                  >
                                    <TableCell className={classes.checkbox}>
                                      <Checkbox
                                        id={`${item.id}`}
                                        onChange={this.onBooleanChanged}
                                        checked={settings[item.id]}
                                      />
                                    </TableCell>
                                  </When>
                                  <When condition={
                                      item.settingDataType === settingDataTypes.integer
                                    }
                                  >
                                    <TableCell className={classes.textBox}>
                                      <Input
                                        className={classes.input}
                                        classes={{ input: classes.textInput }}
                                        id={`${item.id}`}
                                        type="number"
                                        value={Number(settings[item.id] || 0)}
                                        placeholder={strings.noSettingValue}
                                        onChange={this.onStringChanged}
                                      />
                                    </TableCell>
                                  </When>
                                  <Otherwise>
                                    <TableCell className={classes.textBox}>
                                      <Input
                                        className={classes.input}
                                        classes={{ input: classes.textInput }}
                                        id={`${item.id}`}
                                        value={settings[item.id] || ''}
                                        placeholder={strings.noSettingValue}
                                        onChange={this.onStringChanged}
                                      />
                                    </TableCell>
                                  </Otherwise>
                                </Choose>
                              </TableRow>
                            );
                          })}
                        </When>
                        <Otherwise>
                          <TableRowMessage text={strings.noSettingsFound} />
                        </Otherwise>
                      </Choose>
                    </TableBody>
                  </Table>
                </div>
                <Button
                  backgroundColor={constants.backgroundColor.main}
                  className={classes.button}
                  onClick={this.saveSettings}
                >
                  {commonStrings.saveChanges}
                </Button>
              </Card>
            </div>
          </AuthorisedComponent>
        </React.Fragment>
      </div>
    );
  }
}

Settings.defaultProps = {
  data: [],
};

Settings.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    name: PropTypes.string,
    emailAddress: PropTypes.string,
    isSysAdmin: PropTypes.bool,
    isGroupAdmin: PropTypes.bool,
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    settingName: PropTypes.string.isRequired,
    settingDataType: PropTypes.number.isRequired,
    settingValue: PropTypes.string,
  })),
};

function mapStateToProps(state) {
  return {
    permissions: state.user.permissions.data,
    data: allSettingsSelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(Settings);
