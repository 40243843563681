import {
  GET_NEXT_CONFLICT,
  GET_NEXT_CONFLICT_SUCCESS,
  PUT_ALLOW_CONFLICT,
  PUT_ALLOW_ALL_CONFLICT,
  PUT_DELETE_CONFLICT,
  PUT_RESOLVE_CONFLICT,
  SET_CONFLICT_ACTION,
  RESET_CONFLICT,
} from 'Store/Areas/Conflict/ConflictActions';

import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';

const initialState = {
  loading: true,
  success: false,
  periodId: 0,
  conflicted: false,
  conflicts: [],
  prevTotalNumberOfConflicts: 0,
  totalNumberOfConflicts: 0,
  isConflictAction: false,
};

export default function conflictReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NEXT_CONFLICT:
      return Object.assign({}, initialState, {
        periodId: action.payload.periodId,
        prevTotalNumberOfConflicts: state.totalNumberOfConflicts,
      });
    case GET_NEXT_CONFLICT_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        conflicted: action.conflicted,
        conflicts: action.conflicts,
        totalNumberOfConflicts: action.totalNumberOfConflicts,
      });
    case PUT_RESOLVE_CONFLICT:
    case PUT_ALLOW_CONFLICT:
    case PUT_ALLOW_ALL_CONFLICT:
    case PUT_DELETE_CONFLICT:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        isConflictAction: action.isConflictAction,
      });
    case SET_CONFLICT_ACTION:
      return Object.assign({}, state, {
        isConflictAction: action.isConflictAction,
      });
    case RESET_PERIOD:
    case RESET_CONFLICT:
      return initialState;
    default:
      return state;
  }
}
