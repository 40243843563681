import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Paper, IconButton } from '@material-ui/core';
import { compose } from 'recompose';
import CloseIcon from 'Assets/Images/exit-ic.svg';

class Message extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
    };
  }

  onClose() {
    this.setState({
      open: false,
    });
  }

  render() {
    const { open } = this.state;
    const {
      classes,
      className,
      title,
      message,
    } = this.props;

    if (!open) {
      return (<span />);
    }

    return (
      <Paper className={className}>
        <div className={classes.headerContainer}>
          <div className={classes.title}>
            {title}
          </div>
          <IconButton className={classes.close} onClick={() => this.onClose()}>
            <img alt="close" src={CloseIcon} />
          </IconButton>
        </div>
        <div className={classes.message}>
          {message}
        </div>
      </Paper>
    );
  }
}

Message.defaultProps = {
  className: '',
  open: true,
  title: '',
  message: '',
};

Message.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string, // classes to apply to the root element
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
};

const styles = theme => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  close: {
    width: 'auto',
    height: 'auto',
    marginTop: 10,
    marginRight: 10,
    'text-align': 'right',
  },
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
  },
  message: {
    fontSize: theme.constants.fontSizes.regular,
    color: '#666666',
    marginLeft: 20,
    marginRight: 20,
    paddingBottom: 20,
    paddingTop: 4,
    minHeight: '3.5em',
  },
});

export default compose(
  withStyles(styles),
  withTheme(),
)(Message);
