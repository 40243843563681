import { takeLatest, put, cancelled } from 'redux-saga/effects';
import { strings } from 'Constants/Error/Strings';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { REQUEST_BULK_USER_UPLOAD_SUCCESS, setBulkUserUploadStage } from 'Store/Areas/BulkUpload/BulkUserUploadActions';
import { watchSignalREvents } from 'Store/Api/CallSignalR';

function* handleSignalRMessage(payload) {
  yield put(payload.error
    ? displayToastNotification(strings.errorMessageRetry)
    : setBulkUserUploadStage(payload));
}

function* bulkUserUploadSignalRSaga(action) {
  yield watchSignalREvents({
    action,
    processSignalRPayload: {
      hubUrl: `user?groupid=admin-${action.userId}`,
      name: 'ReportImportProgress',
      endConnection: (connection, name) => connection.off(name),
    },
    handleSignalRMessage,
    cancelled,
  });
}

export function* bulkUserUploadSignalRSagas() {
  yield takeLatest(REQUEST_BULK_USER_UPLOAD_SUCCESS, bulkUserUploadSignalRSaga);
}
