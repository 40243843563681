import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import { styles } from './SimpleHeader.styles';

class SimpleHeader extends React.Component {
  render() {
    const {
      classes, children, style, className, padLeft, padRight, padding, width,
    } = this.props;
    return (
      <span
        className={`${classes.root} ${className}`}
        role="button"
        tabIndex="-1"
        style={{
          ...style,
          width: width - (padLeft ? padding : 0) - (padRight ? padding : 0),
          paddingLeft: padLeft ? padding : 0,
          paddingRight: padRight ? padding : 0,
        }}
      >
        {children}
      </span>
    );
  }
}

SimpleHeader.defaultProps = {
  className: '',
  padding: 10,
  padLeft: false,
  padRight: false,
};

SimpleHeader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  padding: PropTypes.number,
  padLeft: PropTypes.bool,
  padRight: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(SimpleHeader);
