import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  withStyles,
  withTheme,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import StickyTable from 'Components/Shared/Table/StickyTable';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { getPreviewRows } from 'Store/Areas/Import/PreviewActions';
import { checkDataType } from 'Constants/Upload/DataTypeHelpers';
import { compose } from 'recompose';
import { selectMappedTagsFromPeriod } from 'Constants/Categorization/Tags';
import { supporting, main } from 'Constants/FileTypes';
import { formatCellValue } from 'Helpers/LineItemHelpers';
import styles from './PreviewTable.styles';
import PreviewTableHeader from './PreviewTableHeader';

class PreviewTable extends React.PureComponent {
  componentDidMount() {
    const {
      dispatch,
      periodId,
      fileType,
    } = this.props;
    dispatch(getPreviewRows(periodId, fileType));
  }

  static getCell(cell) {
    return formatCellValue(cell);
  }

  getTag(tagId) {
    const { tags } = this.props;

    const matchedTags = tags.filter(t => (t.id === tagId));
    if (matchedTags.length === 1) {
      return matchedTags[0];
    }
    return null;
  }

  canDropStyle(header) {
    const {
      dragTagType,
      expectedDataTypes,
      classes,
      tags,
    } = this.props;

    const isMapped = tags.filter(t => (t.id === header.tagId)).length === 1;

    if (dragTagType === null) {
      return '';
    }

    if (!checkDataType(dragTagType, expectedDataTypes[header.name])
    || isMapped) {
      return classes.cellCantDrop;
    }

    return '';
  }

  render() {
    const {
      classes,
      loading,
      rows,
      expectedDataTypes,
      headers,
      fileType,
    } = this.props;

    const sortedHeaders = headers.sort((a, b) => { return a.columnIndex - b.columnIndex; });
    const headerStyleMap = {};
    headers.forEach((header) => {
      headerStyleMap[header.name] = this.canDropStyle(header);
    });

    return (
      <div className={classes.tableContainer}>
        <StickyTable bodyHeight={300}>
          <TableHead>
            <TableRow>
              <For each="header" of={sortedHeaders}>
                <TableCell className={`${classes.cell} ${classes.columnHeader} ${headerStyleMap[header.name]}`} key={header.id}>
                  <PreviewTableHeader
                    fileType={fileType}
                    header={header}
                    tag={this.getTag(header.tagId)}
                    expectedDataType={expectedDataTypes[header.name]}
                  />
                </TableCell>
              </For>
            </TableRow>
          </TableHead>
          <TableBody>
            <For each="row" of={rows}>
              <TableRow key={row.rowId}>
                <For each="header" of={headers}>
                  <TableCell className={`${classes.cell} ${headerStyleMap[header.name]}`} key={`${row.rowId}_${header.name}`}>
                    <If condition={header.name in row.cells}>
                      {PreviewTable.getCell(row.cells[header.name])}
                    </If>
                  </TableCell>
                </For>
              </TableRow>
            </For>
          </TableBody>
        </StickyTable>
        <If condition={loading}>
          <div key="loading-icon" className={classes.loaderIcon}><CircularLoader /></div>
        </If>
      </div>
    );
  }
}

PreviewTable.defaultProps = {
  dragTagType: '',
};

PreviewTable.propTypes = {
  fileType: PropTypes.oneOf([supporting, main]).isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    tagId: PropTypes.int,
    id: PropTypes.int,
  })).isRequired,
  periodId: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  expectedDataTypes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  loading: PropTypes.bool.isRequired,
  dragTagType: PropTypes.string,
};


function mapStateToProps(state, componentProps) {
  const { period } = state.periods;
  const { preview } = state.import;
  const { dragTagType } = state.import.tagMapping.mapTag;
  return {
    tags: selectMappedTagsFromPeriod(state),
    headers: period.data.fileSchema.headers.filter(x => x.fileType === componentProps.fileType),
    periodId: period.periodId,
    rows: preview.rows,
    expectedDataTypes: preview.expectedDataTypes,
    loading: preview.loading,
    dragTagType: dragTagType,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(PreviewTable);
