import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme, Card } from '@material-ui/core';
import { connect } from 'react-redux';
import { strings } from 'Constants/BulkUpload/Strings';
import greenTick from 'Assets/Images/green-tick.svg';
import redCross from 'Assets/Images/exit-ic-red.svg';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import { resetBulkGroupEntityProjectUploadState } from 'Store/Areas/BulkUpload/BulkGroupEntityProjectUploadActions';
import { bulkGroupEntityProjectUploadImportStages } from 'Constants/BulkUpload/BulkGroupEntityProjectUploadStages';
import styles from './BulkGroupEntityProjectUploadResult.styles';

class BulkGroupEntityProjectUploadResult extends React.PureComponent {
  onProceedClick = () => {
    const { dispatch } = this.props;
    dispatch(resetBulkGroupEntityProjectUploadState());
    dispatch(push(routes.bulkUpload));
  }
  render() {
    const {
      classes,
      bulkGroupEntityProjectUpload,
    } = this.props;
    return (
      <Card className={classes.card}>
        <div className={classes.content}>
          <Choose>
            <When condition={
              bulkGroupEntityProjectUpload.stage ===
              bulkGroupEntityProjectUploadImportStages.bulkGroupEntityProjectsUploaded
              }
            >
              <div className={classes.title}>
                <img alt="" className={classes.tick} src={greenTick} />
                {strings.bulkGroupEntityProjectUploadSuccessful}
              </div>
              <div className={classes.textArea}>
                <div className={classes.note}>
                  {strings.bulkGroupEntityProjectUploadSuccessfulNote}
                </div>
                {bulkGroupEntityProjectUpload.hasImportMessages &&
                <div>
                  <h4>{strings.warnings}</h4>
                  <h5>{strings.itemWarnings}</h5>
                  {bulkGroupEntityProjectUpload.importMessages.map((x) => {
                    return <p>{x}</p>;
                  })}
                </div>
                }
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  disableRipple
                  backgroundColor={constants.backgroundColor.main}
                  className={classes.button}
                  onClick={this.onProceedClick}
                >
                  {strings.outcomeBulkGroupEntityProjectUploadButton}
                </Button>
              </div>
            </When>
            <Otherwise>
              <div className={classes.title}>
                <img alt="" className={classes.tick} src={redCross} />
                {strings.bulkGroupEntityProjectUploadFailed}
              </div>
              <div className={classes.textArea}>
                <div className={classes.note}>
                  {strings.bulkGroupEntityProjectUploadFailedNote}
                </div>
                {bulkGroupEntityProjectUpload.hasImportMessages &&
                <div>
                  <h4>Errors</h4>
                  {bulkGroupEntityProjectUpload.importMessages.map((x) => {
                    return <p>{x}</p>;
                  })}
                </div>
                }
                <div />
              </div>
              <div className={classes.buttonContainer}>
                <Button
                  disableRipple
                  backgroundColor={constants.backgroundColor.main}
                  className={classes.button}
                  onClick={this.onProceedClick}
                >
                  {strings.outcomeBulkGroupEntityProjectUploadButton}
                </Button>
              </div>
            </Otherwise>
          </Choose>
        </div >
      </Card >
    );
  }
}

BulkGroupEntityProjectUploadResult.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  bulkGroupEntityProjectUpload: PropTypes.shape({
    stage: PropTypes.number.isRequired,
    hasImportMessages: PropTypes.bool.isRequired,
    importMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    bulkGroupEntityProjectUpload: state.bulkGroupEntityProjectUpload,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(BulkGroupEntityProjectUploadResult);
