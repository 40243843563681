const styles = {
  unselected: {
    width: 20,
    height: 20,
    borderRadius: 10,
  },
  selected: {
    width: 16,
    height: 16,
    borderRadius: 10,
    boxShadow: '0 2px 4px 0 rgba(153, 153, 153, 0.5)',
    border: 'solid 2px #ffffff',
  },
};

export default styles;
