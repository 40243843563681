const styles = theme => ({
  root: {
    padding: 20,
    paddingTop: 40,
  },
  titleContainer: {
    display: 'flex',
    marginBottom: 10,
  },
  description: {
    fontSize: theme.constants.fontSizes.regular,
    color: theme.palette.primary.darkText,
  },
  title: {
    flexGrow: 1,
    fontSize: theme.constants.fontSizes.large,
    color: theme.palette.primary.darkText,
  },
  message: {
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.regular,
    paddingBottom: 15,
  },
  card: {
    padding: 40,
    maxHeight: 400,
    marginBottom: 30,
  },
  tableCard: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 550px)',
    minHeight: 300,
    borderRadius: '0 10px 10px 10px',
  },
  templateTableWrapper: {
    flexGrow: 1,
  },
  bulkUploadTypeContainer: {
    position: 'absolute',
    right: 60,
    top: 125,
  },
});

export default styles;
