import React from 'react';
import { CircularProgress, withTheme, withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styles from './CircularLoader.styles';

class Loader extends React.PureComponent {
  getPrimaryColorClasses() {
    const { color, classes } = this.props;
    switch (color) {
      case 'dark':
        return classes.dark;
      case 'white':
        return classes.white;
      default:
        return classes.main;
    }
  }
  render() {
    const {
      classes,
      size,
      className,
      fullWidth,
    } = this.props;

    const colorPrimary = this.getPrimaryColorClasses();
    return (
      <div className={`${classes.root} ${className} ${fullWidth && classes.fullWidth}`}>
        <CircularProgress size={size} classes={{ colorPrimary: colorPrimary }} />
      </div>
    );
  }
}

Loader.defaultProps = {
  size: 40,
  color: 'main',
  className: '',
  fullWidth: true,
};

Loader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  size: PropTypes.number,
  color: PropTypes.oneOf(['main', 'dark', 'white']),
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};

export default compose(
  withTheme(),
  withStyles(styles),
)(Loader);

