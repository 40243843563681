export const styles = theme => ({
  root: {
    height: '100%',
    position: 'relative',
  },
  handle: {
    opacity: 0.3,
    '&:hover': {
      opacity: 0.7,
    },
    width: 'Calc(100% - 10px)',
    height: 'Calc(100% - 10px)',
    margin: 5,
    borderRadius: 2,
  },
  complete: {
    backgroundColor: theme.palette.primary.main,
  },
  incomplete: {
    backgroundColor: theme.palette.primary.darkText,
  },
  dragLayer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 10,
  },
});
