import { takeLatest, put, cancelled } from 'redux-saga/effects';
import { strings } from 'Constants/Error/Strings';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { REQUEST_UPLOAD_TEMPLATE_SUCCESS, setUploadTemplateStage } from 'Store/Areas/Template/UploadTemplateActions';
import { watchSignalREvents } from 'Store/Api/CallSignalR';

function* handleSignalRMessage(payload) {
  yield put(payload.error
    ? displayToastNotification(strings.errorMessageRetry)
    : setUploadTemplateStage(payload));
}

function* uploadTemplateSignalRSaga(action) {
  yield watchSignalREvents({
    action,
    processSignalRPayload: {
      hubUrl: `user?groupid=admin-${action.userId}`,
      name: 'ReportImportProgress',
      endConnection: (connection, name) => connection.off(name),
    },
    handleSignalRMessage,
    cancelled,
  });
}

export function* uploadTemplateSignalRSagas() {
  yield takeLatest(REQUEST_UPLOAD_TEMPLATE_SUCCESS, uploadTemplateSignalRSaga);
}
