export const NEW_USER_SESSION = 'CONFIG/NEW_USER_SESSION';
export const NEW_USER_SESSION_SUCCESS = 'CONFIG/NEW_USER_SESSION_SUCCESS';
export const USER_SESSION_LOGOUT = 'CONFIG/USER_SESSION_LOGOUT';
export const RESET_PERIOD = 'CONFIG/RESET_PERIOD';
export const SAVE_USER_AUTH_CONTEXT = 'CONFIG/SAVE_USER_AUTH_CONTEXT';
export const SET_LOGOUT = 'CONFIG/SET_LOGOUT';

export function newUserSession() {
  return {
    type: NEW_USER_SESSION,
  };
}

export function newUserSessionSuccess() {
  return {
    type: NEW_USER_SESSION_SUCCESS,
  };
}

export function userSessionLogout() {
  return {
    type: USER_SESSION_LOGOUT,
  };
}

export function resetPeriodLock(page) {
  return {
    type: RESET_PERIOD,
    payload: {
      page: page,
    },
  };
}

export function saveAuthContext(authContext) {
  return {
    type: SAVE_USER_AUTH_CONTEXT,
    authContext: authContext,
  };
}

export function setLogout(isLoggingOut) {
  return {
    type: SET_LOGOUT,
    isLoggingOut: isLoggingOut,
  };
}
