export const styles = theme => ({
  root: {
    borderRadius: 3,
    border: 'dashed 1px #c0c0c0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    margin: '10px 0 0 0',
  },
  cantDrop: {
    border: 'solid 1px transparent', // invisible border to prevent weird layout changes on re-render
  },
  hovered: {
    backgroundColor: `${theme.palette.primary.messageBackground} !important`,
    opacity: '0.8 !important',
  },
});
