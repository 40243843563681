import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles, withTheme, MenuItem, Select as MuiSelect } from '@material-ui/core';
import { styles } from './DisabledSelect.styles';
import Chevron from '../../../Assets/Images/chevron-down-ic.svg';

class DisabledSelect extends React.PureComponent {
  render() {
    const {
      classes,
      text,
      className,
      label,
      selectClassName,
    } = this.props;
    return (
      <div className={className}>
        <div className={classes.label}>{label}</div>
        <MuiSelect
          disableUnderline
          classes={{ root: `${classes.input} ${selectClassName}`, selectMenu: classes.menu, select: classes.noPadding }}
          fullWidth
          IconComponent={() => <img src={Chevron} alt="Select" className={classes.icon} />}
          value={0}
          MenuProps={{
            MenuListProps: {
              className: classes.noPadding,
            },
            open: false,
          }}
        >
          <MenuItem
            selected={false}
            value={0}
            className={classes.menuItem}
          >
            {text}
          </MenuItem>
        </MuiSelect>
      </div>
    );
  }
}

DisabledSelect.defaultProps = {
  text: '',
  className: '',
  label: '',
  selectClassName: '',
};

DisabledSelect.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  label: PropTypes.string,
};

export default compose(withStyles(styles), withTheme())(DisabledSelect);
