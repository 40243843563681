import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { processingSelector } from 'Store/Areas/Rule/CategorisationSelectors';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import ConflictResolver from 'Components/Conflicts/ConflictResolver/ConflictResolver';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Sidebar from 'Components/UserSidebar/Sidebar';
import PropTypes from 'prop-types';
import { getNextConflict, putResolveConflict, putAllowConflict, putAllowAllConflict, putDeleteConflict } from 'Store/Areas/Conflict/ConflictActions';
import { strings } from 'Constants/Categorization/Strings';
import { title } from 'Constants/App/Titles/Strings';

class ConflictManager extends React.PureComponent {
  componentDidMount() {
    document.title = title.conflicts;
    this.getNextConflict();
    this.props.dispatch(getTreePickerData());
  }

  onResolve = (winningRuleId) => {
    const {
      dispatch,
      period: { periodId },
      periodConflicts: { conflicts },
    } = this.props;

    const winningRule = conflicts.find(x => x.id === winningRuleId);
    const losingRules = conflicts.filter(x => x.id !== winningRuleId);

    dispatch(putResolveConflict(
      periodId,
      winningRuleId,
      this.getRuleText(winningRule),
      losingRules.map(x => x.id),
      losingRules.map(x => this.getRuleText(x)),
    ));
  }

  onAllow = (ruleIds) => {
    const {
      dispatch,
      period: { periodId },
      periodConflicts: { conflicts },
    } = this.props;

    dispatch(putAllowConflict(
      periodId,
      ruleIds,
      conflicts.filter(x => ruleIds.includes(x.id)).map(x => this.getRuleText(x)),
    ));
  }

  onAllAllow = () => {
    const {
      dispatch,
      period: { periodId },
    } = this.props;

    dispatch(putAllowAllConflict(periodId));
  }

  onDelete = (ruleIds) => {
    const {
      dispatch,
      period: { periodId },
      periodConflicts: { conflicts },
    } = this.props;

    dispatch(putDeleteConflict(
      periodId,
      ruleIds,
      conflicts.filter(x => ruleIds.includes(x.id)).map(x => this.getRuleText(x)),
    ));
  };

  getNextConflict() {
    const {
      dispatch,
      period: { periodId },
    } = this.props;

    dispatch(getNextConflict(periodId));
  }

  getRuleText(rule) {
    return strings.ruleCategorisationString(rule.rule, rule.categories.map(c => c.categoryName));
  }

  render() {
    const {
      period,
      projects,
      periodConflicts,
      rulePriority,
      currencySymbol,
      processing,
    } = this.props;

    const sidebarLoading = projects.treePicker.loading;
    const mainContentLoading =
      processing
      || !period
      || !period.data
      || !period.periodId
      || !period.data.hasConflicts
      || period.loading
      || periodConflicts.loading
      || (!!rulePriority.periodId && rulePriority.loading);

    return (
      <UserSidebarStructure
        isCollapsible
        collapsed
        sidebarLoading={sidebarLoading}
        mainContentLoading={mainContentLoading}
        sidebarContent={
          <Sidebar />
        }
        mainContent={
          <ConflictResolver
            periodId={period.periodId}
            periodConflicts={periodConflicts}
            rulePriority={rulePriority}
            currencySymbol={currencySymbol}
            getRuleText={this.getRuleText}
            onResolve={this.onResolve}
            onDelete={this.onDelete}
            onAllow={this.onAllow}
            onAllAllow={this.onAllAllow}
          />
        }
      />
    );
  }
}

ConflictManager.propTypes = {
  dispatch: PropTypes.func.isRequired,
  period: PropTypes.shape({
    periodId: PropTypes.number.isRequired,
    data: PropTypes.shape({
      categories: PropTypes.array,
    }),
  }).isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  periodConflicts: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    conflicted: PropTypes.bool.isRequired,
    conflicts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      rule: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.shape({
        categoryId: PropTypes.number,
        categoryName: PropTypes.string,
      })),
    })).isRequired,
    prevTotalNumberOfConflicts: PropTypes.number.isRequired,
    totalNumberOfConflicts: PropTypes.number.isRequired,
  }).isRequired,
  rulePriority: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    success: PropTypes.bool.isRequired,
    ruleText: PropTypes.string,
    ruleId: PropTypes.number,
    priorityLevel: PropTypes.number,
    open: PropTypes.bool.isRequired,
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    period: state.periods.period,
    periodConflicts: state.periods.periodConflicts,
    rulePriority: state.rules.rulePriority,
    currencySymbol: currencySymbolSelector(state),
    processing: processingSelector(state),
  };
}

export default compose(connect(mapStateToProps))(ConflictManager);
