import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  withStyles,
  withTheme,
  Paper,
  Grid,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import { title } from 'Constants/App/Titles/Strings';
import { strings } from 'Constants/PeriodImportDetails/Strings';
import { periodIdSelector, periodIsLockedSelector, periodIsExternalReviewLockedSelector } from 'Store/Areas/Period/PeriodSelectors';
import StickyTable from 'Components/Shared/Table/StickyTable';
import { getPeriodImportDetails } from 'Store/Areas/Import/PeriodImportDetailsActions';
import { requestChangeSupportingDocsAfterPeriodCreation } from 'Store/Areas/Period/PeriodActions';
import { commonStrings } from 'Constants/CommonStrings';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import InfiniteScroll from 'react-infinite-scroller';
import Sidebar from 'Components/UserSidebar/Sidebar';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import { routes } from 'Constants/Routes';
import UploadBreadcrumb, { constants as uploadConstants } from 'Components/Shared/UploadBreadcrumb/UploadBreadcrumb';
import styles from './PeriodImportDetails.styles';

class PeriodImportDetails extends React.PureComponent {
  componentDidMount() {
    const {
      periodId,
      dispatch,
    } = this.props;

    document.title = title.periodImportDetails;
    dispatch(getPeriodImportDetails(periodId));
  }

  onMakeChangeButtonClick = () => {
    const { dispatch } = this.props;
    dispatch(requestChangeSupportingDocsAfterPeriodCreation(true));
    dispatch(push(routes.import.upload));
  }

  getSummaryItem = (name, description) => {
    const { classes } = this.props;
    return (
      <Grid container justify="flex-end">
        <Grid item xs={12} >
          <div className={classes.title}>
            {name}
          </div>
        </Grid>
        <Grid item xs={9} >
          <div className={`${classes.summaryItemDescription} ${classes.commonDescription}`}>
            {description}
          </div>
        </Grid>
      </Grid>
    );
  }

  getTagTable = (tagNames) => {
    const { classes } = this.props;
    return (
      <StickyTable bodyHeight={200}>
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.headerText} ${classes.tableCell}`} key={strings.tagNameHeader}>
              {strings.tagNameHeader}
            </TableCell>
            <TableCell className={`${classes.headerText} ${classes.tableCell}`} key={strings.documentColumnName}>
              {strings.documentColumnName}
            </TableCell>
            <TableCell className={`${classes.headerText} ${classes.tableCell}`} key={strings.tagTypeHeader}>
              {strings.tagTypeHeader}
            </TableCell>
            <TableCell className={`${classes.headerText} ${classes.tableCell}`} key={strings.isNecessary}>
              {strings.isNecessary}
            </TableCell>
          </TableRow>
        </TableHead>
        <InfiniteScroll
          element="tbody"
          initialLoad={false}
          pageStart={0}
          loadMore={() => null}
          hasMore={false}
          useWindow={false}
          threshold={10}
        >
          {tagNames.map((tag, rowIndex) => {
            const rowKey = `${tag.name}_${rowIndex}`;
            const cellIndexTagName = `${rowKey}_${strings.tagNameHeader}`;
            const cellIndexTagType = `${rowKey}_${strings.tagTypeHeader}`;
            const cellIndexTagDescription = `${rowKey}_${strings.documentColumnName}`;
            const cellIndexTagMandatory = `${rowKey}_${strings.isNecessary}`;
            return (
              <TableRow key={rowKey}>
                <TableCell className={classes.tableCell} key={cellIndexTagName}>
                  {tag.name}
                </TableCell>
                <TableCell className={classes.tableCell} key={cellIndexTagDescription}>
                  {tag.description}
                </TableCell>
                <TableCell className={classes.tableCell} key={cellIndexTagType}>
                  {tag.tagType}
                </TableCell>
                <TableCell className={classes.tableCell} key={cellIndexTagMandatory}>
                  {tag.isMandatory ? commonStrings.yes : commonStrings.no}
                </TableCell>
              </TableRow>);
          })}
        </InfiniteScroll>
      </StickyTable>
    );
  }

  getSummaryItemDescription = (items, isArrayComplex = true) => {
    if (items === undefined || items.length === 0) { return ''; }
    if (!isArrayComplex) { return items.join(', '); }
    const newItems = items.map((i) => {
      return `${i.name} [${i.description}] `;
    });
    return newItems.join(', ');
  }

  getSummaryDescription = () => {
    const { importDetails } = this.props.summary;
    const {
      supportingDocNames,
      tagNames,
      referenceListNames,
    } = importDetails;
    return `${supportingDocNames.length} ${strings.supportingDocText}, ${referenceListNames.length} ${strings.referenceListsText}, ${tagNames.length} ${strings.mappedTagsText} `;
  }

  render() {
    const {
      classes,
      projects,
      summary,
      isPeriodLocked,
    } = this.props;

    const sidebarLoading = projects.treePicker.loading;
    const { loading, importDetails, isRedirectedFromReviewPage } = summary;
    const {
      mainDocName,
      supportingDocNames,
      tagNames,
      categoryNames,
      referenceListNames,
      originalTemplate,
    } = importDetails;
    return (
      <UserSidebarStructure
        mainContent={
          <Fragment>
            <Choose>
              <When condition={!loading}>
                <div>
                  <UploadBreadcrumb
                    index={uploadConstants.indexes.periodImportDetails}
                    isRedirected={isRedirectedFromReviewPage}
                  />
                  <Paper className={`${classes.importedDocs} ${classes.commonPaddingAndMargin}`}>
                    <Grid container direction="row" alignItems="stretch">
                      <Grid container justify="flex-end">
                        <Grid item xs={12} >
                          <div className={classes.title}>
                            {strings.importedDocsText}
                          </div>
                        </Grid>
                        <Grid item xs={9} >
                          <div className={`${classes.summaryItemDescription} ${classes.commonDescription} ${classes.summaryItemPosition}`}>
                            {strings.importedDocsDescription}
                          </div>
                        </Grid>
                        <Grid item xs={3} >
                          <If condition={!isPeriodLocked}>
                            <Button
                              className={classes.makeChangeButton}
                              onClick={this.onMakeChangeButtonClick}
                              backgroundColor={constants.backgroundColor.main}
                              height={constants.height.big}
                            >
                              {strings.makeChangeButton}
                            </Button>
                          </If>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper className={`${classes.summary} ${classes.commonPaddingAndMargin}`}>
                    <Grid container direction="row" alignItems="stretch">
                      <Grid container justify="flex-end">
                        <Grid item xs={12} >
                          <div className={classes.title}>
                            {strings.summaryText}
                          </div>
                        </Grid>
                        <Grid item xs={9} >
                          <div className={`${classes.summaryDescription} ${classes.commonDescription}`}>
                            {this.getSummaryDescription()}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper className={`${classes.summaryItems} ${classes.commonPaddingAndMargin}`}>
                    <Grid container direction="row" alignItems="stretch">
                      {this.getSummaryItem(strings.mainDocText, mainDocName)}
                      {this.getSummaryItem(
                        strings.supportingDocText,
                        this.getSummaryItemDescription(supportingDocNames),
                      )}
                      {this.getSummaryItem(
                        strings.categoriesText,
                        this.getSummaryItemDescription(categoryNames, false),
                      )}
                      {this.getSummaryItem(
                        strings.referenceListsText,
                        this.getSummaryItemDescription(referenceListNames),
                      )}
                      {this.getSummaryItem(
                        strings.machineLearningTemplate,
                        originalTemplate,
                      )}
                    </Grid>
                    <Grid>
                      {this.getSummaryItem(
                        strings.tagDataTypes,
                        this.getTagTable(tagNames),
                      )}
                    </Grid>
                  </Paper>
                </div>
              </When>
              <Otherwise>
                <CircularLoader />
              </Otherwise>
            </Choose>
          </Fragment>
        }
        sidebarContent={<Sidebar />}
        sidebarContentLoading={false}
        mainContentLoading={sidebarLoading}
        isCollapsible
        collapsed
      />
    );
  }
}

PeriodImportDetails.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  summary: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    importDetails: PropTypes.object.isRequired,
  }).isRequired,
  isPeriodLocked: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
    projects: state.projects,
    summary: state.import.periodImportDetails,
    isPeriodLocked: periodIsLockedSelector(state) || periodIsExternalReviewLockedSelector(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTheme(),
)(PeriodImportDetails);
