export const styles = theme => ({
  draggableContainer: {
    height: 50,
    left: 0,
    pointerEvents: 'none',
    position: 'fixed',
    top: 0,
    width: 250,
    zIndex: 100,
  },
  root: {
    height: 30,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: '2px',
    backgroundColor: theme.palette.colors.white,
    border: `solid 1px ${theme.palette.primary.greyBorder}`,
    fontSize: theme.constants.fontSizes.small,
    lineHeight: '30px',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    marginBottom: 10,
  },
  flex: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  iconContainer: {
    width: '5%',
    paddingRight: 5,
    paddingLeft: 5,
  },
  closeButton: {
    paddingRight: 5,
    paddingLeft: 5,
  },
  icon: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    paddingTop: 8,
  },
  dragSource: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    opacity: 0,
    zIndex: 10,
    right: 0,
  },
  button: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
    zIndex: 20,
  },
  removeIcon: {
    cursor: 'pointer',
    zIndex: 20,
    margin: -2,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});
