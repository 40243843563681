import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper, withStyles, withTheme } from '@material-ui/core';
import { strings } from 'Constants/Projects/CreatePeriod/Strings';
import { styles } from './TemplateViewer.styles';

class TemplateViewer extends React.PureComponent {
  render() {
    const { classes, template, templateRules } = this.props;
    if (!template) {
      return (
        <div />
      );
    }
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          {strings.viewTemplateTitle}
        </div>
        <Paper className={classes.paper}>
          <div className={classes.headerContainer}>
            <div className={`
              ${classes.largeDescription}
              ${classes.bold}
              ${classes.paddingBottom}
              ${classes.paddingTop}`}
            >
              {`${template.templateRules.length} ${strings.templateViewerRules(template.templateRules.length)}, 
                ${template.templateCategories.length} ${strings.templateViewerCategories(template.templateCategories.length)}, 
                ${template.templateReferenceLists.length} ${strings.templateViewerReferenceLists(template.templateReferenceLists.length)},
                ${templateRules.filter(r => r.isDisabled).length} ${strings.templateViewerFailedRules(templateRules.filter(r => r.isDisabled).length)}`}
            </div>
            <div className={`
                ${classes.regularDescription}
                ${classes.paddingTop}`}
            >
              {`${strings.templateViewerContext} ${template.taxContext.name}`}
            </div>
          </div>
          <div className={classes.contentContainer}>
            <div className={`
                ${classes.regularDescription}
                ${classes.bold}`}
            >
              {strings.necessaryTags}
            </div>
            <div className={`
                ${classes.regularDescription}
                ${classes.paddingTop}
                ${classes.paddingBottom}`}
            >
              {template.templateTags.map(x => x.name).join(', ')}
            </div>
            <div className={`
                ${classes.regularDescription}
                ${classes.bold}
                ${classes.paddingTop}`}
            >
              {strings.categories}
            </div>
            <div className={`
                ${classes.regularDescription}
                ${classes.paddingTop}
                ${classes.paddingBottom}`}
            >
              {template.templateCategories.map(x => x.name).join(', ')}

            </div>
            {template.templateReferenceLists.length > 0 &&
              <div>
                <div className={`
                ${classes.regularDescription}
                ${classes.bold}
                ${classes.paddingTop}`}
                >
                  {strings.referenceLists}
                </div>
                <div className={`
                ${classes.regularDescription}
                ${classes.paddingTop}
                ${classes.paddingBottom}`}
                >
                  {template.templateReferenceLists.map(x => x.name).join(', ')}
                </div>
              </div>
            }
            {templateRules.some(r => r.isDisabled) &&
              <div>
                <div className={`
                ${classes.regularDescription}
                ${classes.bold}
                ${classes.paddingTop}`}
                >
                  {strings.failedRule}
                </div>
                <div className={`
                ${classes.regularDescription}
                ${classes.paddingTop}
                ${classes.paddingBottom}`}
                >
                  {templateRules.filter(r => r.isDisabled).map((r) => {
                    return (
                      <div>
                        {r.keywordRule}
                      </div>);
                  })}
                </div>
              </div>
            }
          </div>
        </Paper>
      </div>
    );
  }
}

TemplateViewer.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  template: PropTypes.object, //eslint-disable-line
  templateRules: PropTypes.arrayOf(PropTypes.shape({
    keywordRule: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool.isRequired,
  })),
};

TemplateViewer.defaultProps = {
  templateRules: [],
};

function mapStateToProps(state) {
  return {
    templateRules: state.projects.createPeriod.selectedTemplateRuleList,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(TemplateViewer);
