const styles = theme => ({
  layoutActions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    position: 'absolute',
    top: 12,
    right: 20,
    '& > button': {
      fontWeight: 'bold',
      fontSize: 12,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      border: 'none',
      background: 'transparent',
      outline: 'none',
      padding: 0,
    },
    '& > *': {
      marginLeft: 5,
    },
  },
});

export default styles;
