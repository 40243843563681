import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme, Paper, Grid } from '@material-ui/core';
import { push } from 'connected-react-router';
import { strings } from 'Constants/Export/Strings';
import { strings as reviewStrings } from 'Constants/Categorization/CategoryReview/Strings';
import { title as titleString } from 'Constants/App/Titles/Strings';
import DashboardTab from 'Components/Shared/DashboardTab/DashboardTab';
import withLineItemMetadata from 'Components/Shared/LineItemMetadataProvider/LineItemMetadataProvider';
import { formatCurrencyString } from 'Helpers/NumberHelpers';
import Select, { constants as constantsSelect } from 'Components/Shared/Selects/Select';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { routes } from 'Constants/Routes';
import { updateComparePeriodFilters, resetComparePeriod } from 'Store/Areas/Review/ComparePeriodActions';
import { setRedirectedFromReviewPage } from 'Store/Areas/Import/PeriodImportDetailsActions';
import { periodStatusSelector } from 'Store/Areas/Period/PeriodSelectors';
import { resetReviewCategory } from 'Store/Areas/Categorisation/ReviewCategoryActions';
import { styles } from './ReviewPage.styles';
import CategoriesBarChart from '../CategoriesBarChart/CategoriesBarChart';
import RevCounterCard from './RevCounter/RevCounterCard';

const CURRENT_PERIOD_ONLY_ID = 0;

class ReviewPage extends React.PureComponent {
  constructor(props) {
    super(props);
    const {
      tree,
      projectId,
      periodId,
    } = props;

    let periods = [{ id: CURRENT_PERIOD_ONLY_ID, name: strings.currentPeriodOnly }];
    tree.map(g =>
      g.entities.map(e =>
        e.projects.map((p) => {
          if (p.id === projectId) {
            periods = periods.concat(p.periods
              .filter(x => x.id !== periodId && x.status !== periodStatuses.periodArchived)
              .map(x => ({ id: x.id, name: x.name })));
          }
          return false;
        })));

    this.state = {
      periods: periods,
    };
  }

  componentDidMount() {
    document.title = titleString.review;
    this.props.loadMetadata();
  }

  onComparePeriodFilterChanged = (value) => {
    const { dispatch } = this.props;
    if (value === CURRENT_PERIOD_ONLY_ID) {
      dispatch(resetComparePeriod());
    } else {
      dispatch(updateComparePeriodFilters(value));
    }
  }

  onOpenReview = (reviewRoute) => {
    this.props.dispatch(resetReviewCategory());
    this.props.dispatch(push(reviewRoute));
  }

  renderDashboardTab(
    classes,
    periodStatus,
    title,
    rowCountLabel,
    metadata,
    reviewRoute,
    currencySymbol,
  ) {
    if (title === reviewStrings.reviewTagging) {
      this.props.dispatch(setRedirectedFromReviewPage(true));
    }

    return (
      <DashboardTab
        className={classes.tab}
        title={title}
        content={this.getContentForDashboard(
          title,
          metadata,
          currencySymbol,
          rowCountLabel,
        )}
        onClick={() => this.onOpenReview(reviewRoute)}
        hideChevron={title === reviewStrings.reviewTagging ? false :
          (periodStatus === periodStatuses.exportRunning || metadata.count <= 0)}
      />
    );
  }

  getContentForDashboard = (
    title,
    metadata,
    currencySymbol,
    rowCountLabel,
  ) => {
    if (title === reviewStrings.reviewTagging) {
      return [
        `${reviewStrings.reviewTaggingContent}`,
      ];
    }

    return [
      `${title === reviewStrings.allLineItems ? reviewStrings.valueCategorised : reviewStrings.value}${formatCurrencyString(metadata.amount, currencySymbol)}`,
      `${rowCountLabel}${metadata.count}`,
    ];
  }

  render() {
    const {
      classes,
      mlMetadata,
      filters,
      currencySymbol,
      manualMetadata,
      conflictsMetadata,
      contraMetadata,
      allExceptContrasMetadata,
      periodStatus,
    } = this.props;

    const {
      periods,
    } = this.state;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container direction="column" spacing={0}>
            <Grid item container direction="row" spacing={8} justify="flex-end">
              <Grid item lg={4} md={6} sm={12} className={classes.categoriesChartSettingsGrid}>
                <Select
                  id={strings.comparePeriod}
                  label={strings.comparePeriod}
                  preventPlaceholder
                  onChange={this.onComparePeriodFilterChanged}
                  data={periods}
                  value={filters.comparePeriodId}
                  colorScheme={constantsSelect.colorScheme.lightBlue}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing={0}>
              <CategoriesBarChart currencySymbol={currencySymbol} />
            </Grid>
          </Grid>
        </Paper>
        <Grid container direction="row" className={classes.marginTop} spacing={24}>
          <Grid item xs={12} md={4}>
            <RevCounterCard />
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container direction="row" spacing={24}>
              <Grid item xs={12}>
                {this.renderDashboardTab(
                  classes,
                  periodStatus,
                  reviewStrings.machineLearning,
                  reviewStrings.categorisedLineItems,
                  mlMetadata,
                  routes.lineItemsCategorisationReview.ml,
                  currencySymbol,
                )}
              </Grid>
              <Grid item xs={12}>
                {this.renderDashboardTab(
                  classes,
                  periodStatus,
                  reviewStrings.manuallyCategorised,
                  reviewStrings.categorisedLineItems,
                  manualMetadata,
                  routes.lineItemsCategorisationReview.manual,
                  currencySymbol,
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container direction="row" spacing={24}>
              <Grid item xs={12}>
                {this.renderDashboardTab(
                  classes,
                  periodStatus,
                  reviewStrings.contra,
                  reviewStrings.numberOfLineItems,
                  contraMetadata,
                  routes.lineItemsCategorisationReview.contra,
                  currencySymbol,
                )}
              </Grid>
              <Grid item xs={12}>
                {this.renderDashboardTab(
                  classes,
                  periodStatus,
                  reviewStrings.conflictedItems,
                  reviewStrings.numberOfLineItems,
                  conflictsMetadata,
                  routes.lineItemsCategorisationReview.conflicts,
                  currencySymbol,
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid item xs={12}>
              {this.renderDashboardTab(
                classes,
                periodStatus,
                reviewStrings.allLineItems,
                reviewStrings.numberOfLineItems,
                allExceptContrasMetadata,
                routes.lineItemsCategorisationReview.lineItems,
                currencySymbol,
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid item xs={12}>
              {this.renderDashboardTab(
                classes,
                periodStatus,
                reviewStrings.reviewTagging,
                reviewStrings.numberOfLineItems,
                manualMetadata,
                routes.periodImportDetails,
                currencySymbol,
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ReviewPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
  periodStatus: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  loadMetadata: PropTypes.func.isRequired,
  mlMetadata: PropTypes.shape({
    count: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  tree: PropTypes.arrayOf(PropTypes.shape({
    entities: PropTypes.arrayOf(PropTypes.shape({
      projects: PropTypes.arrayOf(PropTypes.object),
    })),
  })).isRequired,
  projectId: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    comparePeriodId: PropTypes.number,
  }).isRequired,
  manualMetadata: PropTypes.shape({
    count: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  conflictsMetadata: PropTypes.shape({
    count: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  contraMetadata: PropTypes.shape({
    count: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  allExceptContrasMetadata: PropTypes.shape({
    count: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  const { period } = state.periods;
  return {
    periodId: period.periodId,
    periodStatus: periodStatusSelector(state),
    projectId: state.periods.period.data.projectId,
    filters: state.review.comparePeriod.filters,
    tree: state.projects.treePicker.data,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
  withLineItemMetadata({
    includeMl: true,
    includeManual: true,
    includeConflicts: true,
    includeContra: true,
    includeAllExceptContras: true,
  }),
)(ReviewPage);
