import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { updatePeriodStatus } from 'Store/Areas/Period/PeriodActions';
import { removeFileImportRows } from 'Store/Areas/Import/ImportSummaryActions';
import { resetProcessingState, restartProcess } from 'Store/Areas/Import/ProcessActions';
import { periodStatusSelector } from 'Store/Areas/Period/PeriodSelectors';
import Modal from 'Components/Shared/Modal/StyledModal';
import ErrorModal from 'Components/Shared/Modal/ErrorModal';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { strings } from 'Constants/ImportSummary/Strings';
import redCross from 'Assets/Images/exit-ic-red.svg';
import styles from './FailedSummaryModal.styles';
import SummaryItemTable from '../Table/SummaryItemTable';

class FailedSummaryModal extends React.PureComponent {
  onStartAgainClick = () => {
    const { dispatch, periodId } = this.props;
    dispatch(removeFileImportRows(periodId));
    dispatch(updatePeriodStatus(periodId, periodStatuses.notStarted));
    dispatch(resetProcessingState());
  }

  onConfirm = () => {
    const { dispatch, periodId } = this.props;
    dispatch(restartProcess(periodId));
  }

  render() {
    const {
      classes, importErrors, loading, status, tooManyErrors, tooManyClauses, ruleText,
    } = this.props;
    return (
      <Fragment>
        <Choose>
          <When condition={tooManyClauses}>
            <ConfirmationModal
              open={status === periodStatuses.importSummary}
              title={strings.importFailed}
              message={strings.tooManyClausesNote + ruleText}
              acceptText={strings.proceedAnyway}
              onConfirm={this.onConfirm}
              onCancel={this.onStartAgainClick}
            />
          </When>
          <When condition={tooManyErrors}>
            <ErrorModal
              open={status === periodStatuses.importSummary}
              onClose={this.onStartAgainClick}
              title={strings.importFailed}
              error={strings.tooManyErrorsNote}
            />
          </When>
          <Otherwise>
            <Modal open={status === periodStatuses.importSummary} width="lg">
              <div className={classes.content}>
                <div className={classes.title}>
                  <img alt="X" className={classes.cross} src={redCross} />
                  {strings.importFailed}
                </div>
                <div className={classes.textArea}>
                  <span className={classes.note}>
                    {strings.numOfLineItemsIssues}{importErrors.length}
                  </span>
                </div>
                <div className={classes.textArea}>
                  <span className={classes.note}>
                    {strings.failedNote}
                  </span>
                </div>
                <SummaryItemTable
                  importErrors={importErrors}
                  loading={loading}
                  successful={false}
                />
                <div className={classes.buttonContainer}>
                  <Button
                    disableRipple
                    backgroundColor={constants.backgroundColor.dark}
                    className={classes.button}
                    onClick={this.onStartAgainClick}
                  >
                    {strings.startAgain}
                  </Button>
                </div>
              </div>
            </Modal>
          </Otherwise>
        </Choose >
      </Fragment >
    );
  }
}

FailedSummaryModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  status: PropTypes.string.isRequired,
  importErrors: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  tooManyErrors: PropTypes.bool.isRequired,
  tooManyClauses: PropTypes.bool.isRequired,
  ruleText: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { period } = state.periods;
  return {
    status: periodStatusSelector(state),
    periodId: period.periodId,
    importErrors: state.import.importSummary.importErrors,
    loading: state.import.importSummary.loading,
    tooManyClauses: state.import.importSummary.tooManyClauses,
    ruleText: state.import.importSummary.ruleText,
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTheme(),
)(FailedSummaryModal);
