export const GET_PERIOD = 'PERIOD/GET_PERIOD';
export const GET_PERIOD_SUCCESS = 'PERIOD/GET_PERIOD_SUCCESS';
export const RESET_PERIOD = 'PERIOD/RESET_PERIOD';
export const UPDATE_PERIOD_STATUS = 'PERIOD/UPDATE_PERIOD_STATUS';
export const UPDATE_PERIOD_STATUS_IN_REDUX = 'PERIOD/UPDATE_PERIOD_STATUS_IN_REDUX';
export const UPDATE_PERIOD_STATUS_SUCCESS = 'PERIOD/UPDATE_PERIOD_STATUS_SUCCESS';
export const CONNECT_TO_SIGNALR_PERIOD_HUB = 'PERIOD/CONNECT_TO_SIGNALR_PERIOD_HUB';
export const PERIOD_GET_PERIOD_LOCKED_BY_USERID = 'PERIOD/PERIOD_GET_PERIOD_LOCKED_BY_USERID';
export const PERIOD_GET_PERIOD_LOCKED_BY_USER = 'PERIOD/PERIOD_GET_PERIOD_LOCKED_BY_USER';
export const PERIOD_GET_PERIOD_LOCKED_BY_USERID_SUCCESS = 'PERIOD/PERIOD_GET_PERIOD_LOCKED_BY_USERID_SUCCESS';
export const PERIOD_GET_PERIOD_LOCKED_BY_USER_SUCCESS = 'PERIOD/PERIOD_GET_PERIOD_LOCKED_BY_USER_SUCCESS';
export const SET_PERIOD_IN_USE = 'PERIOD/SET_PERIOD_IN_USE';
export const RESET_PERIOD_IN_USE = 'PERIOD/RESET_PERIOD_IN_USE';
export const SET_PERIOD_IN_USE_SUCCESS = 'PERIOD/SET_PERIOD_IN_USE_SUCCESS';
export const RESET_PERIOD_IN_USE_SUCCESS = 'PERIOD/RESET_PERIOD_IN_USE_SUCCESS';
export const SHOW_PERIOD_IN_USE_POPUP = 'PERIOD/SHOW_PERIOD_IN_USE_POPUP';
export const RESET_PERIOD_IN_USE_POPUP = 'PERIOD/RESET_PERIOD_IN_USE_POPUP';
export const REQUEST_CHANGE_SUPPORTING_DOCS_AFTER_PERIOD_CREATION = 'PERIOD/REQUEST_CHANGE_SUPPORTING_DOCS_AFTER_PERIOD_CREATION';

export function getPeriod(periodId, maintainRoute = false) {
  return {
    type: GET_PERIOD,
    loading: true,
    success: false,
    payload: { periodId },
    maintainRoute,
  };
}

export function getPeriodSuccess(
  periodId,
  response,
  previousPeriodId,
  previousPeriodStatus,
  maintainRoute = false,
) {
  return {
    type: GET_PERIOD_SUCCESS,
    loading: false,
    success: true,
    periodId,
    response,
    previousPeriodId,
    previousPeriodStatus,
    maintainRoute,
  };
}

export function resetPeriod() {
  return {
    type: RESET_PERIOD,
  };
}

export function updatePeriodStatus(periodId, status) {
  return {
    type: UPDATE_PERIOD_STATUS,
    payload: {
      periodId,
      status,
    },
  };
}

export function updatePeriodStatusSuccess(periodId) {
  return {
    type: UPDATE_PERIOD_STATUS_SUCCESS,
    payload: {
      periodId,
    },
  };
}

export function updatePeriodStatusInRedux(status) {
  return {
    type: UPDATE_PERIOD_STATUS_IN_REDUX,
    status: status,
  };
}

export function connectToSignalRPeriodHub(periodId) {
  return {
    type: CONNECT_TO_SIGNALR_PERIOD_HUB,
    selectedPeriodId: periodId,
  };
}

export function getPeriodLockedByUserId(periodId) {
  return {
    type: PERIOD_GET_PERIOD_LOCKED_BY_USERID,
    loading: true,
    success: false,
    periodId: periodId,
  };
}

export function getPeriodLockedByUser(periodId) {
  return {
    type: PERIOD_GET_PERIOD_LOCKED_BY_USER,
    loading: true,
    success: false,
    periodId: periodId,
  };
}

export function getPeriodLockedByUserIdSuccess(response) {
  return {
    type: PERIOD_GET_PERIOD_LOCKED_BY_USERID_SUCCESS,
    loading: false,
    success: true,
    isLockedByUserId: response,
  };
}

export function getPeriodLockedByUserSuccess(response) {
  return {
    type: PERIOD_GET_PERIOD_LOCKED_BY_USER_SUCCESS,
    loading: false,
    success: true,
    isLockedByUser: response,
  };
}

export function setPeriodInUse(periodId, periodControlStatus, userId) {
  return {
    type: SET_PERIOD_IN_USE,
    loading: true,
    success: false,
    payload: {
      periodId: periodId,
      userId: userId,
      periodControlStatus: periodControlStatus,
    },
  };
}

export function setPeriodInUseSuccess() {
  return {
    type: SET_PERIOD_IN_USE_SUCCESS,
    loading: false,
    success: true,
    inUse: true,
  };
}

export function resetPeriodInUse(periodId) {
  return {
    type: RESET_PERIOD_IN_USE,
    loading: true,
    success: false,
    payload: {
      periodId: periodId,
    },
  };
}

export function resetPeriodInUseSuccess() {
  return {
    type: RESET_PERIOD_IN_USE_SUCCESS,
    loading: false,
    success: true,
    inUse: false,
  };
}

export function showPeriodInUsePopup(periodName, userName, requestUserId, periodControlStatus) {
  return {
    type: SHOW_PERIOD_IN_USE_POPUP,
    periodName: periodName,
    userName: userName,
    requestUserId: requestUserId,
    periodControlStatus: periodControlStatus,
  };
}

export function resetPeriodInUsePopup() {
  return {
    type: RESET_PERIOD_IN_USE_POPUP,
  };
}

export function requestChangeSupportingDocsAfterPeriodCreation(status) {
  return {
    type: REQUEST_CHANGE_SUPPORTING_DOCS_AFTER_PERIOD_CREATION,
    changeSupportingDocsAfterPeriodCreation: status,
  };
}