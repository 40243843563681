import React, { Fragment } from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import { formatNumberCommaString } from 'Helpers/NumberHelpers';
import { strings } from 'Constants/MachineLearning/Strings';
import PropTypes from 'prop-types';
import styles from './DistributionChart.styles';

class DistributionChart extends React.PureComponent {
  getMaxY() {
    const { data } = this.props.distributionGraph;
    return data.reduce(
      (max, p) => (p.documentCount > max ? p.documentCount : max),
      data[0].documentCount,
    );
  }
  getSvgX(x) {
    const { svgWidth, maxX } = this.props;
    return (x / maxX) * svgWidth;
  }
  getSvgY(y) {
    const { svgHeight } = this.props;
    return svgHeight - (y / this.getMaxY()) * svgHeight;
  }
  getSeparatorCoords() {
    const confidence = this.props.confidenceLevel / 100.0;
    const { data } = this.props.distributionGraph;
    if (data.length === 0) {
      return { separatorX: 0, separatorY: 0 };
    }
    let currentIdx = Math.round(data.length * confidence);
    currentIdx = Math.max(currentIdx, 0);
    currentIdx = Math.min(currentIdx, data.length - 1);
    const currentSvgX = this.getSvgX(currentIdx);
    const currentSvgY = this.getSvgY(data[currentIdx].documentCount);
    return { separatorX: currentSvgX, separatorY: currentSvgY };
  }
  makeLabels() {
    const { classes } = this.props;
    return (
      <Fragment key="labels">
        <span className={classes.labelY} textAnchor="middle">
          {strings.yAxisLabel}
        </span>
        <span className={classes.labelX} textAnchor="middle">
          {strings.xAxisLabel}
        </span>
      </Fragment>
    );
  }

  makeArea() {
    const { data } = this.props.distributionGraph;
    const { classes, maxX, minX } = this.props;

    let pathD = `M ${0} ${this.getSvgY(data[0].documentCount)} `;

    data.forEach((point, index) => {
      pathD += `L ${this.getSvgX(index)} ${this.getSvgY(point.documentCount)}`;
    });

    pathD += `L ${this.getSvgX(maxX)} ${this.getSvgY(0)} L ${this.getSvgX(minX)} ${this.getSvgY(0)} `;

    return <path className={classes.distArea} d={pathD} />;
  }

  render() {
    const {
      svgWidth,
      svgHeight,
      classes,
      distributionGraph,
      minX,
      maxX,
    } = this.props;
    const { separatorX, separatorY } = this.getSeparatorCoords();
    const separatorXPercents = `${(separatorX / svgWidth) * 100}%`;
    const r = 5;
    return (
      <div>
        <If condition={distributionGraph.loading || distributionGraph.data.length !== 101}>
          <CircularLoader />
        </If>
        <If condition={!(distributionGraph.loading || distributionGraph.data.length !== 101)}>
          <div className={classes.yAxisMax}>
            {formatNumberCommaString(Math.max(...distributionGraph.data.map(x => x.documentCount)))}
          </div>
          <svg
            className={classes.graph}
            viewBox={`0 0 ${svgWidth} ${svgHeight}`}
            preserveAspectRatio="none"
          >
            {this.makeArea()}
            <linearGradient
              id="confidence-shading"
              gradientUnits="userSpaceOnUse"
              x1="0"
              y1="0"
              x2={`${svgWidth}`}
              y2="0"
            >
              <stop offset="0%" className={classes.gradientStopNonConfident} />
              <stop offset={separatorXPercents} className={classes.gradientStopNonConfident} />
              <stop offset={separatorXPercents} className={classes.gradientStopConfident} />
              <stop offset="100%" className={classes.gradientStopConfident} />
            </linearGradient>
            <path
              d={`M ${separatorX}, ${separatorY} m -${r}, 0 a ${r},${r} 0 1,0 ${r *
                2},0 a ${r},${r} 0 1,0 -${r * 2},0 `}
              className={classes.separatorCircle}
            />
            <line
              x1={separatorX}
              y1={separatorY}
              x2={separatorX}
              y2={svgHeight}
              className={classes.separatorLine}
            />
          </svg>
          {this.makeLabels()}
          <div className={classes.xAxisWrapper}>
            <span className={classes.xAxisMin}>
              {minX}
            </span>
            <span className={classes.xAxisMax}>
              {maxX}
            </span>
          </div>
        </If>
      </div>
    );
  }
}

DistributionChart.defaultProps = {
  minX: 0,
  maxX: 100,
  svgHeight: 159,
  svgWidth: 700,
};

DistributionChart.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  distributionGraph: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.number,
      documentCount: PropTypes.number,
    })),
  }).isRequired,
  confidenceLevel: PropTypes.number.isRequired,
  svgHeight: PropTypes.number,
  minX: PropTypes.number,
  maxX: PropTypes.number,
  svgWidth: PropTypes.number,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(DistributionChart);
