export const GET_METHOD_OF_CATEGORISATION = 'METHOD_OF_CATEGORISATION/GET_METHOD_OF_CATEGORISATION';
export const GET_METHOD_OF_CATEGORISATION_SUCCESS = 'METHOD_OF_CATEGORISATION/GET_METHOD_OF_CATEGORISATION_SUCCESS';

export function getMethodOfCategorisation(periodId) {
  return {
    type: GET_METHOD_OF_CATEGORISATION,
    periodId: periodId,
  };
}

export function getMethodOfCategorisationSuccess(response) {
  return {
    type: GET_METHOD_OF_CATEGORISATION_SUCCESS,
    response: response,
  };
}
