function inviteStatusText(status) {
  switch (status) {
    case 0:
      return 'Activated';
    case 1:
      return 'Failed';
    case 2:
      return 'Pending';
    default:
      return '';
  }
}

function emptyMessage(itemType) {
  return `There are no users assigned to this ${itemType}.`;
}

function accessTo(selectedItemName, itemType) {
  return `Access to ${selectedItemName} ${itemType}`;
}

export const strings = {
  didYouKnowMessage:
    'Group Administrators can access the projects in their Group by switching to User mode.',
  sidebarTitle:
    'Manage groups and permissions:',
  removeUserModalMessage:
    'Are you sure you want to remove access for this user?',
  removeAdminModalMessage:
    'Are you sure you want to remove access for this group admin?',
  removeUserModalMessagePlural:
    'Are you sure you want to remove access for these users?',
  removeAdminModalMessagePlural:
    'Are you sure you want to remove access for these group admins?',
  createProject:
    'Create new project',
  addGroup:
    'Add new group',
  createGroup:
    'Create new group',
  editGroup:
    'Edit group',
  groupLabel:
    'Group',
  engagingEntityNameLabel:
    'Engaging Entity Name',
  opportunityNumberLabel:
    'Opportunity Number',
  engagementLeadLabel:
    'Engagement Lead',
  engagementManagerLabel:
    'Engagement Manager',
  engagementNumberLabel:
    'Engagement Number',
  notesLabel:
    'Notes',
  expectedFeeLabel:
    'Expected Fee',
  contextLabel:
    'Context',
  contextOptionPlaceholder:
    'Select a context...',
  projectNameLabel:
    'Project name',
  projectNamePlaceholder:
    'Enter your project name...',
  groupNamePlaceholder:
    'Enter your group name...',
  engagingEntityNamePlaceholder:
    'Enter your engaging entity name...',
  opportunityNumberPlaceholder:
    'Enter your opportunity number...',
  enagagementLeadPlaceholder:
    'Enter your engagement lead...',
  engagementManagerPlaceholder:
    'Enter your engagement manager...',
  engagementNumberPlaceholder:
    'Enter your engagement number...',
  notesPlaceholder:
    'Enter your notes...',
  expectedFeePlaceholder:
    'Enter your expected fee...',
  groupLicensePlaceholder:
    'Select a group license type...',
  activeClientPlaceholder:
    'Active Client?',
  serviceCodePlaceholder:
    'Service Code',
  licenseLabel:
    'Type',
  activeClientLabel:
    'Active Client',
  serviceCodeLabel:
    'Service Code',
  removeAccess:
    'Remove access',
  entityLabel:
    'Entity',
  addEntity:
    'Add new entity',
  createEntity:
    'Create new entity',
  entityNamePlaceholder:
    'Enter your entity name...',
  currencyLabel:
    'Currency',
  currencyOptionPlaceholder:
    'Select currency...',
  machineLearningLabel:
    'Machine Learning Enabled',
  editEntity:
    'Edit entity',
  editProject:
    'Edit project',
  addUser:
    'Add user',
  inviteStatus: inviteStatusText,
  emptyMessage: emptyMessage,
  accessTo: accessTo,
  adminMode: 'Admin mode',
  youAreIn: 'You are in ',
  deleteGroup: 'Delete group',
  deleteEntity: 'Delete entity',
  deleteProject: 'Delete project',
  deleteProjectNoChild: 'Deleting this project will remove it from this group permanently. Are you sure?',
  deleteProjectChild: 'All child items must be removed before this project can be deleted',
  deleteEntityNoChild: 'Deleting this entity will remove it from this group permanently. Are you sure?',
  deleteEntityChild: 'All child items must be removed before this entity can be deleted',
  deleteGroupNoChild: 'Deleting this group will remove it and any associated templates permanently. Are you sure?',
  deleteGroupChild: 'All child items must be removed before this group can be deleted',
  groupAdmin: 'Group admin',
  entityAdmin: 'Entity admin',
  fullAccess: 'Full access',
  readOnly: 'Read only',
  emailAddress: 'Email address',
  syncUser: 'External User',
  status: 'Status',
  permissionType: 'Permission type',
  searchEmail: 'Email',
  searchEmailPlaceholder: 'Search user email',
  addUserConfirmTitle: 'Confirm Add User?',
  addUserConfirmMessage: 'You are increasing the user permissions of this user. This will replace their previous permissions.',
};
