import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { beginDeleteSupportingDoc, cancelDeleteSupportingDoc } from 'Store/Areas/Import/UploadActions';
import { commonStrings } from 'Constants/CommonStrings';
import { strings } from 'Constants/Upload/Strings';

class DeleteSupportingDocsModal extends React.PureComponent {
  onCancel = () => {
    this.props.dispatch(cancelDeleteSupportingDoc());
  }

  onConfirm = () => {
    const { dispatch, supportingDocInfo, period } = this.props;
    dispatch(beginDeleteSupportingDoc(
      supportingDocInfo.cloudReference,
      supportingDocInfo.fileName,
      period.periodId,
    ));
  }

  render() {
    const { supportingDocInfo } = this.props;
    return (
      <ConfirmationModal
        open={supportingDocInfo.isDelete}
        message={strings.deleteSupportingDocConfirmation(supportingDocInfo.fileName)}
        title={strings.deleteSupportingDocConfirmationTitle}
        isSubmitting={supportingDocInfo.loading}
        acceptText={commonStrings.delete}
        onConfirm={this.onConfirm}
        onCancel={this.onCancel}
      />
    );
  }
}

DeleteSupportingDocsModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  supportingDocInfo: PropTypes.shape({
    isDelete: PropTypes.bool,
  }),
  period: PropTypes.shape({
    periodId: PropTypes.number,
  }).isRequired,
};

DeleteSupportingDocsModal.defaultProps = {
  supportingDocInfo: {
    isDelete: false,
  },
};

function mapStateToProps(state) {
  return {
    period: state.periods.period,
    supportingDocInfo: state.import.upload.supportingDocInfo,
  };
}

export default connect(mapStateToProps)(DeleteSupportingDocsModal);
