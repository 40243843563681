export const styles = theme => ({
  draggableContainer: {
    height: 50,
    left: 0,
    pointerEvents: 'none',
    position: 'fixed',
    top: 0,
    width: 250,
    zIndex: 100,
  },
  dragging: {
    width: 250,
  },
  root: {
    height: 30,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: '2px',
    backgroundColor: theme.palette.colors.white,
    border: `solid 1px ${theme.palette.primary.greyBorder}`,
    fontSize: theme.constants.fontSizes.small,
    lineHeight: '30px',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    outline: 0,
  },
  flex: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  padded: {
    paddingRight: 5,
    paddingLeft: 5,
  },
  closeIcon: {
    height: 13,
    margin: 'auto',
  },
  progressBar: {
    position: 'absolute',
    width: '99%',
    bottom: 0,
    right: 0,
    left: 0,
  },
  selectedItem: {
    backgroundColor: theme.palette.primary.background,
  },
});
