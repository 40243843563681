import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  withTheme,
} from '@material-ui/core';
import { compose } from 'recompose';
import Close from 'Assets/Images/exit-ic.svg';
import styles from './DeleteButton.styles';

class DeleteButton extends React.PureComponent {
  render() {
    const {
      onClick,
      cloudReference,
      classes,
    } = this.props;
    return (
      <button
        onClick={(e) => {
        e.stopPropagation();
        onClick(cloudReference);
      }}
        className={classes.deleteButton}
      >
        <img alt="" className={classes.closeIcon} src={Close} />
      </button>
    );
  }
}

DeleteButton.defaultProps = {
};

DeleteButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  cloudReference: PropTypes.string.isRequired,
};

export default compose(withStyles(styles), withTheme())(DeleteButton);
