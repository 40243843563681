export const styles = theme => ({
  root: {
    height: 30,
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: '2px',
    backgroundColor: theme.palette.colors.white,
    border: 'solid 1px #d4d4d4',
    fontSize: theme.constants.fontSizes.small,
    lineHeight: '30px',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 250,
  },
  flex: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
