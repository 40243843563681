const styles = theme => (
  {
    nodule: {
      width: 16,
      height: 16,
      borderRadius: 50,
      border: '4px solid white',
    },
    transparent: {
      backgroundColor: '#efefef',
    },
    blue: {
      backgroundColor: theme.palette.primary.main,
    },
    progressBar: {
      maxWidth: '50%',
      minWidth: '10%',
      marginTop: '5px',
      borderTop: '4px solid white',
      borderBottom: '4px solid white',
      marginRight: -5,
      marginLeft: -5,
    },
    final: {
      borderRight: '4px solid white',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
    first: {
      borderLeft: '4px solid white',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
    },
    name: {
      marginTop: 30,
      display: 'flex',
      justifyContent: 'center',
      lineHeight: 1.4,
      fontSize: theme.constants.fontSizes.regular,
    },
  });

export default styles;
