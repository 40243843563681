import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import styles from './MandatoryAsterisk.styles';

function MandatoryAsterisk(props) {
  const { classes } = props;
  return (<span className={classes.asterisk}>*</span>);
}

MandatoryAsterisk.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(MandatoryAsterisk);
