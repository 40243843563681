import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Popover } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getLineItemMetadata } from 'Store/Areas/Review/LineItemMetadataActions';
import { strings } from 'Constants/Categorization/Strings';
import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';
import { formatCurrency, formatCurrencyString, formatPercentage } from 'Helpers/NumberHelpers';
import styles from './CategorizationValuesBar.styles';
import BarSegment from './BarSegment';

class CategorizationValuesBar extends React.PureComponent {
  state = {
    anchorEl: null,
    popoverCategory: null,
    showMonetaryPercent: true,
  };

  componentDidMount() {
    this.fetchContraMetadata();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.period.data !== this.props.period.data) {
      this.fetchContraMetadata();
    }
  }

  fetchContraMetadata() {
    const { period: { data: periodData }, dispatch } = this.props;

    if (periodData) {
      dispatch(getLineItemMetadata(periodData.id, categorizationTypes.contra, null));
    }
  }

  handlePopoverOpen(event, category) {
    this.setState({ anchorEl: event.target, popoverCategory: category });
  }

  handlePopoverClose() {
    this.setState({ anchorEl: null, popoverCategory: null });
  }

  togglePercentage = (value) => {
    this.setState({ showMonetaryPercent: value });
  }

  render() {
    const {
      classes,
      className,
      currencySymbol,
      period,
      contraMetadata,
    } = this.props;

    const { anchorEl, popoverCategory, showMonetaryPercent } = this.state;
    if (!period.data) {
      return null;
    }

    const { categories, amountValue, lineItems: lineItemsInclContras } = period.data;

    const totalCategorised = categories.reduce((sum, x) => sum + x.amountValue, 0);
    const categorisedFraction = totalCategorised / amountValue;

    const lineItems = contraMetadata.loading ? 0 : (lineItemsInclContras - contraMetadata.count);
    const totalLineItemsCategorised = categories.reduce((sum, x) => sum + x.lineItems, 0);
    const categorisedLineItemsFraction = totalLineItemsCategorised / lineItems;

    return (
      <div className={`${classes.root} ${className}`}>
        <div className={classes.amountsBar}>
          {strings.categorisedSoFar}
          <If condition={showMonetaryPercent}>
            <Choose>
              <When condition={(categorisedFraction > 1 || categorisedFraction < 0)}>
                <b> --% / </b>
              </When>
              <Otherwise>
                <b> {formatPercentage(totalCategorised / amountValue)} / </b>
              </Otherwise>
            </Choose>
            {strings.amountCategorisedOutOf(
              formatCurrencyString(totalCategorised, currencySymbol),
              formatCurrencyString(amountValue, currencySymbol),
            )}
          </If>
          <If condition={period.data.lineItems > 0}>
            <If condition={!showMonetaryPercent}>
              <Choose>
                <When condition={(categorisedLineItemsFraction > 1 ||
                  categorisedLineItemsFraction < 0)}
                >
                  <b> --% / </b>
                </When>
                <Otherwise>
                  <b> {formatPercentage(totalLineItemsCategorised / lineItems)} / </b>
                </Otherwise>
              </Choose>
              {strings.amountCategorisedOutOf(totalLineItemsCategorised, lineItems)}
            </If>
            <button
              className={showMonetaryPercent ? classes.selectedButton : classes.button}
              onClick={() => this.togglePercentage(true)}
              disabled={showMonetaryPercent}
            >
              {strings.byMonetaryAmount}
            </button>
            /
            <button
              className={showMonetaryPercent ? classes.button : classes.selectedButton}
              onClick={() => this.togglePercentage(false)}
              disabled={!showMonetaryPercent}
            >
              {strings.byLineItems}
            </button>
          </If>
        </div>
        <div className={classes.categoriesRoot}>
          <If condition={showMonetaryPercent}>
            <For each="category" of={categories}>
              <BarSegment
                key={category.name}
                totalAmount={amountValue}
                className={`${classes.category} ${classes.categoriesRoot}`}
                handlePopoverOpen={x => this.handlePopoverOpen(x, category)}
                handlePopoverClose={() => this.handlePopoverClose()}
                category={category}
              />
            </For>
            <With
              category={{
                color: '#e0e0e0',
                name: 'Unmapped',
                amountValue: amountValue - totalCategorised,
              }}
            >
              <BarSegment
                className={`${classes.category} ${classes.categoriesRoot}`}
                handlePopoverOpen={x => this.handlePopoverOpen(x, category)}
                handlePopoverClose={() => this.handlePopoverClose()}
                totalAmount={amountValue}
                category={category}
              />
            </With>
          </If>
          <If condition={!showMonetaryPercent && period.data.lineItems > 0}>
            <For each="category" of={categories}>
              <BarSegment
                key={category.name}
                totalAmount={lineItems}
                className={`${classes.category} ${classes.categoriesRoot}`}
                handlePopoverOpen={x => this.handlePopoverOpen(x, category)}
                handlePopoverClose={() => this.handlePopoverClose()}
                category={category}
                viewLineItems
              />
            </For>
            <With
              category={{
                color: '#e0e0e0',
                name: 'Unmapped',
                lineItems: lineItems - totalLineItemsCategorised,
              }}
            >
              <BarSegment
                className={`${classes.category} ${classes.categoriesRoot}`}
                handlePopoverOpen={x => this.handlePopoverOpen(x, category)}
                handlePopoverClose={() => this.handlePopoverClose()}
                totalAmount={lineItems}
                category={category}
                viewLineItems
              />
            </With>
          </If>
        </div>
        <Popover
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <If condition={popoverCategory}>
            <div>{popoverCategory.name}</div>
            <div className={classes.amountValue}>
              {showMonetaryPercent ?
                formatCurrency(popoverCategory.amountValue, currencySymbol) :
                popoverCategory.lineItems}
            </div>
          </If>
        </Popover>
      </div>
    );
  }
}

CategorizationValuesBar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  period: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number,
      amountValue: PropTypes.number,
      lineItems: PropTypes.number,
      categories: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string,
        name: PropTypes.string,
        amountValue: PropTypes.number,
      })),
    }),
  }).isRequired,
  contraMetadata: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
  }).isRequired,
};

CategorizationValuesBar.defaultProps = {
  className: '',
};

function mapStateToProps(state) {
  return {
    period: state.periods.period,
    contraMetadata: state.review.lineItemMetadata.contraMetadata,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(CategorizationValuesBar);
