import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Modal from 'Components/Shared/Modal/StyledModal';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import StyledModalButtons from 'Components/Shared/Modal/StyledModal.Buttons';
import { strings } from 'Constants/Categorization/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { referenceListsSelector } from 'Store/Areas/Categorisation/ReferenceListSelectors';
import { periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import { postDeleteReferenceList, resetReferenceListsRules } from 'Store/Areas/Categorisation/ReferenceListActions';
import { styles } from './ReferenceListDeletionModal.styles';

class ReferenceListDeletionModal extends React.PureComponent {
  onCancel = () => {
    this.props.dispatch(resetReferenceListsRules());
  }

  onSubmit = () => {
    const {
      dispatch,
      referenceLists,
      periodId,
    } = this.props;

    dispatch(postDeleteReferenceList(
      periodId,
      referenceLists.referenceListId,
      referenceLists.referenceListName,
    ));
  }

  render() {
    const {
      classes,
      referenceLists,
    } = this.props;

    return (
      <Modal open>
        <Choose>
          <When condition={referenceLists.referenceListRules.length > 0}>
            <div className={classes.content}>
              <div className={classes.title}>
                {strings.referenceListUnableDeleteTitle(referenceLists.referenceListName)}
              </div>
              <div className={classes.text}>
                {strings.referenceListUnableDeleteText}
              </div>
              <div className={classes.ruleList}>
                <For each="rule" of={referenceLists.referenceListRules} index="index">
                  <div className={classes.ruleText} key={index}>
                    {strings.referenceListRuleConflicts(
                      index + 1,
                      strings.ruleCategorisationString(
                        rule.rule,
                        rule.categories.map(c => c.categoryName),
                      ),
                    )}
                  </div>
                </For>
              </div>
              <div className={classes.text}>
                {strings.referenceListUnableDeleteText2}
              </div>
            </div>
            <Button
              disableRipple
              backgroundColor={constants.backgroundColor.main}
              height={constants.height.big}
              rounded={false}
              onClick={() => this.onCancel()}
            >
              {commonStrings.ok}
            </Button>
          </When>
          <Otherwise>
            <div className={classes.content}>
              <div className={classes.title}>
                {strings.referenceListDeleteModalTitle(referenceLists.referenceListName)}
              </div>
              <div className={classes.text}>
                {strings.referenceListDeleteText(referenceLists.referenceListName)}
              </div>
            </div>
            <StyledModalButtons
              onCancel={() => this.onCancel()}
              onAccept={() => this.onSubmit()}
              acceptText={commonStrings.delete}
              cancelText={commonStrings.cancel}
            />
          </Otherwise>
        </Choose>
      </Modal>
    );
  }
}

ReferenceListDeletionModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
  referenceLists: PropTypes.shape({
    referenceListName: PropTypes.string.isRequired,
    referenceListId: PropTypes.number.isRequired,
    referenceListRules: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      rule: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.shape({
        categoryId: PropTypes.number,
        categoryName: PropTypes.string,
      })),
      ruleType: PropTypes.number,
    })),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
    referenceLists: referenceListsSelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(ReferenceListDeletionModal);
