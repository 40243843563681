import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { styles } from './PreviewRule.styles';

class PreviewRule extends React.PureComponent {
  render() {
    const {
      classes,
      leftAlignedText,
      rightAlignedText,
    } = this.props;
    return (
      <div className={classes.root}>
        <span className={classes.flex}>{leftAlignedText}</span>
        <span>{rightAlignedText}</span>
      </div>
    );
  }
}

PreviewRule.defaultProps = {
  rightAlignedText: '',
};

PreviewRule.propTypes = {
  leftAlignedText: PropTypes.string.isRequired,
  rightAlignedText: PropTypes.object, // eslint-disable-line
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(PreviewRule);
