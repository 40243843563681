export const GET_TREEPICKER_DATA = 'TREEPICKER/GET_TREEPICKER_DATA';
export const GET_TREEPICKER_DATA_SUCCESS = 'TREEPICKER/GET_TREEPICKER_DATA_SUCCESS';

export function getTreePickerData({
  includePeriods = true,
  includePeriodTags = false,
  includePeriodRules = false,
  includePeriodCategories = false,
} = {}) {
  return {
    type: GET_TREEPICKER_DATA,
    data: [],
    loading: true,
    payload: {
      includePeriods,
      includePeriodTags,
      includePeriodRules,
      includePeriodCategories,
    },
  };
}

export function getTreePickerDataSuccess(payload, data) {
  return {
    type: GET_TREEPICKER_DATA_SUCCESS,
    payload,
    data,
    loading: false,
  };
}
