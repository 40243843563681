export const APPORTIONMENT_RESOURCE_FROM_KEYWORD = 'APPORTIONMENT/APPORTIONMENT_RESOURCE_FROM_KEYWORD';
export const APPORTIONMENT_RESOURCE_FROM_LINE_ITEM = 'APPORTIONMENT/APPORTIONMENT_RESOURCE_FROM_LINE_ITEM';
export const REQUEST_CREATE_APPORTIONABLE = 'APPORTIONMENT/REQUEST_CREATE_APPORTIONABLE';
export const APPORTIONMENT_RESOURCE_SUCCESS = 'APPORTIONMENT/APPORTIONMENT_RESOURCE_SUCCESS';
export const APPORTION_LINE_ITEM_SUCCESS = 'APPORTIONMENT/APPORTION_LINE_ITEM_SUCCESS';
export const CLOSE_APPORTIONMENT_MODAL = 'APPORTIONMENT/CLOSE_APPORTIONMENT_MODAL';
export const APPORTIONMENT_RESOURCE_FROM_REFLIST = 'APPORTIONMENT/APPORTIONMENT_RESOURCE_FROM_REFLIST';
export const APPORTIONMENT_RESOURCE_FROM_CUSTOM_RULE = 'APPORTIONMENT/APPORTIONMENT_RESOURCE_FROM_CUSTOM_RULE';

function buildKeywordRule(keywordRules) {
  return keywordRules.map(k => `${k}`).join(' OR ');
}

export function openApportionmentResourceFromKeywordDnd(
  periodId,
  keywords,
  keywordRule,
  ruleType,
  isHighPriority,
  categories,
) {
  return {
    type: APPORTIONMENT_RESOURCE_FROM_KEYWORD,
    payload: {
      keywordRule: buildKeywordRule(keywordRule.map(x => x.keywordRule)),
      rules: keywordRule,
      periodId: periodId,
      keywords: keywords.map(x => x[0]).map(y => y.keyword),
      isDraggedFromKeyword: true,
      apportionableType: 1, // Rule
      ruleType: ruleType,
      isHighPriority: isHighPriority,
      categories: categories,
    },
  };
}

export function openApportionmentResourceFromCustomRule(
  periodId,
  keywordRule,
  ruleId = 0,
  ruleType,
  isHighPriority,
  categories,
) {
  return {
    type: APPORTIONMENT_RESOURCE_FROM_CUSTOM_RULE,
    payload: {
      keywordRule: keywordRule,
      periodId: periodId,
      isCustomRule: true,
      ruleId: ruleId,
      ruleType: ruleType,
      isHighPriority: isHighPriority,
      categories: categories,
    },
  };
}

export function openApportionmentResourceFromLineItem(
  periodId, lineItemIds,
  categories, categorizationType, previousCategoryId,
) {
  return {
    type: APPORTIONMENT_RESOURCE_FROM_LINE_ITEM,
    payload: {
      periodId: periodId,
      lineItemIds: lineItemIds,
      categories: categories,
      isSingleLineItem: lineItemIds.length === 1,
      categorizationType: categorizationType,
      previousCategoryId: previousCategoryId,
    },
  };
}


export function openApportionmentResourceFromRefList(
  periodId,
  keywordRule,
  isSubstring,
  referenceListId,
  ruleType,
  isHighPriority,
  categories,
) {
  return {
    type: APPORTIONMENT_RESOURCE_FROM_REFLIST,
    payload: {
      keywordRule: keywordRule,
      periodId: periodId,
      isopenViewReferenceList: true,
      isSubstring: isSubstring,
      referenceListId: referenceListId,
      ruleType: ruleType,
      isHighPriority: isHighPriority,
      categories: categories,
    },
  };
}

export function apportionLineItemsSuccess(data) {
  return {
    type: APPORTION_LINE_ITEM_SUCCESS,
    data: data,
  };
}

export function closeApportionmentModal() {
  return {
    type: CLOSE_APPORTIONMENT_MODAL,
  };
}

export function requestCreateApportionable(
  periodId,
  keywordRules = '',
  lineItemIds = [],
  amount = 0,
  isLineItems = false,
  ruleType = 0,
  isHighPriority = false,
  categories = [],
  rules = [],
  definitionText = '',
  tagDictionary,
  priority = 1,
  tagInherited = {},
  tags,
  addedBy = '',
) {
  return {
    type: REQUEST_CREATE_APPORTIONABLE,
    payload: {
      periodId: periodId,
      keywordRule: keywordRules,
      lineItemIds: lineItemIds,
      amount: amount,
      definitionText: definitionText,
      priority: priority,
      addedBy: addedBy,
      tagDictionary: tagDictionary,
      tagInherited: tagInherited,
      tags: tags,
      ruleType: ruleType,
      isHighPriority: isHighPriority,
      categories: categories,
      isApportionable: true,
      rules: rules,
    },
    isLineItems: isLineItems,
  };
}
