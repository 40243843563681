import {
  GET_PERIOD_IMPORT_DETAILS,
  GET_PERIOD_IMPORT_DETAILS_SUCCESS,
  SET_REDIRECTED_FROM_REVIEW_PAGE,
} from 'Store/Areas/Import/PeriodImportDetailsActions';

const initialState = {
  loading: false,
  importDetails: {
    mainDocName: '',
    supportingDocNames: [],
    tagNames: [],
    categoryNames: [],
    referenceListNames: [],
    machineLearningTemplate: '',
    originalTemplate: '',
    isRedirectedFromReviewPage: false,
  },
};

export default function periodImportDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PERIOD_IMPORT_DETAILS:
    case GET_PERIOD_IMPORT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        importDetails: action.importDetails === undefined ?
          initialState.importDetails : action.importDetails,
      });
    case SET_REDIRECTED_FROM_REVIEW_PAGE:
      return Object.assign({}, state, {
        isRedirectedFromReviewPage: action.isRedirectedFromReviewPage,
      });
    default:
      return state;
  }
}
