import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  REQUEST_PROCESS,
  RESTART_PROCESS,
  requestProcessSuccess,
  requestSkipMachineLearningSuccess,
  REQUEST_APPLY_MACHINE_LEARNING,
  REQUEST_SKIP_MACHINE_LEARNING,
  RESUME_PROCESSING,
  reportProcessingProgress,
  RETRY_FAILED_IMPORT,
  QUEUE_MACHINE_LEARNING_PREDICTIONS,
  CANCEL_IMPORT,
  REQUEST_PROCESS_NEW_SUPPORTING_DOCS,
  requestProcessNewSupportingDocsSuccess,
  resetProcessingState,
} from 'Store/Areas/Import/ProcessActions';
import {
  PROCESS_PERIOD_URL,
  RESTART_PROCESS_PERIOD_URL,
  ML_APPLY_MACHINE_LEARNING_URL,
  ML_SKIP_MACHINE_LEARNING_URL,
  RESUME_PROCESS_PERIOD_URL,
  RETRY_FAILED_IMPORT_URL,
  QUEUE_MACHINE_LEARNING_PREDICTIONS_URL,
  CANCEL_IMPORT_URL,
  PROCESS_NEW_SUPPORTING_DOCS_URL,
} from 'Store/Api/ApiEndpoints';
import { Get, Post } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { getPeriodSuccess, getPeriod, updatePeriodStatus } from 'Store/Areas/Period/PeriodActions';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { routes } from 'Constants/Routes';
import { importStages } from 'Constants/Processing/ImportStages';

/* Worker Sagas */
function* process(action) {
  const response = yield Get(PROCESS_PERIOD_URL(action.periodId));
  if (response.ok) {
    yield put(requestProcessSuccess(action.periodId));
    yield put(getPeriodSuccess(action.periodId, {
      status: periodStatuses.importRunning,
      periodId: action.periodId,
    }));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* restartProcess(action) {
  const response = yield Get(RESTART_PROCESS_PERIOD_URL(action.periodId));
  if (response.ok) {
    yield put(requestProcessSuccess(action.periodId));
    yield put(getPeriodSuccess(action.periodId, {
      status: periodStatuses.importRunning,
      periodId: action.periodId,
    }));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* processNewSupportingDocs(action) {
  const response = yield Post(PROCESS_NEW_SUPPORTING_DOCS_URL, action.payload);
  if (response.ok) {
    yield put(requestProcessNewSupportingDocsSuccess());
    yield put(push(routes.processing));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* applyMachineLearning(action) {
  const response = yield Post(ML_APPLY_MACHINE_LEARNING_URL, action.payload);
  if (response.ok) {
    yield put(reportProcessingProgress('Applying Machine Learning', importStages.applyingMachineLearning, false));
    yield put(push(routes.processing));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* skipMachineLearning(action) {
  const response = yield Post(ML_SKIP_MACHINE_LEARNING_URL, action.payload);
  if (response.ok) {
    yield put(requestSkipMachineLearningSuccess(action.payload.periodId));
    yield put(getPeriod(action.payload.periodId));
    yield put(push(routes.categorisation.referenceLists));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* resumeProcessing(action) {
  const response = yield Post(RESUME_PROCESS_PERIOD_URL, action.payload);
  if (response.ok) {
    yield put(reportProcessingProgress('', importStages.extractingKeywords));
    yield put(push(routes.processing));
    yield put(requestProcessSuccess(action.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* queueMachineLearningPredictions(action) {
  const response = yield Post(QUEUE_MACHINE_LEARNING_PREDICTIONS_URL, action.payload);
  if (response.ok) {
    yield put(reportProcessingProgress('', importStages.gettingMachineLearningPredictions));
    yield put(push(routes.processing));
    yield put(requestProcessSuccess(action.payload.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* cancelImportPeriod(action) {
  const response = yield Post(CANCEL_IMPORT_URL, action.payload);
  if (response.ok) {
    yield put(updatePeriodStatus(action.payload.periodId, periodStatuses.notStarted));
    yield put(resetProcessingState());
  } else {
    yield handleStatusCode(response.status);
  }
}

function* retryFailedImport(action) {
  const response = yield Post(RETRY_FAILED_IMPORT_URL, action.payload);
  if (response.ok) {
    yield put(reportProcessingProgress('', importStages.gettingStarted));
    yield put(push(routes.processing));
    yield put(requestProcessSuccess(action.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* processSagas() {
  yield takeLatest(REQUEST_PROCESS, process);
  yield takeLatest(RESTART_PROCESS, restartProcess);
  yield takeLatest(REQUEST_PROCESS_NEW_SUPPORTING_DOCS, processNewSupportingDocs);
  yield takeLatest(REQUEST_APPLY_MACHINE_LEARNING, applyMachineLearning);
  yield takeLatest(REQUEST_SKIP_MACHINE_LEARNING, skipMachineLearning);
  yield takeLatest(RESUME_PROCESSING, resumeProcessing);
  yield takeLatest(QUEUE_MACHINE_LEARNING_PREDICTIONS, queueMachineLearningPredictions);
  yield takeLatest(CANCEL_IMPORT, cancelImportPeriod);
  yield takeLatest(RETRY_FAILED_IMPORT, retryFailedImport);
}
