import React from 'react';
import { withStyles, withTheme, Paper, TextField, IconButton } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import { commonStrings } from 'Constants/CommonStrings';
import { title } from 'Constants/App/Titles/Strings';
import DragnDrop from 'Constants/DragnDrop';
import { strings } from 'Constants/Categorization/Strings';
import { selectMappedTagsFromPeriod } from 'Constants/Categorization/Tags';
import { isReadOnlySelector, isAnyLineItemFlaggedSelector } from 'Store/Areas/Period/PeriodSelectors';
import { navigateToReferenceLists } from 'Store/Areas/Categorisation/ReferenceListActions';
import { processingSelector } from 'Store/Areas/Rule/CategorisationSelectors';
import { resetRule } from 'Store/Areas/Rule/RuleBuilderActions';
import Close from 'Assets/Images/exit-ic-light-blue.svg';
import Breadcrumb from 'Components/Shared/Breadcrumbs/ChevronBreadcrumb/Breadcrumb';
import Message from 'Components/Shared/Message/Message';
import GenericDragLayer from 'Components/Shared/DragNDrop/GenericDragLayer';
import RulesBuilder from './RulesBuilder/RulesBuilder';
import { styles } from './CustomRules.styles';
import { OperatorsPanel, DragRule, DragRow, Operator, AddElementsRow } from './RulesBuilder/Components';

class CustomKeywords extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showNotesArea: false,
      note: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(resetRule());
    document.title = title.customRules;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.processing && !this.props.processing) {
      this.props.dispatch(resetRule());
      this.resetNote();
    }
  }

  onChange = (event) => {
    this.setState({
      note: event.target.value,
    });
  }

  resetNote = () => {
    this.setState({
      note: '',
      showNotesArea: false,
    });
  }

  toggleNotesArea = (visible) => {
    this.setState({
      showNotesArea: visible,
    });
  }

  renderDragLayer = (item, itemType) => {
    switch (itemType) {
      case DragnDrop.customRule:
        return (
          <DragRule
            text={this.props.ruleText}
          />
        );
      case DragnDrop.customRuleSegment:
        return (
          <DragRow
            tag={item.tag}
            condition={item.condition}
            join={item.join}
            operator={item.operator}
            width={item.width}
          />
        );
      case DragnDrop.operator.brackets:
      case DragnDrop.operator.tag:
      case DragnDrop.operator.join:
      case DragnDrop.operator.operator:
        return (
          <Operator
            borderColor={item.borderColor}
            type={item.type}
          >
            {item.children}
          </Operator>
        );
      default:
        return null;
    }
  }

  render() {
    const {
      classes,
      tags,
      isReadOnly,
      isAnyLineItemFlagged,
    } = this.props;
    const { showNotesArea, note } = this.state;
    return (
      <div>
        <If condition={!isReadOnly}>
          <GenericDragLayer disableIE={false}>
            {this.renderDragLayer}
          </GenericDragLayer>
        </If>
        <Breadcrumb
          items={[
            strings.referenceLists,
            strings.keyword,
            strings.keywordCombination,
            strings.customRules,
            strings.lineItems,
            strings.conflictedLineItems,
            strings.flaggedLineItems,
          ]}
          activeIndex={3}
          className={classes.breadcrumb}
          selectableIndexes={[0, 1, 4, 5, ...isAnyLineItemFlagged ? [6] : []]}
          onClicks={
            [
              () => this.props.dispatch(navigateToReferenceLists()),
              () => this.props.dispatch(push(routes.categorisation.singleKeywords)),
              null,
              null,
              () => this.props.dispatch(push(routes.categorisation.lineItems)),
              () => this.props.dispatch(push(routes.categorisation.conflictingLineItems)),
              () => this.props.dispatch(push(routes.categorisation.flaggedLineItems)),
            ]
          }
          isSelectable
        />
        <Paper className={classes.content}>
          <Message
            className={classes.message}
            title={commonStrings.didYouKnow}
            message={strings.customKeywordsDidYouKnow}
          />
          <div className={classes.grid}>
            <div className={classes.builder}><RulesBuilder note={note} /></div>
            <div className={classes.operators}>
              <OperatorsPanel tags={tags} isReadOnly={isReadOnly} />
            </div>
          </div>
          <div className={classes.flexContainer}>
            <div className={classes.buttons}><AddElementsRow /></div>
            <button
              className={(showNotesArea || isReadOnly) ? classes.buttonDisabled : classes.button}
              onClick={() => this.toggleNotesArea(true)}
              disabled={!!showNotesArea || isReadOnly}
            >
              {strings.addNote}
            </button>
            {showNotesArea &&
              <div className={classes.deleteButton}>
                <IconButton
                  className={classes.icon}
                  onClick={() => this.toggleNotesArea(false)}
                >
                  <img src={Close} alt="remove" />
                </IconButton>
              </div>
            }
          </div>
          {showNotesArea &&
            <TextField
              placeholder={strings.customRuleNotePlaceholder}
              margin="dense"
              InputProps={{
                classes: {
                  input: classes.textSize,
                },
                inputProps: {
                  maxLength: '255',
                },
              }}
              variant="outlined"
              onChange={this.onChange}
            />
          }
        </Paper>
      </div>
    );
  }
}

CustomKeywords.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  ruleText: PropTypes.string.isRequired,
  processing: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isAnyLineItemFlagged: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    tags: selectMappedTagsFromPeriod(state),
    ruleText: state.rules.ruleBuilder.rule,
    processing: processingSelector(state),
    isReadOnly: isReadOnlySelector(state),
    isAnyLineItemFlagged: isAnyLineItemFlaggedSelector(state),
  };
}

export default compose(connect(mapStateToProps), withStyles(styles), withTheme())(CustomKeywords);
