import React from 'react';
import { compose } from 'recompose';
import withLineItems, { sortFields } from 'Components/Shared/LineItemsProvider/LineItemsProvider';
import DataGrid from 'Components/Shared/DataGrid/DataGrid';
import SimpleHeader from 'Components/Shared/DataGrid/Cells/SimpleHeader/SimpleHeader';
import SimpleCell from 'Components/Shared/DataGrid/Cells/SimpleCell/SimpleCell';
import PropTypes from 'prop-types';
import { formatCellValue } from 'Helpers/LineItemHelpers';
import { commonStrings } from 'Constants/CommonStrings';
import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';
import { withStyles } from '@material-ui/core';
import withSize from 'Components/Shared/SizeProvider/SizeProvider';
import { styles } from './ConflictTable.styles';

const minRegularColumnWidth = 150;
const minWideColumnWidth = 300;

class ConflictTable extends React.PureComponent {
  componentDidMount() {
    this.props.setOptions({
      sortField: sortFields.amount,
      matchedRuleIds: this.props.conflicts.map(x => x.id),
      categorizationType: categorizationTypes.rule,
    });
  }

  getCellValue = ({ rowIndex, columnIndex }) => {
    const {
      lineItems,
      lineItemsColumns,
      amountColumnIndex,
    } = this.props;

    const cell = lineItems[rowIndex].columns[lineItemsColumns[columnIndex]];

    if (columnIndex === amountColumnIndex) {
      return cell.value;
    }

    return formatCellValue(cell);
  }

  getWidth = ({ index }) => {
    const {
      descriptionColumnIndex,
      width,
      lineItemsColumns,
    } = this.props;

    const gridMargin = 20;
    const regularColumns = lineItemsColumns.length - 1;
    const wideColumns = 1;
    const minWidth = (regularColumns * minRegularColumnWidth) + (wideColumns * minWideColumnWidth);
    const remainder = width - minWidth - gridMargin;

    let extraColumnWidth = 0;
    if (remainder > 0) {
      extraColumnWidth = remainder / lineItemsColumns.length;
    }

    switch (index) {
      case descriptionColumnIndex:
        return minWideColumnWidth + extraColumnWidth;
      default:
        return minRegularColumnWidth + extraColumnWidth;
    }
  }
  render() {
    const {
      currencySymbol,
      lineItems,
      lineItemsColumnHeaders,
      lineItemsHasMore,
      lineItemsLoadMore,
      lineItemsLoading,
      amountColumnIndex,
      descriptionColumnIndex,
      classes,
      width,
    } = this.props;
    return (
      <DataGrid
        recomputeGridSizeKey={`${descriptionColumnIndex}${amountColumnIndex}${width}`}
        columns={lineItemsColumnHeaders}
        rows={lineItems}
        getCellValue={this.getCellValue}
        hasNextPage={lineItemsHasMore}
        isNextPageLoading={lineItemsLoading}
        loadNextPage={lineItemsLoadMore}
        getWidth={this.getWidth}
        rowMargin={10}
        childRowHeight={30}
        parentRowHeight={30}
        noDataMessage={commonStrings.noLineItems}
        renderHeaderCell={props => (
          <SimpleHeader {...props} className={classes.header} />
        )}
        renderCell={props => (
          <SimpleCell
            {...props}
            currencySymbol={currencySymbol}
            className={classes.cellBorder}
            formatAsCurrency={props.index === amountColumnIndex}
          />
        )}
      />
    );
  }
}

ConflictTable.propTypes = {
  conflicts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    rule: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
      categoryId: PropTypes.number,
      categoryName: PropTypes.string,
    })),
  })).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  width: PropTypes.number.isRequired,
  // WithLineItems props
  setOptions: PropTypes.func.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  lineItemsColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineItemsColumnHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineItemsHasMore: PropTypes.bool.isRequired,
  lineItemsLoadMore: PropTypes.func.isRequired,
  lineItemsLoading: PropTypes.bool.isRequired,
  amountColumnIndex: PropTypes.number.isRequired,
  descriptionColumnIndex: PropTypes.number.isRequired,
  currencySymbol: PropTypes.string.isRequired,
};

export default compose(
  withSize(),
  withLineItems(),
  withStyles(styles),
)(ConflictTable);
