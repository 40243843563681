import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';
import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';
import { RESET_CATEGORISATION_CREATION, CATEGORISE_ITEMS, REQUEST_TAG_SELECTION, REQUEST_APPORTIONMENT, REQUEST_CATEGORISATION_REASON } from './CategorisationActions';

const initialState = {
  ruleProperties: {
    isHighPriority: false,
    ruleText: '',
    note: '',
    keywords: [],
    selectedTag: null,
    lineItemIds: [],
    referenceListName: null,
  },
  categories: [],
  categoryType: '',
  displayTagSelection: false,
  displayApportionmentSelection: false,
  displayLineItemsCategorisationReasonModal: false,
  searchTerm: '',
  categorizationType: categorizationTypes.uncategorized,
};

export default function categorisationReducer(state = initialState, action) {
  switch (action.type) {
    case CATEGORISE_ITEMS:
      return Object.assign({}, state, {
        ruleProperties: {
          ruleText: action.ruleProperties.ruleText,
          note: action.ruleProperties.note,
          keywords: action.ruleProperties.keywords,
          isHighPriority: action.ruleProperties.isHighPriority,
          referenceListName: action.ruleProperties.referenceListName,
          lineItemIds: action.ruleProperties.lineItemIds,
          selectedTag: action.ruleProperties.selectedTag,
        },
        categories: action.categories,
        categoryType: action.categoryType,
        displayTagSelection: false,
        displayApportionmentSelection: false,
        displayLineItemsCategorisationReasonModal: false,
        searchTerm: action.searchTerm,
        categorizationType: action.categorizationType,
      });
    case REQUEST_APPORTIONMENT:
      return Object.assign({}, state, {
        displayApportionmentSelection: true,
      });
    case REQUEST_TAG_SELECTION:
      return Object.assign({}, state, {
        displayTagSelection: true,
      });
    case RESET_CATEGORISATION_CREATION:
    case RESET_PERIOD:
      return initialState;
    case REQUEST_CATEGORISATION_REASON:
      return Object.assign({}, state, {
        displayLineItemsCategorisationReasonModal: true,
      });
    default:
      return state;
  }
}
