export const REVIEW_CATEGORY = 'REVIEW_CATEGORY/REVIEW_CATEGORY';
export const RESET_REVIEW_CATEGORY = 'REVIEW_CATEGORY/RESET_REVIEW_CATEGORY';
export const GET_POPULAR_KEYWORDS_SUCCESS = 'REVIEW_CATEGORY/GET_POPULAR_KEYWORDS_SUCCESS';
export const GET_POPULAR_KEYWORDS_BY_TAG = 'REVIEW_CATEGORY/GET_POPULAR_KEYWORDS_BY_TAG';
export const GET_POPULAR_KEYWORDS_BY_TAG_SUCCESS = 'REVIEW_CATEGORY/GET_POPULAR_KEYWORDS_BY_TAG_SUCCESS';
export const SELECT_TAG = 'REVIEW_CATEGORY/SELECT_TAG';
export const RESET_APPORITONABLE = 'REVIEW_CATEGORY/RESET_APPORITONABLE';

export function selectCategoryForReview(category, periodId, isApportionable) {
  return {
    type: REVIEW_CATEGORY,
    payload: {
      category: category,
      periodId: periodId,
      isApportionable: isApportionable,
    },
  };
}

export function getPopularKeywordsSuccess(popularKeywords) {
  return {
    type: GET_POPULAR_KEYWORDS_SUCCESS,
    payload: {
      popularKeywords: popularKeywords,
    },
  };
}

export function getPopularKeywordsByTag(periodId, categoryId, tagId, pageNumber) {
  return {
    type: GET_POPULAR_KEYWORDS_BY_TAG,
    payload: {
      categoryId: categoryId,
      periodId: periodId,
      tagId: tagId,
      pageNumber: pageNumber,
    },
  };
}

export function getPopularKeywordsByTagSuccess(response) {
  return {
    type: GET_POPULAR_KEYWORDS_BY_TAG_SUCCESS,
    popularKeywordsByTag: response.popularKeywords,
    pageNumber: response.currentPageNumber,
    hasMore: response.hasMore,
  };
}

export function selectTag(tagId) {
  return {
    type: SELECT_TAG,
    tagId: tagId,
  };
}

export function resetReviewCategory() {
  return {
    type: RESET_REVIEW_CATEGORY,
  };
}

export function resetApportionable() {
  return {
    type: RESET_APPORITONABLE,
  };
}
