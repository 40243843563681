export const styles = ({
  root: {
    padding: 30,
  },
  paper: {
    height: '100%',
  },
  marginTop: {
    marginTop: 20,
  },
  tab: {
    height: 107,
  },
  categoriesChartSettingsGrid: {
    paddingTop: '24px !important',
    paddingRight: '24px !important',
  },
});
