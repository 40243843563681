import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import PropTypes from 'prop-types';
import Sidebar from 'Components/UserSidebar/Sidebar';
import { Switch } from 'react-router';
import ErrorModal from 'Components/Shared/Modal/ErrorModal';
import { strings } from 'Constants/Error/Strings';
import ErrorMessage from './ErrorMessage';
import Robot from './Robot';
import styles from './ErrorPage.styles';

class ErrorPage extends React.PureComponent {
  render() {
    const {
      classes, errorPage, userActions,
    } = this.props;
    const hasUserActions = this.props.userActions !== null;
    return (
      <Switch>
        <Choose>
          <When condition={errorPage.recommendation === strings.externalReviewEmptyFile}>
            <ErrorModal
              open
              onClose={this.props.cancelImport}
              error={errorPage.recommendation}
              title={errorPage.message}
            />
          </When>
          <Otherwise>
            <UserSidebarStructure
              sidebarContent={<Sidebar />}
              sidebarContentLoading={false}
              mainContentLoading={false}
              isCollapsible
              collapsed
              mainContent={
                <div className={classes.errorContainer}>
                  <Robot />
                  <ErrorMessage
                    message={errorPage.message}
                    recommendation={errorPage.recommendation}
                    recommendationClassName={classes.recommendations}
                  />
                  {hasUserActions &&
                  <div>
                    {userActions}
                  </div>
                  }
                </div>
              }
            />
          </Otherwise>
        </Choose>
      </Switch>
    );
  }
}

ErrorPage.defaultProps = {
  userActions: null,
};

ErrorPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  errorPage: PropTypes.shape({
    message: PropTypes.string.isRequired,
    recommendation: PropTypes.string.isRequired,
  }).isRequired,
  userActions: PropTypes.element,
  cancelImport: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    errorPage: state.app.errorPage,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(ErrorPage);
