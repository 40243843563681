import React from 'react';
import {
  withTheme,
  withStyles,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  Chip,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { strings } from 'Constants/Setup/Admin/Strings';
import { permissions as userPermissions } from 'Constants/Setup/Admin/UserConstants';
import TableRowMessage from 'Components/Shared/TableRowMessage/TableRowMessage';
import {
  selectedGroupIdSelector,
  selectedUserIdsSelector,
  adminTreePickerDataSelector,
} from 'Store/Areas/Setup/Admin/SetupAdminSelectors';
import styles from './UsersTable.styles';

class UsersTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectAllCheckbox: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.adminTreePickerData !== this.props.adminTreePickerData) {
      this.resetSelectAllCheckbox();
    }
  }

  resetSelectAllCheckbox() {
    this.setState({
      selectAllCheckbox: false,
    });
  }

  isUserNotSelectable = (selectedItemLevel, item) => {
    if (selectedItemLevel !== 0 && item.accessLevel === userPermissions.groupAdmin) {
      return true;
    }
    if (item.accessLevel === userPermissions.entityAdmin) {
      if (selectedItemLevel !== 1) {
        return true;
      }
      if (this.props.permissions.isGroupAdmin &&
          this.props.permissions.groupAdmins.includes(this.props.selectedGroupId)) {
        return false;
      }
      return true;
    }
    return false;
  };

  onChangeSelectEvent = (checked, item) => {
    if (!this.isUserNotSelectable(this.props.selectedItemLevel, item)) {
      this.props.onCheckboxEvent(checked, item.id);
    }
  };

  onChangeSelectAllEvent = (checked, data) => {
    data.forEach((item) => {
      this.onChangeSelectEvent(checked, item);
    });

    this.setState({
      selectAllCheckbox: checked,
    });
  };

  render() {
    const {
      data,
      classes,
      emptyMessage,
      selectedItemLevel,
      onAccessToggled,
      selectedUserIds,
    } = this.props;
    const {
      selectAllCheckbox,
    } = this.state;
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.checkbox}>
              <Checkbox
                checked={selectAllCheckbox}
                onChange={(event, checked) => this.onChangeSelectAllEvent(checked, data)}
              />
            </TableCell>
            <TableCell className={classes.header}>{strings.emailAddress}</TableCell>
            {/* <TableCell className={classes.header}>{strings.syncUser}</TableCell> */}
            <TableCell className={classes.header}>{strings.status}</TableCell>
            <TableCell className={classes.header} align="inherit">{strings.permissionType}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Choose>
            <When condition={data.length}>
              {data.map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell className={classes.checkbox}>
                      <Checkbox
                        checked={selectedUserIds.includes(item.id)}
                        onChange={(event, checked) => this.onChangeSelectEvent(checked, item)}
                        disabled={this.isUserNotSelectable(selectedItemLevel, item)}
                      />
                    </TableCell>
                    <TableCell className={classes.text}>{item.emailAddress}</TableCell>
                    {/* <TableCell className={classes.text}>{ (item.identityProviderUserId ?
                      'Yes' : 'No')}</TableCell> */
                    }
                    <TableCell>
                      {strings.inviteStatus(item.inviteStatus)}
                    </TableCell>
                    <TableCell align="inherit">
                      {item.accessLevel === userPermissions.groupAdmin &&
                        <Chip
                          label={strings.groupAdmin}
                          className={classes.enabled}
                        />
                      }
                      {item.accessLevel === userPermissions.entityAdmin &&
                        <Chip
                          label={strings.entityAdmin}
                          className={classes.enabled}
                        />
                      }
                      {(selectedItemLevel !== 1 && item.accessLevel === userPermissions.user) &&
                        <React.Fragment>
                          <Chip
                            onClick={item.readOnly ?
                              () => onAccessToggled(item.id, false) :
                              null
                            }
                            label={strings.fullAccess}
                            className={item.readOnly ? classes.disabled : classes.enabled}
                          />
                          <Chip
                            onClick={!item.readOnly ?
                              () => onAccessToggled(item.id, true) :
                              null
                            }
                            label={strings.readOnly}
                            className={item.readOnly ? classes.enabled : classes.disabled}
                          />
                        </React.Fragment>
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </When>
            <Otherwise>
              <TableRowMessage text={emptyMessage} />
            </Otherwise>
          </Choose>
        </TableBody>
      </Table>
    );
  }
}

UsersTable.defaultProps = {
  selectedGroupId: 0,
};

UsersTable.propTypes = {
  onCheckboxEvent: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired, //eslint-disable-line
  emptyMessage: PropTypes.string.isRequired,
  selectedItemLevel: PropTypes.number.isRequired,
  onAccessToggled: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    isGroupAdmin: PropTypes.bool,
    groupAdmins: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  selectedGroupId: PropTypes.number,
  selectedUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  adminTreePickerData: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    editingItem: PropTypes.object,
    editingItemLevel: PropTypes.number,
    emailFilter: PropTypes.string,
    loading: PropTypes.bool,
    taxContextFilter: PropTypes.number,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    permissions: state.user.permissions.data,
    selectedGroupId: selectedGroupIdSelector(state),
    selectedUserIds: selectedUserIdsSelector(state),
    adminTreePickerData: adminTreePickerDataSelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(UsersTable);
