export const styles = theme => ({
  paperRoot: {
    marginTop: 10,
    marginBottom: 10,
  },
  contentRoot: {
    fontSize: theme.constants.fontSizes.small,
  },
  dragTextWrapper: {
    padding: 10,
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
  },
  dragRuleFilterText: {
    color: '#0091da',
    fontWeight: 'bold',
  },
  icon: {
    verticalAlign: 'sub',
    backgroundColor: 'transparent',
    border: 'none',
  },
  dragSource: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    zIndex: 10,
    cursor: 'pointer',
  },
});
