import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import {
  BEGIN_DELETE_FILE,
  MAP_FILE_TO_SCHEMA,
  deleteFileSuccess,
  mapFileToSchemaCompleted,
  BEGIN_DELETE_SUPPORTING_DOC,
  deleteSupportingDocSuccess,
  CLEAR_PERIOD_FILES,
} from 'Store/Areas/Import/UploadActions';
import { getPeriod, updatePeriodStatus } from 'Store/Areas/Period/PeriodActions';
import { clearExportFile } from 'Store/Areas/Export/ExportActions';
import { Post } from 'Store/Api/CallApi';
import { DELETE_FILE_URL, MAP_FILE_TO_SCHEMA_URL, DELETE_SUPPORTING_DOC_URL, PERIOD_CLEAR_FILES } from 'Store/Api/ApiEndpoints';
import { routes } from 'Constants/Routes';
import { push } from 'connected-react-router';
import { periodStatuses } from 'Constants/PeriodStatuses';

/* Worker Sagas */
function* postDeleteFile(action) {
  const response = yield Post(DELETE_FILE_URL, {
    cloudReference: action.cloudReference,
    fileName: action.fileName,
    periodId: action.periodId,
    changeSupportingDocsAfterPeriodCreation: action.changeSupportingDocsAfterPeriodCreation,
  });
  if (response.ok) {
    yield put(deleteFileSuccess(action.periodId));
    if (action.isValidationError) {
      yield put(updatePeriodStatus(action.periodId, periodStatuses.periodCategorisationComplete));
    } else {
      yield put(getPeriod(action.periodId, false));
    }
  } else {
    yield handleStatusCode(response.status);
  }
}

function* postDeleteSupportingDoc(action) {
  const response = yield Post(DELETE_SUPPORTING_DOC_URL, {
    cloudReference: action.cloudReference,
    fileName: action.fileName,
    periodId: action.periodId,
  });
  if (response.ok) {
    yield put(deleteSupportingDocSuccess(action.periodId));
    yield put(push(routes.processing));
    yield put(getPeriod(action.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* postMapFileToSchema(action) {
  const response = yield Post(MAP_FILE_TO_SCHEMA_URL, action.payload);
  if (response.ok) {
    const success = yield response.json();
    yield put(mapFileToSchemaCompleted(success));
    yield put(getPeriod(action.payload.periodId, true));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* clearPeriodFiles(action) {
  const response = yield Post(PERIOD_CLEAR_FILES, action.payload);
  if (response.ok) {
    yield put(clearExportFile(action.payload.periodId));
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* uploadSagas() {
  yield takeLatest(BEGIN_DELETE_FILE, postDeleteFile);
  yield takeLatest(BEGIN_DELETE_SUPPORTING_DOC, postDeleteSupportingDoc);
  yield takeLatest(MAP_FILE_TO_SCHEMA, postMapFileToSchema);
  yield takeLatest(CLEAR_PERIOD_FILES, clearPeriodFiles);
}
