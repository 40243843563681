const styles = theme => (
  {
    container: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      lineHeight: '34px',
      height: '90vh',
    },
    workSmoothly: {
      fontSize: theme.constants.fontSizes.regular,
      lineHeight: 1,
    },
    yourDocuments: {
      fontSize: '32px',
      marginBottom: 10,
      lineHeight: '32px',
    },
    marginTop: {
      marginTop: 10,
    },
  });

export default styles;
