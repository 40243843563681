export const styles = theme => ({
  root: {
    width: 475,
    padding: 20,
  },
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
  },
  content: {
    fontSize: theme.constants.fontSizes.regular,
    marginBottom: 40,
    marginTop: 10,
  },
});

