import { REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE, TOGGLE_CORRUPTED_FILE_DETECTED_MODAL } from 'Store/Areas/FileUpload/FileUploadActions';
import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';
import { DELETE_FILE_SUCCESS } from 'Store/Areas/Import/UploadActions';
import { SELECT_PERIOD } from 'Store/Areas/Projects/UserTreePickerActions';
import {
  GET_EXTERNAL_REVIEW_EXPORTED_FILES,
  GET_EXTERNAL_REVIEW_EXPORTED_FILES_SUCCESS,
  DELETE_EXTERNAL_REVIEW_EXPORTED_FILE,
  DELETE_EXTERNAL_REVIEW_EXPORTED_FILE_SUCCESS,
  SET_EXTERNAL_REVIEW_FILE_LOADING,
  SET_EXTERNAL_REVIEW_FILE_NAME,
  RESET_EXTERNAL_REVIEW_FILES,
  GET_EXTERNAL_REVIEW_IMPORTED_FILE,
  GET_EXTERNAL_REVIEW_IMPORTED_FILE_SUCCESS,
  GET_NUMBER_OF_UNCATEGORISED_LINE_ITEMS,
  GET_NUMBER_OF_UNCATEGORISED_LINE_ITEMS_SUCCESS,
  IS_EXTERNAL_REVIEW_EXPORTED_FILE_EXPIRED,
  IS_EXTERNAL_REVIEW_EXPORTED_FILE_EXPIRED_SUCCESS,
} from './ExternalReviewActions';

const initialState = {
  loading: false,
  files: [],
  fileLoading: false,
  externalReviewCloudRef: '',
  externalReviewFilename: '',
  uncategorisedLineItemCount: null,
  canExport: false,
  isExternalReviewExportedFileExpired: false,
};

export default function externalReviewReducer(state = initialState, action) {
  switch (action.type) {
    case GET_EXTERNAL_REVIEW_IMPORTED_FILE:
    case GET_EXTERNAL_REVIEW_EXPORTED_FILES:
    case DELETE_EXTERNAL_REVIEW_EXPORTED_FILE:
    case DELETE_EXTERNAL_REVIEW_EXPORTED_FILE_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
      });
    case GET_NUMBER_OF_UNCATEGORISED_LINE_ITEMS:
    case GET_NUMBER_OF_UNCATEGORISED_LINE_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        uncategorisedLineItemCount: action.uncategorisedLineItemCount,
        canExport: action.canExport,
      });
    case IS_EXTERNAL_REVIEW_EXPORTED_FILE_EXPIRED:
    case IS_EXTERNAL_REVIEW_EXPORTED_FILE_EXPIRED_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        isExternalReviewExportedFileExpired: action.isExternalReviewExportedFileExpired,
      });
    case GET_EXTERNAL_REVIEW_EXPORTED_FILES_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        files: action.files,
      });
    case GET_EXTERNAL_REVIEW_IMPORTED_FILE_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        externalReviewCloudRef: action.importedFile.cloudReference,
        externalReviewFilename: action.importedFile.name,
      });
    case SET_EXTERNAL_REVIEW_FILE_NAME:
      return Object.assign({}, state, {
        externalReviewCloudRef: action.externalReviewCloudRef,
        externalReviewFilename: action.externalReviewFilename,
        fileLoading: action.fileLoading,
      });
    case REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE:
    case SET_EXTERNAL_REVIEW_FILE_LOADING:
    case TOGGLE_CORRUPTED_FILE_DETECTED_MODAL:
      return Object.assign({}, state, {
        fileLoading: action.fileLoading,
      });
    case RESET_EXTERNAL_REVIEW_FILES:
    case DELETE_FILE_SUCCESS:
      return Object.assign({}, state, {
        externalReviewCloudRef: initialState.externalReviewCloudRef,
        externalReviewFilename: initialState.externalReviewFilename,
        fileLoading: initialState.fileLoading,
      });
    case SELECT_PERIOD:
    case RESET_PERIOD:
      return initialState;
    default:
      return state;
  }
}