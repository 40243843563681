import { put } from 'redux-saga/effects';
import { categoryListTypes } from 'Constants/Categorization/CategoryListTypes';
import { ruleTypes } from 'Constants/Rules/RuleConstants';
import { CategorisationStrategyHandler } from './CategorisationStrategyHandler';
import * as RuleActions from '../CategorisationActions';

export const keywordRuleStrategyHandler = new CategorisationStrategyHandler(
  categoryListTypes.categoryType.keywordRule,
  function* handleCategoryStrategy(action) {
    if (!this.checkCategoriesTotalTo1(action.categories)) {
      yield put(RuleActions.requestApportionment());
      return;
    }
    const baseRulePayload = {
      parentId: action.periodId,
      categories: action.categories,
      isHighPriority: action.ruleProperties.isHighPriority,
      ruleType: ruleTypes.dragAndDropRule,
    };

    const payload = {
      rules: action.ruleProperties.keywords.map(k => ({
        ...baseRulePayload,
        keywordRule: this.buildKeywordRule(k),
      })),
      isCategoryApportionable: this.checkApportionment(action.categories),
      isKeywordDrag: true,
      keywords: action.ruleProperties.keywords,
      parentId: action.periodId,
    };

    yield this.handleRuleCreation(payload, action.periodId);
  },
);
