const styles = theme => ({
  root: {},
  cancel: {
    width: '33%',
  },
  accept: {
    width: '67%',
  },
  fieldMargin: {
    marginBottom: 30,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
  },
  flex: {
    flexGrow: 1,
  },
  padded: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
  },
  title: {
    color: theme.palette.colors.white,
    fontSize: theme.constants.fontSizes.regular,
  },
  deleteButton: {
    fontSize: theme.constants.fontSizes.small,
    fontWeight: 'bold',
    height: 40,
    marginBottom: 50,
  },
});

export default styles;
