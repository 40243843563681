const styles = theme => ({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    borderRadius: 4,
  },
  backgroundMain: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.colors.mainLightened,
    },
    color: theme.palette.primary.contrastText,
  },
  backgroundDark: {
    backgroundColor: theme.palette.primary.darkAltButton,
    '&:hover': {
      backgroundColor: theme.palette.colors.darkLightened,
    },
    color: theme.palette.primary.contrastText,
  },
  backgroundRed: {
    backgroundColor: theme.palette.primary.redButton,
    '&:hover': {
      backgroundColor: theme.palette.colors.redLightened,
    },
    color: theme.palette.primary.contrastText,
  },
  backgroundGreen: {
    backgroundColor: theme.palette.primary.greenButton,
    '&:hover': {
      backgroundColor: theme.palette.colors.greenLightened,
    },
    color: theme.palette.primary.contrastText,
  },
  heightRegular: {
    height: 40,
    minHeight: 40,
  },
  heightBig: {
    height: 60,
    minHeight: 60,
  },
  rounded: {
    borderRadius: 4,
  },
  square: {
    borderRadius: 0,
  },
});

export default styles;
