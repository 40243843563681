import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { Get } from 'Store/Api/CallApi';
import { REVIEW_GET_METHOD_OF_CATEGORISATION } from 'Store/Api/ApiEndpoints';
import {
  GET_METHOD_OF_CATEGORISATION,
  getMethodOfCategorisationSuccess,
} from 'Store/Areas/Review/MethodOfCategorisationActions';


function* getMethodOfCategorisation(action) {
  const response = yield Get(REVIEW_GET_METHOD_OF_CATEGORISATION(action.periodId));

  if (response.ok) {
    const data = yield response.json();
    yield put(getMethodOfCategorisationSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* methodOfCategorisationSaga() {
  yield takeLatest(GET_METHOD_OF_CATEGORISATION, getMethodOfCategorisation);
}
