import { put } from 'redux-saga/effects';
import { routes } from 'Constants/Routes';
import { push } from 'connected-react-router';
import { setError } from 'Store/Areas/App/ErrorPageActions';
import { strings } from 'Constants/Error/Strings';
import { responseStatusCodes } from './StatusCodes';

export default function* handleStatusCode(code) {
  switch (code) {
    case responseStatusCodes.loginRequired:
      window.location.replace('/externalLogin');
      break;

    case responseStatusCodes.unauthorised:
      yield put(setError(strings.unauthorised, strings.unauthorisedRecommendation));
      yield put(push(routes.error));
      break;

    default:
      yield put(setError(strings.errorMessage, strings.errorRecommendation));
      yield put(push(routes.error));
  }
}

export function* getJsonResponse(response, handleFatalStatusCode = true) {
  if (response.ok || response.status === responseStatusCodes.badRequest) {
    const jsonResponse = yield response.json();

    if (jsonResponse || response.ok) {
      return {
        error: response.ok ? null : 'Bad request',
        success: response.ok,
        statusCode: response.status,
        ...jsonResponse,
      };
    }
  }

  if (handleFatalStatusCode) {
    yield handleStatusCode(response.status);
  }

  return false;
}
