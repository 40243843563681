import React from 'react';
import { withStyles, withTheme, Card, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { PropTypes } from 'prop-types';
import Search from 'Components/Shared/Inputs/Search';
import Select, { constants } from 'Components/Shared/Selects/Select';
import { sortOrder } from 'Constants/Categorization/QueryTermFilters';
import { TagTypes } from 'Constants/Categorization/Tags';
import { strings } from 'Constants/Categorization/Strings';
import { styles } from './KeywordFiltersPanel.styles';

class KeywordFiltersPanel extends React.PureComponent {
  render() {
    const {
      classes,
      keywordFilters,
      period,
      onSortOrderChanged,
      onTagFilterChanged,
      onSearchTermChanged,
      onSearchIconClicked,
      onSearchReset,
    } = this.props;
    return (
      <Card className={classes.root}>
        <Grid container direction="row" spacing={8}>
          <Grid item lg={4} md={6} sm={12}>
            <Select
              id={strings.sortBy}
              label={strings.sortBy}
              preventPlaceholder
              onChange={value => onSortOrderChanged(value)}
              data={[
                { id: sortOrder.valueDescending, name: strings.highestValue },
                { id: sortOrder.valueAscending, name: strings.lowestValue },
                { id: sortOrder.alphabeticallyAtoZ, name: strings.alphabeticallyAtoZ },
                { id: sortOrder.alphabeticallyZtoA, name: strings.alphabeticallyZtoA },
              ]}
              value={keywordFilters.sortOrder}
              colorScheme={constants.colorScheme.lightBlue}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            <Select
              id={strings.showKeywordsByTag}
              label={strings.showKeywordsByTag}
              preventPlaceholder
              onChange={value => onTagFilterChanged(value)}
              data={[
                { id: 0, name: strings.allTags },
                ...period.data.tags
                .filter((p) => {
                  return p.type === TagTypes.Text || p.type === TagTypes.TextWholeValue;
                })
                .map((p) => {
                  return { name: p.name, id: p.id };
                  }),
              ]}
              value={keywordFilters.tagFilter}
              colorScheme={constants.colorScheme.lightBlue}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            <Search
              id={strings.searchKeywords}
              label={strings.searchKeywords}
              onChange={value => onSearchTermChanged(value)}
              onSearch={() => onSearchIconClicked()}
              onReset={() => onSearchReset()}
              placeholder={strings.searchPlaceholder}
              value={keywordFilters.searchTerm}
            />
          </Grid>
        </Grid>
      </Card>
    );
  }
}

KeywordFiltersPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onSortOrderChanged: PropTypes.func.isRequired,
  onTagFilterChanged: PropTypes.func.isRequired,
  onSearchTermChanged: PropTypes.func.isRequired,
  onSearchIconClicked: PropTypes.func.isRequired,
  onSearchReset: PropTypes.func.isRequired,
  keywordFilters: PropTypes.shape({
    sortOrder: PropTypes.number.isRequired,
    tagFilter: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired,
  }).isRequired,
  period: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
  connect(),
)(KeywordFiltersPanel);
