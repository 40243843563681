import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { styles } from './LinearProgress.styles';

class LinearDeterminate extends React.PureComponent {
  render() {
    const { classes, progress } = this.props;
    return (
      <LinearProgress className={classes.root} variant="determinate" value={progress} classes={{ colorPrimary: classes.unfilledBar, barColorPrimary: classes.filledBar }} />
    );
  }
}

LinearDeterminate.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  progress: PropTypes.number.isRequired,
};

export default withStyles(styles)(LinearDeterminate);
