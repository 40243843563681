export const REQUEST_PROCESS = 'REQUEST_PROCESS';
export const RESTART_PROCESS = 'RESTART_PROCESS';
export const REQUEST_PROCESS_SUCCESS = 'REQUEST_PROCESS_SUCCESS';
export const REQUEST_PROCESS_NEW_SUPPORTING_DOCS = 'REQUEST_PROCESS_NEW_SUPPORTING_DOCS';
export const REQUEST_PROCESS_NEW_SUPPORTING_DOCS_SUCCESS = 'REQUEST_PROCESS_NEW_SUPPORTING_DOCS_SUCCESS';
export const REQUEST_APPLY_MACHINE_LEARNING = 'REQUEST_APPLY_MACHINE_LEARNING';
export const REQUEST_SKIP_MACHINE_LEARNING = 'REQUEST_SKIP_MACHINE_LEARNING';
export const REQUEST_SKIP_MACHINE_LEARNING_SUCCESS = 'REQUEST_SKIP_MACHINE_LEARNING_SUCCESS';
export const REPORT_PROCESSING_PROGRESS = 'REPORT_PROCESSING_PROGRESS';
export const RESUME_PROCESSING = 'PROCESS/RESUME_PROCESSING';
export const QUEUE_MACHINE_LEARNING_PREDICTIONS = 'PROCESS/QUEUE_MACHINE_LEARNING_PREDICTIONS';
export const CANCEL_IMPORT = 'PROCESS/CANCEL_IMPORT';
export const RETRY_FAILED_IMPORT = 'PROCESS/RETRY_FAILED_IMPORT';
export const RESET_PROCESSING_STATE = 'PROCESS/RESET_PROCESSING_STATE';

export function requestProcess(periodId) {
  return {
    type: REQUEST_PROCESS,
    periodId,
    loading: true,
    progress: null,
    lineItemIds: [],
  };
}

export function restartProcess(periodId) {
  return {
    type: RESTART_PROCESS,
    periodId,
    loading: true,
    progress: null,
    lineItemIds: [],
  };
}

export function requestProcessSuccess(periodId) {
  return {
    type: REQUEST_PROCESS_SUCCESS,
    loading: true,
    periodId,
  };
}

export function requestProcessNewSupportingDocs(periodId) {
  return {
    type: REQUEST_PROCESS_NEW_SUPPORTING_DOCS,
    loading: true,
    progress: null,
    payload: {
      periodId: periodId,
    },
  };
}

export function requestProcessNewSupportingDocsSuccess() {
  return {
    type: REQUEST_PROCESS_NEW_SUPPORTING_DOCS_SUCCESS,
    loading: false,
  };
}

export function requestApplyMachineLearning(periodId, valueLimit, confidenceLevel, lineItemIds) {
  return {
    type: REQUEST_APPLY_MACHINE_LEARNING,
    loading: true,
    progress: null,
    payload: {
      periodId: periodId,
      lineItemValueThreshold: valueLimit,
      confidenceLevel: confidenceLevel,
      lineItemIds: lineItemIds,
    },
  };
}

export function requestSkipMachineLearning(periodId) {
  return {
    type: REQUEST_SKIP_MACHINE_LEARNING,
    loading: true,
    payload: {
      periodId,
    },
  };
}

export function requestSkipMachineLearningSuccess(periodId) {
  return {
    type: REQUEST_SKIP_MACHINE_LEARNING_SUCCESS,
    loading: false,
    periodId,
  };
}

export function reportProcessingProgress(message, stage, shouldPoll) {
  return {
    type: REPORT_PROCESSING_PROGRESS,
    message: message,
    stage: stage,
    shouldPoll: shouldPoll,
  };
}

export function resumeProcessing(periodId) {
  return {
    type: RESUME_PROCESSING,
    payload: {
      periodId: periodId,
    },
  };
}

export function queueMachineLearningPredictions(periodId, mlModelId, threshold) {
  return {
    type: QUEUE_MACHINE_LEARNING_PREDICTIONS,
    payload: {
      periodId,
      machineLearningModelId: mlModelId,
      lineItemValueThreshold: threshold,
    },
  };
}

export function cancelImport(periodId, groupId) {
  return {
    type: CANCEL_IMPORT,
    payload: {
      periodId,
      groupId,
    },
  };
}

export function retryFailedImport(periodId) {
  return {
    type: RETRY_FAILED_IMPORT,
    payload: {
      periodId: periodId,
    },
  };
}

export function resetProcessingState() {
  return {
    type: RESET_PROCESSING_STATE,
  };
}
