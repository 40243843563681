const styles = theme => ({
  tagContainer: {
    backgroundColor: theme.palette.colors.gray,
    paddingRight: 20,
    height: '100%;',
  },
  tagsTitle: {
    margin: 0,
    fontSize: theme.constants.fontSizes.regular,
    color: theme.palette.primary.darkText,
    marginBottom: 15,
    lineHeight: 1.57,
  },
  tableContainer: {
    height: 400,
    overflowY: 'auto',
  },
  row: {
    border: 0,
    height: '100%',
    '&:nth-of-type(even)': {
      backgroundColor: '#f4f4f4',
    },
  },
  cell: {
    padding: '4px 5px 4px 5px',
    fontSize: theme.constants.fontSizes.small,
    color: theme.palette.primary.darkText,
    border: 0,
    display: 'flex',
    flexDirection: 'row',
    '&:last-child': {
      paddingRight: 5,
    },
  },
  flex: {
    flexGrow: 1,
  },
  bold: {
    fontWeight: 'bold',
  },
  icon: {
    marginTop: 2,
    height: 10,
    width: 10,
  },
});

export default styles;
