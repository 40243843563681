const styles = theme => ({
  root: {
    paddingBottom: 30,
  },
  headerCell: {
    height: 16,
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    color: theme.palette.primary.darkText,
    whiteSpace: 'nowrap',
    marginBottom: 10,
    outline: 'none',
  },
  gridCell: {
    padding: 3,
    fontSize: theme.constants.fontSizes.regular,
  },
  rowCell: {
    height: 50,
    lineHeight: '50px',
    backgroundColor: theme.palette.colors.white,
    marginBottom: 6,
    boxShadow: '0 1px 0 0 rgba(204, 204, 204, 0.5)',
  },
  closeCell: {
    textAlign: 'right',
    paddingRight: 15,
  },
  alignCenter: {
    textAlign: 'center',
  },
  dateColumn: {
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  fileNameCell: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export default styles;
