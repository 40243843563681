export const styles = theme => ({
  title: {
    fontSize: theme.constants.fontSizes.large,
    color: theme.palette.primary.darkText,
  },
  root: {
    padding: 20,
    marginTop: 20,
  },
  card: {
    padding: 20,
    marginTop: 10,
  },
  button: {
    marginTop: 20,
    marginRight: 10,
    paddingLeft: 30,
    paddingRight: 30,
    width: 180,
  },
  buttonDisabled: {
    marginTop: 20,
    marginRight: 10,
    paddingLeft: 30,
    paddingRight: 30,
    width: 180,
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.small,
    fontWeight: 'bold',
    border: 'none',
    opacity: 0.7,
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    fontSize: theme.constants.fontSizes.small,
    color: theme.palette.primary.darkText,
  },
  input: {
    fontSize: theme.constants.fontSizes.regular,
  },
  padding: {
    paddingRight: 50,
  },
  disabledSelect: {
    color: theme.palette.primary.darkText,
  },
});
