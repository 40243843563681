export const periodControlStatuses = {
  none: 'None',
  requested: 'Requested',
  approved: 'Approved',
  denied: 'Denied',
};

export function periodControlStatusText(status) {
  switch (status) {
    case 0:
      return 'None';
    case 1:
      return 'Requested';
    case 2:
      return 'Approved';
    case 3:
      return 'Denied';
    default:
      return '';
  }
}