export const GET_HIGHEST_VALUE_LINE_ITEM = 'LINEITEMVALUE/GET_HIGHEST_VALUE_LINE_ITEM';
export const GET_HIGHEST_VALUE_LINE_ITEM_SUCCESS = 'LINEITEMVALUE/GET_HIGHEST_VALUE_LINE_ITEM_SUCCESS';
export const GET_PERIOD_LINE_ITEM_THRESHOLD = 'LINEITEMVALUE/GET_PERIOD_LINE_ITEM_THRESHOLD';
export const GET_PERIOD_LINE_ITEM_THRESHOLD_SUCCESS = 'LINEITEMVALUE/GET_PERIOD_LINE_ITEM_THRESHOLD_SUCCESS';
export const SET_LINE_ITEM_THRESHOLD = 'LINEITEMVALUE/SET_LINE_ITEM_THRESHOLD';
export const GET_GRAPH_DATA = 'LINEITEMVALUE/GET_GRAPH_DATA';
export const GET_GRAPH_DATA_SUCCESS = 'LINEITEMVALUE/GET_GRAPH_DATA_SUCCESS';
export const GET_CATEGORISATION_LOGIC = 'LINEITEMVALUE/GET_CATEGORISATION_LOGIC';
export const GET_CATEGORISATION_LOGIC_SUCCESS = 'LINEITEMVALUE/GET_CATEGORISATION_LOGIC_SUCCESS';

export function getHighestValueLineItem(periodId) {
  return {
    type: GET_HIGHEST_VALUE_LINE_ITEM,
    loading: true,
    value: null,
    payload: {
      periodId: periodId,
    },
  };
}

export function getHighestValueLineItemSuccess(value) {
  return {
    type: GET_HIGHEST_VALUE_LINE_ITEM_SUCCESS,
    value: value,
    loading: false,
  };
}

export function getPeriodLineItemThreshold(periodId) {
  return {
    type: GET_PERIOD_LINE_ITEM_THRESHOLD,
    loading: true,
    lineItemValueThreshold: null,
    payload: {
      periodId: periodId,
    },
  };
}

export function getPeriodLineItemThresholdSuccess(lineItemValueThreshold) {
  return {
    type: GET_PERIOD_LINE_ITEM_THRESHOLD_SUCCESS,
    lineItemValueThreshold: lineItemValueThreshold,
    loading: false,
  };
}

export function setLineItemThreshold(threshold) {
  return {
    type: SET_LINE_ITEM_THRESHOLD,
    lineItemValueThreshold: threshold,
  };
}

export function getGraphData(periodId, threshold) {
  return {
    type: GET_GRAPH_DATA,
    loading: true,
    data: [],
    payload: {
      periodId: periodId,
      lineItemValueThreshold: threshold,
    },
  };
}

export function getGraphDataSuccess(data) {
  return {
    type: GET_GRAPH_DATA_SUCCESS,
    data: data,
    loading: false,
  };
}

export function getCategorisationLogic(periodId, selectedRowIds) {
  return {
    type: GET_CATEGORISATION_LOGIC,
    loading: true,
    payload: {
      periodId: periodId,
      lineItemIds: selectedRowIds,
    },
  };
}

export function getCategorisationLogicSuccess(data) {
  return {
    type: GET_CATEGORISATION_LOGIC_SUCCESS,
    categorisationLogic: data,
    loading: false,
  };
}
