export const strings = {
  importSuccessful: 'Import successful',
  importFailed: 'Import unsuccessful',
  numOfLineItemsSuc: 'Number of line items successfully imported: ',
  numOfLineItemsFail: 'Number of line items failed to import: ',
  numOfLineItemsIssues: 'Number of line items with issues during import: ',
  note: 'The line items that failed to import contain errors. These will be excluded ' +
    'from the data set and will not be available for categorisation.' +
    'They can be reviewed via the reporting tab. Click ‘Proceed anyway’ to begin categorisation ' +
    'or ‘Start again’ to return to the upload screen.',
  failedNote: 'Please review the errors below and make the required changes in your ' +
    'upload documents. You can click ‘Start again’ to return to the upload screen.',
  tooManyErrorsNote: 'The documents being processed have generated a large amount of errors. ' +
    'Please review your document(s) and ensure they are formed correctly before ' +
    're-uploading and trying again.',
  tooManyClausesNote: 'The uploaded data/template has resulted in too many clauses being generated. ' +
     'To upload your data you can try removing tagged data, reference lists from your template or ' +
     'rules relating to the rule: ',
  startAgain: 'Start again',
  proceedAnyway: 'Proceed anyway',
  confirmation: 'I confirm I am happy to proceed and exclude these rows from the dataset.',
};

export const successfulTableColumns = ['File name', 'Row', 'Tag', 'Invalid value'];
export const unsuccessfulTableColumns = ['File name', 'Row', 'Tag', 'Value', 'Expected data type'];
