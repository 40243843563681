export const styles = theme => ({
  contraLineItem: {
    margin: 0,
  },
  contraLineItemWithBorder: {
    marginTop: -1,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    borderTop: `1px solid ${theme.palette.primary.background}`,
  },
  positive: {
    color: theme.palette.primary.valid,
  },
  negative: {
    color: theme.palette.primary.error,
  },
});

