export const styles = theme => ({
  titleBar: {
    height: 30,
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 20,
  },
  ruleTitle: {
    flexGrow: 1,
    lineHeight: '30px',
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.small,
  },
  rule: {
    fontSize: theme.constants.fontSizes.small,
    paddingTop: 10,
    paddingBottom: 15,
    overflow: 'auto',
    height: 50,
  },
  error: {
    fontSize: theme.constants.fontSizes.small,
    color: theme.palette.primary.error,
    height: 20,
    lineHeight: '20px',
  },
});
