import { routes } from 'Constants/Routes';
// import { getSubRoutes } from 'Helpers/RouteHelpers';
import setup from 'Assets/Images/setup.svg';
import categorisation from 'Assets/Images/categorisation.svg';
import reviewIcon from 'Assets/Images/review.svg';
import importIcon from 'Assets/Images/import.svg';
import exportIcon from 'Assets/Images/export.svg';
import modelsIcon from 'Assets/Images/model-management-ic.svg';
import settingsIcon from 'Assets/Images/settings-cog.svg';
import { periodStatuses } from './PeriodStatuses';

export const navLinks = [
  {
    name: 'Setup',
    links: [routes.setup],
    img: setup,
    admin: true,
    user: false,
    systemAdmin: false,
  },
  {
    name: 'Import',
    links: [
      routes.processing,
      routes.supportingDocuments,
      routes.contrasSetup,
      routes.contrasReview,
      routes.importSummary,
      routes.periodImportDetails,
      routes.machineLearningSetup,
      routes.machineLearning,
      routes.import.upload,
      routes.import.mappingMain,
      routes.import.mappingSupporting,
      routes.import.review,
    ],
    primaryLink: ({ periodStatus }) =>
      (periodStatus === periodStatuses.notStarted
        ? routes.import.upload
        : routes.periodImportDetails),
    img: importIcon,
    admin: false,
    user: true,
    systemAdmin: false,
  },
  {
    name: 'Categorisation',
    links: [
      routes.categoryReview,
      routes.conflicts,
      routes.categorisation.referenceLists,
      routes.categorisation.singleKeywords,
      routes.categorisation.combinationKeywords,
      routes.categorisation.customKeywords,
      routes.categorisation.lineItems,
      routes.categorisation.conflictingLineItems,
      routes.categorisation.flaggedLineItems,
    ],
    primaryLink: routes.categorisation.referenceLists,
    img: categorisation,
    admin: false,
    user: true,
    systemAdmin: false,
  },
  {
    name: 'Review',
    links: [
      routes.review,
      routes.lineItemsCategorisationReview.ml,
      routes.lineItemsCategorisationReview.manual,
      routes.lineItemsCategorisationReview.conflicts,
      routes.lineItemsCategorisationReview.contra,
      routes.lineItemsCategorisationReview.rules,
      routes.lineItemsCategorisationReview.lineItems,
    ],
    img: reviewIcon,
    admin: false,
    user: true,
    systemAdmin: false,
  },
  {
    name: 'Upload',
    links: [
      routes.templates,
      routes.templateProcessing,
    ],
    img: importIcon,
    admin: true,
    user: false,
    systemAdmin: false,
  },
  {
    name: 'Bulk Upload',
    links: [
      routes.bulkUpload,
      routes.bulkUserUploadProcessing,
      routes.bulkGroupEntityProjectUploadProcessing,
    ],
    img: importIcon,
    admin: true,
    user: false,
    systemAdmin: false,
  },
  {
    name: 'Export',
    links: [
      routes.export,
      routes.pivotTable,
      routes.externalReview,
    ],
    img: exportIcon,
    admin: false,
    user: true,
    systemAdmin: false,
  },
  {
    name: 'Models',
    links: [
      routes.models,
      routes.createModel,
    ],
    img: modelsIcon,
    admin: true,
    user: false,
    systemAdmin: true,
  },
  {
    name: 'Settings',
    links: [
      routes.settings,
    ],
    img: settingsIcon,
    admin: true,
    user: false,
    systemAdmin: true,
  },
];
