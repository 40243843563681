import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Drawer, withTheme, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import NavigationBreadcrumb from 'Components/Shared/Breadcrumbs/NavigationBreadcrumb/NavigationBreadcrumb';
import ToastNotification from 'Components/Shared/ToastNotification/ToastNotification';
import SiteLoader from 'Components/Shared/SiteLoader/SiteLoader';
import LockedPeriodBanner from 'Components/Shared/LockedPeriodBanner/LockedPeriodBanner';
import PeriodControlTakenOverlay from 'Components/Projects/PeriodControlTakenOverlay';
import {
  periodLockedByUserIdSelector,
  periodLockedByUserSelector,
  periodIsExternalReviewLockedSelector,
  periodInUseSelector,
  periodIsLockedSelector,
  periodNameSelector,
  periodControlNotificationSelector,
  periodIdSelector,
} from 'Store/Areas/Period/PeriodSelectors';
import {
  externalReviewFileSelector,
  externalReviewCloudRefSelector,
} from 'Store/Areas/Export/ExportSelectors';
import { shouldRenderBannerSelector } from 'Store/Areas/Projects/UserTreePickerSelectors';
import { setPeriodInUse } from 'Store/Areas/Period/PeriodActions';
import { toggleCanRenderReadOnlyBanner } from 'Store/Areas/Projects/UserTreePickerActions';
import { periodControlStatuses, periodControlStatusText } from 'Constants/PeriodControlStatuses';
import Menu from './Menu/Menu';
import LayoutActions from './LayoutActions/LayoutActions';
import styles from './Layout.styles';

class Layout extends React.PureComponent {
  state = {
    showInUse: false,
    showIsLocked: false,
    controlTakenModalOpen: false,
  };

  componentDidUpdate(prevProps) {
    const {
      notification,
      periodName,
      periodLockedByUserId,
    } = this.props;

    if (notification && prevProps.notification !== notification
      &&
      (periodControlStatusText(notification.periodControlStatus)
      === periodControlStatuses.requested)) {
      this.showControlTakenOverlay();
    }

    if (prevProps.periodName !== periodName
      || prevProps.periodLockedByUserId !== periodLockedByUserId) {
      this.toggleInUseBanner();
    } else if (notification && prevProps.notification !== notification
      &&
      (periodControlStatusText(notification.periodControlStatus)
      === periodControlStatuses.approved)) {
      this.toggleInUseBanner();
    }
  }

  onControlTakenConfirm = () => {
    const { periodId, notification } = this.props;
    this.setState({
      controlTakenModalOpen: false,
    });
    this.props.dispatch(setPeriodInUse(
      periodId, periodControlStatuses.approved,
      notification.requestUserId,
    ));
  }

   onCancel = () => {
     const { periodId, notification } = this.props;
     this.setState({
       controlTakenModalOpen: false,
     });
     this.props.dispatch(setPeriodInUse(
       periodId, periodControlStatuses.denied,
       notification.requestUserId,
     ));
   }

  showControlTakenOverlay = () => {
    this.setState({
      controlTakenModalOpen: true,
    });
  }

  toggleInUseBanner() {
    const {
      dispatch,
      periodLockedByUserId,
      permissions,
      periodInUse,
      periodIsLocked,
      periodIsExternalReviewLocked,
    } = this.props;

    if (periodLockedByUserId && periodIsLocked) {
      this.setState({
        showInUse: false,
        showIsLocked: true,
      });
      dispatch(toggleCanRenderReadOnlyBanner(true));
    } else if (periodLockedByUserId !== permissions.id && periodInUse) {
      this.setState({
        showInUse: true,
        showIsLocked: false,
      });
      dispatch(toggleCanRenderReadOnlyBanner(true));
    } else if (periodIsExternalReviewLocked) {
      this.setState({
        showInUse: false,
        showIsLocked: true,
      });
      dispatch(toggleCanRenderReadOnlyBanner(true));
    } else {
      this.setState({
        showInUse: false,
        showIsLocked: false,
      });
      dispatch(toggleCanRenderReadOnlyBanner(false));
    }
  }

  renderBanner() {
    const {
      classes,
      periodIsExternalReviewLocked,
      externalReviewFilename,
      externalReviewCloudRef,
      periodLockedByUser,
    } = this.props;
    const { showInUse, showIsLocked } = this.state;

    return (
      <div className={classes.banner}>
        <LockedPeriodBanner
          inUse={showInUse}
          isLocked={showIsLocked || periodIsExternalReviewLocked}
          externalReviewLocked={periodIsExternalReviewLocked}
          shouldHide={externalReviewFilename === '' || externalReviewCloudRef === ''}
          userName={periodLockedByUser ? periodLockedByUser.emailAddress : ''}
        />
      </div>
    );
  }

  render() {
    const { controlTakenModalOpen } = this.state;
    const {
      classes, notification, shouldRenderBanner,
    } = this.props;
    return (
      <Fragment>
        <ToastNotification />
        <div className={shouldRenderBanner ? classes.rootWithBanner : classes.root}>
          {shouldRenderBanner && this.renderBanner()}
          <Drawer
            variant="permanent"
            anchor="left"
            classes={
              { paper: `${classes.drawerPaper} ${shouldRenderBanner ? classes.bannerShift : ''}` }}
          >
            <Menu className={shouldRenderBanner ? classes.bannerShift : ''} />
          </Drawer>
          <main className={classes.content}>
            <NavigationBreadcrumb
              isCollapsible
              collapsed
            />
            {this.props.children}
          </main>
          <SiteLoader />
          <LayoutActions />
        </div>
        <PeriodControlTakenOverlay
          onConfirm={this.onControlTakenConfirm}
          onCancel={this.onCancel}
          userName={notification.userName}
          isModalOpen={controlTakenModalOpen}
        />
      </Fragment>
    );
  }
}

Layout.defaultProps = {
  periodLockedByUserId: -1,
  periodLockedByUser: {},
  periodName: '',
  externalReviewFilename: '',
  externalReviewCloudRef: '',
  periodId: 0,
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodLockedByUserId: PropTypes.number,
  periodLockedByUser: PropTypes.shape({
    emailAddress: PropTypes.string,
  }),
  periodInUse: PropTypes.bool.isRequired,
  periodIsLocked: PropTypes.bool.isRequired,
  periodName: PropTypes.string,
  periodId: PropTypes.number,
  permissions: PropTypes.shape({
    isGroupAdmin: PropTypes.bool,
    groupAdmins: PropTypes.arrayOf(PropTypes.number).isRequired,
    id: PropTypes.number,
  }).isRequired,
  notification: PropTypes.shape({
    periodName: PropTypes.string,
    userName: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  shouldRenderBanner: PropTypes.bool.isRequired,
  periodIsExternalReviewLocked: PropTypes.bool.isRequired,
  externalReviewFilename: PropTypes.string,
  externalReviewCloudRef: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    periodLockedByUserId: periodLockedByUserIdSelector(state),
    periodLockedByUser: periodLockedByUserSelector(state),
    periodInUse: periodInUseSelector(state),
    periodIsLocked: periodIsLockedSelector(state),
    periodIsExternalReviewLocked: periodIsExternalReviewLockedSelector(state),
    periodName: periodNameSelector(state),
    permissions: state.user.permissions.data,
    notification: periodControlNotificationSelector(state),
    shouldRenderBanner: shouldRenderBannerSelector(state),
    externalReviewFilename: externalReviewFileSelector(state),
    externalReviewCloudRef: externalReviewCloudRefSelector(state),
    periodId: periodIdSelector(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withTheme(),
  withStyles(styles),
)(Layout);
