import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'connected-react-router';
import { withStyles, withTheme, Paper, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { routes } from 'Constants/Routes';
import Breadcrumb from 'Components/Shared/Breadcrumbs/ChevronBreadcrumb/Breadcrumb';
import { strings } from 'Constants/Export/Strings';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import styles from './ExternalReviewScreen.styles';

class ExternalReviewScreen extends React.PureComponent {
  render() {
    const {
      classes,
      dispatch,
      title,
      buttonText,
      children,
      onClick,
      disabled,
      description,
    } = this.props;

    return (
      <div className={classes.root}>
        <Breadcrumb
          items={[
            strings.exportReports,
            strings.exportUncategorisedData,
          ]}
          activeIndex={1}
          className={classes.breadcrumb}
          selectableIndexes={[0]}
          onClicks={
            [
              () => dispatch(push(routes.export)),
              null,
            ]
          }
          isSelectable
          withTails={false}
        />
        <Paper className={classes.paperContainer}>
          <Grid container direction="row" alignItems="stretch">
            <Grid container justify="flex-end">
              <Grid item xs={12} >
                <div className={classes.note}>
                  {title}
                </div>
              </Grid>
              <Grid item xs={9} >
                <div className={classes.description}>
                  {description}
                </div>
              </Grid>
              <Grid item xs={3} >
                <Button
                  className={disabled ? classes.buttonDisabled : classes.proceedButton}
                  onClick={onClick}
                  backgroundColor={constants.backgroundColor.main}
                  height={constants.height.big}
                  disabled={disabled}
                >
                  {buttonText}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="stretch">
            <Grid container justify="flex-end">
              <Grid item xs={12} >
                {children}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

ExternalReviewScreen.defaultProps = {
  children: '',
  disabled: false,
  description: '',
};

ExternalReviewScreen.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  description: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default compose(
  connect(),
  withStyles(styles),
  withTheme(),
)(ExternalReviewScreen);
