import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';

export const INSPECT_RULE = 'INSPECT_RULE/INSPECT_RULE';
export const CLOSE_INSPECT_RULE = 'INSPECT_RULE/CLOSE_INSPECT_RULE';
export const GET_RULE_LINE_ITEMS = 'INSPECT_RULE/GET_RULE_LINE_ITEMS';
export const GET_RULE_LINE_ITEMS_SUCCESS = 'INSPECT_RULE/GET_RULE_LINE_ITEMS_SUCCESS';
export const RESET_RULE_LINE_ITEMS = 'INSPECT_RULE/RESET_RULE_LINE_ITEMS';

export function inspectRule(rule) {
  return {
    type: INSPECT_RULE,
    payload: {
      rule: rule,
    },
  };
}

export function closeInspectRule() {
  return {
    type: CLOSE_INSPECT_RULE,
  };
}

export function getRuleLineItems(periodId, ruleId, scrollId, sortOrder, sortField, sortTagId) {
  return {
    type: GET_RULE_LINE_ITEMS,
    loading: true,
    hasMore: false,
    payload: {
      periodId: periodId,
      ruleId: ruleId,
      categorizationType: categorizationTypes.rule,
      scrollId,
      sortOrder: sortOrder,
      sortField: sortField,
      sortTagId: sortTagId,
    },
  };
}

export function resetRuleLineItems() {
  return {
    type: RESET_RULE_LINE_ITEMS,
  };
}

export function getRuleLineItemsSuccess(data) {
  return {
    type: GET_RULE_LINE_ITEMS_SUCCESS,
    loading: false,
    ruleLineItems: data.rows,
    ruleLineItemsColumns: data.columns,
    ruleLineItemsColumnHeaders: data.columnHeaders,
    ruleLineItemsColumnIds: data.columnTagIds,
    hasMore: data.hasMore,
    scrollId: data.scrollId,
  };
}
