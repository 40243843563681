export const REQUEST_BULK_GROUP_ENTITY_PROJECT_UPLOAD = 'BULK_UPLOAD/REQUEST_BULK_GROUP_ENTITY_PROJECT_UPLOAD';
export const REQUEST_BULK_GROUP_ENTITY_PROJECT_UPLOAD_SUCCESS = 'BULK_UPLOAD/REQUEST_BULK_GROUP_ENTITY_PROJECT_UPLOAD_SUCCESS';
export const SET_BULK_GROUP_ENTITY_PROJECT_UPLOAD_STAGE = 'BULK_UPLOAD/SET_BULK_GROUP_ENTITY_PROJECT_UPLOAD_STAGE';
export const RESET_BULK_GROUP_ENTITY_PROJECT_UPLOAD_STATE = 'BULK_UPLOAD/RESET_BULK_GROUP_ENTITY_PROJECT_UPLOAD_STATE';

export function bulkGroupEntityProjectUpload(file) {
  return {
    type: REQUEST_BULK_GROUP_ENTITY_PROJECT_UPLOAD,
    file: file,
  };
}

export function bulkGroupEntityProjectUploadSuccess(response) {
  return {
    type: REQUEST_BULK_GROUP_ENTITY_PROJECT_UPLOAD_SUCCESS,
    loading: false,
    userId: response.userId,
  };
}

export function setBulkGroupEntityProjectUploadStage(payload) {
  return {
    type: SET_BULK_GROUP_ENTITY_PROJECT_UPLOAD_STAGE,
    stage: payload.stage,
    importMessages: payload.importMessages,
  };
}

export function resetBulkGroupEntityProjectUploadState() {
  return {
    type: RESET_BULK_GROUP_ENTITY_PROJECT_UPLOAD_STATE,
  };
}