import React from 'react';
import {
  withStyles,
  withTheme,
} from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import FlatTailSelected from 'Assets/Images/breadcrumb-tail-flat-selected.svg';
import TailSelected from 'Assets/Images/breadcrumb-tail-selected.svg';
import HeadSelected from 'Assets/Images/breadcrumb-head-selected.svg';
import FlatTailUnselected from 'Assets/Images/breadcrumb-tail-flat-unselected.svg';
import TailUnselected from 'Assets/Images/breadcrumb-tail-unselected.svg';
import HeadUnselected from 'Assets/Images/breadcrumb-head-unselected.svg';
import { styles } from './BreadcrumbItem.styles';

class ChevronBreadcrumb extends React.PureComponent {
  render() {
    const {
      classes,
      text,
      active,
      firstItem,
      selectable,
      onClick,
      withTails,
    } = this.props;
    return (
      <div
        className={`${classes.root} ${selectable ? classes.cursor : ''}`}
        onClick={onClick}
        role="button"
        tabIndex="0"
        onKeyPress={onClick}
      >
        {withTails &&
        <Choose>
          <When condition={firstItem}>
            <Choose>
              <When condition={active && selectable}>
                <img src={FlatTailUnselected} alt="" />
              </When>
              <When condition={!active && selectable}>
                <img src={FlatTailSelected} alt="" />
              </When>
              <When condition={active && !selectable}>
                <img src={FlatTailUnselected} alt="" />
              </When>
              <Otherwise condition={!active && !selectable}>
                <img src={FlatTailSelected} alt="" />
              </Otherwise>
            </Choose>
          </When>
          <When condition={!firstItem}>
            <Choose>
              <When condition={active && selectable}>
                <img src={TailUnselected} alt="" />
              </When>
              <When condition={!active && selectable}>
                <img src={TailSelected} alt="" />
              </When>
              <When condition={active && !selectable}>
                <img src={TailUnselected} alt="" />
              </When>
              <Otherwise condition={!active && !selectable}>
                <img src={TailSelected} alt="" />
              </Otherwise>
            </Choose>
          </When>
        </Choose>
          }
        <div className={`
          ${classes.middle}
          ${active && classes.active}
          ${selectable ? classes.selectable : classes.unselectable}
          ${!withTails && classes.spaceRight}`}
        >
          {text}
        </div>
        {withTails &&
        <div className={classes.image}>
          <Choose>
            <When condition={active && selectable}>
              <img src={HeadUnselected} alt="" />
            </When>
            <When condition={!active && selectable}>
              <img src={HeadSelected} alt="" />
            </When>
            <When condition={active && !selectable}>
              <img src={HeadUnselected} alt="" />
            </When>
            <Otherwise condition={!active && !selectable}>
              <img src={HeadSelected} alt="" />
            </Otherwise>
          </Choose>
        </div>
        }
      </div>
    );
  }
}

ChevronBreadcrumb.defaultProps = {
  onClick: () => {},
  active: false,
  firstItem: false,
  selectable: false,
  withTails: true,
  text: '',
};

ChevronBreadcrumb.propTypes = {
  active: PropTypes.bool,
  firstItem: PropTypes.bool,
  selectable: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
  withTails: PropTypes.bool,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(ChevronBreadcrumb);
