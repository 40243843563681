export const REQUEST_EXPORT = 'EXPORT/REQUEST_EXPORT';
export const SET_EXPORT_LOCKED = 'EXPORT/SET_EXPORT_LOCKED';
export const GET_EXPORT_FILES = 'EXPORT/GET_EXPORT_FILES';
export const GET_EXPORT_FILES_SUCCESS = 'EXPORT/GET_EXPORT_FILES_SUCCESS';
export const REQUEST_DOWNLOAD_EXPORT_FILE = 'EXPORT/REQUEST_DOWNLOAD_EXPORT_FILE';
export const SET_CUSTOM_REPORT_FILE_NAME = 'EXPORT/SET_CUSTOM_REPORT_FILE_NAME';
export const RESET_CUSTOM_REPORT_FILES = 'EXPORT/RESET_CUSTOM_REPORT_FILES';
export const DELETE_EXPORT_FILE = 'EXPORT/DELETE_EXPORT_FILE';
export const CLEAR_EXPORT_FILES = 'EXPORT/CLEAR_EXPORT_FILES';
export const DELETE_EXPORT_FILE_SUCCESS = 'EXPORT/DELETE_EXPORT_FILE_SUCCESS';
export const SET_CUSTOM_REPORT_LOADING = 'EXPORT/SET_CUSTOM_REPORT_LOADING';

export function requestExport(periodId, exportTypes, customReportCloudRef, pivotTableData) {
  return {
    type: REQUEST_EXPORT,
    payload: {
      periodId: periodId,
      exportTypes: exportTypes,
      customReportCloudReference: customReportCloudRef,
      pivotTableData: pivotTableData,
    },
  };
}

export function setExportLocked(isLocked) {
  return {
    type: SET_EXPORT_LOCKED,
    isLocked,
  };
}

export function getExportFiles(periodId) {
  return {
    type: GET_EXPORT_FILES,
    loading: true,
    files: [],
    payload: {
      periodId: periodId,
      isExternalReview: false,
    },
  };
}

export function getExportFilesSuccess(files) {
  return {
    type: GET_EXPORT_FILES_SUCCESS,
    loading: false,
    files: files,
  };
}

export function setCustomReportFileName(customReportCloudRef, customReportOriginalFileName) {
  return {
    type: SET_CUSTOM_REPORT_FILE_NAME,
    customReportCloudRef: customReportCloudRef,
    customReportOriginalFileName: customReportOriginalFileName,
    fileLoading: false,
  };
}
export function resetCustomReportFiles() {
  return {
    type: RESET_CUSTOM_REPORT_FILES,
  };
}

export function downloadExportFile(periodId, exportFileId) {
  return {
    type: REQUEST_DOWNLOAD_EXPORT_FILE,
    payload: {
      periodId: periodId,
      exportFileId: exportFileId,
    },
  };
}
export function deleteExportFile(periodId, exportFileId) {
  return {
    type: DELETE_EXPORT_FILE,
    payload: {
      periodId: periodId,
      exportFileId: exportFileId,
    },
    loading: true,
  };
}
export function clearExportFile(periodId) {
  return {
    type: CLEAR_EXPORT_FILES,
    payload: {
      periodId: periodId,
    },
  };
}
export function deleteExportFileSuccess() {
  return {
    type: DELETE_EXPORT_FILE_SUCCESS,
    loading: false,
  };
}

export function setCustomReportLoading(fileLoading) {
  return {
    type: SET_CUSTOM_REPORT_LOADING,
    fileLoading: fileLoading,
  };
}
