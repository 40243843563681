import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import {
  PERIOD_CHECK_CATEGORY_CAN_BE_DELETED,
  PERIOD_POST_DELETE_CATEGORY,
} from 'Store/Api/ApiEndpoints';
import { Get, Post } from 'Store/Api/CallApi';
import {
  CHECK_CATEGORY_CAN_BE_DELETED,
  checkCategoryCanBeDeletedSuccess,
  POST_DELETE_CATEGORY,
  resetDeletingCategory,
} from 'Store/Areas/Categorisation/DeleteCategoryActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { cancelAdding } from 'Store/Areas/Categorisation/CategoryActions';
import { closeEditingRule } from 'Store/Areas/Rule/EditRuleActions';
import { closeInspectRule } from 'Store/Areas/Rule/InspectRuleActions';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';

/* Worker Sagas */
function* checkCategoryCanBeDeletedSaga(action) {
  const response = yield Get(PERIOD_CHECK_CATEGORY_CAN_BE_DELETED(
    action.payload.categoryId,
    action.payload.periodId,
  ));
  if (response.ok) {
    const responseBody = yield response.json();
    yield put(checkCategoryCanBeDeletedSuccess(responseBody));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* postDeleteCategorySaga(action) {
  yield put(setProcessing(true));
  const response = yield Post(PERIOD_POST_DELETE_CATEGORY, action.payload);
  if (response.ok) {
    const deleteResult = yield response.json();

    yield put(cancelAdding());
    yield put(resetDeletingCategory());

    if (deleteResult.success) {
      yield put(closeEditingRule());
      yield put(closeInspectRule());
    } else {
      yield put(displayToastNotification(deleteResult.error));
      yield put(setProcessing(false));
    }
  } else {
    yield handleStatusCode(response.status);
    yield put(setProcessing(false));
  }
}

/* Watcher Sagas */
export function* deleteCategorySagas() {
  yield takeLatest(CHECK_CATEGORY_CAN_BE_DELETED, checkCategoryCanBeDeletedSaga);
  yield takeLatest(POST_DELETE_CATEGORY, postDeleteCategorySaga);
}
