export const styles = theme => ({
  tableCell: {
    padding: '0 50px 0 0',
    '&:first-child': {
      width: '60%',
    },
    '&:last-child': {
      width: '10%',
    },
    overflow: 'hidden',
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'normal',
  },
  headerText: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  paperContainer: {
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    paddingTop: 30,
  },
  failedRulesListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 56px)',
  },
  title: {
    fontSize: theme.constants.fontSizes.regular,
    marginBottom: 5,
    paddingTop: 20,
  },
  tick: {
    paddingRight: 5,
  },
  cross: {
    paddingRight: 5,
  },
  flexContainer: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
  },
  proceedButton: {
    width: 100,
    float: 'right',
  },
});