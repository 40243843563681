const styles = theme => ({
  root: {
    padding: 0,
  },
  content: {
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
    width: 475,
  },
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  input: {
    marginBottom: 10,
  },
});

export default styles;
