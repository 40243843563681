import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'Components/Shared/Modal/ErrorModal';
import { strings } from 'Constants/Templates/Strings';
import { routes } from 'Constants/Routes';
import { push } from 'connected-react-router';
import { resetUploadTemplateState } from 'Store/Areas/Template/UploadTemplateActions';

class FailedScanModal extends React.PureComponent {
  onClose = () => {
    const { dispatch } = this.props;
    dispatch(push(routes.templates));
    dispatch(resetUploadTemplateState());
  }

  render() {
    return (
      <Modal
        title={strings.failedScanTitle}
        error={strings.failedScanDescription}
        onClose={this.onClose}
        open
      />
    );
  }
}

FailedScanModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(FailedScanModal);
