export const styles = theme => ({
  label: {
    opacity: '0.6',
    color: theme.palette.colors.white,
    fontSize: theme.constants.fontSizes.small,
  },
  input: {
    color: 'white',
    fontSize: theme.constants.fontSizes.regular,
  },
  icon: {
    opacity: '0.6',
    position: 'absolute',
    right: 10,
    bottom: 20,
  },
  noPadding: {
    padding: 0,
  },
  menu: {
    opacity: '0.6',
    padding: 0,
    height: 50,
    borderBottomColor: theme.palette.colors.white,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,
  },
  menuItem: {
    opacity: '0.6',
    fontSize: theme.constants.fontSizes.regular,
    height: '45px',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.colors.white,
    color: theme.palette.colors.black,
  },
});

