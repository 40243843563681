import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme, Paper, Grid, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { strings, rowHeight } from 'Constants/Contras/Strings';
import { title } from 'Constants/App/Titles/Strings';
import DataGrid from 'Components/Shared/DataGrid/DataGrid';
import SimpleHeader from 'Components/Shared/DataGrid/Cells/SimpleHeader/SimpleHeader';
import ContraRow from 'Components/Shared/DataGrid/Rows/ContraRow';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import DragnDrop from 'Constants/DragnDrop';
import ArrowButton from 'Components/Shared/Buttons/ArrowButton/ArrowButton';
import CloseIcon from 'Assets/Images/exit-ic-black.svg';
import { goBack } from 'connected-react-router';
import { getReviewContras, removeContraAndRerunCategorisation, resetReviewContras } from 'Store/Areas/Import/ContraActions';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import { periodIdSelector, isReadOnlySelector } from 'Store/Areas/Period/PeriodSelectors';
import ContraGridCell from 'Components/Contras/ContraGridCell';
import ContraFiltersPanel from 'Components/Contras/ContraFiltersPanel';
import { styles } from './ContrasReview.styles';

class ContrasReview extends React.PureComponent {
  state = {
    confirmationOpen: false,
    removingContraId: null,
  }

  componentDidMount() {
    document.title = title.contraReview;
    this.props.dispatch(getTreePickerData());
    this.props.dispatch(resetReviewContras());
    this.loadItems();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.submitting && prevProps.submitting) {
      this.updateStateSubmitting();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetReviewContras());
  }

  onBackClicked = () => {
    this.props.dispatch(goBack());
  }

  getCellValue = ({ rowIndex, columnIndex }) => {
    const { contraGroups, columns } = this.props;
    return contraGroups[rowIndex].documents.map(x => x[columns[columnIndex]]);
  }

  getWidth = ({ index }) => {
    const { amountColumnIndex, descriptionColumnIndex } = this.props;
    switch (index) {
      case amountColumnIndex:
        return 150;
      case descriptionColumnIndex:
        return 300;
      default:
        return 200;
    }
  }

  updateStateSubmitting() {
    this.setState({
      confirmationOpen: false,
      removingContraId: null,
    });
  }

  loadItems = () => {
    const { periodId, scrollId, dispatch } = this.props;
    dispatch(getReviewContras(
      periodId,
      scrollId,
      1,
      '',
    ));
  }

  calculatedRowHeight = (index) => {
    const { contraGroups } = this.props;
    const calculatedRowHeight = contraGroups[index].documents.length * rowHeight;
    return calculatedRowHeight + 10;
  }

  removeContraItem = () => {
    const { periodId, dispatch } = this.props;
    const { removingContraId } = this.state;
    dispatch(removeContraAndRerunCategorisation(periodId, removingContraId));
  }

  selectRemoveContra = contraId => () => {
    this.setState({
      removingContraId: contraId,
      confirmationOpen: true,
    });
  }

  cancelRemoveContra = () => {
    this.setState({
      removingContraId: null,
      confirmationOpen: false,
    });
  }

  render() {
    const {
      classes,
      periodId,
      contraFilters,
      columnHeaders,
      contraGroups,
      amountColumnIndex,
      hasMore,
      loading,
      theme,
      submitting,
      currencySymbol,
      isReadOnly,
    } = this.props;

    const {
      confirmationOpen,
    } = this.state;

    return (
      <div className={classes.contraListWrapper}>
        <Choose>
          <When condition={hasMore || contraGroups.length > 0}>
            <Paper className={classes.proceedContainer}>
              <Grid container direction="row" spacing={8} alignItems="center">
                <Grid item xs={12}>
                  <ArrowButton onClickEvent={this.onBackClicked} className={classes.marginBottom} />
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.description}>
                    {strings.reviewContrasDescription}
                  </div>
                </Grid>
              </Grid>
              <ContraFiltersPanel
                contraFilters={contraFilters}
                periodId={periodId}
              />
            </Paper>
            <div className={classes.contraList}>
              <DataGrid
                recomputeGridSizeKey={`${contraGroups.length}`}
                className={classes.grid}
                columns={columnHeaders}
                rows={contraGroups}
                getCellValue={this.getCellValue}
                hasNextPage={hasMore}
                isNextPageLoading={loading}
                loadNextPage={this.loadItems}
                showLoadingOverlay
                getWidth={this.getWidth}
                childRowHeight={rowHeight}
                calculatedRowHeight={this.calculatedRowHeight}
                stickyColumnWidth={40}
                stickyBackgroundColor={theme.palette.primary.background}
                renderHeaderCell={props => (
                  <SimpleHeader
                    {...props}
                    className={classes.headerCell}
                  />
                    )}
                renderRow={props => (
                  <ContraRow
                    {...props}
                    dragProps={{
                          index: props.index,
                          lineItemIds: [contraGroups[props.index].id],
                        }}
                    dragType={DragnDrop.lineItem.uncategorised}
                  />
                    )}
                renderCell={props => (
                  <ContraGridCell
                    {...props}
                    currencySymbol={currencySymbol}
                    padLeft={props.index === 0}
                    padRight={props.index === columnHeaders.length - 1}
                    padding={10}
                    formatAsCurrency={props.index === amountColumnIndex}
                    childHeight={rowHeight}
                  />
                    )}
                renderStickyCell={props => (
                  <div {...props}>
                    <If condition={!isReadOnly}>
                      <IconButton
                        onClick={this.selectRemoveContra(contraGroups[props.index].contraId)}
                      >
                        <img alt="" src={CloseIcon} style={{ cursor: 'pointer' }} />
                      </IconButton>
                    </If>
                  </div>
                    )}
              />
            </div>
            <ConfirmationModal
              open={confirmationOpen}
              onConfirm={this.removeContraItem}
              onCancel={this.cancelRemoveContra}
              isSubmitting={submitting}
              acceptText={strings.removeContra}
              title={strings.removeContra}
              message={strings.removeContraMessage}
            />
          </When>
          <Otherwise>
            <Paper className={classes.noDataContainer}>
              <Grid item xs={12}>
                <ArrowButton onClickEvent={this.onBackClicked} className={classes.marginBottom} />
              </Grid>
              <div className={`${classes.description} ${classes.marginTop}`}>
                {strings.noContrasReview}
              </div>
            </Paper>
          </Otherwise>
        </Choose>
      </div>
    );
  }
}

ContrasReview.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  scrollId: PropTypes.string.isRequired,
  hasMore: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  columnHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  contraGroups: PropTypes.arrayOf(PropTypes.shape({
    contraId: PropTypes.string,
    isFullyPopulated: PropTypes.bool,
    groupCount: PropTypes.number,
    documents: PropTypes.arrayOf(PropTypes.object),
  })).isRequired,
  amountColumnIndex: PropTypes.number.isRequired,
  descriptionColumnIndex: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        background: PropTypes.string,
      }),
    }),
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
  contraFilters: PropTypes.shape({
    sortOrder: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired,
  }).isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    periodId: periodIdSelector(state),
    loading: state.import.contras.loading,
    hasMore: state.import.contras.hasMore,
    contraGroups: state.import.contras.contraGroups,
    columns: state.import.contras.columns,
    columnHeaders: state.import.contras.columnHeaders,
    scrollId: state.import.contras.scrollId,
    amountColumnIndex: state.import.contras.amountColumnIndex,
    descriptionColumnIndex: state.import.contras.descriptionColumnIndex,
    submitting: state.import.contras.submitting,
    contraFilters: state.import.contras.filters,
    currencySymbol: currencySymbolSelector(state),
    isReadOnly: isReadOnlySelector(state),
  };
}

export default compose(connect(mapStateToProps), withStyles(styles), withTheme())(ContrasReview);
