import {
  SELECT_NODE,
  RESET_GROUP,
  RESET_ENTITY,
  RESET_PROJECT,
  SELECT_PERIOD,
  TOGGLE_SIDEBAR,
  TOGGLE_HAS_BANNER,
  RESET_TO_INITIAL_STATE,
} from './UserTreePickerActions';

const initialState = {
  collapsed: false,
  canRenderReadOnlyBanner: false,
  selectedGroupId: -1,
  selectedEntityId: -1,
  selectedProjectId: -1,
  selectedItemLevel: -1,
  selectedPeriodId: -1,
  selectedIndex: -1,
};

export default function userTreePickerReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_NODE:
    case RESET_GROUP:
    case RESET_ENTITY:
    case RESET_PROJECT:
      return Object.assign({}, state, {
        selectedGroupId: action.selectedGroupId,
        selectedEntityId: action.selectedEntityId,
        selectedProjectId: action.selectedProjectId,
        selectedItemLevel: action.selectedItemLevel,
      });
    case SELECT_PERIOD:
      return Object.assign({}, state, {
        selectedPeriodId: action.selectedPeriodId,
        collapsed: action.collapsed,
        selectedIndex: action.selectedIndex,
      });
    case TOGGLE_SIDEBAR:
      return Object.assign({}, state, {
        collapsed: action.collapsed,
      });
    case TOGGLE_HAS_BANNER:
      return Object.assign({}, state, {
        canRenderReadOnlyBanner: action.canRenderReadOnlyBanner,
      });
    case RESET_TO_INITIAL_STATE:
      return initialState;
    default:
      return state;
  }
}
