export const styles = theme => ({
  root: {
    padding: 20,
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '40vh',
    overflowY: 'auto',
  },
  paperContainer: {
    padding: '20px 20px 30px 20px',
    margin: '0 20px 0 20px',
  },
  paperContainerFilter: {
    padding: '20px 20px 30px 20px',
    margin: '15px 20px 0 20px',
  },
  note: {
    marginTop: 10,
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  description: {
    fontSize: theme.constants.fontSizes.regular,
    lineHeight: 1.5,
    marginRight: 10,
  },
  breadcrumb: {
    marginLeft: 20,
    marginBottom: 20,
  },
  title: {
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.small,
    flexGrow: 1,
    height: 40,
    lineHeight: '40px',
    paddingLeft: 10,
    whitespace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  generateButton: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.constants.fontSizes.regular,
    cursor: 'pointer',
    fontWeight: 'bold',
    border: 'none',
    width: '100%',
  },
  generateButtonDisabled: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    border: 'none',
    width: '100%',
    opacity: 0.6,
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  resetFilterButton: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.constants.fontSizes.regular,
    cursor: 'pointer',
    fontWeight: 'bold',
    border: 'none',
    width: '100%',
  },
  button: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    fontSize: theme.constants.fontSizes.small,
  },
  buttonDisabled: {
    color: theme.palette.primary.main,
    opacity: 0.6,
    '&:hover': {
      cursor: 'not-allowed',
    },
    fontSize: theme.constants.fontSizes.small,
  },
  buttonBase: {
    height: 30,
    textAlign: 'left',
    outline: 'none',
    width: 'fit-content',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
  },
  headerText: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  tableCell: {
    padding: '0 50px 0 0',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:first-child': {
      width: '100%',
    },
    '&:last-child': {
      width: '100%',
    },
    overflow: 'hidden',
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'normal',
  },
  cellWidth: {
    minWidth: 150,
    maxWidth: 150,
  },
  searchBox: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.colors.black,
  },
  progressBar: {
    margin: '30px auto 0 auto',
    width: '65%',
    border: '4px solid white',
    marginBottom: 40,
  },
  linkText: {
    fontWeight: 'bold',
    fontSize: 12,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
});