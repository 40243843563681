export const unmatchedSupportingDocsHeaders = [
  {
    name: 'unmatchedValue',
    displayName: 'Unmatched Value',
  },
  {
    name: 'supportingDataValue',
    displayName: 'Supporting data value',
  },
];
