export const STOP_PERIOD_STATUS_POLLING = 'PERIOD_STATUS_POLLING/STOP_PERIOD_STATUS_POLLING';
export const START_PERIOD_STATUS_POLLING = 'PERIOD_STATUS_POLLING/START_PERIOD_STATUS_POLLING';

export function stopPolling() {
  return {
    type: STOP_PERIOD_STATUS_POLLING,
  };
}

export function startPolling(periodId, maintainRoute = false) {
  return {
    type: START_PERIOD_STATUS_POLLING,
    periodId: periodId,
    maintainRoute: maintainRoute,
  };
}
