import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import {
  PROJECTS_GET_TEMPLATES_URL,
  ADMIN_GET_TEMPLATES,
  ADMIN_DELETE_TEMPLATES,
  ADMIN_DOWNLOAD_TEMPLATE_URL,
} from 'Store/Api/ApiEndpoints';
import { Get, Post, SimpleGet } from 'Store/Api/CallApi';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import {
  GET_TEMPLATES_DATA,
  getTemplatesDataSuccess,
  GET_TEMPLATES_FOR_CURRENT_USER,
  getTemplatesForCurrentUserSuccess,
  DELETE_TEMPLATE,
  deleteTemplateSuccess,
  getTemplatesForCurrentUser,
  DOWNLOAD_TEMPLATE,
} from 'Store/Areas/Projects/TemplatesActions';
import { commonStrings } from 'Constants/CommonStrings';

/* Worker Sagas */
function* getTemplateData(action) {
  if (action.payload.taxContextId && action.payload.groupId) {
    const { taxContextId, groupId } = action.payload;
    const response = yield Get(PROJECTS_GET_TEMPLATES_URL(taxContextId, groupId));
    if (response.ok) {
      const data = yield response.json();
      yield put(getTemplatesDataSuccess(data));
    } else {
      yield handleStatusCode(response.status);
    }
  } else {
    yield put(getTemplatesDataSuccess([]));
  }
}

function* getTemplatesForCurrent() {
  const response = yield Get(ADMIN_GET_TEMPLATES);
  if (response.ok) {
    const data = yield response.json();
    yield put(getTemplatesForCurrentUserSuccess(data.templates));
  } else {
    yield handleStatusCode(response.status);
  }
}
function* deleteSelectedTemplate({ templateId }) {
  const response = yield Post(ADMIN_DELETE_TEMPLATES, { templateId });
  if (response.ok) {
    const data = yield response.json();
    yield put(deleteTemplateSuccess(data));
    yield put(getTemplatesForCurrentUser());
  } else {
    yield handleStatusCode(response.status);
  }
}

function* downloadSelectedTemplate(action) {
  const response = yield Get(ADMIN_DOWNLOAD_TEMPLATE_URL(action.payload.templateId));
  if (response.ok) {
    let { result } = yield response.json();

    result = result && result.trim();

    if (!result) {
      yield put(displayToastNotification(commonStrings.linkNotAvailable));
      return;
    }

    const presignedUrlResponse = yield SimpleGet(result);

    if (presignedUrlResponse.ok) {
      window.location.href = result;
    } else {
      yield handleStatusCode(presignedUrlResponse.status);
    }
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* templateSagas() {
  yield takeLatest(GET_TEMPLATES_DATA, getTemplateData);
  yield takeLatest(GET_TEMPLATES_FOR_CURRENT_USER, getTemplatesForCurrent);
  yield takeLatest(DELETE_TEMPLATE, deleteSelectedTemplate);
  yield takeLatest(DOWNLOAD_TEMPLATE, downloadSelectedTemplate);
}
