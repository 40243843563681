const main = 'KPMG TDAE -';

export const title = {
  welcome: 'KPMG TDAE',
  uploadPage: `${main} Upload`,
  categoryReview: category => `${main} ${category} Category`,
  mapping: `${main} Mapping`,
  processing: `${main} Processing`,
  conflicts: `${main} Conflicts`,
  contras: `${main} Contras`,
  review: `${main} Review`,
  contraReview: `${main} Contra Review`,
  admin: `${main} Admin`,
  customRules: `${main} Custom Rules`,
  referenceLists: `${main} Reference Lists`,
  keywords: `${main} Keywords`,
  export: `${main} Export`,
  externalReview: `${main} External Review`,
  pivotTable: `${main} Pivot Table Builder`,
  machineLearning: `${main} Machine Learning`,
  lineItems: categoryType => `${main}${categoryType === 6 ? ' Conflicting ' : ''} Line Items`,
  periodImportDetails: `${main} Import Summary`,
  failedRulesReview: `${main} Failed Rules Review`,
};
