import { levels } from 'Constants/EntityLevels';

export const UPDATE_TREEPICKER = 'USER_TREEPICKER/USER_UPDATE_TREEPICKER';
export const SELECT_NODE = 'USER_TREEPICKER/USER_SELECT_NODE';
export const SELECT_PERIOD = 'USER_TREEPICKER/USER_SELECT_PERIOD';
export const RESET_TO_INITIAL_STATE = 'USER_TREEPICKER/USER_RESET_TO_INITIAL_STATE';
export const RESET_GROUP = 'USER_TREEPICKER/USER_RESET_GROUP';
export const RESET_ENTITY = 'USER_TREEPICKER/USER_RESET_ENTITY';
export const RESET_PROJECT = 'USER_TREEPICKER/USER_RESET_PROJECT';
export const TOGGLE_SIDEBAR = 'USER_TREEPICKER/USER_TOGGLE_SIDEBAR';
export const TOGGLE_HAS_BANNER = 'USER_TREEPICKER/TOGGLE_HAS_BANNER';

export function updateTreePickerSelection(groupId, entityId, projectId, itemLevel, userTreePicker) {
  return {
    type: UPDATE_TREEPICKER,
    selectedGroupId: groupId,
    selectedEntityId: entityId,
    selectedProjectId: projectId,
    selectedItemLevel: itemLevel,
    userTreePicker: userTreePicker,
  };
}

export function selectPeriod(periodId, selectedIndex) {
  return {
    type: SELECT_PERIOD,
    selectedPeriodId: periodId,
    collapsed: true,
    selectedIndex: selectedIndex,
  };
}

export function resetToInitialState() {
  return {
    type: RESET_TO_INITIAL_STATE,
  };
}

export function selectNode(groupId, entityId, projectId, itemLevel) {
  return {
    type: SELECT_NODE,
    selectedGroupId: groupId,
    selectedEntityId: entityId,
    selectedProjectId: projectId,
    selectedItemLevel: itemLevel,
  };
}

export function resetGroup() {
  return {
    type: RESET_GROUP,
    selectedGroupId: -1,
    selectedEntityId: -1,
    selectedProjectId: -1,
    selectedItemLevel: levels.group,
  };
}

export function resetEntity(groupId) {
  return {
    type: RESET_ENTITY,
    selectedGroupId: groupId,
    selectedEntityId: -1,
    selectedProjectId: -1,
    selectedItemLevel: levels.entity,
  };
}

export function resetProject(groupId, entityId) {
  return {
    type: RESET_PROJECT,
    selectedGroupId: groupId,
    selectedEntityId: entityId,
    selectedProjectId: -1,
    selectedItemLevel: levels.project,
  };
}

export function toggleSidebar(collapsed) {
  return {
    type: TOGGLE_SIDEBAR,
    collapsed: collapsed,
  };
}

export function toggleCanRenderReadOnlyBanner(canRenderReadOnlyBanner) {
  return {
    type: TOGGLE_HAS_BANNER,
    canRenderReadOnlyBanner,
  };
}
