import { takeLatest, put } from 'redux-saga/effects';
import {
  GET_MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS,
  getMatchedSupportingAndMainDocDetailsSuccess,
  PROCESS_MATCHED_SUPPORTING_AND_MAIN_DOCS,
} from 'Store/Areas/Import/SupportingDocumentMappingActions';
import { Post } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import {
  UPDATE_NEW_SUPPORTING_DOCS_URL,
  MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS_URL,
  PROCESS_MATCHED_SUPPORTING_AND_MAIN_DOCS_URL,
} from 'Store/Api/ApiEndpoints';
import { importStages } from 'Constants/Processing/ImportStages';
import { reportProcessingProgress } from 'Store/Areas/Import/ProcessActions';
import { routes } from 'Constants/Routes';
import { push } from 'connected-react-router';

function* getMatchedSupportingAndMainDocDetailsWorker(action) {
  const response = yield Post(MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS_URL, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getMatchedSupportingAndMainDocDetailsSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* processMatchedSupportingAndMainDocsWorker(action) {
  const response = yield Post(action.payload.changeSupportingDocsAfterPeriodCreation
    ? UPDATE_NEW_SUPPORTING_DOCS_URL :
    PROCESS_MATCHED_SUPPORTING_AND_MAIN_DOCS_URL, action.payload);
  if (response.ok) {
    yield put(reportProcessingProgress('', importStages.applyingSupportingDocuments));
    yield put(push(routes.processing));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* supportingDocumentsWatcher() {
  yield takeLatest(
    GET_MATCHED_SUPPORTING_AND_MAIN_DOC_DETAILS,
    getMatchedSupportingAndMainDocDetailsWorker,
  );
  yield takeLatest(
    PROCESS_MATCHED_SUPPORTING_AND_MAIN_DOCS,
    processMatchedSupportingAndMainDocsWorker,
  );
}
