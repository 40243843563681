import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import { strings } from 'Constants/Projects/CreatePeriod/Strings';
import ErrorModal from 'Components/Shared/Modal/ErrorModal';

class PeriodControlOverlay extends React.PureComponent {
  render() {
    const {
      onConfirm, onCancel, isModalOpen, isErrorModalOpen, onClose, userName,
    } = this.props;

    return (
      <Choose>
        <When condition={isModalOpen}>
          <ConfirmationModal
            open={isModalOpen}
            title={strings.controlPeriod}
            message={strings.controlPeriodNote}
            acceptText={strings.requestControlButtonText}
            onConfirm={() => onConfirm()}
            onCancel={() => onCancel()}
          />
        </When>
        <When condition={isErrorModalOpen}>
          <ErrorModal
            open
            onClose={() => onClose()}
            error={strings.periodControlRequestFailureMessage(userName)}
            title={strings.controlPeriod}
          />
        </When>
      </Choose>
    );
  }
}

PeriodControlOverlay.defaultProps = {
  userName: '',
};

PeriodControlOverlay.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isErrorModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userName: PropTypes.string,
};

export default (PeriodControlOverlay);