export const GET_PREVIEW_ROWS = 'PREVIEW/GET_PREVIEW_ROWS';
export const GET_PREVIEW_ROWS_SUCCESS = 'PREVIEW/GET_PREVIEW_ROWS_SUCCESS';

export function getPreviewRows(periodId, fileType) {
  return {
    type: GET_PREVIEW_ROWS,
    periodId: periodId,
    fileType: fileType,
    loading: true,
    rows: [],
  };
}

export function getPreviewRowsSuccess(data) {
  return {
    type: GET_PREVIEW_ROWS_SUCCESS,
    loading: false,
    rows: data.rows,
    expectedDataTypes: data.expectedDataTypes,
  };
}
