import React from 'react';
import {
  withStyles,
  withTheme,
  Button,
  DialogActions,
} from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styles from './StyledModal.Buttons.styles';

class Buttons extends React.PureComponent {
  render() {
    const {
      className,
      buttonClassName,
      cancelText,
      acceptText,
      classes,
      onCancel,
      onAccept,
      isSubmitting,
      submitDisabled,
    } = this.props;
    return (
      <DialogActions className={`${classes.actions} ${className}`}>
        <Button
          disabled={isSubmitting}
          disableRipple
          className={`${classes.cancelButton} ${classes.button} ${buttonClassName}`}
          onClick={onCancel}
          color="primary"
        >
          {cancelText}
        </Button>
        <Button
          disabled={isSubmitting || submitDisabled}
          disableRipple
          className={`${classes.acceptButton} ${classes.button} ${buttonClassName}`}
          onClick={onAccept}
          color="primary"
          type="submit"
        >
          {acceptText}
        </Button>
      </DialogActions>
    );
  }
}

Buttons.defaultProps = {
  className: '',
  buttonClassName: '',
  cancelText: 'Cancel',
  acceptText: 'Accept',
  onAccept: () => { },
  isSubmitting: false,
  submitDisabled: false,
};

Buttons.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  cancelText: PropTypes.string,
  acceptText: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func, // optional as button has type submit
  isSubmitting: PropTypes.bool,
  submitDisabled: PropTypes.bool,
};

export default compose(withStyles(styles), withTheme())(Buttons);
