import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, withTheme, IconButton } from '@material-ui/core';
import { compose } from 'recompose';
import Close from 'Assets/Images/exit-ic-light-blue.svg';
import { strings } from 'Constants/Upload/TagMapping';
import { mapTag } from 'Store/Areas/Import/TagMappingActions';
import { supporting, main } from 'Constants/FileTypes';
import { tagTypeLabel } from 'Constants/Categorization/Tags';
import { DataTypes } from 'Constants/Upload/DataTypes';
import styles from './PreviewTableHeader.styles';
import TagDropzone from './TagDropzone';

class PreviewTableHeader extends React.PureComponent {
  mapTag(tagId, tagName) {
    const {
      dispatch, header, periodId, fileType,
    } = this.props;

    dispatch(mapTag(tagId, header.id, periodId, header.name, tagName, fileType));
  }

  render() {
    const {
      classes,
      header,
      tag,
      expectedDataType,
    } = this.props;
    return (
      <div>
        <div>
          <b>{header.name}</b>
        </div>
        <If condition={tag !== null}>
          <div className={`${classes.headerTagDrop} ${classes.headerTagDropWithTag}`}>
            <span>{tag.name}</span>
            <IconButton
              disableRipple
              className={classes.closeButton}
              color="inherit"
              onClick={() => this.mapTag(null)}
              aria-label="Remove_map"
            >
              <img alt="Close" src={Close} className={classes.closeIcon} />
            </IconButton>
          </div>
        </If>
        <If condition={tag === null}>
          <TagDropzone
            onDrop={(tagId, tagName) => this.mapTag(tagId, tagName)}
            expectedDataType={expectedDataType}
          />
        </If>
        <div className={classes.headerTagType}>
          <If condition={tag !== null}>
            <span>{tagTypeLabel(tag.type)}</span>
          </If>
          <If condition={tag === null}>
            <span>{strings.value}</span>
          </If>
        </div>
      </div>
    );
  }
}

PreviewTableHeader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
  }),
  header: PropTypes.shape({
    name: PropTypes.string,
    tagId: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
  periodId: PropTypes.number.isRequired,
  expectedDataType: PropTypes.arrayOf(PropTypes.oneOf(Object.values(DataTypes))),
  fileType: PropTypes.oneOf([supporting, main]).isRequired,
};

PreviewTableHeader.defaultProps = {
  tag: null,
  expectedDataType: [DataTypes.Text],
};

function mapStateToProps(state) {
  const { period } = state.periods;
  return {
    periodId: period.periodId,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(PreviewTableHeader);
