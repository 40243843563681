export const strings = {
  sidebarTitle: 'Complete the form below',
  viewTemplateTitle: 'Key facts about the selected template',
  viewPeriodTitle: 'Key facts about the selected period',
  necessaryTags: 'Necessary tags (document content):',
  unnecessaryTags: 'Optional tags:',
  categories: 'Categories:',
  referenceLists: 'Reference lists:',
  emptyTemplate: 'You are creating a new template',
  lastUsed: 'Last used on',
  createPeriod: 'Create new period',
  proceedAnyway: 'Proceed Anyway',
  editPeriod: 'Save Changes',
  deletePeriod: 'Delete period',
  deletePeriodNote: 'Warning! Deleting this period will permanently delete all data in this Period.',
  templateLabel: 'Template',
  periodLabel: 'Period',
  createFrom: 'Create from',
  originalTemplate: 'Original Template',
  archivePeriodTitle: period => `Archive period ‘${period}’?`,
  archivePeriod: 'Archive period',
  archivePeriodNote: 'You are about to archive this period. Once archived you will only be able to access the Export screen. Do you wish to continue?',
  archive: 'Archive',
  lockPeriod: 'Lock period',
  unlockPeriod: 'Unlock period',
  groupOptionPlaceholder: 'Select a group...',
  entityOptionPlaceholder: 'Select an entity...',
  projectOptionPlaceholder: 'Select a project...',
  templateOptionPlaceholder: 'Select a template...',
  periodOptionPlaceholder: 'Select a period...',
  periodNamePlaceholder: 'Enter your period name...',
  archivePeriodButtonText: 'Archiving a period will remove it from the list of available periods. Transactional data will be removed from the database, but the Categorised Data report will still be available on the export page. Further amendments cannot be made once a period is Archived. This action cannot be undone.',
  lockPeriodButtonText: 'Locking a period will enable read-only mode. This should be done once returns have been filed with the appropriate authorities. Admins can unlock periods if required.',
  unlockPeriodButtonText: 'Unlocking a period will disable read-only mode and allow users to make further changes.',
  deletePeriodButtonText: 'Deleting the period will remove the period and all of its associated data. This action cannot be undone.',
  templateViewerRules: rulesCount => `rule${rulesCount !== 1 ? 's' : ''}`,
  templateViewerFailedRules: failedRulesCount => `failed rule${failedRulesCount !== 1 ? 's' : ''}`,
  templateViewerCategories: categoriesCount => `categor${categoriesCount !== 1 ? 'ies' : 'y'}`,
  templateViewerReferenceLists: listsCount => `reference list${listsCount !== 1 ? 's' : ''}`,
  periodViewerTags: tagsCount => `tag${tagsCount !== 1 ? 's' : ''}`,
  templateViewerContext: 'Context: ',
  toggleLockPeriodNotificationText: (periodName, isLocking) => `Period '${periodName}' has been ${isLocking ? 'locked' : 'unlocked'}`,
  periodStatusChangeNotificationText: periodName => `Status has been changed for Period '${periodName}'`,
  externalReviewInProgress: 'External Review in progress',
  externalReviewInProgressMessage: 'An External Review process is in progress, therefore this period cannot be unlocked yet.',
  controlPeriod: 'Request control',
  controlPeriodNote: 'Are you sure you want to request control. This will remove access for the current user.',
  periodControlTaken: 'Control taken',
  periodControlRequest: 'Control Request',
  periodControlTakenMessage: userName => `User '${userName}' has taken control of the period. You will now only be able to view this period in read-only mode.`,
  periodControlRequestMessage: userName => `User '${userName}' has requested control of the period. Would you like to release control?`,
  periodControlTakenNotificationText: (userName, periodName) => `${userName} has been assigned ${periodName}.`,
  requestControlButtonText: 'Request control',
  periodControlRequestFailureMessage: userName => `User '${userName}' has refused you control of this period.`,
  clearPeriodData: 'Clear Period Data',
  clearPeriodButtonText: 'This action cannot be undone.',
  clearPeriodNote: 'Warning! Clearing this period will permanently delete all data in this Period.',
  clearData: 'Clear Data',
  failedRule: 'Failed template rules',
};
