export const periodStatuses = {
  notStarted: 'NotStarted',
  mapping: 'Mapping',
  importQueued: 'ImportQueued',
  importRunning: 'ImportRunning',
  importSummary: 'ImportSummary',
  importFailed: 'ImportFailed',
  supportingDocumentsSetup: 'SupportingDocumentsSetup',
  supportingDocumentsQueued: 'SupportingDocumentsQueued',
  supportingDocumentsRunning: 'SupportingDocumentsRunning',
  supportingDocumentsFailed: 'SupportingDocumentsFailed',
  contrasSetup: 'ContrasSetup',
  contrasQueued: 'ContrasQueued',
  contrasRunning: 'ContrasRunning',
  contrasFailed: 'ContrasFailed',
  contrasComplete: 'ContrasComplete',
  keywordExtractionQueued: 'KeywordExtractionQueued',
  keywordExtractionRunning: 'KeywordExtractionRunning',
  keywordExtractionFailed: 'KeywordExtractionFailed',
  initialCategorizationRunning: 'InitialCategorizationRunning',
  initialCategorizationFailed: 'InitialCategorizationFailed',
  initialCategorizationComplete: 'InitialCategorizationComplete',
  machineLearningPredictionsQueued: 'MachineLearningPredictionsQueued',
  machineLearningPredictionsRunning: 'MachineLearningPredictionsRunning',
  machineLearningPredictionsFailed: 'MachineLearningPredictionsFailed',
  machineLearningPredictionsComplete: 'MachineLearningPredictionsComplete',
  applyMachineLearningRunning: 'ApplyMachineLearningRunning',
  applyMachineLearningFailed: 'ApplyMachineLearningFailed',
  applyMachineLearningQueued: 'ApplyMachineLearningQueued',
  readyForUserCategorisation: 'ReadyForUserCategorisation',
  userCategorizationRunning: 'UserCategorizationRunning',
  userCategorizationFailed: 'UserCategorizationFailed',
  userCategorizationQueued: 'UserCategorizationQueued',
  periodCategorisationComplete: 'PeriodCategorisationComplete',
  periodArchived: 'PeriodArchived',
  exportRunning: 'ExportRunning',
  exportQueued: 'ExportQueued',
  archiveRunning: 'ArchiveRunning',
  archiveQueued: 'ArchiveQueued',
  periodReferenceListImportQueued: 'PeriodReferenceListImportQueued',
  periodReferenceListImportRunning: 'PeriodReferenceListImportRunning',
  periodReferenceListImportFailed: 'PeriodReferenceListImportFailed',
  externalReviewImportQueued: 'ExternalReviewImportQueued',
  externalReviewImportRunning: 'ExternalReviewImportRunning',
  externalReviewImportFailed: 'ExternalReviewImportFailed',
  supportingDocDeleteQueued: 'SupportingDocDeleteQueued',
  supportingDocDeleteRunning: 'SupportingDocDeleteRunning',
  supportingDocDeleteFailed: 'SupportingDocDeleteFailed',
  importFailedRules: 'ImportFailedRules',
  apportionmentCalculationQueued: 'ApportionmentCalculationQueued',
  apportionmentCalculationRunning: 'ApportionmentCalculationRunning',
  apportionmentCalculationFailed: 'ApportionmentCalculationFailed',
  apportionmentCalculationCompleted: 'ApportionmentCalculationCompleted',
};

export function getPeriodStatusDescription({ status, isLocked, inUse }) {
  if (isLocked) {
    return 'Locked';
  }

  if (inUse) {
    return 'In Use';
  }

  if (isLocked && inUse) {
    return 'Locked - In Use';
  }

  if (status === periodStatuses.periodArchived) {
    return 'Archived';
  }

  return null;
}
