export const ADD_SIGNALR_SIDE_EFFECT = 'APP/ADD_SIGNALR_SIDE_EFFECT';
export const RESET_SIGNALR_SIDE_EFFECTS = 'APP/RESET_SIGNALR_SIDE_EFFECTS';

export function addSignalrSideEffect(selectedPeriodId, periodStatusTrigger, payload) {
  return {
    type: ADD_SIGNALR_SIDE_EFFECT,
    selectedPeriodId,
    periodStatusTrigger,
    payload: payload,
  };
}
export function resetSignalrSideEffects() {
  return {
    type: RESET_SIGNALR_SIDE_EFFECTS,
  };
}
