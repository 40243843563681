export const OPEN_DELETE_RULE = 'DELETERULE/OPEN_DELETING_RULE';
export const CLOSE_DELETE_RULE = 'DELETERULE/CLOSE_DELETE_RULE';
export const REQUEST_DELETE_RULE = 'DELETERULE/REQUEST_DELETE_RULE';
export const REQUEST_DELETE_RULE_SUCCESS = 'DELETERULE/REQUEST_DELETE_RULE_SUCCESS';

export function openDeletingRule() {
  return {
    type: OPEN_DELETE_RULE,
    open: true,
  };
}

export function requestDeleteRules(periodId, ruleIds, ruleTexts) {
  return {
    type: REQUEST_DELETE_RULE,
    loading: true,
    payload: {
      periodId,
      ruleIds,
      ruleTexts,
    },
  };
}

export function requestDeleteRulesSuccess() {
  return {
    type: REQUEST_DELETE_RULE_SUCCESS,
  };
}

export function closeDeleteRule() {
  return {
    type: CLOSE_DELETE_RULE,
  };
}
