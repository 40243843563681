import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import { styles } from './SortableHeader.styles';
import SortIcon from './SortIcon';

class SortableHeader extends React.Component {
  render() {
    const {
      classes,
      children,
      index,
      onClick,
      isSorting,
      sortOrder,
      style,
      className,
      padLeft,
      padRight,
      padding,
      width,
    } = this.props;
    return (
      <span
        className={`${classes.root} ${className}`}
        onClick={() => onClick(index)}
        onKeyPress={() => onClick(index)}
        role="button"
        tabIndex="-1"
        style={{
          ...style,
          width: width - (padLeft ? padding : 0) - (padRight ? padding : 0),
          paddingLeft: padLeft ? padding : 0,
          paddingRight: padRight ? padding : 0,
        }}
      >
        {children}
        <SortIcon isSorting={isSorting} sortOrder={sortOrder} />
      </span>
    );
  }
}

SortableHeader.defaultProps = {
  className: '',
  padding: 10,
  padLeft: false,
  padRight: false,
  style: {},
  children: '',
};

SortableHeader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.string,
  isSorting: PropTypes.bool.isRequired,
  sortOrder: PropTypes.number.isRequired,
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  padding: PropTypes.number,
  padLeft: PropTypes.bool,
  padRight: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(SortableHeader);
