import React from 'react';
import ErrorPage from 'Components/ErrorPages/ErrorPage';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, Button, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import { strings } from 'Constants/Processing/Strings';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { updatePeriodStatus } from 'Store/Areas/Period/PeriodActions';
import { routes } from 'Constants/Routes';
import {
  externalReviewFileSelector,
  externalReviewCloudRefSelector,
} from 'Store/Areas/Export/ExportSelectors';
import { beginDeleteFile } from 'Store/Areas/Import/UploadActions';
import { push } from 'connected-react-router';
import { styles } from './ExternalReviewImportErrorPage.styles';

class ExternalReviewImportErrorPage extends React.PureComponent {
  cancelImport = () => {
    const {
      periodId,
      dispatch,
      externalReviewFilename,
      externalReviewCloudRef,
    } = this.props;
    dispatch(beginDeleteFile(
      externalReviewCloudRef,
      externalReviewFilename,
      periodId,
      false,
      true,
    ));
    dispatch(push(routes.externalReview));
  }

  retryImport = () => {
    const {
      periodId,
      dispatch,
    } = this.props;
    dispatch(updatePeriodStatus(periodId, periodStatuses.periodCategorisationComplete));
  }

  render() {
    const { classes } = this.props;
    return (
      <ErrorPage
        cancelImport={this.cancelImport}
        userActions={
          <Button
            disableRipple
            className={`${classes.acceptButton} ${classes.button}`}
            onClick={this.retryImport}
            color="primary"
            type="submit"
          >
            {strings.startAgain}
          </Button>
        }
      />
    );
  }
}

ExternalReviewImportErrorPage.defaultProps = {
  externalReviewFilename: '',
  externalReviewCloudRef: '',
};

ExternalReviewImportErrorPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  externalReviewFilename: PropTypes.string,
  externalReviewCloudRef: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    periodId: state.periods.period.periodId,
    externalReviewFilename: externalReviewFileSelector(state),
    externalReviewCloudRef: externalReviewCloudRefSelector(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTheme(),
)(ExternalReviewImportErrorPage);