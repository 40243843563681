import React from 'react';
import { withStyles } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Check from 'Assets/Images/small-check-ic-white.svg';
import Circle from 'Assets/Images/oval-white.svg';
import { styles } from './RadioButton.styles';

class RadioButton extends React.PureComponent {
  render() {
    const {
      active,
      classes,
      onClick,
      label,
      className,
    } = this.props;

    return (
      <div className={`${className} ${classes.root}`}>
        <div className={classes.radioButtonWrapper}>
          {label && (
            <div className={classes.label}>{label}</div>
          )}
          <div
            className={classes.radioButton}
            onClick={onClick}
            role="button"
            tabIndex={0}
            onKeyDown={onClick}
          >
            <If condition={active}>
              <img alt="checked" src={Check} />
            </If>
            <If condition={!active}>
              <img alt="unchecked" src={Circle} />
            </If>
          </div>
        </div>
      </div>
    );
  }
}

RadioButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
};

RadioButton.defaultProps = {
  className: '',
};

export default compose(withStyles(styles))(RadioButton);
