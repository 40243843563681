import React from 'react';
import {
  Paper,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import Sidebar from 'Components/UserSidebar/Sidebar';
import Button, { constants as buttonConstants } from 'Components/Shared/Buttons/Button';
import Select, { constants as constantsSelect } from 'Components/Shared/Selects/Select';
import { strings } from 'Constants/SupportingDocuments/Strings';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import { selectMappedTagsFromPeriod } from 'Constants/Categorization/Tags';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import UploadBreadcrumb, { constants } from 'Components/Shared/UploadBreadcrumb/UploadBreadcrumb';
import ArrowButton, {
  constants as arrowButtonConstants,
} from 'Components/Shared/Buttons/ArrowButton/ArrowButton';
import SimpleButton from 'Components/Shared/Buttons/SimpleButton/SimpleButton';
import { main, supporting } from 'Constants/FileTypes';
import { commonStrings } from 'Constants/CommonStrings';
import {
  getSupportingDocumentMappingFiles,
  getSupportingDocumentMappedLineItemIds,
  isSupportingDocumentMappingLoadingSelector,
  getMatchedSupportingAndMainDocsDataSelector,
} from 'Store/Areas/Import/ImportSelectors';
import {
  initialiseSupportingDocumentMappingState,
  resetSupportingDocumentMappingState,
  mapItemToFile,
  unmapAllFileItems,
  getMatchedSupportingAndMainDocDetails,
  processMatchedSupportingAndMainDocs,
} from 'Store/Areas/Import/SupportingDocumentMappingActions';
import {
  getAllFilesForTypeSelector,
  periodIdSelector,
  changeSupportingDocsAfterPeriodCreationSelector,
  getAllSupportingFilesCount,
} from 'Store/Areas/Period/PeriodSelectors';
import { styles } from './SupportingDocumentsMapper.styles';
import SupportingDocumentsDropzone from './SupportingDocumentsDropzone';
import SupportingDocumentsLineItems from './SupportingDocumentsLineItems';
import MatchedSupportingAndMainDocDetails from './MatchedSupportingAndMainDocDetails';

class SupportingDocumentsMapper extends React.Component {
  state = {
    selectedSupportingDocTag: 0,
    selectedMainDocTag: 0,
    displayManuallyMapLineItemsScreen: false,
    displayMatchedSupportingAndMainDocDetails: false,
    displayBackOfManuallyMapLineItemsScreen: true,
  };

  componentDidMount() {
    const { dispatch, supportingFiles } = this.props;
    dispatch(initialiseSupportingDocumentMappingState({ files: supportingFiles }));
    this.props.dispatch(getTreePickerData());
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetSupportingDocumentMappingState());
  }

  onBackToAutomatedMapLineItemsScreenClick = () => {
    const { supportingFiles, dispatch } = this.props;
    supportingFiles.forEach((file) => {
      dispatch(unmapAllFileItems({ fileId: file.id }));
    });

    this.setState({ displayManuallyMapLineItemsScreen: false });
  };

  onBackOfMatchedSupportingAndMainDocDetailsClick = () => {
    const { supportingFiles, dispatch } = this.props;
    if (supportingFiles.length === 1) {
      this.setState({
        displayMatchedSupportingAndMainDocDetails: false,
        displayManuallyMapLineItemsScreen: false,
        displayBackOfManuallyMapLineItemsScreen: true,
      });
    } else {
      this.setState({
        displayMatchedSupportingAndMainDocDetails: false,
        displayManuallyMapLineItemsScreen: true,
        displayBackOfManuallyMapLineItemsScreen: false,
      });
    }
    supportingFiles.forEach((file) => {
      dispatch(unmapAllFileItems({ fileId: file.id }));
    });
  };

  onProceedOfMatchedSupportingAndMainDocDetailsClick = () => {
    const {
      dispatch,
      periodId,
      matchedSupportingAndMainDocsData,
      changeSupportingDocsAfterPeriodCreation,
    } = this.props;
    dispatch(processMatchedSupportingAndMainDocs(
      periodId,
      matchedSupportingAndMainDocsData.map(x => ({ id: x.id, isProRate: x.isProRate })),
      changeSupportingDocsAfterPeriodCreation,
    ));
  };

  onProceedClick = () => {
    const {
      supportingFilesMappings, dispatch, periodId,
    } = this.props;
      // Add new page for Replacing balancing modal
    dispatch(getMatchedSupportingAndMainDocDetails({
      supportingFiles: supportingFilesMappings,
      periodId: periodId,
      mainDocTagId: 0,
      supportingDocTagId: 0,
      supportingDocFileId: 0,
    }));
    this.setState({
      displayMatchedSupportingAndMainDocDetails: true,
      displayManuallyMapLineItemsScreen: false,
    });
  };

  setSupportingDocTag = (value) => {
    if (this.state.selectedSupportingDocTag !== value) {
      this.setState({
        selectedSupportingDocTag: value,
      });
    }
  };

  setMainDocTag = (value) => {
    if (this.state.selectedMainDocTag !== value) {
      this.setState({
        selectedMainDocTag: value,
      });
    }
  };

  setSupportingDocTag = (value) => {
    if (this.state.selectedSupportingDocTag !== value) {
      this.setState({
        selectedSupportingDocTag: value,
      });
    }
  };

  setMainDocTag = (value) => {
    if (this.state.selectedMainDocTag !== value) {
      this.setState({
        selectedMainDocTag: value,
      });
    }
  };

  getTags = (fileType) => {
    const { headers, tags } = this.props;
    const docHeaders = headers
      .filter(x => x.fileType === fileType && x.name !== commonStrings.amount);
    const docTags = [];
    tags.forEach((tag) => {
      if (docHeaders.filter(x => x.tagId === tag.id).length > 0) {
        docTags.push({ id: tag.id, name: tag.name });
      }
    });
    return docTags;
  }

  isAutomatedMapLineItemsButtonDisabled = () => {
    const { selectedMainDocTag, selectedSupportingDocTag } = this.state;
    const { tags } = this.props;

    if (selectedMainDocTag === 0 || selectedSupportingDocTag === 0) {
      return true;
    }
    const selectedSupportingDocTagType = tags.find(x => x.id === selectedSupportingDocTag).type;
    const selectedMainDocTagType = tags.find(x => x.id === selectedMainDocTag).type;

    if (selectedSupportingDocTagType !== selectedMainDocTagType) {
      return true;
    }
    return false;
  };

  automatedMapLineItemsButtonClick = () => {
    const {
      dispatch, periodId, supportingFiles,
    } = this.props;
    const {
      selectedMainDocTag, selectedSupportingDocTag,
    } = this.state;

    // Add new page for Replacing balancing modal
    dispatch(getMatchedSupportingAndMainDocDetails({
      supportingFiles: [],
      periodId: periodId,
      mainDocTagId: selectedMainDocTag,
      supportingDocTagId: selectedSupportingDocTag,
      supportingDocFileId: supportingFiles[0].id,
    }));
    this.setState({
      displayMatchedSupportingAndMainDocDetails: true,
      displayManuallyMapLineItemsScreen: false,
    });
  };

  manuallyMapLineItemsButtonClick = () => {
    this.setState({ displayManuallyMapLineItemsScreen: true });
  };

  removeLineItemsFromDocument = fileId => () => {
    const { dispatch } = this.props;
    dispatch(unmapAllFileItems({ fileId }));
  };

  mapLineItemToDocument = (fileId, { selectedRows, selectedRowsTotalAmount }) => {
    const { dispatch } = this.props;
    const amount = selectedRowsTotalAmount == null ? 0 : Number(selectedRowsTotalAmount);
    dispatch(mapItemToFile({
      lineItemIds: selectedRows.map(x => x.id),
      lineItemsAmount: amount,
      fileId: fileId,
    }));
  };

  render() {
    const {
      classes,
      supportingFilesMappings,
      supportingDocumentsLoading,
      projects,
      currencySymbol,
      supportingFiles,
      allSupportingFilesCount,
    } = this.props;
    const {
      displayManuallyMapLineItemsScreen,
      displayMatchedSupportingAndMainDocDetails,
      displayBackOfManuallyMapLineItemsScreen,
    } = this.state;

    return (
      <UserSidebarStructure
        mainContent={
          <React.Fragment>
            <UploadBreadcrumb index={constants.indexes.supportingDocumentsSetup} />
            <If condition={
              (supportingFiles.length > 1 ||
              allSupportingFilesCount > 1 ||
              displayManuallyMapLineItemsScreen) &&
              !displayMatchedSupportingAndMainDocDetails}
            >
              <Paper className={classes.root}>
                <div className={classes.flexContainer}>
                  <If condition={displayManuallyMapLineItemsScreen &&
                     displayBackOfManuallyMapLineItemsScreen}
                  >
                    <div className={classes.arrowButton}>
                      <ArrowButton
                        direction={arrowButtonConstants.direction.back}
                        onClickEvent={this.onBackToAutomatedMapLineItemsScreenClick}
                        className={classes.backButton}
                      />
                    </div>
                  </If>
                </div>
                <div className={classes.header}>{strings.pageTitle}</div>
                <div className={classes.flexContainer}>
                  <div className={`${classes.flex} ${classes.description}`}>
                    {strings.pageDescription}
                  </div>
                  <Button
                    onClick={this.onProceedClick}
                    className={classes.proceedButton}
                    height={buttonConstants.height.big}
                  >
                    {strings.process}
                  </Button>
                </div>
                <div className={classes.dropzoneContainer}>
                  <For each="file" of={supportingFilesMappings}>
                    <SupportingDocumentsDropzone
                      key={file.id}
                      file={file}
                      currencySymbol={currencySymbol}
                      onDrop={this.mapLineItemToDocument}
                      onRemove={this.removeLineItemsFromDocument(file.id)}
                      hasBalancingFigure={file.balancingValue !== 0}
                    />
                  </For>
                </div>
                <SupportingDocumentsLineItems currencySymbol={currencySymbol} />
              </Paper>
            </If>
            <If condition={
              supportingFiles.length === 1 &&
              allSupportingFilesCount === 1 &&
              !displayManuallyMapLineItemsScreen &&
              !displayMatchedSupportingAndMainDocDetails}
            >
              <Paper className={classes.root}>
                <div className={classes.header}>{strings.pageTitle}</div>
                <div className={classes.flexContainer}>
                  <div className={`${classes.flex} ${classes.description}`}>
                    {strings.automatedMapLineItemsDescription}
                  </div>
                  <Button
                    disabled={this.isAutomatedMapLineItemsButtonDisabled()}
                    onClick={this.automatedMapLineItemsButtonClick}
                    className={`${classes.proceedButton} ${this.isAutomatedMapLineItemsButtonDisabled() && classes.disabled}`}
                    height={buttonConstants.height.big}
                  >
                    {strings.automatedMapLineItemsButton}
                  </Button>
                </div>
                <div className={classes.manuallyMapLineItemsContainer}>
                  <SimpleButton
                    onClickEvent={this.manuallyMapLineItemsButtonClick}
                  >
                    {strings.manuallyMapLineItemsLabel}
                  </SimpleButton>
                </div>
                <div className={classes.tagContainer}>
                  <div className={classes.supportingDocTag}>
                    <Select
                      id={strings.supportingDocTagSelector}
                      label={strings.supportingDocTagLabel}
                      placeholder={strings.supportingDocTagSelector}
                      data={this.getTags(supporting)}
                      onChange={this.setSupportingDocTag}
                      value={this.state.selectedSupportingDocTag}
                      colorScheme={constantsSelect.colorScheme.lightBlue}
                    />
                  </div>
                  <div>
                    <Select
                      id={strings.mainDocTagSelector}
                      label={strings.mainDocTagLabel}
                      placeholder={strings.mainDocTagSelector}
                      data={this.getTags(main)}
                      onChange={this.setMainDocTag}
                      value={this.state.selectedMainDocTag}
                      colorScheme={constantsSelect.colorScheme.lightBlue}
                    />
                  </div>
                </div>
              </Paper>
            </If>
            <If condition={displayMatchedSupportingAndMainDocDetails}>
              <MatchedSupportingAndMainDocDetails
                onBack={this.onBackOfMatchedSupportingAndMainDocDetailsClick}
                onProceed={this.onProceedOfMatchedSupportingAndMainDocDetailsClick}
              />
            </If>
          </React.Fragment>
        }
        sidebarContent={<Sidebar />}
        sidebarContentLoading={false}
        mainContentLoading={supportingDocumentsLoading && projects.treePicker.loading}
        isCollapsible
        collapsed
      />
    );
  }
}

SupportingDocumentsMapper.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
  supportingFiles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    fileType: PropTypes.string,
    amountValue: PropTypes.number,
  })).isRequired,
  dispatch: PropTypes.func.isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedPeriodId: PropTypes.number,
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  supportingFilesMappings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    currentAmount: PropTypes.number.isRequired,
    initialAmount: PropTypes.number.isRequired,
    lineItemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  })).isRequired,
  allSupportingFilesCount: PropTypes.number.isRequired,
  supportingDocumentsLoading: PropTypes.bool.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  changeSupportingDocsAfterPeriodCreation: PropTypes.bool.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    tagId: PropTypes.number,
    columnIndex: PropTypes.number,
  })).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  })).isRequired,
  matchedSupportingAndMainDocsData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    periodId: periodIdSelector(state),
    supportingFiles: getAllFilesForTypeSelector(
      state,
      supporting,
      changeSupportingDocsAfterPeriodCreationSelector(state),
    ),
    allSupportingFilesCount: getAllSupportingFilesCount(state),
    supportingFilesMappings: getSupportingDocumentMappingFiles(state),
    filteredLineItemIds: getSupportingDocumentMappedLineItemIds(state),
    supportingDocumentsLoading: isSupportingDocumentMappingLoadingSelector(state),
    currencySymbol: isSupportingDocumentMappingLoadingSelector(state) ? '' : currencySymbolSelector(state),
    changeSupportingDocsAfterPeriodCreation: changeSupportingDocsAfterPeriodCreationSelector(state),
    headers: state.periods.period.data.fileSchema.headers.filter(x => x.isTagged),
    tags: selectMappedTagsFromPeriod(state),
    matchedSupportingAndMainDocsData: getMatchedSupportingAndMainDocsDataSelector(state),
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(SupportingDocumentsMapper);
