import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import {
  REQUEST_UPLOAD_PERIOD_FILE,
  REQUEST_UPLOAD_CUSTOM_REPORT_FILE,
  REQUEST_UPLOAD_REFERENCE_LIST_FILE,
  REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE,
  requestUploadFileSuccess,
} from 'Store/Areas/FileUpload/FileUploadActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { setCustomReportLoading } from 'Store/Areas/Export/ExportActions';
import { setReferenceListFileLoading } from 'Store/Areas/Categorisation/ReferenceListActions';
import { setExternalReviewFileLoading } from 'Store/Areas/Export/ExternalReviewActions';
import { PostFile } from 'Store/Api/CallApi';
import { UPLOAD_POST_FILE_URL, UPLOAD_POST_CUSTOM_REPORT_URL, UPLOAD_POST_REFERENCE_LIST_URL, UPLOAD_EXTERNAL_REVIEW_FILE_URL } from 'Store/Api/ApiEndpoints';
import { responseStatusCodes } from 'Store/Api/StatusCodes';

/* Worker Sagas */
function* handleResponse(url, response) {
  if (response.ok) {
    const data = yield response.json();
    yield put(requestUploadFileSuccess(data));
  } else if (response.status === responseStatusCodes.badRequest) {
    switch (url) {
      case REQUEST_UPLOAD_CUSTOM_REPORT_FILE:
        yield put(setCustomReportLoading(false));
        break;
      case REQUEST_UPLOAD_REFERENCE_LIST_FILE:
        yield put(setReferenceListFileLoading(false));
        break;
      case REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE:
        yield put(setExternalReviewFileLoading(false));
        break;
      default:
    }
    const data = yield response.json();
    yield put(displayToastNotification(data.error));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* postUploadFileForPeriod(url, action) {
  const formData = new FormData();
  formData.append('file', action.payload.file);
  formData.append('periodId', action.payload.periodId);
  formData.append('changeSupportingDocsAfterPeriodCreation', action.payload.changeSupportingDocsAfterPeriodCreation);

  const response = yield PostFile(url, formData);
  yield handleResponse(url, response);
}

/* Watcher Sagas */
export function* fileUploadSagas() {
  yield takeLatest(REQUEST_UPLOAD_PERIOD_FILE, postUploadFileForPeriod, UPLOAD_POST_FILE_URL);

  yield takeLatest(
    REQUEST_UPLOAD_CUSTOM_REPORT_FILE,
    postUploadFileForPeriod,
    UPLOAD_POST_CUSTOM_REPORT_URL,
  );
  yield takeLatest(
    REQUEST_UPLOAD_REFERENCE_LIST_FILE,
    postUploadFileForPeriod,
    UPLOAD_POST_REFERENCE_LIST_URL,
  );
  yield takeLatest(
    REQUEST_UPLOAD_EXTERNAL_REVIEW_FILE,
    postUploadFileForPeriod,
    UPLOAD_EXTERNAL_REVIEW_FILE_URL,
  );
}
