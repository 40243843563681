import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestSearch, resetSearch } from 'Store/Areas/Categorisation/KeywordSearchActions';

class KeywordSearchProvider extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const {
      requiresSearch,
      setRequiresSearch,
      requiresReset,
      setRequiresReset,
      dispatch,
    } = this.props;

    if (requiresSearch) {
      this.requestSearch();
      setRequiresSearch();
    } else if (requiresReset) {
      dispatch(resetSearch());
      setRequiresReset();
    } else {
      let propsDirty = false;
      KeywordSearchProvider.dirtyProps.forEach((x) => {
        if (prevProps[x] !== this.props[x]) {
          propsDirty = true;
        }
      });
      if (propsDirty) {
        this.requestSearch();
      }
    }
  }

  requestSearch() {
    const {
      dispatch,
      minQueryLength,
      tagId,
      periodId,
      value,
      searchId,
      preventSearch,
      operator,
    } = this.props;

    if (value && value.trim().length >= minQueryLength && !preventSearch) {
      dispatch(requestSearch(value, tagId, periodId, searchId, operator));
    } else {
      dispatch(resetSearch());
    }
  }

  render() {
    const {
      children,
      results,
      searchId,
      activeSearchId,
    } = this.props;
    return (
      children(searchId === activeSearchId ? results : [])
    );
  }
}

KeywordSearchProvider.dirtyProps = [
  'tagId',
  'value',
  'periodId',
  'searchId',
  'preventSearch',
  'operator',
];

KeywordSearchProvider.defaultProps = {
  minQueryLength: 3,
  preventSearch: false,
  operator: '',
};

KeywordSearchProvider.propTypes = {
  children: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(PropTypes.string).isRequired,
  minQueryLength: PropTypes.number,
  periodId: PropTypes.number.isRequired,
  tagId: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  requiresSearch: PropTypes.bool.isRequired,
  requiresReset: PropTypes.bool.isRequired,
  setRequiresSearch: PropTypes.func.isRequired,
  setRequiresReset: PropTypes.func.isRequired,
  searchId: PropTypes.number.isRequired,
  activeSearchId: PropTypes.number.isRequired,
  preventSearch: PropTypes.bool,
  operator: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    results: state.categorisation.keywordSearch.results,
    periodId: state.periods.period.periodId,
    activeSearchId: state.categorisation.keywordSearch.searchId,
  };
}

export default connect(mapStateToProps)(KeywordSearchProvider);
