import { SAVE_USER_AUTH_CONTEXT, SET_LOGOUT } from './ConfigActions';

const initialState = {
  clientId: null,
  authContext: null,
  signalRKeepAliveIntervalInSeconds: null,
  currentVersion: null,
  isLoggingOut: false,
};

export default function ConfigReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_USER_AUTH_CONTEXT:
      return Object.assign({}, state, {
        authContext: action.authContext,
      });
    case SET_LOGOUT:
      return Object.assign({}, state, {
        isLoggingOut: action.isLoggingOut,
      });
    default:
      return state;
  }
}
