import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DragnDrop from 'Constants/DragnDrop';
import { withStyles, withTheme } from '@material-ui/core';
import { DragSource } from 'react-dnd';
import { dragTag } from 'Store/Areas/Import/TagMappingActions';
import withDndPreviewContext from 'Components/Shared/DragNDrop/DndPreviewContextProvider';
import { styles } from './DragTag.styles';
import Tag from './Tag';

class DragTag extends React.Component {
  componentDidMount() {
    this.props.connectDragPreview(this.props.dndPreview);
  }

  render() {
    const {
      connectDragSource,
      tag,
      isDragging,
      classes,
      onClick,
      showDelete,
    } = this.props;
    return connectDragSource(<span className={isDragging ? classes.hidden : ''}><Tag tag={tag} onClick={onClick} showDelete={showDelete} /></span>);
  }
}

DragTag.defaultProps = {
  onClick: () => {},
  showDelete: true,
};

DragTag.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  tag: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  dndPreview: PropTypes.object.isRequired, // eslint-disable-line
  showDelete: PropTypes.bool,
};

// Implements the drag source contract.
const cardSource = {
  beginDrag(props) {
    props.dispatch(dragTag(props.tag.type));
    return {
      id: props.tag.id,
      name: props.tag.name,
      tagType: props.tag.type,
    };
  },
  endDrag(props, monitor) {
    props.dispatch(dragTag(null));
    if (monitor.didDrop()) {
      const { onDrop } = monitor.getDropResult();
      onDrop(props.tag.id, props.tag.name);
    }
  },
};

// Specifies the props to inject into your component
function collect(connectDnd, monitor) {
  return {
    connectDragSource: connectDnd.dragSource(),
    connectDragPreview: connectDnd.dragPreview(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
  };
}

export default compose(
  withTheme(),
  withStyles(styles),
  connect(),
  DragSource(DragnDrop.mappingTag, cardSource, collect),
  withDndPreviewContext(),
)(DragTag);
