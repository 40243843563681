export const UPDATE_CATEGORY_RULE_FILTERS = 'CATEGORY_RULES_FILTER/UPDATE_CATEGORY_RULE_FILTERS';
export const UPDATE_CATEGORY_RULE_SEARCH_TERM = 'CATEGORY_RULES_FILTER/UPDATE_CATEGORY_RULE_SEARCH_TERM';
export const RESET_CATEGORY_RULE_FILTERS = 'CATEGORY_RULES_FILTER/RESET_CATEGORY_RULE_FILTERS';

export function updateCategoryRuleFilters(
  periodId,
  categoryId,
  sortColumn,
  sortOrder,
  tagFilter,
  searchTerm,
) {
  return {
    type: UPDATE_CATEGORY_RULE_FILTERS,
    sortColumn: sortColumn,
    sortOrder: sortOrder,
    tagFilter: tagFilter,
    payload: {
      periodId: periodId,
      categoryId: categoryId,
      searchTerm: searchTerm,
      currentPageNumber: 0,
    },
  };
}

export function updateCategoryRuleSearchTerm(searchTerm) {
  return {
    type: UPDATE_CATEGORY_RULE_SEARCH_TERM,
    searchTerm: searchTerm,
  };
}

export function resetCategoryRuleFilters() {
  return {
    type: RESET_CATEGORY_RULE_FILTERS,
  };
}
