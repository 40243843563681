export const styles = theme => ({
  breadcrumb: {
    marginBottom: 10,
  },
  content: {
    padding: 20,
    height: 'Calc(100vh - 200px)',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    backgroundColor: theme.palette.primary.messageBackground,
  },
  gridRoot: {
    flexGrow: 1,
  },
  editorGrid: {
    height: '100%',
  },
  notes: {
    height: 100,
  },
  statusContainer: {
    minHeight: 20,
    marginTop: 10,
  },
  statusPanel: {
    fontSize: theme.constants.fontSizes.regular,
    display: 'flex',
    flexDirection: 'row',
  },
  marginLeft: {
    marginLeft: 10,
  },
  error: {
    color: theme.palette.primary.error,
  },
  valid: {
    color: theme.palette.primary.valid,
  },
  grid: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  builder: {
    width: '70%',
  },
  operators: {
    width: '30%',
  },
  button: {
    color: theme.palette.primary.main,
    fontSize: theme.constants.fontSizes.small,
    cursor: 'pointer',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
  },
  buttonDisabled: {
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.small,
    fontWeight: 'bold',
    border: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  textSize: {
    fontSize: theme.constants.fontSizes.small,
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: 5,
    marginTop: 20,
  },
  icon: {
    padding: 5,
  },
});
