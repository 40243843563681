import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  withTheme,
} from '@material-ui/core';
import { compose } from 'recompose';
import { commonStrings } from 'Constants/CommonStrings';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import Modal from './StyledModal';
import styles from './ConfirmationModal.styles';

class ConfirmationModal extends React.PureComponent {
  render() {
    const {
      open,
      title,
      message,
      children,
      onCancel,
      onConfirm,
      isSubmitting,
      submitDisabled,
      acceptText,
      cancelText,
      classes,
    } = this.props;
    return (
      <Modal open={open} className={classes.root} isSubmitting={isSubmitting}>
        <div className={classes.content}>
          <div className={classes.title}>{title}</div>
          <div className={classes.message}>{children || message}</div>
        </div>
        <Buttons
          isSubmitting={isSubmitting}
          submitDisabled={submitDisabled}
          onAccept={onConfirm}
          onCancel={onCancel}
          acceptText={acceptText}
          cancelText={cancelText}
        >
          {commonStrings.ok}
        </Buttons>
      </Modal>
    );
  }
}

ConfirmationModal.defaultProps = {
  message: '',
  title: '',
  children: null,
  isSubmitting: false,
  submitDisabled: false,
  onCancel: () => { },
  onConfirm: () => { },
  acceptText: commonStrings.ok,
  cancelText: commonStrings.cancel,
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  children: PropTypes.element,
  title: PropTypes.string,
  isSubmitting: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  acceptText: PropTypes.string,
  cancelText: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(ConfirmationModal);
