import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from 'Components/Shared/Modal/ConfirmationModal';
import { strings } from 'Constants/Templates/Strings';
import { commonStrings } from 'Constants/CommonStrings';

class DeleteTemplateModal extends React.PureComponent {
  render() {
    const {
      onConfirm,
      onCancel,
      isModalOpen,
      loading,
      templateName,
    } = this.props;

    return (
      <ConfirmationModal
        open={isModalOpen}
        title={strings.deleteModalTitle}
        message={strings.deleteModalNote(templateName)}
        isSubmitting={loading}
        acceptText={commonStrings.delete}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
      />
    );
  }
}

DeleteTemplateModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  templateName: PropTypes.string.isRequired,
};

export default (DeleteTemplateModal);
