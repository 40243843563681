import { put, select } from 'redux-saga/effects';
import { userActions } from 'Constants/UserActions';
import { getPeriod } from 'Store/Areas/Period/PeriodActions';
import { routes } from 'Constants/Routes';
import { strings } from 'Constants/Export/Strings';
import { setExportLocked, resetCustomReportFiles } from 'Store/Areas/Export/ExportActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { currentRouteSelector } from 'Store/Areas/Router/RouteSelectors';
import SignalrStrategyHandler from './SignalrStrategyHandler';

export const exportHandler = new SignalrStrategyHandler(
  userActions.Export,
  function* handler(payload, action) {
    if (payload.error) {
      yield put(resetCustomReportFiles());
      yield put(setExportLocked(false));
      yield this.handleError(action);
    } else {
      const currentRoute = yield select(currentRouteSelector);
      yield put(getPeriod(action.selectedPeriodId, true));
      yield put(setExportLocked(false));
      yield put(resetCustomReportFiles());
      if (currentRoute === routes.review) {
        yield put(displayToastNotification(
          strings.exportCompleteNotificationBody,
          strings.clickHereToView,
          routes.export,
        ));
      }
    }
  },
);
