import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'Components/Shared/Modal/ErrorModal';
import { strings } from 'Constants/Upload/Strings';
import { resetUploadFile } from 'Store/Areas/FileUpload/FileUploadActions';

class ErrorModal extends React.PureComponent {
  toggleErrorModal() {
    this.props.dispatch(resetUploadFile());
  }

  render() {
    const { uploadResponse } = this.props.upload;
    const error = uploadResponse != null ? uploadResponse.error : '';

    return (
      <Modal
        error={error}
        open={uploadResponse != null && uploadResponse.error != null && uploadResponse.error !== ''}
        title={strings.uploadError}
        onClose={() => this.toggleErrorModal()}
      />
    );
  }
}

ErrorModal.propTypes = {
  upload: PropTypes.shape({
    loading: PropTypes.bool,
    uploadResponse: PropTypes.object,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    upload: state.import.upload,
  };
}

export default connect(mapStateToProps)(ErrorModal);
