import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme, Card } from '@material-ui/core';
import FileUploader from 'Components/Shared/FileUploader/FileUploader';
import UploadDropzoneContent from 'Components/Upload/Shared/UploadDropzoneContent';
import withCorruptedFileProvider from 'Components/Shared/CorruptedFileProvider/WithCorruptedFileProvider';
import { strings } from 'Constants/Templates/Strings';
import { strings as adminStrings } from 'Constants/Setup/Admin/Strings';
import { strings as uploadStrings } from 'Constants/Upload/Strings';
import { xlsFileTypes } from 'Constants/FileTypes';
import { routes } from 'Constants/Routes';
import { uploadTemplate } from 'Store/Areas/Template/UploadTemplateActions';
import { toggleCorruptedFileDetectedModal } from 'Store/Areas/FileUpload/FileUploadActions';
import { push } from 'connected-react-router';
import { AutoSizer } from 'react-virtualized';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import Tab from 'Components/Shared/Tab/Tab';
import TemplateTable from './TemplateTable';
import styles from './TemplateManager.styles';

class TemplateManager extends React.PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getTreePickerData());
  }

  onFileSelected = (file) => {
    const { dispatch } = this.props;
    dispatch(uploadTemplate(file));
    dispatch(push(routes.templateProcessing));
  }

  onFileRejected = () => {
    this.props.dispatch(toggleCorruptedFileDetectedModal({
      showModal: true,
      fileTypes: xlsFileTypes,
    }));
  }

  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.titleContainer}>
          <span className={classes.title}>{strings.title}</span>
          <span className={classes.description}>
            {adminStrings.youAreIn}<b>{adminStrings.adminMode}</b>
          </span>
        </div>
        <Card className={classes.card}>
          <div className={classes.message}>
            <b>{strings.subtitle}</b>
          </div>
          <div className={classes.message}>
            {strings.templateUploadNote}
          </div>
          <FileUploader
            onFileSelected={files => this.onFileSelected(files[0])}
            onDropRejected={this.onFileRejected}
            fileTypes={xlsFileTypes}
          >
            <UploadDropzoneContent
              description={uploadStrings.dragAndDropDescriptionTemplate}
              buttonDescription={uploadStrings.templateUploadButtonDescription}
            />
          </FileUploader>
        </Card>
        <Tab
          items={[
            strings.templateTabTitle,
          ]}
          activeIndex={0}
          className={classes.breadcrumb}
          onClicks={
            [
              null,
            ]
          }
        />
        <Card className={classes.tableCard}>
          <div className={classes.templateTableWrapper}>
            <AutoSizer>
              {({ width, height }) => (
                <div style={{ width: width }}>
                  <TemplateTable width={width} height={height} />
                </div>
              )}
            </AutoSizer>
          </div>
        </Card>
      </div >
    );
  }
}

TemplateManager.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default compose(
  withCorruptedFileProvider(),
  withStyles(styles),
  withTheme(),
)(TemplateManager);
