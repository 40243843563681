import {
  GET_COMBO_KEYWORDS,
  SET_COMBO_KEYWORD_RULE,
  RESET_COMBO_KEYWORDS,
  GET_COMBO_KEYWORDS_SUCCESS,
  UPDATE_COMBO_KEYWORD_FILTERS,
  UPDATE_COMBO_SEARCH_TERM,
  RESET_COMBO_KEYWORD_FILTERS,
} from 'Store/Areas/Categorisation/ComboKeywordActions';
import { sortOrder } from 'Constants/Categorization/QueryTermFilters';
import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';
import { union } from 'Helpers/ArrayHelpers';

const initialState = {
  loading: false,
  currentPageNumber: 1,
  morePagesAvailable: true,
  keywords: [],
  hasSearched: false,
  rootKeyword: '',
  minRequestTime: 0,
  rootTag: {
    id: 0,
    name: '',
  },
  filters: {
    sortOrder: sortOrder.valueDescending,
    tagFilter: 0,
    searchTerm: '',
  },
};

function getKeywordsState(state, action) {
  let newState = {
    currentPageNumber: action.payload.currentPageNumber + 1,
    morePagesAvailable: false,
    loading: action.loading,
    hasSearched: action.hasSearched,
  };

  if (!action.isLoadMoreKeywords) {
    newState = {
      ...newState,
      minRequestTime: action.requestTime,
      keywords: [],
    };
  }

  return newState;
}

function getNewKeywordsState(state, action) {
  if (action.requestTime < state.minRequestTime) {
    return null;
  }

  return {
    morePagesAvailable: action.morePagesAvailable,
    loading: action.loading,
    keywords: action.isLoadMoreKeywords
      ? union(state.keywords, action.keywords, k => k.name)
      : action.keywords,
  };
}

export default function comboKeywordReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMBO_KEYWORDS:
      return Object.assign({}, state, getKeywordsState(state, action));
    case GET_COMBO_KEYWORDS_SUCCESS: {
      const newState = getNewKeywordsState(state, action);
      return newState ? Object.assign({}, state, newState) : state;
    }
    case UPDATE_COMBO_KEYWORD_FILTERS:
      return Object.assign({}, state, {
        filters: {
          sortOrder: action.sortOrder,
          tagFilter: action.tagFilter,
          searchTerm: state.filters.searchTerm,
        },
      });
    case UPDATE_COMBO_SEARCH_TERM:
      return Object.assign({}, state, {
        filters: {
          sortOrder: state.filters.sortOrder,
          tagFilter: state.filters.tagFilter,
          searchTerm: action.searchTerm,
        },
      });
    case SET_COMBO_KEYWORD_RULE:
      return Object.assign({}, initialState, {
        rootKeyword: action.payload.rootKeyword,
        rootTag: action.payload.rootTag,
      });
    case RESET_PERIOD:
    case RESET_COMBO_KEYWORDS:
    case RESET_COMBO_KEYWORD_FILTERS:
      return initialState;
    default:
      return state;
  }
}
