const styles = theme => ({
  brand: {
    paddingTop: 28,
    paddingBottom: 28,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    borderBottomStyle: 'solid',
    borderBottomColor: '#cccccc',
    borderBottomWidth: 1,
    width: '100%',
  },
  brandImage: {
    marginLeft: 14,
    marginRight: 14,
    marginTop: 0,
    marginBottom: 0,
  },
  bottom: {
    position: 'fixed',
    bottom: 15,
    width: theme.constants.sidebarWidths.primary,
  },
  brandButton: {
    padding: 0,
    width: '100%',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export default styles;
