import React, { Fragment } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { strings } from 'Constants/Rules/Strings';
import { numericOperators, textOperators, joinWords } from 'Constants/Rules/RuleConstants';
import { anyTag } from 'Constants/Categorization/Tags';
import DragnDrop from 'Constants/DragnDrop';
import { constants } from 'Components/Shared/DragNDrop/SimpleDragCard';
import ExpansionPanel from 'Components/Shared/ExpansionPanel/ExpansionPanel';
import ExpansionPanelManager from 'Components/Shared/ExpansionPanel/ExpansionPanelManager';
import { styles } from './OperatorsPanel.styles';
import { Operator } from '../';

class OperatorsPanel extends React.PureComponent {
  render() {
    const { classes, tags, isReadOnly } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.overflow}>
          <ExpansionPanelManager defaultExpandedIndex={[1, 2, 3]}>
            {({ active, onExpand }) => (
              <Fragment>
                <ExpansionPanel
                  header={strings.tags}
                  className={classes.margin}
                  index={1}
                  activeIndex={active}
                  onExpand={onExpand}
                >
                  <Operator
                    canDrag={!isReadOnly}
                    borderColor={constants.borderColor.grey}
                    type={DragnDrop.operator.tag}
                    metadata={anyTag}
                  >
                    {anyTag.name}
                  </Operator>
                  <For each="tag" of={tags}>
                    <Operator
                      canDrag={!isReadOnly}
                      borderColor={constants.borderColor.grey}
                      key={tag.name}
                      type={DragnDrop.operator.tag}
                      metadata={tag}
                    >
                      {tag.name}
                    </Operator>
                  </For>
                </ExpansionPanel>
                <ExpansionPanel
                  header={strings.operators}
                  className={classes.margin}
                  index={2}
                  activeIndex={active}
                  onExpand={onExpand}
                >
                  <For each="item" of={Object.values(numericOperators)}>
                    <Operator
                      canDrag={!isReadOnly}
                      borderColor={constants.borderColor.blue}
                      key={item}
                      type={DragnDrop.operator.operator}
                    >
                      {item}

                    </Operator>
                  </For>
                  <For each="item" of={Object.values(textOperators)}>
                    <Operator
                      canDrag={!isReadOnly}
                      borderColor={constants.borderColor.blue}
                      key={item}
                      type={DragnDrop.operator.operator}
                    >
                      {item}
                    </Operator>
                  </For>
                </ExpansionPanel>
                <ExpansionPanel
                  header={strings.joinWords}
                  className={classes.margin}
                  index={3}
                  activeIndex={active}
                  onExpand={onExpand}
                >
                  <For each="item" of={Object.values(joinWords)}>
                    <Operator
                      canDrag={!isReadOnly}
                      borderColor={constants.borderColor.blue}
                      key={item}
                      type={DragnDrop.operator.join}
                    >
                      {item}
                    </Operator>
                  </For>
                </ExpansionPanel>
              </Fragment>
            )}
          </ExpansionPanelManager>
        </div>
      </div>
    );
  }
}

OperatorsPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(OperatorsPanel);
