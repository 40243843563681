import React from 'react';
import { withStyles, withTheme, Card, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { PropTypes } from 'prop-types';
import Search from 'Components/Shared/Inputs/Search';
import Select, { constants } from 'Components/Shared/Selects/Select';
import { sortOrder } from 'Constants/Contras/QueryTermFilters';
import { strings } from 'Constants/Contras/Strings';
import { updateReviewContras, updateSearchTerm } from 'Store/Areas/Import/ContraActions';
import { styles } from './ContrasFiltersPanel.styles';

class ContraFiltersPanel extends React.PureComponent {
  onSortOrderChanged = (value) => {
    const {
      dispatch,
      contraFilters,
      periodId,
    } = this.props;
    dispatch(updateReviewContras(
      periodId,
      '',
      value,
      contraFilters.searchTerm,
      !!contraFilters.searchTerm.trim(),
    ));
  }

  onSearchTermChanged = (value) => {
    const { dispatch } = this.props;
    dispatch(updateSearchTerm(value));
  }

  onSearchIconClicked = () => {
    const { dispatch, contraFilters, periodId } = this.props;
    dispatch(updateReviewContras(
      periodId,
      '',
      contraFilters.sortOrder,
      contraFilters.searchTerm,
      !!contraFilters.searchTerm.trim(),
    ));
  }

  onSearchReset = () => {
    const { dispatch, contraFilters, periodId } = this.props;
    dispatch(updateSearchTerm(''));
    dispatch(updateReviewContras(
      periodId,
      '',
      contraFilters.sortOrder,
      '',
      !!contraFilters.searchTerm.trim(),
    ));
  }

  render() {
    const {
      classes,
      contraFilters,
    } = this.props;
    return (
      <Card className={classes.root}>
        <Grid container direction="row" spacing={8}>
          <Grid item lg={4} md={6} sm={12}>
            <Select
              id={strings.sortBy}
              label={strings.sortBy}
              preventPlaceholder
              onChange={this.onSortOrderChanged}
              data={[
                { id: sortOrder.valueDescending, name: strings.highestValue },
                { id: sortOrder.valueAscending, name: strings.lowestValue },
              ]}
              value={contraFilters.sortOrder}
              colorScheme={constants.colorScheme.lightBlue}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={12}>
            <Search
              id={strings.searchContras}
              label={strings.searchContras}
              onChange={this.onSearchTermChanged}
              onSearch={this.onSearchIconClicked}
              onReset={this.onSearchReset}
              placeholder={strings.searchPlaceholder}
              value={contraFilters.searchTerm}
            />
          </Grid>
        </Grid>
      </Card>
    );
  }
}

ContraFiltersPanel.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  contraFilters: PropTypes.shape({
    sortOrder: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired,
  }).isRequired,
  periodId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    contraFilters: state.import.contras.filters,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(ContraFiltersPanel);
