export const OPEN_CREATE_RULE = 'CREATERULE/OPEN_CREATE_RULE';
export const CLOSE_CREATE_RULE = 'CREATERULE/CLOSE_CREATE_RULE';
export const RESET_CREATE_RULE = 'CREATERULE/RESET_CREATE_RULE';

export function openCreateRule() {
  return {
    type: OPEN_CREATE_RULE,
    open: true,
    rule: '',
    note: '',
  };
}

export function closeCreateRule(rule, note) {
  return {
    type: CLOSE_CREATE_RULE,
    open: false,
    rule: rule,
    note: note,
  };
}

export function resetCreateRule() {
  return {
    type: RESET_CREATE_RULE,
  };
}
