import {
  GET_IMPORT_SUMMARY,
  GET_IMPORT_SUMMARY_SUCCESS,
} from 'Store/Areas/Import/ImportSummaryActions';

const initialState = {
  loading: false,
  importErrors: [],
  tooManyErrors: false,
  userCanProceed: true,
  tooManyClauses: false,
  ruleText: '',
};

export default function importSummaryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_IMPORT_SUMMARY:
    case GET_IMPORT_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        importErrors: action.importErrors,
        tooManyErrors: action.tooManyErrors,
        userCanProceed: action.userCanProceed,
        tooManyClauses: action.tooManyClauses,
        ruleText: action.ruleText,
      });
    default:
      return state;
  }
}
