import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core';
import { strings } from 'Constants/Categorization/Strings';
import PropTypes from 'prop-types';
import SuccessMessage from './SuccessMessage';
import Robot from './Robot';
import styles from './CatergorizationComplete.styles';


class CategorizationComplete extends React.PureComponent {
  render() {
    const {
      classes,
      message,
      checkApportionmentResponse,
    } = this.props;
    return (
      <div className={classes.successContainer}>
        <div>
          <If condition={checkApportionmentResponse.isDefinitionNotCompleted}>
            <SuccessMessage
              message={strings.apportionmentDefinitionNotCompleted}
            />
          </If>
          <If condition={!checkApportionmentResponse.isDefinitionNotCompleted}>
            <Robot />
            <SuccessMessage message={message} secondMessage={strings.categorisationComplete} />
          </If>
        </div>
      </div>
    );
  }
}

CategorizationComplete.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  message: PropTypes.string.isRequired,
  checkApportionmentResponse: PropTypes.shape({
    isCalculationCompleted: PropTypes.bool.isRequired,
    isDefinitionNotCompleted: PropTypes.bool.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    checkApportionmentResponse: state.periods.period.checkApportionmentResponse,
  };
}


export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(CategorizationComplete);
