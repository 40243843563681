export const strings = {
  treePickerTitle: 'Select or add a period:',
  addPeriodButton: 'Add new period',
  noGroupsFound: 'No groups found',
  changePeriodStatusTitle: 'Change Period Status/Unlock Period',
  currentPeriodStatus: 'Current Period Status',
  selectPeriodStatus: 'Set New Period Status',
  isPeriodLockedByUser: 'Is Period Locked By User?',
  periodLockReason: 'Period Lock Reason',
  updateStatus: 'Change Status',
  unlockPeriod: 'Unlock Period',
  selectPeriodStatusPlaceHolder: 'Select a new status for the period',
  changePeriodStatusText: periodName => `(This screen is only accessible to Sys Admins. Warning: Changes made here will directly affect the period - ${periodName})`,
  emptyStatusValidationText: 'Please select a Status',
  sameStatusValidationText: 'Please select a different Status',
};
