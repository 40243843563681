import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { push } from 'connected-react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import CogIcon from 'Assets/Images/cog-ic.svg';
import { routes } from 'Constants/Routes';
import { strings } from 'Constants/Categorization/Strings';
import { selectCategoryForReview } from 'Store/Areas/Categorisation/ReviewCategoryActions';
import { isReadOnlySelector, periodIdSelector } from 'Store/Areas/Period/PeriodSelectors';
import { formatCurrency } from 'Helpers/NumberHelpers';
import { categoryListTypes as constants } from 'Constants/Categorization/CategoryListTypes';
import styles from './Category.styles';
import RuleCreationZone from './RuleCreationZone';

class Category extends React.PureComponent {
  onSelect = () => {
    const {
      category,
      dispatch,
      periodId,
    } = this.props;

    if (category.id) {
      if (category.name.toLowerCase() === strings.apportionable.toLowerCase()
        && category.color === strings.apportionableColor) {
        dispatch(selectCategoryForReview(category, periodId, true));
        dispatch(push(routes.apportionableManager));
      } else {
        dispatch(selectCategoryForReview(category, periodId, false));
        dispatch(push(routes.categoryReview));
      }
    }
  }

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onSelect();
    }
  }

  getButtonProps(classes) {
    return {
      className: classes.surroundButton,
      onClick: this.onSelect,
      onKeyPress: this.onKeyPress,
      role: 'button',
      tabIndex: -1,
    };
  }

  render() {
    const {
      classes,
      className,
      currencySymbol,
      category,
      onEdit,
      categoryType,
      editable,
      isReadOnly,
    } = this.props;

    const buttonProps = category.id ? this.getButtonProps(category.id, classes) : null;

    return (
      <div
        {...buttonProps}
      >
        <RuleCreationZone
          category={category}
          categoryType={categoryType}
          className={className}
        >
          <div className={classes.titleRow}>
            <div
              className={classes.color}
              style={{ backgroundColor: category.color }}
            />
            <div className={classes.title}>{category.name}</div>
            <If condition={category.amountValue !== undefined}>
              <div className={classes.value}>
                {formatCurrency(category.amountValue, currencySymbol)}
              </div>
            </If>
            <Choose>
              <When condition={editable && !isReadOnly}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(category);
                  }}
                  className={classes.edit}
                >
                  <img alt="Edit category" src={CogIcon} />
                </button>
              </When>
              <When condition={!editable && !isReadOnly}>
                <button className={classes.editHidden}>
                  <img alt="Edit category" src={CogIcon} />
                </button>
              </When>
            </Choose>
          </div>
        </RuleCreationZone>
      </div>
    );
  }
}

Category.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  className: PropTypes.string,
  currencySymbol: PropTypes.string.isRequired,
  periodId: PropTypes.number.isRequired,
  category: PropTypes.shape({
    id: PropTypes.number,
    colorCode: PropTypes.string,
    name: PropTypes.string,
    amountValue: PropTypes.number,
  }).isRequired,
  onEdit: PropTypes.func,
  categoryType: PropTypes.oneOf(Object.values(constants.categoryType)).isRequired,
  editable: PropTypes.bool,
  isReadOnly: PropTypes.bool.isRequired,
};

Category.defaultProps = {
  className: '',
  onEdit: () => { },
  editable: true,
};

function mapStateToProps(state) {
  return {
    periodId: periodIdSelector(state),
    isReadOnly: isReadOnlySelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(Category);
