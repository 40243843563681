import { takeLatest, put } from 'redux-saga/effects';
import { Post } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';
import {
  GET_CURRENT_RULE_PRIORITY,
  EDIT_RULE_PRIORITY,
  getCurrentRulePrioritySuccess,
  closeEditPriorityModal,
} from 'Store/Areas/Rule/RulePriorityActions';
import {
  RULE_CURRENT_PRIORITY,
  RULE_EDIT_PRIORITY,
} from 'Store/Api/ApiEndpoints';

function* getRulePriority(action) {
  const response = yield Post(RULE_CURRENT_PRIORITY, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getCurrentRulePrioritySuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* updateRulePriority(action) {
  yield put(setProcessing(true));

  const response = yield Post(RULE_EDIT_PRIORITY, action.payload);
  if (response.ok) {
    yield put(closeEditPriorityModal());
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* rulePrioritySagas() {
  yield takeLatest(GET_CURRENT_RULE_PRIORITY, getRulePriority);
  yield takeLatest(EDIT_RULE_PRIORITY, updateRulePriority);
}
