import { put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { categoryListTypes } from 'Constants/Categorization/CategoryListTypes';
import { Post } from 'Store/Api/CallApi';
import { LINE_ITEM_CATEGORISE_LINE_ITEMS } from 'Store/Api/ApiEndpoints';
import { setProcessing } from 'Store/Areas/Categorisation/ProcessingActions';
import { openApportionmentResourceFromLineItem } from 'Store/Areas/Apportionables/ApportionablesModalActions';
import { CategorisationStrategyHandler } from './CategorisationStrategyHandler';
import * as RuleActions from '../CategorisationActions';

export const lineItemsStrategyHandler = new CategorisationStrategyHandler(
  categoryListTypes.categoryType.lineItems,
  function* handleCategoryStrategy(action) {
    if (!this.checkCategoriesTotalTo1(action.categories)) {
      yield put(RuleActions.requestApportionment());
      return;
    }
    if (this.checkApportionment(action.categories)) {
      yield put(openApportionmentResourceFromLineItem(
        action.periodId,
        action.ruleProperties.lineItemIds,
        action.categories,
        action.categorizationType,
        action.previousCategoryId,
      ));
      return;
    }
    if (action.displayLineItemsCategorisationReasonModal) {
      yield put(RuleActions.requestCategorisationReason());
      return;
    }
    yield put(setProcessing(true));
    const response = yield Post(LINE_ITEM_CATEGORISE_LINE_ITEMS, {
      periodId: action.periodId,
      lineItemIds: action.ruleProperties.lineItemIds,
      categories: action.categories,
      searchTerm: action.searchTerm,
      contraShelfLineItemIds: action.contraShelfLineItemIds,
      categorizationType: action.categorizationType,
    });
    if (!response.ok) {
      yield handleStatusCode(response.status);
    }
  },
);
