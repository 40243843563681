export const strings = {
  machineLearning: 'Machine Learning',
  contra: 'Contras',
  manuallyCategorised: 'Manually Categorised',
  rules: 'Rules',
  value: 'Value: ',
  valueCategorised: 'Value (Categorised): ',
  categorisedByRules: 'Categorised By Rules ',
  categorisedLineItems: 'Categorised line items: ',
  numberOfLineItems: 'Number of line items: ',
  lineItems: 'Line items',
  conflictedItems: 'Conflicted Items',
  allLineItems: 'All line items (Excluding Contras)',
  reviewTagging: 'Review Tagging',
  reviewTaggingContent: 'Here you can review how the original main document was tagged',
  apportionable: 'Apportionable',
  apportionableColor: '#000000',
};
