import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme, Paper, Grid, Card, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { inspectRule } from 'Store/Areas/Rule/InspectRuleActions';
import { openCreateRule, resetCreateRule } from 'Store/Areas/Rule/CreateRuleActions';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { processingSelector } from 'Store/Areas/Rule/CategorisationSelectors';
import { isReadOnlySelector, periodSelector } from 'Store/Areas/Period/PeriodSelectors';
import { getCategorisationLogic } from 'Store/Areas/Import/LineItemValueActions';
import { uncategoriseLineItems, markLineItemsAsFlaggedOrUnflagged } from 'Store/Areas/Categorisation/LineItemActions';
import { currencySymbolSelector } from 'Helpers/TreePickerHelpers';
import { formatCurrency, formatCurrencyString } from 'Helpers/NumberHelpers';
import { formatCellValue } from 'Helpers/LineItemHelpers';
import { getTooltip } from 'Helpers/DataGridHelpers';
import { categorizationTypes, lineItemCanBeUncategorised } from 'Constants/Categorization/CategorizationTypes';
import DragnDrop from 'Constants/DragnDrop';
import { categorisationLocations, categorisationLineItemsReviewModes as modes } from 'Constants/Routes';
import { strings } from 'Constants/Categorization/CategoryReview/LineItemsCategorisationReview/Strings';
import { strings as mlStrings } from 'Constants/MachineLearning/Strings';
import { strings as categorisationStrings } from 'Constants/Categorization/Strings';
import { title } from 'Constants/App/Titles/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { categoryTypes } from 'Constants/Review/CategoryTypes';
import { categoryListTypes } from 'Constants/Categorization/CategoryListTypes';
import Close from 'Assets/Images/exit-ic.svg';
import Unflagged from 'Assets/Images/flagged-white.svg';
import Flagged from 'Assets/Images/flagged-black.svg';
import withLineItems, { sortFields } from 'Components/Shared/LineItemsProvider/LineItemsProvider';
import Select, { constants as constantsSelect } from 'Components/Shared/Selects/Select';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import DataGrid from 'Components/Shared/DataGrid/DataGrid';
import SortableHeader from 'Components/Shared/DataGrid/Cells/SortableHeader/SortableHeader';
import SimpleHeader from 'Components/Shared/DataGrid/Cells/SimpleHeader/SimpleHeader';
import BorderedRow from 'Components/Shared/DataGrid/Rows/BorderedRow';
import SimpleCell from 'Components/Shared/DataGrid/Cells/SimpleCell/SimpleCell';
import CategoryList from 'Components/Categorization/Shared/CategoryList/CategoryList';
import GenericDragLayer from 'Components/Shared/DragNDrop/GenericDragLayer';
import PreviewRule from 'Components/Shared/DragNDrop/DragPreviewComponents/PreviewRule';
import Blur from 'Components/Shared/Blur/Blur';
import Search from 'Components/Shared/Inputs/Search';
import KeywordHighlighter from 'Components/MachineLearning/KeywordHighlighter';
import InspectRuleOverlay from 'Components/CategoryReview/InspectRuleOverlay';
import { resetRule } from 'Store/Areas/Rule/RuleBuilderActions';
import CreateRuleOverlay from 'Components/CategoryReview/CreateRuleOverlay/CreateRuleOverlay';
import RuleFilterDragLayer from './RuleFilterDragLayer';
import CreateRuleDragLayer from './CreateRuleDragLayer';
import TagSearchFilter from './TagSearchFilter';
import { styles } from './LineItemsCategorisationReview.styles';

const maxMlLineItems = 60;

class LineItemsCategorisationReview extends React.PureComponent {
  state = {
    selectedCategoryType: 0,
    categoryType: categoryListTypes.categoryType.lineItems,
    advancedSearch: false,
  };

  componentDidMount() {
    document.title = title.review;

    this.props.dispatch(getTreePickerData());
    this.props.dispatch(resetRule());
    this.props.dispatch(resetCreateRule());
    this.setMode(this.props.mode, this.props.categoryRules);
  }

  componentDidUpdate() {
    if (!(!this.props.lineItemsLoading
      && this.props.isSearchTermPresent
      && this.props.mode === modes.lineItems
      && this.props.lineItems
      && this.props.lineItems.length)) {
      this.clearRuleFilterCategoryType();
    }
  }

  getCellValue = ({ rowIndex, columnIndex }) => {
    const {
      classes,
      lineItems,
      lineItemsColumns,
      amountColumnIndex,
      confidenceColumnIndex,
    } = this.props;

    const lineItem = lineItems[rowIndex];
    let cell = lineItem.columns[lineItemsColumns[columnIndex]];

    // Assumes that the first column always contains Confidence values (a number 0..1)
    if (columnIndex === confidenceColumnIndex) {
      cell = Object.assign({}, cell);
      cell.value *= 100;
      cell.format = '#0';
    }
    if (columnIndex === lineItemsColumns.length - 1 && lineItem.columns.RuleId !== undefined
      && !lineItem.isApportionment) {
      const rule = this.state.categoryRules.find(r => r.id === lineItem.columns.RuleId.value);
      return (
        <Button
          onClick={() => this.showInspectRuleOverlay(rule)}
          className={classes.imgButton}
          disableRipple
        >
          {strings.showRule}
        </Button>
      );
    }

    if (columnIndex === amountColumnIndex) {
      return cell.value;
    }

    return formatCellValue(cell);
  }

  getMainTitle = (symbol) => {
    switch (this.props.mode) {
      case modes.ml:
        return strings.mainTitleMl;
      case modes.manual:
        return strings.mainTitleManual;
      case modes.conflicts:
        return strings.mainTitleConflicts;
      case modes.lineItems:
        return strings.mainTitleLineItems;
      case modes.rules:
        return strings.mainTitleRules(
          this.props.category.name,
          formatCurrencyString(this.props.category.amountValue, symbol),
          this.props.category.lineItems,
        );
      default:
        throw new Error('Not implemented.');
    }
  }

  getDescription = () => {
    switch (this.props.mode) {
      case modes.conflicts:
        return strings.descriptionConflicts;
      default:
        return strings.description;
    }
  }

  getWidth = ({ index }) => {
    const {
      descriptionColumnIndex,
      confidenceColumnIndex,
      categoryColumnIndex,
    } = this.props;

    switch (index) {
      case confidenceColumnIndex:
        return 150;
      case categoryColumnIndex:
        return 250;
      default:
        return index === descriptionColumnIndex ? 300 : 150;
    }
  }

  setSelectedCategoryType = (value) => {
    if (this.state.selectedCategoryType !== value) {
      this.setState({
        selectedCategoryType: value,
      });

      this.props.setOptions({
        categoryType: value,
      });
    }
  }

  setRuleFilterCategoryType = () => {
    this.setState({
      categoryType: categoryListTypes.categoryType.customRule,
    });
  }

  clearRuleFilterCategoryType = () => {
    this.setState({
      categoryType: categoryListTypes.categoryType.lineItems,
    });
  }

  openCreateRuleOverlay = () => {
    this.props.dispatch(openCreateRule());
  }

  setMode = (mode, categoryRules) => {
    const { category } = this.props;
    switch (mode) {
      case modes.ml:
        this.props.setOptions({
          type: categorizationTypes.machineLearning,
          includeMlPredictions: true,
          includeCategoryColumn: true,
          includeRuleColumn: false,
          sortField: sortFields.confidence,
          categoryId: category.id,
          categorizationType: categorizationTypes.machineLearning,
        });
        break;
      case modes.manual:
        this.props.setOptions({
          type: categorizationTypes.manual,
          includeMlPredictions: false,
          includeCategoryColumn: true,
          includeRuleColumn: false,
          categoryId: category.id,
          sortField: sortFields.rowId,
          categorizationType: categorizationTypes.manual,
        });
        break;
      case modes.conflicts:
        this.props.setOptions({
          type: categorizationTypes.allowedConflicts,
          includeMlPredictions: false,
          includeCategoryColumn: false,
          includeRuleColumn: false,
          sortField: sortFields.rowId,
          categorizationType: categorizationTypes.allowedConflicts,
        });
        break;
      case modes.contra:
        this.props.setOptions({
          type: categorizationTypes.contra,
          includeMlPredictions: false,
          includeCategoryColumn: false,
          includeRuleColumn: false,
          sortField: sortFields.rowId,
          categorizationType: categorizationTypes.contra,
        });
        break;
      case modes.lineItems:
        this.props.setOptions({
          type: categorizationTypes.allExceptContras,
          includeMlPredictions: false,
          includeContraFlagColumn: false,
          includeCategoryColumn: true,
          includeFlaggedColumn: true,
          sortField: sortFields.rowId,
          categorizationType: categorizationTypes.allExceptContras,
          showOnlyFlaggedLineItems: false,
        });
        break;
      case modes.rules:
        this.props.setOptions({
          type: categorizationTypes.rule,
          includeMlPredictions: false,
          includeCategoryColumn: false,
          includeRuleColumn: true,
          categoryId: category.id,
          sortField: sortFields.rowId,
          categorizationType: categorizationTypes.rule,
        });
        this.setState({ categoryRules });
        break;
      default:
        // Do nothing. Use the state taken from options.
        break;
    }
  }

  getDragProps = (props) => {
    const {
      lineItems,
      selectedRowIds,
      selectedRows,
      selectedRowsTotalAmount,
      category,
    } = this.props;

    return {
      index: props.index,
      lineItemId: lineItems[props.index].id,
      categorizationType: lineItems[props.index].categorizationType,
      lineItemIds: selectedRowIds,
      previousCategoryId: category.id,
      selectedRows,
      selectedRowsTotalAmount,
    };
  }

  getIsFlagged = (lineItem) => {
    return lineItem.columns.IsFlagged.value;
  }

  showInspectRuleOverlay(rule) {
    const { dispatch } = this.props;
    dispatch(inspectRule(rule));
  }

  runCategorisationRequest = () => {
    this.props.dispatch(getCategorisationLogic(this.props.periodId, this.props.selectedRowIds));
  }

  handleIconClick = (lineItemId) => {
    this.props.dispatch(uncategoriseLineItems(
      this.props.periodId,
      [lineItemId],
    ));
  }

  handleFlagClick = (lineItem) => {
    this.props.dispatch(markLineItemsAsFlaggedOrUnflagged(
      this.props.periodId,
      [lineItem.id],
      !this.getIsFlagged(lineItem),
      false,
    ));
  }

  handleAdvancedSearch = (tagFilters) => {
    this.props.setTagFilters(tagFilters);
    this.props.onSearch();
  }

  toggleAdvancedSearchClick = () => {
    this.props.resetSearch();

    this.setState({
      advancedSearch: !this.state.advancedSearch,
    });
  }

  renderDragLayer = (item, itemType) => {
    const {
      lineItems,
      selectedRowIds,
    } = this.props;

    if (itemType === DragnDrop.lineItem.uncategorised &&
      selectedRowIds.length > 0) {
      return (
        <PreviewRule
          leftAlignedText={selectedRowIds.length > 1  // eslint-disable-line
            ? `${this.props.selectedRows.length} items`
            : lineItems.find(x => x.id === selectedRowIds[0]).columns.Description ?
              lineItems.find(x => x.id === selectedRowIds[0]).columns.Description.value : ''}
          rightAlignedText={
            formatCurrency(this.props.selectedRowsTotalAmount, this.props.currencySymbol)
          }
        />
      );
    }

    return null;
  }

  renderStickyCell = (props) => {
    const {
      mode,
      isReadOnly,
      classes,
      lineItems,
    } = this.props;

    if (mode === modes.rules) {
      return null;
    }

    return (
      <div {...props}>
        <If condition={!isReadOnly}>
          <div>
            <Choose>
              <When condition={(mode !== modes.lineItems ||
                lineItemCanBeUncategorised(lineItems[props.index].columns.CategorizationType.value))
                && !lineItems[props.index].isApportionment}
              >
                <IconButton
                  onClick={() => this.handleIconClick(lineItems[props.index].id)}
                  className={classes.stickyIcon}
                  title={commonStrings.remove}
                >
                  <img
                    src={Close}
                    alt={commonStrings.remove}
                  />
                </IconButton>
              </When>
              <Otherwise>
                <div className={classes.stickyIconPlaceholder} />
              </Otherwise>
            </Choose>
            <If condition={mode === modes.lineItems}>
              <With
                icon={this.getIsFlagged(lineItems[props.index])
                  ? Flagged
                  : Unflagged}
                description={this.getIsFlagged(lineItems[props.index])
                  ? categorisationStrings.markLineItemsAsUnflagged(true)
                  : categorisationStrings.markLineItemsAsFlagged(true)}
              >
                <IconButton
                  onClick={() => this.handleFlagClick(lineItems[props.index])}
                  className={`${classes.stickyIcon} ${classes.flagIcon}`}
                  title={description}
                >
                  <img
                    src={icon}
                    alt={description}
                  />
                </IconButton>
              </With>
            </If>
          </div>
        </If>
      </div>
    );
  }

  renderHeaderCell = (props) => {
    return (
      <Choose>
        <When condition={props.index === this.props.categoryColumnIndex
          || (props.index === this.props.lineItemsColumnHeaders.length - 1
            && props.index !== this.props.amountColumnIndex)}
        >
          <SimpleHeader {...props} />
        </When>
        <Otherwise>
          <SortableHeader
            sortOrder={this.props.sortOrder}
            isSorting={this.props.sortedColIndex === props.index}
            onClick={this.props.onSort}
            {...props}
          />
        </Otherwise>
      </Choose>
    );
  }

  renderRow = (props) => {
    const { lineItems } = this.props;

    return (
      <BorderedRow
        {...props}
        canDrag={!this.props.isReadOnly && !lineItems[props.index].isApportionment}
        tooltip={getTooltip(lineItems[props.index].columns, this.props.currencySymbol)}
        onGetDragProps={() => this.getDragProps(props)}
        dragType={DragnDrop.lineItem.uncategorised}
        onBeginDrag={this.props.onBeginDrag}
        onEndDrag={this.props.onEndDrag}
        onSelectionChange={this.props.onRowSelectionChange}
        isSelected={this.props.selectedRowIds.includes(lineItems[props.index].id)}
      />
    );
  }

  renderCell = (props) => {
    const { classes, lineItems } = this.props;

    return (
      <Fragment key={props.key}>
        <Choose>
          <When condition={props.index === 2 &&
            lineItems[props.rowIndex].keyword}
          >
            <KeywordHighlighter
              {...props}
              searchWords={[lineItems[props.rowIndex].keyword]}
              textToHighlight={props.children}
            />
          </When>
          <Otherwise>
            <SimpleCell
              {...props}
              currencySymbol={this.props.currencySymbol}
              padLeft={props.index === 0}
              padRight={props.index === this.props.lineItemsColumnHeaders.length - 1}
              padding={10}
              spacing={10}
              formatAsCurrency={props.index === this.props.amountColumnIndex}
              childrenAfter={this.props.mode === modes.manual
                && props.index === this.props.descriptionColumnIndex && typeof props.children === 'string'
                && lineItems[props.rowIndex].categorisationReason &&
                <div
                  className={classes.categorisationReason}
                  title={strings.categorisationReason(lineItems[props.rowIndex]
                    .categorisationReason)}
                />
              }
            />
          </Otherwise>
        </Choose>
      </Fragment>
    );
  }

  renderSearch(classes, mode, searchTerm) {
    const { selectedRows } = this.props;
    const { advancedSearch } = this.state;

    return (
      <Card className={classes.contentHeader}>
        <Grid container className={classes.searchContainer} direction="row" spacing={8}>
          <If condition={mode === modes.lineItems}>
            <Grid item lg={6}>
              <Select
                id={strings.categoryTypeSelectPlaceholder}
                label={strings.categoryType}
                placeholder={strings.categoryTypeSelectPlaceholder}
                data={categoryTypes}
                onChange={this.setSelectedCategoryType}
                value={this.state.selectedCategoryType}
                colorScheme={constantsSelect.colorScheme.lightBlue}
              />
            </Grid>
          </If>
          <Grid item lg={mode === modes.ml ? 6 : 5}>
            <If condition={!advancedSearch}>
              <Search
                className={classes.searchBox}
                id={categorisationStrings.searchKeywords}
                placeholder={categorisationStrings.searchPlaceholder}
                onChange={this.props.setSearchTerm}
                onReset={this.props.resetSearch}
                onSearch={this.props.onSearch}
                value={searchTerm}
              />
            </If>
          </Grid>
          <If condition={mode === modes.lineItems}>
            <Grid container justify="flex-end">
              <button
                className={classes.advancedSearch}
                onClick={this.toggleAdvancedSearchClick}
              >
                {strings.advancedSearch}
              </button>
            </Grid>
            <If condition={advancedSearch}>
              <TagSearchFilter
                className={classes.tagSearchFilter}
                onSearch={this.handleAdvancedSearch}
              />
            </If>
          </If>
          <If condition={mode === modes.ml}>
            <Grid item className={classes.mlShowLogicWrapper} lg={5}>
              <div>
                <Button
                  className={
                    selectedRows.length === 0 || selectedRows.length >= maxMlLineItems
                      ? classes.mlShowLogicButtonDisabled : classes.mlShowLogicButton}
                  backgroundColor={constants.backgroundColor.dark}
                  onClick={this.runCategorisationRequest}
                  disabled={selectedRows.length === 0
                    || selectedRows.length >= maxMlLineItems}
                >
                  {mlStrings.showCategorisationLogic}
                </Button>
              </div>
              <div>
                {selectedRows.length >= maxMlLineItems &&
                  <div className={classes.toolTipBoxContainer}>
                    <div className={classes.toolTipBox}>
                      <div>
                        {commonStrings.maxNumberOfLineItems}
                      </div>
                    </div>
                  </div>}
              </div>
            </Grid>
          </If>
        </Grid>
      </Card>
    );
  }

  render() {
    const {
      projects,
      classes,
      lineItems,
      totalLineItemCount,
      lineItemsColumnHeaders,
      lineItemsHasMore,
      lineItemsLoadMore,
      lineItemsLoading,
      amountColumnIndex,
      descriptionColumnIndex,
      searchTerm,
      sortOrder,
      processing,
      periodId,
      sortedColIndex,
      mode,
      isReadOnly,
      theme,
      isSearchTermPresent,
      currencySymbol,
      tagFilters,
      periodTags,
      showCreateRuleOverlay,
      rule,
      note,
      selectedRows,
    } = this.props;

    return (
      <Blur
        blur={!periodId || projects.treePicker.loading || processing}
        lightBackground
        displayLoader
      >
        <div className={classes.root}>
          <InspectRuleOverlay currencySymbol={currencySymbol} isReadOnly />
          <If condition={!isReadOnly}>
            <GenericDragLayer disableIE={false}>
              {this.renderDragLayer}
            </GenericDragLayer>
          </If>
          <div>
            <CreateRuleOverlay
              showCreateRuleOverlay={showCreateRuleOverlay}
              selectedRows={selectedRows}
            />
          </div>
          <Grid container className={classes.tabGrid} spacing={0} alignContent="stretch">
            <Grid container item direction="row" xs={12} className={classes.headerGrid} spacing={0} alignContent="stretch">
              <Grid item xs={6} className={classes.mainTitleGrid}>
                <p className={classes.mainTitle}>{this.getMainTitle(currencySymbol)}</p>
                <p className={classes.descriptionTitle}>{this.getDescription()}</p>
              </Grid>
              <Grid container item direction="row" xs={6} spacing={0} justify="flex-end" alignContent="stretch">
                {this.renderSearch(classes, mode, searchTerm)}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.grid}>
                <div className={classes.categories}>
                  <div className={classes.categoriesListWrapper}>
                    <CategoryList
                      currencySymbol={currencySymbol}
                      location={categorisationLocations.lineItems}
                      categoryType={this.state.categoryType}
                      editable={false}
                    />
                  </div>
                </div>
                <div className={classes.listItemsSection}>
                  <div className={classes.listItems}>
                    <If condition={!lineItemsLoading
                      && isSearchTermPresent
                      && mode === modes.lineItems
                      && lineItems.length
                      && !isReadOnly}
                    >
                      <RuleFilterDragLayer
                        allowDrag={!isReadOnly}
                        searchTerm={searchTerm}
                        onBeginDrag={this.setRuleFilterCategoryType}
                        onEndDrag={this.clearRuleFilterCategoryType}
                        tagFilters={tagFilters}
                        periodTags={periodTags}
                      />
                      <Paper className={classes.paperRoot}>
                        <div className={classes.createRulePane}>
                          <button
                            className={classes.createRuleButton}
                            onClick={this.openCreateRuleOverlay}
                          >
                            {strings.createRule}
                          </button>
                          <If condition={rule !== ''}>
                            <CreateRuleDragLayer
                              allowDrag={!isReadOnly}
                              rule={rule}
                              note={note}
                              onBeginDrag={this.setRuleFilterCategoryType}
                              onEndDrag={this.clearRuleFilterCategoryType}
                            />
                          </If>
                        </div>
                      </Paper>
                    </If>
                    <p className={classes.totalLineItemCount}>
                      {!lineItemsLoading && lineItems.length &&
                        categorisationStrings.totalLineItems(totalLineItemCount)}
                    </p>
                    <Paper className={classes.content}>
                      <If condition={!lineItemsLoading && !lineItems.length}>
                        <div className={classes.noSearchResults}>
                          <p className={classes.simpleMessage}>
                            {isSearchTermPresent
                              ? categorisationStrings.noSearchResultsMessage
                              : categorisationStrings.noItemsToDisplayMessage}
                          </p>
                        </div>
                      </If>
                      <DataGrid
                        recomputeGridSizeKey={`${amountColumnIndex}_${descriptionColumnIndex}`}
                        className={classes.grid}
                        columns={lineItemsColumnHeaders}
                        rows={lineItems}
                        getCellValue={this.getCellValue}
                        hasNextPage={lineItemsHasMore}
                        isNextPageLoading={lineItemsLoading}
                        loadNextPage={lineItemsLoadMore}
                        getWidth={this.getWidth}
                        rowMargin={10}
                        childRowHeight={30}
                        parentRowHeight={30}
                        sortedByKey={`${sortOrder}_${sortedColIndex}`}
                        showDefaultNoDataMessage={false}
                        renderHeaderCell={this.renderHeaderCell}
                        renderRow={this.renderRow}
                        renderCell={this.renderCell}
                        renderStickyCell={this.renderStickyCell}
                        stickyColumnWidth={mode === modes.lineItems ? 80 : 40}
                        stickyBackgroundColor={theme.palette.primary.white}
                        noDataMessage={commonStrings.noLineItems}
                      />
                    </Paper>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Blur>
    );
  }
}

LineItemsCategorisationReview.propTypes = {
  processing: PropTypes.bool.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  amountColumnIndex: PropTypes.number.isRequired,
  confidenceColumnIndex: PropTypes.number.isRequired,
  categoryColumnIndex: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  sortedColIndex: PropTypes.number.isRequired,
  sortTagId: PropTypes.number.isRequired,
  setOptions: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalLineItemCount: PropTypes.number.isRequired,
  lineItemsColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineItemsColumnHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRowsTotalAmount: PropTypes.number.isRequired,
  selectedRowIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onBeginDrag: PropTypes.func.isRequired,
  onEndDrag: PropTypes.func.isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  periodId: PropTypes.number.isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        background: PropTypes.string,
      }),
    }),
  }).isRequired,
  category: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.shape({
      selectedGroupId: PropTypes.number,
      selectedEntityId: PropTypes.number,
    }),
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  tagFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  periodTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  showCreateRuleOverlay: PropTypes.bool.isRequired,
  rule: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    processing: processingSelector(state),
    period: state.periods.period,
    periodId: state.periods.period.periodId,
    categoryRules: state.rules.categoryRules.rules,
    category: state.categorisation.reviewCategory.category,
    isReadOnly: isReadOnlySelector(state),
    currencySymbol: currencySymbolSelector(state),
    tagFilters: state.categorisation.lineItems.tagFilters,
    periodTags: periodSelector(state).data.tags,
    showCreateRuleOverlay: state.rules.createRule.open,
    rule: state.rules.createRule.rule,
    note: state.rules.createRule.note,
  };
}

export default compose(
  withLineItems(),
  withStyles(styles),
  connect(mapStateToProps),
  withTheme(),
)(LineItemsCategorisationReview);
