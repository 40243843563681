import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';

export const GET_LINE_ITEM_METADATA = 'LINEITEM_METADATA/GET_LINE_ITEM_METADATA';
export const GET_LINE_ITEM_METADATA_SUCCESS = 'LINEITEM_METADATA/GET_LINE_ITEM_METADATA_SUCCESS';
export const RESET_LINE_ITEM_METADATA = 'LINEITEM_METADATA/RESET_LINE_ITEM_METADATA';
export const SET_REQUIRES_SEARCH = 'LINEITEM_METADATA/SET_REQUIRES_SEARCH';

export function getLineItemMetadata(
  periodId,
  categorizationType,
  categoryId,
) {
  return {
    type: GET_LINE_ITEM_METADATA,
    loading: true,
    amount: 0,
    count: 0,
    categorizationType: categorizationType,
    payload: {
      periodId: periodId,
      categoryId: categoryId,
      categorizationType: categorizationType ===
        categorizationTypes.allExceptContras ? categorizationTypes.manual : categorizationType,
      fetchAllNoContras: categorizationType === categorizationTypes.allExceptContras,
    },
  };
}

export function getLineItemMetadataSuccess(amount, count, categorizationType) {
  return {
    type: GET_LINE_ITEM_METADATA_SUCCESS,
    loading: false,
    amount: amount,
    count: count,
    categorizationType: categorizationType,
  };
}

export function resetLineItemMetadata() {
  return {
    type: RESET_LINE_ITEM_METADATA,
  };
}

export function setRequiresSearch() {
  return {
    type: SET_REQUIRES_SEARCH,
  };
}
