import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  withStyles,
  withTheme,
} from '@material-ui/core';
import TreePickerNode from 'Components/Shared/TreePicker/TreePickerNode';
import Search from 'Components/Shared/Inputs/Search';
import { levels } from 'Constants/EntityLevels';
import AuthorisedComponent, { constants } from 'Components/Shared/AuthorisedComponent/AuthorisedComponent';
import { creatingNewGroup, editGroup } from 'Store/Areas/Setup/Admin/GroupActions';
import { creatingNewEntity, editEntity } from 'Store/Areas/Setup/Admin/EntityActions';
import { creatingNewProject, editProject } from 'Store/Areas/Setup/Admin/ProjectActions';
import { strings } from 'Constants/Setup/Admin/Strings';
import { taxContextFilter } from 'Constants/Setup/Admin/TaxContextFilter';
import Select, { constants as constantsSelect } from 'Components/Shared/Selects/Select';
import { strings as treePickerStrings } from 'Constants/TreePicker/Strings';
import {
  resetGroup,
  resetProject,
  selectNode,
  resetEntity,
  editNode,
  getAdminTreePickerData,
} from 'Store/Areas/Setup/Admin/AdminTreePickerActions';
import {
  adminTreePickerDataSelector,
  taxContextFilterSelector,
  emailFilterSelector,
} from 'Store/Areas/Setup/Admin/SetupAdminSelectors';
import styles from './AdminTreePicker.styles';

class AdminTreePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      taxContextFilterValue: props.taxContextFilterId === undefined ? 0 : props.taxContextFilterId,
      emailFilter: props.emailFilter === undefined ? '' : props.emailFilter,
    };
  }

  componentDidMount() {
    const { adminTreePicker, permissions } = this.props;
    if (adminTreePicker.data &&
      adminTreePicker.data.length > 0 &&
      !adminTreePicker.editingItem &&
      permissions.isSysAdmin) {
      this.onSidebarClick(0, adminTreePicker.data[0]);
    }
  }

  componentDidUpdate(prevProps) {
    const { taxContextFilterId, emailFilter } = this.props;

    if (taxContextFilterId !== 0 || prevProps.taxContextFilterId !== taxContextFilterId) {
      this.updateTaxContextFilterDropdown(taxContextFilterId);
    }

    if (prevProps.emailFilter !== emailFilter) {
      this.updateEmailFilterSearch();
    }
  }

  updateTaxContextFilterDropdown(taxContextFilterId) {
    this.setState({
      taxContextFilterValue: taxContextFilterId,
    });
  }

  updateEmailFilterSearch() {
    this.setState({
      emailFilter: this.props.emailFilter,
    });
  }

  handleSetSearchTerm = (searchTerm) => {
    this.setState({
      emailFilter: searchTerm,
    });
  }

  handleResetSearch = () => {
    this.setState({
      emailFilter: '',
    });
  }

  handleOnSearch = () => {
    const { dispatch } = this.props;
    const { taxContextFilterValue, emailFilter } = this.state;
    dispatch(getAdminTreePickerData(false, taxContextFilterValue, emailFilter));
  }

  onTreeItemClick(groupId, entityId, projectId, selectedItem, selectedItemLevel) {
    const {
      permissions,
    } = this.props;
    if (selectedItemLevel === levels.group &&
      groupId === this.props.adminTreePicker.selectedGroupId) {
      this.props.dispatch(resetGroup());
    } else if (selectedItemLevel === levels.entity &&
      entityId === this.props.adminTreePicker.selectedEntityId) {
      this.props.dispatch(resetEntity(groupId));
    } else if (selectedItemLevel === levels.project &&
      projectId === this.props.adminTreePicker.selectedProjectId) {
      this.props.dispatch(resetProject(groupId, entityId));
    } else {
      this.props.dispatch(selectNode(groupId, entityId, projectId, selectedItemLevel));
    }

    if (selectedItemLevel !== levels.group ||
      permissions.isSysAdmin) {
      this.onSidebarClick(selectedItemLevel, selectedItem);
    }
  }

  onSidebarClick(itemLevel, item) {
    const { emailFilter } = this.state;
    this.props.dispatch(editNode(itemLevel, item, emailFilter));
  }

  onTaxContextFilterSelection = (value) => {
    const { dispatch } = this.props;
    const { emailFilter } = this.state;
    this.updateTaxContextFilterDropdown(value);
    dispatch(getAdminTreePickerData(false, value, emailFilter));
  }

  editProject(selectedProject, selectedGroup, selectedEntity) {
    this.props.dispatch(editProject(selectedProject.id, selectedGroup, selectedEntity));
  }

  generateKey(nodeLevel, id) {
    return `${nodeLevel}_${id}`;
  }

  createEntity(group) {
    this.props.dispatch(creatingNewEntity(group));
  }

  editEntity(selectedEntity, selectedGroup) {
    this.props.dispatch(editEntity(selectedEntity.id, selectedGroup));
  }

  createGroup() {
    this.props.dispatch(creatingNewGroup());
  }

  editGroup(groupId) {
    this.props.dispatch(editGroup(groupId));
  }

  createProject(group, entity) {
    this.props.dispatch(creatingNewProject(group, entity));
  }

  render() {
    const {
      classes,
      adminTreePicker,
      permissions,
    } = this.props;
    const { taxContextFilterValue, emailFilter } = this.state;
    const selection = {
      selectedItem: adminTreePicker.editingItem,
      selectedItemLevel: adminTreePicker.editingItemLevel,
    };

    return (
      <div className={classes.root}>
        <React.Fragment>
          <div className={classes.adminFilterAndSearchContainer}>
            <AuthorisedComponent
              requiredPermissions={constants.permissionLevels.requireSysAdmin}
            >
              <Select
                id="taxcontextfilter"
                preventPlaceholder
                onChange={this.onTaxContextFilterSelection}
                data={taxContextFilter}
                value={taxContextFilterValue}
                colorScheme={constantsSelect.colorScheme.lightBlue}
                className={classes.taxContextFilterSelect}
              />
            </AuthorisedComponent>
            <div className={classes.searchBoxText}>
              <Search
                className={classes.searchBox}
                id={strings.searchEmail}
                type="email"
                placeholder={strings.searchEmailPlaceholder}
                onChange={this.handleSetSearchTerm}
                onReset={this.handleResetSearch}
                onSearch={this.handleOnSearch}
                value={emailFilter}
              />
            </div>
          </div>
          <div className={classes.text}>{strings.sidebarTitle}</div>

          {adminTreePicker.data !== null && adminTreePicker.data.map((group) => {
            return (
              <TreePickerNode
                key={this.generateKey(levels.group, group.id)}
                title={group.name}
                nodeLevel={levels.group}
                onNodeClick={() => this.onTreeItemClick(group.id, -1, -1, group, levels.group)}
                expanded={adminTreePicker.selectedGroupId === group.id}
                expandable
                onHoverClick={permissions.isSysAdmin ? () => this.editGroup(group.id) : () => { }}
                hoverText={permissions.isSysAdmin ? 'Edit' : ''}
                selected={selection.selectedItemLevel === levels.group &&
                  selection.selectedItem &&
                  selection.selectedItem.id === group.id}
              >
                {group.entities && (permissions.groupAdmins.includes(group.id)
                  || permissions.groupsAccessibleForEntityAdmins.includes(group.id)
                  || (permissions.isSysAdmin && emailFilter !== '' && group.entities.length > 0))
                  &&
                  <AuthorisedComponent
                    requiredPermissions={constants.permissionLevels.requireEntityAdmin}
                  >
                    {group.entities.map((entity) => {
                      if (permissions.isEntityAdmin && !permissions.isGroupAdmin &&
                        !permissions.entityAdmins.includes(entity.id)) {
                        return () => { };
                      }
                      return (
                        <TreePickerNode
                          key={this.generateKey(levels.entity, entity.id)}
                          title={entity.name}
                          nodeLevel={levels.entity}
                          onNodeClick={() =>
                            this.onTreeItemClick(group.id, entity.id, -1, entity, levels.entity)}
                          expanded={adminTreePicker.selectedEntityId === entity.id}
                          expandable
                          onHoverClick={permissions.isEntityAdmin ?
                            () => { } : () => this.editEntity(entity, group)}
                          hoverText={permissions.isEntityAdmin ? '' : treePickerStrings.editLinkHoverText}
                          selected={selection.selectedItemLevel === levels.entity &&
                            selection.selectedItem &&
                            selection.selectedItem.id === entity.id}
                        >
                          {entity.projects &&
                            entity.projects.map((project) => {
                              return (
                                <TreePickerNode
                                  key={this.generateKey(levels.project, project.id)}
                                  title={project.name}
                                  nodeLevel={levels.project}
                                  onNodeClick={() =>
                                    this.onTreeItemClick(
                                      group.id,
                                      entity.id,
                                      project.id,
                                      project,
                                      levels.project,
                                    )}
                                  onHoverClick={() => this.editProject(project, group, entity)}
                                  hoverText="Edit"
                                  selected={selection.selectedItemLevel === levels.project &&
                                    selection.selectedItem &&
                                    selection.selectedItem.id === project.id}
                                />
                              );
                            })}
                          <TreePickerNode
                            title={treePickerStrings.addNewProjectNodeText}
                            nodeLevel={levels.project}
                            add
                            onNodeClick={() => this.createProject(group, entity)}
                          />
                        </TreePickerNode>

                      );
                    })}
                    {(permissions.isSysAdmin ||
                    (permissions.isGroupAdmin && permissions.groupAdmins.includes(group.id))) &&
                      <TreePickerNode
                        title={treePickerStrings.addNewEntityNodeText}
                        nodeLevel={levels.entity}
                        add
                        onNodeClick={() => this.createEntity(group)}
                      />}

                  </AuthorisedComponent>
                }
              </TreePickerNode>
            );
          })}
          <AuthorisedComponent
            requiredPermissions={constants.permissionLevels.requireSysAdmin}
          >
            <TreePickerNode
              title={treePickerStrings.addNewGroupNodeText}
              nodeLevel={levels.group}
              add
              onNodeClick={() => this.createGroup()}
            />
          </AuthorisedComponent>

        </React.Fragment>
      </div>
    );
  }
}

AdminTreePicker.defaultProps = {
  taxContextFilterId: 0,
  emailFilter: '',
};

AdminTreePicker.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  adminTreePicker: PropTypes.shape({
    selectedGroupId: PropTypes.number,
    selectedEntityId: PropTypes.number,
    selectedProjectId: PropTypes.number,
    selectedItemLevel: PropTypes.number,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  permissions: PropTypes.shape({
    name: PropTypes.string,
    isSysAdmin: PropTypes.bool,
    emailAddress: PropTypes.string,
  }).isRequired,
  taxContextFilterId: PropTypes.number,
  emailFilter: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    adminTreePicker: adminTreePickerDataSelector(state),
    permissions: state.user.permissions.data,
    taxContextFilterId: taxContextFilterSelector(state),
    emailFilter: emailFilterSelector(state),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(AdminTreePicker);
