const styles = theme => ({
  tableContainer: {
    margin: 15,
    fontSize: 12,
  },
  headerCell: {
    color: theme.palette.primary.darkText,
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
  },
  cell: {
    borderBottom: `1px solid ${theme.palette.colors.darkGrey}`,
  },
  tableTitle: {
    fontSize: theme.constants.fontSizes.large,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  downloadTemplate: {
    fontWeight: 'bold',
    fontSize: 12,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
    outline: 'none',
    padding: 0,
  },
});

export default styles;
