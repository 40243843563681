import { DataTypes } from 'Constants/Upload/DataTypes';
import { TagTypes } from 'Constants/Categorization/Tags';

export function checkDataType(tagType, expectedDataType) {
  let tagDataType = DataTypes.Text;

  switch (tagType) {
    case TagTypes.Numeric:
      tagDataType = DataTypes.Number;
      break;
    case TagTypes.Date:
      tagDataType = DataTypes.Date;
      break;
    default:
      tagDataType = DataTypes.Text;
  }

  return expectedDataType.includes(tagDataType);
}
