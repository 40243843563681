/* eslint-disable jsx-control-statements/jsx-jcs-no-undef */
import React from 'react';
import { withStyles, withTheme, Table, TableCell, Paper, Input, TableRow, TableHead, Card, TableBody } from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import StickyTable from 'Components/Shared/Table/StickyTable';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import { periodIdSelector, isReadOnlySelector } from 'Store/Areas/Period/PeriodSelectors';
import { apportionablesResourceSelector } from 'Store/Areas/Apportionables/ApportionablesSelectors';
import { updateApportionmentDefinition } from 'Store/Areas/Apportionables/ApportionablesActions';
import Checkbox from 'Components/Shared/Checkbox/Checkbox';
import MandatoryAsterisk from 'Components/Shared/MandatoryAsterisk/MandatoryAsterisk';
import styles from './ApportionableCondition.styles';
import { apportionmentStrings } from './ApportionablesStrings';


class ApportionableCondition extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      chipConditions: [],
      apportionableCondition: '',
      requestEntryOfApportionableCondition: false,
      checked: {},
      tagInherited: {},
      priorityValue: '',
      amountValue: '',
      requestEntryOfPriority: false,
      requestEntryofCondition: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { tagValues, tags } = this.props;
    if (tagValues && tags) {
      if (prevProps.tagValues !== tagValues) {
        if (tagValues) {
          tags.map(x =>
            this.addDefaultChip(tagValues, x));
        }
        this.getDefaultValues();
      }
    }
  }

  addDefaultChip(tagValues, x) {
    if (tagValues.some(f => f.tagName === x.name)) {
      const tagVal = this.convertChipArray(tagValues.find(f => f.tagName === x.name).tagValue);
      this.addChip(tagVal, x);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.priority !== this.state.priorityValue) {
      this.setState({ priorityValue: nextProps.priority });
    }
    if (nextProps.amount !== this.state.amountValue) {
      this.setState({ amountValue: nextProps.amount === 0 ? '' : nextProps.amount });
    }
    if (nextProps.tagValues !== this.state.chipConditions) {
      const currentProps = nextProps;
      const newChipCondition = currentProps.tags.map(x => (
        {
          tag: {
            tagName: x.name,
            tagType: x.type,
          },
          chips: currentProps.tagValues.some(y => y.tagName === x.name) ?
            this.convertChipArray(currentProps.tagValues.find(y => y.tagName === x.name).tagValue)
            : [],
        }));

      const newCondition = this.getCondition(newChipCondition);

      this.setState({
        chipConditions: currentProps.tagValues.length === 0 ? [] : newChipCondition,
        apportionableCondition: currentProps.tagValues.length === 0 ? '' : newCondition,
        requestEntryOfApportionableCondition: false,
      });
    }
  }

  convertChipArray(value) {
    const isArray = Array.isArray(value);
    return isArray ? value :
      [value];
  }
  getDefaultValues() {
    const { tagValues } = this.props;
    const checked = {};
    const tagInherited = {};
    const totalCount = tagValues.length;
    for (let i = 0; i < totalCount; i += 1) {
      checked[`Inherit_${i}`] = false;
      tagInherited[tagValues[i].tagName] = false;
    }
    this.setState({
      checked,
      tagInherited,
    });
  }

  getCondition(newChipCondition) {
    let { chipConditions } = this.state;
    if (newChipCondition) {
      chipConditions = newChipCondition;
    }
    if (chipConditions.every(x => x.chips.length === 0)) {
      return '';
    }
    if (chipConditions != null && chipConditions.length > 0) {
      return chipConditions.filter(c => c.chips.length > 0).map(x => `[${x.tag.tagName}] EQUALS (${x.tag.tagType.includes('Text') ? '"' : ''}${x.tag.tagType.includes('Text') ? x.chips.map(c => `${c}`).join('","') : x.chips.map(c => `${c}`).join(',')}${x.tag.tagType.includes('Text') ? '"' : ''})`).join(' AND ');
    }
    return '';
  }

  addChip = (chip, tag) => {
    const { chipConditions } = this.state;
    if (chipConditions.some(x => x.tag.tagName === tag.name)) {
      const requiredTag = chipConditions.find(x => x.tag.tagName === tag.name);
      if (typeof chip === 'string') {
        requiredTag.chips.push(chip);
      } else {
        requiredTag.chips = chip;
      }
      if (requiredTag.chips.length === 0) {
        const index = chipConditions.indexOf(requiredTag);
        chipConditions.splice(index, 1);
      }
    } else {
      const newChipCondition = {
        tag: {
          tagName: tag.name,
          tagType: tag.type,
        },
        chips: typeof chip === 'string' ? [chip] : chip,
      };
      chipConditions.push(newChipCondition);
    }

    const newCondition = this.getCondition();
    this.setState({
      apportionableCondition: newCondition,
      requestEntryOfApportionableCondition: false,
    });
  }

  removeChip = (chip, tag) => {
    const { chipConditions } = this.state;
    const { apportionables } = this.props;

    const chipToRemove = chipConditions.find(x => x.tag.tagName === tag.name);

    if (chipToRemove && apportionables.selectedRowIds.length >= 1) {
      const chipIndex = chipToRemove.chips.indexOf(chip);
      if (chipIndex !== -1) {
        chipToRemove.chips.splice(chipIndex, 1);
      }
      if (chipToRemove.chips.length === 0) {
        const index = chipConditions.indexOf(chipToRemove);
        chipConditions.splice(index, 1);
      }
      const newCondition = this.getCondition();
      this.setState({
        apportionableCondition: newCondition,
        chipConditions: chipConditions,
      });
    }
  }

  onTagInheritChange = (e, id, tag) => {
    this.setCheckboxValue(id, e, tag);
  }

  setCheckboxValue(id, value, tag) {
    const { checked, tagInherited } = this.state;
    const { apportionables, tagValues } = this.props;

    checked[id] = value;
    tagInherited[tag.name] = value;
    this.setState({
      checked: { ...checked },
      tagInherited: { ...tagInherited },
    });
    const tagValue = tagValues.some(t => t.tagName === tag.name) ?
      this.convertChipArray(tagValues.find(t => t.tagName === tag.name).tagValue) : [];

    if (value === true) {
      if (apportionables.selectedRowIds.length === 1 && tagValue.length > 0) {
        this.addChip(tagValue, tag);
      } else {
        this.addChip([apportionmentStrings.inheritedFromTagValueText], tag);
      }
    } else {
      this.removeChip(apportionmentStrings.inheritedFromTagValueText, tag);
      this.addChip(tagValue, tag);
    }
  }

  handlePriorityChange = ({ target: { value } }) => {
    const priorityValue = value === '' ? 0 : parseInt(value, 0);
    if (priorityValue >= 0 && priorityValue <= 500) {
      this.setState({
        priorityValue: priorityValue === 0 ? '' : priorityValue,
      });
    }
  }

  handleAmountChange = ({ target: { value } }) => {
    const amountValue = value === '' ? 0 : value;
    if (this.isNumber(amountValue) || amountValue === '-') {
      this.setState({
        amountValue: amountValue === 0 ? '' : amountValue,
      });
      this.addChip([amountValue], { name: 'Amount', type: 'Numeric' });
      if (amountValue === 0) {
        this.removeChip(amountValue, { name: 'Amount', type: 'Numeric' });
      }
    }
  }

  isNumber = value => !Number.isNaN(Number(value));

  handleSave = () => {
    const {
      chipConditions,
      apportionableCondition,
      tagInherited,
      priorityValue,
    } = this.state;

    const tagDictionary = JSON.stringify(Object.assign(
      {},
      ...chipConditions.filter(t => t.chips.length > 0).map(x => (
        {
          [x.tag.tagName]: x.chips,
        })),
    ));

    if (chipConditions.length <= 0 || tagDictionary.length <= 2) {
      this.setState({
        requestEntryofCondition: true,
      });
    } else if (priorityValue === '' || priorityValue === 0) {
      this.setState({
        requestEntryOfPriority: true,
        requestEntryofCondition: false,
      });
    } else {
      const {
        dispatch,
        periodId,
        keywordRules,
        amount,
        apportionables,
        tags,
      } = this.props;
      const tag = tags.map(x => (
        {
          tagName: x.name,
          type: x.type,
          tagid: x.id,
        }));
      dispatch(updateApportionmentDefinition(
        periodId,
        keywordRules,
        apportionables.selectedRowIds,
        amount,
        apportionableCondition,
        tagDictionary,
        priorityValue,
        tagInherited,
        tag,
      ));
      const chipCondition = [];
      this.setState({
        apportionableCondition: '',
        chipConditions: chipCondition,
        requestEntryOfPriority: false,
        requestEntryofCondition: false,
      });
    }
  }

  render() {
    const {
      classes,
      tags,
      keywordRules,
      tagValues,
      lineItemIds,
      isReadOnly,
    } = this.props;

    const {
      apportionableCondition,
      requestEntryOfApportionableCondition,
      checked,
      chipConditions,
      amountValue,
      priorityValue,
      requestEntryOfPriority,
      requestEntryofCondition,
    } = this.state;

    const invalidData = lineItemIds === null || tagValues === null || lineItemIds.length === 0;
    const disable = invalidData;
    return (
      <Paper className={classes.paperRoot}>
        <Card className={classes.card}>
          <div className={classes.header}>
            <b>{apportionmentStrings.apportionable}</b>
          </div>
          <span className={classes.keywordRule}>{keywordRules}</span>
        </Card>
        <Card className={classes.keywordcard}>
          <div className={classes.header}>
            <b>{apportionmentStrings.apportionmentCondition}</b>
          </div>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                <TableCell
                  className={`${classes.headerText} ${classes.headerTableCell} 
                      ${classes.descriptionCellWidth}`}
                  key="TagsLabel"
                  align="center"
                >
                  Tag
                </TableCell>
                <TableCell
                  className={`${classes.headerText} ${classes.headerTableCell} 
                      ${classes.descriptionCellWidth}`}
                  key="InheritLabel"
                  align="center"
                >
                  Inherit
                </TableCell>
                <TableCell
                  className={`${classes.headerText} ${classes.headerTableCell} 
                      ${classes.descriptionCellWidth}`}
                  key="ValueLabel"
                  align="center"
                >
                  Value
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tags.map((tag, rowIndex) => {
                const rowKey = `${tag.name}_${rowIndex}`;
                const userInputChips = chipConditions && chipConditions.length > 0 &&
                  chipConditions.some(y => tag.name === y.tag.tagName) ?
                  chipConditions.find(y => tag.name === y.tag.tagName).chips : [];
                const inheritId = `Inherit_${rowIndex}`;
                const tagValue = checked[inheritId] ?
                  [apportionmentStrings.inheritedFromTagValueText] : userInputChips;
                return (
                  <TableRow key={rowKey}>
                    <TableCell className={classes.tableCell} key={`${rowKey}_${'tagname'}`}>
                      {tag.name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Checkbox
                        className={classes.Checkbox}
                        id={inheritId}
                        onChange={e => this.onTagInheritChange(e, inheritId, tag)}
                        checked={checked[inheritId]}
                        disabled={disable || isReadOnly}
                      />
                    </TableCell>
                    <TableCell className={classes.tableCell} key={`${rowKey}_${'value'}`}>
                      <Choose>
                        <When condition={tag.name === 'Amount' && tag.type === 'Numeric'}>
                          <Input
                            value={amountValue}
                            className={classes.amountInput}
                            classes={{ input: classes.textInput }}
                            onChange={this.handleAmountChange}
                            disableUnderline
                            disabled={isReadOnly}
                          />
                        </When>
                        <Otherwise>
                          <ChipInput
                            value={tagValue}
                            onAdd={value => this.addChip(value, tag)}
                            onDelete={value => this.removeChip(value, tag)}
                            className={classes.input}
                            clearInputValueOnChange
                            disableUnderline
                            classes={{ chip: classes.customChip }}
                            disabled={checked[inheritId] || isReadOnly}
                            blurBehavior="clear"
                          />
                        </Otherwise>
                      </Choose>
                    </TableCell>
                  </TableRow>);
              })}
            </TableBody>
          </Table>
        </Card>
        <Choose>
          <When
            condition={!requestEntryOfApportionableCondition && lineItemIds !== null
              && apportionableCondition}
          >
            <Card className={classes.card}>
              <span className={classes.definition}>{apportionableCondition}</span>
            </Card>
          </When>
        </Choose>
        <Card className={classes.card}>
          <StickyTable bodyHeight={40}>
            <TableBody>
              <TableRow key="priority">
                <TableCell className={classes.sequenceText} key="priorityLabel">
                  {apportionmentStrings.apportionmentModalPriority}
                  <MandatoryAsterisk />
                  <Input
                    value={priorityValue === 0 ? '' : priorityValue}
                    className={classes.priorityInput}
                    classes={{ input: classes.textInput }}
                    type="number"
                    disableUnderline
                    onChange={this.handlePriorityChange}
                    disabled={isReadOnly}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </StickyTable>
          <div align="left" className={classes.marginTop}>
            <Choose>
              <When condition={requestEntryofCondition}>
                <div className={classes.note}>
                  {apportionmentStrings.entertag}
                </div>
              </When>
              <When condition={requestEntryOfPriority}>
                <div className={classes.note}>
                  {apportionmentStrings.enterPriority}
                </div>
              </When>
            </Choose>
          </div>
          <div align="right" className={classes.marginTop}>
            <Button
              disableRipple
              backgroundColor={constants.backgroundColor.main}
              height={constants.height.regular}
              className={classes.Button}
              onClick={this.handleSave}
              disabled={isReadOnly}
            >
              {apportionmentStrings.saveDefinition}
            </Button>
          </div>
        </Card>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  const lineItemIds = state.categorisation.apportionables.lineItems.length > 0 ?
    state.categorisation.apportionables.lineItems.map(({ lineItemId }) => lineItemId) : [];

  const firstElement = {};
  const firstElementwithDefinitionId = state.categorisation.apportionables.selectedRows
    .find(lineItem => lineItem.apportionmentDefinitionId > 0);

  if (firstElementwithDefinitionId) {
    const tags = firstElementwithDefinitionId.apportionmentTagAndValues
      .find(tag => tag.tagName === 'Amount');
    const tagValues = firstElementwithDefinitionId.apportionmentTagAndValues ?
      firstElementwithDefinitionId.apportionmentTagAndValues : [];
    const amount = tags && tags.tagValue.length > 0 ? tags.tagValue : 0;

    firstElement.apportionmentDefinitionId = firstElementwithDefinitionId.apportionmentDefinitionId;
    firstElement.apportionableRuleText = firstElementwithDefinitionId.apportionableRuleText;
    firstElement.tagValues = Array.isArray(tagValues) ? tagValues : [tagValues];
    firstElement.priority = firstElementwithDefinitionId.priority;
    if (tags && tags.tagValue.length > 0) {
      firstElement.amount = Array.isArray(amount) ? +amount[0] : +[amount][0];
    }
  }

  if (!firstElement.apportionmentDefinitionId) {
    const firstElementwithRule = state.categorisation.apportionables.selectedRows[0];
    if (firstElementwithRule) {
      firstElement.apportionableRuleText = firstElementwithRule.apportionableRuleText;
    } else {
      firstElement.apportionableRuleText = '';
    }
    firstElement.apportionmentDefinitionId = 0;
    firstElement.tagValues = [];
    firstElement.priority = 0;
  }


  return {
    open: apportionablesResourceSelector(state),
    periodId: periodIdSelector(state),
    tags: state.periods.period.data.tags.filter(x => x.type !== 'NotMapped'),
    keywordRules: firstElement.apportionableRuleText ? firstElement.apportionableRuleText : '',
    tagValues: firstElement.tagValues ? firstElement.tagValues.filter(x => !x.tagName.startsWith('SYSTEM_UNMAPPED_TAG_Main_')) : [],
    lineItemIds: lineItemIds,
    amount: firstElement.amount,
    apportionables: state.categorisation.apportionables,
    priority: firstElement.priority,
    isReadOnly: isReadOnlySelector(state),
  };
};

ApportionableCondition.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  periodId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  keywordRules: PropTypes.string.isRequired,
  tagValues: PropTypes.arrayOf(PropTypes.shape({
    tagName: PropTypes.string.isRequired,
    tagValue: PropTypes.node.isRequired,
  })),
  lineItemIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  amount: PropTypes.number,
  apportionables: PropTypes.shape({
    lineItems: PropTypes.array.isRequired,
    activeRowId: PropTypes.number,
    selectedRows: PropTypes.array,
    selectedRowIds: PropTypes.array,
    selectedRowsTotalAmount: PropTypes.number,
  }).isRequired,
  priority: PropTypes.number,
  isReadOnly: PropTypes.bool.isRequired,
};

ApportionableCondition.defaultProps = {
  amount: 0,
  tagValues: null,
  priority: 0,
};

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(ApportionableCondition);
