const styles = theme => ({
  table: {
    marginTop: 20,
  },
  text: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  checkbox: {
    padding: 0,
    width: 48,
  },
  header: {
    border: 'none',
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.regular,
    color: '#666666',
  },
  enabled: {
    color: '#666666',
    background: '#ededed',
  },
  disabled: {
    color: theme.palette.primary.main,
    background: 'transparent',
  },
});

export default styles;
