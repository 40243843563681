import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { Formik } from 'formik';
import Yup from 'yup';
import Modal from 'Components/Shared/Modal/StyledModal';
import WrappedInput from 'Components/Shared/Inputs/Input';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import styles from './AddUsersModal.styles';

class AddUsersModal extends React.PureComponent {
  render() {
    const {
      classes,
      open,
      onCancel,
      errorMessage,
      loading,
    } = this.props;

    return (
      <Modal open={open} className={classes.root} isSubmitting={loading}>
        <Formik
          initialValues={{
            firstname: '',
            lastname: '',
            email: '',
          }}
          validationSchema={Yup.object().shape({
            firstname: Yup.string()
              .required('First Name is required'),
            lastname: Yup.string()
              .required('Last Name is required'),
            email: Yup.string()
              .email('Email address entered is not valid')
              .required('Email is required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            this.props.onAccept(values.email, values.firstname, values.lastname);
          }}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            isValid,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.content}>
                <div className={classes.title}>{'Please enter the user\'s first name'}</div>
                <WrappedInput
                  id="firstname"
                  placeholder="First Name"
                  value={values.firstname}
                  onChange={(field, value) => setFieldValue(field, value)}
                  touched={touched.firstname}
                  preserveSpacing
                  error={errors.firstname || errorMessage}
                />
              </div>
              <div className={classes.content}>
                <div className={classes.title}>{'Please enter the user\'s last name'}</div>
                <WrappedInput
                  id="lastname"
                  placeholder="Last Name"
                  value={values.lastname}
                  onChange={(field, value) => setFieldValue(field, value)}
                  touched={touched.lastname}
                  preserveSpacing
                  error={errors.lastname || errorMessage}
                />
              </div>
              <div className={classes.content}>
                <div className={classes.title}>{'Please enter the user\'s email address'}</div>
                <WrappedInput
                  id="email"
                  placeholder="Email address"
                  value={values.email}
                  onChange={(field, value) => setFieldValue(field, value)}
                  touched={touched.email}
                  preserveSpacing
                  error={errors.email || errorMessage}
                />
              </div>
              <Buttons
                submitDisabled={!isValid && !touched.error}
                isSubmitting={loading}
                onAccept={handleSubmit}
                onCancel={onCancel}
                acceptText="Create new user"
                cancelText="Cancel"
              />
            </form>
            )}
        />
      </Modal>
    );
  }
}

AddUsersModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default compose(withStyles(styles), withTheme())(AddUsersModal);
