import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { Post } from 'Store/Api/CallApi';
import { RULE_GET_IMPORT_FAILED_RULES } from 'Store/Api/ApiEndpoints';
import {
  GET_IMPORT_FAILED_RULES,
  getImportFailedRulesSuccess,
} from 'Store/Areas/Import/ImportFailedRulesActions';

function* getImportFailedRulesSaga(action) {
  const response = yield Post(RULE_GET_IMPORT_FAILED_RULES, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getImportFailedRulesSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* importFailedRulesWatcher() {
  yield takeLatest(GET_IMPORT_FAILED_RULES, getImportFailedRulesSaga);
}
