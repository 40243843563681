import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { getAllVersions } from 'Store/Areas/MachineLearning/MachineLearningActions';
import { machineLearningSelector } from 'Store/Areas/MachineLearning/MachineLearningSelectors';
import { strings } from 'Constants/ModelManagement/Strings';
import Version from './Version';
import { styles } from './ModelList.styles';


class ModelList extends React.PureComponent {
  state = {
    expandedModelId: 0,
  }

  componentDidMount = () => {
    this.props.dispatch(getAllVersions());
  }

  onVersionClick = (id) => {
    const { expandedModelId } = this.state;
    this.setState({
      expandedModelId: expandedModelId === id ? 0 : id,
    });
  }

  render() {
    const { versions, classes } = this.props;
    const { expandedModelId } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.title}>
          {strings.modelManagement}
        </div>
        {versions.map(v => (
          <Version
            version={v}
            key={v.id}
            expanded={expandedModelId === v.id}
            onExpand={this.onVersionClick}
          />
        ))}
      </div>
    );
  }
}

ModelList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  versions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    isActive: PropTypes.bool,
    models: PropTypes.arrayOf(PropTypes.shape({
      displayName: PropTypes.string,
      categories: PropTypes.string,
      description: PropTypes.string,
    })),
  }).isRequired).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => {
  return {
    versions: machineLearningSelector(state).versions,
  };
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTheme(),
)(ModelList);
