import { takeLatest, put } from 'redux-saga/effects';
import { ML_GET_ALL_VERSIONS, ML_ACTIVATE_VERSION, ML_GET_MODELS_LATEST_VERSION, ML_ADD_MODEL, ML_EDIT_MODEL, ML_DELETE_MODEL, ML_CHECK_MODEL_USED, ML_COPY_MODELS } from 'Store/Api/ApiEndpoints';
import { Get, Post } from 'Store/Api/CallApi';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { responseStatusCodes } from 'Store/Api/StatusCodes';
import { strings } from 'Constants/ModelManagement/Strings';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { routes } from 'Constants/Routes';
import { push } from 'connected-react-router';
import { commonStrings } from 'Constants/CommonStrings';
import { GET_ALL_VERSIONS, getAllVersionsSuccess, ACTIVATE_VERSION, activateVersionSuccess, getAllVersions, GET_MODELS_OF_LATEST_VERSION, getModelsOfLatestVersionSuccess, POST_ADD_MODEL, postAddModelSuccess, postEditModelSuccess, POST_EDIT_MODEL, POST_DELETE_MODEL, postDeleteModelSuccess, CHECK_IF_MODEL_IS_USED, checkIfModelIsUsedSuccess, COPY_MODELS, copyModelsSuccess } from './MachineLearningActions';

function* getAllMlVersionsSaga() {
  const response = yield Get(ML_GET_ALL_VERSIONS);
  if (response.ok) {
    const data = yield response.json();
    yield put(getAllVersionsSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}
function* getModelsOfLatestVersionSaga(action) {
  const response = yield Get(ML_GET_MODELS_LATEST_VERSION(action.projectId));
  if (response.ok) {
    const data = yield response.json();
    yield put(getModelsOfLatestVersionSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* activateVersionSaga(action) {
  const response = yield Post(ML_ACTIVATE_VERSION, action.payload);
  if (response.ok) {
    yield put(activateVersionSuccess());
    yield put(getAllVersions());
    yield put(displayToastNotification(strings.versionActivated(action.versionName)));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* postAddModelSaga(action) {
  const response = yield Post(ML_ADD_MODEL, action.payload);
  if (response.ok) {
    yield put(postAddModelSuccess());
    yield put(push(routes.models));
    yield put(displayToastNotification(`${action.payload.name} ${commonStrings.created}`));
  } else if (response.status === responseStatusCodes.badRequest) {
    const data = yield response.json();
    yield put(displayToastNotification(data.error));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* postEditModelSaga(action) {
  const response = yield Post(ML_EDIT_MODEL, action.payload);
  if (response.ok) {
    yield put(postEditModelSuccess());
    yield put(push(routes.models));
    yield put(displayToastNotification(`${action.payload.name} ${commonStrings.updated}`));
  } else if (response.status === responseStatusCodes.badRequest) {
    const data = yield response.json();
    yield put(displayToastNotification(data.error));
  } else {
    yield handleStatusCode(response.status);
  }
}
function* postDeleteModelSaga(action) {
  const response = yield Post(ML_DELETE_MODEL, action.payload);
  if (response.ok) {
    yield put(postDeleteModelSuccess());
    yield put(push(routes.models));
  } else {
    yield handleStatusCode(response.status);
  }
}
function* checkIfModelUsedSaga(action) {
  const response = yield Post(ML_CHECK_MODEL_USED, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(checkIfModelIsUsedSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}
function* copyAllModels(action) {
  const response = yield Post(ML_COPY_MODELS, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(copyModelsSuccess(data));
    yield put(getAllVersions());
    yield put(displayToastNotification(strings.modelsCopied));
  } else {
    yield handleStatusCode(response.status);
  }
}

export function* machineLearningWatcher() {
  yield takeLatest(GET_ALL_VERSIONS, getAllMlVersionsSaga);
  yield takeLatest(GET_MODELS_OF_LATEST_VERSION, getModelsOfLatestVersionSaga);
  yield takeLatest(ACTIVATE_VERSION, activateVersionSaga);
  yield takeLatest(POST_ADD_MODEL, postAddModelSaga);
  yield takeLatest(POST_EDIT_MODEL, postEditModelSaga);
  yield takeLatest(POST_DELETE_MODEL, postDeleteModelSaga);
  yield takeLatest(CHECK_IF_MODEL_IS_USED, checkIfModelUsedSaga);
  yield takeLatest(COPY_MODELS, copyAllModels);
}
