const styles = theme => ({
  content: {
    padding: 20,
    width: 475,
  },
  title: {
    fontSize: theme.constants.fontSizes.regular,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  text: {
    height: 14,
    fontWeight: 'normal',
    fontSize: 12,
  },
  red: {
    color: theme.palette.primary.error,
  },
  delete: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: 12,
    float: 'right',
  },
});

export default styles;
