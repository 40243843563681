export const styles = theme => ({
  grid: {
    marginTop: 20,
    maxWidth: '100%',
  },
  card: {
    padding: '20px 20px 0 20px',
    height: 'Calc(100vh - 16.5em)',
    overflowY: 'auto',
  },
  message: {
    backgroundColor: theme.palette.primary.messageBackground,
    color: theme.simpleMessage.color,
    lineHeight: 1.43,
    fontSize: theme.constants.fontSizes.regular,
  },
  messageContent: {
    padding: 20,
  },
  loaderIcon: {
    marginTop: 10,
  },
  lastKeywordsPageMessage: {
    paddingTop: 20,
  },
  searchMatchesNoKeywordsMessage: {
    paddingTop: 20,
  },
  simpleMessage: theme.simpleMessage,
});
