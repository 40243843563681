const styles = theme => ({
  root: {
    paddingTop: 30,
    paddingLeft: 30,
  },
  text: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.constants.fontSizes.regular,
    marginBottom: 10,
  },
  taxContextFilterSelect: {
    paddingBottom: 20,
  },
  searchBox: {
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.colors.black,
    paddingBottom: 20,
  },
  searchBoxText: {
    paddingLeft: 5,
    paddingRight: 10,
  },
});

export default styles;
