const styles = () => ({
  root: {
    backgroundColor: '#f4f4f4',
    padding: 30,
    marginBottom: 20,
    borderRadius: 4,
  },
  button: {
    width: '100%',
  },
  disabled: {
    opacity: 0.6,
  },
});

export default styles;
