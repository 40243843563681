export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: 90,
    overflow: 'auto',
    outline: 'none',
    cursor: 'pointer',
    paddingLeft: 20,
    paddingRight: 25,
    lineHeight: 1.4,
    marginTop: 20,
  },
  rowItem: {
    flexGrow: 1,
    fontSize: theme.constants.fontSizes.regular,
    marginTop: 10,
    marginBottom: 10,
  },
  active: {
    backgroundColor: theme.palette.primary.messageBackground,
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginRight: 20,
  },
});
