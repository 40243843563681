import React from 'react';
import PropTypes from 'prop-types';

export class CustomizedAxisTick extends React.Component {
  render() {
    const {
      x, y, payload,
    } = this.props;
    const maxChars = 15;
    const fontSize = 12;
    const lineHeight = 14;
    const categoryLines = payload.value.replace(/-/g, ' ').split(/[ ]+/).map(cl => cl);
    const tspans = categoryLines.map((categoryLine, i) =>
      (
        <tspan key={categoryLine} x={0} y={lineHeight} dy={(i * lineHeight)}>
          {categoryLine.length > maxChars ? `${categoryLine.substring(0, maxChars)}...` : categoryLine}
        </tspan>
      ));
    return (
      <g transform={`translate(${x},${y})`}>
        <text height="auto" textAnchor="middle" fontSize={fontSize}>
          {tspans}
        </text>
      </g>
    );
  }
}

CustomizedAxisTick.defaultProps = {
  x: 0,
  y: 0,
  payload: {},
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.shape({
    value: PropTypes.string,
  }),
};

export default CustomizedAxisTick;
