import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { PERIOD_GET_REFERENCE_LISTS, PERIOD_GET_REFERENCE_LIST_ENTRIES, PERIOD_UPLOAD_AND_REPLACE_REFERENCE_LIST, PERIOD_GET_REFERENCE_LIST_RULES, PERIOD_POST_DELETE_REFERENCE_LIST } from 'Store/Api/ApiEndpoints';
import { Get, Post } from 'Store/Api/CallApi';
import { updatePeriodStatusInRedux } from 'Store/Areas/Period/PeriodActions';
import { periodStatuses } from 'Constants/PeriodStatuses';

import {
  GET_REFERENCE_LIST_ITEMS,
  GET_REFERENCE_LIST_ENTRIES,
  UPLOAD_AND_REPLACE_REFERENCE_LIST,
  CHECK_REFERENCE_LIST_RULES,
  getReferenceListItemsSuccess,
  getReferenceListEntriesSuccess,
  checkReferenceListRulesSuccess,
  POST_DELETE_REFERENCE_LIST,
  getReferenceListItems,
  resetReferenceListsRules,
  NAVIGATE_TO_REFERENCE_LISTS,
  DELETE_REFERENCE_LIST_ENTRIES,
} from 'Store/Areas/Categorisation/ReferenceListActions';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { commonStrings } from 'Constants/CommonStrings';
import { routes } from 'Constants/Routes';

/* Worker Sagas */
function* getReferenceListItemsSaga(action) {
  const response = yield Get(PERIOD_GET_REFERENCE_LISTS(action.payload.periodId));
  if (response.ok) {
    const data = yield response.json();
    yield put(getReferenceListItemsSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}
function* getReferenceListEntriesSaga(action) {
  const response = yield Post(PERIOD_GET_REFERENCE_LIST_ENTRIES, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(getReferenceListEntriesSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* uploadAndReplaceReferenceListSaga(action) {
  const response = yield Post(PERIOD_UPLOAD_AND_REPLACE_REFERENCE_LIST, action.payload);

  yield put(updatePeriodStatusInRedux(periodStatuses.readyForUserCategorisation));

  if (!response.ok) {
    yield handleStatusCode(response.status);
  }
}

function* checkReferenceListRulesSaga(action) {
  const response = yield Post(PERIOD_GET_REFERENCE_LIST_RULES, action.payload);
  if (response.ok) {
    const data = yield response.json();
    yield put(checkReferenceListRulesSuccess(data));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* postDeleteReferenceListSaga(action) {
  const response = yield Post(PERIOD_POST_DELETE_REFERENCE_LIST, action.payload);
  if (response.ok) {
    yield put(resetReferenceListsRules());
    yield put(getReferenceListItems(action.payload.periodId, true));
    yield put(displayToastNotification(`${action.referenceListName} ${commonStrings.wasDeleted}`));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* deleteReferenceListEntriesSaga(action) {
  const response = yield Post(PERIOD_POST_DELETE_REFERENCE_LIST, action.payload);
  if (response.ok) {
    yield put(getReferenceListItems(action.payload.periodId, true));
    yield put(displayToastNotification(`Referencelist entries ${action.payload.referenceListEntryIds.join(',')} ${commonStrings.wasDeleted}`));
  } else {
    yield handleStatusCode(response.status);
  }
}

function* navigateToReferenceListSaga() {
  yield put(push(routes.categorisation.referenceLists));
}

/* Watcher Sagas */
export function* referenceListSagas() {
  yield takeLatest(GET_REFERENCE_LIST_ITEMS, getReferenceListItemsSaga);
  yield takeLatest(GET_REFERENCE_LIST_ENTRIES, getReferenceListEntriesSaga);
  yield takeLatest(UPLOAD_AND_REPLACE_REFERENCE_LIST, uploadAndReplaceReferenceListSaga);
  yield takeLatest(CHECK_REFERENCE_LIST_RULES, checkReferenceListRulesSaga);
  yield takeLatest(POST_DELETE_REFERENCE_LIST, postDeleteReferenceListSaga);
  yield takeLatest(NAVIGATE_TO_REFERENCE_LISTS, navigateToReferenceListSaga);
  yield takeLatest(DELETE_REFERENCE_LIST_ENTRIES, deleteReferenceListEntriesSaga);
}
