import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './SuccessMessage.styles';

class SuccessMessage extends React.PureComponent {
  render() {
    const { message, secondMessage, classes } = this.props;
    return (
      <Fragment>
        <div className={classes.successMessage}>{message}</div>
        <div className={classes.successSecondMessage}>{secondMessage}</div>
      </Fragment>
    );
  }
}

SuccessMessage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  message: PropTypes.string.isRequired,
  secondMessage: PropTypes.string.isRequired,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(SuccessMessage);
