const styles = theme => ({
  blurred: {
    width: '100%',
    position: 'fixed',
    overflow: 'hidden',
    left: 0,
    top: 0,
    zIndex: theme.zIndex.drawer - 1,
    marginTop: '-1em',
    height: '125vh',
  },
  lightBackground: {
    backgroundColor: 'white',
    opacity: 0.6,
  },
  darkBackground: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  loader: {
    height: '100vh',
    width: '100%',
  },
  noOverflow: {
    overflow: 'hidden',
  },
});

export default styles;
