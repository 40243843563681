import React, { Fragment } from 'react';
import { withStyles, withTheme, Card, Grid, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { getTagFilters } from 'Helpers/CategorizationHelpers';
import { strings } from 'Constants/Categorization/Strings';
import Message from 'Components/Shared/Message/Message';
import GenericDragLayer from 'Components/Shared/DragNDrop/GenericDragLayer';
import { getKeywords } from 'Store/Areas/Categorisation/KeywordActions';
import { commonStrings } from 'Constants/CommonStrings';
import { title } from 'Constants/App/Titles/Strings';
import CategorizationComplete from 'Components/Categorization/Shared/Robot/CategorizationComplete';
import CircularLoader from 'Components/Shared/Loaders/CircularLoader';
import DragnDrop from 'Constants/DragnDrop';
import { onPeriodReloaded } from 'Components/Shared/PeriodProviders/onPeriodReloaded';
import KeywordRule from 'Components/Categorization/Shared/KeywordRule/KeywordRule';
import { isIE } from 'Helpers/EnvironmentHelpers';
import { isCtrlKeyActive, isShiftKeyActive, onMultiSelectionChange } from 'Helpers/SelectionHelpers';
import { isReadOnlySelector } from 'Store/Areas/Period/PeriodSelectors';
import { styles } from './KeywordsList.styles';
import Keyword from './Keyword/Keyword';

class KeywordsList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.tags = getTagFilters(this.props.period.data.tags);

    this.noSelection = {
      activeKeywordName: null,
      selectedKeywords: [],
      selectedKeywordNames: [],
    };

    this.state = {
      ...this.noSelection,
      hoveredKeywordName: null,
      currentTag: this.tags[0],
      categorizationCompleteRender: false,
    };
  }

  componentDidMount() {
    document.title = title.keywords;
    if (this.props.keywordFilters.tagFilter) {
      this.updateCurrentTag();
    }
    setTimeout(() => {
      this.setState({ categorizationCompleteRender: !this.state.categorizationCompleteRender });
    }, 3000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.keywordFilters !== this.props.keywordFilters) {
      this.resetSelection();

      if (prevProps.keywordFilters.tagFilter !== this.props.keywordFilters.tagFilter) {
        this.updateCurrentTag();
      }
    }
  }

  onKeywordHover = ({ name }) => {
    if (this.state.hoveredKeywordName !== name) {
      this.setState({
        hoveredKeywordName: name,
      });
    }
  }

  onKeywordOut = () => {
    this.onKeywordHover({ name: null });
  }

  onKeywordClick = (keyword, e) => {
    const ctrlKeyActive = isCtrlKeyActive(e);
    const shiftKeyActive = isShiftKeyActive(e);

    if (!ctrlKeyActive && !shiftKeyActive) {
      this.props.onKeywordClick(keyword, this.state.currentTag);
      return;
    }

    const resultantState = onMultiSelectionChange(
      this.props.keywords.keywords,
      keyword.name,
      ctrlKeyActive,
      shiftKeyActive,
      this.state.selectedKeywordNames,
      this.state.activeKeywordName,
      k => k.name,
    );

    const newState = {
      selectedKeywords: resultantState.selectedItems,
      selectedKeywordNames: resultantState.selections,
    };

    if (resultantState.activeSelection) {
      newState.activeKeywordName = resultantState.activeSelection;
    }

    this.setState(newState);
  }

  onBeginDrag = (dragProps) => {
    if (dragProps.keywords.length === 1 && this.state.selectedKeywords !== dragProps.keywords) {
      const activeKeywordName = dragProps.keywords[0].name;

      this.setState({
        activeKeywordName,
        selectedKeywords: dragProps.keywords,
        selectedKeywordNames: [activeKeywordName],
      });
    }
  }

  loadMoreKeywords = () => {
    this.props.dispatchGetKeywords(true);
  }

  resetSelection() {
    this.setState({
      ...this.noSelection,
    });
  }

  updateCurrentTag() {
    this.setState({
      currentTag: this.tags.find(x => x.id === this.props.keywordFilters.tagFilter),
    });
  }

  renderDragLayer = (item) => {
    if (item.type === DragnDrop.keyword) {
      let keyword;

      if (item.keywords.length === 1) {
        [keyword] = item.keywords;
      } else {
        keyword = {
          name: `${item.keywords.length} keywords`,
        };
      }

      return (
        <Keyword
          isDragging
          currencySymbol={this.props.currencySymbol}
          keyword={keyword}
          currentTag={item.currentTag}
          allowDrag={false}
          showProgress={item.keywords.length === 1}
        />
      );
    }

    return null;
  }

  render() {
    const {
      classes,
      currencySymbol,
      keywords: {
        loading,
        hasSearched,
        currentPageNumber,
        morePagesAvailable,
        keywords,
      },
      showProgress,
      isReadOnly,
    } = this.props;

    const {
      hoveredKeywordName,
      selectedKeywords,
      currentTag,
      categorizationCompleteRender,
    } = this.state;

    return (
      <Fragment>
        <If condition={!isReadOnly}>
          <GenericDragLayer>
            {this.renderDragLayer}
          </GenericDragLayer>
        </If>
        <Card className={classes.card}>
          <KeywordRule
            rootTag={currentTag.name}
            hoveredKeyword={hoveredKeywordName}
            keywordSelections={selectedKeywords.length}
          />
          <Hidden smDown>
            <Message
              title={commonStrings.didYouKnow}
              className={classes.message}
              contentClassName={classes.messageContent}
              message={strings.didYouKnowMessage}
            />
          </Hidden>
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMoreKeywords}
            hasMore={morePagesAvailable}
            useWindow={false}
            threshold={0}
          >
            <Grid container direction="row" spacing={8} className={classes.grid}>
              <If condition={!loading && !keywords.length}>
                <If condition={hasSearched}>
                  <div className={classes.searchMatchesNoKeywordsMessage}>
                    <p className={classes.simpleMessage}>{strings.noKeywordsMatchSearch}</p>
                  </div>
                </If>
                <If condition={!hasSearched && categorizationCompleteRender}>
                  <CategorizationComplete message={strings.keywordsCategorised} />
                </If>
              </If>
              <For each="keyword" of={keywords}>
                <Grid item xl={3} lg={4} md={6} xs={12} key={`{grid_${keyword.name}}`}>
                  <Keyword
                    currencySymbol={currencySymbol}
                    allowDrag={!isReadOnly}
                    keyword={keyword}
                    selectedKeywords={selectedKeywords}
                    currentTag={currentTag}
                    isSelected={selectedKeywords.includes(keyword)}
                    key={keyword.name}
                    onClick={this.onKeywordClick}
                    onMouseOver={this.onKeywordHover}
                    onFocus={this.onKeywordHover}
                    onMouseOut={this.onKeywordOut}
                    onBlur={this.onKeywordOut}
                    onBeginDrag={this.onBeginDrag}
                    showProgress={showProgress}
                    transparentSource={!isIE}
                  />
                </Grid>
              </For>
            </Grid>
            <If condition={loading || !categorizationCompleteRender}>
              <div key="loading-icon" className={classes.loaderIcon}><CircularLoader className={classes.loader} /></div>
            </If>
          </InfiniteScroll>

          <If condition={!morePagesAvailable && currentPageNumber > 20}>
            <div className={classes.lastKeywordsPageMessage}>
              <p className={classes.simpleMessage}>{strings.lastKeywordsPageMessage}</p>
            </div>
          </If>
        </Card>
      </Fragment>
    );
  }
}

KeywordsList.defaultProps = {
  onKeywordClick: () => {},
  showProgress: true,
};

KeywordsList.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  keywords: PropTypes.shape({
    name: PropTypes.string,
    keywords: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      totalSum: PropTypes.number,
      morePagesAvailable: PropTypes.bool,
      loading: PropTypes.bool,
    })),
    totalSum: PropTypes.number,
    currentPageNumber: PropTypes.number,
  }).isRequired,
  dispatchGetKeywords: PropTypes.func.isRequired,
  keywordFilters: PropTypes.shape({
    sortOrder: PropTypes.number.isRequired,
    tagFilter: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired,
  }).isRequired,
  onKeywordClick: PropTypes.func,
  showProgress: PropTypes.bool,
  isReadOnly: PropTypes.bool.isRequired,
  period: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      tags: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
    }),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    isReadOnly: isReadOnlySelector(state),
    period: state.periods.period,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  const {
    keywords,
    periodId,
    keywordFilters,
  } = ownProps;
  return {
    dispatchGetKeywords: (isLoadMoreKeywords = false) => dispatch(getKeywords(
      periodId,
      keywords.currentPageNumber,
      keywordFilters.sortOrder,
      keywordFilters.tagFilter,
      keywordFilters.searchTerm,
      !!(keywordFilters.searchTerm && keywordFilters.searchTerm.trim()),
      isLoadMoreKeywords,
    )),
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps, mapDispatchToProps),
  onPeriodReloaded(props => props.dispatchGetKeywords()),
)(KeywordsList);
