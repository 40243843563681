import React from 'react';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Breadcrumb from 'Components/Shared/Breadcrumbs/ChevronBreadcrumb/Breadcrumb';
import { strings as uploadStrings } from 'Constants/Upload/Strings';
import {
  hasSupportingFilesSelector,
  periodStatusSelector,
  periodEntityMachineLearningEnabled,
} from 'Store/Areas/Period/PeriodSelectors';
import { periodStatuses } from 'Constants/PeriodStatuses';
import { styles } from './UploadBreadcrumb.styles';

export const constants = {
  indexes: {
    upload: 0,
    mainMapping: 1,
    supportMapping: 2,
    review: 3,
    failedRules: 4,
    supportingDocumentsSetup: 5,
    contras: 6,
    machineLearningSetup: 7,
    periodImportDetails: 8,
  },
};

class UploadBreadcrumb extends React.PureComponent {
  getBreadcrumbItems = () => {
    const {
      hasSupportingFiles,
      periodStatus,
      machineLearningEnabled,
      isRedirectedFromReviewTile,
    } = this.props;
    let items = null;
    if (hasSupportingFiles) {
      items = [
        uploadStrings.upload,
        uploadStrings.mappingMain,
        uploadStrings.mappingSupporting,
        uploadStrings.review,
        uploadStrings.failedRules,
        uploadStrings.supportingDocuments,
        uploadStrings.contras,
      ];
    } else {
      items = [
        uploadStrings.upload,
        uploadStrings.mappingMain,
        uploadStrings.review,
        uploadStrings.failedRules,
        uploadStrings.contras,
      ];
    }

    if (machineLearningEnabled) {
      items.push(uploadStrings.machineLearning);
    }

    if (periodStatus === periodStatuses.readyForUserCategorisation || isRedirectedFromReviewTile) {
      items.push(uploadStrings.periodImportDetails);
    }

    return items;
  }

  getActiveIndex = () => {
    const { hasSupportingFiles, index } = this.props;
    if (hasSupportingFiles) {
      return index;
    }
    switch (index) {
      case constants.indexes.upload:
      case constants.indexes.mainMapping:
        return index;
      case constants.indexes.review:
      case constants.indexes.failedRules:
        return index - 1;
      case constants.indexes.contras:
      case constants.indexes.machineLearningSetup:
      case constants.indexes.periodImportDetails:
        return index - 2;
      default:
        return 0;
    }
  }

  render() {
    const activeIndex = this.getActiveIndex();
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Breadcrumb
          items={this.getBreadcrumbItems()}
          activeIndex={activeIndex}
          className={classes.breadcrumb}
        />
      </React.Fragment>
    );
  }
}

UploadBreadcrumb.defaultProps = {
  isRedirectedFromReviewTile: false,
};

UploadBreadcrumb.propTypes = {
  index: PropTypes.oneOf(Object.values(constants.indexes)).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  hasSupportingFiles: PropTypes.bool.isRequired,
  periodStatus: PropTypes.string.isRequired,
  machineLearningEnabled: PropTypes.bool.isRequired,
  isRedirectedFromReviewTile: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    hasSupportingFiles: hasSupportingFilesSelector(state),
    periodStatus: periodStatusSelector(state),
    machineLearningEnabled: periodEntityMachineLearningEnabled(state),
  };
}

export default compose(withStyles(styles), connect(mapStateToProps))(UploadBreadcrumb);
