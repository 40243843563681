import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { styles } from './Autocomplete.styles';

const enterKeyCode = 13;
class Autocomplete extends React.PureComponent {
  setInputRef = (inputRef) => {
    this.input = inputRef;
    if (this.input) { this.input.addEventListener('keydown', this.handleKeyDown); }
  }

  handleKeyDown = (event) => {
    const {
      suggestions,
      onSuggestionChosen,
    } = this.props;
    if (event.keyCode === enterKeyCode && suggestions.length === 0) {
      onSuggestionChosen(this.input.value);
    }
  }

  callOnInput = (func) => {
    if (this.input) {
      return func(this.input);
    }
    return null;
  }

  render() {
    const {
      classes,
      suggestions,
      onSuggestionChosen,
      blurOnSelection,
      rootClassName,
      style,
      children,
    } = this.props;
    return (
      <div className={`${classes.root} ${rootClassName}`} style={style}>
        <Downshift
          defaultHighlightedIndex={0}
          onSelect={(selection) => {
            onSuggestionChosen(selection);
            if (blurOnSelection) {
              this.callOnInput(input => input.blur());
            }
          }}
        >
          {({
            getInputProps,
            getItemProps,
            highlightedIndex,
            isOpen,
          }) => (
            <div className={classes.container}>
              {children(
                getInputProps,
                this.setInputRef,
              )}
              <If condition={isOpen && suggestions && suggestions.length && this.input}>
                <With inputCoords={this.callOnInput(input => input.getBoundingClientRect())}>
                  <Paper
                    className={classes.paper}
                    square
                    style={{
                      position: 'fixed',
                      left: inputCoords.left,
                      top: inputCoords.bottom,
                      width: inputCoords.width,
                      height: '25vh',
                      overflow: 'auto',
                    }}
                  >
                    <List>
                      {suggestions.map((suggestion, index) =>
                      (
                        <ListItem key={suggestion} button selected={highlightedIndex === index}>
                          <ListItemText
                            {...getItemProps({ item: suggestion })}
                            key={suggestion}
                            component="div"
                            primary={suggestion}
                          />
                        </ListItem>))}
                    </List>
                  </Paper>
                </With>
              </If>
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

Autocomplete.defaultProps = {
  suggestions: [],
  placeholder: '',
  blurOnSelection: true,
  rootClassName: '',
};

Autocomplete.propTypes = {
  rootClassName: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string), // suggestions to display in dropdown
  onSuggestionChosen: PropTypes.func.isRequired, // suggestion item clicked
  placeholder: PropTypes.string,
  blurOnSelection: PropTypes.bool, // blur input after selecting suggestion
  children: PropTypes.func.isRequired,
};

export default withStyles(styles)(Autocomplete);
