import { sortOrder } from 'Constants/Categorization/QueryTermFilters';
import { UPDATE_CATEGORY_RULE_FILTERS, UPDATE_CATEGORY_RULE_SEARCH_TERM, RESET_CATEGORY_RULE_FILTERS } from 'Store/Areas/Rule/CategoryRulesFilterActions';

const initialState = {
  sortColumn: 2,
  sortOrder: sortOrder.valueDescending,
  tagFilter: 0,
  searchTerm: '',
};

export default function keywordFilterReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CATEGORY_RULE_FILTERS:
      return Object.assign({}, state, {
        sortColumn: action.sortColumn,
        sortOrder: action.sortOrder,
        tagFilter: action.tagFilter,
      });
    case UPDATE_CATEGORY_RULE_SEARCH_TERM:
      return Object.assign({}, state, {
        searchTerm: action.searchTerm,
      });
    case RESET_CATEGORY_RULE_FILTERS:
      return initialState;
    default:
      return state;
  }
}
