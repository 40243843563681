export const POST_TOGGLE_ACCESS = 'TOGGLEACCESS/POST_TOGGLE_ACCESS';
export const POST_TOGGLE_ACCESS_SUCCESS = 'TOGGLEACCESS/POST_TOGGLE_ACCESS_SUCCESS';

export function postToggleAccess(userId, entityLevelId, entityLevel, readOnly) {
  return {
    type: POST_TOGGLE_ACCESS,
    payload: {
      userId,
      entityLevelId,
      entityLevel,
      readOnly,
    },
  };
}

export function postToggleAccessSuccess(user) {
  return {
    type: POST_TOGGLE_ACCESS_SUCCESS,
    payload: {
      user: user,
    },
  };
}
