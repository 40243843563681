import React, { Fragment } from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { routes } from 'Constants/Routes';
import { strings } from 'Constants/Categorization/Strings';
import ComboKeywordFiltersPanel from 'Components/Categorization/Shared/Keywords/KeywordFilters/ComboKeywordFiltersPanel';
import ComboKeywordsList from 'Components/Categorization/Shared/Keywords/ComboKeywordsList';
import Breadcrumb from 'Components/Shared/Breadcrumbs/ChevronBreadcrumb/Breadcrumb';
import { resetComboKeywords } from 'Store/Areas/Categorisation/ComboKeywordActions';
import { navigateToReferenceLists } from 'Store/Areas/Categorisation/ReferenceListActions';
import { periodIdSelector, isAnyLineItemFlaggedSelector } from 'Store/Areas/Period/PeriodSelectors';
import { styles } from './CombinationKeywords.styles';

class CombinationKeywords extends React.PureComponent {
  onNavigate(path) {
    const { dispatch } = this.props;
    dispatch(resetComboKeywords());
    if (path === routes.categorisation.referenceLists) {
      this.props.dispatch(navigateToReferenceLists());
    } else {
      dispatch(push(path));
    }
  }

  render() {
    const {
      classes,
      currencySymbol,
      keywords,
      keywordFilters,
      periodId,
      isAnyLineItemFlagged,
    } = this.props;
    return (
      <Fragment>
        <Breadcrumb
          items={[
            strings.referenceLists,
            strings.keyword,
            strings.keywordCombination,
            strings.customRules,
            strings.lineItems,
            strings.conflictedLineItems,
            strings.flaggedLineItems,
          ]}
          activeIndex={2}
          className={classes.breadcrumb}
          selectableIndexes={[0, 1, 3, 4, 5, ...isAnyLineItemFlagged ? [6] : []]}
          onClicks={
            [
              () => this.onNavigate(routes.categorisation.referenceLists),
              () => this.onNavigate(routes.categorisation.singleKeywords),
              null,
              () => this.onNavigate(routes.categorisation.customKeywords),
              () => this.onNavigate(routes.categorisation.lineItems),
              () => this.onNavigate(routes.categorisation.conflictingLineItems),
              () => this.onNavigate(routes.categorisation.flaggedLineItems),
            ]
          }
          isSelectable
        />
        <ComboKeywordFiltersPanel />
        <ComboKeywordsList
          currencySymbol={currencySymbol}
          keywords={keywords}
          keywordFilters={keywordFilters}
          periodId={periodId}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    keywords: state.categorisation.comboKeywords,
    keywordFilters: state.categorisation.comboKeywords.filters,
    periodId: periodIdSelector(state),
    isAnyLineItemFlagged: isAnyLineItemFlaggedSelector(state),
  };
}

CombinationKeywords.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  keywords: PropTypes.shape({
    loading: PropTypes.bool,
    currentPageNumber: PropTypes.number,
    morePagesAvailable: PropTypes.bool,
    keywords: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalSum: PropTypes.number.isRequired,
    })),
    rootKeyword: PropTypes.shape({
      name: PropTypes.string.isRequired,
      totalSum: PropTypes.number.isRequired,
    }).isRequired,
    rootTag: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  periodId: PropTypes.number.isRequired,
  keywordFilters: PropTypes.shape({
    sortOrder: PropTypes.number.isRequired,
    tagFilter: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired,
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  isAnyLineItemFlagged: PropTypes.bool.isRequired,
};

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withTheme(),
)(CombinationKeywords);
