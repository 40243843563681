const styles = theme => ({
  root: {
    marginTop: '1em',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    height: '100%',
    width: '100%',
    display: 'flex',
  },
  main: {
    color: theme.palette.primary.main,
  },
  dark: {
    color: theme.palette.primary.dark,
  },
  white: {
    color: theme.palette.colors.white,
  },
});

export default styles;
