import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getKeywords, updateSearchTerm, updateKeywordFilters } from 'Store/Areas/Categorisation/KeywordActions';
import KeywordFiltersPanel from 'Components/Categorization/Shared/KeywordFiltersPanel/KeywordFiltersPanel';

class SingleKeywordFiltersPanel extends React.PureComponent {
  onSortOrderChanged(value) {
    const { keywordFilters, dispatch, period } = this.props;
    dispatch(updateKeywordFilters(
      value,
      keywordFilters.tagFilter,
      keywordFilters.searchTerm,
      period.data.id,
      1,
    ));
  }

  onTagFilterChanged(value) {
    const { keywordFilters, dispatch, period } = this.props;
    dispatch(updateKeywordFilters(
      keywordFilters.sortOrder,
      value,
      keywordFilters.searchTerm,
      period.data.id,
      1,
    ));
  }

  onSearchTermChanged(value) {
    const { dispatch } = this.props;
    dispatch(updateSearchTerm(value));
  }

  onSearchIconClicked() {
    const { dispatch, keywordFilters, period } = this.props;
    dispatch(getKeywords(
      period.data.id,
      1,
      keywordFilters.sortOrder,
      keywordFilters.tagFilter,
      keywordFilters.searchTerm,
      !!keywordFilters.searchTerm.trim(),
    ));
  }

  onSearchReset() {
    const { dispatch, keywordFilters, period } = this.props;
    dispatch(updateSearchTerm(''));
    dispatch(getKeywords(
      period.data.id,
      1,
      keywordFilters.sortOrder,
      keywordFilters.tagFilter,
      '',
      !!keywordFilters.searchTerm.trim(),
    ));
  }

  render() {
    const {
      keywordFilters,
      period,
    } = this.props;
    return (
      <KeywordFiltersPanel
        keywordFilters={keywordFilters}
        period={period}
        onSortOrderChanged={value => this.onSortOrderChanged(value)}
        onTagFilterChanged={value => this.onTagFilterChanged(value)}
        onSearchTermChanged={value => this.onSearchTermChanged(value)}
        onSearchIconClicked={() => this.onSearchIconClicked()}
        onSearchReset={() => this.onSearchReset()}
      />
    );
  }
}

SingleKeywordFiltersPanel.propTypes = {
  dispatch: PropTypes.func.isRequired,
  keywordFilters: PropTypes.shape({
    sortOrder: PropTypes.number.isRequired,
    tagFilter: PropTypes.number.isRequired,
    searchTerm: PropTypes.string.isRequired,
  }).isRequired,
  period: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    keywordFilters: state.categorisation.keywords.filters,
    period: state.periods.period,
  };
}

export default connect(mapStateToProps)(SingleKeywordFiltersPanel);
