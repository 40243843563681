import { RESET_PERIOD } from 'Store/Areas/Period/PeriodActions';
import { RESET_CONFLICT } from 'Store/Areas/Conflict/ConflictActions';
import {
  CLOSE_EDIT_PRIORITY_MODAL,
  EDIT_RULE_PRIORITY,
  GET_CURRENT_RULE_PRIORITY,
  GET_CURRENT_RULE_PRIORITY_SUCCESS,
} from './RulePriorityActions';

const initialState = {
  loading: true,
  success: false,
  open: false,
  ruleText: '',
  periodId: 0,
  ruleId: -1,
  priorityLevel: null,
};

export default function rulePriorityReducer(state = initialState, action) {
  switch (action.type) {
    case EDIT_RULE_PRIORITY:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
      });
    case GET_CURRENT_RULE_PRIORITY:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        periodId: action.payload.periodId,
        ruleId: action.payload.ruleId,
        ruleText: action.ruleText,
        priorityLevel: action.priorityLevel,
        open: action.open,
      });
    case GET_CURRENT_RULE_PRIORITY_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        priorityLevel: action.priorityLevel,
        open: action.open,
      });
    case CLOSE_EDIT_PRIORITY_MODAL:
    case RESET_PERIOD:
    case RESET_CONFLICT:
      return initialState;
    default:
      return state;
  }
}
