import {
  GET_LINE_ITEMS,
  GET_LINE_ITEMS_SUCCESS,
  RESET_LINE_ITEMS,
  UNCATEGORISE_LINE_ITEM,
  UNCATEGORISE_LINE_ITEM_SUCCESS,
  MOVE_LINE_ITEM_TO_CONTRA_SHELF,
  REMOVE_LINE_ITEM_FROM_CONTRA_SHELF,
  MARK_LINE_ITEMS_AS_CONTRA,
  MARK_LINE_ITEMS_AS_CONTRA_SUCCESS,
  MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED,
  MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED_SUCCESS,
} from 'Store/Areas/Categorisation/LineItemActions';
import { GET_CATEGORISATION_LOGIC_SUCCESS, GET_CATEGORISATION_LOGIC } from 'Store/Areas/Import/LineItemValueActions';
import { SELECT_PERIOD } from 'Store/Areas/Projects/UserTreePickerActions';


const initialState = {
  lineItems: [],
  columns: [],
  columnHeaders: [],
  columnTagIds: [],
  loading: true,
  pageNumber: 1,
  hasMore: true,
  scrollId: null,
  totalLineItemCount: 0,
  isFilteredLineItems: false,
  isSearchTermPresent: false,
  contraShelfLineItems: [],
  tagFilters: [],
};

export default function lineItemReducer(state = initialState, action) {
  switch (action.type) {
    case GET_LINE_ITEMS:
      return Object.assign({}, state, {
        loading: action.loading,
        hasMore: action.hasMore,
        contraShelfLineItems: action.payload.contraShelfLineItems,
        tagFilters: action.payload.tagFilters,
      });
    case GET_LINE_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        hasMore: action.hasMore,
        scrollId: action.scrollId,
        pageNumber: action.pageNumber,
        lineItems: action.scrollId ?
          state.lineItems.concat(action.lineItems).filter(lineItem =>
            !state.contraShelfLineItems.some(x => x.id === lineItem.id)) :
          action.lineItems.filter(lineItem =>
            !state.contraShelfLineItems.some(x => x.id === lineItem.id)),
        columns: action.columns,
        columnHeaders: action.columnHeaders,
        columnTagIds: action.columnTagIds,
        totalLineItemCount: action.totalLineItemCount,
        isFilteredLineItems: action.isFilteredLineItems,
        isSearchTermPresent: action.isSearchTermPresent,
      });
    case GET_CATEGORISATION_LOGIC_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        lineItems: state.lineItems.map(x => ({
          ...x,
          keyword: action.categorisationLogic.lineItemKeywords[x.id.toString()] || null,
        })),
      });
    case GET_CATEGORISATION_LOGIC:
    case UNCATEGORISE_LINE_ITEM:
    case UNCATEGORISE_LINE_ITEM_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
      });
    case RESET_LINE_ITEMS:
    case SELECT_PERIOD:
      return initialState;
    case MOVE_LINE_ITEM_TO_CONTRA_SHELF:
      return Object.assign({}, state, {
        contraShelfLineItems: state.contraShelfLineItems
          .concat(state.lineItems.find(x => x.id === action.lineItemId)),
        lineItems: state.lineItems.filter((lineItem) => {
          return lineItem.id !== action.lineItemId;
        }),
      });
    case REMOVE_LINE_ITEM_FROM_CONTRA_SHELF:
      return Object.assign({}, state, {
        lineItems: state.lineItems
          .concat(state.contraShelfLineItems.find(x => x.id === action.lineItemId)),
        contraShelfLineItems: state.contraShelfLineItems.filter((lineItem) => {
          return lineItem.id !== action.lineItemId;
        }),
      });
    case MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED:
    case MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED_SUCCESS:
    case MARK_LINE_ITEMS_AS_CONTRA:
    case MARK_LINE_ITEMS_AS_CONTRA_SUCCESS:
      return Object.assign({}, state, {
        contraShelfLineItems: [],
        loading: action.loading,
      });
    default:
      return state;
  }
}
