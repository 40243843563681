import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import MaterialSlider from '@material-ui/lab/Slider';
import styles from './DiscreteSlider.styles';

class DiscreteSlider extends React.PureComponent {
  static insertString(oldString, value, start, end) {
    return (
      oldString.substring(0, start) + value + oldString.substring(end || start, oldString.length)
    );
  }

  static round(value, step) {
    const inv = 1.0 / step;
    return Math.round(value * inv) / inv;
  }

  static highlightOnFocus(event) {
    event.target.select();
  }

  constructor(props) {
    super(props);

    this.invalidValue = undefined;
    this.state = {
      value: 0,
    };
  }

  belowMin(value) {
    const { min } = this.props;

    return min !== undefined && value < min;
  }

  aboveMax(value) {
    const { max } = this.props;

    return max !== undefined && value > max;
  }

  handleChange(event, value) {
    const { invalidValue } = this;
    const { min, max, step } = this.props;
    const targetValue = event.target.value;

    if (invalidValue) {
      this.forceUpdate();
      return;
    }

    let val =
      value !== undefined // eslint-disable-line
        ? value
        : invalidValue
          ? targetValue
          : parseFloat(targetValue);

    if (this.belowMin(val)) {
      val = min;
    } else if (this.aboveMax(val)) {
      val = max;
    }

    val = DiscreteSlider.round(val, step);

    this.props.onValueChange(val);
  }

  render() {
    const { value } = this.state;
    const {
      classes,
      min,
      max,
      step,
    } = this.props;

    // try using a horizontal ul
    return (
      <div className={classes.root}>
        <MaterialSlider
          className={classes.slider}
          aria-labelledby="discrete-slider-small-steps"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(e, v) => this.handleChange(e, v)}
        />
      </div>
    );
  }
}

DiscreteSlider.getDerivedStateFromProps = (props, state) => {
  const newState = {};

  if (props.value !== state.value) {
    newState.value = props.value;
  }

  return Object.keys(newState).length > 0 ? newState : null;
};

DiscreteSlider.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onValueChange: PropTypes.func.isRequired,
};

DiscreteSlider.defaultProps = {
  min: 10,
  max: 100,
  step: 10,
};

export default withStyles(styles)(DiscreteSlider);
