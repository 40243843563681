import React from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Modal from 'Components/Shared/Modal/StyledModal';
import StyledModalButtons from 'Components/Shared/Modal/StyledModal.Buttons';
import { commonStrings } from 'Constants/CommonStrings';
import { resetDeletingCategory, postDeleteCategory } from 'Store/Areas/Categorisation/DeleteCategoryActions';
import { strings } from 'Constants/Categorization/Strings';
import { styles } from './CategoryDeletingModal.styles';

class CategoryDeletionModal extends React.PureComponent {
  onCancel() {
    this.props.dispatch(resetDeletingCategory());
  }

  onSubmit() {
    const { dispatch, category, period } = this.props;
    dispatch(postDeleteCategory(category.payload.id, period.data.id, category.payload.name));
  }

  getDeleteCategoryWarningMessage = (categoryCanBeDeleted, categoryHasLineItems) => {
    if (categoryCanBeDeleted) {
      return strings.deleteConfirmation;
    }
    return strings.deleteCategoryWarningMessage(categoryHasLineItems ?
      this.props.deleteCategory.lineItemCount : 0);
  };

  render() {
    const {
      classes,
      deleteCategory,
      category,
    } = this.props;

    if (!deleteCategory.deleting || !category.adding) {
      return (<div />);
    }

    return (
      <Modal open={deleteCategory.deleting} isSubmitting={deleteCategory.submitting}>
        <Choose>
          <When condition={!deleteCategory.loading}>
            <div className={classes.content}>
              <div className={classes.title}>
                {commonStrings.delete} {category.payload.name}
              </div>
              <div className={classes.text}>
                {this.getDeleteCategoryWarningMessage(
                  deleteCategory.categoryCanBeDeleted,
                  deleteCategory.categoryHasLineItems,
                  )}
              </div>
            </div>
            <StyledModalButtons
              onCancel={() => this.onCancel()}
              onAccept={() => this.onSubmit()}
              acceptText={strings.deleteCategory}
              cancelText={commonStrings.cancel}
            />
          </When>
        </Choose>
      </Modal>
    );
  }
}

CategoryDeletionModal.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  deleteCategory: PropTypes.shape({
    deleting: PropTypes.bool.isRequired,
    categoryHasRules: PropTypes.bool.isRequired,
    categoryHasLineItems: PropTypes.bool.isRequired,
    lineItemCount: PropTypes.number.isRequired,
    categoryCanBeDeleted: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  }).isRequired,
  category: PropTypes.shape({
    adding: PropTypes.bool,
    payload: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      color: PropTypes.string,
    }),
  }).isRequired,
  period: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    deleteCategory: state.categorisation.deleteCategory,
    category: state.categorisation.category,
    period: state.periods.period,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(CategoryDeletionModal);
