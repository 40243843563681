export const SET_REFERENCE_LIST_FILE_LOADING = 'REFERENCE_LIST/SET_REFERENCE_LIST_FILE_LOADING';
export const GET_REFERENCE_LIST_ITEMS = 'REFERENCE_LIST/GET_REFERENCE_LIST_ITEMS';
export const GET_REFERENCE_LIST_ITEMS_SUCCESS = 'REFERENCE_LIST/GET_REFERENCE_LIST_ITEMS_SUCCESS';
export const GET_REFERENCE_LIST_ENTRIES = 'REFERENCE_LIST/GET_REFERENCE_LIST_ENTRIES';
export const GET_REFERENCE_LIST_ENTRIES_SUCCESS = 'REFERENCE_LIST/GET_REFERENCE_LIST_ENTRIES_SUCCESS';
export const UPLOAD_AND_REPLACE_REFERENCE_LIST = 'REFERENCE_LIST/UPLOAD_AND_REPLACE_REFERENCE_LIST';
export const RESET_REFERENCE_LIST_ENTRIES = 'REFERENCE_LIST/RESET_REFERENCE_LIST_ENTRIES';
export const CHECK_REFERENCE_LIST_RULES = 'REFERENCE_LIST/CHECK_REFERENCE_LIST_RULES';
export const CHECK_REFERENCE_LIST_RULES_SUCCESS = 'REFERENCE_LIST/CHECK_REFERENCE_LIST_RULES_SUCCESS';
export const OPEN_VIEW_REFERENCE_LIST = 'REFERENCE_LIST/OPEN_VIEW_REFERENCE_LIST';
export const CLOSE_VIEW_REFERENCE_LIST = 'REFERENCE_LIST/CLOSE_VIEW_REFERENCE_LIST';
export const SET_REFERENCE_LIST_FILE_UPLOAD_RESPONSE = 'REFERENCE_LIST/SET_REFERENCE_LIST_FILE_UPLOAD_RESPONSE';
export const POST_DELETE_REFERENCE_LIST = 'REFERENCE_LIST/POST_DELETE_REFERENCE_LIST';
export const RESET_REFERENCE_LISTS = 'REFERENCE_LIST/RESET_REFERENCE_LISTS';
export const RESET_REFERENCE_LISTS_RULES = 'REFERENCE_LIST/RESET_REFERENCE_LISTS_RULES';
export const NAVIGATE_TO_REFERENCE_LISTS = 'REFERENCE_LIST/NAVIGATE_TO_REFERENCE_LISTS';
export const RESET_REFERENCE_LISTS_NAVIGATION = 'REFERENCE_LIST/RESET_REFERENCE_LISTS_NAVIGATION';
export const DELETE_REFERENCE_LIST_ENTRIES = 'REFERENCE_LIST/DELETE_REFERENCE_LIST_ENTRIES';
export const DELETE_REFERENCE_LIST_ENTRIES_SUCCESS = 'REFERENCE_LIST/DELETE_REFERENCE_LIST_ENTRIES_SUCCESS';

export function getReferenceListItems(periodId, maintainRoute = false) {
  return {
    type: GET_REFERENCE_LIST_ITEMS,
    loading: true,
    payload: {
      periodId: periodId,
    },
    maintainRoute,
  };
}

export function getReferenceListItemsSuccess(data) {
  return {
    type: GET_REFERENCE_LIST_ITEMS_SUCCESS,
    loading: false,
    fileLoading: false,
    referenceLists: data,
  };
}

export function getReferenceListEntries(periodId, referenceListId) {
  return {
    type: GET_REFERENCE_LIST_ENTRIES,
    payload: {
      periodId: periodId,
      referenceListId: referenceListId,
    },
    entriesLoading: true,
  };
}

export function getReferenceListEntriesSuccess(data) {
  return {
    type: GET_REFERENCE_LIST_ENTRIES_SUCCESS,
    referenceListEntries: data,
    entriesLoading: false,
  };
}

export function uploadAndReplaceReferenceList(periodId, cloudReference) {
  return {
    type: UPLOAD_AND_REPLACE_REFERENCE_LIST,
    payload: {
      periodId,
      cloudReference,
    },
    fileLoading: true,
    fileUploadResponse: {},
  };
}

export function resetReferenceListEntries() {
  return {
    type: RESET_REFERENCE_LIST_ENTRIES,
  };
}

export function setReferenceListFileLoading(fileLoading) {
  return {
    type: SET_REFERENCE_LIST_FILE_LOADING,
    fileLoading: fileLoading,
  };
}

export function setFileUploadResponse(fileUploadResponse = {}) {
  return {
    type: SET_REFERENCE_LIST_FILE_UPLOAD_RESPONSE,
    fileUploadResponse: fileUploadResponse,
    fileLoading: false,
  };
}

export function checkReferenceListRules(periodId, referenceListId, referenceListName) {
  return {
    type: CHECK_REFERENCE_LIST_RULES,
    loading: true,
    payload: {
      periodId: periodId,
      referenceListId: referenceListId,
    },
    referenceListName,
  };
}

export function checkReferenceListRulesSuccess(data) {
  return {
    type: CHECK_REFERENCE_LIST_RULES_SUCCESS,
    loading: false,
    referenceListRules: data,
  };
}

export function openViewReferenceList(refListId, refList) {
  return {
    type: OPEN_VIEW_REFERENCE_LIST,
    open: true,
    payload: {
      refListId: refListId,
      refList: refList,
    },
  };
}

export function resetReferenceLists() {
  return {
    type: RESET_REFERENCE_LISTS,
  };
}

export function closeViewReferenceList() {
  return {
    type: CLOSE_VIEW_REFERENCE_LIST,
    open: false,
  };
}

export function postDeleteReferenceList(periodId, referenceListId, referenceListName) {
  return {
    type: POST_DELETE_REFERENCE_LIST,
    payload: {
      periodId,
      referenceListId,
    },
    referenceListName,
  };
}

export function resetReferenceListsRules() {
  return {
    type: RESET_REFERENCE_LISTS_RULES,
  };
}

export function navigateToReferenceLists(chevronNavigation = true) {
  return {
    type: NAVIGATE_TO_REFERENCE_LISTS,
    chevronNavigation,
  };
}
export function resetReferenceListsNavigation() {
  return {
    type: RESET_REFERENCE_LISTS_NAVIGATION,
    chevronNavigation: false,
  };
}

export function deleteReferenceLisEntities(periodId, referenceListId, referenceListEntryIds) {
  return {
    type: DELETE_REFERENCE_LIST_ENTRIES,
    loading: true,
    payload: {
      periodId: periodId,
      referenceListId: referenceListId,
      referenceListEntryIds: referenceListEntryIds,
    },
  };
}
export function deleteReferenceLisEntitiesSuccess() {
  return {
    type: DELETE_REFERENCE_LIST_ENTRIES_SUCCESS,
    loading: false,
    fileLoading: false,
  };
}
