import { levels } from 'Constants/EntityLevels';

export const SELECT_NODE = 'ADMIN_TREEPICKER/SELECT_NODE';
export const EDIT_NODE = 'ADMIN_TREEPICKER/EDIT_NODE';
export const RESET_GROUP = 'ADMIN_TREEPICKER/RESET_GROUP';
export const RESET_ENTITY = 'ADMIN_TREEPICKER/RESET_ENTITY';
export const RESET_PROJECT = 'ADMIN_TREEPICKER/RESET_PROJECT';

export const GET_ADMIN_TREEPICKER_DATA = 'ADMIN_TREEPICKER/GET_ADMIN_TREEPICKER_DATA';
export const GET_ADMIN_TREEPICKER_DATA_SUCCESS = 'ADMIN_TREEPICKER/GET_ADMIN_TREEPICKER_DATA_SUCCESS';

export function getAdminTreePickerData(
  resetAdminSelectionNode = false,
  taxContextFilter = 0,
  emailFilter = '',
  licenseTypeFilter = null,
) {
  return {
    type: GET_ADMIN_TREEPICKER_DATA,
    data: [],
    payload: {
      taxContextFilter: taxContextFilter,
      emailFilter: emailFilter,
      licenseType: licenseTypeFilter,
    },
    loading: true,
    resetAdminSelectionNode,
  };
}

export function getAdminTreePickerDataSuccess(data, taxContextFilter = 0, emailFilter = '') {
  return {
    type: GET_ADMIN_TREEPICKER_DATA_SUCCESS,
    data: data,
    loading: false,
    taxContextFilter: taxContextFilter,
    emailFilter: emailFilter,
  };
}

export function selectNode(groupId, entityId, projectId, selectedItemLevel) {
  return {
    type: SELECT_NODE,
    selectedGroupId: groupId,
    selectedEntityId: entityId,
    selectedProjectId: projectId,
    selectedItemLevel: selectedItemLevel,
  };
}

export function editNode(editingItemLevel, editingItem, emailFilter) {
  return {
    type: EDIT_NODE,
    editingItemLevel: editingItemLevel,
    editingItem: editingItem,
    emailFilter: emailFilter,
  };
}

export function resetGroup() {
  return {
    type: RESET_GROUP,
    selectedGroupId: -1,
    selectedEntityId: -1,
    selectedProjectId: -1,
    selectedItemLevel: levels.group,
  };
}

export function resetEntity(groupId) {
  return {
    type: RESET_ENTITY,
    selectedGroupId: groupId,
    selectedEntityId: -1,
    selectedProjectId: -1,
    selectedItemLevel: levels.entity,
  };
}

export function resetProject(groupId, entityId) {
  return {
    type: RESET_PROJECT,
    selectedGroupId: groupId,
    selectedEntityId: entityId,
    selectedProjectId: -1,
    selectedItemLevel: levels.project,
  };
}
