import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getTreePickerData } from 'Store/Areas/Projects/TreePickerActions';
import { currentRouteSelector } from 'Store/Areas/Router/RouteSelectors';
import { selectCreatePeriod, resetCreatePeriod, editPeriod } from 'Store/Areas/Projects/CreatePeriodActions';
import { updateTreePickerSelection, selectPeriod } from 'Store/Areas/Projects/UserTreePickerActions';
import UserSidebarStructure from 'Components/Shared/Sidebar/UserSidebarStructure';
import { routes } from 'Constants/Routes';
import { resetPeriodLock } from 'Store/Areas/App/ConfigActions';
import Welcome from './Welcome';
import ProjectsSidebar from './ProjectsSidebar';

class ProjectsContainer extends React.PureComponent {
  componentDidMount() {
    const { dispatch, currentRoute } = this.props;
    if (currentRoute !== routes.archive) {
      dispatch(getTreePickerData({
        includePeriods: true,
      }));
    }
  }

  onTreeItemClick = (groupId, entityId, projectId, selectedItemLevel) => {
    const { dispatch, projects } = this.props;
    dispatch(updateTreePickerSelection(
      groupId, entityId, projectId,
      selectedItemLevel, projects.userTreePicker,
    ));
  }

  onEditPeriodClick = (groupId, entityId, projectId, periodId, taxContextId, templateId) => {
    this.props.dispatch(editPeriod(
      groupId,
      entityId,
      projectId,
      periodId,
      taxContextId,
      templateId,
    ));
    this.props.dispatch(push(routes.createPeriod));
  }

  onAddPeriodClick = (group, entity, project) => {
    this.props.dispatch(resetPeriodLock());
    if (group) {
      this.props.dispatch(selectCreatePeriod(group, entity, project));
    } else {
      this.props.dispatch(resetCreatePeriod(group, entity, project));
    }
    this.props.dispatch(push(routes.createPeriod));
  }

  onPeriodSelection = (periodId, selectedIndex) => {
    this.props.dispatch(selectPeriod(periodId, selectedIndex));
  }

  render() {
    const { treePicker, userTreePicker } = this.props.projects;
    return (
      <React.Fragment>
        <UserSidebarStructure
          sidebarContent={
            <ProjectsSidebar
              treePickerData={treePicker}
              userTreePicker={userTreePicker}
              onEditPeriodClick={this.onEditPeriodClick}
              onPeriodSelection={this.onPeriodSelection}
              onTreeItemClick={this.onTreeItemClick}
              onAddPeriodClick={this.onAddPeriodClick}
            />
          }
          mainContent={<Welcome />}
          sidebarLoading={treePicker.loading}
          mainLoading={false}
        />
      </React.Fragment>
    );
  }
}

ProjectsContainer.propTypes = {
  currentRoute: PropTypes.string.isRequired,
  projects: PropTypes.shape({
    treePicker: PropTypes.object,
    userTreePicker: PropTypes.object,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    currentRoute: currentRouteSelector(state),
  };
}

export default connect(mapStateToProps)(ProjectsContainer);
