import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import { categoryListTypes } from 'Constants/Categorization/CategoryListTypes';
import { strings } from 'Constants/Categorization/Strings';
import styles from './Category.styles';
import PriorityDropZone from './PriorityDropZone';

class RuleCreationZone extends React.PureComponent {
  render() {
    const {
      classes,
      className,
      children,
      categoryType,
      category,
    } = this.props;
    return (
      <div className={`${classes.root} ${className}`}>
        {children}
        <div className={classes.priorityRow}>
          <Choose>
            <When condition={categoryType === categoryListTypes.categoryType.keywordRule}>
              <PriorityDropZone
                category={category}
                categoryType={categoryListTypes.categoryType.keywordRule}
                isHighPriority
                title={strings.assignHighPriority}
                className={classes.highPriority}
              />
              <PriorityDropZone
                category={category}
                categoryType={categoryListTypes.categoryType.keywordRule}
                title={strings.assignRegularPriority}
                className={classes.regularPriority}
              />
            </When>
            <When condition={categoryType === categoryListTypes.categoryType.customRule}>
              <PriorityDropZone
                category={category}
                categoryType={categoryListTypes.categoryType.customRule}
                isHighPriority
                title={strings.assignHighPriority}
                className={classes.highPriority}
              />
              <PriorityDropZone
                category={category}
                categoryType={categoryListTypes.categoryType.customRule}
                title={strings.assignRegularPriority}
                className={classes.regularPriority}
              />

            </When>
            <When condition={categoryType === categoryListTypes.categoryType.referenceList}>
              <PriorityDropZone
                category={category}
                categoryType={categoryListTypes.categoryType.referenceList}
                isHighPriority
                title={strings.assignHighPriority}
                className={classes.highPriority}
              />
              <PriorityDropZone
                category={category}
                categoryType={categoryListTypes.categoryType.referenceList}
                title={strings.assignRegularPriority}
                className={classes.regularPriority}
              />
            </When>
            <When condition={categoryType === categoryListTypes.categoryType.lineItems}>
              <PriorityDropZone
                category={category}
                categoryType={categoryListTypes.categoryType.lineItems}
                title={strings.assignToCategory}
                className={classes.fullWidth}
              />
            </When>
          </Choose>
        </div>
      </div>
    );
  }
}

RuleCreationZone.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  categoryType: PropTypes.oneOf(Object.values(categoryListTypes.categoryType)).isRequired,
  category: PropTypes.shape({
    id: PropTypes.number,
    colorCode: PropTypes.string,
    name: PropTypes.string,
    amountValue: PropTypes.number,
  }).isRequired,
};

RuleCreationZone.defaultProps = {
  className: '',
  children: undefined,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(RuleCreationZone);
