const styles = theme => ({
  root: {
    marginLeft: 20,
    marginTop: 10,
    marginRight: 20,
  },
  back: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
    fontSize: theme.constants.fontSizes.small,
  },
  chevron: {
    height: 8,
  },
  title: {
    color: theme.palette.primary.darkText,
    fontSize: theme.constants.fontSizes.large,
  },
  body: {
    marginTop: 20,
  },
  tabGrid: {
    marginTop: 20,
  },
  lineItemsTitle: {
    fontSize: theme.constants.fontSizes.large,
    marginBottom: 10,
    marginTop: 10,
    marginLeft: 6,
  },
  apportionmentContainer: {
    width: '100%',
    height: '100%',
  },
});

export default styles;
