import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core';
import { strings } from 'Constants/Rules/Strings';
import { styles } from './HeaderRow.styles';

class BuilderRow extends React.PureComponent {
  render() {
    const {
      width,
      classes,
    } = this.props;
    const rootWidth = width - 20;
    const handleWidth = 20;
    const boxWidth = (rootWidth - handleWidth) / 4;
    return (
      <div className={classes.root} style={{ width: rootWidth }}>
        <div style={{ width: handleWidth }} />
        <div style={{ width: boxWidth }}>{strings.tag}</div>
        <div style={{ width: boxWidth }}>{strings.operator}</div>
        <div style={{ width: boxWidth }}>{strings.condition}</div>
        <div style={{ width: boxWidth }}>{strings.join}</div>
      </div>
    );
  }
}

BuilderRow.propTypes = {
  width: PropTypes.number.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default compose(withStyles(styles), withTheme())(BuilderRow);
