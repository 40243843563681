import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { AutoSizer } from 'react-virtualized';
import { chunkTypes } from 'Store/Areas/Rule/RuleBuilderReducer';
import { DragSource } from 'react-dnd';
import { strings } from 'Constants/Categorization/Strings';
import DragnDrop from 'Constants/DragnDrop';
import { loadInitialRule } from 'Store/Areas/Rule/RuleBuilderActions';
import { styles } from './RulesBuilder.styles';
import HeaderRow from './Components/HeaderRow/HeaderRow';
import BuilderRowDropzone from './Components/BuilderRowDropzone/BuilderRowDropzone';
import {
  withRulesBuilder,
  BuilderRow,
  BuilderBrackets,
  DragRuleHandle,
  FreeTextRule,
} from './Components';

class RulesBuilder extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hoveredIndex: -1,
    };
  }

  componentDidMount() {
    if (this.props.initialRuleId) {
      this.props.dispatch(loadInitialRule(
        this.props.initialRuleId,
        this.props.initialRuleChunks,
        this.props.initialRule,
        this.props.tags,
      ));
    }
  }

  setIsOver = (index) => {
    this.setState({
      hoveredIndex: index,
    });
  }

  render() {
    const {
      rule,
      ruleError,
      ruleChunks,
      classes,
      draggingItemIndex,
      ruleHasError,
      note,
      initialRuleId,
      isDragRuleHandleVisible,
      keywordPanelAvailable,
      isApportionable,
    } = this.props;
    const {
      hoveredIndex,
    } = this.state;
    return (
      <AutoSizer>
        {({ width, height }) => (
          <div style={{ width: width, height: height, overflow: 'hidden' }}>
            <div className={classes.titleBar}>
              <div className={classes.ruleTitle}>
                {initialRuleId === 0 ? strings.createCustomRule : strings.editCustomRule}
              </div>
              <DragRuleHandle
                ruleText={rule}
                note={note}
                hasError={ruleHasError}
                ruleId={initialRuleId}
                isVisible={isDragRuleHandleVisible}
                isApportionable={isApportionable}
              />
            </div>
            <div className={classes.error}>{ruleError}</div>
            <Choose>
              <When condition={!initialRuleId || this.props.initialRuleChunks.length}>
                <div className={classes.rule}>{rule}</div>
                <HeaderRow width={width} />
                <div style={{ maxHeight: height - (initialRuleId === 0 ? 180 : 200), overflow: 'auto' }}>
                  <For each="chunk" of={ruleChunks} index="index">
                    <If condition={chunk.type === chunkTypes.startBracket}>
                      <BuilderBrackets
                        type={chunkTypes.startBracket}
                        key={chunk.id}
                        index={index}
                        isOver={hoveredIndex === index}
                        setIsOver={this.setIsOver}
                      />
                    </If>
                    <If condition={chunk.type === chunkTypes.endBracket}>
                      <BuilderBrackets
                        type={chunkTypes.endBracket}
                        key={chunk.id}
                        index={index}
                        isOver={hoveredIndex === index}
                        setIsOver={this.setIsOver}
                      />
                    </If>
                    <If condition={chunk.type === chunkTypes.ruleRow}>
                      <BuilderRow
                        ruleSegment={chunk}
                        width={width}
                        index={index}
                        isOver={hoveredIndex === index}
                        setIsOver={this.setIsOver}
                        hoveredIndex={hoveredIndex}
                        draggingIndex={draggingItemIndex}
                        keywordPanelAvailable={keywordPanelAvailable}
                      />
                    </If>
                  </For>
                  <If condition={draggingItemIndex > -1}>
                    <div
                      style={{
                        width: width,
                        height: draggingItemIndex === ruleChunks.length ? 40 : 80,
                      }}
                    >
                      <BuilderRowDropzone
                        setIsOver={this.setIsOver}
                        index={ruleChunks.length}
                        isOver={hoveredIndex === ruleChunks.length}
                      />
                    </div>
                  </If>
                </div>
              </When>
              <Otherwise>
                <FreeTextRule width={width} height={height} />
              </Otherwise>
            </Choose>
          </div>
        )}
      </AutoSizer>
    );
  }
}

RulesBuilder.defaultProps = {
  note: '',
  initialRuleChunks: [],
  initialRule: '',
  initialRuleId: 0,
  tags: null,
  isDragRuleHandleVisible: true,
  keywordPanelAvailable: false,
  isApportionable: false,
};

RulesBuilder.propTypes = {
  rule: PropTypes.string.isRequired,
  ruleError: PropTypes.string.isRequired,
  ruleChunks: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.object,
  })).isRequired,
  ruleHasError: PropTypes.bool.isRequired,
  draggingItemIndex: PropTypes.number.isRequired,
  note: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  initialRuleChunks: PropTypes.arrayOf(PropTypes.shape({
    rawCondition: PropTypes.string,
    rawTag: PropTypes.string,
    operator: PropTypes.string,
    join: PropTypes.string,
  })),
  initialRule: PropTypes.string,
  initialRuleId: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  })),
  isDragRuleHandleVisible: PropTypes.bool,
  keywordPanelAvailable: PropTypes.bool,
  isApportionable: PropTypes.bool,
};


const spec = {
  beginDrag: () => {},
  isDragging: (props, monitor) => monitor.getItemType() === DragnDrop.customRuleSegment,
};

// Specifies the props to inject into your component
function collect(connect, monitor) {
  return {
    draggingItemIndex: monitor.isDragging() ? monitor.getItem().index : -1,
  };
}

export default compose(
  DragSource(DragnDrop.customRuleSegment, spec, collect),
  withStyles(styles),
  withTheme(),
  withRulesBuilder(),
)(RulesBuilder);
