import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { title } from 'Constants/App/Titles/Strings';
import SidebarStructure from 'Components/Shared/Sidebar/SidebarStructure';
import Blur from 'Components/Shared/Blur/Blur';
import AuthorisedComponent, { constants } from 'Components/Shared/AuthorisedComponent/AuthorisedComponent';
import { getAdminTreePickerData } from 'Store/Areas/Setup/Admin/AdminTreePickerActions';
import GroupCreationSidebar from './GroupCreationSidebar/GroupCreationSidebar';
import ProjectCreationSidebar from './ProjectCreationSidebar/ProjectCreationSidebar';
import EntityCreationSidebar from './EntityCreationSidebar/EntityCreationSidebar';
import AdminContent from './AdminContent/AdminContent';
import AdminTreepicker from './AdminTreePicker/AdminTreepicker';
import WelcomeMessage from './WelcomeMessage';

class AdminContainer extends React.PureComponent {
  componentDidMount() {
    document.title = title.admin;
    this.props.dispatch(getAdminTreePickerData());
  }

  render() {
    const {
      admin,
      adminTreePicker,
    } = this.props;

    const sidebarLoading =
      admin.group.loading ||
      admin.project.loading ||
      admin.entity.loading ||
      adminTreePicker.loading;

    const creating =
      admin.group.showForm ||
      admin.project.showForm ||
      admin.entity.showForm;

    return (
      <Fragment>
        <SidebarStructure
          sidebarLoading={sidebarLoading}
          mainLoading={admin.getUsers.loading}
          sidebarContent={
            <Choose>
              <When condition={admin.group.showForm}>
                <AuthorisedComponent
                  requiredPermissions={constants.permissionLevels.requireSysAdmin}
                >
                  <GroupCreationSidebar />
                </AuthorisedComponent>
              </When>
              <When condition={admin.project.showForm}>
                <AuthorisedComponent
                  requiredPermissions={constants.permissionLevels.requireEntityAdmin}
                >
                  <ProjectCreationSidebar />
                </AuthorisedComponent>
              </When>
              <When condition={admin.entity.showForm}>
                <AuthorisedComponent
                  requiredPermissions={constants.permissionLevels.requireGroupAdmin}
                >
                  <EntityCreationSidebar />
                </AuthorisedComponent>
              </When>
              <Otherwise>
                <AuthorisedComponent
                  requiredPermissions={constants.permissionLevels.requireEntityAdmin}
                >
                  <AdminTreepicker />
                </AuthorisedComponent>
              </Otherwise>
            </Choose>
          }
          mainContent={
            <Blur blur={creating} >
              <Choose>
                <When condition={adminTreePicker.editingItem}>
                  <AdminContent />
                </When>
                <Otherwise>
                  <WelcomeMessage />
                </Otherwise>
              </Choose>
            </Blur>
          }
        />
      </Fragment>
    );
  }
}

AdminContainer.propTypes = {
  admin: PropTypes.shape({
    getUsers: PropTypes.object,
    removeAccess: PropTypes.object,
    addUsers: PropTypes.object,
    project: PropTypes.object,
    entity: PropTypes.object,
    group: PropTypes.object,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  adminTreePicker: PropTypes.shape({
    selectedGroupId: PropTypes.number,
    selectedEntityId: PropTypes.number,
    selectedProjectId: PropTypes.number,
    selectedItemLevel: PropTypes.number,
    editingItemLevel: PropTypes.number,
    editingItem: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    admin: state.setup.admin,
    adminTreePicker: state.setup.admin.adminTreePicker,
  };
}

export default connect(mapStateToProps)(AdminContainer);
