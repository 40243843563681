export const styles = theme => ({
  root: {
    height: 'Calc(100vh - 200px)',
    padding: '15px 30px 30px 30px',
    margin: '15px 30px 30px 20px',
    display: 'flex',
    flexDirection: 'column',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 20,
  },
  flex: {
    flexGrow: 1,
  },
  description: {
    fontSize: theme.constants.fontSizes.regular,
    marginRight: 50,
    lineHeight: 1.5,
  },
  proceedButton: {
    width: 500,
  },
  disabled: {
    opacity: 0.6,
  },
  label: {
    color: theme.palette.primary.contrastText,
  },
  tableContainer: {
    flexGrow: 1,
    marginTop: 30,
    fontSize: theme.constants.fontSizes.regular,
  },
  tableHeader: {
    fontWeight: 'bold',
  },
  tableCell: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.messageBackground,
  },
  arrowButton: {
    flex: 1,
    width: '100%',
    display: 'flex',
  },
  backButton: {
    width: '100%',
  },
  skipButton: {
    width: '100%',
    justifyContent: 'flex-end',
  },
  maxLineItemsInContraContainer: {
    flexDirection: 'row',
    display: 'flex',
    paddingBottom: 6,
    paddingTop: 20,
  },
  maxLineItemsInContra: {
    marginRight: 80,
  },
  disabledAsInUse: {
    pointerEvents: 'none',
  },
});
