import { updateTreePickerState } from 'Helpers/TreePickerHelpers';
import {
  SELECT_NODE,
  EDIT_NODE,
  RESET_GROUP,
  RESET_ENTITY,
  RESET_PROJECT,
  GET_ADMIN_TREEPICKER_DATA,
  GET_ADMIN_TREEPICKER_DATA_SUCCESS,
} from 'Store/Areas/Setup/Admin/AdminTreePickerActions';

const initialState = {
  selectedGroupId: -1,
  selectedEntityId: -1,
  selectedProjectId: -1,
  selectedItemLevel: -1,
  editingItemLevel: 0,
  editingItem: null,
  data: [],
  loading: false,
};

export default function adminTreePickerReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_NODE:
    case RESET_GROUP:
    case RESET_ENTITY:
    case RESET_PROJECT:
      return Object.assign({}, state, {
        selectedGroupId: action.selectedGroupId,
        selectedEntityId: action.selectedEntityId,
        selectedProjectId: action.selectedProjectId,
        selectedItemLevel: action.selectedItemLevel,
      });
    case EDIT_NODE:
      return Object.assign({}, state, {
        editingItemLevel: action.editingItemLevel,
        editingItem: action.editingItem,
        emailFilter: action.emailFilter,
      });
    case GET_ADMIN_TREEPICKER_DATA:
    case GET_ADMIN_TREEPICKER_DATA_SUCCESS:
      return updateTreePickerState(action, state, true);
    default:
      return state;
  }
}
