import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme, Grid, FormControlLabel, Typography, IconButton } from '@material-ui/core';
import { tagSelector } from 'Store/Areas/Period/PeriodSelectors';
import { TagTypes } from 'Constants/Categorization/Tags';
import { strings as categorisationStrings } from 'Constants/Categorization/Strings';
import { strings } from 'Constants/Categorization/CategoryReview/LineItemsCategorisationReview/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { numericOperators, textOperators } from 'Constants/Rules/RuleConstants';
import Select, { constants as constantsSelect } from 'Components/Shared/Selects/Select';
import Search from 'Components/Shared/Inputs/Search';
import Checkbox from 'Components/Shared/Checkbox/Checkbox';
import SearchIcon from 'Assets/Images/search-ic.svg';
import RemoveIcon from 'Assets/Images/exit-ic.svg';
import { styles } from './TagSearchFilter.styles';

const numericOperatorFilters = [
  textOperators.equals,
  ...Object.values(numericOperators),
].map(op => ({
  id: op,
  name: op,
}));

const idSeparator = '::';

class TagSearchFilter extends React.PureComponent {
  state = {
    tagFilters: [...this.getInitialTagFilters()],
  };

  getNewNumericOperator(numericTagId) {
    return numericOperatorFilters
      .find(op => this.state.tagFilters
        .every(tf => tf.tagId !== numericTagId || tf.operator !== op.id)).id;
  }

  getNewTagFilterForTag(tag, oldTagFilter) {
    const newTagFilter = {
      tagId: tag.id,
      tagType: tag.type,
      searchTerm: '',
    };

    if (oldTagFilter
      && (tag.type === TagTypes.Numeric) === (oldTagFilter.tagType === TagTypes.Numeric)) {
      newTagFilter.searchTerm = oldTagFilter.searchTerm;

      newTagFilter.operator = tag.type === TagTypes.Numeric
        && this.state.tagFilters
          .some(tf => tf.tagId === newTagFilter.tagId && tf.operator === newTagFilter.operator)
        ? this.getNewNumericOperator(newTagFilter.tagId)
        : oldTagFilter.operator;
    } else {
      newTagFilter.operator = tag.type === TagTypes.Numeric
        ? this.getNewNumericOperator(newTagFilter.tagId)
        : textOperators.contains;
    }

    return {
      ...newTagFilter,
    };
  }

  getInitialTagFilters() {
    return [
      this.getNewTagFilterForTag(this.props.tags
        .find(x => x.name === commonStrings.description)),
    ];
  }

  handleSelectedTagChange = (tagId, id) => {
    const index = this.getIndexFromId(id);
    const tagFilters = [...this.state.tagFilters];

    const newTag = this.props.tags.find(t => t.id === tagId);

    tagFilters[index] = this.getNewTagFilterForTag(newTag, tagFilters[index]);

    this.setState({
      tagFilters,
    });
  }

  handleSelectedNumericOperatorChange = (newOperator, id) => {
    const index = this.getIndexFromId(id);
    const tagFilters = [...this.state.tagFilters];

    tagFilters[index].operator = newOperator;

    this.setState({
      tagFilters,
    });
  }

  handleSearchTermChange = (searchTerm, id) => {
    const index = this.getIndexFromId(id);
    const tagFilters = [...this.state.tagFilters];

    tagFilters[index].searchTerm = searchTerm;

    this.setState({
      tagFilters,
    });
  }

  handleExactMatchChange = (exactMatch, id) => {
    const index = this.getIndexFromId(id);
    const tagFilters = [...this.state.tagFilters];

    tagFilters[index].operator = exactMatch
      ? textOperators.equals
      : textOperators.contains;

    this.setState({
      tagFilters,
    });
  }

  handleSearch = () => {
    this.props.onSearch(this.state.tagFilters.filter(x => x.searchTerm !== ''));
  }

  handleRemoveAllClick = () => {
    this.setState({
      tagFilters: [...this.getInitialTagFilters()],
    });

    this.props.onSearch([]);
  }

  handleAddClick = () => {
    this.setState({
      tagFilters: [
        ...this.state.tagFilters,
        this.getNewTagFilterForTag(this.getTagForNewFilter()),
      ],
    });
  }

  handleRemoveIconClick = (index) => {
    const tagFilters = [...this.state.tagFilters];
    tagFilters.splice(index, 1);

    this.setState({
      tagFilters,
    });
  }

  getTagForNewFilter() {
    return this.props.tags
      .find((t) => {
        if (t.type !== TagTypes.Numeric) {
          return this.state.tagFilters
            .every(tf => t.id !== tf.tagId);
        }

        return this.state.tagFilters
          .reduce((x, y) => (y.tagId === t.id ? x + 1 : x), 0) !== numericOperatorFilters.length;
      });
  }

  getIndexFromId(id) {
    return Number(id.substring(id.lastIndexOf(idSeparator) + idSeparator.length));
  }

  getTags(tagFilter) {
    return this.props.tags
      .filter((t) => {
        if (t.id === tagFilter.tagId) {
          return true;
        }

        if (t.type !== TagTypes.Numeric) {
          return this.state.tagFilters
            .every(tf => t.id !== tf.tagId);
        }

        return this.state.tagFilters
          .reduce((x, y) => (y.tagId === t.id ? x + 1 : x), 0) !== numericOperatorFilters.length;
      });
  }

  getNumericOperators(tagFilter) {
    return numericOperatorFilters
      .filter(op => op.id === tagFilter.operator
        || this.state.tagFilters.every(tf => tf.tagId !== tagFilter.tagId
          || tf.operator !== op.id));
  }

  renderEmptyInputAdornment() {
    return null;
  }

  renderTagFilter = (tagFilter, index) => {
    const { classes } = this.props;

    return (
      <Fragment key={`tag-filter-${tagFilter.tagId}-${index}`}>
        <Grid item xs={3} className={classes.tagSelectWrapper}>
          <If condition={this.state.tagFilters.length > 1}>
            <IconButton
              onClick={() => this.handleRemoveIconClick(index)}
              className={classes.removeIcon}
              title={commonStrings.remove}
            >
              <img
                alt={commonStrings.remove}
                src={RemoveIcon}
              />
            </IconButton>
          </If>
          <Select
            id={`tag-${tagFilter.tagId}${idSeparator}${index}`}
            data={this.getTags(tagFilter)}
            onChange={this.handleSelectedTagChange}
            value={tagFilter.tagId}
            colorScheme={constantsSelect.colorScheme.lightBlue}
            preventPlaceholder
          />
        </Grid>
        {
          tagFilter.tagType === TagTypes.Numeric &&
          <Grid item xs={3}>
            <Select
              id={`numeric-operator-${tagFilter.tagId}${idSeparator}${index}`}
              data={this.getNumericOperators(tagFilter)}
              onChange={this.handleSelectedNumericOperatorChange}
              value={tagFilter.operator}
              colorScheme={constantsSelect.colorScheme.lightBlue}
              preventPlaceholder
            />
          </Grid>
        }
        <Grid item xs={tagFilter.tagType === TagTypes.Numeric ? 3 : 6}>
          <Search
            className={classes.searchBox}
            id={`search-${tagFilter.tagId}${idSeparator}${index}`}
            placeholder={categorisationStrings.searchPlaceholder}
            onChange={this.handleSearchTermChange}
            onSearch={this.handleSearch}
            value={tagFilter.searchTerm}
            type={tagFilter.tagType === TagTypes.Numeric ? 'number' : 'text'}
            renderInputAdornment={this.renderEmptyInputAdornment}
          />
        </Grid>
        <Grid item xs={3}>
          {
            tagFilter.tagType !== TagTypes.Numeric &&
            <FormControlLabel
              control={
                <Checkbox
                  id={`exact-match-${tagFilter.tagId}${idSeparator}${index}`}
                  checked={tagFilter.operator === textOperators.equals}
                  onChange={this.handleExactMatchChange}
                />
              }
              label={
                <Typography
                  className={classes.label}
                >
                  {strings.exactMatch}
                </Typography>
              }
            />
          }
        </Grid>
      </Fragment>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Grid item xs={9}>
          <span
            className={classes.labelDark}
          >
            {strings.tagFilters}
          </span>
        </Grid>
        <Grid item xs={3}>
          <button
            className={classes.linkButton}
            onClick={this.handleRemoveAllClick}
          >
            {strings.removeAllTagFilters}
          </button>
        </Grid>
        <Grid
          container
          spacing={this.props.spacing}
          className={this.props.className}
        >
          {this.state.tagFilters.map(this.renderTagFilter)}
        </Grid>
        <Grid item xs={11}>
          <If condition={!!this.getTagForNewFilter()}>
            <button
              className={classes.linkButton}
              onClick={this.handleAddClick}
            >
              {strings.addTagFilter}
            </button>
          </If>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={this.handleSearch}
            title={strings.advancedSearch}
          >
            <img
              alt={strings.advancedSearch}
              src={SearchIcon}
            />
          </IconButton>
        </Grid>
      </Fragment>
    );
  }
}

TagSearchFilter.defaultProps = {
  className: '',
  spacing: 8,
};

TagSearchFilter.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onSearch: PropTypes.func.isRequired,
  className: PropTypes.string,
  spacing: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    tags: tagSelector(state)
      .filter(t => t.type === TagTypes.Text || t.type === TagTypes.TextWholeValue
        || t.type === TagTypes.Numeric),
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTheme(),
)(TagSearchFilter);
