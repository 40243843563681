export const strings = {
  addTagButton: '+ Add tag',
  tagsTitle: 'Tags:',
  mandatoryTags: 'mandatory',
  addTagModalTitle: 'Add a new tag',
  addTagModalText:
    'Please assign this tag a name, data type and select whether ' +
    'this tag should be recognised as a whole value cell or split ' +
    'value. A whole value cell recognises the entire cell entry as a ' +
    'keyword. A split value cell takes individual elements within a ' +
    'cell as separate keywords.',
  addTagModalAccept: 'Create tag',
  addTagModalCancel: 'Cancel',
  pleaseMap: 'Please map ',
  byDragging:
    ' by dragging the appropriate tag on to each of the most important columns. This mapping will be stored and automatically applied in future periods. All columns within your uploaded file will be imported, but the contents of any columns not mapped will not be available for the purposes of writing categorisation rules.',
  process: 'Process',
  tagNameRequired: 'A tag name is required.',
  tagNameExists: 'This tag already exists.',
  dataTypeRequired: 'Please set a data type.',
  dragTagHere: 'Drag tag here',
  dragTagWrongType: 'Tag not applicable',
  value: 'Value',
  forbiddenCharacters: "Tag names can only contain letters, numbers and () - _ ' & . ,",
  beginOrEndSpaces: 'Tag names must not begin or end with spaces.',
  addTagError: 'Tag not successfully added, please try again.',
  mapTagError: 'Tag not successfully mapped.',
  tagNameLength: 'Tag names must be 50 characters or less.',
  mandatoryTagsModalTitle: 'Mandatory tags',
  mandatoryTagsModalText: 'Please map all mandatory tags to continue.',
  invalidTagsModalTitle: 'Invalid tags',
  invalidTagsModalText: 'Please modify the below tag name that matches the system variables to continue.',
  removeTag: 'Remove Tag',
  deleteTagTitle: 'Delete this tag?',
  deleteTagText: tagName => `Are you sure you want to delete the '${tagName}' tag?`,
  unmappedTagsModalTitle: 'Un-mapped tags',
  unmappedTagsModalText: 'There are un-mapped (non-mandatory) tags. Rules that use un-mapped tags will be deleted.',
};
