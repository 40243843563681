import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';

export const CATEGORISE_ITEMS = 'CATEGORISATION/CATEGORISE_ITEMS';
export const REQUEST_APPORTIONMENT = 'CATEGORISATION/REQUEST_APPORTIONMENT';
export const REQUEST_TAG_SELECTION = 'CATEGORISATION/REQUEST_TAG_SELECTION';
export const RESET_CATEGORISATION_CREATION = 'CATEGORISATION/RESET_CATEGORISATION_CREATION';
export const REQUEST_CATEGORISATION_REASON = 'CATEGORISATION/REQUEST_CATEGORISATION_REASON';

export function categoriseItems(
  periodId,
  categories,
  categoryType,
  payload = {},
  searchTerm = '',
  categorisationReason = '',
  contraShelfLineItemIds = [],
  categorizationType = categorizationTypes.uncategorized,
  isSubstring = false,
) {
  const { lineItemId, ...ruleProperties } = payload;

  if (!payload.lineItemIds && payload.lineItemId) {
    ruleProperties.lineItemIds = [lineItemId];
  }

  return {
    type: CATEGORISE_ITEMS,
    periodId,
    isSubstring: isSubstring,
    categories: categories.map(x => ({
      categoryId: x.id === undefined ? x.categoryId : x.id,
      categoryName: x.name,
      percentage: x.percentage,
      reason: categorisationReason,
      color: x.color,
    })),
    searchTerm: payload.searchTerm === undefined ? searchTerm : payload.searchTerm,
    contraShelfLineItemIds: payload.contraShelfLineItemIds === undefined
      ? contraShelfLineItemIds : payload.contraShelfLineItemIds,
    categorizationType: payload.categorizationType === undefined
      ? categorizationType : payload.categorizationType,
    displayLineItemsCategorisationReasonModal: categorisationReason === '',
    previousCategoryId: payload.previousCategoryId ? payload.previousCategoryId : 0,
    categoryType,
    ruleProperties: {
      ruleText: '',
      note: '',
      keywords: [],
      isHighPriority: false,
      referenceListName: '',
      lineItemIds: [],
      selectedTag: null,
      ...ruleProperties,
    },
  };
}

export function requestApportionment() {
  return {
    type: REQUEST_APPORTIONMENT,
  };
}

export function requestTagSelection() {
  return {
    type: REQUEST_TAG_SELECTION,
  };
}

export function resetCategorisationCreation() {
  return {
    type: RESET_CATEGORISATION_CREATION,
  };
}

export function requestCategorisationReason() {
  return {
    type: REQUEST_CATEGORISATION_REASON,
  };
}
