import { put } from 'redux-saga/effects';
import { categoryListTypes } from 'Constants/Categorization/CategoryListTypes';
import { CategorisationStrategyHandler } from './CategorisationStrategyHandler';
import * as RuleActions from '../CategorisationActions';

export const customRuleStrategyHandler = new CategorisationStrategyHandler(
  categoryListTypes.categoryType.customRule,
  function* handleCategoryStrategy(action) {
    if (!this.checkCategoriesTotalTo1(action.categories)) {
      yield put(RuleActions.requestApportionment());
      return;
    }
    const payload = this.getCustomRulePayload(action);

    yield this.handleRuleCreation(payload, action.periodId);
  },
);
