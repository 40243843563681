export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 0,
    height: '100%',
  },
  title: {
    fontSize: theme.constants.fontSizes.large,
    marginBottom: 5,
  },
  textContainer: {
    flexGrow: 1,
    margin: 20,
  },
  text: {
    fontSize: theme.constants.fontSizes.small,
    marginBottom: 5,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  rotate: {
    transform: 'rotate(270deg)',
    height: 18,
    width: 18,
  },
});
