import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import {
  withStyles,
  withTheme,
} from '@material-ui/core';
import Buttons from 'Components/Shared/Modal/StyledModal.Buttons';
import SidebarInput from 'Components/Shared/Inputs/SidebarInput';
import SimpleButton from 'Components/Shared/Buttons/SimpleButton/SimpleButton';
import AdminDeleteModal from 'Components/Setup/Admin/AdminDeleteModal';
import Select from 'Components/Shared/Selects/Select';
import { getCreateGroupSchema } from 'Constants/Setup/Admin/CreateGroupSchema';
import { cancelGroupCreation, postGroup, deleteGroup } from 'Store/Areas/Setup/Admin/GroupActions';
import { strings } from 'Constants/Setup/Admin/Strings';
import { commonStrings } from 'Constants/CommonStrings';
import { licenseTypes } from 'Constants/LicenseTypes';
import { triStateBoolean } from 'Constants/TriStateBoolean';
import styles from './GroupCreationSidebar.styles';

class GroupCreationSidebar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDeleteModalOpen: false,
    };
  }

  onRemoveGroup = () => {
    this.setState({
      isDeleteModalOpen: true,
    });
  }
  onConfirmDelete = () => {
    const { dispatch, group } = this.props;
    dispatch(deleteGroup(group.payload.id, group.payload.name));
    this.onCloseDelete();
  }

  onCloseDelete = () => {
    this.setState({
      isDeleteModalOpen: false,
    });
  }

  cancelCreateGroup() {
    this.props.dispatch(cancelGroupCreation());
  }

  getExpectedFee = (expectedFee) => {
    if (expectedFee === null) {
      return '';
    }
    return expectedFee;
  }

  postNewGroup(
    groupName,
    licenseType,
    engagingEntityName,
    opportunityNumber,
    engagementLead,
    engagementManager,
    engagementNumber,
    notes,
    activeClient,
    expectedFee,
    serviceCode,
  ) {
    const { dispatch, group } = this.props;
    let payload = {};

    if (group.isEdit) {
      payload = {
        id: group.payload.id,
        name: groupName,
        licenseType: licenseType,
        engagingEntityName: engagingEntityName,
        opportunityNumber: opportunityNumber,
        engagementLead: engagementLead,
        engagementManager: engagementManager,
        engagementNumber: engagementNumber,
        notes: notes,
        activeClient: activeClient,
        expectedFee: expectedFee,
        serviceCode: serviceCode,
      };
    } else {
      payload = {
        name: groupName,
        licenseType: licenseType,
        engagingEntityName: engagingEntityName,
        opportunityNumber: opportunityNumber,
        engagementLead: engagementLead,
        engagementManager: engagementManager,
        engagementNumber: engagementNumber,
        notes: notes,
        activeClient: activeClient,
        expectedFee: expectedFee,
        serviceCode: serviceCode,
      };
    }
    dispatch(postGroup(payload, group.isEdit));
  }

  render() {
    const {
      classes,
      adminTreePicker,
      group,
    } = this.props;
    const {
      isDeleteModalOpen,
    } = this.state;
    const groupNames = adminTreePicker.data
      .filter(grp => grp.id !== group.payload.id)
      .map(groupItem => groupItem.name);
    return (
      <Fragment>
        {
          group.payload.entities &&
          <AdminDeleteModal
            onConfirm={this.onConfirmDelete}
            onCancel={this.onCloseDelete}
            isModalOpen={isDeleteModalOpen}
            title={strings.deleteGroup}
            message={group.payload.entities.length > 0 ?
              strings.deleteGroupChild : strings.deleteGroupNoChild}
            canDelete={group.payload.entities.length === 0}
          />
        }
        <Formik
          initialValues={{
            name: group.isEdit ? group.payload.name : '',
            licenseType: group.isEdit ? group.payload.licenseType : 0,
            engagingEntityName: group.isEdit ? group.payload.engagingEntityName : '',
            opportunityNumber: group.isEdit ? group.payload.opportunityNumber : '',
            engagementLead: group.isEdit ? group.payload.engagementLead : '',
            engagementManager: group.isEdit ? group.payload.engagementManager : '',
            engagementNumber: group.isEdit ? group.payload.engagementNumber : '',
            notes: group.isEdit ? group.payload.notes : '',
            activeClient: group.isEdit ? group.payload.activeClient : 0,
            expectedFee: group.isEdit ? this.getExpectedFee(group.payload.expectedFee) : '',
            serviceCode: group.isEdit ? group.payload.serviceCode : '',
          }}
          validationSchema={getCreateGroupSchema(groupNames)}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            this.postNewGroup(
              values.name,
              values.licenseType,
              values.engagingEntityName,
              values.opportunityNumber,
              values.engagementLead,
              values.engagementManager,
              values.engagementNumber,
              values.notes,
              values.activeClient,
              this.getExpectedFee(values.expectedFee),
              values.serviceCode,
            );
          }}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
          }) => (
            <form className={classes.form} onSubmit={handleSubmit}>
              <div className={classes.root}>
                <div className={classes.text}>
                  {group.isEdit ? strings.editGroup : strings.addGroup}
                </div>
                <div className={classes.content}>
                  <SidebarInput
                    label={strings.groupLabel}
                    id="name"
                    value={values.name}
                    onChange={value => setFieldValue('name', value)}
                    touched={touched.name}
                    error={errors.name}
                    className={classes.input}
                    placeholder={strings.groupNamePlaceholder}
                  />
                  <Select
                    id="licenseType"
                    label={strings.licenseLabel}
                    value={values.licenseType}
                    error={touched.licenseType ? errors.licenseType : ''}
                    data={licenseTypes}
                    onChange={value => setFieldValue('licenseType', value)}
                    className={classes.fieldMargin}
                    placeholder={strings.groupLicensePlaceholder}
                  />
                  <SidebarInput
                    label={strings.engagingEntityNameLabel}
                    id="engagingEntityName"
                    value={values.engagingEntityName}
                    onChange={value => setFieldValue('engagingEntityName', value)}
                    touched={touched.engagingEntityName}
                    error={touched.engagingEntityName ? errors.engagingEntityName : ''}
                    className={classes.input}
                    placeholder={strings.engagingEntityNamePlaceholder}
                  />
                  <SidebarInput
                    label={strings.opportunityNumberLabel}
                    id="opportunityNumber"
                    value={values.opportunityNumber}
                    onChange={value => setFieldValue('opportunityNumber', value)}
                    touched={touched.opportunityNumber}
                    error={touched.opportunityNumber ? errors.opportunityNumber : ''}
                    className={classes.input}
                    placeholder={strings.opportunityNumberPlaceholder}
                  />
                  <SidebarInput
                    label={strings.engagementLeadLabel}
                    id="engagementLead"
                    value={values.engagementLead}
                    onChange={value => setFieldValue('engagementLead', value)}
                    touched={touched.engagementLead}
                    error={touched.engagementLead ? errors.engagementLead : ''}
                    className={classes.input}
                    placeholder={strings.enagagementLeadPlaceholder}
                  />
                  <SidebarInput
                    label={strings.engagementManagerLabel}
                    id="engagementManager"
                    value={values.engagementManager}
                    onChange={value => setFieldValue('engagementManager', value)}
                    touched={touched.engagementManager}
                    error={touched.engagementManager ? errors.engagementManager : ''}
                    className={classes.input}
                    placeholder={strings.engagementManagerPlaceholder}
                  />
                  <SidebarInput
                    label={strings.engagementNumberLabel}
                    id="engagementNumber"
                    value={values.engagementNumber}
                    onChange={(value) => {
                      const positiveNumerRegex = /^([0-9]+(\.[0-9]+)?)?$/;
                      if (positiveNumerRegex.test(value)) {
                        setFieldValue('engagementNumber', value);
                      }
                    }}
                    className={classes.input}
                    placeholder={strings.engagementNumberPlaceholder}
                  />
                  <SidebarInput
                    label={strings.notesLabel}
                    id="notes"
                    value={values.notes}
                    onChange={value => setFieldValue('notes', value)}
                    className={classes.input}
                    placeholder={strings.notesPlaceholder}
                  />
                  <Select
                    id="activeClient"
                    label={strings.activeClientLabel}
                    value={values.activeClient}
                    error={touched.activeClient ? errors.activeClient : ''}
                    data={triStateBoolean}
                    onChange={value => setFieldValue('activeClient', value)}
                    className={classes.fieldMargin}
                    placeholder={strings.activeClientPlaceholder}
                  />
                  <SidebarInput
                    label={strings.expectedFeeLabel}
                    id="expectedFee"
                    value={String(values.expectedFee)}
                    onChange={(value) => {
                      const positiveNumerRegex = /^([0-9]+(\.[0-9]+)?)?$/;
                      if (positiveNumerRegex.test(value)) {
                        setFieldValue('expectedFee', value);
                      }
                    }}
                    className={classes.input}
                    placeholder={strings.expectedFeePlaceholder}
                  />
                  <SidebarInput
                    id="serviceCode"
                    label={strings.serviceCodeLabel}
                    value={values.serviceCode}
                    onChange={(value) => {
                    const positiveNumerRegex = /^([0-9]+(\.[0-9]+)?)?$/;
                    if (positiveNumerRegex.test(value)) {
                      setFieldValue('serviceCode', value);
                    }
                  }}
                    className={classes.input}
                    placeholder={strings.serviceCodePlaceholder}
                  />
                </div>
                <If condition={group.isEdit}>
                  <SimpleButton className={classes.deleteButton} onClickEvent={this.onRemoveGroup}>
                    {strings.deleteGroup}
                  </SimpleButton>
                </If>
              </div>
              <div className={classes.buttons}>
                <Buttons
                  onCancel={() => this.cancelCreateGroup()}
                  acceptText={group.isEdit ? commonStrings.saveChanges : strings.createGroup}
                  cancelText={commonStrings.cancel}
                />
              </div>
            </form>
            )}
        />
      </Fragment>

    );
  }
}

GroupCreationSidebar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  adminTreePicker: PropTypes.shape({
    selectedGroupId: PropTypes.number,
    selectedEntityId: PropTypes.number,
    selectedProjectId: PropTypes.number,
    selectedItemLevel: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired),
  }).isRequired,
  group: PropTypes.shape({
    isEdit: PropTypes.bool.isRequired,
    payload: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      licenseType: PropTypes.number,
      entities: PropTypes.array,
      opportunityNumber: PropTypes.string,
      engagementLead: PropTypes.string,
      engagementManager: PropTypes.string,
      engagementNumber: PropTypes.string,
      notes: PropTypes.string,
      activeClient: PropTypes.number,
      expectedFee: PropTypes.number,
      serviceCode: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    adminTreePicker: state.setup.admin.adminTreePicker,
    group: state.setup.admin.group,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(GroupCreationSidebar);
