import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles, withTheme, Card } from '@material-ui/core';
import Button, { constants } from 'Components/Shared/Buttons/Button';
import Message from 'Components/Shared/Message/Message';
import { strings } from 'Constants/Setup/Admin/Strings';
import { levelName, levels } from 'Constants/EntityLevels';
import AuthorisedComponent, { constants as permissions } from 'Components/Shared/AuthorisedComponent/AuthorisedComponent';
import { postToggleAccess } from 'Store/Areas/Setup/Admin/ToggleAccessActions';
import { postRemoveAccess, toggleRemoveAccessModal, resetRemoveAccess } from 'Store/Areas/Setup/Admin/RemoveAccessActions';
import { postNewUser, toggleAddUserModal } from 'Store/Areas/Setup/Admin/AddUserActions';
import { addSelectedUser, removeSelectedUser } from 'Store/Areas/Setup/Admin/GetUserActions';
import AddUsersModal from 'Components/Setup/Admin/AddUsersModal/AddUsersModal';
import AddUserConfirmModal from 'Components/Setup/Admin/AddUsersModal/AddUserConfirmModal';
import RemoveAccessModal from 'Components/Setup/Admin/RemoveAccessModal/RemoveAccessModal';
import { commonStrings } from 'Constants/CommonStrings';
import { licenseTypes } from 'Constants/LicenseTypes';
import styles from './AdminContent.styles';
import UsersTable from './UsersTable';

class AdminContent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      firstName: '',
      lastName: '',
    };
  }
  onUserSelectionEvent(isChecked, id) {
    const { dispatch } = this.props;
    if (isChecked) {
      dispatch(addSelectedUser(id));
    } else {
      dispatch(removeSelectedUser(id));
    }
  }

  onAccessToggled(userId, readOnly) {
    const { editingItemLevel, editingItem } = this.props.adminTreePicker;
    this.props.dispatch(postToggleAccess(userId, editingItem.id, editingItemLevel, readOnly));
  }

  onRemoveAccessClick() {
    const { editingItem, editingItemLevel, selectedGroupId } = this.props.adminTreePicker;
    const { users, selectedUserIds } = this.props.admin.getUsers;
    const userEmail = this.props.permissions.emailAddress;
    let removingSelf = false;
    const thisUserList = users.filter(x =>
      x.emailAddress.toUpperCase() === userEmail.toUpperCase());
    if (thisUserList.length > 0) {
      const userId = thisUserList[0].id;
      removingSelf = selectedUserIds.filter(x => x === userId).length > 0;
    }
    const emails = users.filter(x => selectedUserIds.includes(x.id)).map(x => x.emailAddress);
    this.props.dispatch(postRemoveAccess(
      selectedUserIds,
      editingItem.id,
      editingItemLevel,
      removingSelf,
      selectedGroupId <= 0 ? editingItem.id : selectedGroupId,
      emails,
    ));
  }

  toggleUserModal(active) {
    this.props.dispatch(toggleAddUserModal(active));
    this.setState({
      email: '',
      firstName: '',
      lastName: '',
    });
  }

  toggleRemoveAccessModal(active) {
    const { dispatch } = this.props;
    const { selectedUserIds } = this.props.admin.getUsers;
    if (active && selectedUserIds.length > 0) {
      dispatch(toggleRemoveAccessModal(true));
    } else {
      dispatch(toggleRemoveAccessModal(false));
    }
  }

  cancelRemoveAccess() {
    const { dispatch } = this.props;
    dispatch(resetRemoveAccess());
  }

  addNewUser(email, firstName, lastName) {
    this.setState({
      email: email,
      firstName: firstName,
      lastName: lastName,
    });
    this.postAddUser(email, firstName, lastName, false);
  }

  confirmAddNewUser = () => {
    this.postAddUser(this.state.email, this.state.firstName, this.state.lastName, true);
    this.toggleUserModal(false);
  }

  postAddUser = (email, firstName, lastName, confirmAddUser) => {
    const { editingItemLevel, editingItem, selectedGroupId } = this.props.adminTreePicker;
    const userEmail = this.props.permissions.emailAddress;
    const addingSelf = userEmail.toUpperCase() === email.toUpperCase();
    this.props.dispatch(postNewUser(
      email,
      firstName,
      lastName,
      editingItem.id,
      editingItemLevel,
      addingSelf,
      selectedGroupId <= 0 ? editingItem.id : selectedGroupId,
      confirmAddUser,
    ));
  }

  isAddUserButtonVisible = (itemType) => {
    const { selectedGroupId } = this.props.adminTreePicker;
    const { groupAdmins } = this.props.permissions;
    if (itemType !== levelName(1)) {
      return true;
    } else if (groupAdmins.includes(selectedGroupId)) {
      return true;
    }
    return false;
  };

  render() {
    const {
      classes,
      admin,
      adminTreePicker,
    } = this.props;

    const { users, selectedUserIds } = admin.getUsers;
    const showRemoveAccessModal = admin.removeAccess.showModal;
    const showAddUserModal = admin.addUsers.showModal;
    const { showAddUserConfirmModal } = admin.addUsers;
    const selectedItemName = adminTreePicker.editingItem.name;
    const selectedItemLevel = adminTreePicker.editingItemLevel;

    const itemType = levelName(selectedItemLevel);
    const requiredPermissions = (selectedItemLevel === levels.group ?
      permissions.permissionLevels.requireSysAdmin :
      permissions.permissionLevels.requireEntityAdmin);
    const title = strings.accessTo(selectedItemName, itemType);
    const emptyMessage = strings.emptyMessage(itemType);

    return (
      <React.Fragment>
        <AuthorisedComponent requiredPermissions={requiredPermissions}>
          <div className={classes.root}>
            <div className={classes.titleContainer}>
              <span className={classes.title}>{title}</span>
              <span className={classes.description}>
                {strings.youAreIn}<b>{strings.adminMode}</b>
              </span>
            </div>
            <If condition={itemType === levelName(0)}>
              <div>
                <span className={classes.description}>
                  {`${strings.licenseLabel} - ${licenseTypes.find(x => x.id === adminTreePicker.editingItem.licenseType).name}`}
                </span>
              </div>
            </If>

            <Card className={classes.card}>
              <Message
                title={commonStrings.didYouKnow}
                className={classes.message}
                contentClassName={classes.messageContent}
                message={strings.didYouKnowMessage}
              />
              <div>
                <UsersTable
                  onCheckboxEvent={(checked, id) => this.onUserSelectionEvent(checked, id)}
                  data={users}
                  emptyMessage={emptyMessage}
                  selectedItemLevel={selectedItemLevel}
                  onAccessToggled={(id, readOnly) => this.onAccessToggled(id, readOnly)}
                />
              </div>

              <div>
                <If condition={this.isAddUserButtonVisible(itemType)}>
                  <Button
                    disableRipple
                    backgroundColor={constants.backgroundColor.main}
                    className={classes.button}
                    onClick={() => this.toggleUserModal(true)}
                  >
                    {strings.addUser}
                  </Button>
                </If>

                <If condition={users.length > 0}>
                  <Button
                    backgroundColor={constants.backgroundColor.dark}
                    className={classes.button}
                    onClick={() => this.toggleRemoveAccessModal(true)}
                  >
                    {strings.removeAccess}
                  </Button>
                </If>
              </div>
            </Card>
          </div>
          <AddUsersModal
            open={showAddUserModal}
            onCancel={() => this.toggleUserModal(false)}
            onAccept={(email, firstName, lastName) => this.addNewUser(email, firstName, lastName)}
            errorMessage={admin.addUsers.errorMessage}
            loading={admin.addUsers.loading}
          />
          <AddUserConfirmModal
            open={showAddUserConfirmModal}
            onCancel={() => this.toggleUserModal(false)}
            onAccept={this.confirmAddNewUser}
            errorMessage={admin.addUsers.errorMessage}
            loading={admin.addUsers.loading}
          />
          <RemoveAccessModal
            open={showRemoveAccessModal}
            onCancel={() => this.cancelRemoveAccess()}
            onAccept={() => this.onRemoveAccessClick()}
            errorMessage={admin.removeAccess.errorMessage}
            loading={admin.removeAccess.loading}
            itemLevel={adminTreePicker.editingItemLevel}
            multipleUsers={selectedUserIds.length > 1}
          />
        </AuthorisedComponent>
      </React.Fragment>
    );
  }
}

AdminContent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  adminTreePicker: PropTypes.shape({
    selectedGroupId: PropTypes.number,
    selectedEntityId: PropTypes.number,
    selectedProjectId: PropTypes.number,
    selectedItemLevel: PropTypes.number,
    editingItemLevel: PropTypes.number,
    editingItem: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  admin: PropTypes.shape({
    getUsers: PropTypes.shape({
      users: PropTypes.arrayOf(PropTypes.object).isRequired,
      selectedUserIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    removeAccess: PropTypes.shape({
      showModal: PropTypes.bool.isRequired,
    }).isRequired,
    addUsers: PropTypes.shape({
      showModal: PropTypes.bool.isRequired,
    }).isRequired,
    project: PropTypes.object,
    entity: PropTypes.object,
  }).isRequired,
  permissions: PropTypes.shape({
    name: PropTypes.string,
    emailAddress: PropTypes.string,
    isSysAdmin: PropTypes.bool,
    groupAdmins: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    permissions: state.user.permissions.data,
    admin: state.setup.admin,
    adminTreePicker: state.setup.admin.adminTreePicker,
  };
}

export default compose(
  withStyles(styles),
  withTheme(),
  connect(mapStateToProps),
)(AdminContent);
