import {
  SELECT_CREATE_PERIOD,
  RESET_CREATE_PERIOD,
  GET_PERIOD_INFO,
  GET_PERIOD_INFO_SUCCESS,
  POST_PERIOD,
  POST_PERIOD_SUCCESS,
  SELECT_PERIOD_TEMPLATE,
  SELECT_PERIOD_TEMPLATE_RULE,
  SELECT_PERIOD_TEMPLATE_RULE_SUCCESS,
  SELECT_PERIOD,
  EDIT_PERIOD,
  EDIT_LOAD_PERIOD_SUCCESS,
  TOGGLE_PERIOD_LOCK,
} from 'Store/Areas/Projects/CreatePeriodActions';

const initialState = {
  loading: false,
  isCreatePeriodDisabled: false,
  success: false,
  isEdit: false,
  selection: {
    selectedGroup: { id: 0 },
    selectedEntity: { id: 0 },
    selectedProject: { id: 0, taxContextId: 0 },
  },
  form: {
    selectedTemplateId: 0,
    selectedPeriodId: 0,
  },
  periodInfo: {
    id: 0,
    rulesCount: 0,
    categories: [],
    mappedTags: [],
    loading: true,
    success: false,
  },
  selectedTemplateRuleList: [],
};

export default function createPeriodReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_CREATE_PERIOD:
      return Object.assign({}, state, {
        isEdit: action.selection.isEdit,
        selection: {
          selectedGroup: action.selection.selectedGroup,
          selectedEntity: action.selection.selectedEntity,
          selectedProject: action.selection.selectedProject,
        },
        success: action.success,
        form: {
          selectedTemplateId: action.form.selectedTemplateId,
          selectedPeriodId: action.form.selectedPeriodId,
        },
        payload: action.payload || null,
      });
    case POST_PERIOD:
    case POST_PERIOD_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        success: action.success,
        data: action.data,
      });
    case SELECT_PERIOD_TEMPLATE:
      return Object.assign({}, state, {
        form: {
          selectedTemplateId: action.form.selectedTemplateId,
          selectedPeriodId: initialState.form.selectedPeriodId,
        },
      });
    case SELECT_PERIOD_TEMPLATE_RULE:
      return Object.assign({}, state, {
        form: {
          selectedTemplateId: action.form.selectedTemplateId,
          selectedPeriodId: initialState.form.selectedPeriodId,
          isCreatePeriodDisabled: true,
        },
      });
    case SELECT_PERIOD_TEMPLATE_RULE_SUCCESS:
      return Object.assign({}, state, {
        selectedTemplateRuleList: action.selectedTemplateRuleList,
        isCreatePeriodDisabled: false,
      });
    case SELECT_PERIOD:
      return Object.assign({}, state, {
        form: {
          selectedPeriodId: action.form.selectedPeriodId,
          selectedTemplateId: initialState.form.selectedTemplateId,
        },
      });
    case EDIT_PERIOD:
      return Object.assign({}, state, {
        showForm: action.showForm,
        isEdit: action.isEdit,
        loading: action.loading,
        payload: action.payload,
        selection: action.selection,
      });
    case EDIT_LOAD_PERIOD_SUCCESS:
      return Object.assign({}, state, {
        showForm: action.showForm,
        isEdit: action.isEdit,
        loading: action.loading,
        payload: action.payload,
      });
    case TOGGLE_PERIOD_LOCK:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_PERIOD_INFO:
      return Object.assign({}, state, {
        periodInfo: Object.assign({}, initialState.periodInfo, {
          id: action.payload.periodId,
          loading: action.loading,
          success: action.success,
        }),
      });
    case GET_PERIOD_INFO_SUCCESS:
      return Object.assign({}, state, {
        periodInfo: Object.assign({}, action.data, {
          loading: action.loading,
          success: action.success,
        }),
      });
    case RESET_CREATE_PERIOD:
      return initialState;
    default:
      return state;
  }
}
