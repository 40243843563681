import { takeLatest, put } from 'redux-saga/effects';
import handleStatusCode from 'Store/Api/HandleStatusCode';
import { Get } from 'Store/Api/CallApi';
import { PERIODS_GET_PERIOD } from 'Store/Api/ApiEndpoints';
import {
  COMPARE_PERIOD_FILTERS,
  getComparePeriodSuccess,
} from 'Store/Areas/Review/ComparePeriodActions';


function* getPeriodToCompareTo(action) {
  const response = yield Get(PERIODS_GET_PERIOD(action.comparePeriodId));

  if (response.ok) {
    const data = yield response.json();
    yield put(getComparePeriodSuccess(
      action.comparePeriodId,
      data,
    ));
  } else {
    yield handleStatusCode(response.status);
  }
}

/* Watcher Sagas */
export function* comparePeriodSaga() {
  yield takeLatest(COMPARE_PERIOD_FILTERS, getPeriodToCompareTo);
}
