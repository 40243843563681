export const strings = {
  conflictingRules: 'Conflicting rules',
  description: 'The rule that you have just created conflicts with one or more existing rule(s). This means that the same line items have been allocated to multiple categories. Please review the conflicting rules and line items below and select the rule that should take priority to resolve the conflict. The software will automatically update the syntax of the losing rules in the background, explicitly excluding the high priority keyword, in order to reflect your choice.',
  remainingConflictsNotePart1: totalNumberOfConflicts => `There are currently ${totalNumberOfConflicts} outstanding conflicts to be resolved`,
  remainingConflictsNotePart2: (prevTotalNumberOfConflicts, isIncrease, percentageChange) => ` (previously ${prevTotalNumberOfConflicts}, ${isIncrease ? 'an increase' : 'a decrease'} of ${percentageChange})`,
  remainingConflictsNotePart3: '. This number may increase or decrease depending on the action you take.',
  resolveConflictButton: 'Resolve',
  editPriorityButton: 'Edit priority',
  editRulePriority: 'Edit rule priority',
  changePriorityLevel: 'Change priority level to match:',
  editRuleDescription: 'Edit rule priority by dragging the slider to the appropriate priority level. This will define which rule takes precedence during categorisation, regardless of its complexity.',
  rule: 'Rule:',
  allowButton: 'Allow current',
  allowAllButton: 'Allow all',
  deleteButton: 'Delete rule(s)',
  customRuleConflictsTitle: 'Custom Rule Conflicts',
  customRuleConflictsDescription: 'The rule that you have just created conflicts with one or more existing rule(s). This means that the same line items have been allocated to multiple categories. Please review the conflicting rules and line items below and delete at least one of the rules to resolve the conflict.',
  editButtonTooltip: 'Edit selected rule priority for a rule from above',
  deleteButtonTooltip: 'Delete one or more selected rules from above',
  allowButtonTooltip: 'Set currently displayed conflicted items below as conflicted',
  allowAllButtonTooltip: 'Set all remaining conflicted items as conflicted',
  resolveButtonTooltip: 'Set selected rule as the winning rule excluding negating all other rules from above',
};
