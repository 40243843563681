import React, { Fragment } from 'react';
import { withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styles from './SimpleButton.styles';

class SimpleButton extends React.PureComponent {
  render() {
    const {
      children,
      classes,
      onClickEvent,
      className,
      description,
      disabled,
    } = this.props;

    return (
      <Fragment>
        <Choose>
          <When condition={!disabled}>
            <button type="button" className={`${classes.button} ${className}`} onClick={onClickEvent}>
              {children}
            </button>
          </When>
          <Otherwise>
            <div className={`${classes.disabledButton} ${classes.disabled} ${className}`}>
              {children}
            </div>
          </Otherwise>
        </Choose>
        <If condition={description !== ''}>
          <p className={`${classes.description} ${disabled && classes.disabled}`}>{description}</p>
        </If>
      </Fragment>
    );
  }
}

SimpleButton.defaultProps = {
  className: '',
  description: '',
  disabled: false,
};

SimpleButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  onClickEvent: PropTypes.func.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(SimpleButton);
