import { categorizationTypes } from 'Constants/Categorization/CategorizationTypes';

export const GET_LINE_ITEMS = 'LINEITEMS/GET_LINE_ITEMS';
export const GET_LINE_ITEMS_SUCCESS = 'LINEITEMS/GET_LINE_ITEMS_SUCCESS';
export const RESET_LINE_ITEMS = 'LINEITEMS/RESET_LINE_ITEMS';
export const UNCATEGORISE_LINE_ITEM = 'LINEITEMS/UNCATEGORISE_LINE_ITEM';
export const UNCATEGORISE_LINE_ITEM_SUCCESS = 'LINEITEMS/UNCATEGORISE_LINE_ITEM_SUCCESS';
export const MOVE_LINE_ITEM_TO_CONTRA_SHELF = 'LINEITEMS/MOVE_LINE_ITEM_TO_CONTRA_SHELF';
export const REMOVE_LINE_ITEM_FROM_CONTRA_SHELF = 'LINEITEMS/REMOVE_LINE_ITEM_FROM_CONTRA_SHELF';
export const MARK_LINE_ITEMS_AS_CONTRA = 'LINEITEMS/MARK_LINE_ITEMS_AS_CONTRA';
export const MARK_LINE_ITEMS_AS_CONTRA_SUCCESS = 'LINEITEMS/MARK_LINE_ITEMS_AS_CONTRA_SUCCESS';
export const MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED = 'LINEITEMS/MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED';
export const MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED_SUCCESS = 'LINEITEMS/MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED_SUCCESS';

export function getLineItems({
  periodId,
  ruleId,
  categoryId,
  categorizationType,
  includeMlPredictions,
  includeAllTags,
  includeCategoryColumn,
  includeRuleColumn,
  includeContraFlagColumn,
  includeFlaggedColumn,
  showOnlyFlaggedLineItems,
  categoryType,
  confidenceThreshold,
  sortOrder,
  sortField,
  sortTagId,
  maxLineItemValue,
  minLineItemValue,
  debounceDelay,
  scrollId,
  searchTerm,
  matchedRuleIds,
  fileType,
  tagFilters,
  contraShelfLineItems,
}) {
  return {
    type: GET_LINE_ITEMS,
    loading: true,
    hasMore: true,
    debounceDelay: debounceDelay,
    payload: {
      periodId,
      ruleId,
      categoryId,
      includeMlPredictions,
      includeAllTags,
      includeCategoryColumn,
      includeRuleColumn,
      includeContraFlagColumn,
      includeFlaggedColumn: categorizationType ===
        categorizationTypes.allExceptContras ? true : includeFlaggedColumn,
      showOnlyFlaggedLineItems,
      categoryType,
      confidenceThreshold,
      sortOrder,
      sortField,
      sortTagId,
      maxLineItemValue,
      minLineItemValue,
      scrollId,
      searchTerm: searchTerm.trim(),
      matchedRuleIds,
      fileType,
      tagFilters: tagFilters.map(tf => ({
        tagId: tf.tagId,
        searchTerm: tf.searchTerm.trim(),
        operator: tf.operator,
      })),
      contraShelfLineItems,
      categorizationType: categorizationType ===
        categorizationTypes.allExceptContras ? categorizationTypes.manual : categorizationType,
      fetchAllNoContras: categorizationType === categorizationTypes.allExceptContras,
    },
  };
}

export function getLineItemsSuccess(data) {
  return {
    type: GET_LINE_ITEMS_SUCCESS,
    loading: false,
    lineItems: data.rows,
    columnTagIds: data.columnTagIds,
    columns: data.columns,
    columnHeaders: data.columnHeaders,
    hasMore: data.hasMore,
    scrollId: data.scrollId,
    totalLineItemCount: data.totalRowCount,
    isFilteredLineItems: data.isFilteredLineItems,
    isSearchTermPresent: data.isSearchTermPresent,
  };
}

export function resetLineItems() {
  return {
    type: RESET_LINE_ITEMS,
  };
}

export function moveLineItemToContraShelf(lineItemId) {
  return {
    type: MOVE_LINE_ITEM_TO_CONTRA_SHELF,
    lineItemId: lineItemId,
  };
}

export function removeLineItemFromContraShelf(lineItemId) {
  return {
    type: REMOVE_LINE_ITEM_FROM_CONTRA_SHELF,
    lineItemId: lineItemId,
  };
}

export function markLineItemsAsContra(periodId, lineItemIds, contraGroupHighestValue) {
  return {
    type: MARK_LINE_ITEMS_AS_CONTRA,
    loading: true,
    payload: {
      periodId,
      lineItemIds,
      contraGroupHighestValue,
    },
  };
}

// eslint-disable-next-line no-unused-vars
export function markLineItemsAsContraSuccess() {
  return {
    type: MARK_LINE_ITEMS_AS_CONTRA_SUCCESS,
    loading: false,
  };
}

export function markLineItemsAsFlaggedOrUnflagged(
  periodId,
  lineItemIds,
  markAsFlagged,
  showOnlyFlaggedLineItems,
) {
  return {
    type: MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED,
    loading: true,
    payload: {
      periodId: periodId,
      lineItemIds: lineItemIds,
      markAsFlagged: markAsFlagged,
      showOnlyFlaggedLineItems: showOnlyFlaggedLineItems,
    },
  };
}

export function markLineItemsAsFlaggedOrUnflaggedSuccess() {
  return {
    type: MARK_LINE_ITEMS_AS_FLAGGED_OR_UNFLAGGED_SUCCESS,
    loading: false,
  };
}

export function uncategoriseLineItems(periodId, lineItemIds) {
  return {
    type: UNCATEGORISE_LINE_ITEM,
    loading: true,
    payload: {
      periodId,
      lineItemIds,
    },
  };
}
export function uncategoriseLineItemSuccess() {
  return {
    type: UNCATEGORISE_LINE_ITEM_SUCCESS,
    loading: false,
  };
}
