export const styles = theme => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: 6,
    marginTop: 4,
    '&:focus, &:focus-visible': {
      outline: 0,
    },
  },
  radioButtonWrapper: {
    opacity: 1.0,
    marginLeft: 20,
    marginRight: 20,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.colors.white,
    position: 'relative',
  },
  label: {
    opacity: 0.5,
    color: theme.palette.colors.white,
    fontSize: theme.constants.fontSizes.medium,
    display: 'inline',
  },
  radioButton: {
    display: 'inline-block',
    height: 15,
    width: 15,
    color: theme.palette.colors.white,
    cursor: 'pointer',
    '& img': {
      width: 'inherit',
      height: 'inherit',
    },
  },
});
