import { takeLatest, put, cancelled } from 'redux-saga/effects';
import { strings } from 'Constants/Error/Strings';
import { displayToastNotification } from 'Store/Areas/App/ToastNotificationActions';
import { REQUEST_BULK_GROUP_ENTITY_PROJECT_UPLOAD_SUCCESS, setBulkGroupEntityProjectUploadStage } from 'Store/Areas/BulkUpload/BulkGroupEntityProjectUploadActions';
import { watchSignalREvents } from 'Store/Api/CallSignalR';

function* handleSignalRMessage(payload) {
  yield put(payload.error
    ? displayToastNotification(strings.errorMessageRetry)
    : setBulkGroupEntityProjectUploadStage(payload));
}

function* bulkGroupEntityProjectUploadSignalRSaga(action) {
  yield watchSignalREvents({
    action,
    processSignalRPayload: {
      hubUrl: `user?groupid=admin-${action.userId}`,
      name: 'ReportImportProgress',
      endConnection: (connection, name) => connection.off(name),
    },
    handleSignalRMessage,
    cancelled,
  });
}

export function* bulkGroupEntityProjectUploadSignalRSagas() {
  yield takeLatest(
    REQUEST_BULK_GROUP_ENTITY_PROJECT_UPLOAD_SUCCESS,
    bulkGroupEntityProjectUploadSignalRSaga,
  );
}
