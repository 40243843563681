import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles, withTheme, Table } from '@material-ui/core';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import styles from './StickyTable.styles';

/* eslint-disable class-methods-use-this */

class StickyTable extends React.PureComponent {
  render() {
    const {
      classes, children, className, bodyHeight,
    } = this.props;
    return (
      <ScrollSync>
        <Table className={`${className} ${classes.sticky_table}`}>
          {React.Children.map(children, (child, i) => {
            if (i === 0) {
              const clone = React.cloneElement(child, {
                className: `${child.className} ${classes.sticky_tableHead}`,
              });
              return <ScrollSyncPane>{clone}</ScrollSyncPane>;
            } else if (i === 1) {
              const clone = React.cloneElement(child, {
                className: `${child.className}  ${classes.sticky_tableBody}`,
                style: { maxHeight: bodyHeight, height: bodyHeight },
              });
              return <ScrollSyncPane>{clone}</ScrollSyncPane>;
            }
            return child;
          })}
        </Table>
      </ScrollSync>
    );
  }
}

StickyTable.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  bodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

StickyTable.defaultProps = {
  children: '',
  className: '',
  bodyHeight: 300,
};

export default compose(
  withStyles(styles),
  withTheme(),
)(StickyTable);
