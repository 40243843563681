import {
  GET_APPORTIONABLE_ITEMS,
  GET_APPORTIONABLE_ITEMS_SUCCESS,
  RESET_APPORTION_ITEMS,
  APPORTIONABLE_ROWS_CHANGE,
  CALCULATE_APPORTIONMENT,
  GET_APPORTIONABLE_ITEMS_SUCCESS_AFTER_UPDATE,
} from 'Store/Areas/Apportionables/ApportionablesActions';
import { SELECT_PERIOD } from 'Store/Areas/Projects/UserTreePickerActions';


const initialState = {
  lineItems: [],
  columns: [],
  columnHeaders: [],
  columnTagIds: [],
  loading: true,
  pageNumber: 1,
  hasMore: true,
  scrollId: null,
  totalLineItemCount: 0,
  tagFilters: [],
  activeRowId: null,
  selectedRows: [],
  selectedRowIds: [],
  selectedRowsTotalAmount: 0,
  isInheritedFromTags: false,
  isLineItems: false,
};

export default function apportionablesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_APPORTIONABLE_ITEMS:
      return Object.assign({}, state, {
        loading: action.loading,
        hasMore: action.hasMore,
        tagFilters: action.payload.tagFilters,
      });
    case GET_APPORTIONABLE_ITEMS_SUCCESS_AFTER_UPDATE:
    case GET_APPORTIONABLE_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        loading: action.loading,
        hasMore: action.hasMore,
        scrollId: action.scrollId,
        pageNumber: action.pageNumber,
        lineItems: action.scrollId ?
          state.lineItems.concat(action.lineItems) :
          action.lineItems,
        columns: action.columns,
        columnHeaders: action.columnHeaders,
        columnTagIds: action.columnTagIds,
        totalLineItemCount: action.totalLineItemCount,
        activeRowId: action.activeRowId,
        selectedRows: action.selectedRows,
        selectedRowIds: action.selectedRowIds,
        selectedRowsTotalAmount: action.selectedRowsTotalAmount,
      });
    case APPORTIONABLE_ROWS_CHANGE:
      return Object.assign({}, state, {
        activeRowId: action.activeRowId,
        selectedRows: action.selectedRows,
        selectedRowIds: action.selectedRowIds,
        selectedRowsTotalAmount: action.selectedRowsTotalAmount,
      });

    case RESET_APPORTION_ITEMS:
    case SELECT_PERIOD:
      return initialState;
    case CALCULATE_APPORTIONMENT:
      return Object.assign({}, state, {
      });
    default:
      return state;
  }
}
